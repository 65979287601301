import api from '../utils/api'
// import { getUserAttributesFromResponse } from '../utils/auth-tools';

/* eslint-disable-next-line no-unused-vars */
import { normalizeProfile } from '../utils/normalization'
import alertModal from '../utils/alert'

import {
  PAYMENT_METHOD_TOKEN_FORM_STATUS,
  SET_PAYMENT_TOKEN,
  PAYMENT_METHOD_STATUS,
  SET_PREFERRED_PAYMENT_METHOD_INFO
} from '../constants/actionTypes'
// import { camelToSnake, snakeToCamel } from '../utils/camelSnake';
import { TokenFormStatus, PaymentMethodStatus } from '../constants/statuses'

export function tokenFormSubmitted() {
  return {
    type: PAYMENT_METHOD_TOKEN_FORM_STATUS,
    payload: TokenFormStatus.SENT
  }
}

export function tokenFormFailed() {
  return {
    type: PAYMENT_METHOD_TOKEN_FORM_STATUS,
    payload: TokenFormStatus.FAILED
  }
}

export function tokenRecieved() {
  return {
    type: PAYMENT_METHOD_TOKEN_FORM_STATUS,
    payload: TokenFormStatus.RECIEVED
  }
}

export function setToken(paymentToken) {
  // console.log("setToken--->",paymentToken)
  return {
    type: SET_PAYMENT_TOKEN,
    payload: paymentToken
  }
}

export function setStatus(status) {
  return {
    type: PAYMENT_METHOD_STATUS,
    payload: status
  }
}

export function setMethod(paymentMethod) {
  return {
    type: SET_PREFERRED_PAYMENT_METHOD_INFO,
    payload: paymentMethod
  }
}

export function addPayment(cardToken) {
  /* eslint-disable-next-line no-unused-vars, no-console */
  return async (dispatch, getState) => {
    // const { id } = getState().auth.currentUser.attributes;
    const user = { checkout_card_token: cardToken }
    dispatch(setStatus(PaymentMethodStatus.SENT))
    try {
      const response = await api.post(`users/payment`, { user })
      alertModal('Payment Successfully Added!', 'success', 'Continue')
      dispatch(setStatus(PaymentMethodStatus.RECIEVED))
      dispatch(setMethod(response.data.card_data))
    } catch (err) {
      // alertModal(err.response, 'error', 'Try Again');
      dispatch(setStatus(PaymentMethodStatus.FAILED))
      throw err
    }
  }
}
