import oldCurrentUser from 'redux-token-auth/dist/reducers/current-user'
// import initialState from 'redux-token-auth/dist/initial-state';
import reduceReducers from 'reduce-reducers'
import { combineReducers } from 'redux'

import { snakeToCamel } from '../utils/camelSnake'

// import { REHYDRATE, PURGE } from 'redux-persist';
import {
  SET_FORGOT_PASSWORD,VERIFY_OTP,VERIFY_MOBILE_OTP
} from '../constants/actionTypes'

const initialState = {
  forgotPass: false,
  showPopup:false,
  mobileVerified:false
}

const forgotPassReducer = (state = initialState, action) => {
  switch (action.type) {
  case SET_FORGOT_PASSWORD: {
    return {
      ...state,
      forgotPass: action.payload
    }
  }
  case VERIFY_OTP: {
    return {
      ...state,
      showPopup: action.payload
    }
  }

  case VERIFY_MOBILE_OTP: {
    return {
      ...state,
      mobileVerified: action.payload
      //mobileVerified:true,
    }
  }

    
  default:
    return state
  }
}

export default forgotPassReducer
