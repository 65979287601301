import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { goBack } from 'connected-react-router'
import { bindActionCreators } from 'redux'

// Validations
import { currentUserPropTypes } from '../../../constants/composed-proptypes'

// Actions
import * as modalActionCreators from '../../../actions/modals'
import * as formActionCreators from '../../../actions/forms'
import * as paymentCreator from '../../../actions/paymentMethods'
import * as reservationActionCreators from '../../../actions/reservation'

// Form Steps
import PaymentForm from './Step1'
import PaymentConfirmation from './Step2'
import CouponForm from './Step0'

class Experience extends Component {
  constructor(props) {
    super(props)
    this.nextPage = this.nextPage.bind(this)
    this.previousPage = this.previousPage.bind(this)

    // const { currentUser, formActions, stepForm } = this.props;
  }

  componentWillMount() {
    const { stepForm, formActions, paymentMethods, reservationRequest } = this.props

    // console.log('StepReservationForm', this.props)
    // if (reservationRequest && reservationRequest.experience.price > 0) {
    //   formActions.updateReservationFormStep(1)
    // } else {
    //   formActions.updateReservationFormStep(2)
    // }
  }

  componentDidMount() {
    const { stepForm, formActions, paymentMethods, reservationRequest } = this.props
    // console.log({ reservationRequest })
    // TODO: Ping backend here to create orderId
  }

  nextPage() {
    const { stepForm, formActions } = this.props
    formActions.updateReservationFormStep(stepForm.page + 1)
  }

  previousPage() {
    const { stepForm, formActions } = this.props
    formActions.updateReservationFormStep(stepForm.page - 1)
  }

  render() {
    const { currentUser, stepForm } = this.props
    const { page } = stepForm
    // replace with just a password field or something of the sort
    return (
      <React.Fragment>
        {/* {page === 1 && (
          <CouponForm
            nextPage={this.nextPage}
            previousPage={this.previousPage}
            onSubmit={data => this.nextPage(data)}
            currentUser={currentUser}
          />
        )} */}
        {/* {page === 1 && (
          <PaymentForm
            nextPage={this.nextPage}
            previousPage={this.previousPage}
            onSubmit={data => this.nextPage(data)}
            setPaymentMethod={(data) =>this.props.paymentAction.setMethod(data)}
            currentUser={currentUser}
            {...this.props}
          />
        )} */}
        {page === 1 && (
          <PaymentConfirmation
            // nextPage={this.nextPage}
            // previousPage={this.previousPage}
            setPaymentMethod={(data) =>this.props.paymentAction.setMethod(data)}
            onSubmit={async data => {
              console.log('CONFIRMATION SUBMIT', data);
            }}
            currentUser={currentUser}
          />
        )}
      </React.Fragment>
    )
  }
}

Experience.propTypes = {
  currentUser: currentUserPropTypes.isRequired,
  stepForm: PropTypes.shape({}).isRequired,
  formActions: PropTypes.shape({}).isRequired
}

const mapDispatchToProps = dispatch => ({
  backToPage: () => dispatch(goBack()),
  /* Bound Action Creators */
  modalActions: bindActionCreators(modalActionCreators, dispatch),
  formActions: bindActionCreators(formActionCreators, dispatch),
  paymentAction: bindActionCreators(paymentCreator, dispatch),
  reservationAction: bindActionCreators(reservationActionCreators, dispatch)
  /** NOTE: This would also be a nice way of doing it
   * https://github.com/reduxjs/react-redux/blob/master/docs/api.md#inject-todos-and-all-todoactioncreators-and-counteractioncreators-directly-as-props
   *
   * actions: bindActionCreators(Object.assign({}, modalActions), dispatch)
   */
})

const mapStateToProps = state => ({
  pathname: state.router.location.pathname,
  currentUser: state.reduxTokenAuth.currentUser,
  stepForm: state.stepForm.reservation,
  reservationRequest: state.reservationRequest
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Experience)
