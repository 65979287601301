import React from "react"

const ChefHat = props => (
  <svg viewBox="0 0 88 88" width="1em" height="1em" {...props}>
    <g fill="currentColor" data-name="Layer 2">
      <path d="M52.22 31a6.92 6.92 0 0 0-2.27.31 7 7 0 0 0-11.89 0 6.78 6.78 0 0 0-2.29-.31A7.12 7.12 0 0 0 29 37.75a7 7 0 0 0 4.44 6.76 1 1 0 0 1 .56.92V59a1 1 0 0 0 1 1h18a1 1 0 0 0 1-1V45.43a1 1 0 0 1 .56-.92A7 7 0 0 0 59 37.75 7.11 7.11 0 0 0 52.22 31zm1.61 11.65A3 3 0 0 0 52 45.43V58H36v-4h13a1 1 0 0 0 0-2h-1v-5a1 1 0 0 0-2 0v5h-4v-5a1 1 0 0 0-2 0v5h-4v-6.57a3 3 0 0 0-1.83-2.78A5 5 0 0 1 31 37.82a5 5 0 0 1 7.1-4.35 1 1 0 0 0 .77 0 1 1 0 0 0 .56-.53 5 5 0 0 1 9.14 0A4.86 4.86 0 0 1 49 35a5 5 0 0 1-.83 2.76 1 1 0 0 0 .28 1.39 1 1 0 0 0 .55.17 1 1 0 0 0 .83-.45A6.93 6.93 0 0 0 51 35a7.08 7.08 0 0 0-.25-1.83 6 6 0 0 1 1.41-.17A5.08 5.08 0 0 1 57 37.82a5 5 0 0 1-3.17 4.83z" />
      <path d="M44 88a44 44 0 1 1 44-44 44.05 44.05 0 0 1-44 44zm0-86a42 42 0 1 0 42 42A42 42 0 0 0 44 2z" />
    </g>
  </svg>
)

export default ChefHat
