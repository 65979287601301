import React from 'react'
import PropTypes from 'prop-types'
import Review from '../../Review/ReviewItem'

const ReviewsAboutMe = props => {
  const { reviews } = props

  return (
    <div className="breakbread-container">
      <h5 className="text-center">What guests are saying about you:</h5>
      {typeof reviews !== 'undefined' &&
        reviews.length &&
        reviews.map(item => (
          <Review key={"Review-"+item.id} review={item} user={item.guest} />
        ))}
    </div>
  )
}

ReviewsAboutMe.propTypes = {
  reviews: PropTypes.arrayOf(PropTypes.shape({})).isRequired
}

export default ReviewsAboutMe
