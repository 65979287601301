import React, { Component } from 'react'
import { reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import PropTypes, { any } from 'prop-types'
import validate from './validate'
import { currentUserPropTypes } from '../../../constants/composed-proptypes'
import seo_meta from '../../../utils/seo-meta.json'
import VerifyMobile from '../../Form/CommonForms/User/VerifyMobile'
import verifyImg from '../../../icons/status/success-icon.png'
import SEO from '../../Shared/Web/SEO'

/* NOTE: if currentUser form values match,they will be updated via
 * the initialValues of connectedStep3 which connects to Redux.
 * this happens automagically for all fields on the redux connected
 * form hostRegistration
 * @see https://redux-form.com/7.0.2/examples/initializefromstate/
 */
class Step2Form extends Component {
  state = { isAvatar: false }

  componentDidMount() {
    const { currentUser, nextPage, justRegister } = this.props
    if (!currentUser.attributes.phoneNumber) {
      nextPage()
    } 
  }

  updateAvatarStatus = value => {
    this.setState({ isAvatar: value })
  }

  render() {
    const { nextPage, form, currentUser, VerifyMobileOtp } = this.props
    const {
      title,
      description,
      og_title,
      og_description,
      og_site_name,
      og_locale,
      og_type
    } = seo_meta.verify_phone
    return (
      <React.Fragment>
        <SEO title={title} description={description} />

        <img src={verifyImg} className="verifyImageOtp" alt="verifyImageOtp" />

        <div className="form-header">
          <h4>Welcome {currentUser.attributes.fullName}</h4>
          <p>You have successfully registered. Please verify your number.</p>
        </div>
        <VerifyMobile
          displayAvtarError
          {...this.props}
          VerifyMobileOtp={VerifyMobileOtp}
          isAvatar={this.state.isAvatar}
          updateAvatarStatus={this.updateAvatarStatus}
          nextPage={nextPage}
          changeAvatar
        />
        <div className="form-footer">
          {/*  <button
            type="button"
            className="btn btn--text btn--link"
            onClick={() => nextPage()}
          >
            Skip
          </button> */}

          <button
            type="submit"
            form={form}
            disabled={!currentUser.attributes.phoneVerified}
            className="btn btn--darker form-footer--right"
          >
            Continue
          </button>
        </div>
      </React.Fragment>
    )
  }
}

Step2Form.propTypes = {
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  valid: PropTypes.bool.isRequired,
  previousPage: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  currentUser: currentUserPropTypes.isRequired
}

// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
const reduxFormStep3 = reduxForm({
  form: 'hostRegistration',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  enableReinitialize: true,
  validate
})(Step2Form)

// You have to connect() to any reducers that you wish to connect to yourself
const Step2Verify = connect(state => ({
  initialValues: state.reduxTokenAuth.currentUser.attributes,
  VerifyMobileOtp: state.forgotPassword.mobileVerified
  // pull initial values from account reducer
}))(reduxFormStep3)

export default Step2Verify
