import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { RightNavItems, LeftNavItems } from './Navs'

import * as modalActionCreators from '../../../actions/modals'
import * as experienceActionCreators from '../../../actions/experience'
import * as formActionCreators from '../../../actions/forms'
import * as hostActionCreators from '../../../actions/host'

import Logo from './Logo'
import LogoLarge from './LogoLargeWhite'

import MobileMenu from './MobileMenu'
import MobileMenuUtil from '../../../utils/mobileMenu'
import transitions from '../../../utils/transitions'
import navbarScrollUtil from '../../../utils/navbarScroll'
//import loadingProgressBar from '../../../utils/loadingProgressBar';
import 'gsap/CSSPlugin'

class Navigation extends Component {
  constructor() {
    super()
    this.state = {
      mobileMenuOpen: false
    }
    this.handleMobileMenu = this.handleMobileMenu.bind(this)
  }

  componentDidMount() {
    //loadingProgressBar();
    this.navbarScroll()
    const { mobileMenuOpen } = this.state
    this.mobileMenu = new MobileMenuUtil({ mobileMenuOpen })
    if (typeof window !== 'undefined') {
      this.deviceSize()
      window.addEventListener('resize', this.onResize.bind(this))
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize.bind(this))
  }

  async onSearch() {
    const { filter } = this.state
    const { setFilters } = this.props
    setFilters(filter)
    this.fetch()
  }

  onResize = () => {
    let windowResizeTime
    clearTimeout(windowResizeTime)
    windowResizeTime = setTimeout(() => {
      this.deviceSize()
    }, 100)
  };

  deviceSize = () => {
    const isMobileNav = window.matchMedia('(max-width: 1005px)').matches
    this.setState({ isMobileNav })
  };

  navbarScroll = () => {
    if (typeof window !== 'undefined') navbarScrollUtil()
  };

  handleMobileMenu(args) {
    if (typeof args !== 'undefined') {
      const { closed } = args
      switch (closed) {
      case true:
        this.closeMenu()
        break
      default:
        this.openMenu()
      }
    }
    const { mobileMenuOpen, isMobileNav } = this.state

    if (isMobileNav) {
      switch (mobileMenuOpen) {
      case true:
        this.closeMenu()
        break
      default:
        this.openMenu()
      }
    }

    return false
  }

  closeMenu() {
    const tl = transitions.mobileMenu.close($('.breakbread-mobile-menu'))
    tl.add(() => {
      this.mobileMenu.menuClose()
      this.setState({ mobileMenuOpen: false })
    }).play()
  }

  openMenu() {
    this.mobileMenu.menuOpen({ darkDrop: true })
    this.setState({ mobileMenuOpen: true })
  }

  render() {
    const { pathname } = this.props
    const { mobileMenuOpen, isMobileNav } = this.state
    const burgerClass = mobileMenuOpen
      ? 'breakbread-animated-menu-icon -active'
      : 'breakbread-animated-menu-icon'

    const isHome = pathname === '/'
    const showLargeLogo = isHome && !isMobileNav

    let navModifierClass
    let LogoComponent
    switch (showLargeLogo) {
    case true:
      navModifierClass = '-transparent'
      LogoComponent = LogoLarge
      break
    default:
      navModifierClass = '-default'
      LogoComponent = Logo
    }

    return (
      <React.Fragment>
        {mobileMenuOpen &&
          isMobileNav && (
          <MobileMenu
            {...this.props}
            toggleMobileMenu={() => this.handleMobileMenu()}
          />
        )}

        <div className="breakbread-navbar-wrap clearfix">
          <nav
            id="mainNav"
            className={`breakbread-navbar navbar ${navModifierClass}`}
          >
            <div className="breakbread-navbar-container">
              <div className="breakbread-navbar-header">
                <Link className="breakbread-navbar-brand -centered" to="/">
                  <div className="breakbread-navbar-logo">
                    <LogoComponent />
                  </div>
                </Link>
                {/* Burger Icon */}
                <button
                  type="button"
                  className="breakbread-burger-button"
                  onClick={() => this.handleMobileMenu()}
                >
                  <div className={burgerClass}>
                    <div className="breakbread-animated-menu-icon-bar" />
                    <div className="breakbread-animated-menu-icon-bar" />
                    <div className="breakbread-animated-menu-icon-bar" />
                  </div>
                </button>
                {/* / Burger Icon END */}
              </div>
              {/* Most Right Navbar */}

              <RightNavItems
                toggleMobileMenu={this.handleMobileMenu}
                {...this.props}
              />

              {/* / Most Right Navbar END */}
              <div
                className="breakbread-navbar-collapse collapse navbar-collapse"
                id="breakbread-navbar-collapse"
              >
                {/* First Navbar (Site) */}

                <LeftNavItems
                  toggleMobileMenu={() =>
                    this.handleMobileMenu({ closed: true })
                  }
                  {...this.props}
                />

                {/* / First Navbar (Site) END */}
              </div>
            </div>
          </nav>
        </div>
      </React.Fragment>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  formActions: bindActionCreators(formActionCreators, dispatch),
  experienceActions: bindActionCreators(experienceActionCreators, dispatch),
  modalActions: bindActionCreators(modalActionCreators, dispatch),
  hostActions: bindActionCreators(hostActionCreators, dispatch)
})

const mapStateToProps = state => ({
  pathname: state.router.location.pathname,
  currentUser: state.reduxTokenAuth.currentUser
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Navigation)
