import {
  HIDE_MODAL,
  BECOME_HOST,
  REG,
  RESERVATION,
  UPDATE_STEP,
  EXPERIENCE,
  HOST_LOCATION_FORM,
  SHOW_MODAL,
  USER_CONFIRMATION,
  HOST_CONFIRMATION,
  AUTH_SIGNOUT_SUCCESS,
  ACCOUNT,
  HOST_REVIEW,
  GUEST_REVIEW,
  JUST_REGISTER
} from '../constants/actionTypes'

const initialState = {
  justRegister: false,
  hostRegistration: {
    page: 1
  },
  registration: {
    page: 1
  },
  auth: {
    page: 1
  },
  reservation: {
    page: 1
  },
  experience: {
    page: 1
  },
  hostLocation: {
    page: 1
  },
  account: {
    page: 1
  },
  hostReview: {
    page: 1
  },
  guestReview: {
    page: 1
  }
}

/* Reset the Form Step State based on actions */

const stepForm = (state = initialState, action) => {
  switch (action.type) {
  case UPDATE_STEP:
    switch (action.formType) {
    case BECOME_HOST:
      return {
        ...state,
        hostRegistration: {
          page: action.page
        }
      }
    case REG:
      return {
        ...state,
        registration: {
          page: action.page
        }
      }
    case EXPERIENCE:
      return {
        ...state,
        experience: {
          page: action.page
        }
      }
    case RESERVATION:
      return {
        ...state,
        reservation: {
          page: action.page
        }
      }
    case ACCOUNT:
      return {
        ...state,
        account: {
          page: action.page
        }
      }
    case HOST_REVIEW:
      return {
        ...state,
        hostReview: {
          page: action.page
        }
      }
    case GUEST_REVIEW:
      return {
        ...state,
        guestReview: {
          page: action.page
        }
      }
    case HOST_LOCATION_FORM: 
      return {
        ...state,
        hostLocation: {
          page: action.page
        }
      }
    default:
      return state
    }
  case '@@router/LOCATION_CHANGE':
    return {
      ...state,
      account: {
        ...initialState.account
      }
    }

  case JUST_REGISTER:
    return {
      ...state,
      justRegister: action.payload
    }

  case SHOW_MODAL:
    switch (action.modalType) {
    case HOST_CONFIRMATION:
      return {
        ...initialState
      }
    case USER_CONFIRMATION:
      return {
        ...initialState
      }
    case RESERVATION:
      return {
        ...initialState
      }
    case HOST_REVIEW:
      return {
        ...initialState
      }
    case EXPERIENCE:
      return {
        ...initialState
      }
    case HOST_LOCATION_FORM:
      return {
        ...initialState
      }
    default:
      return state
    }

  case HIDE_MODAL:
    switch (action.modalType) {
    case BECOME_HOST:
      return {
        ...initialState
      }
    case REG:
      return {
        ...initialState
      }
    case EXPERIENCE:
      return {
        ...initialState
      }
    case RESERVATION:
      return {
        ...initialState
      }
    case HOST_CONFIRMATION:
      return {
        ...initialState
      }
    case USER_CONFIRMATION:
      return {
        ...initialState
      }
    case HOST_REVIEW:
      return {
        ...initialState
      }
    case HOST_LOCATION_FORM:
      return {
        ...initialState
      }
    default:
      return state
    }
  case AUTH_SIGNOUT_SUCCESS:
    return { ...initialState }

  default:
    return state
  }
}

export default stepForm
