import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

/* Components */
import { showCreateExperienceModal } from '../../../actions/modals'
import AccountsWrapper from '../AccountsWrapper'

import { TabActions, TabContent, TabButtons } from '../../Tabs/Tabs'
import List from './List'
import Reservations from '../Reservations'
import SEO from '../../Shared/Web/SEO'

const experiencesTabs = {
  defaultTab: 'upcomingExperiences', // Not Required (if other than the first tab)
  tabs: [
    {
      name: 'upcomingExperiences',
      title: 'Upcoming Experiences',
      // You can also pass in content as HTML or Text
      content: props => <List {...props} />
    },
    // {
    //   name: 'reservations',
    //   title: 'Reservations',
    //   // You can also pass in content as HTML or Text
    //   content: props => <Reservations host={props.currentUser.attributes} />,
    // },
    {
      name: 'pastExperiences',
      title: 'Past Experiences',
      // This can return a component, html or strings
      content: props => <List {...props} />
    }
  ]
}

// TODO trigger create here if url has /create
const MyExperiences = ({ showModal, location, item }) => {
  if (location.pathname === `/hosts/experiences/create`) {
    showModal({ show: true, item })
  }
  return (
    <AccountsWrapper title="Hosted Experiences">
      <SEO title="Hosted Experiences" description="Hosted Experiences" />
      <div className="account-tabs text-center">
        <TabActions namespace="experiences" data={experiencesTabs}>
          {childProps => <TabButtons {...childProps} />}
        </TabActions>
      </div>

      <TabContent namespace="experiences" data={experiencesTabs} />
    </AccountsWrapper>
  )
}

const mapStateToProps = state => ({
  currentUser: state.reduxTokenAuth.currentUser
})

const mapDispatchToProps = dispatch => ({
  showModal: async state => {
    dispatch(showCreateExperienceModal(state))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(MyExperiences)
