import React from 'react'
import { connect } from 'react-redux'
import { showReceiptModal } from '../../actions/modals'
import { getEditExperience } from '../../actions/host'

const ViewReceipt = ({ className, children, showModal, item }) =>{
  const receiptType = 'guest'
  return (
    <React.Fragment>
      {!item.approved && (
        <button
          type="button"
          className={`${className}`}
          onClick={() => showModal({ show: true, item, receiptType })}
        // disabled={item.seatsBooked}
        >
          {children}
        </button>
      )}
    </React.Fragment>
  )}

const mapStateToProps = state => ({
  currentUser: state.reduxTokenAuth.currentUser
})

const mapDispatchToProps = dispatch => ({
  showModal: async state => {
    // TODO: Show modal first (or global spinner) and hide the form until
    // getEditExperience has resolved.
    await dispatch(getEditExperience(state.item.id))
    dispatch(showReceiptModal(state))
  }
})
export default connect(mapStateToProps, mapDispatchToProps)(ViewReceipt)
