// import Swal from 'sweetalert2/dist/sweetalert2';
// import { push } from 'connected-react-router';

import api from '../../utils/api'
/* eslint-disable-next-line no-unused-vars */
import { normalizeReservations } from '../../utils/normalization'
import alertModal from '../../utils/alert'

import {
  RESERVATIONS_MADE_REQUEST_SENT,
  RESERVATIONS_MADE_REQUEST_SUCCESS,
  RESERVATIONS_MADE_REQUEST_FAILED,
  SET_RESERVATIONS_MADE_REQUEST_DATA,
  SET_RESERVATIONS_MADE_PAST_FUTURE
} from '../../constants/actionTypes'

export function reservationsMadeRequestSent() {
  return { type: RESERVATIONS_MADE_REQUEST_SENT }
}

export function reservationsMadeRequestSuccess() {
  return { type: RESERVATIONS_MADE_REQUEST_SUCCESS }
}

export function reservationsMadeRequestFailed() {
  return { type: RESERVATIONS_MADE_REQUEST_FAILED }
}

export function setReservationsMade(payload) {
  return {
    type: SET_RESERVATIONS_MADE_REQUEST_DATA,
    payload
  }
}

export function setReservationsByDate({ past, future }) {
  return {
    type: SET_RESERVATIONS_MADE_PAST_FUTURE,
    payload: {
      past,
      future
    }
  }
}

const haveNotHappened = reservations =>
  reservations.filter(r => !r.experience.hasPassed)

const alreadyHappened = reservations =>
  reservations.filter(r => r.experience.hasPassed)

export function fetchReservationsMade(
  params = { status: undefined, needs_guest_reviews: undefined }
) {
  return async (dispatch, getState) => {
    dispatch(reservationsMadeRequestSent())
    let response
    try {
      response = await api.get(`diners/reservations`, {
        params
      })

      const json = response.data
      if (json.is_success) {
        const normalizedReservations = normalizeReservations(json.reservations)
        const future = haveNotHappened(normalizedReservations)
        const past = alreadyHappened(normalizedReservations)

        dispatch(setReservationsByDate({ past, future }))
        dispatch(setReservationsMade(normalizedReservations))
      } else {
        alertModal(json.error, 'error', 'Close')
      }

      dispatch(reservationsMadeRequestSuccess())
      // dispatch(getExperience(reservationsMadeRequest.experience.id));
    } catch (err) {
      // alertModal(err.message, 'error', 'Close');
      dispatch(reservationsMadeRequestFailed())
      throw err
    }
  }
}

export function cancelReservation(reservationId) {
  return async (dispatch, getState) => {
    // const { accessToken } = getState().user;
    const url = `diners/reservations/${reservationId}/cancel`
    try {
      const response = await api.post(url)
      if (!response.data.is_succes) {
        alertModal(
          'Your reservation has been cancelled successfully.',
          'success',
          'Continue'
        )
        dispatch(fetchReservationsMade())
      } else {
        alertModal(response.data.error, 'error', 'Close')
      }
    } catch (err) {
      // alertModal(err.response, 'error', 'Close');
      dispatch(fetchReservationsMade())
    }
  }
}
