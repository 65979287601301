import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { reset } from 'redux-form'

import * as modalActionCreators from '../../../actions/modals'
import * as formActionCreators from '../../../actions/forms'
import * as userActionCreators from '../../../actions/user'
import * as hostActionCreators from '../../../actions/host'

// Validations
import { currentUserPropTypes } from '../../../constants/composed-proptypes'

// Form Steps
import HostReviewFirstPage from './Step1'

class HostReview extends Component {
  constructor(props) {
    super(props)
    this.nextPage = this.nextPage.bind(this)
    this.previousPage = this.previousPage.bind(this)
    this.closeAndExit = this.closeAndExit.bind(this)
  }

  componentWillMount() {
    const { userActions, hostActions, currentUser, experience } = this.props
    // userActions.getUser(currentUser.attributes.id)
    if (typeof experience !== 'undefined')
    if(experience && experience.schedule) {
      hostActions.getConfirmedReservations(experience.schedule.id)
    }
  }

  handleHostReviewData = async ({ data, reservation }) => {
    const {
      hostActions,
      resetForm,
      stepForm,
      reservations,
      userActions
    } = this.props

    const submitData = {
      ...data,
      reservation
    }

    resetForm()
    await hostActions.reservationHostReview(submitData)
    this.closeAndExit()
    userActions.getMyReviews()

    // let { page } = stepForm
    // page = stepForm ? page : 1
    // if (page < reservations.length) {
      // this.nextPage()
    // } else {
    // }
  };

  fetchExperiences() {
    const { hostActions } = this.props
    hostActions.getExperiences()
  }

  async closeAndExit() {
    const { modalActions } = this.props
    await modalActions.hideHostReviewModal()
    this.fetchExperiences()
  }

  nextPage() {
    const { stepForm, formActions } = this.props
    formActions.updateHostReviewFormStep(stepForm.page + 1)
  }

  previousPage() {
    const { stepForm, formActions } = this.props
    formActions.updateHostReviewFormStep(stepForm.page - 1)
  }

  render() {
    const { currentUser, stepForm, reservations, experience } = this.props
    let { page } = stepForm
    page = stepForm ? page : 1
    let currentReservation = reservations.filter((item) => item.id === experience.selectedReservation)
    return (
      <React.Fragment>
        {currentReservation.map((reservation, index) => (
          <div
          className="host-review-item form-body form-body--full"
          key={"HostReviewFirstPage-"+reservation}
        >
          <HostReviewFirstPage
          currentUser={currentUser}
          previousPage={this.previousPage}
          nextPage={this.nextPage}
          currentPage={page}
          reservationCount={reservations.length}
          guest={reservation.guest}
          experience={reservation.experience}
          reviewedByHost={reservation.reviewedByHost}
          reviewedByGuest={reservation.reviewedByGuest}
          hostReview={reservation.hostReview}
          guestReview={reservation.guestReview}
          exit={this.closeAndExit}
          reservation={reservation}
          onSubmit={data => {
            this.handleHostReviewData({ data, reservation, page })
          }}
        />
        </div>
        ))}
        {/* {page <= reservations.length &&
          reservations.map((reservation, index) => {
            // console.log(reservation)
            if (page === index + 1) {
              return (
                <div
                  className="host-review-item form-body form-body--full"
                  key={"HostReviewFirstPage-"+reservation}
                >
                  <HostReviewFirstPage
                    currentUser={currentUser}
                    previousPage={this.previousPage}
                    nextPage={this.nextPage}
                    currentPage={page}
                    reservationCount={reservations.length}
                    guest={reservation.guest}
                    experience={reservation.experience}
                    reviewedByHost={reservation.reviewedByHost}
                    reviewedByGuest={reservation.reviewedByGuest}
                    hostReview={reservation.hostReview}
                    guestReview={reservation.guestReviews}
                    exit={this.closeAndExit}
                    onSubmit={data => {
                      this.handleHostReviewData({ data, reservation, page })
                    }}
                  />
                </div>
              )
            }
            return null
          })} */}

        {currentUser.isLoading && <div className="spinner" />}
      </React.Fragment>
    )
  }
}

HostReview.propTypes = {
  currentUser: currentUserPropTypes.isRequired,
  reservations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  experience: PropTypes.shape({}).isRequired,
  stepForm: PropTypes.shape({}).isRequired,
  hostActions: PropTypes.shape({}).isRequired,
  modalActions: PropTypes.shape({}).isRequired,
  formActions: PropTypes.shape({}).isRequired,
  userActions: PropTypes.shape({}).isRequired,
  resetForm: PropTypes.func.isRequired
}

const mapDispatchToProps = dispatch => ({
  formActions: bindActionCreators(formActionCreators, dispatch),
  userActions: bindActionCreators(userActionCreators, dispatch),
  hostActions: bindActionCreators(hostActionCreators, dispatch),
  modalActions: bindActionCreators(modalActionCreators, dispatch),
  resetForm: () => dispatch(reset('hostReview'))
})

const mapStateToProps = state => ({
  pathname: state.router.location.pathname,
  currentUser: state.reduxTokenAuth.currentUser,
  stepForm: state.stepForm.guestReview,
  reservations: state.host.reservations
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HostReview)
