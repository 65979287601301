import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'
import RenderDateField from '../../../components/Form/Fields/renderDateField'
import RenderSelectField from '../../../components/Form/Fields/renderSelectField'
import PropTypes from 'prop-types'
import { generalInfoFields } from '../../../constants/experienceFormFields'
class ScheduleForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      generalFields: generalInfoFields
    }
  }

  componentDidMount() {
  }

  render() {
    const {
      handleSubmit,
      currentUser,
      submitting,
      pristine,
      valid,
      userActions
    } = this.props

    const { generalFields } = this.state
    const {
      date,
      time,
      seats
    } = generalFields
    /* const handleCountryCodeChange = e => {
      this.props.change('phoneCountryCode', e.target.value)
    } */

    return (
      <form onSubmit={handleSubmit} className="form-vertical">
        <div className="form-body">
          {/* {signUpPane === EMAIL_FORM && ( */}
          <React.Fragment>
            <div className="form-header">
              <h4>Schedule</h4>
            </div>

            <div className="grid mtt_10">
              <div className="grid__item">
                <Field
                  value={date.value}
                  initialValue={date.value}
                  name={date.name}
                  placeholder={date.placeholder}
                  onFocus={({ focused }) =>
                    this.setState(state => ({
                      ...state,
                      generalFields: {
                        ...state.generalFields,
                        date: {
                          ...state.generalFields.date,
                          focused
                        }
                      }
                    }))
                  }
                  focused={date.focused}
                  component={RenderDateField}
                  type="date"
                  label="Select Date *"
                  /* onChange={(value)=>{
                      if(moment(value).isAfter(end_date)){
                        this.props.change('end_date',moment(value))
                      }

                    }} */
                  past={false}
                />
              </div>
            </div>
            <div className="grid mtt_10">
              <div className="grid__item">
                <Field
                  name={seats.name}
                  component={RenderSelectField}
                  options={seats.options}
                  placeholder={seats.placeholder}
                  isClearable
                  type="select"
                  selectType="number"
                  property="value"
                  label="How Many Seats Are Available? *"
                  displayLabel={true}
                />
              </div>
            </div>
            <button
              type="submit"
              className="btn next btn--full"
              disabled={submitting || pristine || !valid}
              onSubmit={(data) => { 
                // eslint-disable-next-line no-console
                console.log('data', data)
              }}
            >
                Submit
            </button>
          </React.Fragment>
        </div>
      </form>
    )
  }
}

ScheduleForm.propTypes = {
  currentUser: PropTypes.shape({}).isRequired
  // handleSubmit: PropTypes.func.isRequired
}

export default ScheduleForm
