import React from "react"

const Sulfate = props => (
  <svg viewBox="0 0 88 88" width="1em" height="1em" {...props}>
    <g fill="currentColor" data-name="Layer 2">
      <circle cx={43} cy={48} r={1} />
      <circle cx={46} cy={50} r={1} />
      <circle cx={44} cy={53} r={1} />
      <circle cx={47} cy={56} r={1} />
      <circle cx={42} cy={56} r={1} />
      <path d="M55.16 51.19L49 39.75V33a2.5 2.5 0 0 0-.5-5h-9a2.5 2.5 0 0 0-.5 5v6.8l-6.16 11.39a7 7 0 0 0-.84 3.32V57a3 3 0 0 0 3 3h18a3 3 0 0 0 3-3v-2.49a7 7 0 0 0-.84-3.32zM39.5 30h9a.5.5 0 0 1 0 1H48a1 1 0 0 0-1 1v8a1 1 0 0 0 .12.47l1.77 3.29a3 3 0 0 1-2.47-.1 4.91 4.91 0 0 0-4.84 0 2.91 2.91 0 0 1-2.47.1l1.77-3.29A1 1 0 0 0 41 40v-7h3a1 1 0 0 0 0-2h-4.5a.5.5 0 0 1 0-1zM54 57a1 1 0 0 1-1 1H35a1 1 0 0 1-1-1v-2.49a5.05 5.05 0 0 1 .6-2.37l3.57-6.63a4.92 4.92 0 0 0 4.5-.17 2.9 2.9 0 0 1 2.66 0 4.5 4.5 0 0 0 2.42.66 4.68 4.68 0 0 0 2.08-.48l3.57 6.62a5.05 5.05 0 0 1 .6 2.37z" />
      <path d="M44 88a44 44 0 1 1 44-44 44.05 44.05 0 0 1-44 44zm0-86a42 42 0 1 0 42 42A42 42 0 0 0 44 2z" />
    </g>
  </svg>
)

export default Sulfate
