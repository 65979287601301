import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Hero from '../Shared/Web/Hero'
import SEO from '../Shared/Web/SEO'
/* Action Creators */
import * as blogActionCreators from '../../actions/blogs'
// import 'ckeditor4/contents.css'
import './blogPost.scss'
import SignUpAndSubscribe from '../Shared/SignUpAndSubscribe'

const MobileDetect = require('mobile-detect')

class BlogDetail extends Component {
  constructor(props) {
    super(props)

    const { blogActions } = this.props

    this.getBlog = blogActions.getBlog.bind(this)

    this.state = {
      // mobile: false,
      // exactMobile: false,
      // tabView: false
    }
    this.isMobile = this.isMobile.bind(this)
  }

  componentDidMount() {
    const { match } = this.props
    this.getBlog(match.params.slug)
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.handleResize)
    }

    if (typeof window !== 'undefined') {
      this.responsiveState()
      window.addEventListener('resize', this.handleResize)
    }
  }

  handleResize = () => {
    this.responsiveState()
  }

  responsiveState = () => {
    if (typeof window === 'undefined') {
      return false
    }
    const w = window
    const d = document
    const { documentElement } = d.documentElement
    const body = d.getElementsByTagName('body')[0]
    const width = w.innerWidth || documentElement.clientWidth || body.clientWidth
    // this.setState({ mobile: width < 993, exactMobile: width < 768, tabView: width === 768 })
    // this.setState({ exactMobile: width < 768, tabView: width === 768 })
    return width < 993
  }

  isMobile = () => {
    if (typeof window === 'undefined') {
      return false
    }
    const device = new MobileDetect(window.navigator.userAgent)
    const isMobile = device.mobile() || device.tablet()
    const isPhone = device.is('iPhone') || device.is('AndroidOS') || device.is('Windows Phone')
    // this.setState({ mobile: true })
    return isMobile || isPhone
  }

  render() {
    // TODO: We need global redux state for mobile rather than here.
    // const { mobile, exactMobile, tabView } = this.state
    // const { exactMobile, tabView } = this.state
    const { blog, blogLoading } = this.props
    if (blogLoading) {
      return (
        <div className="breakbread-container experience-single">
          <div className="grid" style={{ minHeight: '60vh' }}>
            <div className="spinner" />
          </div>
        </div>
      )
    }

    if (!blog) {
      return (
        <div className="breakbread-container experience-single">
          <div className="grid" style={{ minHeight: '60vh' }}>
            <h1>404 - Experience Not Found</h1>
          </div>
        </div>
      )
    }
    const { title, content, heroImage, author, description, publishedDate, searchable, metaTags } = blog

    const image = heroImage && heroImage.big ? heroImage.big : ''

    const heroImageHeight = (metaTags !== undefined && metaTags.heroImageHeight) ? metaTags.heroImageHeight : 'large'
    /* Grid Class */
    // const gridSizeThumbs = 'grid__item small--one-whole medium-down--one-half medium-up--one-half'
    /* Return the Experience */
    // const maxCharCountReview = exactMobile ? 172 : 138

    return (
      <div className="fadeIn">
        <SEO title={`${title}`} description={description} />
        <figure>
          <Hero heroImage={image} textPosition="left" theme="dark" mobileHeroImage={image} topHero heroImageHeight={heroImageHeight} treatment="dark-half">
            <div className="breakbread-component-text">
              <h1 className="h1 mega-title">{title}</h1>
              <div className="mega-subtitle">
                {searchable
                  ? (
                    <React.Fragment>
                      <p className="blog__author">
                        { author && `By ${author}` }
                      </p>
                      <ul className="inline inline-list list-inline">
                        <li className="text-primary">
                          {/*`${moment(publishedDate).format('DD MMM, YYYY')}` */}
                        </li>
                      </ul>
                    </React.Fragment>
                  )
                  : ''}
              </div>
            </div>
          </Hero>
        </figure>

        {content && (
          <section className="cke__outer">
            <div
              className="cke_contents_ltr"
              style={{ textAlign: 'left', paddingBottom: '1rem', maxWidth: '960px', width: '90%', margin: '0 auto' }}
              dangerouslySetInnerHTML={{ __html: content }}
            />
          </section>
        )}

        <SignUpAndSubscribe />
      </div>
    )
  }
}

BlogDetail.defaultProps = {
  // blog: null
}

BlogDetail.propTypes = {
  blogLoading: PropTypes.bool.isRequired,
  blog: PropTypes.shape({
    title: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    heroImage: PropTypes.shape({
      big: PropTypes.string,
      original: PropTypes.string,
      thumb: PropTypes.string,
      id: PropTypes.number
    }),
    author: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    publishedDate: PropTypes.string,
    content: PropTypes.string.isRequired,
    searchable: PropTypes.bool,
    metaTags: PropTypes.shape({
      heroImageHeight: PropTypes.string
    })
  }).isRequired,
  blogActions: PropTypes.shape({
    getBlog: PropTypes.func
  }).isRequired,
  match: {
    params: {
      slug: PropTypes.string
    }
  }.isRequired
}

const mapStateToProps = (state) => ({
  blogLoading: state.blogs.loading,
  blog: state.blogs.blog
})

const mapDispatchToProps = (dispatch) => ({
  blogActions: bindActionCreators(blogActionCreators, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(BlogDetail)
