import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import * as modalActionCreators from '../../../actions/modals'
import * as formActionCreators from '../../../actions/forms'
import * as userActionCreators from '../../../actions/user'

// Validations
import { currentUserPropTypes } from '../../../constants/composed-proptypes'

// Form Steps
import AccountFirstPage from './Step1'
import AccountSecondPage from './Step2'

class StepForm extends Component {
  constructor(props) {
    super(props)
    this.nextPage = this.nextPage.bind(this)
    this.previousPage = this.previousPage.bind(this)
  }

  componentDidMount() {
    // userActions.getCountryList()
  }

  componentWillMount() {
    const { userActions, currentUser } = this.props
    // userActions.getUser(currentUser.attributes.id); // Enabling this results in a loop
    // TODO: Explore why this breaks
  }

  handleAccountData = async data => {
    const { userActions /* profilePicObj */ } = this.props
    const userData = data
    delete userData.foodPhotos
    delete userData.locationPhotos
    delete userData.hostingLocation
    /* if (profilePicObj) {
      userData.avatarUrl = profilePicObj.thumb
      // await userActions.updateProfilePic({image: profilePicObj})
      // await userActions.setProfileObj(null)
    } */
    return userActions.updateUserAccount(userData, true)
  }

  closeAndExit() {
    const { modalActions } = this.props
  }

  nextPage() {
    const { stepForm, formActions } = this.props
    formActions.updateAccountFormStep(stepForm.page + 1)
  }

  previousPage() {
    // const { page } = this.state;
    // this.setState({ page: page - 1 });
    const { stepForm, formActions } = this.props
    formActions.updateAccountFormStep(stepForm.page - 1)
  }

  render() {
    const { currentUser, stepForm, countryOptions } = this.props
    let { page } = stepForm
    page = stepForm ? page : 1
    return (
      <React.Fragment>
        {page === 1 && !currentUser.isLoading && (
          <AccountFirstPage
            currentUser={currentUser}
            previousPage={this.previousPage}
            onSubmit={async data => {
              let {
                countryCode,
                city,
                birthday,
                firstName,
                lastName,
                image,
                gender,
                languages,
                phoneCountryCode,
                phoneMetaData,
                phoneNumber
              } = data
              if (countryCode && typeof countryCode !== 'string') {
                countryCode = countryCode.value
              }
              const userData = {
                countryCode,
                city,
                birthday,
                firstName,
                image,
                lastName,
                gender,
                languages,
                phoneCountryCode,
                phoneMetaData,
                phoneNumber
              }
              await this.handleAccountData(userData)
            }}
            nextPage={() => this.nextPage()}
            countryOptions={countryOptions}
          />
        )}

        {currentUser.isLoading && <div className="spinner" />}
      </React.Fragment>
    )
  }
}

StepForm.propTypes = {
  currentUser: currentUserPropTypes.isRequired,
  stepForm: PropTypes.shape({}).isRequired,
  formActions: PropTypes.shape({}).isRequired,
  userActions: PropTypes.shape({}).isRequired
  // modalActions: PropTypes.shape({}).isRequired,
}

const mapDispatchToProps = dispatch => ({
  formActions: bindActionCreators(formActionCreators, dispatch),
  userActions: bindActionCreators(userActionCreators, dispatch)
  // modalActions: bindActionCreators(modalActionCreators, dispatch),
})

const mapStateToProps = state => ({
  pathname: state.router.location.pathname,
  currentUser: state.reduxTokenAuth.currentUser,
  stepForm: state.stepForm.account,
  profilePicObj: state.user.fileUploads.profilePicObj
    ? state.user.fileUploads.profilePicObj
    : null,
  countryOptions: state.user.countryCode.countryList
    ? state.user.countryCode.countryList.list
    : null
})

export default connect(mapStateToProps, mapDispatchToProps)(StepForm)
