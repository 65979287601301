import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import Review from '../../Review/ReviewItem'

const ReviewsByMe = props => {
  const { reviews } = props
  if (!reviews.length) {
    return (
      <div className="text-center text-warning">
        <div className="section-header section-heading">
          <h5>You havent left reviews.</h5>
        </div>
        <p>
          <Link to="/experiences" className="btn">
            Explore Experiences
          </Link>
        </p>
      </div>
    )
  }

  return (
    <div className="breakbread-container">
      <h5 className="text-center">You said about your experiences:</h5>
      {typeof reviews !== 'undefined' &&
        reviews.length &&
        reviews.map(review => (
          <Review key={"Review-"+review.id} review={review} host={review.host} byMe />
        ))}
    </div>
  )
}

ReviewsByMe.propTypes = {
  reviews: PropTypes.arrayOf(PropTypes.shape({})).isRequired
}

export default ReviewsByMe
