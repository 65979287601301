import React from "react"

const Corn = props => (
  <svg viewBox="0 0 88 88" width="1em" height="1em" {...props}>
    <g fill="currentColor" data-name="Layer 2">
      <path d="M46 37a1 1 0 0 0-1-1h-2a1 1 0 0 0 0 2h2a1 1 0 0 0 1-1z" />
      <path d="M53 37h-2v-2a7 7 0 0 0-14 0v2h-2a1 1 0 0 0-1 1v13a9 9 0 0 0 9 9h2a9 9 0 0 0 9-9V38a1 1 0 0 0-1-1zm-14-1a1 1 0 0 0 1-1 1 1 0 0 0-.9-1 4.85 4.85 0 0 1 .32-1H43a1 1 0 0 0 0-2h-2a4.94 4.94 0 0 1 3-1 5 5 0 0 1 4.58 3H46a1 1 0 0 0 0 2h3v4h-1a1 1 0 0 0 0 2h1v9a5 5 0 0 1-3 4.57V46a8.26 8.26 0 0 0-.06-1H47a1 1 0 0 0 0-2h-1.52A9 9 0 0 0 39 37.23zm-3 15V39h1a7 7 0 0 1 7 7v12h-1a7 7 0 0 1-7-7zm16 0a7 7 0 0 1-6 6.92v-1.23A7 7 0 0 0 51 50V39h1z" />
      <path d="M44 88a44 44 0 1 1 44-44 44.05 44.05 0 0 1-44 44zm0-86a42 42 0 1 0 42 42A42 42 0 0 0 44 2z" />
    </g>
  </svg>
)

export default Corn
