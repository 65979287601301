
import React from 'react'
import {
  StripeProvider, Elements, CardElement,
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
  PaymentRequestButtonElement,
  IbanElement,
  IdealBankElement,
  injectStripe
} from 'react-stripe-elements'

import alertModal from '../../../utils/alert'
// import './CardSectionStyles.css'

const createOptions = (fontSize, padding) => {
  return {
    style: {
      base: {
        fontSize,
        color: '#424770',
        letterSpacing: '0.025em',
        fontFamily: 'Source Code Pro, monospace',
        '::placeholder': {
          color: '#aab7c4'
        },
        padding
      },
      invalid: {
        color: '#9e2146'
      }
    }
  }
}

const handleBlur = () => {
  // console.log('[blur]');
}
const handleChange = (change) => {
  // console.log('[change]', change);
}
const handleClick = () => {
  // console.log('[click]');
}
const handleFocus = () => {
  // console.log('[focus]');
}
const handleReady = () => {
  // console.log('[ready]');
}


class _SplitForm extends React.Component {
  handleSubmit = (ev) => {
    ev.preventDefault()
    if (this.props.stripe) {
      this.props.stripe
        .createToken()
        .then((payload) => {
          if (payload.error) {
            alertModal(payload.error.message, 'error', 'Close')
            // console.log('[token]--- if error> ', payload.error.message)
          } else {
            const paymentMethod = {
              payment_type: payload.token.card.brand,
              last4: payload.token.card.last4,
              expiry_month: payload.token.card.exp_month,
              expiry_year: payload.token.card.exp_year,
              data: payload.token
            }
            this.props.setPaymentMethod(paymentMethod)
            this.props.nextPage()
            // console.log('[token]--->', payload)
          }
        })
    } else {
      console.log("Stripe.js hasn't loaded yet.")
    }
  };

  render() {
    const { stripe } = this.props
    return (
      <form onSubmit={this.handleSubmit}>
        <span style={{ display: 'flex' }}>
          <label>
            Card number
          </label>
          <CardNumberElement
            onBlur={handleBlur}
            onChange={handleChange}
            onFocus={handleFocus}
            onReady={handleReady}
            {...createOptions(this.props.fontSize)}
          />
        </span>

        <span style={{ display: 'flex' }}>

          <label>
            Expiration date
          </label>
          <CardExpiryElement
            onBlur={handleBlur}
            onChange={handleChange}
            onFocus={handleFocus}
            onReady={handleReady}
            {...createOptions(this.props.fontSize)}
          />
        </span>
        
        <span style={{ display: 'flex' }}>
          <label>
            CVC
          </label>
          <CardCVCElement
            onBlur={handleBlur}
            onChange={handleChange}
            onFocus={handleFocus}
            onReady={handleReady}
            {...createOptions(this.props.fontSize)}
          />
        </span>
        <span style={{ display: 'flex' }}>
          <label>
            Coupon
          </label>
          <input
            style={{width: '100%', borderRadius: 0, height: 45, marginTop: 20, borderColor: 'azure', borderWidth: 'medium'}}
            component="input"
            type="text"
            name="couponCode"
            onChange={e => {
              const code = e.target.value
              const codeRegex = /\b[a-zA-Z]{13}\b/
              // const payNowButton = document.getElementById('pay-now-button')
              if (code || code.match(codeRegex)) {
                // payNowButton.disabled = false
                // console.log('Coupon code is ' + code)
                this.props.reservationAction.setCouponCode({ coupon: code })
              }
            }}
            placeholder="Enter coupon code (optional)"
          />
        </span>
        

        {/* <button
            id="pay-now-button"
            className="btn btn--darker form-footer--right"
            type="submit"
            form="payment-form"
            disabled={false}
            onClick={e => {
              e.preventDefault();
              // nextPage();
            }}
          >
            Add Code
          </button> */}
        <div className="checkout_button">
          <button type="submit" disabled={!stripe}>Continue</button>
        </div>
        
      </form>
    )
  }
}

const SplitForm = injectStripe(_SplitForm)

export default SplitForm
