import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { showCreateExperienceModal } from '../../actions/modals'
import { bindActionCreators } from 'redux'
import { withRouter } from "react-router"
/* Action Creators */
import * as tabActionCreators from '../../actions/tabs'

class TabsContent extends React.Component {
  constructor(props) {
    super(props)
    this.state = { }
  }

  componentDidMount() {
    const { namespace, data, tabActions, tabState } = this.props
    if (
      typeof data.defaultTab !== 'undefined' &&
      tabState[namespace] === null
    ) {
      tabActions.changeSelectedTab(data.defaultTab, namespace)
    }

    if (
      typeof data.defaultTab === 'undefined' &&
      tabState[namespace] === null
    ) {
      tabActions.changeSelectedTab(data.tabs[0].name, namespace)
    }
    
  }

  componentDidUpdate=()=>{
    //if(window.l)
    
    let createForm = new URLSearchParams(this.props.location.search).get("create_experience")
    // console.log("props",searchString);
    if(createForm){
      this.props.showModal({ show: true, undefined })
    }
    //this.props.showModal({ show: true, undefined });
  }

  render() {
    const { namespace, tabState, data } = this.props

    let selectedData

    if (tabState[namespace] !== null) {
      selectedData = data.tabs.find(obj => obj.name === tabState[namespace])
    } else {
      /* eslint-disable-next-line prefer-destructuring */
      selectedData = data.tabs[0]
    }

    if (typeof selectedData.content !== 'undefined') {
      return (
        <React.Fragment>
          {selectedData.content({ namespace, tabState, ...this.props })}
        </React.Fragment>
      )
    }
    return (
      <div className="error">
        The data you are trying to access is not available
      </div>
    )
  }
}

const mapStateToPropsTabContent = state => ({
  tabState: state.tabs,
  currentUser: state.reduxTokenAuth.currentUser
})

const mapDispatchToPropsTabContent = dispatch => ({
  tabActions: bindActionCreators(tabActionCreators, dispatch),
  showModal: async state => {
    dispatch(showCreateExperienceModal(state))
  }
})

// You have to connect() to any reducers that you wish to connect to yourself
export default withRouter(connect(
  mapStateToPropsTabContent,
  mapDispatchToPropsTabContent
)(TabsContent))
