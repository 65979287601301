// import React, { useState, useEffect } from 'react'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { orderBy } from 'lodash'
import PropTypes from 'prop-types'
import ReactTable from 'react-table'
import { Link } from 'react-router-dom'
import moment from 'moment'
// import Modal from 'react-bootstrap/lib/Modal'
// import ReactPaginate from 'react-paginate'
import { bindActionCreators } from 'redux'
import Swal from 'sweetalert2/dist/sweetalert2'
import api from '../../utils/api'
import alertModal from '../../utils/alert'
import {
  getDinerReservations as getReservationsAction,
  getHostReviews
} from '../../actions/host'
// import ChefAvatar from '../Chef/ChefAvatar'
// import UserReview from './Reviews/UserReview'
import * as hostActionCreators from '../../actions/host'
import { showReceiptModal } from '../../actions/modals'
import * as transactionActionCreators from '../../actions/transactions'
import GuestReviewLink from '../Workflows/GuestReview/GuestReviewLink'
import SEO from '../Shared/Web/SEO'

class Reservations extends Component {
  constructor(props) {
    super(props)
    this.state = {
      reservations: [],
      sortedReservations: [],
      show: false,
      user: [],
      startIndex: 0,
      endIndex: 1,
      selectedExperience: {},
      userInfo: [],
      options: [],
      loading: false,
      sortBy: [],
      disable: false,
      sortingOption: [
        { value: 'status', label: 'Status' },
        { value: 'booked_date', label: 'Booking Date' },
        { value: 'seats', label: 'Seats' }
      ]
    }
  }

  fetchData = async () => {
    const { getReservations } = this.props
    try {
      const result = await getReservations(this.props)

      let reservationList = result.reservations
      const experience_id = this.props.match.params.reservationId
      if (experience_id) {
        reservationList = reservationList.filter(
          data => data.experience.id == experience_id
        )
        // this.setState({ reservations: filteredList });
      }
      this.setState({
        reservations: result.reservations,
        loading: true,
        sortedReservations: reservationList
      })
    } catch (err) {
      this.setState({ loading: true, sortedReservations: [] })
    }
  }

  componentWillMount() {
    const { hostActions } = this.props
    hostActions.getExperiences()

    // let reservationList = [];
    let selectedExperience = {}
    let disableExperience = false
    this.fetchData()
    const experience_id = this.props.match.params.reservationId
    const options = []
    let option = {}
    for (const key in this.props.allExperiences) {
      if (!this.props.allExperiences[key].sample) {
        option = {
          value: this.props.allExperiences[key].id,
          label: this.props.allExperiences[key].title
        }
      }

      options.push(option)
      if (experience_id == this.props.allExperiences[key].id) {
        selectedExperience = option
        disableExperience = true
        // this.setState({ });
      }
    }
    this.setState({ options, selectedExperience, disable: disableExperience })
  }

  updateReservation = (type, reservation) => {
    if (type === 'decline') {
      api
        .post(`hosts/reservations/${reservation.id}/decline`, { reservation })
        .then(response => {
          const json = response.data
          if (json.is_success) {
            alertModal('Reservation request rejected.', 'success', 'Close')
            const fetchData = async () => {
              const { getReservations } = this.props
              const result = await getReservations(this.props)
              const reservationList = result.reservations
              const newlySortedReservations = orderBy(
                reservationList,
                'booked_date'
              )
              this.setState({ reservations: reservationList })
              this.setState({ sortedReservations: newlySortedReservations })
            }

            fetchData()
          } else {
            alertModal('there is some network issue', 'error', 'Close')
          }
        })
        .catch(e => alertModal(e.message, 'error', 'Close'))
    } else if (type === 'approve') {
      api
        .post(`hosts/reservations/${reservation.id}/approve`, { reservation })
        .then(response => {
          const json = response.data
          if (json.is_success) {
            alertModal('Reservation request approved.', 'success', 'Close')
            const fetchData = async () => {
              const { getReservations } = this.props
              const result = await getReservations(this.props)
              const reservationList = result.reservations
              const newlySortedReservations = orderBy(
                reservationList,
                'booked_date'
              )
              this.setState({ reservations: reservationList })
              this.setState({ sortedReservations: newlySortedReservations })
            }
            fetchData()
          } else {
            alertModal('there is some network issue', 'error', 'Close')
          }
        })
        .catch(e => alertModal(e.message, 'error', 'Close'))
    }
  }

  handleChange = selectedExperience => {
    if (selectedExperience != null) {
      this.setState({ selectedExperience })
      let reservationList = this.state.reservations
      const experience_id = selectedExperience.value
      if (experience_id) {
        reservationList = reservationList.filter(
          data => data.experience.id == experience_id
        )
      }
      const newlySortedReservations = orderBy(reservationList, 'booked_date')
      // this.setState({ reservations: reservationList });
      this.setState({ sortedReservations: newlySortedReservations })
    } else {
      const reservationList = this.state.reservations
      this.setState({
        selectedExperience: '',
        sortedReservations: reservationList
      })
    }

    // this.props.history.push(`/hosts/reservations/${selectedExperience.value}`);
  }

  handlePageClick = data => {
    const { selected } = data
    const startIndex = Math.ceil(selected * 2)
    const endIndex = Math.ceil((selected + 1) * 2 - 1)

    this.setState({ startIndex, endIndex })
  }

  updateSelection = target => {
    this.setState({ sortBy: target })
    const selected = target.value
    const data = this.state.reservations

    if (selected === 'booked_date') {
      const newlySortedReservations = orderBy(data, 'booked_date', 'desc')
      this.setState({ sortedReservations: newlySortedReservations })
    } else if (selected === 'status') {
      const newlySortedReservations = orderBy(data, 'status', 'desc')
      this.setState({ sortedReservations: newlySortedReservations })
    } else if (selected === 'experience') {
      const newlySortedReservations = orderBy(
        orderBy(data, 'booked_date'),
        'experience.id',
        'asc'
      )
      this.setState({ sortedReservations: newlySortedReservations })
    } else if (selected === 'seats') {
      const newlySortedReservations = orderBy(
        orderBy(data, 'booked_date'),
        'seats_booked',
        'desc'
      )
      this.setState({ sortedReservations: newlySortedReservations })
    }
  }

  handleCancellation(reservation) {
    const { transactionActions } = this.props

    const seatWording = reservation.seats > 1 ? 'Seats' : 'Seat'
    const message = `Are you sure you want to cancel your booking for <b>${reservation.seatsBooked} ${seatWording}</b> to <i>${reservation.experience.title}</i>?`
    const text =
      'Confirmed bookings are not refundable if cancelled within less than 48 hours before start of experience.'

    const sweetness = Swal.mixin({
      confirmButtonClass: 'btn btn--link',
      cancelButtonClass: 'btn',
      buttonsStyling: false,
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonText: 'Explore Experiences',
      padding: '2rem'
    })

    sweetness({
      title: 'Cancel Confirmation',
      html: `<h6>${message}</h6><p><small>${text}</small></p>`,
      type: '',
      showCancelButton: true,
      cancelButtonText: 'No, Dont Cancel',
      confirmButtonText: `Yes, I'm Sure`,
      padding: '3rem'
    }).then(result => {
      if (result.value) {
        transactionActions.cancelReservation(reservation.id)
        this.fetchData()
      }
    })
  }

  showReciept = () => {}

  render() {
    const { reservations, sortedReservations } = this.state
    const data = reservations
    function hasPassed(reservation) {
      const parsedReservationDate = Date.parse(reservation.eventDate)
      const currentDate = Date.now()

      return parsedReservationDate < currentDate
    }

    const columns = [
      {
        Header: 'Booking No',
        className: 'padded-cells',

        Cell: ({ row, index }) => (
          <div className="padded-cells">
            <Link
              to={`/diners/reservation/${row._original.id}`}
              target="_blank"
            >
              {row._original.bookingNumber}{' '}
            </Link>
          </div>
        )
      },
      {
        Header: 'Experience Name',
        className: 'padded-cells',
        Cell: ({ row }) => (
          <React.Fragment>
            <Link
              to={`/experiences/${row._original.experience.id}`}
              target="_blank"
            >
              <div
                className="padded-cells"
                style={{ paddingLeft: 'px', paddingRight: '0px' }}
              >
                {row._original.experience.title.length > 30
                  ? `${row._original.experience.title.substring(0, 15)}....`
                  : row._original.experience.title}
              </div>
            </Link>
          </React.Fragment>
        )
      },
      {
        Header: 'Host Name',
        className: 'padded-cells',
        // width:"300%",
        Cell: ({ row }) => (
          <React.Fragment>
            <Link
              to={`/host/${row._original.experience.host.slug}`}
              target="_blank"
            >
              <div
                className="padded-cells"
                style={{ paddingLeft: 'px', paddingRight: '0px' }}
              >
                {row._original.experience.host.fullname.length > 25
                  ? `${row._original.experience.host.fullname.substring(
                    0,
                    15
                  )}....`
                  : row._original.experience.host.fullname}
              </div>
            </Link>
          </React.Fragment>
        )
      },
      {
        Header: 'Date of Booking',
        className: 'padded-cells',
        Cell: ({ row }) => (
          <div className="padded-cells">
            {' '}
            {moment.utc(row._original.bookedDate).format('Do MMM YYYY')}
          </div>
        )
      },
      {
        Header: 'Booking Status',
        className: 'padded-cells',
        Cell: ({ row }) => (
          <div className="padded-cells">{row._original.status}</div>
        )
      },
      {
        Header: 'No of Seats',
        // width: 1,
        Cell: ({ row }) => (
          <div
            className="padded-cells"
            style={{ paddingLeft: '0px', paddingRight: '0px' }}
          >
            {row._original.seatsBooked}
          </div>
        )
      },
      {
        Header: 'Action',
        width: 220,
        Cell: ({ row }) => {
          let showCncel = false
          const now = moment(new Date()) // todays date
          const end = moment(row._original.experience.date)
          const duration = moment.duration(end.diff(now))
          const hours = duration.asHours()
          if (hours > 48 && row._original.status != 'Cancelled') {
            showCncel = true
          }
          return (
            <div
              className="padded-cells"
              style={{
                paddingLeft: '0px',
                paddingRight: '0px',
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              <React.Fragment>
                {showCncel ? (
                  <div
                    onClick={() => {
                      this.handleCancellation(row._original)
                    }}
                    style={{ padding: '10px', width: '10rem' }}
                    className="breakbread-btn btn btn--smaller btn--dark btn-sm btn-Mycolor"
                  >
                    Cancel
                  </div>
                ) : (
                  ''
                )}

                <div
                  onClick={() => {
                    this.props.showModal(row._original)
                  }}
                  style={{
                    padding: '10px',
                    width: '10rem',
                    marginTop: '10px',
                    marginBottom: '10px'
                  }}
                  className="breakbread-btn btn btn--smaller btn--dark btn-sm btn-Mycolor"
                >
                  View Receipt
                </div>
                {row._original.experience.hasPassed ? (
                  <GuestReviewLink
                    className="btn btn--smaller btn-action"
                    reservation={row._original}
                    callbackParams={{ needs_guest_reviews: true }}
                  >
                    Write a Review
                  </GuestReviewLink>
                ) : (
                  ''
                )}
              </React.Fragment>
            </div>
          )
        }
      }
    ]
    const experience_id = this.props.match.params.reservationId
    const detaNotFoundMessage = !experience_id
      ? 'No Reservation found. Try reseting the filter '
      : 'No Reservation found.'
    const pageTitle = 'My Bookings'

    return (
      <div className="breakbread-container">
        <SEO title={pageTitle} description={pageTitle} />
        <h1>My Bookings</h1>
        <div className="grid grid--uniform mb-5">
          <div className="grid__item medium-down--one-half medium--one-half large-up--one-quarter mb-5" />

          <div className="grid__item medium-down--one-half medium--one-half large-up--one-quarter mb_10">
            <div className="grid grid--uniform flexCenter">
              {/* <div className="grid__item medium-down--one-half medium--one-half large-up--one-quarter" style={{ margin: "auto" }}>
                <label htmlFor="searchSort" className="display-inline pr-2" >
                  Experience
                </label>
              </div>
              <div className="grid__item medium-down--one-half medium--one-half large-up--three-quarter">
                <Select
                  value={this.state.selectedExperience}
                  isDisabled={this.state.disable}
                  // Placeholder="Select Experience"
                  onChange={this.handleChange}
                  className={"react-select centerSelectBtn"}
                  classNamePrefix="select"
                  isClearable
                  options={this.state.options}
                />
    </div> */}
            </div>
          </div>

          <div className="grid__item medium-down--one-half medium--one-half large-up--one-quarter">
            <div className="grid grid--uniform flexCenter">
              {/* <div className="grid__item medium-down--one-half medium--one-half large-up--one-quarter" style={{ margin: "auto" }} >
                <label htmlFor="searchSort" className="display-inline pr-2" >
                  Sort by
                 </label>
              </div>
              <div className="grid__item medium-down--one-half medium--one-half large-up--three-quarter">
                <Select
                  // value={}
                  onChange={this.updateSelection}
                  value={this.state.sortBy}
                  className={"react-select centerSelectBtn"}
                  classNamePrefix="select"
                  isClearable
                  options={this.state.sortingOption}
                />
            </div> */}
            </div>
          </div>
          <div className="grid__item medium-down--one-half medium--one-half large-up--one-quarter" />
        </div>

        {sortedReservations.length > 0 ? (
          <ReactTable
            // pageSize={sortedReservations.length < 20 ? 5 : 20}
            className="reservationList"
            data={sortedReservations}
            defaultPageSize={sortedReservations.length < 20 ? 5 : 20}
            pageSizeOptions={[20, 25, 30, 35, 40]}
            columns={columns}
          />
        ) : (
          <React.Fragment>
            <div className="grid__item section-header section-heading">
              <h5>
                {this.state.loading ? (
                  detaNotFoundMessage
                ) : (
                  <div className="spinner" />
                )}
              </h5>
            </div>
          </React.Fragment>
        )}
      </div>
    )
  }
}
Reservations.propTypes = {
  getReservations: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  // hosts: state.user.hosts,
  reviews: state.host.reviews.reviews,
  allExperiences: state.host.listings.data
})

const mapDispatchToProps = dispatch => ({
  getReservations: () => dispatch(getReservationsAction()),
  getHostReviews: id => dispatch(getHostReviews(id)),
  transactionActions: bindActionCreators(transactionActionCreators, dispatch),
  hostActions: bindActionCreators(hostActionCreators, dispatch),
  showModal: state => {
    dispatch(showReceiptModal({ item: state, receiptType: 'guest' }))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(Reservations)
