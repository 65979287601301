import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import validate from './validate'

import { experienceInitialValues } from '../initialValues'
import { AUTH_TOKEN_VERIFIED } from '../../../constants/actionTypes'

import AccountDetails from '../../Form/CommonForms/User/AccountDetails'

const GeneralInfo = props => {
  const {
    handleSubmit,
    pristine,
    submitting,
    valid,
    previousPage,
    form
  } = props
  const formTitle = 'Edit Experience'
  return (
    <React.Fragment>
      <AccountDetails
        {...props}
        formTitle={formTitle}
        disableFields={false}
        showPasswordField
        displayCurrency={true}
        displayAvtarError={false}
      />
      <div className="form-footer displyflex" style={{ marginTop: "80px"}}>
        <button
          type="submit"
          disabled={submitting }
          form={form}

          className="btn btn--darker form-footer--right margin-auto"
        >
          Save
        </button>
      </div>
    </React.Fragment>
  )
}

GeneralInfo.propTypes = {
  submitting: PropTypes.bool.isRequired,
  previousPage: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired
  // currentUser: currentUserPropTypes.isRequired,
}

// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
const reduxFormGeneralInfo = reduxForm({
  form: 'accountEdit',
  destroyOnUnmount: false,
  // forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  enableReinitialize:true 
  // validate: values => validate(values),
})(GeneralInfo)

// You have to connect() to any reducers that you wish to connect to yourself
const connectedGeneralInfo = connect(
  // state => ({
  // initialValues: state.reduxTokenAuth.currentUser.attributes,
  state => ({
    initialValues: state.reduxTokenAuth.currentUser.attributes // pull initial values from account reducer
  }),
  { load: AUTH_TOKEN_VERIFIED } // bind account loading action creator
  // pull initial values from account reducer
  // })
)(reduxFormGeneralInfo)

export default connectedGeneralInfo
