import React from "react"

const IconClose = props => (
  <svg viewBox="0 0 20 20" className="icon" width="1em" height="1em" {...props}>
    <path
      fill="currentColor"
      d="M15.89 14.696l-4.734-4.734 4.717-4.717c.4-.4.37-1.085-.03-1.485s-1.085-.43-1.485-.03L9.641 8.447 4.97 3.776c-.4-.4-1.085-.37-1.485.03s-.43 1.085-.03 1.485l4.671 4.671-4.688 4.688c-.4.4-.37 1.085.03 1.485s1.085.43 1.485.03l4.688-4.687 4.734 4.734c.4.4 1.085.37 1.485-.03s.43-1.085.03-1.485z"
    />
  </svg>
)

export default IconClose
