import React from 'react'

import 'react-dates/initialize'

import StarRatingComponent from 'react-star-rating-component'

import inputPropTypes from './propTypes'

const renderDateField = props => {
  const {
    input,
    meta,
    value
    // meta: { touched, error, active }
  } = props

  const { touched, error, active } = meta
  /*


  You can pass in a shape through the 'field' prop,
  or use the individual props provided.

  This is what the field shape could look like.

  field: {
    name: 'fullName',
    type: 'text',
    autoComplete: 'name',
    placeholder: 'Full Name'
  },

   */

  const errored = touched && error

  return (
    <React.Fragment>
      <StarRatingComponent
        name="reviewStarRating"
        value={input.value?Number(input.value):0}
        starCount={5}
        onStarClick={value => {
          input.onChange(value)
        }}
      />
      {errored && (
        <p className="common-p-error">
          <span className="form-error">{error}</span>
        </p>
      )}
    </React.Fragment>
  )
}

renderDateField.propTypes = {
  ...inputPropTypes
}

export default renderDateField
