import React, { Component } from 'react'
import CreateExperienceLink from '../Workflows/ExperienceCreate/CreateExperienceLink'

const AccountsWrapper = ({ title, children }) => (
  <div className="breakbread-container">
    <div style={{ float: 'right', marginLeft: '-17%' }}>
      <CreateExperienceLink className="btn">
        Create a new Experience
      </CreateExperienceLink>
    </div>
    <div className=" text-center">
      <h1>{title}</h1>
    </div>
    <div className="account">{children}</div>
  </div>
)

export default AccountsWrapper
