/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 * @flow
 */

// import { AppRegistry } from 'react-native';
//

import React from 'react'
import ReactDOM from 'react-dom'
// import Helmet from 'react-helmet'
import { AppContainer } from 'react-hot-loader'
import BreakBread from './App'

// if (typeof window !== 'undefined') {
//   window.__DEV__ = true;
//   window.dev = true;
// }

require('dotenv').config()
// Global Styles
require('./scss/theme.scss')
/* Dev Tools */
// if (typeof window !== 'undefined') {
//   /* eslint-disable-next-line no-underscore-dangle */
//   window.__DEV__ = true;
//   window.dev = true;
// }

/* Render */
//const helmet = Helmet.renderStatic()
ReactDOM.render(<BreakBread />, document.getElementById('react-root'))

// /* Hot Module Replacement */
// if (module.hot) {
//   module.hot.accept(['./App'], () => {
//     ReactDOM.render(
//       <AppContainer>
//         <BreakBread />
//       </AppContainer>,
//       document.getElementById('react-root')
//     );
//   });
// }
