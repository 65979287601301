import React from "react"

const TreeNuts = props => (
  <svg viewBox="0 0 88 88" width="1em" height="1em" {...props}>
    <g fill="currentColor" data-name="Layer 2">
      <circle cx={41} cy={36.99} r={1} />
      <circle cx={44} cy={34.99} r={1} />
      <circle cx={47} cy={36.99} r={1} />
      <circle cx={50} cy={35.99} r={1} />
      <circle cx={38} cy={35.99} r={1} />
      <circle cx={44} cy={54.99} r={1} />
      <path d="M39.27 48a1 1 0 0 0-2 0 7.7 7.7 0 0 0 3.09 6 1 1 0 0 0 .64.23 1 1 0 0 0 .77-.36 1 1 0 0 0-.13-1.41A5.7 5.7 0 0 1 39.27 48z" />
      <path d="M55 39a8 8 0 0 0-8-8h-2v-2a1 1 0 1 0-2 0v2h-2a8 8 0 0 0-8 8 2 2 0 0 0 1 1.72V48c0 8.24 9.26 11.79 9.65 11.94a1.05 1.05 0 0 0 .7 0C44.74 59.78 54 56.23 54 48v-7.29A2 2 0 0 0 55 39zm-14-6h6a6 6 0 0 1 6 6H35a6 6 0 0 1 6-6zm3 24.92c-1.59-.7-8-3.89-8-9.92v-7h16v7c0 6-6.41 9.21-8 9.91z" />
      <path d="M44 88a44 44 0 1 1 44-44 44.05 44.05 0 0 1-44 44zm0-86a42 42 0 1 0 42 42A42 42 0 0 0 44 2z" />
    </g>
  </svg>
)

export default TreeNuts
