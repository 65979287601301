import React from 'react'
import ExperienceCard from '../../Experiences/ExperienceCard'

const Card = ({ reservation, cancelReservation, experience }) => {
  const parsedReservationDate = Date.parse(reservation.event_date)
  return (
    <React.Fragment>
      <div className="reservation-card">
        <ExperienceCard experience={experience} />
        <div className="reservation-card__row">
          <h6>Status: {reservation.status}</h6>
        </div>
        {/* reservation.status === 'Cancelled' || reservation.status === 'Declined' ?
          "" :
          <div className="reservation-card__row">
            <button
              type="button"
              onClick={() => cancelReservation(reservation)}
              className="btn btn--link"
              disabled={
                reservation.status === 'Cancelled' ||
                parsedReservationDate < Date.now() ||
                reservation.status === 'Declined'
              }
            >
              Cancel Request
      </button>
          </div>
            */}
      </div>
    </React.Fragment>
  )
}

export default Card
