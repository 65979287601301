import { combineReducers } from 'redux'

import host from './host'
import hosts from './hosts'
import filter from './filter'
import listings from './listings'
import reservations from './reservations'
import experienceEdit from './experienceEdit'
import experienceCreate from './experienceCreate'
import reviews from './reviews'
import location from './location'

export default combineReducers({
  host,
  hosts,
  filter,
  listings,
  reservations,
  experienceEdit,
  experienceCreate,
  reviews,
  location
})
