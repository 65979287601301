import React from "react"

const Egg = props => (
  <svg viewBox="0 0 88 88" width="1em" height="1em" {...props}>
    <g fill="currentColor" data-name="Layer 2">
      <path d="M52.74 35c-2.39-4.5-5.5-7-8.74-7s-6.35 2.47-8.74 7A28.4 28.4 0 0 0 32 47.5C32 55.09 36.71 60 44 60s12-4.91 12-12.5A28.4 28.4 0 0 0 52.74 35zM44 58c-7.37 0-10-5.42-10-10.5C34 40.59 38.48 30 44 30s10 10.59 10 17.5C54 52.58 51.37 58 44 58z" />
      <path d="M44 41a7 7 0 1 0 7 7 7 7 0 0 0-7-7zm0 12a5 5 0 1 1 5-5 5 5 0 0 1-5 5z" />
      <path d="M44 88a44 44 0 1 1 44-44 44.05 44.05 0 0 1-44 44zm0-86a42 42 0 1 0 42 42A42 42 0 0 0 44 2z" />
    </g>
  </svg>
)

export default Egg
