import {
  SET_LISTINGS,
  SET_LISTINGS_PAST_FUTURE,
  AUTH_SIGNOUT_SUCCESS,
  FETCH_LISTINGS_REQUEST_SENT,
  FETCH_LISTINGS_REQUEST_FAILED,
  FETCH_LISTINGS_REQUEST_SUCCESS,
  AUTH_TOKEN_FAILED
} from '../../constants/actionTypes'

import { GenericRequestStatus } from '../../constants/statuses'

const initialState = {
  status: null,
  data: [],
  past: [],
  future: []
}

export default function(state = initialState, action) {
  switch (action.type) {
  case FETCH_LISTINGS_REQUEST_SENT:
    return {
      ...state,
      status: GenericRequestStatus.SENT
    }
  case FETCH_LISTINGS_REQUEST_FAILED:
    return {
      ...state,
      status: GenericRequestStatus.FAILED
    }
  case FETCH_LISTINGS_REQUEST_SUCCESS:
    return {
      ...state,
      status: GenericRequestStatus.SUCCESS
    }
  case SET_LISTINGS:
    return {
      ...state,
      data: action.experiences
    }
  case SET_LISTINGS_PAST_FUTURE:
    return {
      ...state,
      ...action.payload
    }
  case AUTH_SIGNOUT_SUCCESS:
    return initialState

  case AUTH_TOKEN_FAILED:
    return initialState

  default:
    return state
  }
}
