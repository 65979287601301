// import moment from 'moment';
import { SET_FEATURED_ITEMS } from '../constants/actionTypes'

const initialState = {
  featuredExperiences: [],
  featuredHosts: [],
  // featuredReviews: [],
  featureGuestReviews: []
}

export default function(state = initialState, action) {
  if (action.type === SET_FEATURED_ITEMS) {
    return {
      ...state,
      featuredExperiences: [...action.featuredItems.featuredExperiences],
      featuredHosts: [...action.featuredItems.featuredHosts],
      // featuredReviews: [...action.featuredItems.featuredReviews],
      featureGuestReviews: [...action.featuredItems.featureGuestReviews]
    }
  }
  return state
}
