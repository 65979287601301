import React from 'react'
import { connect } from 'react-redux'
import Snacks from 'react-notification-system-redux'

import PropTypes from 'prop-types'

/*
  See https://github.com/gor181/react-notification-system-redux
*/

const SnackContainer = props => {
  const { snacks } = props
  // Color and Variable Guide
  const styleGuide = {
    primaryColor: 'rgb(248, 164, 141)'
  }

  /* Notification Styles
    *
    * See https://github.com/igorprado/react-notification-system/blob/master/src/styles.js
    */

  const style = {
    // TODO - Put in its own file as a const export
    NotificationItem: {
      DefaultStyle: {
        margin: '10px 5px 2px 1px',
        backgroundColor: 'white',
        color: 'inherit',
        boxShadow: 'rgba(0, 0, 0, 0.28) 1px 0px 9px',
        height: 'auto',
        padding: '32px',
        borderRadius: '0px'
      },

      success: {
        // Applied only to the success notification item
        borderTop: `2px solid ${styleGuide.primaryColor}`
      }
    },
    Title: {
      DefaultStyle: {
        margin: '0 0 5px 0',
        padding: 0
      },
      success: {
        color: styleGuide.primaryColor
      }
    },
    Action: {
      DefaultStyle: {
        borderRadius: '0px'
      },
      success: {
        backgroundColor: styleGuide.primaryColor,
        color: '#ffffff'
      }
    },
    Dismiss: {
      DefaultStyle: {
        fontFamily: 'Arial',
        fontSize: '17px',
        position: 'absolute',
        top: '4px',
        right: '5px',
        lineHeight: '15px',
        backgroundColor: '#dededf',
        color: '#ffffff',
        borderRadius: '50%',
        width: '14px',
        height: '14px',
        fontWeight: 'normal',
        textAlign: 'center'
      },

      success: {
        color: 'white',
        backgroundColor: styleGuide.primaryColor
      }
    }
  }
  // if (!toasts) {
  //   return null;
  // }
  return <Snacks notifications={snacks} style={style} />
}

SnackContainer.contextTypes = {
  store: PropTypes.shape({})
}

SnackContainer.propTypes = {
  snacks: PropTypes.arrayOf(PropTypes.shape({})).isRequired
}

const mapStateToProps = state => ({
  snacks: state.snacks
})

export default connect(
  mapStateToProps,
  null
)(SnackContainer)
