import React from 'react'
import PropTypes from 'prop-types'
import IconGoogle from '../../icons/social/IconGoogle'

const FacebookButton = ({ disabled, onClickFunc, text, extraClassName }) => (
  <button
    className={`btn btn--fb btn--has-icon-before ${extraClassName}`}
    onClick={() => onClickFunc()}
    rel="noopener noreferrer"
    type="button"
    disabled={disabled}
  >
    <i className="icon icon--facebook" /> {text}
  </button>
)

FacebookButton.defaultProps = {
  text: 'Login with Facebook',
  extraClassName: ''
}
FacebookButton.propTypes = {
  text: PropTypes.string,
  onClickFunc: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  extraClassName: PropTypes.string
}

const GoogleButton = ({ disabled, onClickFunc, text, extraClassName }) => (
  <button
    className={`btn btn--google btn--has-icon-before ${extraClassName}`}
    onClick={() => onClickFunc()}
    rel="noopener noreferrer"
    type="button"
    disabled={disabled}
  >
    <IconGoogle /> {text}
  </button>
)

GoogleButton.defaultProps = {
  text: 'Login with Google',
  extraClassName: ''
}

GoogleButton.propTypes = {
  text: PropTypes.string,
  onClickFunc: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  extraClassName: PropTypes.string
}

export { FacebookButton, GoogleButton }
