import React, { Component } from 'react'
import { connect } from 'react-redux'
import alertModal from '../../utils/alert'
/* Actions */
import { verifyToken, resetPassword } from '../../actions/forgotPassword'
import seo_meta from '../../utils/seo-meta.json'
import SEO from '../Shared/Web/SEO'

const validatePassword =(password)=>{
  if(password != undefined){
    let length = password.length >= 8 ? true : false  
    let isCapitalcase = false
    let isLowercase = false
    let isNumber = false
    for(let key in password){
      let ch = password[key]
      if(ch >= 0 && ch <= 9){
        isNumber =true
      }else{
        if(ch.charCodeAt() >= 65 && ch.charCodeAt() <= 90){
          isCapitalcase = true
        }else{
          if(ch.charCodeAt() >= 97 && ch.charCodeAt() <= 122){
            isLowercase = true
          }
        }
      }
    }
       
    return  (length && isCapitalcase && isLowercase && isNumber)
  }
  else{
    return false
  }
}
class ResetPassword extends Component {

  constructor() {
    super()
    this.state = {
      confirm_password: '',
      password: '',
      token: "",
      message: "",
      passwordChange: false
    }
  }

  componentDidMount = async () => {

    let token = this.props.match.params.token
    try {
      let res = await this.props.verifyToken(token)
      if (res) {
        this.setState({ token })
      } else {
        this.props.history.push('/404')
      }
    } catch (err) {
      this.props.history.push('/404')

      //console.log("tokenerr",err);
    }
  }

  changePassword = async () => {

    let { password, confirm_password, token } = this.state
    let passwordDeta = { password, confirm_password, token }

    if (!validatePassword(password)) {
      this.setState({
        confirm_password: '',
        password: '',
        message: 'Your password must be at least 8 characters long, contain at least one number, one lowercase & uppercase letter!',
        passwordChange: false
      })
    }else{

      if ((password == confirm_password) && (passwordDeta && confirm_password)) {

        try {
  
  
          let res = await this.props.resetPassword(passwordDeta)
          if (res.is_success) {
  
            this.setState({
              confirm_password: '',
              password: '',
              message: "",
              passwordChange: true
            })
  
            alertModal(res.message, 'success', 'Close')
            setTimeout(() => { this.props.history.push('/') }, 3000)
          } else {
            this.setState({
              confirm_password: '',
              password: '',
              message: "",
              passwordChange: false
            })
            alertModal(res.error_message, 'error', 'Close')
          }
        } catch (err) {
  
          this.setState({
            confirm_password: '',
            password: '',
            message: "There is some error please try again.",
            passwordChange: false
          })
  
        }
  
      }
      else {
  
        if (!(password && confirm_password)) {
          this.setState({
            confirm_password: '',
            password: '',
            message: "Please enter password and confirm password both.",
            passwordChange: false
          })
        }
  
        if (password != confirm_password) {
          this.setState({
            confirm_password: '',
            password: '',
            message: "Password and confirm password should be same.",
            passwordChange: false
          })
        }
  
      }
    }

   


  }

  render() {
    let {
      confirm_password, password, message, passwordChange } = this.state
    return (
      <div className="experience-single fadeIn">
        <SEO title="Reset Password | Breakbread" description="Reset Password | Breakbread" />
        <div className="breakbread-container" style={{ marginTop: '5%', marginBottom: '5%' }}>
          <figure>
            <div className="breakbread-component-text">
              <h1 className="h1 mega-title">Reset Password</h1>
              <span className={passwordChange ? 'successMessage' : 'errorMessage'}> {message}</span>
            </div>
          </figure>
          <div className="grid grid--uniform">
            <div className="grid__item form_grid__item--input  ">
              {/*/password}
                {//fields.renderField*/}

              <input
                type="password"
                style={{ width: '50%', marginBottom: '10px' }}
                value={password}
                name="password"
                onChange={e => {
                  this.setState({ password: e.target.value })
                }}
                placeholder="Enter New Password"
              />
            </div>
          </div>

          <div className="grid grid--uniform">
            <div className="grid__item form_grid__item--input ">
              <input
                type="password"
                style={{ width: '50%', marginBottom: '10px' }}
                value={confirm_password}
                name="confirm_password"
                placeholder="Retype Password"
                onChange={e => {
                  this.setState({ confirm_password: e.target.value })
                }}
              />
            </div>
          </div>

          <div className="grid grid--uniform">
            <div className="grid__item form_grid__item--input ">
              <button
                type="submit"
                form="auth"
                value="Submit"
                className="btn minWidth200"
                onClick={() => {
                  this.changePassword()
                }}
                style={{ width: '50%', marginBottom: '10px' }}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}


const mapStateToProps = state => ({
  forgotPassword: state.forgotPassword
})

const mapDispatchToProps = dispatch => ({

  verifyToken: state => dispatch(verifyToken(state)),
  resetPassword: state => dispatch(resetPassword(state))

})
export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword)

//export default ResetPassword;