import React, { Component, Children } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import moment from 'moment'
import { bindActionCreators } from 'redux'
import { Link } from 'react-router-dom'
import ReactTable from 'react-table'

/* Action Creators */
import { RouterActionType } from 'connected-react-router'
import * as hostActionCreators from '../../../actions/host'
import * as tabActionCreators from '../../../actions/tabs'

/* Components */
import EditExperienceLink from '../../Workflows/ExperienceEdit/EditExperienceLink'
import HostReviewLink from '../../Workflows/HostReview/HostReviewLink'
import CreateExperienceLink from '../../Workflows/ExperienceCreate/CreateExperienceLink'
import ViewReceipt from './ViewReceipt'
/* Action Creators */
// import * as userActionCreators from '../../actions/user';
// import * as hostActionCreators from '../../actions/hosts';

/* Styles */
import 'react-table/react-table.css'
import './List.css'
import { experience } from '../../../constants/mockdata'
import PauseReservation from './PauseReservation'

const futureColumns = [
  {
    id: 'date',
    Header: 'Date',
    // accessor: e => `${e.readableDate} - ${e.time}`, // String-based value accessors!
    accessor: e => `${moment(e.readableDate).format('Do MMM YYYY')}`,
    className: 'padded-cells'
  },
  {
    Header: 'Experience',
    accessor: 'title', // String-based value accessors!
    className: 'padded-cells customBold',
    Cell: ({ row }) => (
      <div className="padded-cells customBold wrapTextLeft">
        <a
          href={`/experiences/${row._original.id}`}
          target="_blank"
          style={{ color: 'black', textDecoration: 'none' }}
          rel="noreferrer"
        >
          {' '}
          {row._original.title}
        </a>
      </div>
    )
  },
  {
    Header: 'Phase',
    accessor: 'status',
    className: 'padded-cells'
  },
  {
    id: 'numberOfGuests',
    Header: 'Number of Guests',
    accessor: e => (
      <a href={`/hosts/reservations/${e.id}`}>{`${e.seats - e.seatsAvailable}/${
        e.seats
      }`}</a>
    ), // Custom value accessors!
    className: 'padded-cells'
  },
  {
    Header: 'Actions',
    accessor: 'pause_reservations',
    className: 'padded-cells',
    Cell: ({ row }) => (
      <React.Fragment>
        {row.status.toLowerCase() === 'approved' || row.status.toLowerCase() === 'paused' ? (
          <PauseReservation className="padded-cells" data={row._original}>
            <React.Fragment>
              {row._original.paused ? (
                <div
                  style={{ padding: '10px', marginRight: '5px' }}
                  className="breakbread-btn btn btn--dark btn-sm btn-Mycolor"
                >
                  Resume
                </div>
              ) : (
                <div
                  style={{ padding: '10px', marginRight: '5px' }}
                  className="breakbread-btn btn btn--dark btn-sm btn-Mycolor"
                >
                  Pause
                </div>
              )}
            </React.Fragment>
          </PauseReservation>
        ) : (
          ''
        )}
        <EditExperienceLink
          className="breakbread-btn btn btn--dark btn-sm btn-Mycolor"
          item={row._original}
        >
          Edit
        </EditExperienceLink>
      </React.Fragment>
    )
  }

  // {
  //   id: 'status',
  //   Header: 'Status',
  //   accessor: e => {
  //     if (e.approved) {
  //       return 'Approved';
  //     }
  //     return 'Pending Approval';
  //   }, // Custom value accessors!
  //   className: 'padded-cells',
  // },

  // {
  //   id: 'total',
  //   Header: 'Total',
  //   accessor: e => `$${(e.seats - e.seatsAvailable) * e.price}` // Custom value accessors!
  // },
  // {
  //   Header: 'Action',
  //   Cell: ({ original }) => (
  //     <EditExperienceLink className="btn btn--white btn--small" item={original}>
  //       Edit
  //     </EditExperienceLink>
  //   ),
  //   className: 'text-center',
  // },
]

const pastColumns = [
  {
    id: 'date',
    Header: 'Date',
    // accessor: e => `${e.readableDate} - ${e.time}`, // String-based value accessors!
    accessor: e => `${moment(e.readableDate).format('MM/DD/YY')}`,
    className: 'padded-cells'
  },
  {
    Header: 'Experience',
    accessor: 'title', // String-based value accessors!
    className: 'padded-cells customBold',
    Cell: ({ row }) => (
      <div className="wrapTextLeft">
        <a
          href={`/experiences/${row._original.id}`}
          target="_blank"
          style={{ color: 'black', textDecoration: 'none' }}
          rel="noreferrer"
        >
          {' '}
          {row._original.title}
        </a>
      </div>
    )
  },

  // {
  //   id: 'status',
  //   Header: 'Status',
  //   accessor: e => {
  //     if (e.approved) {
  //       return 'Approved';
  //     }
  //     return 'Pending Approval';
  //   }, // Custom value accessors!
  //   className: 'padded-cells',
  // },

  {
    id: 'seats',
    Header: 'Seats',
    accessor: 'seats', // String-based value accessors!
    className: 'padded-cells',
    accessor: e => <a href={`/hosts/reservations/${e.id}`}>{`${e.seats}`}</a>
  },
  {
    id: 'numberOfGuests',
    Header: 'Fulfilled Seats',
    accessor: e => `${e.seats - e.seatsAvailable}`, // Custom value accessors!
    className: 'padded-cells'
  },
  {
    Header: 'Reviews',
    accessor: 'host.reviewsReceivedAsHostCount',
    className: 'padded-cells'
  },

  {
    Header: 'Review Status',
    Cell: ({ original }) => {
      if (!original.reviewsPending) {
        return <span>Reviews Completed!</span>
      }
      return <span>Review Pending</span>
    },
    className: 'text-center'
  },
  {
    id: 'total',
    Header: 'Total Payout',
    accessor: e => `${(e.seats - e.seatsAvailable) * e.price} ${e.currency}` // Custom value accessors!
  },

  {
    Header: 'Actions',

    Cell: ({ row }) => (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <ViewReceipt
          className="breakbread-btn btn btn--smaller mb_5 btn--dark btn-sm btn-Mycolor"
          item={row._original}
        >
          View Receipts
        </ViewReceipt>
        {row._original.reviewsPending ? (
          <HostReviewLink
            className="btn--link btn--small "
            experience={row._original}
          >
            <span
              className="text-primary btn btn--smaller"
              style={{ whiteSpace: 'normal' }}
            >
              Complete Guest Reviews
            </span>
          </HostReviewLink>
        ) : (
          ''
        )}
      </div>
    )
  }
  // {
  //   Header: 'Action',
  //   Cell: ({ original }) => (
  //     <EditExperienceLink className="btn btn--muted btn--small" item={original}>
  //       View Receipt
  //     </EditExperienceLink>
  //   ),
  //   className: 'text-center',
  // },
]

class MyExperiencesList extends Component {
  constructor(props) {
    super(props)
    this.handleConfirmation.bind(this)
  }

  componentWillMount() {
    const {
      hostActions,
      futureExperiences,
      pastExperiences,
      fetchStatus,
      namespace,
      tabActions,
      tabs
    } = this.props

    hostActions.getExperiences()

    if (pastExperiences.length === 0) {
      tabActions.changeSelectedTab('upcomingExperiences', namespace)
    }
  }

  viewReceipt(reservation) {
    return false
  }

  editExperience(reservation) {
    return false
  }

  handleConfirmation(reservation) {
    return false
  }

  render() {
    const {
      tabs,
      allExperiences,
      futureExperiences,
      pastExperiences,
      hostActions,
      fetchStatus
    } = this.props

    let experiences = []
    let keyword
    let columns
    /* NOTE: For SAMPLE experiences:
     * (i.e. first experience submitted via host application),
     * Sample experiences dont have dates and will not display here.
     */
    switch (tabs.experiences) {
    case 'pastExperiences':
      experiences = pastExperiences
      keyword = 'past'
      columns = pastColumns
      break
    case 'upcomingExperiences':
      experiences = futureExperiences
      keyword = 'upcoming'
      columns = futureColumns
      break
    default:
      experiences = []
    }

    if (fetchStatus === 'error') {
      return (
        <div className="text-error error">
          There was an error fetching your hosts
        </div>
      )
    }

    if (fetchStatus !== 'success') {
      return <div className="spinner" />
    }

    const newExperience = experiences.filter(item => item.sample != true)
    if (newExperience && newExperience.length > 0) {
      return (
        <ReactTable
          defaultPageSize={experiences.length < 20 ? 5 : 20}
          pageSizeOptions={[20, 25, 30, 35, 40]}
          data={newExperience}
          columns={columns}
        />
      )
    }

    return (
      <div className="text-center text-warning">
        <div className="section-header section-heading">
          <p style={{ fontSize: '20px' }}>You don't have {keyword} experiences yet.</p>
        </div>
        <p>
          {/* <Link to="/experiences" className="btn">
            Explore Experiences
          </Link> */}
          <CreateExperienceLink className="btn">Create a new Experience</CreateExperienceLink>
        </p>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  // hosts: state.user.hosts,
  allExperiences: state.host.listings.data,
  futureExperiences: state.host.listings.future,
  pastExperiences: state.host.listings.past,
  fetchStatus: state.host.listings.status,
  tabs: state.tabs
})

const mapDispatchToProps = dispatch => ({
  hostActions: bindActionCreators(hostActionCreators, dispatch),
  tabActions: bindActionCreators(tabActionCreators, dispatch)
})

// You have to connect() to any reducers that you wish to connect to yourself
export default connect(mapStateToProps, mapDispatchToProps)(MyExperiencesList)

// const mapStateToProps = state => ({
//   hosts: state.user.hosts,
//   experiences: state.host.listings.data,
//   fetchStatus: state.host.listings.status
// });
//
// const mapDispatchToProps = dispatch => ({
//   userActions: bindActionCreators(userActionCreators, dispatch),
//   hostActions: bindActionCreators(hostActionCreators, dispatch)
// });
//
// // You have to connect() to any reducers that you wish to connect to yourself
// export default connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(TransactionHistory);
