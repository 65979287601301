import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import { injectStripe } from 'react-stripe-elements'
import seoMeta from '../../../utils/seo-meta.json'

/* Action Creators */
import * as reservationActionCreators from '../../../actions/reservation'
import * as modalActionCreators from '../../../actions/modals'
import * as paymentActionCreators from '../../../actions/transactions/payments'

/* Components */
import ShowSavedCard from '../../CreditCard/ShowSavedCard'
import SetupNewCardComponent from '../../CreditCard/SetupNewCard'

// Internal Utils
import alertModal from '../../../utils/alert'
import SEO from '../../Shared/Web/SEO'
import { trackBookNowButton } from '../../../utils/tracking'
import successIcon from '../../../icons/status/success-icon.png'
import errorIcon from '../../../icons/allergies_and_diets/SVG/icon_error.svg'

class PaymentConfirmation extends Component {
  constructor(props) {
    super(props)
    this.state = {
      elementFontSize: '18px',
      isPaymentButtonEnabled: false,
      isPaymentInputEnabled: true,
      dynamicBackButtonContent: 'Cancel',
      selectedPaymentMethod: null,
      isLoadingCardData: true,
      isLoadingAPIResponse: false,
      paymentInputErrors: {},
      doInputErrorsExist: false,
      isLoadingCouponData: false,
      noNeedOfPaymentinput: false
    }
  }

  async componentDidMount() {
    await window.addEventListener('resize', this.handleResize)

    const { stripe, paymentActions, modalActions } = this.props

    try {
      await paymentActions.createOrderId()
    } catch (e) {
      await modalActions.hideReservationModal()
      await alertModal(e.message, 'error', 'Close')
      return
    }
    await paymentActions.fetchSavedCard()

    if (stripe) {
      await this.setState({ isPaymentButtonEnabled: true })
    }

    const { paymentState } = this.props
    const { savedCard } = paymentState

    if (!savedCard || !savedCard.id) {
      this.setState({ isPaymentInputEnabled: true })
      this.setState({ selectedPaymentMethod: null })
    } else {
      this.setState({ isPaymentInputEnabled: false })
      this.setState({ selectedPaymentMethod: savedCard })
    }
    await this.setState({ isLoadingCardData: false, isLoadingCouponData: false })

    trackBookNowButton()
  }

  async componentDidUpdate() {
    const { reservationRequest, modalActions } = this.props
    const { noNeedOfPaymentinput } = this.state

    if (!reservationRequest || !reservationRequest.experience || !reservationRequest.experience.title) {
      await modalActions.hideReservationModal()
      reservationActions.setReservationRequestInfo({
        schedules: 0,
        seats: 0,
        coupon: null,
        couponInfo: null,
        couponError: null,
        couponDetail: null
      })
    }

    const netDiscount = (reservationRequest.couponDetail && reservationRequest.couponDetail.netDiscount) ? parseFloat(reservationRequest.couponDetail.netDiscount) : 0
    const experiencePrice = reservationRequest.experience && reservationRequest.experience.price ? reservationRequest.experience.price : 0
    const netAmount = ((reservationRequest.seats * experiencePrice) - netDiscount)

    if (netAmount == 0 && !noNeedOfPaymentinput){
      this.setState({ noNeedOfPaymentinput: true })
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }

  handleResize() {
    const { elementFontSize } = this.state
    if (window.innerWidth < 450 && elementFontSize !== '14px') {
      this.setState({ elementFontSize: '14px' })
    } else if (window.innerWidth >= 450 && elementFontSize !== '18px') {
      this.setState({ elementFontSize: '18px' })
    }
  }

  render() {
    const {
      reservationRequest,
      reservationActions,
      paymentActions,
      modalActions,
      paymentState
    } = this.props
    const {
      elementFontSize, isPaymentButtonEnabled, isPaymentInputEnabled,
      dynamicBackButtonContent, selectedPaymentMethod, isLoadingCardData,
      isLoadingAPIResponse, paymentInputErrors, doInputErrorsExist, isLoadingCouponData, noNeedOfPaymentinput
    } = this.state
    const { savedCard, setupIntent } = paymentState

    const netDiscount = (reservationRequest.couponDetail && reservationRequest.couponDetail.netDiscount) ? parseFloat(reservationRequest.couponDetail.netDiscount) : 0
    const experiencePrice = reservationRequest.experience && reservationRequest.experience.price ? reservationRequest.experience.price : 0
    const netTotal = parseFloat((reservationRequest.seats * experiencePrice) - netDiscount ).toFixed(2)

    const triggerBookingReservation = async () => {
      await reservationActions.bookExperience()
      await this.setState({ isLoadingAPIResponse: false })
      await modalActions.hideReservationModal()
      // on success hide modal
    }

    const handleConfirmReservation = async () => {
      await this.setState({ isLoadingAPIResponse: true })

      if (noNeedOfPaymentinput || (!isPaymentInputEnabled && selectedPaymentMethod)) {
        await triggerBookingReservation()
      } else {
        const { elements, stripe } = this.props
        const cardElement = elements.getElement('cardNumber') // this.props.stripe.elements().getElement(CardNumberElement)

        await stripe.confirmCardSetup(setupIntent, { payment_method: { card: cardElement } })
          .then(async (payload) => {
            if (payload.error) {
              // TODO: send error to backend
              paymentActions.handleSetupIntentFailure(payload.error)
              paymentActions.createSetupIntent()

              await alertModal(payload.error.message, 'error', 'Close')
              await this.setState({ isLoadingAPIResponse: false })
            } else {
              await paymentActions.setSavedCard({ id: payload.setupIntent.payment_method })
              await triggerBookingReservation()
            }
          })
      }
    }

    const initiateNewCardInput = async () => {
      await this.setState({ dynamicBackButtonContent: 'Back' })
      await this.setState({ isPaymentInputEnabled: true })
    }

    const cancelNewCardInput = async () => {
      await this.setState({ dynamicBackButtonContent: 'Cancel' })
      await this.setState({ isPaymentInputEnabled: false })
    }

    const selectSavedCard = async (selectedCard) => {
      await this.setState({ selectedPaymentMethod: selectedCard })
    }

    const dynamicBackButtonAction = () => {
      if (isPaymentInputEnabled && savedCard && savedCard.id) {
        cancelNewCardInput()
      } else {
        reservationActions.setReservationRequestInfo({
          coupon: null,
          couponDetail: {},
          couponError: null,
          couponInfo: null
        })
        modalActions.hideReservationModal()
      }
    }

    const applyCoupon = async (code) => {
      await this.setState({ isLoadingCouponData: true })
      await reservationActions.applyCouponCode(code)
      await this.setState({ isLoadingCouponData: false })
    }

    const clearCoupon = async() => {
      await this.setState({ isLoadingCouponData: true })
      await reservationActions.setCouponCode({coupon: null})
      await this.setState({ isLoadingCouponData: false })
    }

    const checkForInputErrorsExist = (errors) => {
      const filteredErrors = Object.keys(errors).filter(e => e && errors[e])

      return this.setState({ doInputErrorsExist: filteredErrors.length > 0 })
    }

    const handlePaymentInputError = async (_change) => {
      if (_change.error) {
        await this.setState({ paymentInputErrors: { ...paymentInputErrors, [_change.elementType]: _change.error.message } })
      } else {
        await this.setState({ paymentInputErrors: { ...paymentInputErrors, [_change.elementType]: null } })
      }

      const newState = this.state
      const newErrorsState = newState.paymentInputErrors
      await checkForInputErrorsExist(newErrorsState)
    }

    return (
      <React.Fragment>
        <SEO title={seoMeta.confirm_payment.title} description={seoMeta.confirm_payment.description} />
        <div className="form-body">
          {/* <p style={{ overflowWrap: 'anywhere' }}>
            { JSON.stringify(paymentState) }
          </p> */}
          {isLoadingAPIResponse && <div className="spinner spinner__override" />}
          {reservationRequest && reservationRequest.experience && (
            <div className="form-header">
              {/* { JSON.stringify(paymentState) } */}
              <p className="experience_title">
                <b>Experience:</b> {reservationRequest.experience.title}
              </p>
              {true && (
                <div className="coupon-form">
                  <div className="coupon-form-inner">
                    <div className="input-group coupon-input-group">
                      <input
                        type="text"
                        name="coupon"
                        id="Coupon"
                        value={reservationRequest.coupon ? reservationRequest.coupon : ''}
                        onChange={e => {
                          e.preventDefault()
                          const coupon = e.target.value ? e.target.value : null
                          console.log('Coupon code is ' + coupon)
                          reservationActions.setCouponCode({
                            coupon: coupon,
                            old_coupon: reservationRequest.coupon
                          })
                        }}
                        readOnly= {reservationRequest.couponInfo}
                        className="input-group__field coupon_input"
                        placeholder="Enter Coupon Code"
                        autoCorrect="off"
                      />
                      { isLoadingCouponData ? (
                          <div className="spinner spinner__override" />
                      ) : ''}
                      {reservationRequest.couponInfo ? (
                        <span className="input-group__btn">
                          <button
                            type="button"
                            className="btn btn--darker coupon-clear"
                            name="clear_coupon"
                            onClick={e => {
                              e.preventDefault();
                              clearCoupon()
                            }}
                            id="clear_applied_coupon"
                          >
                            <span className="coupon-clear-text--large">Clear</span>
                          </button>
                        </span>
                      ) : (
                        <span className="input-group__btn">
                          <button
                            type="button"
                            disabled={!reservationRequest.coupon}
                            onClick={e => {
                              e.preventDefault();
                              applyCoupon(reservationRequest.coupon)
                            }}
                            className="btn btn-outline-secondary coupon-apply"
                            name="apply"
                            id="coupon_apply"
                          >
                            <span className="coupon-apply-text--large">Apply</span>
                          </button>
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              )}
              { reservationRequest.couponInfo ? (
                <React.Fragment>
                  <div className="success">
                    <span className="coupon_success_span">
                      <img
                        src={successIcon}
                        style={{ height: '18px', paddingRight: '10px', marginTop: '12px' }}
                      />
                      <p className="successMessage">{reservationRequest.couponInfo}</p>
                    </span>
                  </div>
                </React.Fragment>
              ) : '' }
              { reservationRequest.couponError ? (
                <React.Fragment>
                  <div className="error">
                    <span className="coupon_error_span">
                      <img
                        src={errorIcon}
                        style={{ height: '18px', paddingRight: '10px', marginTop: '12px' }}
                      />
                      <p>{reservationRequest.couponError}</p>
                    </span>
                  </div>
                </React.Fragment>
              ) : '' }
              <p className="net_amount">
                <b>Net Total: </b>
                {`${reservationRequest.experience.currency.value} ${netTotal}`} ({`${reservationRequest.seats} ${reservationRequest.seats > 1 ? 'seats' : 'seat'}`})
                {/* <small style={{color: 'darkslategray'}}>(inclusive of VAT)</small> */}
              </p>
              {!reservationRequest.experience.instant ? (
                <p className="text-center">
                  {/* inclusive of VAT<br /> */}
                  <small>
                    <i>
                      Charge will occur only when the host confirms the reservation request.
                    </i>
                  </small>
                </p>
              ) : ('')}
            </div>
          )}
          { (netTotal > 0.0) && (
            <div className="payment-outer">
              {isLoadingCardData && <div className="spinner" />}

              {!isLoadingCardData && !isPaymentInputEnabled && (
                <div className="credit-card__list">
                  <ShowSavedCard changeCard={selectSavedCard} paymentMethod={savedCard} isChecked={selectedPaymentMethod && savedCard && savedCard.id === selectedPaymentMethod.id} />
                  <ShowSavedCard changeCard={initiateNewCardInput} title="Change Card" isChecked={false} />
                </div>
              )}
              {!isLoadingCardData && isPaymentInputEnabled && (
                <SetupNewCardComponent fontSize={elementFontSize} errors={paymentInputErrors} handleError={handlePaymentInputError} />
              )}
            </div>
          )}
          <p className="text-center">
            <small>
              By sending the reservation request, you agree to <br />BreakBread’s{' '}
              <a target="_blank" href="/terms-and-conditions">
                Terms &amp; Conditions
              </a>{' '}
              and{' '}
              <a target="_blank" href="/privacy-policy">
                Privacy Policy
              </a>
            </small>
          </p>
        </div>
        <div className="form-footer">
          <button
            type="button"
            className="btn form-footer--right"
            onClick={handleConfirmReservation}
            disabled={!isPaymentButtonEnabled || doInputErrorsExist}
          >
            Confirm
          </button>
          <button
            type="button"
            className="btn btn--darker form-footer--left"
            onClick={dynamicBackButtonAction}
          >
            {dynamicBackButtonContent}
          </button>
        </div>
      </React.Fragment>
    )
  }
}

PaymentConfirmation.defaultProps = {
  reservationRequest: {
    experience: null
  }
}

PaymentConfirmation.propTypes = {
  reservationRequest: PropTypes.shape({
    experience: PropTypes.shape({
      title: PropTypes.string,
      currency: PropTypes.shape({
        value: PropTypes.string
      }),
      price: PropTypes.number
    }),
    seats: PropTypes.number
  }),
  modalActions: PropTypes.shape({
    hideReservationModal: PropTypes.func
  }).isRequired,
  reservationActions: PropTypes.shape({
    bookExperience: PropTypes.func.isRequired,
    setCouponCode: PropTypes.func.isRequired
  }).isRequired,
  elements: PropTypes.shape({
    getElement: PropTypes.func.isRequired
  }).isRequired,
  stripe: PropTypes.shape({
    confirmCardSetup: PropTypes.func.isRequired
  }).isRequired,
  paymentState: PropTypes.shape({
    savedCard: PropTypes.shape({
      exp_month: PropTypes.number,
      exp_year: PropTypes.number,
      last4: PropTypes.string,
      brand: PropTypes.string,
      id: PropTypes.string.isRequired
    }),
    setupIntent: PropTypes.string
  }).isRequired,
  currentUser: PropTypes.shape({
    email: PropTypes.string,
    id: PropTypes.number
  }).isRequired,
  paymentActions: PropTypes.shape({
    fetchSavedCard: PropTypes.func.isRequired,
    setSavedCard: PropTypes.func.isRequired,
    createOrderId: PropTypes.func.isRequired,
    createSetupIntent: PropTypes.func.isRequired,
    handleSetupIntentFailure: PropTypes.func.isRequired
  }).isRequired
}

const mapStateToProps = state => ({
  currentUser: state.reduxTokenAuth.currentUser,
  paymentState: state.transactions.payments.made,
  reservationRequest: state.reservationRequest
})

const mapDispatchToProps = dispatch => ({
  reservationActions: bindActionCreators(reservationActionCreators, dispatch),
  modalActions: bindActionCreators(modalActionCreators, dispatch),
  paymentActions: bindActionCreators(paymentActionCreators, dispatch)
})

// You have to connect() to any reducers that you wish to connect to yourself
const connectedPaymentConfirmation = connect(
  mapStateToProps,
  mapDispatchToProps
)(injectStripe(PaymentConfirmation))

export default connectedPaymentConfirmation
