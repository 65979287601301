import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'

/* Constants */
import { currentUserPropTypes } from '../../../constants/composed-proptypes'

/* Action Creators */
import * as reservationActionCreators from '../../../actions/reservation'
import * as modalActionCreators from '../../../actions/modals'

/* Components */
import PaymentCard from '../../CreditCard/PaymentCard'

/* Form Validation */
import validate from './validate'

const PaymentConfirmation = ({
  paymentMethod,
  reservationRequest,
  reservationActions,
  modalActions,
  previousPage
}) => (
  <React.Fragment>
    <div className="form-body">
      <div className="form-header">
        <h4>Current Payment Method</h4>
      </div>

      <PaymentCard paymentMethod={paymentMethod} changeCard={previousPage} />
    </div>
  </React.Fragment>
)

PaymentConfirmation.propTypes = {
  paymentMethod: PropTypes.shape({}).isRequired,
  previousPage: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  currentUser: state.reduxTokenAuth.currentUser,
  paymentMethod: state.paymentMethods.paymentMethod
})

const mapDispatchToProps = dispatch => ({
  modalActions: bindActionCreators(modalActionCreators, dispatch)
})

// You have to connect() to any reducers that you wish to connect to yourself
const connectedPaymentConfirmation = connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentConfirmation)

export default connectedPaymentConfirmation
