import React from "react"

const Gluten = props => (
  <svg viewBox="0 0 88 88" width="1em" height="1em" {...props}>
    <g fill="currentColor" data-name="Layer 2">
      <path d="M47 36a6.1 6.1 0 0 0-2-4.44V29a1 1 0 0 0-2 0v2.56A6.1 6.1 0 0 0 41 36a6.19 6.19 0 0 0 2.37 4.78 1 1 0 0 0 1.26 0A6.19 6.19 0 0 0 47 36zm-3 2.61a3.91 3.91 0 0 1 0-5.22 3.91 3.91 0 0 1 0 5.22zM52.29 52.29l-.72.72a6.87 6.87 0 0 0-4.69 1.49A5.07 5.07 0 0 0 45 59.1a1 1 0 0 0 1 .9h.74a6.82 6.82 0 0 0 4.43-1.5A5.07 5.07 0 0 0 53 54.42l.72-.71a1 1 0 0 0-1.42-1.42zM49.84 57A4.79 4.79 0 0 1 47 58a3.1 3.1 0 0 1 1.13-2A4.79 4.79 0 0 1 51 55a3.1 3.1 0 0 1-1.16 2zM52.29 45.29l-.71.72a6.88 6.88 0 0 0-4.7 1.49A5.07 5.07 0 0 0 45 52.1a1 1 0 0 0 1 .9h.74a6.82 6.82 0 0 0 4.43-1.5A5.07 5.07 0 0 0 53 47.42l.72-.71a1 1 0 0 0-1.42-1.42zM49.84 50A4.79 4.79 0 0 1 47 51a3.1 3.1 0 0 1 1.13-2A4.79 4.79 0 0 1 51 48a3.1 3.1 0 0 1-1.16 2zM41.12 54.5a6.88 6.88 0 0 0-4.69-1.5l-.72-.72a1 1 0 0 0-1.42 1.42l.72.71a5.07 5.07 0 0 0 1.87 4.08A6.82 6.82 0 0 0 41.31 60h.74a1 1 0 0 0 .95-.9 5.07 5.07 0 0 0-1.88-4.6zm-3 2.47A3.1 3.1 0 0 1 37 55a4.79 4.79 0 0 1 2.81 1A3.1 3.1 0 0 1 41 58a4.79 4.79 0 0 1-2.84-1zM41.12 47.5a6.89 6.89 0 0 0-4.7-1.5l-.71-.72a1 1 0 0 0-1.42 1.42l.72.71a5.07 5.07 0 0 0 1.87 4.08A6.82 6.82 0 0 0 41.31 53h.74a1 1 0 0 0 .95-.9 5.07 5.07 0 0 0-1.88-4.6zm-3 2.47A3.1 3.1 0 0 1 37 48a4.79 4.79 0 0 1 2.81 1A3.1 3.1 0 0 1 41 51a4.79 4.79 0 0 1-2.84-1zM41.12 40.5a6.87 6.87 0 0 0-4.64-1.5 2.36 2.36 0 0 1-.48-1.41V31a1 1 0 0 0-2 0v6.59a4.45 4.45 0 0 0 1 2.77 5.05 5.05 0 0 0 1.87 4.14 6.82 6.82 0 0 0 4.44 1.5h.74a1 1 0 0 0 .95-.9 5.07 5.07 0 0 0-1.88-4.6zm-3 2.47A3.1 3.1 0 0 1 37 41a4.79 4.79 0 0 1 2.81 1A3.1 3.1 0 0 1 41 44a4.79 4.79 0 0 1-2.84-1zM53 30a1 1 0 0 0-1 1v6.59a2.42 2.42 0 0 1-.47 1.41 6.86 6.86 0 0 0-4.65 1.49A5.07 5.07 0 0 0 45 45.1a1 1 0 0 0 1 .9h.74a6.82 6.82 0 0 0 4.43-1.5A5.05 5.05 0 0 0 53 40.36a4.45 4.45 0 0 0 1-2.77V31a1 1 0 0 0-1-1zm-3.16 13A4.79 4.79 0 0 1 47 44a3.1 3.1 0 0 1 1.13-2A4.79 4.79 0 0 1 51 41a3.1 3.1 0 0 1-1.16 2z" />
      <path d="M49 37a1 1 0 0 0 1-1v-6a1 1 0 0 0-2 0v6a1 1 0 0 0 1 1zM39 37a1 1 0 0 0 1-1v-6a1 1 0 0 0-2 0v6a1 1 0 0 0 1 1z" />
      <path d="M44 88a44 44 0 1 1 44-44 44.05 44.05 0 0 1-44 44zm0-86a42 42 0 1 0 42 42A42 42 0 0 0 44 2z" />
    </g>
  </svg>
)

export default Gluten
