import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import _ from 'lodash'
// import { Parallax, Background } from 'react-parallax';

export default class ThumbLink extends Component {
  componentDidMount() {}

  render() {
    const { link, linkTarget, image, title, desc, pre, children } = this.props

    const url = _.replace(_.replace(image, '(', '%28'), ')', '%29')

    return (
      <div
        className={`breakbread--thumb-link ${
          !image.includes('/thumb_default.png') ? '' : 'expanded'
        }`}
        data-wow-offset={20}
        style={{ backgroundImage: `url(${url})` }}
      >
        {link &&
          linkTarget && (
          <Link
            className="breakbread--thumb-link__link"
            to={link}
            target={linkTarget}
          />
        )}

        {link &&
          !linkTarget && (
          <Link className="breakbread--thumb-link__link" to={link} />
        )}
        <div className="breakbread--thumb-link__inner">
          <div className="breakbread--thumb-link__overlay" />
          <div className="text-center">
            <small>{pre}</small>
            <h4 className="breakbread--thumb-link__title">{title}</h4>
            <div className="breakbread--thumb-link__desc">
              <p>{desc}</p>
              {children}
            </div>
          </div>
        </div>
      </div>
    )
  }
}
