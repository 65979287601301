import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { showGuestReviewModal } from '../../../actions/modals'
import { fetchReservationsMade } from '../../../actions/transactions'

const GuestReviewLink = ({
  className,
  children,
  showModal,
  reservation,
  callbackParams,
  fetchReservations,
  getReservations
}) => (
  <button
    type="button"
    className={`${className}`}
    onClick={() =>
      showModal({
        show: true,
        reservation,
        modalCallBack: () => {
          console.log('under callback=====')
          // This gets added to Modal object in Redux as a prop. Can then be used
          // to send the user to a previous state, next state and/or close the modal.
          // console.log('CALLBACK');
          getReservations()
          fetchReservations(callbackParams)
        }
      })
    }
  >
    {children}
  </button>
)

const mapStateToProps = state => ({
  currentUser: state.reduxTokenAuth.currentUser
})

const mapDispatchToProps = dispatch => ({
  showModal: state => dispatch(showGuestReviewModal(state)),
  hideModal: state => dispatch(showGuestReviewModal(state)),
  fetchReservations: params => dispatch(fetchReservationsMade(params))
})
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GuestReviewLink)
