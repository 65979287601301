import React from "react"

const IconCheck = (props) => (
  <svg
    width="24"
    aria-hidden="true"
    className="icon icon-check"
    {...props}
    height="24"
    viewBox="0 0 24 24"
  >
    <path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z" />
  </svg>
)

export default IconCheck
