import React, { Component } from 'react'
import { connect } from 'react-redux'
import SimpleReactValidator from 'simple-react-validator'
import alertModal from '../../utils/alert'
/* Actions */
import { verifyToken, changePassword } from '../../actions/forgotPassword'
import SEO from '../Shared/Web/SEO'

const validate = (password) => {
  if (password != undefined) {
    let length = password.length >= 8 ? true : false
    let isCapitalcase = false
    let isLowercase = false
    let isNumber = false
    for (let key in password) {
      let ch = password[key]
      if (ch >= 0 && ch <= 9) {
        isNumber = true
      } else {
        if (ch.charCodeAt() >= 65 && ch.charCodeAt() <= 90) {
          isCapitalcase = true
        } else {
          if (ch.charCodeAt() >= 97 && ch.charCodeAt() <= 122) {
            isLowercase = true
          }
        }
      }
    }

    return (length && isCapitalcase && isLowercase && isNumber)
  }
  else {
    return false
  }
}

class ChangePassword extends Component {

  constructor() {
    super()
    this.state = {
      confirm_password: '',
      current_password: '',
      password: '',
      token: "",
      message: "",
      passwordChange: false
    }
    //this.validator = new SimpleReactValidator();
    this.validator = new SimpleReactValidator({
      validators: {
        password: {  // name the rule
          message: 'Your password must be at least 8 characters long, contain at least one number, one lowercase & uppercase letter!',
          rule: (val, params, validator) => {
            return validate(val) && params.indexOf(val) === -1
          },
          messageReplace: (message, params) => message.replace(':values', this.helpers.toSentence(params)),  // optional
          required: true  // optional
        }
      }
    })

  }

    componentDidMount = async () => {

      let token = this.props.match.params.token
      try {
        let res = await this.props.verifyToken(token)
        if (res) {
          this.setState({ token })
        } else {
          //  this.props.history.push('/404');
        }
      } catch (err) {
        //this.props.history.push('/404');

        //console.log("tokenerr",err);
      }
    }

    changePassword = async () => {

      if (this.validator.allValid()) {

        let { password, confirm_password, token, current_password } = this.state

        let passwordDeta = { password, confirm_password, current_password }

        if (confirm_password == password) {
          try {


            let res = await this.props.changePassword(passwordDeta)
            if (res.is_success) {

              this.setState({
                confirm_password: '',
                current_password: "",
                password: '',
                message: "",
                passwordChange: true
              })

              alertModal(res.message, 'success', 'Close')
              setTimeout(() => { this.props.history.push('/') }, 3000)
            } else {
              this.setState({
                confirm_password: '',
                current_password: "",
                password: '',
                message: "",
                passwordChange: false
              })
              alertModal(res.error_message, 'error', 'Close')
            }
          } catch (err) {

            this.setState({
              confirm_password: '',
              current_password: "",
              password: '',
              message: "There is some error please try again.",
              passwordChange: false
            })

          }
        } else {
          this.setState({
            confirm_password: '',
            // current_password:"",
            // password: '',
            message: "Password and confirm password should be same.",
            passwordChange: false
          })

        }


      } else {
        this.validator.showMessages()
        // rerender to show messages for the first time
        // you can use the autoForceUpdate option to do this automatically`
        this.forceUpdate()
      }


    }

    render() {
      let {
        confirm_password, password, message, passwordChange, current_password } = this.state
      return (
        <div className="experience-single fadeIn" >
          <SEO title="Change Password | Breakbread" description="Change Password | Breakbread" />
          <div className="breakbread-container" style={{ marginTop: "5%", marginBottom: "5%" }}>
            <figure>
              <div className="breakbread-component-text" >
                <h1 className="h1 mega-title">Change Password</h1>
                <span className={passwordChange ? "successMessage" : "errorMessage"} > {message}</span>
              </div>

            </figure>

            <div className="grid grid--uniform">
              <div className="grid__item form_grid__item--input  ">
                {/*/password}
                {//fields.renderField*/}

                <input  type="password" className="bb-input-field halfWidthInput" style={{}} value={current_password} name="current_password" onChange={(e) => {
                  this.setState({ current_password: e.target.value })
                }} placeholder="Enter Current Password" />
                {/* <br/> */}
              </div>
                {this.validator.message('current_password', current_password, 'required', { messages: { default: "Enter your current password." }, className: 'form-error' })}

            </div>
            <div className="grid grid--uniform">
              <div className="grid__item form_grid__item--input  ">
                {/*/password}
                {//fields.renderField*/}

                <input type="password" className="bb-input-field halfWidthInput" style={{}} value={password} name="password" onChange={(e) => {
                  this.setState({ password: e.target.value })
                }} placeholder="Enter New Password" />
               {/*  <br/> */}
              </div>
                {this.validator.message('password', password, 'password', { messages: { default: "Your password must be at least 8 characters long, contain at least one number, one lowercase & uppercase letter." }, className: 'form-error' })}

            </div>

            <div className="grid grid--uniform">

              <div className="grid__item form_grid__item--input ">
                <input type="password" className="bb-input-field halfWidthInput" style={{}} value={confirm_password} name="confirm_password" placeholder="Retype Password" onChange={(e) => {
                  this.setState({ confirm_password: e.target.value })
                }} />
                {/* <br/> */}
              </div>
                {this.validator.message('confirm_password', confirm_password, 'password', { messages: { default: "Retype the new password" }, className: 'form-error' })}
            </div>

            <div className="grid grid--uniform">

              <div className="grid__item form_grid__item--input ">
                <button type="submit" form="auth" value="Submit" className="btn minWidth200" onClick={() => { this.changePassword() }} style={{ marginBottom: "10px" }}>Submit</button>
              </div>
            </div>
          </div>
        </div>
      )
    }
}


const mapStateToProps = state => ({
  forgotPassword: state.forgotPassword,
  currentUser: state.reduxTokenAuth.currentUser
})

const mapDispatchToProps = dispatch => ({

  verifyToken: state => dispatch(verifyToken(state)),
  changePassword: state => dispatch(changePassword(state))

})
export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword)

//export default ResetPassword;