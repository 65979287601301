import api from '../../utils/api'
import { SET_MY_HOST_REVIEWS,SET_MY_DINERS_REVIEWS } from '../../constants/actionTypes'
import alertModal from '../../utils/alert'
import { getReservations, getConfirmedReservations } from './other'
import { normalizeReviews } from '../../utils/normalization'

export function setMyHostReviews(reviews) {
  return {
    type: SET_MY_HOST_REVIEWS,
    payload: reviews
  }
}

export function setMyDinersReviews(reviews) {
  return {
    type: SET_MY_DINERS_REVIEWS,
    payload: reviews
  }
}



export function getMyReviews(type) {
  // console.log('HOST ID ', hostId);
  /* eslint-disable-next-line no-unused-vars */
  return async (dispatch, getState) => {
    try {
      const response = await api.get(`/hosts/${type?type:'reviews_by_me'}`)
      dispatch(setMyHostReviews(normalizeReviews(response.data.reviews)))
    } catch (err) {
      console.log(err)
      throw err
    }
  }
}


export function getHostReviews(id) {
  // console.log('HOST ID ', hostId);
  /* eslint-disable-next-line no-unused-vars */
  return async (dispatch, getState) => {
    try {
      const response = await api.get(`/hosts/${id}/reviews`)
      // const response = await api.get(`/hosts/112/reviews`);
      //console.log("review response",response);
      if(response.data.is_success){
        dispatch(setMyHostReviews(response.data.guest_reviews))
      }
     
    } catch (err) {
      console.log(err)
      throw err
    }
  }
}



export function getDinerReviews(id) {
  /* eslint-disable-next-line no-unused-vars */
  return async (dispatch, getState) => {
    try {
      const response = await api.get(`/users/${id}/reviews`)
      //const response = await api.get(`/users/57/reviews`);
      //console.log("review response",response);
      if(response.data.is_success){
        dispatch(setMyDinersReviews(response.data.host_reviews))
      }
     
    } catch (err) {
      console.log(err)
      throw err
    }
  }
}


export function reservationHostReview(props) {
  return async (dispatch, getState) => {
    const { reservation } = props

    if (typeof reservation === 'undefined') {
      return false
    }
    

    // eslint-disable-next-line no-useless-catch
    try {
      // const response = await api.post(
      //   `/reservations/${reservation.id}/create_host_review`,
      //   {
      //     review: props
      //   }
      // )

      const response = await api.post(`/hosts/reviews`, {
        ...props,
        reservation_id: reservation.id
      })
      if (response.data.is_success) {
        alertModal('Great! Your review was saved successfully', 'success', 'Continue')
        // dispatch(getConfirmedReservations(reservation.experience.id))
        // dispatch(getReservations(experienceId));
      } else {
        dispatch(getConfirmedReservations(reservation.experience.id))
      }
    } catch (err) {
      // alertModal(err.message, 'error', 'Close');
      throw err
    }
  }
}
