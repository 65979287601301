// Packages
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Link } from 'react-router-dom'
import axios from 'axios'
import _ from 'lodash'
import PropTypes from 'prop-types'
import moment from 'moment'

// Components
import HomeExperiences from './HomeExperiences'
import ImageWithOverlaySquare from '../../Shared/Web/ImageWithOverlaySquare'
import ValueProp from '../../Shared/Web/ValueProp'

import CMSSection from '../../CMS/Section'
import RegLink from '../../Registration/Users/RegLink'

// Sections
import BackgroundSection from '../../Sections/BackgroundSection'
import ChefOrHostCard from '../../Chef/ChefOrHostCard'
import ImageCarousel from '../../ImageCarousel/ImageCarousel'
import SEO from '../../Shared/Web/SEO'
import HostSlider from '../../Shared/Web/HostSlider'

// Utils
import { getFeatured } from '../../../actions/featured'
import { setFilter } from '../../../actions/experience'
import newsletterSignup from '../../../actions/newsletter'
import { trackCTAClick } from '../../../utils/tracking'
import * as modalActionCreators from '../../../actions/modals'
import * as hostActionCreators from '../../../actions/host'

// Media
import ValueProp1 from '../../../images/home/explore.jpeg'
import ValueProp2 from '../../../images/home/indulge.jpeg'
import ValueProp3 from '../../../images/home/connect.jpg'
import communityImage from '../../../images/home/join-the-community.jpeg'
import PlatedRisotto from '../../../images/lifestyle/plated-risotto@2x.png'

// Constants
import { COMMON_GRID_SIZE as gridSize, HOST } from '../../../constants'
import staticCmsData from '../../../utils/cmsData.json'
import { snakeToCamel } from '../../../utils/camelSnake'
import SignUpAndSubscribe from '../../Shared/SignUpAndSubscribe'

const CMS_SECTION_API_ENDPOINT = `${HOST}/api/v2`

class HomeScreen extends Component {
  constructor(props) {
    super(props)
    this.state = {
      email: '',
      filter: {
        address: null,
        cuisineTypes: null,
        startDate: moment.utc(new Date()),
        endDate: moment.utc(new Date()).add(1, 'year')
      },
      isLoading: true,
      cmsData: undefined
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    const { filter } = this.state
    const { setFilters, modalActions, hostActions, justSubmitted } = this.props
    setFilters(filter)
    this.fetch()
    this.fetchCmsData({ page: 'homepage', section: 'hero' })
    if (justSubmitted) {
      modalActions.showHostConfirmation()
      hostActions.setHostApplicationStatus(false)
    }
  }

  async onSearch() {
    const { filter } = this.state
    const { setFilters } = this.props
    setFilters(filter)
    this.fetch()
  }

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  setCmsDataToState(cmsData) {
    this.setState({
      cmsData,
      isLoading: false
    })
  }

  signUp = () => {
    const { email } = this.state
    newsletterSignup(email)
  }

  async fetch() {
    const { fetchFeatured } = this.props
    await fetchFeatured()
  }

  async fetchCmsData({ page }) {
    try {
      await axios.get(`${CMS_SECTION_API_ENDPOINT}/cms/pages/${page}`, { mode: 'no-cors' })
        .then((res) => {
          if (res.data.is_success) {
            this.setCmsDataToState(snakeToCamel(res.data.page_info, 4))
          } else {
            const matchingPage = _.find(staticCmsData, { page })
            this.setCmsDataToState(matchingPage)
          }
        })
    } catch (e) {
      const matchingPage = _.find(staticCmsData, { page })
      this.setCmsDataToState(matchingPage)
    }
  }

  render() {
    const { email, cmsData, isLoading } = this.state
    const { featuredExperiences = [], featuredHosts = [], featureGuestReviews = [] } = this.props
    const isMobile = window.matchMedia('(max-width: 1005px)').matches
    const isMobileLocal = window.matchMedia('(max-width: 480px)').matches
    const themeForHero = isMobile ? null : 'light'

    return (
      <React.Fragment>
        <SEO />
        <CMSSection data={cmsData} isLoading={isLoading} sectionName="hero" type="hero" page="homepage" isTop/>

        {isMobileLocal ? (
          ''
        ) : (
          <section className="index-section">
            <div className="grid grid--uniform">
              <div className={gridSize} style={{ marginTop: '-5%' }}>
                <ValueProp image={ValueProp1} title="Meet" desc="Supperclubs embody a friendly and approachable environment, making it a great setting to meet up with friends or get to know new people!" />
              </div>

              <div className={gridSize} style={{ marginTop: '-5%' }}>
                <div className="break-bread--value-prop">
                  <ValueProp image={ValueProp2} title="Eat" desc="A supperclub dining experience is, at heart, all about the immersive culinary experiences that passionate hosts and chefs lead, all in the comfort of their own homes. Enjoy the food!" />
                </div>
              </div>

              <div className={gridSize} style={{ marginTop: '-5%' }}>
                <ValueProp image={ValueProp3} title="Connect" desc="The cosy atmosphere of supperclubs allow for more dynamic conversations about life, food and culture, and more! Connect more with your loved ones or with new people!" />
              </div>
            </div>
          </section>
        )}

        {featuredExperiences && featuredExperiences.length && featuredExperiences.length > 0 ? (
          <section className="index-section" style={{ position: 'relative', background: 'white', paddingTop: '0px', paddingBottom: '0px' }}>
            {typeof featuredExperiences !== 'undefined' && featuredExperiences.length > 0 && (
              <React.Fragment>
                <div className="section-header text-center" style={{ paddingTop: '0px', marginBottom: '1rem' }}>
                  <h2 style={{ padding: '0', paddingTop: '0.7rem' }}> Now Available </h2>
                </div>
                <HomeExperiences exactMobile={isMobileLocal} featuredExperiences={featuredExperiences} />
                <div className="text-center" style={{ paddingBottom: '1rem' }}>
                  <Link to="/experiences" className="btn" onClick={() => trackCTAClick({ buttonCTA: 'Explore all Experiences' })}>
                    Explore the Culinary Experiences
                  </Link>
                </div>
              </React.Fragment>
            )}
          </section>
        ) : (
          ''
        )}

        {featuredHosts && featuredHosts.length > 0 && (
          <BackgroundSection size="x-small" image={PlatedRisotto}>
            <div className="section-header text-center" style={{ marginBottom: '20px' }}>
              <h2 style={{ padding: '0' }}>Meet Our Hosts</h2>
            </div>

            <div className="mega-subtitle">
              <p>BreakBread hosts are talented home or professional chefs who have an exceptional talent in creating live eating experiences.</p>
            </div>
            <div className="grid grid--uniform">
              {typeof featuredHosts === 'undefined' && featuredHosts.length === 0 && <div className="spinner" />}
              { ((!isMobileLocal && featuredHosts.length <= 4) || (isMobileLocal && featuredHosts.length === 1))
                && featuredHosts.map((user) => (
                  <div className={`grid__item ${isMobileLocal ? '' : 'small--one-half'} medium-up--one-quarter large-up--one-quarter hosthomescreen`} key={`ChefOrHostCard-${user.id}`}>
                    <ChefOrHostCard person={user} />
                    {user.description ? (
                      <p>
                        {user.description.length > 150 ? (
                          <React.Fragment>
                            {`${user.description.substring(0, 150)}....`}
                            <Link to={`/host/${user.slug}`}> Read more </Link>
                          </React.Fragment>
                        ) : user.description}
                      </p>
                    ) : null}
                  </div>
                ))}
              { ((!isMobileLocal && featuredHosts.length > 4) || (isMobileLocal && featuredHosts.length > 1))
                && <HostSlider isMobile={isMobileLocal} featuredHosts={featuredHosts} /> }
            </div>
            <Link to="/hosts" className="breakbread-btn breakbread-btn-browseallhost" onClick={() => trackCTAClick({ buttonCTA: 'Browse All Hosts' })}>
              Browse All Hosts
            </Link>
          </BackgroundSection>
        )}

        {featureGuestReviews && featureGuestReviews.length > 0 && (
          <section className="index-section" style={{ position: 'relative', background: 'white' }}>
            <ImageCarousel carouselData={featureGuestReviews} />
          </section>
        )}
        {/* signup and subscribe part */}
        <SignUpAndSubscribe />

      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  featuredExperiences: state.featured.featuredExperiences,
  featuredHosts: state.featured.featuredHosts,
  // featuredReviews: state.featured.featuredReviews,
  featureGuestReviews: state.featured.featureGuestReviews,
  experiences: state.experience.experienceIndex.experiences,
  justSubmitted: state.host.host.hostApplicationStatus
})

HomeScreen.defaultProps = {
  featuredExperiences: [],
  featuredHosts: [],
  featureGuestReviews: []
}

HomeScreen.propTypes = {
  fetchFeatured: PropTypes.func.isRequired,
  featuredExperiences: PropTypes.arrayOf(PropTypes.shape({})),
  featuredHosts: PropTypes.arrayOf(PropTypes.shape({})),
  setFilters: PropTypes.func.isRequired,
  featureGuestReviews: PropTypes.arrayOf(PropTypes.shape({}))
}

const mapDispatchToProps = dispatch => ({
  setFilters: filter => dispatch(setFilter(filter)),
  fetchFeatured: () => dispatch(getFeatured()),
  hostActions: bindActionCreators(hostActionCreators, dispatch),
  modalActions: bindActionCreators(modalActionCreators, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(HomeScreen)
