import React from 'react'
import { Redirect, Route, Switch } from 'react-router'
import { generateRequireSignInWrapper } from 'redux-token-auth'
// Containers
import ComponentsAndElements from '../components/StyleGuides/ComponentsAndElements'

/* Screens */
import ExperienceIndex from '../components/Experiences/Index'
import Hosts from '../components/Host/HostIndex'
import HostProfile from '../components/Host/Profile'
import Experience from '../components/Experiences/Show'
import AuthenticationScreen from '../components/Auth/AuthForm'
/* Marketing Pages */
import HomeScreen from '../components/PagesMarketing/HomeScreen'
import HowItWorks from '../components/PagesMarketing/HowItWorks'
import BecomeAChef from '../components/PagesMarketing/BecomeAChef'
import BecomeAchefStep1 from '../components/PagesMarketing/BecomeAChef/Step1'
import BecomeAchefStep2 from '../components/PagesMarketing/BecomeAChef/Step2'

/* User Dashboard */
import UserExperiences from '../components/User/MyExperiences'
import UserReviews from '../components/User/Reviews'
import UserReviewsCardView from '../components/User/Reviews/UserReviewsCardView'

/* User Dashboard */
import HostExperiences from '../components/Host/MyExperiences'
import MyExperiencesCardView from '../components/Host/MyExperiences/CardExperinceIndex'

import HostReservations from '../components/Host/Reservations'
import ReservationsCardView from '../components/Host/ReservationsCardView'

import DinerReservations from '../components/Diner/Reservations'
import DinerReservationsCardView from '../components/Diner/ReservationCardView'

import ReservationDetail from '../components/Diner/ReservationDetail'
import HostReviews from '../components/Host/Reviews'
import HostReviewsCardView from '../components/Host/Reviews/HostReviewsCardView'

/* Account Settings */
import Wallet from '../components/User/Wallet'

import AccountDetails from '../components/User/Account'
import BankingDetails from '../components/User/BankingDetails'

import PersonalDetails from '../components/User/PersonalDetail'
import ProfessionalDetails from '../components/User/ProfessionalDetails'
import HostingSpaceDetail from '../components/User/HostingSpaceDetails'
import ProfilePhoto from '../components/User/ProfilePhotos'
import CovidBadge from '../components/User/CovidBadge'
import Notifications from '../components/Notifications'
import ResetPassword from '../components/User/ResetPassword'
import ChangePassword from '../components/User/ChangePassword'
import verifyEmail from '../components/User/verifyEmail'
import LegalTermsCondition from '../components/User/LegalTermsCondition'
import notFound from '../components/User/notFound'
import SignIn from '../components/User/SignIn'
import SignUp from '../components/User/SignUp'
import PrivacyPolicy from '../components/User/PrivacyPolicy'
import CookiePolicy from '../components/User/CookiePolicy'
import AboutUs from '../components/PagesInfo/AboutUs'
import RefundPolicy from '../components/PagesInfo/RefundPolicy'
// import seoMeta from '../utils/seo-meta.json'

// blog
import Blog from '../components/Blog/Index'
import BlogDetail from '../components/Blog/BlogDetail'

// coupon
import Coupon from '../components/Coupon/index'

import {
  PrivacyPolicyScreen,
  TermsScreenWithRedux
} from '../components/PagesInfo'

const requireSignIn = generateRequireSignInWrapper({
  redirectPathIfNotSignedIn: '/sign-in'
})

// const requireAuth = component => component
// // if (localStorage.getItem('authCode') === 'LetsBreakBread') {
// // component
// // }
// // return ForbiddenScreen

// const withTitle = ({ Component, seoMeta: _seoData }) => {
//   // let title
//   // let description
//   // if (seo_meta && seo_meta.title && seo_meta.description) {
//   //   title = seo_meta.title
//   //   description = seo_meta.description
//   // }
//   const SEOWithTitle = (props) => (
//     <React.Fragment>
//       {/* <SEO title={title} description={description} /> */}
//       <Component {...props} />
//     </React.Fragment>
//   )
//   return SEOWithTitle
// }

const WebRoutes = () => (
  <Switch>
    <Route
      exact
      path="/"
      component={HomeScreen}
    />
    {/* <Route exact path="/login" component={(AuthenticationScreen)} mega /> */}
    <Route path="/styles" component={ComponentsAndElements} />
    {/* Browse Experiences */}

    <Route
      exact
      path="/experiences"
      component={ExperienceIndex}
    />
    <Route
      path="/experiences/:cities/:cousine"
      component={ExperienceIndex}
    />
    <Route
      path="/experiences/:experienceSlug"
      component={Experience}
      // key={location.href}
    />
    <Route
      path="/how-it-works"
      component={HowItWorks}
    />
    <Route
      exact
      path="/become-a-host"
      component={BecomeAChef}
    />
    <Route
      exact
      path="/become-a-host/step-1"
      component={(requireSignIn(BecomeAchefStep1))}
    />
    <Route
      exact
      path="/become-a-host/step-2"
      component={(requireSignIn(BecomeAchefStep2))}
    />

    {/* Info */}
    <Route
      path="/terms-and-conditions-old"
      component={TermsScreenWithRedux}
    />
    <Route
      path="/privacy-policy-old"
      component={PrivacyPolicyScreen}
    />
    <Route
      path="/refund-policy"
      component={RefundPolicy}
    />
    <Route path="/about-us" component={AboutUs} />

    {/* User's Account */}
    <Route
      path="/users/experiences"
      component={(requireSignIn(UserExperiences))}
    />
    <Route
      path="/users/reviews-old"
      component={(requireSignIn(UserReviews))}
    />
    <Route
      path="/diners/reviews"
      component={(requireSignIn(UserReviewsCardView))}
    />

    {/* Host's Account */}
    <Route
      path="/hosts/experiences-old"
      component={(requireSignIn(HostExperiences))}
    />
    <Route
      path="/hosts/experiences"
      component={(requireSignIn(MyExperiencesCardView))}
    />

    <Route
      exact
      path="/hosts/reservations-old"
      component={(requireSignIn(HostReservations))}
    />
    <Route
      exact
      path="/hosts/reservations"
      component={(requireSignIn(ReservationsCardView))}
    />

    <Route
      exact
      path="/diners/bookings-old"
      component={(requireSignIn(DinerReservations))}
    />

    <Route
      exact
      path="/diners/bookings"
      component={(requireSignIn(DinerReservationsCardView))}
    />

    <Route
      exact
      path="/diners/reservation/:reservationId"
      component={(requireSignIn(ReservationDetail))}
    />

    <Route
      path="/hosts/reservations/:reservationId"
      component={(requireSignIn(ReservationsCardView))}
    />
    <Route
      path="/hosts/reviews-old"
      component={(requireSignIn(HostReviews))}
    />
    <Route
      path="/hosts/reviews"
      component={(requireSignIn(HostReviewsCardView))}
    />

    {/* Account Settings */}
    <Route
      path="/users/wallet"
      component={(requireSignIn(Wallet))}
    />
    <Route
      path="/users/personal_details"
      component={(requireSignIn(PersonalDetails))}
    />
    <Route
      path="/users/bank_account_details"
      component={(requireSignIn(BankingDetails))}
    />

    <Route
      path="/users/hosting_space_detail"
      component={(requireSignIn(HostingSpaceDetail))}
    />

    <Route
      path="/users/profile_photos"
      component={(requireSignIn(ProfilePhoto))}
    />

    <Route
      path="/users/professional_details"
      component={(requireSignIn(ProfessionalDetails))}
    />
    <Route
      path="/users/account"
      component={(requireSignIn(AccountDetails))}
    />
    <Route
      path="/users/covid_badge"
      component={(requireSignIn(CovidBadge))}
    />

    <Route
      path="/notifications"
      component={(requireSignIn(Notifications))}
    />

    {/* Browse Hosts */}

    <Route
      exact
      path="/hosts"
      component={Hosts}
    />
    <Route
      path="/hosts/:cuisine"
      component={Hosts}
    />
    <Route
      path="/hosts/:cuisine/:rating"
      component={Hosts}
    />
    <Route path="/host/:hostSlug" component={HostProfile} />

    {/*
     * OmniAuth
     * We can use this as a catch call for omni auth providers
     * when a call back redirect is made from the API. Make sure you
     * set the origin url params to the appropriate auth_origin_url params
     * when trying to authenticate.
     * @example -  ?auth_origin_url=https://breakbread.dv:3000/omniauth/facebook/success
     */}

    <Route path="/omniauth/:provider/" component={AuthenticationScreen} />
    {/* Reset Password */}
    <Route path="/reset-password/:token/" component={ResetPassword} />
    <Route
      path="/users/change_password"
      component={(requireSignIn(ChangePassword))}
    />
    <Route
      path="/sign-in"
      component={SignIn}
    />
    <Route
      path="/sign-up"
      component={SignUp}
    />
    <Route path="/verify-email/:token/" component={verifyEmail} />
    <Route path="/404" component={notFound} />
    <Route path="/terms-and-conditions" component={LegalTermsCondition} />
    <Route path="/privacy-policy" component={PrivacyPolicy} />
    <Route path="/cookie-policy" component={CookiePolicy} />
    <Route
      exact
      path="/blog"
      component={Blog}
    />
    <Route
      exact
      path="/blog/:slug"
      component={BlogDetail}
    />
    <Route exact path="/my_coupons" component={requireSignIn(Coupon)} />
    <Redirect to="/404" />
  </Switch>
)

export default WebRoutes
