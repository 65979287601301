import React, { Component } from 'react'
import { Field, FieldArray } from 'redux-form'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import * as fields from '../../Fields/formFields'
import renderMenuItemField from '../../Fields/renderMenuItemField'
import CheckboxGroup from '../../Fields/renderCheckBoxGroup'
import { SPLIT_GRID_SIZE } from '../../../../constants'
import _ from 'lodash'
import {
  professionalFieldCollection,
  menuItemFieldCollection
} from '../../../../constants/formFields'

/* eslint-disable-next-line no-unused-vars */
const renderError = ({ meta: { touched, error } }) => {
  /* eslint-disable-next-line no-unused-expressions */
  touched && error ? <span>{error}</span> : false
}

class Menu extends Component {
  constructor(props) {
    super(props)
    this.state = {
      professionalFields: professionalFieldCollection,
      menuItemFields: menuItemFieldCollection,
      localFlag: false
    }
  }

  
  componentDidUpdate() {
    const { currentExperienceMenu } = this.props
    if (currentExperienceMenu && !this.state.localFlag) {
      this.setState({localFlag: true})
      this.props.change('menuAttributes.menuItemsAttributes', currentExperienceMenu.menu.menu_items)
    }
  }

  render() {
    const {
      handleSubmit,
      pristine,
      valid,
      submitting,
      previousPage,
      form,
      formState,
      initialValues,
      array,
      formTitle,
      buttonText,
      allExperiences,
      cuisineTypesOptions,
      menuAllergenOptions,
      mealCategoriesOptions,
      menuCoursesOptions,
      userActions,
      isUpdating,
      menuFIleArray
    } = this.props

    const { professionalFields, menuItemFields } = this.state
    const { cuisine } = professionalFields
    // console.log('cuisine.options', cuisine.options)
    const { allergens, mealCategories } = menuItemFields
    const { menuItems } = initialValues.menuAttributes.menuItemsAttributes
    const localCuisineTypes = _.sortBy(cuisineTypesOptions?cuisineTypesOptions.filter(el=>el.status === 'active').map(el=>({label: el.name, value: el.name, id: el.id})):[], 'label')
    const localMenuAllergens = _.sortBy(menuAllergenOptions?menuAllergenOptions.filter(el=>el.status === 'active').map(el=>({label: el.name, value: el.name, id: el.id})):[], 'label')
    const localMealCategories = _.sortBy(mealCategoriesOptions?mealCategoriesOptions.filter(el=>el.status === 'active').map(el=>({label: el.name, value: el.name, id: el.id})):[], 'label')
    const localMenuCoursesOptions = menuCoursesOptions?menuCoursesOptions.filter(el=>el.status === 'active').map(el=>({label: el.name, value: el.name, id: el.id})):[]

    const menuItemsArr = formState.experienceEdit && formState.experienceEdit.values
      ? formState.experienceEdit.values.menuAttributes.menuItemsAttributes
      : formState.experienceCreate && formState.experienceCreate.values
        ? formState.experienceCreate.values.menuAttributes.menuItemsAttributes
        : []

    let menuItemValid = false
    // console.log('menuItemsArr', menuItemsArr, menuFIleArray)
    if (!menuItemsArr) {
      menuItemValid = true
    }
    if (menuItemsArr) {
      if (menuItemsArr.length < 1) {
        menuItemValid = true
      }
      if (menuItemsArr.length > 0) {
        menuItemsArr.forEach((element, index) => {
          if (!element.title || !element.description || !element.course || (!menuFIleArray[index] && (!element.menu_image_urls || (element.menu_image_urls && !element.menu_image_urls.big)))) {
            menuItemValid = true
          }
        })
      }
    }
    return (
      <form onSubmit={handleSubmit} className="">
        <div className="form-header">
          <h4>{formTitle}</h4>
        </div>
        <div className="form-body form-body--full">
          <div className="grid--uniform">
            {/*  <div className="grid__item medium-up--full"> */}
            <div>
              <Field
                name="cuisineTypesAttributes"
                component={fields.renderSelectField}
                // disabled={submitting}
                options={localCuisineTypes}
                placeholder={cuisine.placeholder}
                isClearable
                isMulti
                type="select"
                selectType="cuisine"
                label="Cuisine"
                displayLabel
              />
              <Field
                name="menuAttributes.menuAllergensAttributes"
                component={fields.renderSelectField}
                disabled={submitting}
                options={localMenuAllergens}
                placeholder={allergens.placeholder}
                isClearable
                isMulti
                type="select"
                selectType="allergens"
                label="Let your guests know if your meal contains any food allergens"
                displayLabel
              />
              
              <CheckboxGroup
                name="menuAttributes.mealCategories"
                label="Check any that apply to your menu"
                options={localMealCategories}
              />
              <hr />
              <div className="grid__item menu-items">
                <FieldArray
                  form={form}
                  name="menuAttributes.menuItemsAttributes"
                  component={renderMenuItemField}
                  userActions={userActions}
                  localMenuCoursesOptions={localMenuCoursesOptions}
                  props={{
                    data: menuItems,
                    compareKey: 'id',
                    array
                  }}
                />
              </div>
            </div>
          </div>
          {/* <div className="grid__item small--one-whole medium-down--one-half medium-up--one-half allergiesMenu">
              <Field
                name="menuAttributes.menuAllergensAttributes"
                component={fields.renderSelectField}
                disabled={submitting}
                options={allergens.options}
                placeholder={allergens.placeholder}
                isClearable
                isMulti
                type="select"
                selectType="allergens"
                label="Let your guests know if your meal contains any food allergens"
                displayLabel
              />

              <hr />
              <CheckboxGroup
                name="menuAttributes.mealCategories"
                label="Check any that apply to your menu"
                options={mealCategories.options}
              />
            </div> */}
          {/*  </div> */}
        </div>
        <div className="form-footer">
          <button
            type="button"
            disabled={submitting}
            className="btn btn--link form-footer--left"
            onClick={() => previousPage()}
          >
            Go Back a Step
          </button>
          {this.props.form != 'experienceCreate' ? (
            <button
              type="submit"
              disabled={isUpdating || menuItemValid}
              className="btn btn--default form-footer--right"
            >
              {/* buttonText || */ 'Save And Continue'}
            </button>
          ) : (
            <button
              type="submit"
              disabled={submitting || pristine || isUpdating || menuItemValid}
              className="btn btn--default minWidth200 form-footer--right"
            >
              {/* buttonText || */ 'Save And Continue'}
            </button>
          )}
        </div>
      </form>
    )
  }
}

Menu.propTypes = {
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  valid: PropTypes.bool.isRequired,
  previousPage: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired
}


export default connect(state => ({
  formState: state.form,
  menuFIleArray: state.user.fileUploads.menuFIleArray
    ? [...state.user.fileUploads.menuFIleArray]
    : []
}))(Menu)
