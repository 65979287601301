import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Link } from 'react-router-dom'
import axios from 'axios'
import firebase from 'firebase/app'
import 'firebase/auth'

/* Actions */
// import { show } from 'react-notification-system-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import * as modalActionCreators from '../../actions/modals'
// import * as signUpActionCreators from '../../actions/signUp'

import * as userActionCreators from '../../actions/user'
import * as hostActionCreators from '../../actions/host'
import { setForgotPass, forgetPassword } from '../../actions/forgotPassword'

import { signInUser, registerUser } from '../../auth/redux-token-auth-config'

import { trackSignIn, trackGetStartedHostApplication } from '../../utils/tracking'
import alertModal from '../../utils/alert'
import { currentUserPropTypes } from '../../constants/composed-proptypes'
import { FIREBASE_API_KEY, FIREBASE_AUTH_DOMAIN, FIREBASE_PROJECT_ID } from '../../constants'
// OAuth
// import OAuthButtonContainer from '../OmniAuth/OauthButtonContainer'
import { history } from '../../configureStore'

const config = {
  firebase_api_key: FIREBASE_API_KEY,
  firebase_auth_domain: FIREBASE_AUTH_DOMAIN,
  firebase_project_id: FIREBASE_PROJECT_ID
}
const eye = <FontAwesomeIcon icon={faEye} />
const eyeSlash = <FontAwesomeIcon icon={faEyeSlash} />

const fetchFacebookUserInfo = ({ token }) => axios
  .get('https://graph.facebook.com/v2.12/me?fields=name,email,birthday,picture.width(720).height(720),location', {
    headers: { authorization: `Bearer ${token}` }
  })
  .then(({ data }) => {
    const name = data.name.split(' ')
    const payload = {
      // id: data.id,
      firstName: name[0],
      lastName: name[1],
      email: data.email,
      // dateOfBirth: new Date(data.birthday),
      socialImage: data.picture.data.url,
      // password: 'socialUser',
      phoneMetaData: { dialCode: null, iso2: null, name: null }
    }
    return payload
  })
class AuthForm extends Component {
  constructor() {
    super()
    this.state = {
      email: '',
      password: '',
      showPassword: false
      // firebaseapp: null
    }
  }

  componentWillMount() {
    this.handleChange = this.handleChange.bind(this)
    this.handleSignIn = this.handleSignIn.bind(this)
    this.handleSocialLogin = this.handleSocialLogin.bind(this)
  }

  componentDidMount = async () => {
    const { userActions } = this.props
    await userActions.disableLogin(true)
  }

  componentDidUpdate(prevProps, _prevState, _snapshot) {
    const { currentUser, successAction } = this.props
    if (currentUser.isSignedIn !== prevProps.currentUser.isSignedIn) {
      // This after action is passed to the Authform as a prop.
      successAction() // Callback to redirect or open another Modal
      if (history) history.push('/')
    }
  }

  handleChange(event) {
    const { checked, value, type, name } = event.target
    const formedValue = type === 'checkbox' ? checked : value
    this.setState({
      [name]: formedValue
    })
  }

  handleSocialLogin($event, type) {
    $event.preventDefault(false)
    // console.log('type', type)
    switch (type) {
    case 'facebook':
      this.commonSocialLogin({ provider: 'facebook' })
      break
    case 'twitter':
      this.commonSocialLogin({ provider: 'twitter' })
      break
    case 'google':
      this.commonSocialLogin({ provider: 'google' })
      break
    default:
      break
    }
  }

  async handleForgetPassword(event) {
    event.preventDefault()
    const { email } = this.state
    const { forgetPassword: triggerForget, modalActions } = this.props

    try {
      const response = await triggerForget(email)
      if (response) {
        this.setState({ email: '' })
        await modalActions.hideAuthModal()
        alertModal(
          'Please check your email for Password Reset Instructions.',
          'success',
          'Close'
        )
      } else {
        alertModal(
          'error',
          'error',
          'There is no user registered with this email.'
        )
      }
    } catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        alertModal(error.response, 'error', 'Try Again')
      } else if (error.request) {
        alertModal('', 'error', 'There is no user registered with this email.')
      } else {
        alertModal('', 'error', 'There is no user registered with this email.')
      }
    }
  }

  async handleSignIn(event) {
    event.preventDefault()

    const { signIn, userActions, hostActions, modalActions } = this.props
    const { email, password } = this.state
    const data = { email, password }
    try {
      await signIn(data)
      trackSignIn()
      // alertModal('Successfullly logged in!', 'success', 'Close');
      const { currentUser, isHost } = this.props
      const { hostApplication } = currentUser && currentUser.attributes
      if (isHost && currentUser.isSignedIn && (hostApplication !== 'complete' && hostApplication !== 'approved' && hostApplication !== 'declined')) {
        trackGetStartedHostApplication({ status: 'user' })
        hostActions.hostApplicationStarted()
        await modalActions.hideAuthModal()
        history.push('/become-a-host/step-1')
      } else {
        await modalActions.hideAuthModal()
        await userActions.getCountryList()
        await hostActions.getLocations()
        await hostActions.getSampleExperiences()
      }
      // Lets make sure we avoid default!
    } catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        /* eslint-disable-next-line no-console */
        alertModal(error.response, 'error', 'Try Again')
      } else if (error.request) {
        /* eslint-disable-next-line no-console */
        alertModal(error, 'error', 'Try Again')
      } else {
        /* eslint-disable-next-line no-console */
        alertModal(error, 'error', 'Try Again')
      }
    }
  }

  componentWillUnmount = async () => {
    const { userActions } = this.props
    await userActions.disableLogin(false)
  }

  commonSocialLogin(commonObj) {
    let provider = null
    let providerID = null
    switch (commonObj.provider) {
    case 'twitter':
      provider = new firebase.auth.TwitterAuthProvider()
      providerID = 'twitter.com'
      break
    case 'facebook':
      provider = new firebase.auth.FacebookAuthProvider()
      providerID = 'facebook.com'
      /* provider.addScope('user_birthday')
        provider.addScope('public_profile')
        provider.addScope('user_photos') */
      // public_profile
      break
    case 'google':
      provider = new firebase.auth.GoogleAuthProvider()
      providerID = 'google.com'
      break
    default:
      break
    }
    const firebaseConfig = {
      apiKey: config.firebase_api_key,
      authDomain: config.firebase_auth_domain,
      projectId: config.firebase_project_id
    }
    if (!firebase.apps.length) {
      firebase.initializeApp(firebaseConfig)
    }
    provider.setCustomParameters({
      lang: 'en'
    })
    firebase
      .auth()
      .signInWithPopup(provider)
      .then((result) => {
        this.lastFunForSocialLogin({ result, commonObj, providerID })
      }).catch((error) => {
        // Handle Errors here.
        const errorCode = error.code
        const errorMessage = error.message
        // The email of the user's account used.
        // The firebase.auth.AuthCredential type that was used.
        const { email, credential } = error
        // eslint-disable-next-line no-console
        console.log('errorCode', errorCode, 'errorMessage', errorMessage, 'email', email, 'credential', credential)

        this.inCaseOfError({ errorObj: error, providerID, commonObj })
      // ...
      })
  }

  inCaseOfError({ errorObj, providerID, commonObj }) {
    const firebaseConfig = {
      apiKey: config.firebase_api_key,
      authDomain: config.firebase_auth_domain,
      projectId: config.firebase_project_id
    }
    if (!firebase.apps.length) {
      firebase.initializeApp(firebaseConfig)
    }
    firebase.auth().fetchSignInMethodsForEmail(errorObj.email)
      .then(async (providers) => {
        let provider = null
        // const providerID = providers[0]
        if (providers[0] === 'google.com') {
          provider = new firebase.auth.GoogleAuthProvider()
        } else if (providers[0] === 'twitter.com') {
          provider = new firebase.auth.TwitterAuthProvider()
        } else if (providers[0] === 'facebook.com') {
          provider = new firebase.auth.FacebookAuthProvider()
        }
        provider.setCustomParameters({ login_hint: errorObj.email })
        try {
          const result = await firebase.auth().signInWithPopup(provider)
          await result.user.linkWithCredential(errorObj.credential)
          let token = null
          if (providerID === 'facebook.com') {
            token = errorObj.credential.accessToken
          }
          this.lastFunForSocialLogin({ result, commonObj, providerID, token })
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log('error====== in side linking 217', error)
          alertModal(
            'Unable to complete the sign-in process, please allow the popup window to load and try again.',
            'warning',
            'Close'
          )
        }
        // this.commonSocialLogin({ signUpChannel: 'google', afterError: true, email: errorObj.email })
        // providers returns this array -> ["google.com"]
        // You need to sign in the user to that google account
        // with the same email.
        // In a browser you can call:
        // var provider = new firebase.auth.GoogleAuthProvider();
        // provider.setCustomParameters({login_hint: error.email});
        // firebase.auth().signInWithPopup(provider)
        // If you have your own mechanism to get that token, you get it
        // for that Google email user and sign in
        /* firebase.auth().signInWithCredential(googleCred)
          .then((user) => {
            // You can now link the pending credential from the first
            // error.
            user.linkWithCredential(error.credential)
          })
          .catch((error) => {
            // eslint-disable-next-line no-console
            console.log(error)
          }) */
      })
  }

  async lastFunForSocialLogin({ result, commonObj, providerID, token }) {
    // eslint-disable-next-line no-console
    // console.log('result', result, 'result.user', result.user, 'result.user.providerData', result.user.providerData, 'commonObj', commonObj, 'providerID', providerID)
    const { credential } = result
    let fbToken = token
    // This gives you a the Twitter OAuth 1.0 Access Token and Secret.
    // You can use these server side with your app's credentials to access the Twitter API.
    if (!fbToken) {
      fbToken = credential.accessToken
    }
    // const secret = credential.secret

    // The signed-in user info.
    // eslint-disable-next-line no-console
    const user = result.user.providerData.find(el => el.providerId === providerID)
    const currentDate = new Date()
    // displayName: "sachin"
    // email: "sachin.saini@mail.vinove.com"
    const name = user.displayName.split(' ')
    // eslint-disable-next-line no-console
    // console.log('name', name[0], name[1])
    let payload = {
      ...commonObj,
      // id: user.uid,
      firebaseUid: user.uid,
      firstName: name[0],
      lastName: name[1],
      phoneMetaData: { dialCode: null, iso2: null, name: null },
      // otherUserReferralCode: getUtm('HH_aref') ? getUtm('HH_aref').HH_aref : null,
      email: user.email ? user.email : `${currentDate.getTime}@${commonObj.provider}.com`,
      idToken: credential.idToken
      // password: 'socialUser' //,
      // provider: 'twitter'
    }
    if (user.photoURL) {
      payload.socialImage = user.photoURL
    }
    if (commonObj.provider === 'facebook') {
      payload = await fetchFacebookUserInfo({ token: fbToken })
      delete payload.id
      payload.firebaseUid = user.uid
      payload.provider = 'facebook'
    }
    const { handleRegistration } = this.props
    await handleRegistration(payload)
    const { userActions, hostActions, modalActions, currentUser, isHost } = this.props
    const { hostApplication } = currentUser && currentUser.attributes
    // We need to call this.props.signIn somewhere here to actually tell the redux auth library a sign in has occured
    if (isHost && currentUser.isSignedIn && (hostApplication !== 'complete' && hostApplication !== 'approved' && hostApplication !== 'declined')) {
      trackGetStartedHostApplication({ status: 'user' })
      hostActions.hostApplicationStarted()
      await modalActions.hideAuthModal()
      history.push('/become-a-host/step-1')
    } else {
      await modalActions.hideAuthModal()
      await userActions.getCountryList()
      await hostActions.getLocations()
      await hostActions.getSampleExperiences()
    }
  }

  render() {
    const { email, password, showPassword } = this.state

    const {
      currentUser,
      // successAction,
      // history,
      setForgotPassword,
      forgotPassword,
      isHost
    } = this.props
    if (currentUser.isSignedIn) {
      return (
        <div className="text-center text-warning">
          <h6>You are already signed in.</h6>

          <p>
            <Link to="/experiences" className="btn">
              Explore Experiences
            </Link>
          </p>
        </div>
      )
    }
    if (forgotPassword.forgotPass) {
      return (
        <form
          onSubmit={this.handleForgetPassword.bind(this)}
          id="auth"
          className="form-vertical"
        >
          <div className="form-body text-center">
            <div className="form-header">
              <h4>Forgot Password</h4>
            </div>
            <label htmlFor="email" className="label-hidden">
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              placeholder="Email"
              autoComplete="email"
              value={email}
              onChange={this.handleChange}
            />

            <button
              type="submit"
              form="auth"
              value="Submit"
              className="btn btn--full"
            >
              Submit
            </button>
          </div>
        </form>
      )
    }
    return (
      <div>
        <form onSubmit={this.handleSignIn} id="auth" className="form-vertical">
          <div className="form-body text-center">
            <div className="form-header">
              { isHost ? <h4>Become A Host</h4> : <h4>Login</h4> }
            </div>

            {/* <p>
              <OAuthButtonContainer
                handleSucess={this.handleSucess}
                provider="facebook"
                action="Login"
                extraClassName="btn--full"
              />
            </p>
            <p>
              <OAuthButtonContainer
                handleSucess={this.handleSucess}
                provider="google_oauth2"
                action="Login"
                extraClassName="btn--full"
              />
            </p> */}

            {/* <div className="form--or-divider">
              <span className="form--or-divider--inner">
                <span className="form--or-divider--text">Or</span>
              </span>
            </div> */}

            <label htmlFor="email" className="label-hidden">
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              placeholder="Email"
              autoComplete="email"
              value={email}
              onChange={this.handleChange}
            />
            <label htmlFor="password" className="label-hidden">
              Password
            </label>

            <div className="input-group mb_5 ">
              <input
                type={showPassword ? 'text' : 'password'}
                id="password"
                name="password"
                placeholder="Password"
                autoComplete="current-password"
                value={password}
                onChange={this.handleChange}
              />

              <div
                className="input-group-addon cursor"
                onClick={() => {
                  const isShowPassword = !showPassword
                  this.setState({ showPassword: isShowPassword })
                }}
                role="presentation"
                style={{ padding: '10px' }}
              >
                <i>{showPassword ? eyeSlash : eye}</i>
              </div>
            </div>

            <button
              type="submit"
              form="auth"
              value="Submit"
              className="btn btn--full mtt_10"
            >
              Login
            </button>
            <div>
              <p style={{ marginTop: '21px', marginBottom: '0px' }}><b>Or</b></p>
              <button type="button" className="socialbutton facbookbtn" onClick={($event) => this.handleSocialLogin($event, 'facebook')}>
                <span className="fab fa-facebook-f" />
              </button>
              <button type="button" className="socialbutton googlebtn" onClick={($event) => this.handleSocialLogin($event, 'google')}>
                <span className="fab fa-google" />
              </button>
              <button type="button" className="socialbutton twitterbtn" onClick={($event) => this.handleSocialLogin($event, 'twitter')}>
                <span className="fab fa-twitter" />
              </button>
            </div>
            <button
              type="button"
              onClick={() => setForgotPassword(true)}
              className="btn btn--link"
            >
              Forgot password?
            </button>
          </div>
        </form>
      </div>
    )
  }
}

AuthForm.defaultProps = {
  successAction: () => {},
  isHost: false,
  forgotPassword: { forgotPass: false }
}
AuthForm.propTypes = {
  signIn: PropTypes.func.isRequired,
  successAction: PropTypes.func,
  handleRegistration: PropTypes.func.isRequired,
  currentUser: currentUserPropTypes.isRequired,
  userActions: PropTypes.shape({
    disableLogin: PropTypes.func,
    getCountryList: PropTypes.func
  }).isRequired,
  forgetPassword: PropTypes.func.isRequired,
  isHost: PropTypes.bool,
  hostActions: PropTypes.shape({
    hostApplicationStarted: PropTypes.func,
    getLocations: PropTypes.func,
    getSampleExperiences: PropTypes.func
  }).isRequired,
  modalActions: PropTypes.shape({
    hideAuthModal: PropTypes.func
  }).isRequired,
  forgotPassword: PropTypes.shape({
    forgotPass: PropTypes.bool
  }),
  setForgotPassword: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  currentUser: state.reduxTokenAuth.currentUser,
  forgotPassword: state.forgotPassword,
  pathname: state.router.location.pathname,
  isHost: state.modal.modalProps.isHost || false
})

const mapDispatchToProps = dispatch => ({
  modalActions: bindActionCreators(modalActionCreators, dispatch),
  hideModal: state => dispatch(modalActionCreators.hideAuthModal(state)),
  signIn: user => dispatch(signInUser(user)),
  handleRegistration: user => dispatch(registerUser(user)),
  userActions: bindActionCreators(userActionCreators, dispatch),
  hostActions: bindActionCreators(hostActionCreators, dispatch),
  forgetPassword: state => dispatch(forgetPassword(state)),
  setForgotPassword: state => dispatch(setForgotPass(state))
})
export default connect(mapStateToProps, mapDispatchToProps)(AuthForm)
