import React from 'react'
import { Modal } from 'react-bootstrap'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as modalActionCreators from '../../actions/modals'

const ReviewCommentModal = (props) => {
  const { show, modalActions, comment } = props
  return (
    <React.Fragment>
      <Modal show={show} onHide={modalActions.hideCommentModal}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>{comment && <blockquote className="sidekick">{comment}</blockquote>}</Modal.Body>
      </Modal>
    </React.Fragment>
  )
}

const mapStateToProps = (state) => ({
  currentUser: state.reduxTokenAuth.currentUser
})

const mapDispatchToProps = (dispatch) => ({
  modalActions: bindActionCreators(modalActionCreators, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(ReviewCommentModal)
