// import React from 'react';
// import { Elements } from '@stripe/react-stripe-js'
// import { loadStripe } from '@stripe/stripe-js'

// import CheckoutForm from './CheckoutForm';

// // Make sure to call `loadStripe` outside of a component’s render to avoid
// // recreating the `Stripe` object on every render.
// const stripePromise = loadStripe("pk_test_TYooMQauvdEDq54NiTphI7jx");

// function NewGetPaymentToken() {
//   return (
//     <Elements stripe={stripePromise}>
//       <CheckoutForm />
//     </Elements>
//   );
// };

// export default NewGetPaymentToken;

import React from 'react'
import Checkout from './CheckoutForm'
import { StripeProvider } from 'react-stripe-elements'


function NewGetPaymentToken(props) {
  return (
    <StripeProvider apiKey="pk_test_SFffLB6N7dioAI2uK7f85SUk00XlFVBdlm">
      <Checkout {...props} />
    </StripeProvider>
  )
};

export default NewGetPaymentToken
