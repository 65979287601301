import React from 'react'
 
// components
import SplitForm from './CardSection'
import {
  StripeProvider, Elements, CardElement,
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
  PaymentRequestButtonElement,
  IbanElement,
  IdealBankElement,
  injectStripe
} from 'react-stripe-elements'

import seo_meta from '../../../utils/seo-meta.json'

/* Icons */
import CreditCardIconGroup from '../../../icons/creditCards/CreditCardIconGroup'
import SEO from '../../Shared/Web/SEO'

class Checkout extends React.Component {
  constructor() {
    super()
    this.state = {
      elementFontSize: window.innerWidth < 450 ? '14px' : '18px'
    }
    window.addEventListener('resize', () => {
      if (window.innerWidth < 450 && this.state.elementFontSize !== '14px') {
        this.setState({ elementFontSize: '14px' })
      } else if (
        window.innerWidth >= 450 &&
        this.state.elementFontSize !== '18px'
      ) {
        this.setState({ elementFontSize: '18px' })
      }
    })
  }

render() {
  const { elementFontSize } = this.state
  let { title, description, og_title, og_description, og_site_name, og_locale, og_type } = seo_meta.add_payment_detail
  return (
    <div className="Checkout">
      <SEO title={title} description={description} />
      <div className="form-header">
        <h4>Add a payment method to complete your requests</h4>
        {/* <h4>Complete your payment</h4> */}
        <CreditCardIconGroup />
        {/*  */}
      </div>
      <Elements>
        <SplitForm fontSize={elementFontSize} {...this.props} />
      </Elements>
      {/* <Elements>
            <PaymentRequestForm />
          </Elements> */}
    </div>
  )
}
}

export default Checkout