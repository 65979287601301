import React from 'react'
import Modal from 'react-bootstrap/lib/Modal'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'

/* Components */
import HostLocationForm from '../Host/HostLocationForm'
/* Actions */
import * as modalActionCreators from '../../actions/modals'

const HostLocationFormModal = props => {
  const { show, modalActions, item } = props
  return (
    <Modal
      show={show}
      onHide={() => modalActions.hideHostLocationFormModal()}
      animation={false}
      bsSize="large"
      className="receipt-modal"
    >
      <Modal.Header closeButton className="customHeader">
        <h5>Add/Edit Location</h5>
      </Modal.Header>
      <Modal.Body>
        <HostLocationForm {...props} />
      </Modal.Body>
    </Modal>
  )
}

HostLocationFormModal.propTypes = {
  modalActions: PropTypes.shape({}).isRequired,
  show: PropTypes.bool.isRequired
}
const mapStateToProps = state => ({
  currentUser: state.reduxTokenAuth.currentUser
})

const mapDispatchToProps = dispatch => ({
  modalActions: bindActionCreators(modalActionCreators, dispatch)
})
export default connect(mapStateToProps, mapDispatchToProps)(HostLocationFormModal)
