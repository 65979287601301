// import moment from 'moment';
import { SET_CUISINE_TYPES } from '../constants/actionTypes'

const initialState = {
  allCuisine: []
}

export default function(state = initialState, action) {
  if (action.type === SET_CUISINE_TYPES) {
    return {
      ...state,
      allCuisine: [...action.cuisineItems.cuisine_types]
    }
  }
  return state
}
