import React from 'react'
import SEO from '../Shared/Web/SEO'

const notFound = () => (
  <div className="experience-single fadeIn">
    <figure>
      <SEO title="404 | Breakbread" />
      <div className="breakbread-component-text pagenotFound" >
        <h1>404</h1>
        <h2 className="h1 mega-title">Page Not Found</h2>
        <p >The page you are looking for might have been removed had its name changed or temporary unavailable.</p>
        <a href="/"><button type="button" className="btn">Home Page</button></a>
        <div >
          <h6>Get in touch</h6>
          <p><a href="mailto:support@breakbread.com">support@breakbread.com</a></p>
        </div>
      </div>
    </figure>
  </div>
)

export default notFound
