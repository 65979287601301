// import qs from 'qs';
// import api from '../../utils/api';
// import { getUserAttributesFromResponse } from '../../utils/auth-tools';
// import { resetRoute } from './nav';

/* eslint-disable-next-line no-unused-vars */
import { normalizeProfile } from '../../utils/normalization'
import { ActiveStorageUpload } from '../../utils/activeStorageUpload'
import api from '../../utils/api'
import { snakeToCamel } from '../../utils/camelSnake'
import { setUserAttributes } from './update'
import { setAllLocations } from './../host/location'
import { camelToSnake } from '../../utils/camelSnake'

import alertModal from '../../utils/alert'
//
export const multiUploadStarted = files => ({
  type: 'MULTI_UPLOAD_STARTED',
  payload: {
    files
  }
})

export const multiUploadInProgress = files => ({
  type: 'MULTI_UPLOAD_IN_PROGRESS', 
  payload: {
    files
  }
})

export const multiUploadComplete = files => ({
  type: 'MULTI_UPLOAD_COMPLETE',
  payload: {
    files
  }
})

export const setProfileObj = data => ({
  type: 'SET_PROFILE_PIC_OBJ',
  payload: {
    profilePic: data
  }
})

export const setMenuFilesObjects = (data, index) => ({
  type: 'SET_MENU_FILE_OBJECT',
  payload: {
    fileData: data,
    indexData: index
  }
})

export const resetMenuFilesObjects = (data) => ({
  type: 'RESET_SET_MENU_FILE_OBJECT',
  payload: {
    fileData: data
  }
})
export const removeMenuFilesObject = (index) => ({
  type: 'REMOVE_MENU_FILE_OBJECT',
  payload: {
    indexData: index
  }
})



export function fileUpload(imageData) {
  /* eslint-disable-next-line no-unused-vars */
  return async (dispatch, getState) => {
    let uploadProgress
    const newImageUpload = new ActiveStorageUpload(imageData, progress => {
      uploadProgress = progress
    })

    const newImageBlob = await newImageUpload.create(newImageUpload)
    const file = snakeToCamel(newImageUpload.upload.file, 1)
    const blob = snakeToCamel(newImageBlob, 1)
    // Update User here.
    const object = {
      ...file,
      ...blob,
      uploadProgress
    }

    return object
  }
}

export function newFileUpload(imageData) {
  /* eslint-disable-next-line no-unused-vars */
  return async (dispatch, getState) => {
    const userDetail = getState().reduxTokenAuth.currentUser.attributes
    // console.log('imageData', imageData, userDetail)
    let formData = new FormData()
    formData.append('gallery_image', imageData)
    // const dataObj = JSON.stringify({id: userDetail.id})
    // formData.append('user', userDetail.id)
    // user = camelToSnake(user, 2)
    const response = await api.post(
      'hosts/gallery_images',
      formData
    )
    if(response.data.is_success) {
    /* dispatch(setProfileObj(response.data.profile_picture)) */
      const oldPhotos = userDetail.hostGalleryImages?userDetail.hostGalleryImages:[]
      dispatch(setUserAttributes({...userDetail, hostGalleryImages: [...oldPhotos, response.data.gallery_image]}))
    }
    return response
  }
}

export function fileUploadForHostLocation(imageData, id) {
  /* eslint-disable-next-line no-unused-vars */
  return async (dispatch, getState) => {
    const allLocations = getState().host.location.allLocations
    
    // console.log('imageData', imageData, userDetail)
    let formData = new FormData()
    formData.append('gallery_image', imageData)
    // const dataObj = JSON.stringify({id: userDetail.id})
    // formData.append('user', userDetail.id)
    // user = camelToSnake(user, 2)
    const response = await api.post(
      `hosts/locations/${id}/gallery_images`,
      formData
    )
    if(response.data.is_success) {
      const updateLocations = allLocations.map((el)=>{
        if (el.id === id) {
          return {
            ...el,
            galleryImages: [...el.galleryImages, response.data.gallery_image]
          }
        } else {
          return el
        }
      })
      dispatch(setAllLocations(updateLocations))
    }
    return response
  }
}

export function fileUploadForExperienceLocation(imageData, id) {
  /* eslint-disable-next-line no-unused-vars */
  return async (dispatch, getState) => {
    //const allLocations = getState().host.location.allLocations
    
    // console.log('imageData', imageData, userDetail)
    let formData = new FormData()
    formData.append('gallery_image', imageData)
    // const dataObj = JSON.stringify({id: userDetail.id})
    // formData.append('user', userDetail.id)
    // user = camelToSnake(user, 2)
    const response = await api.post(
      `hosts/experiences/${id}/gallery_images`,
      formData
    )
    if(response.data.is_success) {
    /* const updateLocations = allLocations.map((el)=>{
      if (el.id === id) {
        return {
          ...el,
          galleryImages: [...el.galleryImages, response.data.gallery_image]
        }
      } else {
        return el
      }
    })
    dispatch(setAllLocations(updateLocations)) */
    }
    return response
  }
}

export function deleteGalleryImage(id) {
  /* eslint-disable-next-line no-unused-vars */
  return async (dispatch, getState) => {
    const userDetail = getState().reduxTokenAuth.currentUser.attributes
    // const dataObj = JSON.stringify({id: userDetail.id})
    // formData.append('user', userDetail.id)
    // user = camelToSnake(user, 2)
    const response = await api.delete(
      'hosts/gallery_images/' +id,
    )
    if(response.data.is_success) {
      dispatch(setUserAttributes({...userDetail, hostGalleryImages: [...userDetail.hostGalleryImages.filter(el=>el.id !== id)]}))
    }
    return response
  }
}

export function deleteHostGalleryImage(id, locationId) {
  /* eslint-disable-next-line no-unused-vars */
  return async (dispatch, getState) => {
    const allLocations = getState().host.location.allLocations
    // const dataObj = JSON.stringify({id: userDetail.id})
    // formData.append('user', userDetail.id)
    // user = camelToSnake(user, 2)
    const response = await api.delete(
      `hosts/locations/${locationId}/gallery_images/${id}`,
    )
    if(response.data.is_success) {
      const updateLocations = allLocations.map((el)=>{
        if (el.id === locationId) {
          return {
            ...el,
            galleryImages: [...el.galleryImages.filter(el=>el.id !== id)]
          }
        } else {
          return el
        }
      })
      dispatch(setAllLocations(updateLocations))
    }
    return response
  }
}

export function deleteExperienceGalleryImage(id, experienceId) {
  /* eslint-disable-next-line no-unused-vars */
  return async (dispatch, getState) => {
    // const allLocations = getState().host.location.allLocations
    // const dataObj = JSON.stringify({id: userDetail.id})
    // formData.append('user', userDetail.id)
    // user = camelToSnake(user, 2)
    const response = await api.delete(
      `hosts/experiences/${experienceId}/gallery_images/${id}`,
    )
    if(response.data.is_success) {
    /* const updateLocations = allLocations.map((el)=>{
      if (el.id === locationId) {
        return {
          ...el,
          galleryImages: [...el.galleryImages.filter(el=>el.id !== id)]
        }
      } else {
        return el
      }
    }) */
    // dispatch(setAllLocations(updateLocations))
    }
    return response
  }
}


async function asyncForEach(array, callback) {
  for (let index = 0; index < array.length; index += 1) {
    /* eslint-disable-next-line no-await-in-loop */
    await callback(array[index], index, array)
  }
}

export function imageMultiUpload(fileArray, input, parentComponent, locationId, experienceId) {
  return async (dispatch, getState) => {  
    const blobArray = input.value ? [...input.value] : []
    dispatch(multiUploadStarted(fileArray))
    await asyncForEach(fileArray, async file => {
      dispatch(multiUploadInProgress(file))
      if(parentComponent==='profilePhotos') {
        const dataImage = await dispatch(newFileUpload(file))
        blobArray.push(dataImage.data.gallery_image)
        input.onChange(blobArray)
      } else if(parentComponent==='locationPhotos') {
        const dataImage = await dispatch(fileUploadForHostLocation(file, locationId))
        blobArray.push(dataImage.data.gallery_image)
        input.onChange(blobArray)
      } else if(parentComponent==='experiencePhotos') {
        const dataImage = await dispatch(fileUploadForExperienceLocation(file, experienceId))
        blobArray.push(dataImage.data.gallery_image)
        input.onChange(blobArray)
      }
      else {
        const blob = await dispatch(fileUpload(file))
        blobArray.push(blob)
        input.onChange(blobArray)
      }
    })
    dispatch(multiUploadComplete(blobArray))
  }
}

export function attachAvatar(imageData) {
  /* eslint-disable-next-line no-unused-vars */
  return async (dispatch, getState) => {
    let uploadProgress
    const newImageUpload = new ActiveStorageUpload(imageData, progress => {
      uploadProgress = progress
    })

    const newImageBlob = await newImageUpload.create(newImageUpload)

    // Update User here.
    const object = {
      ...newImageBlob,
      uploadProgress
    }

    return object
  }
}

export function singleImageUpload(imageData) {
  /* eslint-disable-next-line no-unused-vars */
  return async (dispatch, getState) => {
    let uploadProgress
    const newImageUpload = new ActiveStorageUpload(imageData, progress => {
      uploadProgress = progress
    })

    const newImageBlob = await newImageUpload.create(newImageUpload)

    // Update User here.
    const object = {
      ...newImageBlob,
      uploadProgress
    }
    dispatch(setProfileObj(object))

    return object
  }
}

export function profileImageUpload(imageData, goToNext) {
  /* eslint-disable-next-line no-unused-vars */
  return async (dispatch, getState) => {
    const userDetail = getState().reduxTokenAuth.currentUser.attributes
    // console.log('imageData', imageData, userDetail)
    let formData = new FormData()
    if (Array.isArray(imageData)) {
      imageData.forEach(el => {
        formData.append('imagesArray', el)
      })
    } else {
      formData.append('profile_picture', imageData)
    }
    // const dataObj = JSON.stringify({id: userDetail.id})
    // formData.append('user', userDetail.id)
    // user = camelToSnake(user, 2)
    const response = await api.post(
      'users/profile_picture',
      formData
    )
    if (response.data.is_success) {
    /* dispatch(setProfileObj(response.data.profile_picture)) */
      const profilePicture = {...response.data.profile_picture, thumb: response.data.profile_picture.thumb /* + '?' + new Date().getTime() */ }
      dispatch(setUserAttributes({...userDetail, profilePicture}))
      if (!goToNext) {
        alertModal('Profile Picture Successfully Uploaded!', 'success', 'Close')
      }
    }
    return response
  }
}

export function heroExpImageUpload(imageData, id) {
  /* eslint-disable-next-line no-unused-vars */
  return async (dispatch, getState) => {
    // console.log('imageData', imageData, userDetail)
    let formData = new FormData()
    formData.append('hero_image', imageData)
    // const dataObj = JSON.stringify({id: userDetail.id})
    // formData.append('user', userDetail.id)
    // user = camelToSnake(user, 2)
    const response = await api.post(
      `hosts/experiences/${id}/hero_image`,
      formData
    )
    if(response.data.is_success) {
      return response.data.hero_image
    }
  }
}

export function userHeroImageUpload(imageData) {
  /* eslint-disable-next-line no-unused-vars */
  return async (dispatch, getState) => {
    const userDetail = getState().reduxTokenAuth.currentUser.attributes
    // console.log('imageData', imageData, userDetail)
    let formData = new FormData()
    formData.append('hero_image', imageData)
    // const dataObj = JSON.stringify({id: userDetail.id})
    // formData.append('user', userDetail.id)
    // user = camelToSnake(user, 2)
    const response = await api.post(
      `users/hero_image`,
      formData
    )
    if(response.data.is_success) {
      const heroImageData = {...response.data.hero_image, big: response.data.hero_image.big /* + '?' + new Date().getTime() */ }
      dispatch(setUserAttributes({...userDetail, heroImage: heroImageData}))
      return heroImageData
    }

  }
}

export function userCovidCertificateUpload(imageData) {
  /* eslint-disable-next-line no-unused-vars */
  return async (dispatch, getState) => {
    const fsize = imageData.size
    const sizeInKb = Math.round((fsize / 1024))
    if (sizeInKb < 5000) {
      const userDetail = getState().reduxTokenAuth.currentUser.attributes
      let formData = new FormData()
      formData.append('covid_certificate', imageData)
      const response = await api.post(
        `users/covid_certificate`,
        formData
      )
      if(response.data.is_success) {
        const covidCertificateData = {...response.data.covid_certificate, thumb: response.data.covid_certificate.thumb /* + '?' + new Date().getTime() */ }
        dispatch(setUserAttributes({...userDetail, covidCertificate: covidCertificateData}))
        return covidCertificateData
      }
    } else {
      return {error: true, message: 'File size exceed to 5MB'}
    }
  }
}



