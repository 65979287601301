import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import seo_meta from '../../../utils/seo-meta.json'
/* Components */

import AccountsWrapper from '../AccountsWrapper'

import { TabActions, TabContent, TabButtons } from '../../Tabs/Tabs'
import List from './List'
import SEO from '../../Shared/Web/SEO'

const experiencesTabs = {
  defaultTab: 'upcomingExperiences', // Not Required (if other than the first tab)
  tabs: [
    {
      name: 'upcomingExperiences',
      title: 'Upcoming Experiences',
      // You can also pass in content as HTML or Text
      content: props => <List {...props} />
    },
    {
      name: 'pastExperiences',
      title: 'Past Experiences',
      // This can return a component, html or strings
      content: props => <List {...props} />
    }
  ]
}
class MyExperiences extends Component {
  constructor(props) {
    super(props)
  }

  componentDidMount = () => {
  }

  render() {
    return (
      <AccountsWrapper title="My Experiences">
        <SEO title="My Experiences" description="My Experiences" />
        <div className="account-tabs text-center">
          <TabActions namespace="experiences" data={experiencesTabs}>
            {childProps => <TabButtons {...childProps} />}
          </TabActions>
        </div>

        <TabContent namespace="experiences" data={experiencesTabs} />
      </AccountsWrapper>
    )
  }
}

const mapStateToProps = state => ({
  currentUser: state.reduxTokenAuth.currentUser
})

const mapDispatchToProps = dispatch => ({})

export default connect(mapStateToProps, mapDispatchToProps)(MyExperiences)
