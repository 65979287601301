import React, { Component } from 'react'
import { connect } from 'react-redux'
import SEO from '../Shared/Web/SEO'
import { getMyCoupons } from '../../actions/coupons'
import ReferralCouponBox from './ReferalCouponBox'
import Icon from '../Shared/Web/Icon'

class Coupons extends Component {
  constructor(props) {
    super(props)
    const { filter } = props

    this.state = {
      filter: {
        perPage: 12,
        currentPage: 1
      },
      total_count: 5,
      copiedID: null,
      isLoading: true
    }
  }

  async componentDidMount() {
    this.setState({isLoading: false})
    this.fetch();
  }

  loadMore = (currentPage) => {
    this.fetch(currentPage)
  }

  async fetch() {
    const { getMyCoupons } = this.props
    this.setState({ isLoading: true })
    try {
      await getMyCoupons()
      this.setState({ isLoading: false })
    } catch (e) {
      this.setState({ isLoading: false })
    }
  }

  copyToClipboard = (str, id=null) => {
    navigator.clipboard.writeText(str);
    this.setState({ copiedID: id })
  };

  render() {
    const { coupons, meta, referralCoupon, currentUser } = this.props
    const { copiedID } = this.state

    return (
      <React.Fragment>
        <SEO title="My Coupons List" description="My Coupons List" />
        <div className="couponlist">
          <div className="page-header text-center">
            <h1>My Reward / Discount Coupons</h1>
          </div>
          { (referralCoupon !== null) && !referralCoupon.expired && <ReferralCouponBox referralCoupon={referralCoupon} currentUser={currentUser} copiedId={copiedID} copyToClipboard={(str, id) => this.copyToClipboard(str, id)}/> }
          {coupons && (coupons.length > 0) ? (
            <div style={{overflowX:"auto", margin: "20px 25px 0px 25px"}}>
              <table className="responsive-table--no-borders">
                <thead className="table-heading">
                  <tr>
                    <th style={{textAlign: 'left'}}>#</th>
                    <th style={{textAlign: 'left'}}>Coupon Code</th>
                    <th style={{textAlign: 'left'}}>Coupon Type</th>
                    <th style={{textAlign: 'left'}}>Issued At</th>
                    <th style={{textAlign: 'left'}}>Expires At</th>
                    <th style={{textAlign: 'left'}}>Redeemed On</th>
                  </tr>
                </thead>
                <tbody>
                  
                {coupons.map((coupon, index)=>(
                  <tr key={index}>
                    <td style={{textAlign: 'left'}}>
                      {index+1}
                    </td>
                    <td style={{textAlign: 'left'}}>
                      {coupon.code}
                      <a onClick={() => this.copyToClipboard(coupon.code, coupon.id) } title={(copiedID == coupon.id) ? 'Copied to Clipboard' : 'Click to Copy'} style={{display: "inline-block", padding: "0px 0px 5px 23px"}}>
                        <Icon iconName={(copiedID == coupon.id) ? 'Copied' : 'Copy'} width="20" height="20" />
                      </a>
                    </td>
                    <td style={{textAlign: 'left'}}>
                      <b>{(coupon.couponType).toUpperCase()}</b> ({coupon.couponValue} OFF)
                    </td>
                    <td style={{textAlign: 'left'}}>
                      {coupon.startingAt}
                    </td>
                    <td style={{textAlign: 'left'}}>
                      {coupon.couponType == 'referral' ? 'NA' : coupon.expiringAt}
                    </td>
                    <td style={{textAlign: 'left'}}>
                      {coupon.couponRedemptions && coupon.couponRedemptions.length > 0 ?
                        coupon.couponRedemptions.map((couponRedemption) => (
                          couponRedemption.redeemed_at
                        ))
                        :
                        '-'
                      }
                    </td>
                  </tr>))}
                </tbody>
              </table>
            </div>
          ) :
          <h4 style={{paddingTop: "25px"}}>No discount coupons found.</h4>
          }
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  coupons: state.coupons.coupons,
  meta: state.coupons.meta,
  referralCoupon: state.coupons.refCoupon,
  currentUser: state.reduxTokenAuth.currentUser
})

const mapDispatchToProps = (dispatch) => ({
  getMyCoupons: () => dispatch(getMyCoupons())
})

export default connect(mapStateToProps, mapDispatchToProps)(Coupons)
