const userAttributes = {
  id: 'id',
  firstName: 'first_name',
  lastName: 'last_name',
  fullName: 'fullname',
  slug: 'slug',
  email: 'email',
  confirmedAt: 'confirmed_at',
  city: 'city',
  countryCode: 'country_code',
  phoneVerified: 'phone_verified',
  phoneCountryCode: 'phone_country_code',
  description: 'description',
  avatarUrl: 'avatar_url',
  profilePicture: 'profile_picture',
  location: 'location',
  destinationLink: 'destination_link',
  gender: 'gender',
  birthday: 'birthday',
  languages: 'languages',
  phoneNumber: 'phone_number',
  phoneMetaData: 'phone_meta_data',
  culinaryJourney: 'culinary_journey',
  preferredLanguage: 'preferred_language',
  preferredCurrency: 'preferred_currency',
  specialties: 'specialties',
  socialLinks: 'social_links',
  defaultCard: 'default_card',
  role: 'role',
  locationPhotos: 'location_photos',
  // locationPhotosValues:"location_photos",
  foodPhotos: 'food_photos',
  hostGalleryImages: 'host_gallery_images',
  heroImage: 'hero_image',
  covidCertificate: 'covid_certificate',
  hostReviewsCount: 'host_reviews_count',
  averageHostRating: 'average_host_rating',
  guestReviewsCount: 'guest_reviews_count',
  averageGuestRating: 'average_guest_rating',
  hostingLocation: 'hosting_location',
  hostApplication: 'host_application',
  sampleExperience: 'sample_experience',
  bank_account_name: 'bank_account_name',
  bank_name: 'bank_name',
  bank_account_number: 'bank_account_number',
  bank_iban_number: 'bank_iban_number',
  bank_city: 'bank_city',
  bank_country: 'bank_country',
  bank_swift_code: 'bank_swift_code',
  acceptTerms: 'accept_terms',
  provider: 'provider',
  revenueSharePercentage: 'revenue_share_percentage',
  refferalCoupon: 'referralCoupon'
}

export default userAttributes
