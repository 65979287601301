import * as React from "react"

const LinkedIn = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="25"
    aria-label="LinkedIn"
    viewBox="0 0 512 512"
    fill="#fff"
    {...props}
  >
    <rect width={512} height={512} rx="15%" fill="#0077b5" />
    <circle cx={142} cy={138} r={37} />
    <path stroke="#fff" strokeWidth={66} d="M244 194v198M142 194v198" />
    <path d="M276 282c0-20 13-40 36-40 24 0 33 18 33 45v105h66V279c0-61-32-89-76-89-34 0-51 19-59 32" />
  </svg>
)

export default LinkedIn

// This is LINE ICON

// import * as React from "react"

// const LinkedIn = (props) => (
//   <svg
//     width={24}
//     height={24}
//     viewBox="0 0 24 24"
//     xmlns="http://www.w3.org/2000/svg"
//     {...props}
//   >
//     <path
//       fill="#0077B5"
//       fillRule="evenodd"
//       d="M22.037 22h-4.152v-6.496c0-1.55-.026-3.542-2.157-3.542-2.16 0-2.49 1.688-2.49 3.43V22H9.09V8.64h3.98v1.827h.058c.553-1.05 1.908-2.158 3.928-2.158 4.204 0 4.98 2.766 4.98 6.364V22ZM4.409 6.816A2.407 2.407 0 0 1 2 4.407a2.408 2.408 0 1 1 2.41 2.408ZM6.486 22H2.33V8.64h4.156V22Z"
//     />
//   </svg>
// )

// export default LinkedIn

