// import moment from 'moment';
import _ from 'lodash'
import {
  SET_EXPERIENCES,
  SET_ALL_EXPEREINCES_DATA,
  SET_HOST_EXPERINCE_FILTER,
  SET_UPCOMING_SCHEDULE,
  SET_FILTER_SCHEDULE_DATES
} from '../../constants/actionTypes'

const initialState = {
  // persistExpiresAt: moment.utc()
  //   .add(1, 'm')
  //   .format(),
  experiences: [],
  HostExperincefilter: {
    experienceType: 'all'
  },
  allExperience: {},
  upcomingSchedules: [],
  filterDates: []
}

export default function(state = initialState, action) {
  if (action.type === SET_EXPERIENCES) {
    return {
      ...state,
      experiences: action.experiences
    }
  }
  if (action.type === SET_ALL_EXPEREINCES_DATA) {
    return {
      ...state,
      allExperience: action.data
    }
  }

  if (action.type === SET_HOST_EXPERINCE_FILTER) {
    return {
      ...state,
      HostExperincefilter: action.filter
    }
  }
  if (action.type === SET_UPCOMING_SCHEDULE) {
    return {
      ...state,
      upcomingSchedules: action.needToAdd?[...state.upcomingSchedules, ...action.upcomingSchedules]:[...action.upcomingSchedules]
    }
  }
  if (action.type === SET_FILTER_SCHEDULE_DATES) {
    let currentDates = [...state.filterDates]
    if(_.isArray(action.filterDate)) {
      currentDates = [...action.filterDate]
    } else {
      if (!state.filterDates.find(el=>el === action.filterDate)) {
        currentDates = [...currentDates, action.filterDate]
      } 
    }
    
    return {
      ...state,
      filterDates: currentDates
    }
  }

  return state
}
