import React, { Component } from 'react'
import { Field } from 'redux-form'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import StarRatingComponent from 'react-star-rating-component'
import StarRatingField from '../Fields/renderStarRatingField'
/* Components */
import ChefAvtarHost from '../../Chef/ChefAvtarHost'
import ExperienceCard from '../../Experiences/ExperienceCard'

/* Fields */
import RenderField from '../Fields/renderField'

/* Centralized PropTypes */
import { currentUserPropTypes } from '../../../constants/composed-proptypes'

const reviewField = {
  name: 'comment',
  type: 'textarea',
  autoComplete: '',
  placeholder:
    'Please leave a detailed review of what you would like to share about this host and it should have max 1000 character.'
}

class ReviewForm extends Component {
  constructor(props) {
    super(props)
  }

  componentWillMount() {}

  render() {
    const {
      handleSubmit,
      pristine,
      submitting,
      nextPage,
      form,
      currentPage,
      reservationCount,
      guest,
      experience,
      reviewedByHost,
      reviewedByGuest,
      guestReview,
      hostReview,
      reservation,
      exit
    } = this.props

    let reviewable = Object.keys(guestReview).length > 0 ? false : true

    return (
      <form onSubmit={handleSubmit} id={form} className="">
        <div className="text-center form-header">
          {reviewable ? (
            <React.Fragment>
              <h4>You are writing a review for:</h4>
              <h5>{`"${experience.title}"`}</h5>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <h5>Thanks for reviewing:</h5>
              <h5>{`"${experience.title}"`}</h5>
            </React.Fragment>
          )}
        </div>
        <hr className="hr--clear" />
        <div className="grid grid--uniform">
          <div className="form-column grid__item medium-up--two-thirds">
            {/* <h5>Reviewing host:</h5> */}
            <div className="grid grid--uniform">
              <div className="grid__item medium-up--one-quarter text-center">
                <ChefAvtarHost person={experience.host} />
                <p>
                  <strong>{experience.host.fullname}</strong>
                </p>
              </div>

              <div className="grid__item medium-up--three-quarters text-left">
                {!reviewable && (
                  <React.Fragment>
                    <StarRatingComponent
                      name="starRating"
                      value={guestReview.star?Number(guestReview.star):0}
                      starCount={5}
                    />
                    <h5> Your Review: </h5>
                    <div className="review_comment">
                      <blockquote className="sidekick">{guestReview.comment}</blockquote>
                    </div>
                    <p className="schedule_date_text">{experience.title}, {reservation.schedule.date ? moment.utc(reservation.schedule.date).format('LL') : ''}</p>
                    <div>
                      <span className="booking_number_text left">{reservation.bookingNumber}</span>
                      <span className="time_posted_text right">{guestReview.timePosted}</span>
                    </div>
                  </React.Fragment>
                )}
                { guestReview.status == 'pending' ? (
                  <div>
                    <br/>
                    <br/>
                    <p><i>* The review is pending activation and will be active and show up when both parties submit their feedback or after 14-day since it was submitted, whichever comes first.</i></p>
                  </div>
                ) : ''
                }
                {reviewable && (
                  <React.Fragment>
                    <label>
                      What is your overall rating of this host & experience?
                    </label>
                    <Field name="star" component={StarRatingField}/>
                    <p>
                      <label>
                        Please include a detailed review of this experience
                      </label>
                      <Field
                        name={reviewField.name}
                        component={RenderField}
                        type="textArea"
                        inputType="textArea"
                        placeholder={reviewField.placeholder}
                        displayLabel={false}
                        field={reviewField}
                      />
                    </p>
                    <div className="form-action">
                      <button
                        type="submit"
                        disabled={pristine}
                        form={form}
                        className="btn btn--darker pull-right"
                      >
                        Submit
                      </button>
                    </div>
                  </React.Fragment>
                )}
              </div>
            </div>
          </div>

          <div className="form-card-column grid__item medium-up--one-third">
            {typeof experience !== 'undefined' && (
              <ExperienceCard
                experience={experience}
                hideAvatar
                displaySoldStatus
              />
            )}
          </div>
        </div>
      </form>
    )
  }
}

ReviewForm.defaultProps = {
  disableFields: true
}

ReviewForm.propTypes = {
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  valid: PropTypes.bool.isRequired,
  // nextPage: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  currentUser: currentUserPropTypes.isRequired,
  guest: PropTypes.shape({}).isRequired,
  // validate: PropTypes.shape({}).isRequired,
  form: PropTypes.string.isRequired,
  currentPage: PropTypes.number.isRequired,
  reservationCount: PropTypes.number.isRequired,
  experience: PropTypes.shape({}).isRequired,
  reviewedByGuest: PropTypes.bool.isRequired,
  reviewedByHost: PropTypes.bool.isRequired,
  reservation: PropTypes.shape({}).isRequired
}

export default ReviewForm
