import { REHYDRATE } from 'redux-persist'

import { SET_PROFILE, SET_UPDATE_API_ERROR } from '../../constants/actionTypes'

const initialState = {}

export default function(state = initialState, action) {

  if (action.type === SET_PROFILE) {
    return {
      ...state,
      ...action.profile
    }
  }
  if (action.type === SET_UPDATE_API_ERROR) {
    return {
      ...state,
      errorData: action.payload.errorData
    }
  }

  if (action.type === REHYDRATE) {
    // const savedUser = action.payload.user || state;
    // return {
    //   ...state,
    //   accessToken: savedUser.accessToken,
    //   profile: savedUser.profile,
    //   payment: savedUser.payment
    // };
  }

  return state
}
