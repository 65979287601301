import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'

/* Components */

import AccountsWrapper from '../AccountsWrapper'
import { TabActions, TabContent, TabButtons } from '../../Tabs/Tabs'
import ReviewsAboutMe from './AboutMe'
import ReviewsByMe from './ByMe'
import NeedsReview from './NeedsReview'

/* Actions */
import * as userActionCreators from '../../../actions/user'
import * as modalActionCreators from '../../../actions/modals'
import * as formActionCreators from '../../../actions/forms'
import * as hostActionCreators from '../../../actions/host'
import SEO from '../../Shared/Web/SEO'

class HostReviews extends Component {
  componentWillMount() {
    const { userActions, hostActions } = this.props
    userActions.getMyReviews()
    hostActions.getExperiences()
  }

  render() {
    const { location } = this.props
    const defaultTab =
      location.pathname === '/hosts/reviews/needs-review'
        ? 'needsReview'
        : 'aboutYou'

    const reviewsTabs = {
      defaultTab, // Not Required (if other than the first tab)
      tabs: [
        {
          name: 'aboutYou',
          title: 'Reviews About You',
          // This can return a component, html or strings
          content: ({ reviews }) => (
            <ReviewsAboutMe reviews={reviews.receivedAsHost} />
          )
        },
        {
          name: 'byYou',
          title: 'Reviews By You',
          // You can also pass in content as HTML or Text
          content: ({ reviews }) => (
            <ReviewsByMe reviews={reviews.givenAsHost} />
          )
        },
        {
          name: 'needsReview',
          title: 'Needs Review',
          // You can also pass in content as HTML or Text
          content: props => <NeedsReview {...props} />
        }
      ]
    }

    return (
      <AccountsWrapper title="Host Reviews">
        <SEO title="Host Reviews" description="Host Reviews" />
        <div className="account-tabs text-center">
          <TabActions namespace="reviews" data={reviewsTabs}>
            {childProps => <TabButtons {...childProps} />}
          </TabActions>
        </div>
        <TabContent namespace="reviews" data={reviewsTabs} {...this.props} />
      </AccountsWrapper>
    )
  }
}

HostReviews.defaultProps = {
  // onChange: null,
  // experiences: null,
}

HostReviews.propTypes = {
  userActions: PropTypes.shape({}).isRequired,
  reviews: PropTypes.shape({}).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  }).isRequired
}

const mapStateToProps = state => ({
  currentUser: state.reduxTokenAuth.currentUser,
  reviews: state.host.reviews,
  experiences: state.host.listings.past
})

const mapDispatchToProps = dispatch => ({
  formActions: bindActionCreators(formActionCreators, dispatch),
  userActions: bindActionCreators(userActionCreators, dispatch),
  hostActions: bindActionCreators(hostActionCreators, dispatch),
  modalActions: bindActionCreators(modalActionCreators, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HostReviews)
