import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import _ from 'lodash'
import { Field, reduxForm } from 'redux-form'

// Actions
import * as formActionCreators from '../../../actions/forms'
import * as hostActionCreators from '../../../actions/host'
import * as userActionCreators from '../../../actions/user'

import UploadProfileImage from '../../Registration/Common/UploadProfileImage'

// Validations
import { validateProfessional } from '../../Registration/Hosts/validate'

// FormFields
import RenderField from '../../Form/Fields/renderField'
import * as fields from '../../Form/Fields/formFields'
import { professionalFieldCollection, hostAddressFields } from '../../../constants/formFields'

import SEO from '../../Shared/Web/SEO'
import { history } from '../../../configureStore'

// images
// const heroImage1 = require('../../../images/placeholders/new-batch/become-a-host-header@2x.png')
// const shareExperiences = require('../../../images/become-a-host/share-your-creations.jpeg')

function keysMatch(key, obj) {
  const keys = Object.keys(obj).map(x => x.toLowerCase())
  return keys.indexOf(key.toLowerCase()) !== -1
}

class Step1 extends Component {
  constructor(props) {
    super(props)
    this.state = {
      professionalFields: professionalFieldCollection
    }
  }

  componentWillMount() { }

  componentDidMount = async () => {
    const { userActions, hostActions, currentUser, change, firstHostLocationObj, initialValues, hostAddressFieldsCity } = this.props
    const { role, hostApplication } = currentUser.attributes

    if (role === 'host' || hostApplication === 'complete') {
      history.push('/become-a-host')
    }

    userActions.getCuisineTypesList()
    if (currentUser.attributes && currentUser.attributes.id) {
      hostActions.getLocations()
    }

    if (initialValues && initialValues.specialties) {
      change('specialties', initialValues.specialties.map((el) => ({ id: el.id, label: el.name, value: el.id })))
    }

    if (firstHostLocationObj) {
      if (firstHostLocationObj.neighbourhood) {
        change('hostAddressFieldsNeighbourhood', firstHostLocationObj.neighbourhood)
      }
    }

    if (hostAddressFieldsCity === '' || hostAddressFieldsCity == null) {
      if (currentUser.attributes.city !== '' || currentUser.attributes.city != null) {
        const defaultCountry = hostAddressFields.city.options.find(data => data.value === currentUser.attributes.city)
        if (!defaultCountry) {
          if (firstHostLocationObj) {
            if (firstHostLocationObj.city) {
              const newData = hostAddressFields.city.options.find(data => data.value === firstHostLocationObj.city)
              if (newData) change('hostAddressFieldsCity', newData)
            }
          }
        } else {
          change('hostAddressFieldsCity', defaultCountry)
        }
      } else {
        change('hostAddressFieldsCity', {
          name: 'Dubai',
          value: 'Dubai',
          label: 'Dubai'
        })
      }
    }
  }

  handleAccountData = async () => {
    const { userActions, firstHostLocationObj, formData } = this.props
    const userData = { ...formData.hostRegistration.values }
    delete userData.foodPhotos
    delete userData.locationPhotos
    delete userData.hostingLocation
    // if (profilePicObj) {
    //   await userActions.updateProfilePic({ image: profilePicObj })
    //   await userActions.setProfileObj(null)
    // }
    if (firstHostLocationObj) {
      userData.location = {
        id: firstHostLocationObj ? firstHostLocationObj.id : null,
        neighbourhood: userData.hostAddressFieldsNeighbourhood,
        city: typeof userData.hostAddressFieldsCity === 'string' ? userData.hostAddressFieldsCity : userData.hostAddressFieldsCity.value,
        destinationLink: typeof userData.hostAddressFieldsDestinationLink === 'string' ? userData.hostAddressFieldsDestinationLink : ''
      }
    }
    return userActions.updateUserAccount(userData, false, 'fromBecomeHost')
  }

  /**
   * Disables a field if the same key is present in the currentUser object,
   * the user will not be able to update the field, but it will display the value.
   * @param  {object} fieldArray  an array of fields with keys and values.
   * @param  {object} currentUser the current signed in user
   * @return {object}             the updated field array
   */
  disableFields = fieldArray => {
    const { currentUser } = this.props
    if (typeof currentUser === 'undefined' || !currentUser.isSignedIn) {
      return fieldArray
    }
    const obj = fieldArray
    Object.keys(obj).map(key => {
      obj[key].disabled = !!keysMatch(key, currentUser.attributes)
      return obj[key]
    })
    return obj
  };

  limitText = limit => value => {
    if (value.length < 0) {
      return value
    }
    if (value.length > limit) {
      return value.slice(0, (limit - 1))
    }
    return value
  }

  render() {
    const { stepForm, cuisineTypesOptions, submitting, valid, pristine, pathname, dataError, formData } = this.props
    const { professionalFields } = this.state

    const localCuisineTypes = _.sortBy(cuisineTypesOptions
      ? cuisineTypesOptions
        .filter(el => el.status === 'active')
        .map(el => ({ label: el.name, value: el.id, id: el.id }))
      : [], 'label')

    const { specialties, culinaryJourney, socialLinks, acceptTerms } = professionalFields
    const { neighbourhood, city, destination } = hostAddressFields

    let formValues = { culinaryJourney: '', hostAddressFieldsNeighbourhood: '', hostAddressFieldsCity: '', hostAddressFieldsDestinationLink: '' }

    if (formData.hostRegistration) { formValues = formData.hostRegistration.values }

    if (pathname === '/become-a-host/step-1') {
      stepForm.page = 1
    }

    const otherRequiredFieldCheck = () => typeof formValues.hostAddressFieldsNeighbourhood === 'undefined' || typeof formValues.hostAddressFieldsCity === 'undefined'

    return (
      <div className="breakbread-container">
        <div className="section-header text-center">
          <React.Fragment>
            <SEO title="Become A Host" />
            <h1>Become A Host</h1>
            {/* <div className="form-pages">
              Step {stepForm.page} of 2 -
              {[1, 2].map(step => (
                <div
                  key={"step-"+step}
                  className={`badge ${
                    step <= stepForm.page ? 'badge--active' : 'badge--step'
                  }`}
                >
                  {step}
                </div>
              ))}
            </div> */}
            <div className="grid grid--no-gutters grid--uniform">
              <div className="grid__item small--one-whole medium-up--one-half">
                <div className="form-header">
                  <h4>Upload your photo</h4>
                  <p> Explain why breakbread need the host to upload their photo.</p>
                </div>
                {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                <UploadProfileImage {...this.props} />
              </div>
              <div className="grid__item small--one-whole medium-up--one-half ">
                <div className="form-header">
                  <h4>Tell us more about yourself</h4>
                  <p>Provide details on your culinary expertise and passions</p>
                </div>
                <div className="form-body">
                  <div className="grid grid--uniform">
                    {/* Cuisine / Specialties */}
                    <div className="grid__item small--one-whole medium-up--full">
                      <Field
                        name={specialties.name}
                        component={fields.renderSelectField}
                        disabled={submitting}
                        options={localCuisineTypes}
                        // value={}
                        placeholder={specialties.placeholder}
                        isClearable
                        isMulti
                        type="select"
                        selectType="cuisine"
                        label="Cuisine / Specialties *"
                      />
                    </div>
                    {/* Personal Info Fields */}
                    <div className="grid__item small--one-whole medium-up--full">
                      <Field
                        key={culinaryJourney.name}
                        field={culinaryJourney}
                        type={culinaryJourney.type}
                        name={culinaryJourney.name}
                        label={culinaryJourney.label}
                        value={culinaryJourney.value}
                        disabled={culinaryJourney.disabled}
                        component={fields.renderField}
                        normalize={this.limitText(1000)}
                        inputType="textArea"
                        displayLabel
                        placeholder={culinaryJourney.placeholder}
                      />
                      <span className="limittextculinary">
                        <span className="field_limit_text__limit">
                          { 1000 - (formValues && formValues.culinaryJourney ? formValues.culinaryJourney.length : 0) } characters remaining
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="grid grid--no-gutters grid--uniform">
              <div className="grid__item small--one-whole medium-up--one-half">
                <div className="form-header">
                  <h4>Let&apos;s connect</h4>
                  <p>Provide at least one of the social media link.</p>
                </div>
                <div className="form-body">
                  <div className="grid grid--uniform">
                    <div className="grid__item small--one-whole medium-up--full">
                      <fields.FieldSet
                        wrap
                        legend="Social Networks *"
                        fields={socialLinks}
                        className="flat"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="grid__item small--one-whole medium-up--one-half">
                <div className="form-header">
                  <h4>Location</h4>
                  <p>Where are you hosting your experience?</p>
                </div>
                <div className="form-body">
                  <div className="grid grid--uniform">
                    <div className="grid__item small--one-whole medium-up--full">
                      <Field
                        name="hostAddressFieldsNeighbourhood"
                        component={fields.renderField}
                        disabled={submitting}
                        // value={hostAddressFields.neighbourhood.value}
                        placeholder={neighbourhood.placeholder}
                        isClearable
                        inputType="fullText"
                        label="Neighbourhood *"
                        displayLabel
                      />
                    </div>
                    <div className="grid__item small--one-whole medium-up--full">
                      <Field
                        name="hostAddressFieldsCity"
                        component={fields.renderSelectField}
                        disabled={submitting}
                        // value={hostAddressFields.city.value}
                        options={city.options}
                        placeholder={city.placeholder}
                        isClearable
                        inputType="fullText"
                        label="City *"
                        // displayLabel={false}
                      />
                    </div>
                    <div className="grid__item small--one-whole medium-up--full">
                      <Field
                        name="hostAddressFieldsDestinationLink"
                        component={fields.renderField}
                        disabled={submitting}
                        // value={hostAddressFields.city.value}
                        placeholder={destination.placeholder}
                        isClearable
                        inputType="fullText"
                        label="Location Map Link"
                        displayLabel
                      />
                      <span className="limittexthostaddress">
                        <span className="field_limit_text__limit">{destination.helperText}</span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="grid grid--no-gutters grid--uniform">
              <div className="grid__item small--one-whole medium-up--one-full">
                <span>
                  <Field
                    name={acceptTerms.name}
                    component={RenderField}
                    type="checkbox"
                    inputType="checkbox"
                    placeholder={acceptTerms.placeholder}
                  />
                  <label htmlFor="agreeTermsAndConditions" className="inline" style={{ paddingLeft: '10px' }}>
                    <small>
                      &nbsp; I agree to BreakBread&apos;s
                      <a href="/terms-and-conditions" target="_blank">
                        &nbsp; terms and conditions
                      </a>
                    </small>
                  </label>
                </span>
              </div>
            </div>
            <div className="grid grid--no-gutters grid--uniform">
              <div className="grid__item small--one-whole medium-up--one-full">
                <div style={{ marginTop: '15px' }}>
                  <button
                    type="submit"
                    disabled={
                      submitting || pristine || !valid || otherRequiredFieldCheck() || (formValues && !formValues.acceptTerms)
                    }
                    onClick={async () => {
                      await this.handleAccountData()
                      // console.log('resp', this.props.dataError)
                      if (!dataError) {
                        history.push('/become-a-host/step-2')
                      }
                    }}
                    className="btn btn--darker"
                  >
                    Save And Continue
                  </button>
                </div>
              </div>
            </div>
          </React.Fragment>
        </div>
      </div>
    )
  }
}

Step1.propTypes = {
  stepForm: PropTypes.shape({
    page: PropTypes.number
  }).isRequired,
  valid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  userActions: PropTypes.shape({
    getCuisineTypesList: PropTypes.func,
    updateUserAccount: PropTypes.func
  }).isRequired,
  currentUser: PropTypes.shape({
    attributes: PropTypes.shape({
      role: PropTypes.string,
      hostApplication: PropTypes.string,
      id: PropTypes.string,
      city: PropTypes.string
    }),
    isSignedIn: PropTypes.bool
  }).isRequired,
  change: PropTypes.func.isRequired,
  hostActions: PropTypes.shape({
    getLocations: PropTypes.func
  }).isRequired,
  initialValues: PropTypes.shape({
    specialties: PropTypes.arrayOf(PropTypes.shape({}))
  }).isRequired,
  firstHostLocationObj: PropTypes.shape({
    neighbourhood: PropTypes.string,
    city: PropTypes.string,
    id: PropTypes.string
  }).isRequired,
  hostAddressFieldsDestinationLink: PropTypes.string,
  hostAddressFieldsCity: PropTypes.shape({}).isRequired,
  formData: PropTypes.shape({
    hostRegistration: PropTypes.string
  }).isRequired,
  cuisineTypesOptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  pristine: PropTypes.bool.isRequired,
  pathname: PropTypes.string.isRequired,
  dataError: PropTypes.shape({}).isRequired
}

const Step1Redux = reduxForm({
  form: 'hostRegistration', // <------ same form name
  destroyOnUnmount: false, // <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  validate: validateProfessional
})(Step1)

const mapStateToProps = state => ({
  pathname: state.router.location.pathname,
  stepForm: state.stepForm.hostRegistration,
  featuredHosts: state.featured.featuredHosts,
  currentUser: state.reduxTokenAuth.currentUser,
  initialValues: state.reduxTokenAuth.currentUser.attributes,
  formData: state.form,
  profilePicObj: state.user.fileUploads.profilePicObj
    ? state.user.fileUploads.profilePicObj
    : null,
  cuisineTypesOptions: state.user.amenitiesAndWowElements.cuisineTypes
    ? state.user.amenitiesAndWowElements.cuisineTypes.list
    : null,
  firstHostLocationObj: state.host.location.allLocations ? state.host.location.allLocations[0] : null
})

const mapDispatchToProps = dispatch => ({
  formActions: bindActionCreators(formActionCreators, dispatch),
  hostActions: bindActionCreators(hostActionCreators, dispatch),
  userActions: bindActionCreators(userActionCreators, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(Step1Redux)
