import React from 'react'
import { connect } from 'react-redux'
import AuthModal from './AuthModal'
import Modalbtn from '../PagesMarketing/HomeScreen/SubscriptionModal'
import RegModal from './RegModal'
import RegHostModal from './RegHostModal'
import HostConfirmationModal from './HostConfirmationModal'
import UserConfirmationModal from './UserConfirmationModal'
import BookExperienceModal from './BookExperienceModal'
import EditExperienceModal from './EditExperienceModal'
import HostReviewModal from './HostReviewModal'
import GuestReviewModal from './GuestReviewModal'
import ReceiptModal from './ReceiptModal'
import ExperienceSchedules from './ExperienceSchedules'
import ScheduleFormModal from './ScheduleFormModal'
import ReviewCommentModal from './ReviewCommentModal'
import HostLocationFormModal from './HostLocationFormModal'

const MODAL_COMPONENTS = {
  AUTH: AuthModal,
  SUBCRIPTION: Modalbtn,
  REG: RegModal,
  BECOME_HOST: RegHostModal,
  HOST_CONFIRMATION: HostConfirmationModal,
  USER_CONFIRMATION: UserConfirmationModal,
  RESERVATION: BookExperienceModal,
  EXPERIENCE: EditExperienceModal,
  HOST_REVIEW: HostReviewModal,
  GUEST_REVIEW: GuestReviewModal,
  RECEIPT: ReceiptModal,
  EXPERIENCE_SCHEDULE: ExperienceSchedules,
  EXPERIENCE_SCHEDULE_FORM: ScheduleFormModal,
  REVIEW_COMMENT_FORM: ReviewCommentModal,
  HOST_LOCATION_FORM: HostLocationFormModal
  /* other modals */
}

const ModalRoot = ({ modal }) => {
  const { modalType, modalProps, modalScheduleType, modalScheduleProps } = modal

  if (!modalType) {
    return null // after React v15 you can return null here
  }
  if (modalScheduleType !== 'EXPERIENCE_SCHEDULE_FORM') {
    const YieldModal = MODAL_COMPONENTS[modalType]
    return <YieldModal {...modalProps} />
  } else {
    const YieldModal = MODAL_COMPONENTS[modalScheduleType]
    return <YieldModal {...modalScheduleProps} />
  }
}

// export default connect(state => state.modal)(ModalRoot);

const mapStateToProps = (state) => ({
  modal: state.modal
})

export default connect(mapStateToProps, null)(ModalRoot)
