import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { reset } from 'redux-form'
import moment from 'moment'
import jsPDF from 'jspdf'
import html2canvas from 'html2canvas'
import * as modalActionCreators from '../../actions/modals'
import * as formActionCreators from '../../actions/forms'
import * as userActionCreators from '../../actions/user'
import * as transactionActionCreators from '../../actions/transactions'

import { currencyTypes } from '../../constants/currency'

// Validations
import { currentUserPropTypes } from '../../constants/composed-proptypes'

import AmericanExpressIcon from '../../icons/creditCards/AmericanExpressIcon'
import MastercardIcon from '../../icons/creditCards/MastercardIcon'
import PaypalIcon from '../../icons/creditCards/PaypalIcon'
import VisaIcon from '../../icons/creditCards/VisaIcon'

const PAYMENT_ICONS = {
  AMERICANEXPRESS: AmericanExpressIcon,
  MASTERCARD: MastercardIcon,
  PAYPAL: PaypalIcon,
  VISA: VisaIcon
  /* other icons */
}

class GuestReceipt extends Component {
  constructor(props) {
    super(props)
    const { receipt } = props.reservation
    // receipt = JSON.parse(receipt);
    this.closeAndExit = this.closeAndExit.bind(this)
  }

  handleGuestReceiptData = async ({ data, reservation }) => {
    this.closeAndExit()
  }

  async closeAndExit() {
    const { modalActions, modalCallBack } = this.props
    await modalActions.hideReceiptModal()
    if (typeof modalCallBack !== 'undefined') {
      modalCallBack()
    }
  }

  _exportPdf = () => {
    html2canvas(document.querySelector('#guest-receipt')).then(canvas => {
      document.body.appendChild(canvas) // if you want see your screenshot in body.
      const imgData = canvas.toDataURL('image/png')
      const pdf = new jsPDF()
      pdf.addImage(imgData, 'PNG', 0, 0)
      pdf.save('download.pdf')
    })
  }

  render() {
    const { currentUser, reservation } = this.props
    const receipt = {
      currency: 'AED',
      value: 0,
      card: { paymentMethod: 'Coupon', last4: '0000' },
      status: 'PAID',
      trackId: '#43537',
      created: reservation.bookedDate
    }
    const { paymentStatus } = reservation
    const currency = currencyTypes[reservation.experience.currency]
    const seatLabel = reservation.seatsBooked > 1 ? 'Seats' : 'Seat'
    const totalValue = (receipt.value / 100).toFixed(2)
    const paymentMethodType = receipt.card.paymentMethod.toUpperCase()
    const composedValue = ` ${totalValue} ${reservation.experience.currency}`
    const IsLocationable = reservation.status === 'confirmed' && reservation.paymentStatus === 'succeeded'
    const location = reservation.experience.location ? reservation.experience.location : null

    let paymentStatusColor = 'grey'
    switch (paymentStatus) {
    case 'authorised':
      paymentStatusColor = 'green'
      break
    case 'succeeded':
      paymentStatusColor = 'yellow'
      break
    case 'voided':
      paymentStatusColor = 'grey'
      break
    case 'declined':
    case 'flagged':
    case 'failed':
      paymentStatusColor = 'red'
      break
    case 'refunded':
      paymentStatusColor = 'orange'
      break
    default:
      break
    }
    let paymentStatusStyle = { backgroundColor: paymentStatusColor, fontSize: '14px', margin: '5px 5px 0px 0px', padding: '7px', color: 'white' }

    const YieldPaymentMethodIcon = PAYMENT_ICONS[paymentMethodType]
    return (
      <div className="guest-receipt-item form-body form-body--full" id="guest-receipt">
        <div className="section-header text-center">
          {/* <div
            className="chef-or-host__avatar cursor tableAvtar"
            style={{
              backgroundImage: `url(${reservation.experience.host.avatarUrl})`,
              width: '100px'
            }}
          >
            <img
              className="lazyload lazysizes avatar profileAvatarDrop__avatar hidden"
              alt={reservation.experience.host.fullname}
              style={{ width: '40%' }}
              src={reservation.experience.host.avatarUrl}
              data-src={reservation.experience.host.avatarUrl}
              data-widths="[180, 360, 540, 720, 900, 1080, 1296, 1512, 1728, 2048]"
            />
          </div>
          <div className="padded-cells" style={{ paddingLeft: 'px', paddingRight: '0px' }}>
            <h4>{reservation.experience.host.fullname} </h4>
          </div> */}
          <div className="padded-cells" style={{ paddingLeft: 'px', paddingRight: '0px' }}>
            <h4>{reservation.experience.title} </h4>
            <p>{reservation.experience.host.fullname} </p>
          </div>
        </div>
        <div className="grid">
          <div className="grid__item for-booking-detail">
            <span style={{ fontSize: '16px', fontWeight: 'bold' }}>Booking Details</span>
          </div>
          <div className="grid__item medium-up--one-half">
            <table className="responsive-table responsive-table--no-labels responsive-table--no-borders">
              <tbody>
                <tr className="responsive-table-row responsive-table--no-labels">
                  <td>Experience Date</td>
                  <td>{reservation.date}</td>
                </tr>
                <tr className="responsive-table-row">
                  <td>Time</td>
                  <td>{moment(reservation.experience.time, 'HH:mm').format('h:mm A')}</td>
                </tr>
                <tr className="responsive-table-row">
                  <td>Hosted By</td>
                  <td>{reservation.experience.host.fullname}</td>
                </tr>
                <tr className="responsive-table-row">
                  <td>Number of Seats Booked</td>
                  <td>
                    {reservation.seatsBooked} {seatLabel}
                  </td>
                </tr>
                <tr className="responsive-table-row">
                  <td>Unit Price</td>
                  <td>
                    {reservation.experience.currency} {reservation.experience.price}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="grid__item medium-up--one-half">
            <table className="responsive-table responsive-table--no-labels responsive-table--no-borders">
              <tbody>
                <tr className="responsive-table-row">
                  <td>Booked By</td>
                  <td>{reservation.guest.fullname}</td>
                </tr>
                <tr className="responsive-table-row">
                  <td>Booked Date</td>
                  <td>{reservation.bookedDate}</td>
                </tr>
                <tr className="responsive-table-row">
                  <td>Booking Number</td>
                  <td>{reservation.bookingNumber}</td>
                </tr>
                <tr className="responsive-table-row">
                  <td>Reservation Status</td>
                  <td>
                    <p className="badge">
                      {reservation.status.charAt(0).toUpperCase() + reservation.status.slice(1)}
                    </p>
                  </td>
                </tr>
                <tr className="responsive-table-row">
                  <td className="total">Payment Status</td>
                  <td>
                    {' '}
                    <p className="badge">
                      {reservation.paymentStatus ? reservation.paymentStatus.charAt(0).toUpperCase() + reservation.paymentStatus.slice(1) : ''}
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="grid">
          <div className="grid__item medium-up--one-half">
            <div className="grid">
              <div className="grid__item for-booking-detail">
                <span style={{ fontSize: '16px', fontWeight: 'bold' }}>Transaction Details</span>
              </div>
              <div className="grid__item medium-up--one-full">
                <table className="responsive-table responsive-table--no-labels responsive-table--no-borders">
                  <tbody>
                    <tr className="responsive-table-row">
                      <td>
                        {reservation.experience.title}
                        <br />
                        Seats: {reservation.seatsBooked}, Unit Price: {reservation.experience.currency} {reservation.experience.price}
                      </td>
                      <td>
                        {reservation.currency} {reservation.total.toFixed(2)}
                      </td>
                    </tr>
                    {reservation.discount ? (
                      <tr className="responsive-table-row">
                        <td>
                          <strong>Discount Against The Coupon <i>'{reservation.coupon}'</i></strong>
                        </td>
                        <td>
                          <strong>{reservation.experience.currency} {reservation.discount.toFixed(2)}</strong>
                        </td>
                      </tr>
                    ) : null}
                    {/* {reservation.tax ? (
                    <tr className="responsive-table-row">
                      <td>Tax</td>
                      <td>
                        {reservation.currency} {reservation.tax}
                      </td>
                    </tr>
                  ) : null} */}
                    <tr className="responsive-table-row">
                      <td>
                        <strong>Net Total</strong>
                      </td>
                      <td>
                        <strong>
                          {reservation.currency} {reservation.netTotal ? parseFloat(reservation.netTotal).toFixed(2) : ''}
                        </strong>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="grid__item medium-up--one-half">
            { IsLocationable && (
              <div className="grid">
                <div className="grid__item for-booking-detail">
                  <span style={{ fontSize: '16px', fontWeight: 'bold' }}>Experience Location</span>
                </div>
                <div className="grid__item medium-up--one-full">
                  <table className="responsive-table responsive-table--no-labels responsive-table--no-borders">
                    <tbody>
                      <tr className="responsive-table-row">
                        {location != null ?(
                          <td>
                            {location.street_address ? `${location.street_address}, ` : ''} {location.neighbourhood ? `${location.neighbourhood}, ` : ''}
                            <br />
                            {location.city ? `${location.city}, ` : ''}{location.country_name ? `${location.country_name} ` : location.country_code }{location.postal_code ? `- ${location.postal_code}` : ''}
                            <br />
                          </td>
                        ) : '' }
                      </tr>
                      {(location != null && location.destination_link != undefined) ?(
                        <tr className="responsive-table-row">
                          <td>
                            <strong>
                              Location Map Link:
                              <a
                                href={location.destination_link}
                                target="_blank"
                                style={{textDecoration: 'none' }}
                                rel="noreferrer"
                              >
                                {' '}{location.destination_link}
                              </a>
                            </strong>
                          </td>
                        </tr>
                      ):''}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </div>
        </div>
        {currentUser.isLoading && <div className="spinner" />}
      </div>
    )
  }
}

GuestReceipt.propTypes = {
  currentUser: currentUserPropTypes.isRequired,
  userActions: PropTypes.shape({}).isRequired,
  modalActions: PropTypes.shape({}).isRequired,
  transactionActions: PropTypes.shape({}).isRequired
}

const mapDispatchToProps = dispatch => ({
  formActions: bindActionCreators(formActionCreators, dispatch),
  userActions: bindActionCreators(userActionCreators, dispatch),
  modalActions: bindActionCreators(modalActionCreators, dispatch),
  transactionActions: bindActionCreators(transactionActionCreators, dispatch),
  resetForm: () => dispatch(reset('guestReview'))
})

const mapStateToProps = state => ({
  pathname: state.router.location.pathname,
  currentUser: state.reduxTokenAuth.currentUser
})

export default connect(mapStateToProps, mapDispatchToProps)(GuestReceipt)
