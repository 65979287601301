import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { loadReCaptcha } from 'react-recaptcha-v3'
import PhoneInput from 'react-phone-input-2'
import IntlTelInput from 'react-intl-tel-input'
import renderField from '../../Form/Fields/renderField'
import IconEmail from '../../../icons/global/SVGJS/IconEmail'
import { phoneNumber } from '../../../constants/formFields'
import OAuthButtonContainer from '../../OmniAuth/OauthButtonContainer'
// import ReCAPTCHA from "react-google-recaptcha";
import { ALL_OPTIONS, EMAIL_FORM } from '../../../constants/actionTypes'
import { EARLIEST_YEAR } from '../../../constants'
// import { loadReCaptcha } from 'react-recaptcha-v3'
import 'react-phone-input-2/lib/style.css'

import 'react-intl-tel-input/dist/main.css'

const stepFormFields = {
  firstName: {
    name: 'firstName',
    type: 'text',
    autoComplete: 'given-name',
    placeholder: 'First / Given Name'
  },
  lastName: {
    name: 'lastName',
    type: 'text',
    autoComplete: 'family-name',
    placeholder: 'Last / Family Name'
  },
  email: {
    name: 'email',
    type: 'email',
    autoComplete: 'email',
    placeholder: 'Email'
  },
  password: {
    name: 'password',
    type: 'password',
    autoComplete: 'password-new',
    addon: true,
    addonIcon: 'eye',
    placeholder: 'Password'
  }
}

class CreateAnAccount extends Component {
  constructor(props) {
    super(props)
    this.state = {
      validCaptcha: false,
      didMount: false
    }
  }

  componentDidMount() {
    loadReCaptcha('6LcWSKEaAAAAADexa-H8kNRAOXgIXLfFgM7IV7Xl', value => {})
    this.props.change('phoneMetaData', {dialCode: null,
      iso2: null,
      name: null})
    
    this.setState({didMount: true})
  }

  onChange = value => {
    this.setState({ validCaptcha: true })
  }

  render() {
    const {
      handleSubmit,
      currentUser,
      nextPage,
      oAuthSuccess,
      signUpActions,
      signUpPane,
      submitting,
      pristine,
      valid,
      userActions
    } = this.props
    /* const handleCountryCodeChange = e => {
      this.props.change('phoneCountryCode', e.target.value)
    } */

    return (
      <form onSubmit={handleSubmit} className="form-vertical">
        <div className="form-body">
          {/* {signUpPane === EMAIL_FORM && ( */}
          <React.Fragment>
            <div className="form-header">
              <h4>Sign Up</h4>
            </div>
            {Object.keys(stepFormFields).map(key => {
              const field = stepFormFields[key]
              return (
                <Field
                  key={field.name}
                  field={field}
                  type={field.type}
                  name={field.name}
                  label={field.placeholder}
                  disabled={currentUser.isSignedIn}
                  component={renderField}
                />
              )
            })}

            <div className="grid mtt_10">
              <div className="grid__item">
                {this.state.didMount?(<IntlTelInput
                  containerClassName="intl-tel-input fullWidth fullInputClass"
                  inputClassName="form-control"
                  defaultCountry={
                    currentUser.attributes.phoneMetaData != null
                      ? currentUser.attributes.phoneMetaData.iso2
                      : 'ae'
                  }
                  onSelectFlag={(flag, metadata) => {
                    this.props.change('phoneMetaData', metadata)
                  }}
                  onPhoneNumberChange={(flag, phoneNumber, metadata) => {
                    const {
                      areaCodes,
                      dialCode,
                      iso2,
                      name,
                      priority
                    } = metadata
                    if (this.state.didMount) {
                      this.props.change('phoneNumber', phoneNumber.trim())
                      this.props.change('phoneMetaData', metadata)
                      this.props.change('phoneCountryCode', dialCode)
                    }
                  }}
                />):''}
              </div>

              {/* <div className="grid__item medium-up--one-third large-up--one-third">
                  <Field
                    name="phoneCountryCode"
                    component={renderField}
                    onChange={(e) => { handleCountryCodeChange(e) }}
                    placeholder={"Country Code"}

                    inputType="fullText"
                    label="Country Code"
                    displayLabel={false}

                  />
                </div>
                <div className="grid__item medium-up--two-thirds large-up-two-thirds">
                
                    <Field
                      name="phoneNumber"
                      component={renderField}
                      placeholder={phoneNumber.placeholder}
                      type={phoneNumber.type}
                      autoComplete={phoneNumber.autoComplete}
                      label={phoneNumber.label}
                      field="phone_number"
                      displayLabel={false}
                      inputType="phnNumber"
                    />

                 
                    </div> */}
            </div>

            <div className="grid grid  flexCenter mb_10">
              {/* <ReCAPTCHA
                    sitekey="6LeCG6EaAAAAADYdr8pyvkZGTnQ6dX2uo1Upe3YK"
                    onChange={(value)=>this.onChange(value)}
                    onExpired={()=>{this.setState({validCaptcha:false});}}
                    onErrored={()=>{this.setState({validCaptcha:false});}}

                /> */}
            </div>

            {currentUser.isSignedIn && (
              <button
                className="btn btn--full"
                type="button"
                onClick={() => nextPage()}
              >
                Next
              </button>
            )}

            {!currentUser.isSignedIn && (
              <button
                type="submit"
                className="btn next btn--full"
                disabled={submitting || pristine || !valid}
                onSubmit={() => userActions.getCountryList()}
              >
                Continue
              </button>
            )}

            {/* <p className="text-center">
              <button
                type="button"
                onClick={() => signUpActions.showAllOptionsPane()}
                className="btn btn--link"
              >
                Rather use Facebook or Google? Click Here
              </button>
            </p> */}
          </React.Fragment>
          {/* // )} */}

          {/* {signUpPane === ALL_OPTIONS && (
          <React.Fragment>
            <div className="form-header">
              <h4>Create an Account</h4>
              <label> Sign up using an options below</label>
            </div>
            <p>
              <OAuthButtonContainer
                text="Register using Facebook"
                handleSuccess={() => oAuthSuccess()}
                provider="facebook"
                action="Continue"
                extraClassName="btn--full"
              />
            </p>
            <p>
              <OAuthButtonContainer
                text="Register using Google"
                handleSuccess={() => oAuthSuccess()}
                provider="google_oauth2"
                action="Continue"
                extraClassName="btn--full"
              />
            </p>
            <p>
              <button
                type="button"
                onClick={() => signUpActions.showEmailPane()}
                className="btn btn--email btn--has-icon-before btn--full"
              >
                <IconEmail /> Email
              </button>
            </p>
          </React.Fragment>
        )} */}
        </div>
      </form>
    )
  }
}

CreateAnAccount.propTypes = {
  signUpActions: PropTypes.shape({}).isRequired,
  currentUser: PropTypes.shape({}).isRequired,
  signUpPane: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  nextPage: PropTypes.func.isRequired,
  oAuthSuccess: PropTypes.func.isRequired
}

export default CreateAnAccount
