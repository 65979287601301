import {
  SET_EXPERIENCE,
  EXPERIENCE_LOADING,
  EXPERIENCE_UPDATING
} from '../../constants/actionTypes'

const initialState = {
  data: null,
  loading: false,
  updating: false
}

export default function(state = initialState, action) {
  if (action.type === EXPERIENCE_LOADING) {
    const { loading } = action
    return {
      ...state,
      loading
    }
  }
  if (action.type === EXPERIENCE_UPDATING) {
    const { updating } = action
    return {
      ...state,
      updating
    }
  }
  if (action.type === SET_EXPERIENCE) {
    const { experience } = action
    return {
      ...state,
      data: {
        ...experience
      }
    }
  }
  return state
}
