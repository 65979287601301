import api from '../utils/api'

import { SET_CUISINE_TYPES } from '../constants/actionTypes'

// import { normalizeFeatured } from '../utils/normalization';

export function setCuisine(cuisineItems) {
  return { type: SET_CUISINE_TYPES, cuisineItems }
}

export function getCuisine() {
  return dispatch => {
    api(`masters/cuisine_types`)
      .then(response => {
        const json = response.data
        if (response.status === 200) {
          dispatch(setCuisine(json))
        } else {
          // console.log(json.error);
        }
      })
      .catch(e => console.log(e)) /* eslint-disable-line no-console */
  }
}
