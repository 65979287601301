import React from 'react'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import GetPaymentToken from '../../Form/Fields/GetPaymentToken'
/**
 * HOC that wraps the same common step so we can be DRY
 * @param {Object} props
 */
const PaymentForm = props => (
  <React.Fragment>
    <div className="form-body">
      <div className="form-header">
        <h4>Add a payment method</h4>
      </div>
      <GetPaymentToken {...props} />
    </div>
    <div className="form-footer">
      <button
        id="pay-now-button"
        className="btn btn--darker form-footer--right"
        type="submit"
        form="payment-form"
        disabled
      >
        Add Payment Method
      </button>
      <button
        type="button"
        className="btn btn--link form-footer--left"
        onClick={() => props.nextPage()}
      >
        Cancel
      </button>
    </div>
  </React.Fragment>
)

// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
const paymentForm = reduxForm({
  form: 'wallet',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true // <------ unregister fields on unmount
  // validate
})(PaymentForm)

// You have to connect() to any reducers that you wish to connect to yourself
const connectedPaymentForm = connect(state => ({
  // initialValues: state.paymentMethods
  // pull initial values from account reducer
}))(paymentForm)

export default connectedPaymentForm
