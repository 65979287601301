import React from "react"

const Sesame = props => (
  <svg viewBox="0 0 88 88" width="1em" height="1em" {...props}>
    <g fill="currentColor" data-name="Layer 2">
      <path d="M54 37.49a3.37 3.37 0 0 0-4.49 1.57l-.31.66a2.14 2.14 0 0 1-.76.86c-.35.22-.7.47-1 .73a23.26 23.26 0 0 0-3-6.93 2.78 2.78 0 0 1-.47-1.5V32a4.22 4.22 0 0 0-4-4 4.22 4.22 0 0 0-4 4v.89a2.86 2.86 0 0 1-.46 1.5A21.93 21.93 0 0 0 32 46c0 5.64 3 9 8 9h.07a6.53 6.53 0 0 0 3.78 4.25A7.52 7.52 0 0 0 47 60a6 6 0 0 0 3.56-1.18c3.89-2.81 5.28-9.71 4.65-15.07a2.15 2.15 0 0 1 .16-1.14l.31-.66A3.36 3.36 0 0 0 54 37.49zM34 46a19.9 19.9 0 0 1 3.22-10.53 4.83 4.83 0 0 0 .78-2.59V32a2.24 2.24 0 0 1 2-2 2.24 2.24 0 0 1 2 2v.89a4.83 4.83 0 0 0 .78 2.58A19.9 19.9 0 0 1 46 46c0 3.19-1.06 7-6 7s-6-3.82-6-7zm19.88-4.9l-.31.66a4.07 4.07 0 0 0-.33 2.24c.63 5.4-1.05 11.21-3.84 13.22a4.37 4.37 0 0 1-4.7.23 4.62 4.62 0 0 1-2.61-2.66c3.72-.83 5.9-4 5.91-8.77a18.72 18.72 0 0 0-.17-2.46 18.68 18.68 0 0 1 1.72-1.28A4.14 4.14 0 0 0 51 40.57l.31-.66a1.4 1.4 0 0 1 2.53 1.18z" />
      <path d="M37 44a1 1 0 1 0-1-1 1 1 0 0 0 1 1zM41 50a1 1 0 0 0-1-1c-1.08 0-2-1.37-2-3a1 1 0 0 0-2 0c0 2.76 1.79 5 4 5a1 1 0 0 0 1-1zM50.15 52a1 1 0 0 0-1.26.64A3.57 3.57 0 0 1 46 55a1 1 0 0 0 0 2 5.55 5.55 0 0 0 4.79-3.69 1 1 0 0 0-.64-1.31z" />
      <path d="M44 88a44 44 0 1 1 44-44 44.05 44.05 0 0 1-44 44zm0-86a42 42 0 1 0 42 42A42 42 0 0 0 44 2z" />
    </g>
  </svg>
)

export default Sesame
