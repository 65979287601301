import { REHYDRATE } from 'redux-persist'

import {
  PAYMENT_METHOD_TOKEN_FORM_STATUS,
  SET_PAYMENT_TOKEN,
  PAYMENT_METHOD_STATUS,
  SET_PREFERRED_PAYMENT_METHOD_INFO,
  AUTH_TOKEN_VERIFIED,
  AUTH_SIGNIN_SUCCESS,
  AUTH_SIGNOUT_SUCCESS,
  AUTH_TOKEN_FAILED
} from '../constants/actionTypes'

import { PaymentMethodStatus, TokenFormStatus } from '../constants/statuses'

// import TokenFormStatus from '../actions/paymentMethods';

const initialState = {
  paymentMethod: null,
  tokenStatus: TokenFormStatus.NOT_SENT,
  status: PaymentMethodStatus.NOT_SENT,
  latestToken: null // NOTE THAT THIS WILL EXPIRE
}

export default function(state = initialState, action) {
  if (action.type === AUTH_TOKEN_VERIFIED) {
    return {
      ...state,
      paymentMethod: action.payload.userAttributes.defaultCard
    }
  }

  if (action.type === AUTH_SIGNIN_SUCCESS) {
    return {
      ...state,
      paymentMethod: action.payload.userAttributes.defaultCard
    }
  }

  switch (action.type) {
  case PAYMENT_METHOD_TOKEN_FORM_STATUS:
    return {
      ...state,
      tokenStatus: action.payload
    }
  case SET_PAYMENT_TOKEN:
    return {
      ...state,
      latestToken: action.payload
    }
  case PAYMENT_METHOD_STATUS:
    return {
      ...state,
      status: action.payload
    }
  case SET_PREFERRED_PAYMENT_METHOD_INFO:
    return {
      ...state,
      paymentMethod: action.payload
    }
  case AUTH_SIGNOUT_SUCCESS:
    return initialState

  case AUTH_TOKEN_FAILED:
    return initialState

  default:
    return state
  }

  // if (action.type === REHYDRATE) {
  //   // const savedUser = action.payload.user || state;
  //   // return {
  //   //   ...state,
  //   //   accessToken: savedUser.accessToken,
  //   //   profile: savedUser.profile,
  //   //   payment: savedUser.payment
  //   // };
  // }
  //
  // return state;
}
