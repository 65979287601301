import React from "react"

const Spicy = props => (
  <svg viewBox="0 0 88 88" width="1em" height="1em" {...props}>
    <g fill="currentColor" data-name="Layer 2">
      <path d="M51.91 32.08A5 5 0 0 0 47 28a1 1 0 0 0 0 2 3 3 0 0 1 2.85 2.11A6 6 0 0 0 45 38v5a11 11 0 0 1-11 11 3 3 0 0 0 0 6h1a22 22 0 0 0 22-22 6 6 0 0 0-5.09-5.92zM35 58h-1a1 1 0 0 1 0-2 13 13 0 0 0 13-13v-3.28l1.92-.64 1.63.81a1 1 0 0 0 .9 0l2-1a1 1 0 1 0-.9-1.78l-1.55.77-1.55-.77a1 1 0 0 0-.77-.06L47 37.6a4 4 0 0 1 8 .4 20 20 0 0 1-20 20z" />
      <path d="M44 88a44 44 0 1 1 44-44 44.05 44.05 0 0 1-44 44zm0-86a42 42 0 1 0 42 42A42 42 0 0 0 44 2z" />
    </g>
  </svg>
)

export default Spicy
