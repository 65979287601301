import React, { Component } from 'react'
import { Field } from 'redux-form'
import PropTypes from 'prop-types'

/**
 * Retrieves the selection from a value
 * @param  {object} props   object containing component props
 * @param  {array} options the react select values
 * @return {array}         values mutated for react-select
 */

export default class CheckboxGroup extends Component {
  static propTypes = {
    options: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired
      })
    ).isRequired
  };

  field = ({ input, meta, options, label }) => {
    const { name, onChange, onBlur, onFocus } = input
    const { touched, error } = meta
    const inputValue = input.value
    const fieldGroupLabel = label
    const checkboxes = options.map(({ label, value, id }, index) => {
      const mainValue = { label, value, id}
      const handleChange = event => {
        const arr = [...inputValue]
        if (event.target.checked) {
          arr.push(mainValue)
        } else {
          arr.splice(arr.findIndex(el=>el.id === id), 1)
        }
        onBlur(arr)
        return onChange(arr)
      }

      const filteredOption =
        typeof inputValue === 'object'
          ? inputValue.filter(object => object.id === id)
          : ''
      const checked = filteredOption.length > 0?true: false
      
      return (
        <label key={`checkbox-${name}-${index}`}>
          <input
            type="checkbox"
            name={`${name}[${index}]`}
            value={mainValue}
            checked={checked}
            onChange={handleChange}
            onFocus={onFocus}
          />
          {'  '}

          <div>
            {label === 'BYOW' ? (
              <i style={{ fontSize: '24px' }} className="fas">
                &#xf79f;
              </i>
            ) : (
              ''
            )}
          </div>
          <div  className="tooltip">{label}
            {label === 'BYOW' ?  <span className="tooltiptext">Bring your own wine</span> :""}
          </div>
        </label>
      )
    })

    return (
      <React.Fragment>
        <label>{fieldGroupLabel}</label>
        <div className="checkboxGroup">{checkboxes}</div>
        {touched && error && <p className="error">{error}</p>}
      </React.Fragment>
    )
  };

  render() {
    return <Field {...this.props} type="checkbox" component={this.field} />
  }
}
