import React from 'react'
import Modal from 'react-bootstrap/lib/Modal'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { signOutUser } from '../../actions/sessions'
import Swal from 'sweetalert2/dist/sweetalert2'

/* Action Creators */
import * as modalActionCreators from '../../actions/modals'

// Registration
import EditExperienceForm from '../Workflows/ExperienceEdit/StepExperienceForm'
import CreateExperienceForm from '../Workflows/ExperienceCreate/StepExperienceForm'

const Experience = props => {

  const { show, modalActions, stepForm, type } = props
  const modalSize = 'large'

  const closeModal = async () => {
    const confirmationModal = Swal.mixin({
      confirmButtonClass: 'btn',
      cancelButtonClass: 'btn btn--white',
      buttonsStyling: false,
      customClass: 'confirmationModal'
    })

    await confirmationModal({
      title: 'Are you sure you want to close it',
      html: `<h6>Your changes will be lost</h6>`,
      type: '',
      showCancelButton: true,
      confirmButtonText: 'YES',
      confirmButtonDisplay: 'block',
      cancelButtonDisplay: 'block',
      padding: '3rem'
    }).then(result => {
      if (result.value) {
        modalActions.hideCreateExperienceModall()
      }

    })
  }


  if (type === 'create') {
    return (
      <Modal
        show={show}
        // onHide={() => modalActions.hideCreateExperienceModall()}
        animation={false}
        bsSize="large"
        backdrop={false}
        backdropClassName=""
      >
        <Modal.Header closeButton onClick={closeModal} />
        <Modal.Body>
          <CreateExperienceForm />
        </Modal.Body>
      </Modal>
    )
  }

  return (
    <Modal
      show={show}
      onHide={() => modalActions.hideEditExperienceModall()}
      animation={false}
      bsSize={modalSize}
      backdrop={false}
      backdropClassName=""
    >
      <Modal.Header closeButton />
      <Modal.Body>
        <EditExperienceForm />
      </Modal.Body>
    </Modal>
  )
}

Experience.defaultProps = {
  stepForm: {
    page: 1
  }
}
Experience.propTypes = {
  show: PropTypes.bool.isRequired,
  modalActions: PropTypes.shape({}).isRequired,
  stepForm: PropTypes.shape({})
}

const mapStateToProps = state => ({
  currentUser: state.reduxTokenAuth.currentUser,
  stepForm: state.stepForm.experience
})

const mapDispatchToProps = dispatch => ({
  modalActions: bindActionCreators(modalActionCreators, dispatch)
})
export default connect(mapStateToProps, mapDispatchToProps)(Experience)
