import { SET_CUISINE_TYPE_FILTER_VALUES } from '../../constants/actionTypes'

const initialState = []

export default function(state = initialState, action) {
  const { cuisineTypes } = action
  if (action.type === SET_CUISINE_TYPE_FILTER_VALUES) {
    return [...state, ...cuisineTypes]
  }

  return state
}
