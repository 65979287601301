import React, { Component } from 'react'
import { connect } from 'react-redux'
import AccountsWrapper from './AccountsWrapper'
import AccountForm from '../Workflows/Account/AccountForm'
import reactNavigation from 'react-navigation'
import seo_meta from '../../utils/seo-meta.json'
import { bindActionCreators } from 'redux'
import * as userActionCreators from '../../actions/user'
import SEO from '../Shared/Web/SEO'

class BankingDetails extends Component {

  constructor(props) {
    super(props)
    this.state = {
      formType: "account"
    }
  }

  componentDidMount = () => {
  }

  componentWillMount() { }

  render() {
    let {currentUser} = this.props
    return (
      <React.Fragment>
        <div className="breakbread-container">
          <SEO title="Bank Account Details" description="Bank Account Details" />
          <div className="grid grid--uniform " >
            <div className="grid__item ">
              <div className="">
                <div className="page-header text-center">
                  <h1>Bank Account Details</h1>
                </div>
                <div className="account">
                  <AccountForm form="bank_details" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  
  currentUser: state.reduxTokenAuth.currentUser
})

const mapDispatchToProps = dispatch => ({
  userActions: bindActionCreators(userActionCreators, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BankingDetails)
