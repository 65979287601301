import qs from 'qs'
import api from '../../utils/api'
// import { getUserAttributesFromResponse } from '../../utils/auth-tools';
// import { resetRoute } from './nav';
/* eslint-disable-next-line no-unused-vars */
// import { ActiveStorageUpload } from '../../utils/activeStorageUpload';
import alertModal from '../../utils/alert'
// import { camelToSnake, snakeToCamel } from '../../utils/camelSnake';
import {
  normalizeExperiences,
  normalizeProfile
} from '../../utils/normalization'

const SET_MY_EXPERIENCES = 'SET_MY_EXPERIENCES'

export function setMyExperiences(experiences) {
  return { type: SET_MY_EXPERIENCES, payload: experiences }
}

export function getMyExperiences() {
  return (dispatch, getState) =>
    api('experiences', {
      params: {},
      paramsSerializer(params) {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
      .then(response => {
        const json = response.data
        // console.log('getExperiences', json);

        if (json.is_success) {
          dispatch(setMyExperiences(normalizeExperiences(json.experiences)))
        } else {
          // console.log('getExperiences failed', json);
        }
      })
      .catch(err => {
        // alertModal(err.response, 'error', 'Try Again')
        throw err
      })
}
