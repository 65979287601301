import React from 'react'
import PropTypes from 'prop-types'

import Review from '../../Review/ReviewItem'

const ReviewsByMe = props => {
  const { reviews } = props

  return (
    <div className="breakbread-container">
      <h5 className="text-center">You said about your guests:</h5>
      {typeof reviews !== 'undefined' &&
        reviews.length &&
        reviews.map(item => (
          <Review key={"Review-"+item.id} review={item} user={item.guest} byMe={true} />
        ))}
    </div>
  )
}

ReviewsByMe.propTypes = {
  reviews: PropTypes.arrayOf(PropTypes.shape({})).isRequired
}

export default ReviewsByMe
