import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
// import { reset } from 'redux-form'
// import moment from 'moment'
// import jsPDF from 'jspdf'
// import html2canvas from 'html2canvas'
// import * as htmlToImage from 'html-to-image'
// import { toPng, toJpeg, toBlob, toPixelData, toSvg } from 'html-to-image'

import ExperienceCard from '../Experiences/ExperienceCard'
// import * as modalActionCreators from '../../actions/modals'
// import * as formActionCreators from '../../actions/forms'
import * as userActionCreators from '../../actions/user'
import * as transactionActionCreators from '../../actions/transactions'
import { getReservationDetail } from '../../actions/reservation'
import { showReceiptModal } from '../../actions/modals'
// import { currencyTypes } from '../../constants/currency'

// Validations
import { currentUserPropTypes } from '../../constants/composed-proptypes'

// import AmericanExpressIcon from '../../icons/creditCards/AmericanExpressIcon'
// import MastercardIcon from '../../icons/creditCards/MastercardIcon'
// import PaypalIcon from '../../icons/creditCards/PaypalIcon'
// import VisaIcon from '../../icons/creditCards/VisaIcon'
// import PrintReciept from '../Receipt/PrintReciept'
import SEO from '../Shared/Web/SEO'

// const PAYMENT_ICONS = {
//   AMERICANEXPRESS: AmericanExpressIcon,
//   MASTERCARD: MastercardIcon,
//   PAYPAL: PaypalIcon,
//   VISA: VisaIcon
//   /* other icons */
// }

class ReservationDetail extends Component {
  constructor(props) {
    super(props)
    this.state = {
      // ReservationDetail: null
    }
    // const { receipt } = props.reservation;
    // receipt = JSON.parse(receipt);
    // this.closeAndExit = this.closeAndExit.bind(this);
  }

  /*
  componentDidMount = () => {

    console.log("this.props.match.params",this.props.match.params);
    if (this.props.match.params.reservationId) {
      this.props.getReservationDetail(this.props.match.params.reservationId);

    }

  }
*/
  componentWillMount = () => {
    // console.log("this.props.match.params",this.props.match.params);
    if (this.props.match.params.reservationId) {
      this.props.getReservationDetail(this.props.match.params.reservationId)
    }
  }

  exportPdf = () => {
    /* html2canvas(document.querySelector("#guest-receipt")).then(canvas => {
       document.body.appendChild(canvas);  // if you want see your screenshot in body.
       const imgData = canvas.toDataURL('image/png');
       const pdf = new jsPDF();
       pdf.addImage(imgData, 'PNG', 0, 0);
       pdf.save("download.pdf");
   }); */
    /*
       htmlToImage.toPng(document.getElementById('myPage'), { quality: 0.95 })
       .then(function (dataUrl) {
         var link = document.createElement('a');
         link.download = 'my-image-name.jpeg';
         const pdf = new jsPDF();
         pdf.addImage(dataUrl, 'PNG', 0, 0);
         pdf.save("download.pdf");
       })
      */
  }

  render() {
    const { currentUser, reservation } = this.props

    // const receipt = {
    //   currency: 'AED',
    //   value: 0,
    //   card: { paymentMethod: 'Coupon', last4: '0000' },
    //   status: 'PAID',
    //   trackId: '#43537',
    //   created: reservation != null ? reservation.bookedDate : null
    // }
    // const currency = currencyTypes[reservation.experience.currency];
    const seatLabel = reservation != null && reservation.seatsBooked > 1 ? 'Seats' : 'Seat'
    // const totalValue = (receipt.value / 100).toFixed(2)
    return (
      <div className="breakbread-container">
        <SEO title={reservation.experience.title} />

        <div className="section-header text-center" />

        <div id="guest-receipt" style={{ display: 'none' }} />

        {currentUser.isLoading && <div className="spinner" />}
        {reservation != null ? (
          <div className="guest-receipt-item form-body form-body--full">
            <ExperienceCard
              experience={reservation.experience}
              featured
              displaySoldStatus
            />
            <div className="page-header ">
              <h1>Reservation Detail </h1>
              <h5 className="text-primary">({reservation.bookingNumber})</h5>
            </div>

            <div className="grid">
              <div className="grid__item medium-up--full alignLeft mb_10 ">
                <h5 className=" text-uppercase">Booking Information</h5>{' '}
              </div>

              <div className="grid__item ">
                <table className="responsive-table bookingInfo  ">
                  <tbody>
                    <tr className="responsive-table-row">
                      <th>Number of Seats </th>
                      <td>
                        {reservation.seatsBooked} {seatLabel}
                      </td>

                      <th>Booking Number</th>
                      <td>{reservation.bookingNumber}</td>
                    </tr>

                    <tr className="responsive-table-row">
                      <th>Booked By</th>
                      <td>{reservation.guest.fullname}</td>

                      <th>Booking Status</th>
                      <td>{reservation.status}</td>
                    </tr>
                    <tr className="responsive-table-row">
                      <th>Booked Date</th>
                      <td>{reservation.bookedDate}</td>

                      <th className="total">Payment Status</th>
                      <td>{reservation.status}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className="grid mb_10">
              <div className="grid__item medium-up--one-half alignLeft ">
                <h5
                  className="cursor text-uppercase"
                  onClick={() => {
                    this.props.showModal(reservation)
                  }}
                >
                  {' '}
                  Reciept{' '}
                </h5>
              </div>
              <div className="grid__item medium-up--one-half alignRight mb_10">
                {' '}
                <button
                  className="btn btn--smaller"
                  onClick={() => {
                    this.exportPdf()
                  }}
                  type="button"
                >
                  Print Reciept
                </button>
              </div>

              <div className="grid__item mtt_10">
                <table className="responsive-table  bookingInfo ">
                  <tbody>
                    <tr>
                      <th>{/* Description */}Transaction Details</th>
                      {/* <th>Quantity</th>

                      <th>Unit Price</th> */}
                      <th>Amount</th>
                    </tr>

                    <tr className="responsive-table-row">
                      <td>
                        {/* {reservation.seatsBooked} seats for the experience ' */}
                        {reservation.experience.title},
                        <br />
                        seats: {reservation.seatsBooked}, Unit Price: {reservation.experience.currency}{' '}{reservation.experience.price}
                      </td>
                      {/* <td>{reservation.seatsBooked}</td>
                      <td>
                        {reservation.experience.price}{' '}
                        {reservation.experience.currency}
                      </td> */}
                      <td>
                        {(
                          reservation.experience.price * reservation.seatsBooked
                        ).toFixed(2)}{' '}
                        {reservation.experience.currency}
                      </td>
                    </tr>

                    <tr className="responsive-table-row ">
                      <td>Service Charge</td>
                      {/*   <td>1</td>
                      <td>0.00</td> */}
                      <td>0.00</td>
                    </tr>
                    <tr className="responsive-table-row">
                      {/*  <td colSpan="2" /> */}
                      <td>Total</td>
                      <td>
                        {(
                          reservation.experience.price * reservation.seatsBooked
                        ).toFixed(2)}{' '}
                        {reservation.experience.currency}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        ) : (
          'No reservation found1.'
        )}
      </div>
    )
  }
}

ReservationDetail.propTypes = {
  currentUser: currentUserPropTypes.isRequired,
  userActions: PropTypes.shape({}).isRequired,
  transactionActions: PropTypes.shape({}).isRequired
}

const mapDispatchToProps = dispatch => ({
  userActions: bindActionCreators(userActionCreators, dispatch),
  getReservationDetail: reservationId => dispatch(getReservationDetail(reservationId)),
  transactionActions: bindActionCreators(transactionActionCreators, dispatch),
  showModal: state => {
    dispatch(showReceiptModal({ item: state, receiptType: 'guest' }))
  }
})

const mapStateToProps = state => ({
  pathname: state.router.location.pathname,
  currentUser: state.reduxTokenAuth.currentUser,
  reservation: state.reservationRequest.reservationDetail
})

export default connect(mapStateToProps, mapDispatchToProps)(ReservationDetail)
