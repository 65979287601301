import React from 'react'
import PropTypes from 'prop-types'
import {
  Elements,
  StripeProvider
} from 'react-stripe-elements'

// const STRIPE_API_KEY = 'pk_live_KAUVmXYiGpDTp4lZRqKpzxef00EByuleyk' // Breakbread UK
const STRIPE_API_KEY = 'pk_live_51Jr33KDMrVQHYBBoPp4xpXPRluLIYqKJgC73ROrYpjmMp4HexOeq9pyykr6cYdbVMTAqHvJjHRkMVVYcgm8Nqg3n00ZVYbwb69' // Breakbread UAE

export default function StripeWrapper(props) {
  const { children } = props
  return (
    <StripeProvider apiKey={STRIPE_API_KEY}>
      <Elements>
        {children}
      </Elements>
    </ StripeProvider>
  )
}

StripeWrapper.propTypes = {
  children: PropTypes.node.isRequired
}
