import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class CardInfo extends Component {
  // constructor(props) {
  //   super(props);
  // }
  componentDidMount() {}

  componentWillUnmount() {}

  render() {
    const { person, hideInfo } = this.props
    return (
      <React.Fragment>
        <div className="guest_card__guest">
          <div className="guest_card__guest--name">{person.fullname}</div>
        </div>
      </React.Fragment>
    )
  }
}

CardInfo.defaultProps = {
  // featured: false,
  // hideAvatar: false,
  // forceHoverState: false,
  // mobileHeroImage: null,
  // heroVideo: null,
}
CardInfo.propTypes = {
  // person: PropTypes.shape({
  //   image: PropTypes.string.isRequired,
  //   rating: PropTypes.string.isRequired
  // }).isRequired
}
