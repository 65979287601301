import React, { Component } from 'react'
import { Link } from 'react-router-dom'
// import { withRouter } from 'react-router'
import seoMeta from '../../utils/seo-meta.json'
// import * as modalActionCreators from '../../actions/modals'
import SEO from '../Shared/Web/SEO'

class AboutUs extends Component {
  componentWillMount() {
  }

  componentDidMount() {
  }

  render() {
    const { title, description } = seoMeta.about_us

    return (
      <div className="breakbread-container">
        <SEO title={title} description={description} />

        <div className="page-header ">
          <h1>About Us</h1>
        </div>

        <div className="page-body grid grid--uniform ">

          <div className="grid__item medium-up--three-twelfths large-up-three-twelfths navbar-collapse collapse displayDesktop">
            <h4> </h4>
            <ul className="list-unstyled customList">
              <li ><Link to="/about-us">About Us</Link></li>
              <li >
                <Link to="/terms-and-conditions">Terms &amp; Conditions</Link>
              </li>
              <li ><Link to="/privacy-policy">Privacy Policy</Link>
              </li>
              <li><Link to="/cookie-policy">Cookie Policy</Link></li>
            </ul>
          </div>

          <div className="grid__item medium-up--nine-twelfths large-up-nine-twelfths policyPara">

            <p className="c1">
              <span className="c0">
                BreakBread is a digital platform, marketplace and hub for curated
                home-cooked food experiences with a mission to unite and connect people,
                chefs and food lovers around a central idea, &lsquo;food is best
                experienced home-cooked and shared&rsquo;.
              </span>
            </p>
            <p className="c1">
              <span className="c0">
                Historically, cooking and eating food together has always united people
                across cultures and backgrounds, and its ability to exceed its unity and
                act as a catalyst for human interaction and engagement cannot be
                understated. The nostalgia of a meal shared with friends and family in a
                home unites and inspires us to revive, cultivate and share great home
                cooked food experiences.
              </span>
            </p>
            <p
              className="c1"
              id="h.gjdgxs"
            >
              <span className="c0">
                We provide our diners with a simple and easy food discovery experience
                that is at its core, nostalgic, uncomplicated and accessible. We are a
                trusted hub for quality, relevant, contemporary and authentic home
                cooked food experiences.
              </span>
            </p>
            <p className="c1">
              <span className="c0">
                We endeavor to be a useful member of the food industry. We cultivate and
                encourage a passion in the professionals, amateur chefs, as well as the
                food lovers we support, by providing a platform that prioritizes low
                risk experimentation, group engagement, growth and entrepreneurship.
              </span>
            </p>
            <p className="c1">
              <span className="c0">
                In all our endeavors we strive to boldly but humbly break the mold and
                promise our chefs and diners one thing above all else - A platform that
                offers an engaging brand experience driven by the &lsquo;simple
                pleasures of great food experiences shared in a setting that feels like
                home&rsquo;.
              </span>
            </p>
            <p className="c1">
              <span className="c0">We break bread together.</span>
            </p>

          </div>
        </div>

      </div>
    )
  }
}

export default AboutUs
