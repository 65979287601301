import React from 'react'
import PropTypes from 'prop-types'
import {
  WhatsappShareButton,
  EmailShareButton,
  FacebookShareButton,
  TwitterShareButton
} from "react-share"
import Icon from '../Shared/Web/Icon'

const ReferralCouponBox = ({ referralCoupon, currentUser, copiedId, copyToClipboard }) => {
  const { attributes } = currentUser
  const { firstName } = attributes
  const { code, couponValue } = referralCoupon
  
  const title = "Refer & Earn"
  const emailSubject = title;
  const url = "https://www.breakbread.com"
  const whatsAppDescText = `Hey! I'm inviting you on a unique culinary adventure like no other! Use my promocode *${code}* and get a *${couponValue}* OFF your first experience! Let's BreakBread together!`
  const descriptionText = `Hey! I'm inviting you on a unique culinary adventure like no other! Use my promocode ${code} and get a ${couponValue} OFF your first experience! Let's BreakBread together!`

  return (
    <div className="breakbread-container">
      <section className="experience-single__overview">
        <div className="grid__item medium-up--one-full">
          <div className="grid">
            <div className="coupon_grid__item">
              <div className="couponOverview panel panel-default">
                <div className="panel-body">
                  <h3 className="bb-host-page"><b>{title}</b></h3>
                  <p style={{fontColor: 'black', fontSize: "20px" }}>
                    Hey {firstName}, do you love to share good food with family and friends? Share with them your own referral code <b>{code}</b> and you both will receive <b>{couponValue} OFF</b> on your next experience!
                  </p>
                  <ul className='list-icon-inline'>
                    <li>
                      <WhatsappShareButton url={url} title={whatsAppDescText} className='social-share--list-button'>
                        <Icon iconName='WhatsApp' width="32" height="32" />
                      </WhatsappShareButton>
                    </li>
                    <li>
                      <FacebookShareButton url={url} quote={descriptionText} className='social-share--list-button'>
                        <Icon iconName='Facebook' width="32" height="32" />
                      </FacebookShareButton>
                    </li>
                    <li>
                      <TwitterShareButton url={url} title={descriptionText} className='social-share--list-button'>
                        <Icon iconName='Twitter' width="32" height="32"/>
                      </TwitterShareButton>
                    </li>
                    <li>
                      <EmailShareButton subject={emailSubject} body={descriptionText} className='social-share--list-button'>
                        <Icon iconName='Email' width="32" height="32" />
                      </EmailShareButton>
                    </li>
                    <li>
                      <a onClick={() => copyToClipboard(descriptionText, 'description') } className='social-share--list-button' title={copiedId === 'description' ? 'Copied to Clipboard' : 'Click to Copy'}>
                        <Icon iconName={(copiedId == 'description') ? 'Copied' : 'Copy'} width="32" height="32" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

ReferralCouponBox.propTypes = {
  referralCoupon: PropTypes.shape({
    code: PropTypes.string.isRequired,
    couponValue: PropTypes.string.isRequired,
  }),
  copyToClipboard: PropTypes.func.isRequired,
  currentUser: PropTypes.shape({
    attributes: PropTypes.shape({
      firstName: PropTypes.string.isRequired
    })
  })
}

export default ReferralCouponBox