import React, { Component } from 'react'
import { Field } from 'redux-form'
import PropTypes from 'prop-types'
import IntlTelInput from 'react-intl-tel-input'

import { currentUserPropTypes } from '../../../../constants/composed-proptypes'
import * as fields from '../../Fields/formFields'
import RenderDateField from '../../Fields/renderDateField'
import {
  backgroundFieldCollection,
  personalFieldCollection,
  addressFieldCollection,
  email,
  phoneNumber
} from '../../../../constants/formFields'

import seoMeta from '../../../../utils/seo-meta.json'

import AvatarUpload from '../../Fields/AvatarUpload'
import SEO from '../../../Shared/Web/SEO'
import './AccountDetails.css'

import 'react-intl-tel-input/dist/main.css'

function keysMatch(key, obj) {
  const keys = Object.keys(obj).map(x => x.toLowerCase())
  return keys.indexOf(key.toLowerCase()) !== -1
}

/* NOTE: if currentUser form values match,they will be updated via
 * the initialValues of connectedStep3 which connects to Redux.
 * this happens automagically for all fields on the redux connected
 * form hostRegistration
 * @see https://redux-form.com/7.0.2/examples/initializefromstate/
 */

class AccountDetailsHost extends Component {
  constructor(props) {
    super(props)

    if (props.disableFields) {
      this.state = {
        personalFields: personalFieldCollection,
        backgroundFields: this.disableFields(backgroundFieldCollection),
        addressFields: this.disableFields(addressFieldCollection),
        email: this.disableFields({ email }).email,
        phoneNumber: this.disableFields({ phoneNumber }).phoneNumber,
        maxNumber: '',
        veriFyEmailsent: false,
        show: false,
        confirmBox: false,
        verifyOtp: '',
        otPerror: '',
        didMount: false
      }
    } else {
      this.state = {
        personalFields: personalFieldCollection,
        backgroundFields: backgroundFieldCollection,
        addressFields: addressFieldCollection,
        email,
        phoneNumber,
        maxNumber: '',
        veriFyEmailsent: false,
        show: false,
        confirmBox: false,
        verifyOtp: '',
        otPerror: '',
        didMount: false
      }
    }
  }

  componentDidMount = async () => {
    const { currentUser, displayCurrency, updateAvatarStatus, showPopup } = this.props
    this.setState({ didMount: true })
    // getUser()

    const avatarPresent = typeof currentUser.attributes.avatarUrl !== 'undefined'
      && !currentUser.attributes.avatarUrl.includes('/thumb_default.png')

    if (!displayCurrency) {
      if (!avatarPresent) {
        updateAvatarStatus(false)
      } else {
        updateAvatarStatus(true)
      }
    }
    if (showPopup) {
      this.setState({ show: true, confirmBox: true })
    }
  }

  componentDidUpdate = (_props, _nextprops) => {
    const { initialValues, change } = this.props
    if (!initialValues.languages || initialValues.languages.length === 0) {
      change('languages', [
        {
          name: 'English',
          value: 'en',
          label: 'English',
          language: 'English',
          code: 'en'
        }
      ])
    }

    if (
      initialValues.countryCode === ''
      || initialValues.countryCode === null
    ) {
      change('countryCode', {
        name: 'United Arab Emirates',
        value: 'AE',
        label: 'United Arab Emirates',
        countryName: 'United Arab Emirates',
        code: 'AE'
      })
      if (initialValues.phoneCountryCode === '') {
        change('phoneCountryCode', '971')
      }
    }

    if (!initialValues.languages || initialValues.languages.length === 0) {
      change('preferred_currency', {
        name: 'UAE Dirham',
        value: 'AED',
        label: 'د.إ / AED',
        number: '784',
        symbol: 'د.إ'
      })
    }
  }

  disableFields = fieldCollection => {
    const { currentUser } = this.props
    if (typeof currentUser === 'undefined' || !currentUser.isSignedIn) {
      return fieldCollection
    }
    const obj = fieldCollection
    const { attributes } = currentUser
    Object.keys(obj).map(key => {
      if (attributes[key] !== null) {
        /*  if (key == 'location') {
        } */
        obj[key].disabled = !!keysMatch(key, attributes)
      }
      return obj[key]
    })
    return obj
  }

  handleCountryCode = e => {
    const { countryOptions, change } = this.props
    // eslint-disable-next-line array-callback-return
    countryOptions.map(option => {
      if (option.alpha2 === e.value) {
        change('phoneCountryCode', option.country_code)
      }
    })
  }

  handleCountryCodeChange = e => {
    const { change } = this.props
    change('phoneCountryCode', e.target.value)
  }

  render() {
    const {
      handleSubmit,
      // pristine,
      submitting,
      // valid,
      // previousPage,
      // validate,
      form,
      currentUser,
      countryOptions,
      dataError,
      updateAvatarStatus,
      changeAvatar,
      displayCurrency,
      change
    } = this.props

    const {
      personalFields,
      backgroundFields,
      addressFields,
      didMount,
      phoneError
      // contactFields,
      // email,
      // phoneNumber
    } = this.state
    const { birthday, gender, languages } = backgroundFields
    const {
      countryCode,
      city
      // address,
      // postalCode,
      // country_code
    } = addressFields.location
    /* currentUser.attributes.city =
      currentUser.attributes.location && currentUser.attributes.location.city
    currentUser.attributes.countryCode =
      currentUser.attributes.location &&
      currentUser.attributes.location.countryCode */
    currentUser.attributes.preferred_currency = currentUser.attributes.preferredCurrency
    currentUser.attributes.birthday = currentUser.attributes.birthday
      ? currentUser.attributes.birthday
      : '1970-01-01T17:30:00+05:30'
    // currentUser.attributes.birthday = currentUser.attributes.birthday ? currentUser.attributes.birthday : new Date()
    const { title, description } = seoMeta.users_personal_detail
    return (
      <React.Fragment>
        <SEO title={title} description={description} />

        <form onSubmit={handleSubmit} id={form} className="">
          <div className="form-body form-body--full">
            <div className="grid grid--uniform">
              <div className="form-column grid__item large-up--one-quarter text-center">
                <AvatarUpload
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...this.props}
                  avatarUpload={updateAvatarStatus}
                  changeAvatar={changeAvatar}
                  goToNext={false}
                  name="image"
                  className="avatar-upload--small"
                  selectCTA="Upload File"
                />
              </div>
              <div className="form-column grid__item small--one-whole large-up--three-quarters">
                <div className="grid grid--uniform">
                  {/* Personal Info Fields */}
                  <div className="grid__item  medium-up--one-third large-up--one-third">
                    <Field
                      name={personalFields.firstName.name}
                      component={fields.renderField}
                      disabled={submitting}
                      placeholder={personalFields.firstName.placeholder}
                      isClearable
                      type="text"
                      label="First Name *"
                      initialValue={personalFields.firstName.value}
                      inputType="fullText"
                      displayLabel
                    />
                    {/* <fields.FieldSet
                      wrap
                      legend="Name *"
                      fields={personalFields}
                      validate={validate}
                      className="custom-margin flat"
                    /> */}
                  </div>
                  <div className="grid__item  medium-up--one-third large-up--one-third">
                    <Field
                      name={personalFields.lastName.name}
                      component={fields.renderField}
                      disabled={submitting}
                      placeholder={personalFields.lastName.placeholder}
                      isClearable
                      type="text"
                      label="Last Name *"
                      initialValue={personalFields.lastName.value}
                      inputType="fullText"
                      displayLabel
                    />
                  </div>
                  {/* Gender */}
                  <div className="grid__item medium-up--one-third large-up--one-third">
                    <Field
                      name="gender"
                      component={fields.renderSelectField}
                      disabled={submitting}
                      options={gender.options}
                      placeholder={gender.placeholder}
                      isClearable
                      type="select"
                      selectType="gender"
                      label="Gender *"
                      // value={gender.value}
                      initialValue={gender.value}
                    />
                  </div>
                </div>

                <div className="grid grid--uniform">
                  {/* Date of Birth */}
                  <div className="grid__item medium-up--one-half large-up--one-third fullWidthDateInput">
                    {/* <label htmlFor="fields"> Your Name* </label> */}
                    <Field
                      // value={birthday.value}
                      initialValue="1970-01-01T17:30:00+05:30"
                      name="birthday"
                      placeholder={birthday.placeholder}
                      past
                      onFocus={({ focused }) => this.setState(state => ({
                        ...state,
                        backgroundFields: {
                          ...state.backgroundFields,
                          birthday: {
                            ...state.backgroundFields.birthday,
                            focused
                          }
                        }
                      }))}
                      focused={birthday.focused}
                      component={RenderDateField}
                      disabled={submitting}
                      type="date"
                      label="Date of Birth"
                    />
                  </div>

                  {/* Languages */}

                  <div className="grid__item  medium-up--one-half large-up--two-thirds">
                    <Field
                      name="languages"
                      component={fields.renderSelectField}
                      disabled={submitting}
                      options={languages.options}
                      placeholder={languages.placeholder}
                      isClearable
                      isMulti
                      valueKey="code"
                      labelKey="language"
                      type="select"
                      selectType="languages"
                      label={`${languages.label} *`}
                    />
                  </div>
                </div>

                <div className="grid grid--uniform">
                  <div className="grid__item small--one-whole medium-up--full">
                    {/* <p
                    style={{
                      display: 'block',
                      marginBottom: '5px',
                      fontSize: '1rem',
                      fontWeight: 'bold',
                      color: '#667f8c',
                    }}
                  >
                    Personal Address
                  </p> */}
                    {/* Address Fields */}
                    {/* <fields.FieldSet
                    fields={addressFields}
                    validate={validate}
                    disabled={submitting}
                    parent="location"
                    wrap
                    legend="Personal Address"
                    className="flat"
                  /> */}
                    <div className="grid grid--uniform">
                      {/* <div className="grid__item medium-up--one-half large-up--two-thirds">
                      <Field
                        name="streetAddress"
                        component={fields.renderField}
                        disabled={submitting}Tajikistan
                        placeholder={address.placeholder}
                        isClearable
                        inputType="fullText"
                        label="street address"
                        // legend="Personal Address"
                      />
                    </div> */}

                      <div className="grid__item medium-up--one-half large-up--one-third">
                        {/* <Field
                        name="city"
                        component={fields.renderSelectField}
                        disabled={submitting}
                        //value={city.value}
                        options={city.options}
                        placeholder={city.placeholder}
                        isClearable
                        type="select"
                        selectType="city"
                        // inputType="fullText"
                        label="City *"
                        displayLabel={true}
                      /> */}

                        <Field
                          name="city"
                          component={fields.renderField}
                          disabled={submitting}
                          type="text"
                          placeholder={city.placeholder}
                          isClearable
                          label="City *"
                          displayLabel
                          inputType="fullText"
                        />
                      </div>
                      <div className="grid__item medium-up--one-half large-up--two-thirds">
                        <Field
                          name="countryCode"
                          component={fields.renderSelectField}
                          disabled={submitting}
                          // value={countryCode.value}
                          // options={countryCode.options}
                          options={countryOptions}
                          placeholder={countryCode.placeholder}
                          isClearable
                          type="select"
                          selectType="country"
                          label="Country *"
                          onChange={e => this.handleCountryCode(e)}
                        />
                      </div>
                    </div>
                    {/* <Field
                    name="postalCode"
                    component={fields.renderField}
                    disabled={submitting}
                    // value={postalCode.value}
                    placeholder={postalCode.placeholder}
                    isClearable
                    inputType="fullText"
                    label="postalCode"
                  /> */}
                  </div>
                  {/* Country for Personal Address */}
                  {/* <div className="grid__item small--one-whole medium-up--full">
                  <Field
                    name="countryCode"
                    component={fields.renderSelectField}
                    disabled={submitting}
                    // value={countryCode.value}
                    options={countryCode.options}
                    placeholder={countryCode.placeholder}
                    isClearable
                    type="select"
                    selectType="country"
                    label="Country"
                  />
                </div> */}
                </div>

                {/* Email Field */}
                {/* <div className="form-group">
                <Field
                  name={email.name}
                  component={fields.renderField}
                  disabled
                  value={email.value}
                  placeholder={email.placeholder}
                  type={email.type}
                  autoComplete="off"
                  label={email.label}
                  field={email}
                  displayLabel={email.displayLabel}
                />
              </div> */}
                {/* Phone Field */}

                {/* <div className="grid grid--uniform">
                <div className="grid__item form_grid__item--input small--one-whole medium-up--one-half">
                  <Field
                    name={password.name}
                    component={fields.renderField}
                    disabled={password.disabled || submitting}
                    placeholder={password.placeholder}
                    type={password.type}
                    autoComplete="off"
                    label={password.label}
                    field={password}
                    displayLabel={password.displayLabel}
                  />
                </div>
                <div className="grid__item form_grid__item--input small--one-whole medium-up--one-half">
                  <Field
                    name={passwordConfirmation.name}
                    component={fields.renderFimobileeld}
                    disabled={passwordConfirmation.disabled || submitting}
                    placeholder={passwordConfirmation.placeholder}
                    type={passwordConfirmation.type}
                    autoComplete={passwordConfirmation.autoComplete}
                    label={passwordConfirmation.label}
                    field={passwordConfirmation}
                    displayLabel={passwordConfirmation.displayLabel}
                  />
                </div>
              </div> */}

                {/* <input
                type="password"
                id="password"
                name="password"
                placeholder="Password"
                autoComplete="new-password"
                value={password}
                onChange={this.handleChange}
              /> */}
                <div className="grid ">
                  {!displayCurrency ? (
                    <div className="grid__item medium-up--one-half large-up--one-third">
                      <Field
                        name="preferred_currency"
                        component={fields.renderSelectField}
                        disabled={submitting}
                        // value={preferredCurrency.value}
                        options={[]}
                        placeholder="Currency"
                        type="select"
                        selectType="preferredCurrency"
                        label="Currency *"
                        displayLabel
                      />
                    </div>
                  ) : null}
                  <div className="grid__item medium-up--one-half large-up--two-thirds ">
                    <div className="grid">
                      <div className="grid__item  ">
                        <label className=" ">{phoneNumber.label} </label>
                        {didMount ? (
                          <IntlTelInput
                            containerClassName="intl-tel-input fullWidth fullInputClass"
                            inputClassName="form-control"
                            disabled
                            defaultCountry={
                              currentUser.attributes.phoneMetaData != null
                                ? currentUser.attributes.phoneMetaData.iso2
                                : 'ae'
                            }
                            onSelectFlag={(selectedPhoneNumber, metadata) => {
                              const { dialCode } = metadata
                              if (Number(selectedPhoneNumber.replace(/\D/g, '')) !== Number(currentUser.attributes.phoneNumber.replace(/\D/g, '')) || Number(dialCode) !== Number(currentUser.attributes.phoneMetaData.dialCode)) {
                                change('phoneNumber', selectedPhoneNumber)
                                change('phoneCountryCode', dialCode)
                                change('phoneMetaData', metadata)
                              }
                            }}
                            onPhoneNumberChange={(
                              flag,
                              changedPhoneNumber,
                              metadata
                            ) => {
                              // console.log('metadata', metadata)
                              const { dialCode } = metadata
                              if (Number(changedPhoneNumber.replace(/\D/g, '')) !== Number(currentUser.attributes.phoneNumber.replace(/\D/g, '')) || Number(dialCode) !== Number(currentUser.attributes.phoneMetaData.dialCode)) {
                                change('phoneNumber', changedPhoneNumber)
                                change('phoneMetaData', metadata)
                                change('phoneCountryCode', dialCode)
                              }
                            }}
                            defaultValue={
                              currentUser.attributes.phoneNumber
                                ? currentUser.attributes.phoneNumber
                                : ''
                            }
                          />
                        ) : ''}
                        {phoneError || dataError ? (
                          <p>
                            <span className="form-error">
                              {phoneError || ''} {dataError && dataError.error_details ? dataError.error_details : ''}
                            </span>
                          </p>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </React.Fragment>
    )
  }
}

AccountDetailsHost.defaultProps = {
  disableFields: true,
  displayCurrency: true
}

AccountDetailsHost.propTypes = {
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  valid: PropTypes.bool.isRequired,
  previousPage: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  currentUser: currentUserPropTypes.isRequired,
  disableFields: PropTypes.bool,
  displayCurrency: PropTypes.bool,
  updateAvatarStatus: PropTypes.func.isRequired,
  showPopup: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    languages: PropTypes.arrayOf(PropTypes.string),
    countryCode: PropTypes.string,
    phoneCountryCode: PropTypes.string
  }).isRequired,
  countryOptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  change: PropTypes.func.isRequired,
  dataError: PropTypes.shape({
    error_details: PropTypes.shape({})
  }).isRequired,
  changeAvatar: PropTypes.func.isRequired,
  form: PropTypes.shape({}).isRequired
}

export default AccountDetailsHost
