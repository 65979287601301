import React, { Component } from 'react'
/* import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux' */
import ChefOrHostCard from '../../Chef/ChefOrHostCard'
import { Link } from 'react-router-dom'
import Flickity from 'flickity-fullscreen'
import _ from 'lodash'
import 'flickity-fullscreen/fullscreen.css'
import 'flickity-as-nav-for'

class HostSlider extends Component {
  constructor(props) {
    super(props)
    this.state = {
      flkty: null,
      flktynav: null
    }
  }

  componentDidMount() {
    this.initSlider()
    if (typeof window !== 'undefined') {
      // window.addEventListener('resize', this.handleResize);
    }
  }

  componentDidUpdate() {
    // this.initSlider();
  }

  initSlider = () => {
    // const { uniquename, thumbnav } = this.props
    const elem = document.querySelector(`#hostcard`)
    if (elem) {
      const flkty = new Flickity(elem, {
        fullscreen: false,
        cellAlign: 'left',
        pageDots: false
      })
      this.setState({ flkty })
    }
    /* if (thumbnav) {
      const navElem = document.querySelector(`#${uniquename}-nav`)
      if (elem) {
        const flktyNav = new Flickity(navElem, {
          fullscreen: false,
          asNavFor: `#${uniquename}`,
          contain: true,
          pageDots: false
        })
        this.setState({ flktyNav })
      }
    } */
  };

  render() {
    const { featuredHosts, isMobile } = this.props
    return (
      <React.Fragment>
        {featuredHosts.length && (
        /*  <section className="photography"> */
          <div className="image-carousel-fullscreen" id="hostcard">
            {featuredHosts.map((user, index) => {
              // TODO: fix handling brackets with escaping('\') instead of replacing
              return (
              /* eslint-disable-next-line react/no-array-index-key */
                <div className={`${isMobile?'carousel-cell':''} grid__item small--one-half medium-up--one-quarter large-up--one-quarter hosthomescreen`} key={`ChefOrHostCard-${user.id}`}>
                  <ChefOrHostCard person={user} />
                  {user.description ? (
                    <p>
                      {user.description.length > 150 ? (
                        <React.Fragment>
                          {`${user.description.substring(0, 150)}....`}
                          <Link to={`/host/${user.slug}`}> Read more </Link>
                        </React.Fragment>
                      ) : user.description}
                    </p>
                  ) : null}
                </div>
              )
            })}
          </div>
        )}
      </React.Fragment>
    )
  }
}
export default HostSlider
