import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import alertModal from '../../utils/alert'
import * as modalActionCreators from '../../actions/modals'
import * as formActionCreators from '../../actions/forms'
import * as hostActionCreators from '../../actions/host'

// Validations
import { currentUserPropTypes } from '../../constants/composed-proptypes'

// Form Steps
import HostLocationStepOne from '../../components/Workflows/HostLocation/Step1'
import HostLocationPhotos from '../../components/Workflows/HostLocation/step2'

class HostLocationForms extends Component {
  constructor(props) {
    super(props)
    this.nextPage = this.nextPage.bind(this)
    this.previousPage = this.previousPage.bind(this)
    this.state={
      currentLocalLocation: null,
      successMessage: null
    }
  }

  handleHostData = async data => {
    const { hostActions, currentLocation, modalActions, type } = this.props
    const { currentLocalLocation } = this.state
    const hostingSpaceData = {
      id: currentLocalLocation?currentLocalLocation.id:null,
      countryCode: data.hostAddressFieldsCountryCode,
      postalCode: data.hostAddressFieldsPostalCode,
      streetAddress: data.hostingLocationStreetAddress,
      neighbourhood: data.hostAddressFieldsNeighbourhood,
      city: data.hostAddressFieldsCity,
      destinationLink: data.hostAddressFieldsDestinationLink,
      // locationPhotos: data.locationPhotos,
      amenities: data.amenities?data.amenities.map((el)=>{ return {id: el.value, name: el.label}}):[],
      wowElements: data.wow_elements?data.wow_elements.map((el)=>{ return {id: el.value, name: el.label}}):[]
    }
    const response = await hostActions.createUpdateLocation(hostingSpaceData)
    const { locations } = this.props
    const localLocation = locations.find(el=>el.id === response.data.location.id)
    this.setState({currentLocalLocation: localLocation})
    if (response.status == 200) {
      this.setState({successMessage: `Hosting space details have been ${hostingSpaceData.id?'updated':'saved'} successfully`})
      /* alertModal(
        'Hosting space details have been saved successfully.',
        'success',
        'Close'
        ) */
    } else {
      modalActions.hideHostLocationFormModal()
      alertModal(
        'There is some network problem. Please try later.',
        'error',
        'Close'
      )
    }
  }

  handleFinalSubmit = () => {
    const { modalActions } = this.props
    modalActions.hideHostLocationFormModal()
  }

  componentDidMount = () => {
    const { currentLocation } = this.props
    if (currentLocation) {
      this.setState({currentLocalLocation: currentLocation})
    }
  }

  closeAndExit() {
    const { modalActions, hostActions } = this.props
    modalActions.hideHostLocationFormModal()
  }

  nextPage() {
    const { stepForm, formActions } = this.props
    formActions.updateHostLocationFormStep(stepForm.page + 1)
  }

  previousPage() {
    // const { page } = this.state;
    // this.setState({ page: page - 1 });
    const { stepForm, formActions } = this.props
    formActions.updateHostLocationFormStep(stepForm.page - 1)
  }


  render() {
    const { currentUser, stepForm, currentLocation, isUpdating } = this.props
    const { currentLocalLocation, successMessage } = this.state
    const { page } = stepForm
    return (
      <React.Fragment>
        {page === 1 && (
          <HostLocationStepOne
            isUpdating={isUpdating}
            currentLocation={currentLocalLocation?currentLocalLocation:currentLocation}
            previousPage={this.previousPage}
            onSubmit={async () => {
              await this.handleHostData(this.props.hostForm.values)
              this.nextPage()
            }}
            nextPage={() => this.nextPage()}
          />
        )}
        {page === 2 && (
          <HostLocationPhotos
            previousPage={this.previousPage}
            currentLocation={currentLocalLocation?currentLocalLocation:currentLocation}
            successMessage={successMessage}
            onSubmit={async () => {
              // console.log("@Page 2---", data)
              await this.handleFinalSubmit()
              // this.nextPage()
            }}
            // nextPage={() => this.nextPage()}
          />
        )}
      </React.Fragment>
    )
  }
}

HostLocationForms.propTypes = {
  currentUser: currentUserPropTypes.isRequired,
  stepForm: PropTypes.shape({}).isRequired,
  formActions: PropTypes.shape({}).isRequired,
  modalActions: PropTypes.shape({}).isRequired,
  hostActions: PropTypes.shape({}).isRequired
}

const mapDispatchToProps = dispatch => ({
  hostActions: bindActionCreators(hostActionCreators, dispatch),
  modalActions: bindActionCreators(modalActionCreators, dispatch),
  formActions: bindActionCreators(formActionCreators, dispatch)
})
const mapStateToProps = state => ({
  stepForm: state.stepForm.hostLocation,
  currentUser: state.reduxTokenAuth.currentUser,
  hostForm: state.form.hostLocation,
  locations: state.host.location.allLocations?state.host.location.allLocations:[]
})

export default connect(mapStateToProps, mapDispatchToProps)(HostLocationForms)

