
import { NavigationActions } from 'react-navigation'
import { store } from '../configureStore'
import { AUTH_RELOAD_PAGE } from '../constants/actionTypes'

export function refreshPage() {
  return { type: AUTH_RELOAD_PAGE }
}

export function goBack() {
  return NavigationActions.back()
}

export function navigate(route) {
  return NavigationActions.navigate(route)
}

export function resetRoute(route) {
  return (dispatch, getState) => {
    const nav = getState().nav
    dispatch(NavigationActions.reset({
      index: 0, 
      key: null,
      actions: [
        NavigationActions.navigate(route)
      ]
    }))
  }
}
