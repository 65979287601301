const style = {
  '.embedded .card-form .input-group': {
    borderRadius: '20px'
  },
  /* focus */
  '.embedded .card-form .input-group.focus:not(.error)': {
    border: '1px solid green'
  },
  /* icons */
  '.embedded .card-form .input-group .icon': {
    color: 'slategray'
  },
  /* hint icon (CVV) */
  '.embedded .card-form .input-group .hint-icon': {
    color: 'slategray'
  },
  '.embedded .card-form .input-group .hint-icon:hover': {
    color: 'darkslategray'
  },
  /* error */
  '.embedded .card-form .input-group.error': {
    border: '1px solid red'
  },
  '.embedded .card-form .input-group.error .hint.error-message': {
    /* message container */
    background: 'lightgray' /* make sure to match the pointer color (below) */,
    color: 'red'
  },
  '.embedded .card-form .input-group.error .hint.error-message .arrow': {
    /* message container pointer */
    borderBottomColor: 'lightgray' /* matches message container background */
  },
  '.embedded .card-form .input-group.error .hint-icon:hover': {
    /* hint icon */
    color: 'red'
  },

  '.embedded .card-form .input-group:not(.error) input': {
    color: 'dimgray'
  },
  '.embedded .card-form .input-group.focus input': {
    color: 'black'
  },
  '.embedded .card-form .input-group.error input': {
    color: 'red'
  },
  '.embedded .card-form .input-group input::-webkit-input-placeholder': {
    /* Chrome/Opera/Safari */
    color: 'lightgray'
  },
  '.embedded .card-form .input-group input::-moz-placeholder': {
    /* Firefox 19+ */
    color: 'lightgray'
  },
  '.embedded .card-form .input-group input:-ms-input-placeholder': {
    /* IE 10+ */
    color: 'lightgray'
  },
  '.embedded .card-form .input-group input:-moz-placeholder': {
    /* Firefox 18- */
    color: 'lightgray'
  },

  '.embedded .card-form .split-view .left': {
    paddingRight: '3px'
  },
  '.embedded .card-form .split-view .right': {
    paddingLeft: '3px'
  }
}

export default style
