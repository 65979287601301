import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
// import LogRocket from 'logrocket'
import { Link } from 'react-router-dom'
import ProfileAvatarDrop from './ProfileAvatarDrop'
import { currentUserPropTypes } from '../../constants/composed-proptypes'
import AuthLink from '../Auth/AuthLink'
import RegLink from '../Registration/Users/RegLink'

const NavAccountState = ({ currentUser, isMobile = false, loginStatus }) => {
  // if (localStorage.getItem('authCode') !== 'LetsBreakBread') {
  //   return <div />
  // }

  const { isSignedIn } = currentUser

  switch (isSignedIn) {
  case true:
    // LogRocket.identify(`${currentUser.attributes.id}`, {
    //   name: `${currentUser.attributes.fullName}`,
    //   email: `${currentUser.attributes.email}`,
    //   role: `${currentUser.attributes.role}`
    // })
    return <ProfileAvatarDrop />
  default:
    return (
      <li>
        {!loginStatus ? (
          <AuthLink className="breakbread-navbar-nav-item btn--link">
            <span>Log In</span>
          </AuthLink>
        ) : null}
          
        <RegLink className="breakbread-navbar-nav-item btn--link">
          <span>Sign Up</span>
        </RegLink>
          
      </li>
    )
  }
}

NavAccountState.propTypes = {
  currentUser: currentUserPropTypes.isRequired
}

const mapStateToProps = state => ({
  currentUser: state.reduxTokenAuth.currentUser,
  loginStatus: state.user.countryCode.disableLogin
})

// const mapDispatchToProps = dispatch => ({
//   // Lets us this to open the modal
// });

export default connect(
  mapStateToProps,
  null
)(NavAccountState)
