import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import validate from './validate'

import ReviewForm from '../../Form/CommonForms/HostReviewForm'

const HostReview = props => {
  const formTitle = 'Leave a Review for'
  return (
    <React.Fragment>
      <ReviewForm
        {...props}
        formTitle={formTitle}
        disableFields={false}
        showPasswordField
      />
    </React.Fragment>
  )
}

HostReview.propTypes = {
  submitting: PropTypes.bool.isRequired,
  previousPage: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired
  // currentUser: currentUserPropTypes.isRequired,
}

// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
const reduxFormHostReview = reduxForm({
  form: 'hostReview',
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  validate: values => validate(values)
})(HostReview)

// You have to connect() to any reducers that you wish to connect to yourself
const connectedHostReview = connect(state => ({
  // initialValues: state.reduxTokenAuth.currentUser.attributes,
  // pull initial values from account reducer
}))(reduxFormHostReview)

export default connectedHostReview
