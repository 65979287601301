import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { reset } from 'redux-form'
import moment from 'moment'
import jsPDF from 'jspdf'
import html2canvas from 'html2canvas'
import * as modalActionCreators from '../../actions/modals'
import * as formActionCreators from '../../actions/forms'
import * as userActionCreators from '../../actions/user'
import * as transactionActionCreators from '../../actions/transactions'

import { currencyTypes } from '../../constants/currency'

// Validations
import { currentUserPropTypes } from '../../constants/composed-proptypes'

import AmericanExpressIcon from '../../icons/creditCards/AmericanExpressIcon'
import MastercardIcon from '../../icons/creditCards/MastercardIcon'
import PaypalIcon from '../../icons/creditCards/PaypalIcon'
import VisaIcon from '../../icons/creditCards/VisaIcon'

const PAYMENT_ICONS = {
  AMERICANEXPRESS: AmericanExpressIcon,
  MASTERCARD: MastercardIcon,
  PAYPAL: PaypalIcon,
  VISA: VisaIcon
  /* other icons */
}

class BookingDetail extends Component {
  constructor(props) {
    super(props)
    const { receipt } = props.reservation
    // receipt = JSON.parse(receipt);
    this.closeAndExit = this.closeAndExit.bind(this)
  }

  handleGuestReceiptData = async ({ data, reservation }) => {
    this.closeAndExit()
  }

  async closeAndExit() {
    const { modalActions, modalCallBack } = this.props
    await modalActions.hideReceiptModal()
    if (typeof modalCallBack !== 'undefined') {
      modalCallBack()
    }
  }

  _exportPdf = () => {
    html2canvas(document.querySelector('#guest-receipt')).then(canvas => {
      document.body.appendChild(canvas) // if you want see your screenshot in body.
      const imgData = canvas.toDataURL('image/png')
      const pdf = new jsPDF()
      pdf.addImage(imgData, 'PNG', 0, 0)
      pdf.save('download.pdf')
    })
  }

  render() {
    const { currentUser, reservation } = this.props
    const receipt = {
      currency: 'AED',
      value: 0,
      card: { paymentMethod: 'Coupon', last4: '0000' },
      status: 'PAID',
      trackId: '#43537',
      created: reservation.bookedDate
    }
    const currency = currencyTypes[reservation.experience.currency]
    const seatLabel = reservation.seatsBooked > 1 ? 'Seats' : 'Seat'
    const totalValue = (receipt.value / 100).toFixed(2)
    const paymentMethodType = receipt.card.paymentMethod.toUpperCase()
    const composedValue = ` ${totalValue} ${reservation.experience.currency}`

    const YieldPaymentMethodIcon = PAYMENT_ICONS[paymentMethodType]
    return (
      <div
        className="guest-receipt-item form-body form-body--full"
        id="guest-receipt"
      >
        <div className="section-header text-center">
          <div
            className="chef-or-host__avatar cursor tableAvtar"
            style={{
              backgroundImage: `url(${reservation.guest.avatarUrl})`,
              width: '100px'
            }}
          >
            <img
              className="lazyload lazysizes avatar profileAvatarDrop__avatar hidden"
              alt={reservation.guest.fullname}
              style={{ width: '40%' }}
              src={reservation.guest.avatarUrl}
              data-src={reservation.guest.avatarUrl}
              data-widths="[180, 360, 540, 720, 900, 1080, 1296, 1512, 1728, 2048]"
            />
          </div>
          <div
            className="padded-cells"
            style={{ paddingLeft: 'px', paddingRight: '0px' }}
          >
            <h4>{reservation.guest.fullname} </h4>
          </div>
        </div>

        <div className="grid">
          <div className="grid__item medium-up--one-half">
            <table className="responsive-table responsive-table--no-labels responsive-table--no-borders">
              <tbody>
                <tr className="responsive-table-row responsive-table--no-labels">
                  <td>Experience Date</td>
                  <td>{reservation.date}</td>
                </tr>
                <tr className="responsive-table-row">
                  <td>Time</td>
                  <td>{reservation.experience.time}</td>
                </tr>
                <tr className="responsive-table-row">
                  <td>Hosted By</td>
                  <td>{reservation.experience.host.fullname}</td>
                </tr>
                <tr className="responsive-table-row">
                  <td>Number of Seats Booked</td>
                  <td>
                    {reservation.seatsBooked} {seatLabel}
                  </td>
                </tr>
                <tr className="responsive-table-row">
                  <td>Total no of guests(seats)</td>
                  <td>
                    {reservation.experience.seats} {seatLabel}
                  </td>
                </tr>
              </tbody>
            </table>

            {/*
            <table className="responsive-table responsive-table--no-labels responsive-table--no-borders">
              <thead className="table-heading">
                <tr>
                  <th colSpan="2">Charges</th>
                </tr>
              </thead>
              <tbody>
                <tr className="responsive-table-row">
                  <td>
                    {reservation.experience.price}{' '}
                    {reservation.experience.currency} X {reservation.seatsBooked} {seatLabel}
                  </td>
                  <td>{composedValue}</td>
                </tr>

                <tr className="responsive-table-row">
                  <td className="total">Service Charge</td>
                  <td>None</td>
                </tr>
              </tbody>
              <tfoot>
                <tr className="responsive-table-footer">
                  <td>
                    <b>Total</b>
                  </td>
                  <td>
                    <b>{composedValue}</b>
                  </td>
                </tr>
              </tfoot>
            </table>
            */}
          </div>

          <div className="grid__item medium-up--one-half">
            <table className="responsive-table responsive-table--no-labels responsive-table--no-borders">
              <tbody>
                <tr className="responsive-table-row">
                  <td>Booked By</td>
                  <td>{reservation.guest.fullname}</td>
                </tr>
                <tr className="responsive-table-row">
                  <td>Booked Date</td>
                  <td>{reservation.bookedDate}</td>
                </tr>
                <tr className="responsive-table-row">
                  <td>Booking Number</td>
                  <td>{reservation.bookingNumber}</td>
                </tr>
                <tr className="responsive-table-row">
                  <td>Reservation Status</td>
                  <td>{reservation.status.charAt(0).toUpperCase() + reservation.status.slice(1)}</td>
                </tr>
                <tr className="responsive-table-row">
                  <td className="total">Payment Status</td>
                  <td>{reservation.paymentStatus.charAt(0).toUpperCase() + reservation.paymentStatus.slice(1)}</td>
                </tr>
                {/* <tr className="responsive-table-row">
                  <td>Reference #</td>
                  <td>{reservation.trackId ? reservation.trackId : null}</td>
          </tr> */}
              </tbody>
            </table>
            {/*
            
            <table className="responsive-table responsive-table--no-labels responsive-table--no-borders">
              <thead className="table-heading">
                <tr>
                  <th colSpan="2">Payment</th>
                </tr>
              </thead>
              <tbody>
                <tr className="responsive-table-row">
                  <td>Card (last 4)</td>
                  {/* <td>
                    {receipt.card.last4} <YieldPaymentMethodIcon />
                  </td> 
                </tr>
                <tr className="responsive-table-row">
                  <td>Charge Date</td>
                  <td>{moment.utc(receipt.created).format('LL')}</td>
                </tr>
              </tbody>
              <tfoot>
                <tr className="responsive-table-footer">
                  <td>
                    <b>Total Paid</b>
                  </td>
                  <td>
                    <b>{composedValue}</b>
                  </td>
                </tr>
              </tfoot>
            </table>
          
                  */}
          </div>
        </div>

        <div className="grid">
          <table className="responsive-table responsive-table--no-labels responsive-table--no-borders">
            <thead className="table-heading">
              <tr className="booking-detail-footer-row">
                <th style={{ fontSize: '16px', fontWeight: 'bold' }}>
                  {/* Description */}
                  Transaction Details
                </th>
                <th style={{ fontSize: '16px', fontWeight: 'bold' }}>
                  {/* Quantity */}
                </th>

                {/* <th style={{ fontSize: '16px', fontWeight: 'bold' }}>
                    Unit Price
                  </th>
                  <th style={{ fontSize: '16px', fontWeight: 'bold' }}>Amount</th> */}
              </tr>
            </thead>
            <tbody>
              <tr className="responsive-table-row responsive-table--no-labels">
                <td>
                  {/* {reservation.seatsBooked} seats for the experience ' */}
                  {reservation.experience.title},
                  <br />
                  seats: {reservation.seatsBooked}, Unit Price: {reservation.experience.currency}{' '}{reservation.experience.price}
                </td>
                {/* <td>{reservation.seatsBooked}</td> */}
                {/*  <td>
                  {reservation.experience.price}{' '}
                  {reservation.experience.currency}
                </td> */}
                <td>
                  {(
                    reservation.experience.price * reservation.seatsBooked
                  ).toFixed(2)}{' '}
                  {reservation.experience.currency}
                </td>
              </tr>

              <tr className="responsive-table-row responsive-table--no-labels">
                <td>Service Charge</td>
                {/* <td>1</td>
                <td>0.00</td> */}
                <td>0.00</td>
              </tr>
              <tr className="responsive-table-row">
                {/* <td colSpan="2" /> */}
                <td>
                  <strong>Total</strong>
                </td>
                <td>
                  <strong>
                    {(
                      reservation.experience.price * reservation.seatsBooked
                    ).toFixed(2)}{' '}
                    {reservation.experience.currency}
                  </strong>
                </td>
              </tr>
            </tbody>
          </table>
          {/* <button onClick={()=>{this._exportPdf()}}>Download</button> */}
        </div>

        {currentUser.isLoading && <div className="spinner" />}
      </div>
    )
  }
}

BookingDetail.propTypes = {
  currentUser: currentUserPropTypes.isRequired,
  userActions: PropTypes.shape({}).isRequired,
  modalActions: PropTypes.shape({}).isRequired,
  transactionActions: PropTypes.shape({}).isRequired
}

const mapDispatchToProps = dispatch => ({
  formActions: bindActionCreators(formActionCreators, dispatch),
  userActions: bindActionCreators(userActionCreators, dispatch),
  modalActions: bindActionCreators(modalActionCreators, dispatch),
  transactionActions: bindActionCreators(transactionActionCreators, dispatch),
  resetForm: () => dispatch(reset('guestReview'))
})

const mapStateToProps = state => ({
  pathname: state.router.location.pathname,
  currentUser: state.reduxTokenAuth.currentUser
})

export default connect(mapStateToProps, mapDispatchToProps)(BookingDetail)
