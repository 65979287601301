import React from "react"

const IconChevronDown = props => (
  <svg
    className="icon icon--wide icon-chevron-down"
    viewBox="0 0 6.56 3.79"
    width="1em"
    height="1em"
    {...props}
  >
    <path d="M6.06 0l.5.51-3.28 3.28L0 .51.51 0l2.77 2.78L6.06 0z" />
  </svg>
)

export default IconChevronDown
