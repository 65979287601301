import React from "react"

const Fish = props => (
  <svg viewBox="0 0 88 88" width="1em" height="1em" {...props}>
    <g fill="currentColor" data-name="Layer 2">
      <circle cx={34} cy={45} r={1} />
      <path d="M58.28 44.31A7.37 7.37 0 0 0 60 39a1 1 0 0 0-.29-.71A.82.82 0 0 0 59 38a7 7 0 0 0-5.74 3 18.35 18.35 0 0 0-6.74-4.16 20.2 20.2 0 0 1 .57-3.65 1 1 0 0 0-.18-.86 1 1 0 0 0-.78-.38c-3.7 0-6.4 2.68-7.14 3.5a6.4 6.4 0 0 1-2.21 1.68 19.45 19.45 0 0 0-8.62 7.29 1 1 0 0 0 0 1.06 19.26 19.26 0 0 0 3.55 4.22A1 1 0 0 0 33 48.25 17.35 17.35 0 0 1 30.19 45a17.45 17.45 0 0 1 7.37-6 8.65 8.65 0 0 0 2.9-2.18 9 9 0 0 1 4.39-2.69 19.19 19.19 0 0 0-.35 3.44 1 1 0 0 0 .68.95c1.34.45 4.66 1.73 6.6 3.86a2 2 0 0 0 1.59.63 2 2 0 0 0 1.53-.86 5 5 0 0 1 3-2.05 4.75 4.75 0 0 1-1.11 2.81 3 3 0 0 0 0 4.14 4.75 4.75 0 0 1 1.11 2.81 5 5 0 0 1-3-2.06 2 2 0 0 0-3.1-.22c-1.67 1.84-4.87 3.16-5.92 3.6a1 1 0 0 0-.49 1.41 4.62 4.62 0 0 1 .48 1.25 5 5 0 0 1-2.58-1.55 1 1 0 0 0-.79-.33h-.12A11.74 11.74 0 0 1 37 50.83 8.83 8.83 0 0 0 40.13 44a1 1 0 0 0-2 0 6.85 6.85 0 0 1-3.58 6.07 1 1 0 0 0 0 1.74A15.07 15.07 0 0 0 42 54h.14A7 7 0 0 0 47 56a1.05 1.05 0 0 0 .71-.29A1 1 0 0 0 48 55a7 7 0 0 0-.42-2.36A17.1 17.1 0 0 0 53.24 49 7.05 7.05 0 0 0 59 52a1 1 0 0 0 .71-.29A1 1 0 0 0 60 51a7.37 7.37 0 0 0-1.72-5.31 1 1 0 0 1 0-1.38z" />
      <path d="M44 88a44 44 0 1 1 44-44 44.05 44.05 0 0 1-44 44zm0-86a42 42 0 1 0 42 42A42 42 0 0 0 44 2z" />
    </g>
  </svg>
)

export default Fish
