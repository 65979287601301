import React from 'react'
import Modal from 'react-bootstrap/lib/Modal'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { signOutUser } from '../../actions/sessions'
import * as modalActionCreators from '../../actions/modals'
import * as signUpActionCreators from '../../actions/signUp'
import { setForgotPass } from '../../actions/forgotPassword'

import AuthForm from '../Auth/AuthForm'

const AuthModal = props => {
  const { show, modalActions, modalCallBack, setForgotPass, isHost } = props
  return (
    <Modal
      backdrop={false}
      //backdropTransition={false}
      backdrop="static"
      show={show}
      onHide={() => {modalActions.hideAuthModal();setForgotPass(false)}}
      animation={false}
      bsSize="small"
    >
      <Modal.Header closeButton />
      <Modal.Body>
        
        <AuthForm successAction={() => modalCallBack()} />

      </Modal.Body>
      <Modal.Footer>
        <div className="text-center">
          <button
            type="button"
            className="btn btn--link"
            onClick={() => {
              // Shows the Reg Modal with a callback
              // to show the reservation modal on successful login.
              if (isHost) {
                modalActions.showRegHostModal({
                  show: true,
                  modalCallBack
                })
              } else {
                modalActions.showRegModal({
                  show: true,
                  modalCallBack
                })
              }
              setForgotPass(false)
            }}
           
          >
            Don't have an account yet? <br></br>Click here to register.
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}

AuthModal.defaultProps = {
  modalCallBack: () => {
    // console.log('AUTH MODAL - NO AFTER ACTION RECEIVED');
  }
}
AuthModal.propTypes = {
  show: PropTypes.bool.isRequired,
  modalActions: PropTypes.shape({}).isRequired,
  modalCallBack: PropTypes.func
}

const mapStateToProps = state => ({
  currentUser: state.reduxTokenAuth.currentUser,
  modalCallBack: state.modal.modalProps.modalCallBack,
  isHost: state.modal.modalProps.isHost || false,
})

const mapDispatchToProps = dispatch => ({
  modalActions: bindActionCreators(modalActionCreators, dispatch),
  signUpActions: bindActionCreators(signUpActionCreators, dispatch),
  setForgotPass: state => dispatch(setForgotPass(state)),
  /** NOTE: This would also be a nice way of doing it
   * https://github.com/reduxjs/react-redux/blob/master/docs/api.md#inject-todos-and-all-todoactioncreators-and-counteractioncreators-directly-as-props
   *
   * actions: bindActionCreators(Object.assign({}, modalActions), dispatch)
   */
  signOut: user => dispatch(signOutUser(user))
})
export default connect(mapStateToProps, mapDispatchToProps)(AuthModal)
