import api from '../../utils/api'
import { SET_MY_REVIEWS, SET_GUEST_REVIEWS } from '../../constants/actionTypes'
import { normalizeReviews } from '../../utils/normalization'
import alertModal from '../../utils/alert'
import { fetchReservationsMade } from '../transactions'

export function setMyReviews(reviews) {
  return {
    type: SET_MY_REVIEWS,
    payload: reviews
  }
}

export function setGuestReviews(reviews) {
  return {
    type: SET_GUEST_REVIEWS,
    payload: reviews
  }
}

export function getMyReviews(type) {
  // console.log('HOST ID ', hostId);
  /* eslint-disable-next-line no-unused-vars */
  return async (dispatch, getState) => {
    // eslint-disable-next-line no-useless-catch
    try {
      const response = await api.get(`/diners/${type?type:'reviews_by_me'}`)
      const reviews = {
        guest_reviews: normalizeReviews(response.data.reviews)
      }
      dispatch(setMyReviews(reviews))
    } catch (err) {
      throw err
    }
  }
}

export function reservationGuestReview(props) {
  return async (dispatch, getState) => {
    const { reservation_id } = props

    if (typeof reservation_id === 'undefined') {
      console.log('Reservation ID not passed!')
      return false
    }

    // eslint-disable-next-line no-useless-catch
    try {
      // const response = await api.post(
      //   `/reservations/${reservation_id}/create_guest_review`,
      //   {
      //     review: props
      //   }
      // )
      const response = await api.post(
        `/diners/reviews`,
        {
          ...props
        }
      )
      if (response.data.is_success) {
        alertModal('Nice! Review was sent.', 'success', 'Continue')
        dispatch(getMyReviews())
      } else {
        // dispatch(getConfirmedReservations(reservation.experience.id));
      }
    } catch (err) {
      // alertModal(err.message, 'error', 'Close');
      throw err
    }
  }
}

export function getCommunities() {
  return async (dispatch, getState) => {
    // eslint-disable-next-line no-useless-catch
    try {
      const response = await api.get(`/reviews/guest_reviews`)
      const { guest_reviews } = response.data
      dispatch(setGuestReviews(guest_reviews))
    } catch (err) {
      throw err
    }
  }
}
