import React from "react"

const Soybeans = props => (
  <svg viewBox="0 0 88 88" width="1em" height="1em" {...props}>
    <g fill="currentColor" data-name="Layer 2">
      <path d="M44 40a4 4 0 1 0 4 4 4 4 0 0 0-4-4zm0 6a2 2 0 1 1 2-2 2 2 0 0 1-2 2zM48 36a4 4 0 1 0 4-4 4 4 0 0 0-4 4zm6 0a2 2 0 1 1-2-2 2 2 0 0 1 2 2zM36 48a4 4 0 1 0 4 4 4 4 0 0 0-4-4zm0 6a2 2 0 1 1 2-2 2 2 0 0 1-2 2z" />
      <path d="M59 28h-7a8 8 0 0 0-7.85 6.46 2 2 0 0 1-1.61 1.67 8 8 0 0 0-6.41 6.41 2 2 0 0 1-1.67 1.61A8 8 0 0 0 28 52v7a1 1 0 0 0 1 1h7a8 8 0 0 0 7.85-6.46 2 2 0 0 1 1.61-1.67 8 8 0 0 0 6.41-6.41 2 2 0 0 1 1.67-1.61A8 8 0 0 0 60 36v-7a1 1 0 0 0-1-1zm-1 8a6 6 0 0 1-4.84 5.89 4 4 0 0 0-3.26 3.21 6 6 0 0 1-4.81 4.8 4 4 0 0 0-3.2 3.26A6 6 0 0 1 36 58h-6v-6a6 6 0 0 1 4.84-5.89 4 4 0 0 0 3.26-3.21 6 6 0 0 1 4.81-4.8 4 4 0 0 0 3.2-3.26A6 6 0 0 1 52 30h6z" />
      <path d="M44 88a44 44 0 1 1 44-44 44.05 44.05 0 0 1-44 44zm0-86a42 42 0 1 0 42 42A42 42 0 0 0 44 2z" />
    </g>
  </svg>
)

export default Soybeans
