import * as React from "react"

const TikTok = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    heights="25"
    viewBox="0 0 122.88 122.31"
    {...props}
  >
    <defs>
      <style>
        {
          "\n .cls-1{fill:#160c1a}.cls-1,.cls-2,.cls-3,.cls-4{fill-rule:evenodd}.cls-2{fill:#26f4ee}.cls-3{fill:#fb2c53}.cls-4{fill:#fff}\n"
        }
      </style>
    </defs>
    <path
      className="cls-1"
      d="M27.75 0h67.38a27.83 27.83 0 0 1 27.75 27.75v66.82a27.83 27.83 0 0 1-27.75 27.74H27.75A27.83 27.83 0 0 1 0 94.57V27.75A27.83 27.83 0 0 1 27.75 0Z"
    />
    <path
      className="cls-2"
      d="M77.57 15.06C79.06 27.79 86.69 36.77 99 37.57v12.07-10.25C86.61 38.6 79.52 31 78 18.28H65v53.55c1.89 24.11-16.89 24.83-24.08 15.69 8.38 5.27 22.08 1.84 20.44-18.9V15.06Zm-35.12 88.79A27.71 27.71 0 0 1 24.4 91.46c-9.58-15.67-.94-41.22 26.85-43.85v3.77C25.44 55.49 22.85 83.16 30 94.68a22.11 22.11 0 0 0 12.46 9.17Z"
    />
    <path
      className="cls-3"
      d="M81.29 19.06c.85 7.25 3.52 13.55 7.93 17.79-6.22-3.21-9.94-9.31-11.12-17.79ZM98.93 42a21.39 21.39 0 0 0 3.29.45v14.27c-7.16.71-13.43-1.65-20.73-6l1.12 24.48c0 7.9 0 11.51-4.21 18.79-10.34 17.72-29.65 17.71-41.09 7.62 14.91 8.56 40.94 1.86 40.89-27.22V47.64c7.3 4.39 13.57 6.74 20.73 6V42ZM51.18 52.31a44.52 44.52 0 0 1 4.72-.71v15.1c-6 1-9.84 2.84-11.63 6.25-5 9.63.23 17.47 7.2 20-8.72-.32-17.36-9.27-11.36-20.75 1.79-3.42 5-5.26 11.07-6.26V52.31Z"
    />
    <path
      className="cls-4"
      d="M78 18.25C79.49 31 86.59 38.58 98.93 39.37v14.31c-7.16.7-13.43-1.65-20.73-6v26.71C78.26 109.31 40.7 112 30 94.65c-7.13-11.51-4.54-39.19 21.22-43.29v14.53c-6 1-9.28 2.84-11.07 6.26-11 21.12 27.48 33.69 24.82-.34V18.25Z"
    />
  </svg>
)

export default TikTok

// This is LINE ICON

// import * as React from "react"

// const TikTok = (props) => (
//   <svg
//     width={25}
//     height={25}
//     viewBox="0 0 512 512"
//     xmlns="http://www.w3.org/2000/svg"
//     {...props}
//   >
//     <path d="M412.19 118.66a109.27 109.27 0 0 1-9.45-5.5 132.87 132.87 0 0 1-24.27-20.62c-18.1-20.71-24.86-41.72-27.35-56.43h.1C349.14 23.9 350 16 350.13 16h-82.44v318.78c0 4.28 0 8.51-.18 12.69 0 .52-.05 1-.08 1.56 0 .23 0 .47-.05.71v.18a70 70 0 0 1-35.22 55.56 68.8 68.8 0 0 1-34.11 9c-38.41 0-69.54-31.32-69.54-70s31.13-70 69.54-70a68.9 68.9 0 0 1 21.41 3.39l.1-83.94a153.14 153.14 0 0 0-118 34.52 161.79 161.79 0 0 0-35.3 43.53c-3.48 6-16.61 30.11-18.2 69.24-1 22.21 5.67 45.22 8.85 54.73v.2c2 5.6 9.75 24.71 22.38 40.82A167.53 167.53 0 0 0 115 470.66v-.2l.2.2c39.91 27.12 84.16 25.34 84.16 25.34 7.66-.31 33.32 0 62.46-13.81 32.32-15.31 50.72-38.12 50.72-38.12a158.46 158.46 0 0 0 27.64-45.93c7.46-19.61 9.95-43.13 9.95-52.53V176.49c1 .6 14.32 9.41 14.32 9.41s19.19 12.3 49.13 20.31c21.48 5.7 50.42 6.9 50.42 6.9v-81.84c-10.14 1.1-30.73-2.1-51.81-12.61Z" />
//   </svg>
// )

// export default TikTok
