import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
/* Components */

import AccountsWrapper from '../AccountsWrapper'
import { TabActions, TabContent, TabButtons } from '../../Tabs/Tabs'
import ReviewsAboutMe from './AboutMe'
import ReviewsByMe from './ByMe'
import NeedsReview from './NeedsReview'

import HostReviewCard from '../../Experiences/HostReviewCard'

/* Actions */
import * as userActionCreators from '../../../actions/user'
import * as modalActionCreators from '../../../actions/modals'
import * as formActionCreators from '../../../actions/forms'
import * as hostActionCreators from '../../../actions/host'
import SEO from '../../Shared/Web/SEO'

const gridSize = 'grid__item small--one-whole medium-down--one-half medium--one-half large-up--one-third widescreen-up--one-quarter'

class HostReviewsCardView extends Component {
  constructor(props) {
    super(props)
    this.state = {
      reviewData: [],
      reviewType: 'about',
      loading: false
    }
    // this.handleConfirmation.bind(this);
  }

  async componentWillMount() {
    const { userActions, hostActions } = this.props
    // await userActions.getMyReviews()
    await this.fetchData('reviews_about_me')
    this.setState({ loading: true })
    hostActions.getExperiences()
  }

  async fetchData(type) {
    const { reviews, userActions, hostActions } = this.props
    await hostActions.getMyReviews(type)
    if (type === 'reviews_by_me') {
      await this.setState({
        // reviewData: reviews.givenAsGuest,
        reviewData: this.props.reviews.reviews,
        reviewType: 'by',
        notFoundMessage: "You haven't reviewed any hosts."
      })
    }
    if (type === 'needs_review') {
      await this.setState({
        // reviewData: reviews.givenAsGuest,
        reviewData: this.props.reviews.reviews,
        reviewType: 'need',
        notFoundMessage: "You haven't reviewed any hosts."
      })
    }
    if (type === 'reviews_about_me') {
      await this.setState({
        // reviewData: reviews.givenAsGuest,
        reviewData: this.props.reviews.reviews,
        reviewType: 'about',
        notFoundMessage: "You don't have any reviews."
      })
    }
  }

  componentDidMount() {
    this.setState({
      reviewData: this.props.reviews.receivedAsHost,
      reviewType: 'about',
      notFoundMessage: "You don't have any reviews."
    })
  }

  render() {
    const { location, reviews } = this.props
    const defaultTab = location.pathname === '/hosts/reviews/needs-review' ? 'needsReview' : 'aboutYou'

    return (
      <div className="breakbread-container">
        <SEO title="Reviews (Host)" description="Reviews (Host)" />
        <div className=" text-center">
          <h1>Reviews (Host)</h1>
        </div>
        <div className="account">
          <div className="account-tabs text-center">
            {/* <TabActions namespace="reviews" data={reviewsTabs}>
                        {childProps => <TabButtons {...childProps} />}
        </TabActions> */}

            <ul className="nav nav-tabs flexCenter newtabs">
              <li className={this.state.reviewType == 'about' ? 'nav-item active' : 'nav-item'}>
                <a
                  className="nav-link"
                  style={{ padding: '10px 10px' }}
                  aria-current="page"
                  href="#"
                  onClick={async (e) => {
                    e.preventDefault()
                    await this.fetchData('reviews_about_me')
                    /* this.setState({
                      reviewData: reviews.receivedAsHost,
                      reviewType: 'about',
                      notFoundMessage: "You don't have any reviews."
                    }) */
                  }}
                >
                  About You
                </a>
              </li>
              <li className={this.state.reviewType == 'by' ? 'nav-item active' : 'nav-item'}>
                <a
                  className="nav-link"
                  style={{ padding: '10px 10px' }}
                  href="#"
                  onClick={async (e) => {
                    e.preventDefault()
                    await this.fetchData('reviews_by_me')
                    /* e.preventDefault()
                    this.setState({
                      reviewData: reviews.givenAsHost,
                      reviewType: 'by',
                      notFoundMessage: "You haven't reviewed any hosts."
                    }) */
                  }}
                >
                  By You
                </a>
              </li>
              {/*  <li className={this.state.reviewType == 'need' ? 'nav-item active' : 'nav-item'}>
                <a
                  className="nav-link"
                  style={{ padding: '10px 10px' }}
                  href="#"
                  onClick={e => {
                    e.preventDefault()
                    this.setState({
                      reviewData: reviews.receivedAsHost,
                      reviewType: 'need'
                    })
                  }}
                >
                  Needs Review
                </a>
              </li> */}
            </ul>
          </div>

          {this.state.reviewType == 'need' ? (
            <NeedsReview {...this.props} />
          ) : (
            <div className="grid grid--uniform">
              {this.state.reviewData.length > 0 ? (
                this.state.reviewData.map((review, idx) => (
                  <div key={'HostReviewCard-' + idx} className={gridSize}>
                    <HostReviewCard review={review} reviewType={this.state.reviewType} {...this.props} />
                    {/* <Link to={`/hosts/${host.id}`}> }
                    <ChefOrHostCard person={host} />
                    {/* </Link> */}
                  </div>
                ))
              ) : this.state.loading ? (
                <div className="text-center text-warning">
                  {/* <div className="grid__item section-header section-heading"> */}
                  <div className="breakbread-container">
                    <h5 className="text-center">{this.state.notFoundMessage}</h5>
                    {/* <p style={{ fontSize: '20px'}}>{this.state.notFoundMessage}</p> */}
                  </div>

                  <p>
                    <Link to="/experiences" className="btn">
                      Explore Experiences
                    </Link>
                  </p>
                </div>
              ) : (
                <div className="spinner spinner--empty" />
              )}
            </div>
          )}

          {/* <TabContent namespace="reviews" data={reviewsTabs} {...this.props} /> */}
        </div>
      </div>
    )
  }
}

HostReviewsCardView.defaultProps = {
  // onChange: null,
  // experiences: null,
}

HostReviewsCardView.propTypes = {
  userActions: PropTypes.shape({}).isRequired,
  reviews: PropTypes.shape({}).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  }).isRequired
}

const mapStateToProps = (state) => ({
  currentUser: state.reduxTokenAuth.currentUser,
  reviews: state.host.reviews,
  experiences: state.host.listings.past
})

const mapDispatchToProps = (dispatch) => ({
  formActions: bindActionCreators(formActionCreators, dispatch),
  userActions: bindActionCreators(userActionCreators, dispatch),
  hostActions: bindActionCreators(hostActionCreators, dispatch),
  modalActions: bindActionCreators(modalActionCreators, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(HostReviewsCardView)
