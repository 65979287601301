import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Modal from 'react-bootstrap/lib/Modal'
import * as hostActionCreators from '../../actions/host'
import * as modalActionCreators from '../../actions/modals'
import IconClose from '../../icons/global/SVGJS/IconClose'
import IconPencil from '../../icons/global/SVGJS/IconPencil'
import * as userActionCreators from '../../actions/user'
import SEO from '../Shared/Web/SEO'


/* NOTE: if currentUser form values match,they will be updated via
 * the initialValues of connectedStep5 which connects to Redux.
 * this happens automagically for all fields on the redux connected
 * form hostRegistration
 * @see https://redux-form.com/7.0.2/examples/initializefromstate/
 */

class HostingSpaceDetail extends Component {
  constructor(props) {
    super(props)
    this.state = {
      confirmShow: false,
      dialogMessage: null,
      currentObj: null
    }
  }


  _initDeleteLocation = (el) => {
    let dialogMessage = 'Are you sure to delete the location?'
    this.setState({confirmShow: true, dialogMessage, currentObj: el })  
  }

  handleClose = () => {
    this.setState({confirmShow: false, dialogMessage: null, currentObj: null })  
  }

  componentDidMount = async () => {
    const { userActions, hostActions } = this.props
    await userActions.getAmentyList()
    await hostActions.getLocations()
    await userActions.getWowElementList()
  }

  _deleteHostLocation = async () => {
    const { hostActions } = this.props
    const { currentObj }  = this.state
    this.handleClose()
    await hostActions.deleteLocation({ id: currentObj.id })
  }
  

  _editHostLocation = (currentLocation) => {
    const { modalActions } = this.props
    modalActions.showHostLocationFormModal({...this.props, currentLocation, type: 'update' })
  }

  _showHostLocationForm = ({ type }) => {
    const { modalActions } = this.props
    modalActions.showHostLocationFormModal({...this.props, type })
  }

  handleReadMore = (obj) => {
    const readmore = this.state[`readmore-${obj.id}`]
    this.setState({[`readmore-${obj.id}`]: typeof readmore === 'undefined' || !readmore? true : false })
  }

  render() {
    const {
      locations
    } = this.props

    const { confirmShow, dialogMessage } = this.state 

    return (
      <React.Fragment>
        <SEO title="Hosting Space Details" description="Hosting Space Details" />
        <div className="step5 hostingspace">
          <div className="page-header text-center">
            <h1>Hosting Space Details</h1>
          </div>
          <div style={{textAlign: 'right'}}><button style={{paddingTop: '5px', paddingBottom: '5px'}} className="btn form-footer--right" onClick={()=>this._showHostLocationForm({ type: 'new' })}>+ Add Location</button></div>
          <table className="responsive-table responsive-table--no-labels responsive-table--no-borders">
            <thead className="table-heading">
              <tr>
                <th colSpan="2" style={{textAlign: 'left'}}>#</th>
                <th colSpan="2" style={{textAlign: 'left'}}>Neighbourhood</th>
                <th colSpan="2" style={{textAlign: 'left'}}>Street Address</th>
                <th colSpan="2" style={{textAlign: 'left'}}>City</th>
                <th colSpan="2" style={{textAlign: 'left'}}>Country</th>
                <th colSpan="2" style={{textAlign: 'left'}}>Location Map Link</th>
                <th colSpan="2">Actions</th>
              </tr>
            </thead>
            <tbody>
              {locations && locations.map((el, index)=>(<tr key={index} className="responsive-table-row">
                <td colSpan="2" style={{textAlign: 'left'}}>
                  {index+1}
                </td>
                <td colSpan="2" style={{textAlign: 'left'}}>
                  {el.neighbourhood}
                </td>
                <td colSpan="2" style={{textAlign: 'left'}}>
                  {el.streetAddress && el.streetAddress.length > 40 && !this.state[`readmore-${el.id}`]? el.streetAddress.slice(0, 37) + '...': el.streetAddress}{' '}
                  {el.streetAddress && el.streetAddress.length > 40 && <span style={{cursor: 'pointer', fontWeight: 'bold', fontStyle: 'italic' }} onClick={()=>{ this.handleReadMore(el) }}>{ !this.state[`readmore-${el.id}`]?'read more':'less more'}</span>}
                  {/* { el.streetAddress} */}
                </td>
                <td colSpan="2" style={{textAlign: 'left'}}>
                  {el.city}
                </td>
                <td colSpan="2" style={{textAlign: 'left'}}>
                  {el.countryName}
                </td>
                <td colSpan="2" style={{textAlign: 'left'}}>
                  <a
                    href={el.destinationLink}
                    target="_blank"
                    style={{textDecoration: 'none' }}
                    rel="noreferrer"
                  >
                    {el.destinationLink}
                  </a>
                </td>
                <td colSpan="2" style={{textAlign: 'left'}}>
                  <button 
                    style={{color: 'blue'}}
                    type="button"
                    className="btn--link btn--remove"
                    title="Edit"
                    onClick={()=>this._editHostLocation(el)}
                  >
                        
                    <IconPencil />
                  </button>
                  <button 
                    style={{ color: 'red'}}
                    type="button"
                    className="btn--link"
                    title="Cancel"
                    onClick={()=>this._initDeleteLocation(el)}
                  >
                    <IconClose />
                  </button>
                </td>
              </tr>))}
            </tbody>
          </table>
        </div>
        <Modal
          show={confirmShow}
          onHide={()=>this.handleClose()}
          animation={false}
        >
          <Modal.Header closeButton>
            <Modal.Title style={{textAlign: 'center'}}>Confirm!</Modal.Title>
          </Modal.Header>
    
          <Modal.Body>
            <p>{dialogMessage}</p>
          </Modal.Body>
    
          <Modal.Footer>
            <button style={{paddingTop: '10px', paddingBottom: '10px'}} className="btn form-footer--right" onClick={()=>{this._deleteHostLocation()}} >OK</button>
            <button style={{paddingTop: '10px', paddingBottom: '10px'}} className="btn form-footer--right" onClick={()=>{this.handleClose()}} >Cancel</button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    )
  }
}

HostingSpaceDetail.propTypes = {
  // pristine: PropTypes.bool.isRequired,
  // submitting: PropTypes.bool.isRequired,
  // valid: PropTypes.bool.isRequired,
  // previousPage: PropTypes.func.isRequired,
  // handleSubmit: PropTypes.func.isRequired,
  // currentUser: currentUserPropTypes.isRequired,
  // locationPhotosValues: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
}

const mapDispatchToProps = dispatch => ({
  modalActions: bindActionCreators(modalActionCreators, dispatch),
  hostActions: bindActionCreators(hostActionCreators, dispatch),
  userActions: bindActionCreators(userActionCreators, dispatch)
})

const mapStateToProps = state => ({
  pathname: state.router.location.pathname,
  currentUser: state.reduxTokenAuth.currentUser,
  locations: state.host.location.allLocations?state.host.location.allLocations:[],
  firstHostLocationObj: state.host.location.allLocations?state.host.location.allLocations[0]:null,
  stepForm: state.stepForm.hostRegistration,
  /* locationPhotosValues:
    typeof state.form.hostRegistration !== 'undefined'
      ? state.form.hostRegistration.values.locationPhotos
      : null, */
  hostForm: state.form.hostRegistration,
  countryOptions: state.user.countryCode.countryList
    ? state.user.countryCode.countryList.list
    : null,
  amenitiesOptions: state.user.amenitiesAndWowElements.amenityList
    ? state.user.amenitiesAndWowElements.amenityList.list
    : null,
  wowElementOptions: state.user.amenitiesAndWowElements.wowElementList
    ? state.user.amenitiesAndWowElements.wowElementList.list
    : null
})

export default connect(mapStateToProps, mapDispatchToProps)(HostingSpaceDetail)
