import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import PastCard from '../MyExperiences/PastCard'

const gridSize = 'grid__item medium-down--one-half medium--one-half large-up--one-third'

class NeedsReview extends Component {
  componentDidMount() {
    const { fetch } = this.props
    fetch()
  }

  render() {
    const { reservations } = this.props

    const reservationsList = reservations.filter(
      item => !(item.status !== 'Confirmed' || item.reviewedByGuest)
    )
    if (!reservationsList.length) {
      return (
        <div className="text-center text-warning">
          <div className="breakbread-container">
            <h5 className="text-center">You don&apos;t have any experiences needing review at this time.</h5>
          </div>
          <p>
            <Link to="/experiences" className="btn">
              Explore Experiences
            </Link>
          </p>
        </div>
      )
    }
    return (
      <div className="breakbread-container">
        <div className="grid grid--uniform">
          {typeof reservationsList !== 'undefined'
            && reservationsList.length > 0
            && reservationsList.map(item => (
              <div key={`PastCard-${item.id}`} className={gridSize}>
                <PastCard
                  reservation={item}
                  experience={item.experience}
                  callbackParams={{ needs_guest_reviews: true }}
                />
              </div>
            ))}
        </div>
      </div>
    )
  }
}

NeedsReview.propTypes = {
  reservations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  fetch: PropTypes.func.isRequired
}

export default NeedsReview
