import api from '../utils/api'

import { SET_FEATURED_ITEMS } from '../constants/actionTypes'

import { normalizeFeatured } from '../utils/normalization'

export function setFeatured(featuredItems) {
  return { type: SET_FEATURED_ITEMS, featuredItems }
}

export function getFeatured() {
  return dispatch => {
    api(`featured/home_page?reset_cache=t`)
      .then(response => {
        const json = response.data
        if (json.is_success) {
          dispatch(setFeatured(normalizeFeatured(json)))
        } else {
          // console.log(json.error);
        }
      })
      .catch(e => console.log(e)) /* eslint-disable-line no-console */
  }
}
