import {
  SET_HOST_FILTER,
  AUTH_TOKEN_FAILED,
  AUTH_SIGNOUT_SUCCESS
} from '../../constants/actionTypes'

const initialState = {
  rating: null,
  cuisineTypes: ''
}

export default function(state = initialState, action) {
  if (action.type === SET_HOST_FILTER) {
    // console.log('action', action);
    return {
      ...state,
      ...action.filter
    }
  }

  if (action.type === AUTH_SIGNOUT_SUCCESS) {
    return initialState
  }

  if (action.type === AUTH_TOKEN_FAILED) {
    return initialState
  }
  return state
}
