import api from './api'

const setupMultiPartDataInterceptors = () => {
  api.interceptors.request.use(
    async config => {
      const newConfig = config
      newConfig.headers = {
        ...config.headers,
        'Content-Type': 'multipart/form-data'
      }
      //console.log('setupMultiPartDataInterceptors USED', newConfig.headers);
      return newConfig
    },
    error =>
      // Do something with request error
      Promise.reject(error)
  )
}

export { setupMultiPartDataInterceptors }
