import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import ChefAvtarHost from '../Chef/ChefAvtarHost'
import ChefInfo from '../Chef/ChefInfo'

import DinerBookingActionButtons from './Diners/DinerBookingActionButtons'

export default class UserReservationCard extends Component {
  // constructor(props) {
  //   super(props);
  // }
  componentDidMount() {}

  componentWillUnmount() {}

  render() {
    const {
      reservation,
      updateReservation,
      cancelReservation,
      isCancellable, 
      getReservations
    } = this.props

    const {
      bookingNumber,
      experience,
      currency,
      netTotal,
      total,
      bookedDate,
      status,
      paymentStatus,
      seatsBooked,
      featured,
      hideAvatar,
      forceHoverState,
      displaySoldStatus,
      guest
    } = reservation
    const now = new Date()
    // const hasPassed = experience.date < now.toISOString()
    let extraClassNames = ''
    extraClassNames += featured ? ' experience-card--featured' : ''
    extraClassNames += forceHoverState ? ' grid-view-item--force-hover' : ''
    extraClassNames += hideAvatar ? ' experience-card--avatar-hidden' : ''
    // experience.readableTime = experience.time ? experience.time : experience.readableTime;


    let paymentStatusColor = 'grey'
    switch (paymentStatus) {
    case 'authorised':
      paymentStatusColor = 'green'
      break
    case 'succeeded':
      paymentStatusColor = 'green'
      // paymentStatusColor = 'grey'
      break
    case 'voided':
      paymentStatusColor = 'grey'
      break
    case 'declined':
    case 'flagged':
    case 'failed':
      paymentStatusColor = 'red'
      // paymentStatusColor = 'grey'
      break
    case 'refunded':
      paymentStatusColor = 'orange'
      break
    default:
      break
    }
    let paymentStatusStyle = { color: paymentStatusColor }

    let statusClass = 'badge '

    switch (status) {
    case 'confirmed':
      statusClass = 'badge badge-success'
      break
    case 'cancelled':
      statusClass = 'badge badge-error'
      break
    case 'declined':
      statusClass = 'badge badge-rejected'
      break
    default:
      statusClass = 'badge badge-pending'
      break
    }

    let paymentStatusClass = 'badge '

    switch (paymentStatus) {
    case 'succeeded':
      paymentStatusClass = 'badge badge-success'
      break
    case 'cancelled':
    case 'failed':
      paymentStatusClass = 'badge badge-error'
      break
    case 'declined':
    case 'flagged':
      paymentStatusClass = 'badge badge-rejected'
      break
    default:
      paymentStatusClass = 'badge badge-pending'
      break
    }

    return (
      <React.Fragment>
        <div className={`experience-card grid-view-item ${extraClassNames}`}>
          <div className="experience-card__image--container grid-view-item__link grid-view-item__image-container">
            <div className="grid-view-item__image-wrapper js ">
              <Link to={`/experiences/${experience.slug}`}>
                <div className="grid-view-item__image-container">
                  <div
                    className="grid-view-item__overlay box ratio-container lazyload js"
                    // NOTE: NEED TO ADD LAZY lOAD SUPPORT
                    data-sizes="auto"
                    data-parent-fit="cover"
                    style={{
                      backgroundImage: `url("${experience.image}")`
                    }}
                  />
                  <div className="experience-card__hover-meta grid-view-item_hover-el">
                    <span>{/* experience.summary */}</span>
                  </div>
                </div>
              </Link>
            </div>
          </div>

          <div className="experience-card__meta grid-view-item__meta">
            {!hideAvatar && (
              <ChefAvtarHost displayInfo={false} person={experience.host} />
            )}
            <div className="experience-card__text grid-view-item__text">
              {!hideAvatar && (
                <ChefInfo displaySpecialties={false} person={experience.host} />
              )}

              <div className="experience-card__title grid-view-item__title">
                <Link to={`/experiences/${experience.slug}`}>
                  <h5>{experience.title}</h5>
                </Link>
              </div>
            </div>

            <span onClick={() => {
              this.props.showModal(reservation)
            }} 
            style={{ cursor: 'pointer' }}>{bookingNumber}</span>
          </div>

          <span className="cardDate">
            <p>Experience Date: {reservation.eventDate}</p>
          </span>
          {/*    <div className="experience-card__reservation-info group">
                        <div className="experience-card__date color_orange">

                        </div>
                        <div className="experience-card__price">
                            Seats <span><p>{seatsBooked}</p></span>
                        </div>
                    </div>
                */}
          <div className="experience-card__reservation-info group ">
            <div
              className="experience-card__date"
              style={{ borderLeft: 'none' }}
            >
              <span style={{ color: '#f8a48d' }}>
                Booking Status{' '}
              </span>
              <span>
                <p
                  style={{
                    fontSize: '14px',
                    margin: '0 auto',
                    marginTop: '5px',
                    padding: '7px',
                    color: 'white'
                  }}
                  className={statusClass}
                >
                  {status.charAt(0).toUpperCase() + status.slice(1)}
                </p>
              </span>
            </div>
            <div className="experience-card__price">
              Seats{' '}
              <span>
                <p>{seatsBooked}</p>
              </span>
            </div>
            <div className="experience-card__date">
              <span style={{ color: '#f8a48d' }}>
                Payment Status{' '}
              </span>
              <p
                className={paymentStatusClass}
                style={{
                  fontSize: '14px',
                  margin: '0 auto',
                  marginTop: '5px',
                  padding: '7px',
                  color: 'white'
                  // ...paymentStatusStyle
                }}
              >
                {paymentStatus.charAt(0).toUpperCase() + paymentStatus.slice(1)}
              </p>
            </div>
            <div className="experience-card__price">
              {currency}
              <p>
                { parseFloat(netTotal).toFixed(2) }
              </p>
            </div>
          </div>

          <DinerBookingActionButtons {...this.props} />
        </div>
      </React.Fragment>
    )
  }
}

UserReservationCard.defaultProps = {
  featured: false,
  hideAvatar: false,
  forceHoverState: false
}
