// import moment from 'moment';
import {
  SET_MY_REVIEWS,
  AUTH_TOKEN_FAILED,
  AUTH_SIGNOUT_SUCCESS,
  SET_GUEST_REVIEWS,
  SET_MY_HOST_REVIEWS,
  SET_MY_DINERS_REVIEWS
} from '../../constants/actionTypes'

const initialState = {
  receivedAsHost: [],
  givenAsHost: [],
  reviews:[],
  diners_reviews:[],
  guestCommunitiesReviews: [],
  hostReviews: []
}

export default function(state = initialState, action) {
  if (action.type === AUTH_SIGNOUT_SUCCESS) {
    return initialState
  }

  if (action.type === AUTH_TOKEN_FAILED) {
    return initialState
  }
  if (action.type === SET_MY_REVIEWS) {
    return {
      ...state,
      // receivedAsHost: [...action.payload.received_as_host],
      // givenAsHost: [...action.payload.given_as_host],
      hostReviews: [...action.payload.guest_reviews]
    }
  }

  if (action.type === SET_GUEST_REVIEWS) {
    return {
      ...state,
      guestCommunitiesReviews: [...action.payload]
    }
  }
  
  if (action.type === SET_MY_HOST_REVIEWS) {
    return {
      ...state,
      reviews: [...action.payload]
    }
  }
  if (action.type === SET_MY_DINERS_REVIEWS) {
    return {
      ...state,
      diners_reviews: [...action.payload]
    }
  }


  return state
}
