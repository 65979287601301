import React from "react"

const Honey = props => (
  <svg viewBox="0 0 88 88" width="1em" height="1em" {...props}>
    <g fill="currentColor" data-name="Layer 2">
      <path d="M56.43 42c-1.58-2-4.72-3.47-7.43-4.48V35a1.06 1.06 0 0 0-.11-.44 4.62 4.62 0 0 0-2.8-3.13A2 2 0 0 1 48 30a1 1 0 0 0 0-2 4 4 0 0 0-3.86 3h-.28A4 4 0 0 0 40 28a1 1 0 0 0 0 2 2 2 0 0 1 1.9 1.43 4.61 4.61 0 0 0-2.79 3.13.93.93 0 0 0-.11.44v2.52c-2.71 1-5.85 2.51-7.43 4.44a6.32 6.32 0 0 0 .37 8.74A5.42 5.42 0 0 0 35.5 52h.5a6.14 6.14 0 0 0 1.68-.4A8.38 8.38 0 0 0 43 57.73V59a1 1 0 0 0 2 0v-1.27a8.38 8.38 0 0 0 5.31-6.15A6.14 6.14 0 0 0 52 52h.51a5.42 5.42 0 0 0 3.56-1.3 6.32 6.32 0 0 0 .36-8.7zM41 35.5a2.78 2.78 0 0 1 3-2.5 2.79 2.79 0 0 1 3 2.5v1.32a38.69 38.69 0 0 0-2.77-.79H43.77c-.13 0-1.25.3-2.77.79zm3 6a34.44 34.44 0 0 0 2 5.5h-4a33.55 33.55 0 0 0 2-5.52zM35.84 50a3.49 3.49 0 0 1-2.59-.81 4.31 4.31 0 0 1-.14-6c1.92-2.35 7-4.08 9.59-4.84-.56 2.54-1.85 7.53-3.78 9.9A4.64 4.64 0 0 1 35.84 50zM44 56a6.5 6.5 0 0 1-3.68-3h7.36A6.5 6.5 0 0 1 44 56zm4.42-5h-8.84a4.38 4.38 0 0 1 0-.51 7.52 7.52 0 0 0 .93-.94c.14-.17.27-.36.4-.55h6.26c.13.19.26.38.4.55a6.89 6.89 0 0 0 .93.94c-.05.17-.06.34-.08.51zm6.33-1.82a3.5 3.5 0 0 1-2.59.81 4.64 4.64 0 0 1-3.08-1.7c-1.94-2.37-3.22-7.36-3.78-9.9 3 .86 7.79 2.64 9.58 4.84a4.3 4.3 0 0 1-.13 5.95z" />
      <path d="M44 88a44 44 0 1 1 44-44 44.05 44.05 0 0 1-44 44zm0-86a42 42 0 1 0 42 42A42 42 0 0 0 44 2z" />
    </g>
  </svg>
)

export default Honey
