import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import _ from 'lodash'

import { validate } from '../../Registration/Hosts/validate'
import * as fields from '../../Form/Fields/formFields'
import { hostAddressFields } from '../../../constants/formFields'
import * as hostActionCreators from '../../../actions/host'
import alertModal from '../../../utils/alert'
import * as modalActionCreators from '../../../actions/modals'
// import { amenities, wow_elements } from '../../../constants'

function keysMatch(key, obj) {
  const keys = Object.keys(obj).map(x => x.toLowerCase())
  return keys.indexOf(key.toLowerCase()) !== -1
}

/* NOTE: if currentUser form values match,they will be updated via
 * the initialValues of connectedStep5 which connects to Redux.
 * this happens automagically for all fields on the redux connected
 * form hostRegistration
 * @see https://redux-form.com/7.0.2/examples/initializefromstate/
 */

class HostLocationForms extends Component {
  constructor(props) {
    super(props)
  }

  disableFields = fieldArray => {
    const { currentUser } = this.props
    if (typeof currentUser === 'undefined' || !currentUser.isSignedIn) {
      return fieldArray
    }
    const obj = fieldArray
    Object.keys(obj).map(key => {
      obj[key].disabled = !!keysMatch(key, currentUser.attributes)
      return obj[key]
    })
    return obj
  }

  limitText = limit => value => {
    if (value.length < 0) {
      return value
    } else if (value.length > limit) {
      return value.slice(0, (limit-1))
    } else {
      return value
    }
  }

  componentDidMount = async () => {
    const { currentLocation } = this.props
    if (currentLocation) {
      if(currentLocation.amenities)  {
        this.props.change(
          'amenities',
          currentLocation.amenities.map(el=>({value: el.id, label: el.name}))
        )
      }
      if(currentLocation.wowElements)  {
        this.props.change(
          'wow_elements',
          currentLocation.wowElements.map(el=>({value: el.id, label: el.name}))
        )
      }
      this.props.change('hostingLocationStreetAddress', currentLocation.streetAddress )
      this.props.change('hostAddressFieldsNeighbourhood', currentLocation.neighbourhood )
      this.props.change('hostAddressFieldsPostalCode', currentLocation.postalCode )
      this.props.change('hostAddressFieldsCity', currentLocation.city )
      this.props.change('hostAddressFieldsCountryCode', currentLocation.countryCode )
      this.props.change('hostAddressFieldsDestinationLink', currentLocation.destinationLink )
    } else {
      this.props.change( 'amenities', [])
      this.props.change( 'wow_elements',[])
      this.props.change('hostingLocationStreetAddress', '' )
      this.props.change('hostAddressFieldsNeighbourhood', '' )
      this.props.change('hostAddressFieldsPostalCode', '' )
      this.props.change('hostAddressFieldsCity', '' )
      this.props.change('hostAddressFieldsCountryCode', '' )
      this.props.change('hostAddressFieldsDestinationLink', '' )
    }
  }

  render() {
    const {
      valid,
      submitting,
      amenitiesOptions,
      wowElementOptions,
      handleSubmit
    } = this.props

    const newAmenities =  _.sortBy(amenitiesOptions?amenitiesOptions.filter(el=>el.status === 'active').map((el)=>{return {value:el.id, label:el.name}}):[],"label")
    const newWowElements =  _.sortBy(wowElementOptions?wowElementOptions.filter(el=>el.status === 'active').map((el)=>{return {value:el.id, label:el.name}}):[],"label")

    return (
      <React.Fragment>
        <form onSubmit={handleSubmit} className="step5">
          <div className="form-body form-body--med">
            <div className="grid__item small--one-whole medium-up--full">
              {/* Address Fields */}
              {/* <fields.FieldSet
                fields={hostAddressFields}
                validate={validate}
                parent="hostingLocation"
                wrap
                legend="Address of hosting space"
                className="flat"
              /> */}
              <div className="grid grid--uniform">
                <div className="grid__item  customLabel medium-up ">
                  <Field
                    name="hostingLocationStreetAddress"
                    component={fields.renderField}
                    disabled={submitting}
                    value={hostAddressFields.address.value}
                    placeholder={hostAddressFields.address.placeholder}
                    isClearable
                    inputType="fullText"
                    // className="customLabel"
                    label="Street address  *"
                    displayLabel
                    normalize={this.limitText(100)}
                  />
                  <span className="limittexthostaddress">
                    <span className="field_limit_text__limit">{ 100 - (this.props.hostForm && this.props.hostForm.values && this.props.hostForm.values.hostingLocationStreetAddress ? this.props.hostForm.values.hostingLocationStreetAddress.length : 0) } characters remaining</span>
                  </span>
                </div>
              </div>
              <div className="grid grid--uniform">
                <div className="grid__item  medium-up--one-half large-up--two-third">
                  <Field
                    name="hostAddressFieldsNeighbourhood"
                    component={fields.renderField}
                    disabled={submitting}
                    value={hostAddressFields.neighbourhood.value}
                    placeholder={hostAddressFields.neighbourhood.placeholder}
                    isClearable
                    inputType="fullText"
                    label="Neighbourhood"
                    displayLabel
                  />
                </div>
                <div className="grid__item  medium-up--one-half large-up--two-third">
                  <Field
                    name="hostAddressFieldsPostalCode"
                    component={fields.renderField}
                    disabled={submitting}
                    value={hostAddressFields.postalCode.value}
                    placeholder={hostAddressFields.postalCode.placeholder}
                    isClearable
                    inputType="fullText"
                    label="Postal Code"
                    displayLabel
                  />
                </div>
              </div>
              <div className="grid grid--uniform">
                {/* <div className="grid__item medium-up large-up--two-thirds mb-5">
                  <div className="grid grid--uniform"> */}
                <div className="grid__item  medium-up--one-half large-up--two-third">
                  <Field
                    name="hostAddressFieldsCity"
                    component={fields.renderSelectField}
                    disabled={submitting}
                    value={hostAddressFields.city.value}
                    options={hostAddressFields.city.options}
                    placeholder={hostAddressFields.city.placeholder}
                    isClearable
                    inputType="fullText"
                    label="City *"
                    // displayLabel={false}
                  />
                </div>

                <div className="grid__item  medium-up--one-half large-up--two-third">
                  <Field
                    name="hostAddressFieldsCountryCode"
                    component={fields.renderSelectField}
                    disabled={submitting}
                    value={hostAddressFields.countryCode.value}
                    options={hostAddressFields.countryCode.options}
                    placeholder={hostAddressFields.countryCode.placeholder}
                    isClearable
                    type="select"
                    selectType="country"
                    label="Country *"
                    // displayLabel={false}
                  />
                </div>
                {/* </div>
                </div> */}
              </div>
              <div className="grid grid--uniform">
                <div className="grid__item customLabel medium-up ">
                  <Field
                    name="hostAddressFieldsDestinationLink"
                    component={fields.renderField}
                    disabled={submitting}
                    value={hostAddressFields.destination.value}
                    placeholder={hostAddressFields.destination.placeholder}
                    isClearable
                    inputType="fullText"
                    label="Location Map Link"
                    displayLabel
                  />
                  <span className="limittexthostaddress">
                    <span className="field_limit_text__limit">{hostAddressFields.destination.helperText}</span>
                  </span>
                </div>
              </div>

              <div className="grid grid--uniform">
                <div className="grid__item medium-up">
                  <Field
                    name="amenities"
                    component={fields.renderSelectField}
                    // disabled={submitting}
                    options={newAmenities}
                    placeholder="Select Amenities"
                    isClearable
                    isMulti
                    type="select"
                    selectType="amenity"
                    label="Amenities"
                    displayLabel
                  />
                </div>
                <div className="grid__item medium-up">
                  <Field
                    name="wow_elements"
                    component={fields.renderSelectField}
                    // disabled={submitting}
                    options={newWowElements}
                    placeholder="Select wow elements"
                    isClearable
                    isMulti
                    type="select"
                    selectType="wow_elements"
                    label="Wow Elements"
                    displayLabel
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="form-footer">
            {/* || !hostingPhotosPresent() */}
            <button
              type="submit"
              disabled={!valid}
              /* onClick={async () => {
                await this.handleHostData(this.props.hostForm.values)
              }} */
              className="btn btn--darker form-footer--right"
            >
              Save & Continue
            </button>
          </div>
        </form>
      </React.Fragment>
    )
  }
}

HostLocationForms.propTypes = {
  // pristine: PropTypes.bool.isRequired,
  // submitting: PropTypes.bool.isRequired,
  // valid: PropTypes.bool.isRequired,
  // previousPage: PropTypes.func.isRequired,
  // handleSubmit: PropTypes.func.isRequired,
  // currentUser: currentUserPropTypes.isRequired,
  // locationPhotosValues: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
}

// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
const HostLocationForm = reduxForm({
  form: 'hostLocation',
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  validate
})(HostLocationForms)

const mapDispatchToProps = dispatch => ({
  hostActions: bindActionCreators(hostActionCreators, dispatch),
  modalActions: bindActionCreators(modalActionCreators, dispatch)
})

const mapStateToProps = state => ({
  currentUser: state.reduxTokenAuth.currentUser,
  /* locationPhotosValues:
    typeof state.form.hostRegistration !== 'undefined'
      ? state.form.hostRegistration.values.locationPhotos
      : null, */
  hostForm: state.form.hostLocation,
  countryOptions: state.user.countryCode.countryList
    ? state.user.countryCode.countryList.list
    : null,
  amenitiesOptions: state.user.amenitiesAndWowElements.amenityList
    ? state.user.amenitiesAndWowElements.amenityList.list
    : null,
  wowElementOptions: state.user.amenitiesAndWowElements.wowElementList
    ? state.user.amenitiesAndWowElements.wowElementList.list
    : null
})

export default connect(mapStateToProps, mapDispatchToProps)(HostLocationForm)
