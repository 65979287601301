import {
  COUPON_LOADING,
  SET_MY_COUPONS,
  SET_MY_REFERRAL_COUPON,
} from '../constants/actionTypes'

const initialState = {
  coupons: [],
  refCoupon: null,
  meta: {totalCount: 0, page: 1},
  loading: false
}

export default function(state = initialState, action) {
  switch (action.type) {
    case COUPON_LOADING:
      const { loading } = action
      return {
        ...state,
        loading
      }
    case SET_MY_COUPONS:
      return  {
        ...state,
        coupons: action.meta.page > 1 ? [...state.coupons, ...action.coupons] : action.coupons,
        meta: action.meta
      }
    case SET_MY_REFERRAL_COUPON:
      return {
        ...state,
        refCoupon: action.refCoupon
      }
    default:
      return state
  }
}