import { connect } from 'react-redux'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { showAuthModal } from '../../../actions/modals'
import * as userActionCreators from '../../../actions/user'
import * as hostActionCreators from '../../../actions/host'
import { trackGetStartedHostApplication } from '../../../utils/tracking'
import { history } from '../../../configureStore'

const getHostApplicationButtonText = (hostApplication) => {
  let applicationText
  switch (hostApplication) {
  case 'started':
    applicationText = 'COMPLETE APPLICATION'
    break
  case 'complete':
    applicationText = 'PENDING APPROVAL'
    break
  case 'declined':
    applicationText = 'Update your Application'
    break
  default:
    applicationText = 'GET STARTED'
    break
  }
  return applicationText
}
class RegHostLink extends Component {
  redirect() { history.push('/become-a-host/step-1') }

  render() {
    const { className, children, showModal, currentUser, hostDown, userActions, hostActions } = this.props
    const { role, hostApplication } = currentUser.attributes

    return (
      <React.Fragment>
        <p>
          {hostApplication === 'declined'
            ? 'Your Host Application was declined by the admin. Please click on the button below to apply again'
            : ''}
        </p>
        <button
          type="button"
          className={`${className}`}
          disabled={role === 'host' || hostApplication === 'complete'}
          onClick={() => {
            if (currentUser.isSignedIn) {
              // userActions.getUser(currentUser.attributes.id)
              trackGetStartedHostApplication({ status: 'user' })
              hostActions.hostApplicationStarted()
              history.push('/become-a-host/step-1')
              // return <Redirect to="/become-a-host/step-1">
            } else {
              trackGetStartedHostApplication({ status: 'not_user' })
              showModal({ show: true, isHost: true })
            }
          }}
          style={hostDown ? { zIndex: 'auto', background: '#343741' } : null}
        >
          <b>
            {/* {hostApplication === 'started' ? "COMPLETE PROCESS" : (hostApplication === 'complete' ? "APPROVAL PENDING" :
            (hostApplication === 'not_applied' ? "GET STARTED" : null))} */}
            {getHostApplicationButtonText(hostApplication)}
          </b>
        </button>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  currentUser: state.reduxTokenAuth.currentUser
})

const mapDispatchToProps = dispatch => ({
  showModal: state => dispatch(showAuthModal(state)),
  userActions: bindActionCreators(userActionCreators, dispatch),
  hostActions: bindActionCreators(hostActionCreators, dispatch)
})
export default connect(mapStateToProps, mapDispatchToProps)(RegHostLink)
