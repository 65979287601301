import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import _ from 'lodash'

import { validate } from '../../Registration/Hosts/validate'
import FileDropField from '../../Form/Fields/renderFileDropField'
import * as hostActionCreators from '../../../actions/host'
import * as modalActionCreators from '../../../actions/modals'


/* NOTE: if currentUser form values match,they will be updated via
 * the initialValues of connectedStep5 which connects to Redux.
 * this happens automagically for all fields on the redux connected
 * form hostRegistration
 * @see https://redux-form.com/7.0.2/examples/initializefromstate/
 */

class HostLocationForms extends Component {
  constructor(props) {
    super(props)
  }


  componentDidMount = async () => {
    const { currentLocation } = this.props
    if (currentLocation) {
      this.props.change('hostLocationPhotos', currentLocation.galleryImages )
    } else {
      this.props.change('hostLocationPhotos', [] )
    }
  }

  render() {
    const {
      valid,
      currentLocation,
      handleSubmit,
      previousPage,
      successMessage
    } = this.props
    let notValid = true
    if (this.props.hostForm.values && this.props.hostForm.values.hostLocationPhotos && this.props.hostForm.values.hostLocationPhotos.length > 0) {
      notValid = false
    }
    return (
      <React.Fragment>
        { successMessage && <div className="form-header">
          {/* <h4>Welcome {currentUser.attributes.fullName}</h4> */}
          {/* <h4>Upload your Profile Picture</h4> */}
          <p style={{color: '#F3A48D'}}>{successMessage}</p>
        </div>
        } 
        <form onSubmit={handleSubmit} className="step5">
          <div className="form-body form-body--med">
            <div className="grid__item small--one-whole medium-up--full">
              <label style={{ marginLeft: '15px', marginTop: '15px' }}>Upload Photos</label>
              <p className="m-5">
                Upload photos that best depict your experience. The first one in the sequence will be used on the cover photo.
                <br />
                (JPEG or PNG less than 2 MB, 1280×780 pixels. Aspect ratio 16:9)
              </p>
              {<Field name="hostLocationPhotos" parentComponent="locationPhotos" locationId={currentLocation.id} label="" component={FileDropField} type="file" imageFile={currentLocation.galleryImages} />}
            </div>
          </div>
          <div className="form-footer">
            {/* || !hostingPhotosPresent() */}
            <button
              type="button"            
              className="btn btn--link form-footer--left"
              onClick={() => previousPage()}
            >
              Go Back a Step
            </button>
            <button
              type="submit"
              disabled={ notValid }
              /*  onClick={async () => {
                await this.handleHostData(this.props.hostForm.values)
              }} */
              className="btn btn--darker form-footer--right"
            >
              Save & Close
            </button>
          </div>
        </form>
      </React.Fragment>
    )
  }
}

HostLocationForms.propTypes = {
  // pristine: PropTypes.bool.isRequired,
  // submitting: PropTypes.bool.isRequired,
  // valid: PropTypes.bool.isRequired,
  // previousPage: PropTypes.func.isRequired,
  // handleSubmit: PropTypes.func.isRequired,
  // currentUser: currentUserPropTypes.isRequired,
  // locationPhotosValues: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
}

// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
const HostLocationForm = reduxForm({
  form: 'hostLocation',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  validate,
  enableReinitialize: true,
  asyncBlurFields: []
})(HostLocationForms)

const mapDispatchToProps = dispatch => ({
  hostActions: bindActionCreators(hostActionCreators, dispatch),
  modalActions: bindActionCreators(modalActionCreators, dispatch)
})

const mapStateToProps = state => ({
  currentUser: state.reduxTokenAuth.currentUser,
  hostForm: state.form.hostLocation
})

export default connect(mapStateToProps, mapDispatchToProps)(HostLocationForm)
