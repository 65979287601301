import {
  SET_LOCATIONS,
  SET_SINGLE_LOCATION,
  AUTH_SIGNOUT_SUCCESS,
  AUTH_TOKEN_FAILED
} from '../../constants/actionTypes'
  
const initialState = {
  allLocations:[],
  location:null
}
  
export default function(state = initialState, action) {
  if (action.type === SET_LOCATIONS) {
    return {
      ...state,
      allLocations:[...action.payload.location]
    }
  }
  if (action.type === SET_SINGLE_LOCATION) {
    //  console.log('action host', action);
    return {
      ...state,
      location:action.payload.location
    }
  }
  if (action.type === AUTH_SIGNOUT_SUCCESS) {
    return initialState
  }
  if (action.type === AUTH_TOKEN_FAILED) {
    return initialState
  }
  return state
}
  