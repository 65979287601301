// Libraries
import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Link } from 'react-router-dom'

// Components
import Hero from '../Shared/Web/Hero'
import ImageWithOverlaySquare from '../Shared/Web/ImageWithOverlaySquare'
import staticCmsData from '../../utils/cmsData.json'

function Section({ isTop, isLoading, data, sectionName, type, theme, page, modalActions, currentUser }) {
  const isMobile = window.matchMedia('(max-width: 1005px)').matches
  const themeForHero = isMobile ? null : 'light'

  if (!data || !data.sections || (data.sections && data.sections.length === 0)) {
    // console.log('Invalid data passed to cms section')
    return null
  }
  // console.log('matchingSectionData', sectionName, matchingSectionData, data.sections)

  let matchingSectionData = _.find(data.sections, { code: sectionName })

  if (!(_.find(data.sections, { code: sectionName }))) {
    const matchingPage = _.find(staticCmsData, { page })
    matchingSectionData = _.find(matchingPage.sections, { code: sectionName })
  }

  if (!matchingSectionData) {
    return null
  }

  if (type === 'hero') {
    const { title, subTitle, buttonText, buttonLink, textAlignment, desktopImageBgUrl, mobileImageBgUrl } = matchingSectionData.content

    let Alignment = 'left'
    if (textAlignment === 'right') Alignment = 'right'
    if (textAlignment === 'center') Alignment = 'center'

    return (
      <Fragment>
        <Hero
          title={title}
          subtitle={subTitle}
          buttonCTA={buttonText}
          buttonLink={buttonLink}
          heroImage={desktopImageBgUrl}
          textPosition={Alignment}
          theme={theme || themeForHero}
          mobileHeroImage={mobileImageBgUrl}
          topHero={isTop}
          isLoading={isLoading}
          modalActions={modalActions}
          currentUser={currentUser}
          sectionName={sectionName}
        />
      </Fragment>
    )
  }

  if (type === 'overlaySquare') {
    const { title, subTitle, buttonText, buttonLink } = matchingSectionData.content

    return (
      <Fragment>
        <ImageWithOverlaySquare theme={theme}>
          <h2 className="h1 mega-title">{title}</h2>
          <p className="mega-subtitle">
            {subTitle}
          </p>
          {buttonText && buttonLink && (
            <React.Fragment>
              <Link
                to="/become-a-host"
                className="breakbread-btn btn--flat btn--dark"
                // onClick={() => handleMobileMenu()}
              >
                Become A Host
              </Link>
            </React.Fragment>
          )}
        </ImageWithOverlaySquare>
      </Fragment>
    )
  }
}

Section.defaultProps = {
  isTop: false,
  isLoading: false,
  data: {
    sections: [{
      id: 0,
      code: '',
      sequence: 0,
      web_page_id: 0,
      web_page_section_group_id: null,
      content: {
        title: '',
        subtitle: '',
        buttonCTA: null,
        buttonLink: '',
        heroImage: '',
        textPosition: 'left',
        mobileHeroImage: ''
      }
    }]
  },
  theme: null,
  sectionName: null,
  modalActions: undefined,
  type: 'hero',
  currentUser: null
}

Section.propTypes = {
  isTop: PropTypes.bool,
  isLoading: PropTypes.bool,
  data: PropTypes.shape({
    sections: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      code: PropTypes.string,
      sequence: PropTypes.number,
      web_page_id: PropTypes.number,
      web_page_section_group_id: PropTypes.number || null,
      content: PropTypes.shape({
        title: PropTypes.string,
        subtitle: PropTypes.string,
        buttonCTA: PropTypes.string,
        buttonLink: PropTypes.string,
        heroImage: PropTypes.string,
        textPosition: PropTypes.string,
        mobileHeroImage: PropTypes.string
      })
    }))
  }),
  theme: PropTypes.string,
  sectionName: PropTypes.string,
  modalActions: PropTypes.shape({}),
  type: PropTypes.oneOf(['hero', 'fragment']),
  page: PropTypes.oneOf(['homepage', 'howitworks', 'becomeahost']).isRequired,
  currentUser: PropTypes.shape({})
}

export default Section
