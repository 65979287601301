import React from 'react'
import PropTypes from 'prop-types'
import ReactPlaceholder from 'react-placeholder'

function Placeholder({ isLoading, rows, type }) {
  return (
    <ReactPlaceholder
      showLoadingAnimation
      type={type}
      ready={!isLoading}
      rows={rows}
      style={{ opacity: 0 }}
    >
      ...loading
    </ReactPlaceholder>
  )
}

Placeholder.defaultProps = {
  isLoading: true,
  rows: 1,
  type: 'text'
}

Placeholder.propTypes = {
  isLoading: PropTypes.bool,
  rows: PropTypes.number,
  type: PropTypes.string
}

export default Placeholder
