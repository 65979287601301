import React from 'react'
import Slider from 'react-slick'
import './ImageCarousel.css'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { data } from 'flickity'

class Article extends React.Component {
  
  render() {
    var image = this.props.data.guest.avatarUrl,
      fullname = this.props.data.guest.fullname,
      comment = this.props.data.comment
    const { dataLength } = this.props
    return (
      <div>
        <div className="snip1584" style={{ backgroundImage: `url(${image})`,backgroundSize:"cover",height:"180px",width:"180px"}}>
        </div>
      </div>
    )
  }
}

class News extends React.Component {
  render() {
    var data = this.props.data
    var newsTemplate

    //let slides =
    var settings = {
      dots: true,
      infinite: true,
      slidesToShow: data.length > 5 ? 5 : 1,
      slidesToScroll: data.length > 5 ? 5 : 1,
      swipeToSlide: true,
      centerMode: true,
      centerPadding: '0px',
      beforeChange: (current, next) => {
        this.props.handleImageChange(next)
        // this.setState({ comment: this.props.data[next].comment });
      },
      afterChange: current => console.log('after change', current)
    }
    if (data.length > 0) {
      newsTemplate = data.map((item, index) => {
        return (
          <div key={"Article-"+index}>
            <Article data={item} dataLength={data && data.length} />
          </div>
        )
      })
    } else {
      newsTemplate = <p>Please Login or Signup First !!</p>
    }
    return (
      <div className="news" style={{ marginBottom: `60px`}}>
        <Slider {...settings}>{newsTemplate}</Slider>
        {/* <h3>{this.state.title}</h3> */}
      </div>
    )
  }
}

class ImageCarousel extends React.Component {
  constructor() {
    super()
    this.state = {
      comment: '',
      fullname: ''
    }
  }

  componentDidMount() {
    this.setintialCarousel()
    console.log(data)
  }

  setintialCarousel = () => {
    this.setState({
      comment:
        this.props.carouselData && this.props.carouselData.length > 0
          ? this.props.carouselData[0].comment
          : '',
      fullname:
        this.props.carouselData && this.props.carouselData.length > 0
          ? this.props.carouselData[0].guest.fullname
          : ''
    })
  };

  handle(e) {
    this.setState({ comment: this.props.carouselData[e].comment })
    this.setState({ fullname: this.props.carouselData[e].guest.fullname })
  }

  render() {
    const { carouselData } = this.props
    if (!carouselData) return <div>No Data</div>
    return (
      <div className="app community-thinks" style={{ width: `80%`}}>
        { this.state.comment ? 
          <React.Fragment>
            <h3 className="communitylabel">What Our Community Thinks</h3>
            <div className="title-content">
              <div className="center-wrap">
                <div className="quotes">
                  { this.state.comment ? 
                    <p>{this.state.comment}</p>
                    :
                    <p>No data available</p>
                  }
                </div>
              </div>
            </div>
            <h3 className="title author">{this.state.fullname}</h3>
            { this.state.comment && <News
              data={this.props.carouselData}
              handleImageChange={e => this.handle(e)}
            />}
          </React.Fragment>
          :null
        }
      </div>
    
    )
  }
}

export default ImageCarousel
