import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ChefAvtarHost from './ChefAvtarHost'
import ChefSocialLinks from './ChefSocialLinks'

export default class ChefHeroInfo extends Component {
  componentDidMount() {}

  componentWillUnmount() {}

  render() {
    const { person, displaySpecialties, displayLinks } = this.props
    return (
      <React.Fragment>
        <ChefAvtarHost person={person} />
        <div className="chef-or-host__chef">
          <h1 className="h1 mega-title text-center">{person.fullname}</h1>

          {displaySpecialties && (
            <div className="mega-subtitle text-center">
              <ul className="inline inline-list list-inline text-center">
                {person.specialties.map((specialty, i) => (
                  /* eslint-disable-next-line react/no-array-index-key */
                  <li className="text-primary" key={specialty.name}>
                    {specialty.name}
                    {person.specialties.length - 1 !== i && <span>, </span>}
                  </li>
                ))}
              </ul>
            </div>
          )}

          {displayLinks && typeof socialLinks === 'undefined' && <ChefSocialLinks links={person.socialLinks} />}
        </div>
      </React.Fragment>
    )
  }
}

ChefHeroInfo.defaultProps = {
  person: {
    specialties: []
  },
  displayLinks: false,
  displaySpecialties: true
}
ChefHeroInfo.propTypes = {
  displaySpecialties: PropTypes.bool,
  displayLinks: PropTypes.bool,
  person: PropTypes.shape({
    specialties: PropTypes.arrayOf(PropTypes.shape({}))
  })
}
