import React from "react"

const IconPause = props => (
  <svg {...props} 
    aria-hidden="true"
    className="icon icon-pause"
    viewBox="0 0 18 32"
    width="1em"
    height="1em" 
    id="Layer_1" 
    version="1.1" 
  >
    <path d="M10 24h-6v-24h6v24zm10 0h-6v-24h6v24zm-11-23h-4v22h4v-22zm10 0h-4v22h4v-22z"/>
  </svg>
)

export default IconPause