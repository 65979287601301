/* eslint-disable max-classes-per-file */
// Libraries
import React from 'react'
import {
  Elements,
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
  StripeProvider,
  injectStripe
} from 'react-stripe-elements'
import PropTypes from 'prop-types'

// Internal Utils
import alertModal from '../../../utils/alert'

// Components
import SavedPaymentCard from '../../CreditCard/PaymentCard'

// Assets
import CreditCardIconGroup from '../../../icons/creditCards/CreditCardIconGroup'
// import './CardSectionStyles.css'

// Constants
const STRIPE_API_KEY = 'pk_test_SFffLB6N7dioAI2uK7f85SUk00XlFVBdlm'

const handleBlur = () => {
  // console.log('[blur]');
}
const handleChange = (_change) => {
  // console.log('[change]', change);
}
// const handleClick = () => {
//   // console.log('[click]');
// }
const handleFocus = () => {
  // console.log('[focus]');
}
const handleReady = () => {
  // console.log('[ready]');
}

const createOptions = (fontSize, padding) => ({
  base: {
    fontSize,
    color: '#424770',
    letterSpacing: '0.025em',
    fontFamily: 'Source Code Pro, monospace',
    '::placeholder': {
      color: '#aab7c4'
    },
    padding
  },
  invalid: {
    color: '#9e2146'
  }
})
class _PaymentComponent extends React.Component {
  constructor() {
    super()
    this.state = { intent: null }
  }

  handleSubmit = async (ev) => {
    try {
      const { elements, stripe, nextPage, setPaymentMethod } = this.props 
      const paymentMethod = {
        payment_type: 'Visa',
        last4: 1234,
        expiry_month: 12,
        expiry_year: 2022,
        data: 123456789
      }
      const cardElement = elements.getElement('cardNumber') // this.props.stripe.elements().getElement(CardNumberElement)
      await ev.preventDefault()
      await setPaymentMethod(paymentMethod)
      await nextPage()

      // await stripe.confirmCardSetup('{{ REQUEST_FROM_RAILS }}', {
      //   payment_method: {
      //     card: cardElement
      //   }
      // })
      //   .then((payload) => {
      //     if (payload.error) {
      //       alertModal(payload.error.message, 'error', 'Close')
      //     } else {
      //       alertModal(JSON.stringify(payload), 'success', 'success')
      //       this.setState({ intent: payload.paymentIntent })
      //       nextPage()
      //     }
      //   })
    } catch (e) {
      alertModal(e.message, 'error', 'Close')
    }
  }

  render() {
    const { stripe, fontSize } = this.props
    const paymentMethod = {
      payment_type: 'Visa',
      last4: 1234,
      expiry_month: 12,
      expiry_year: 2022,
      data: 123456789
    }

    // const paymentMethod = {
    //   payment_type: payload.token.card.brand,
    //   last4: payload.token.card.last4,
    //   expiry_month: payload.token.card.exp_month,
    //   expiry_year: payload.token.card.exp_year,
    //   data: payload.token
    // }
    const { intent } = this.state
    return (
      <form onSubmit={this.handleSubmit}>
        <span style={{ display: 'flex' }}>
          <label>Card number</label>
          <CardNumberElement onBlur={handleBlur} onChange={handleChange} onFocus={handleFocus} onReady={handleReady} style={createOptions(fontSize)} />
        </span>
        
        <span style={{ display: 'flex' }}>
          <label>Expiration date</label>
          <CardExpiryElement onBlur={handleBlur} onChange={handleChange} onFocus={handleFocus} onReady={handleReady} style={createOptions(fontSize)} />
        </span>

        <span style={{ display: 'flex' }}>
          <label>CVC</label>
          <CardCVCElement onBlur={handleBlur} onChange={handleChange} onFocus={handleFocus} onReady={handleReady} style={createOptions(fontSize)} />
        </span>

        <div className="checkout_button">
          <button disabled={!stripe} type="submit">Continue</button>
        </div>
        {paymentMethod && <SavedPaymentCard paymentMethod={paymentMethod} changeCard={() => null} />}

        <p>
          {intent && <span>Status: {intent.status}</span>}
        </p>
      </form>
    )
  }
}
const PaymentComponent = injectStripe(_PaymentComponent)

class Checkout extends React.Component {
  constructor() {
    super()
    this.state = {
      elementFontSize: window.innerWidth < 450 ? '14px' : '18px'
    }
    const { elementFontSize } = this.state
    window.addEventListener('resize', () => {
      if (window.innerWidth < 450 && elementFontSize !== '14px') {
        this.setState({ elementFontSize: '14px' })
      } else if (window.innerWidth >= 450 && elementFontSize !== '18px') {
        this.setState({ elementFontSize: '18px' })
      }
    })
  }

  render() {
    const { elementFontSize } = this.state
    const { nextPage, setPaymentMethod } = this.props

    return (
      <StripeProvider apiKey={STRIPE_API_KEY}>
        <div className="Checkout">
          <div className="form-header">
            <h4>Add your payment info to complete your request</h4>
            <CreditCardIconGroup />
          </div>
          <Elements>
            <PaymentComponent nextPage={nextPage} setPaymentMethod={setPaymentMethod} fontSize={elementFontSize} />
          </Elements>
        </div>
      </StripeProvider>
    )
  }
}

export default Checkout

_PaymentComponent.defaultProps = {
  fontSize: '14px'
}

_PaymentComponent.propTypes = {
  fontSize: PropTypes.string,
  elements: PropTypes.shape({
    getElement: PropTypes.func.isRequired
  }).isRequired,
  stripe: PropTypes.shape({
    confirmCardSetup: PropTypes.func.isRequired
  }).isRequired
}
