import {
  SET_USER_ONLINE_STATUS,
  NOTIFICATIONS_LOADING,
  SET_NOTIFICATIONS,
  AUTH_SIGNOUT_SUCCESS,
  SET_CABLE_NOTIFICATION
} from '../constants/actionTypes'

const initialState = {
  online: 'off',
  notificationsLoading: false,
  data: []
}

const notification = (state = initialState, action) => {
  // console.log('notification reducer', action.type, action);
  switch (action.type) {
  case SET_USER_ONLINE_STATUS:
    return {
      ...state,
      online: action.payload
    }
  case NOTIFICATIONS_LOADING:
    return {
      ...state,
      online: action.payload
    }
  case SET_NOTIFICATIONS:
    // console.log('IN REDUCER - Supposed to be more', action.payload);
    return {
      ...state,
      data: action.payload
    }
  case AUTH_SIGNOUT_SUCCESS:
    return initialState

  default:
    return state
  }
}

export default notification
