import * as React from "react"

const FaceBook = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="25"
    aria-label="Facebook"
    viewBox="0 0 512 512"
    {...props}
  >
    <rect width={512} height={512} rx="15%" fill="#1877f2" />
    <path
      d="m355.6 330 11.4-74h-71v-48c0-20.2 9.9-40 41.7-40H370v-63s-29.3-5-57.3-5c-58.5 0-96.7 35.4-96.7 99.6V256h-65v74h65v182h80V330h59.6z"
      fill="#fff"
    />
  </svg>
)

export default FaceBook


// This is LINE ICON

// import * as React from "react"

// const Facebook = (props) => (
//   <svg
//     width={25}
//     height={25}
//     viewBox="0 0 24 24"
//     xmlns="http://www.w3.org/2000/svg"
//     {...props}
//   >
//     <path
//       fill="#3B5998"
//       fillRule="evenodd"
//       d="M9.945 22v-8.834H7V9.485h2.945V6.54c0-3.043 1.926-4.54 4.64-4.54 1.3 0 2.418.097 2.744.14v3.18h-1.883c-1.476 0-1.82.703-1.82 1.732v2.433h3.68l-.736 3.68h-2.944L13.685 22"
//     />
//   </svg>
// )

// export default Facebook
