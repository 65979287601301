import React from 'react'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import validate from './validate'
import GetPaymentToken from '../../Form/Fields/GetPaymentToken'

import NewGetPaymentToken from '../../Form/Fields/NewGetPaymentToken'
import ProcessPaymentIntent from '../../Form/Fields/ProcessPaymentIntent'

// const PaymentForm = props => (
//   <React.Fragment>
//     <div className="form-body">
//       <div className="form-header">
//         <h4>Add a payment method to complete your request</h4>
//       </div>
//       <GetPaymentToken {...props} />
//     </div>
//     <div className="form-footer">
//       <button
//         id="pay-now-button"
//         className="btn btn--darker form-footer--right"
//         type="submit"
//         form="payment-form"
//         disabled
//       >
//         Add Payment Method
//       </button>
//     </div>
//   </React.Fragment>
// );

// // Decorate with reduxForm(). It will read the initialValues prop provided by connect()
// const paymentForm = reduxForm({
//   form: 'reservation',
//   destroyOnUnmount: false,
//   forceUnregisterOnUnmount: true // <------ unregister fields on unmount
//   // validate
// })(PaymentForm);

// // You have to connect() to any reducers that you wish to connect to yourself
// const connectedPaymentForm = connect(state => ({
//   // initialValues: state.paymentMethods
//   // pull initial values from account reducer
// }))(paymentForm);

// export default connectedPaymentForm;

const PaymentForm = props => (
  <div>
    <div className="form-body">
      {/* <div className="form-header">
        <h4>Add a payment method to complete your request</h4>
      </div> */}
      <ProcessPaymentIntent
        nextPage={props.nextPage}
        setPaymentMethod={props.setPaymentMethod}
      />
    </div>
    {/* <div className="form-footer">
      <button
        id="pay-now-button"
        className="btn btn--darker form-footer--right"
        type="submit"
        form="payment-form"
        disabled
      >
        Add Payment Method
      </button>
    </div> */}
    {/* <p className="text-center">
      <small>
        The form of payment will be stored and used for the future transactions. The card<br/>
        can be changed or deleted from 'My Wallet' in the user dashboard.
      </small>
    </p> */}
    <div className="form-footer">
      <button
        type="button"
        className="btn btn--darker form-footer--left"
        onClick={async () => {
          props.modalActions.hideReservationModal()
        }}
      >
        Cancel
      </button>
      {/* Cancel */}
    </div>
  </div>
)

export default PaymentForm
