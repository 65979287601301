import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import { Link } from 'react-router'

/* Constants */
import { currentUserPropTypes } from '../../../constants/composed-proptypes'
import seo_meta from '../../../utils/seo-meta.json'

/* Action Creators */
import * as reservationActionCreators from '../../../actions/reservation'
import * as modalActionCreators from '../../../actions/modals'

/* Components */
import PaymentCard from '../../CreditCard/PaymentCard'
import SEO from '../../Shared/Web/SEO'

import validate from './validate'

class PaymentConfirmation extends Component {
  constructor(props) {
    super(props)
    // const { reservationRequest } = this.props

    this.state = {
      couponValid: false,
      paymentProceed:false
    }
    /*
    const regexForCoupon = /\b[a-zA-Z]{13}\b/
    if (reservationRequest && reservationRequest.coupon && reservationRequest.coupon.match(regexForCoupon)) {
      this.setState = {
        couponValid: true
      }
    } else {
      this.setState = {
        couponValid: false
      }
    }
    */
  }

  componentDidMount() {
    const { reservationRequest, paymentMethod, previousPage } = this.props
    // if (!paymentMethod) {
    //   previousPage()
    // }
    // const regexForCoupon = /\b[a-zA-Z]{13}\b/
    // if (reservationRequest.coupon.match(regexForCoupon)) {
    //   return this.setState({
    //     couponValid: true
    //   })
    // }
    // return this.setState({
    //   couponValid: false
    // })
  }
  
  render() {
    const {
      reservationRequest,
      reservationActions,
      modalActions,
      // previousPage,
      paymentMethod
    } = this.props

    const { couponValid } = this.state

    return (
      <React.Fragment>
        <SEO title={seo_meta.confirm_payment.title} description={seo_meta.confirm_payment.description} />
        <div className="form-body">
          <div className="form-header">
            <h4> Confirm Payment </h4>
            <p>
              <b>Experience:</b> {reservationRequest.experience.title}
            </p>
            <h6>
              Total for {reservationRequest.seats} Guests:{' '}
              {/* {reservationRequest.experience.currency.symbol} */}
              {couponValid
                ? `${reservationRequest.experience.currency.value} ${reservationRequest.seats * reservationRequest.experience.price - reservationRequest.seats * reservationRequest.experience.price} (${reservationRequest.experience.currency.value} ${reservationRequest.seats * reservationRequest.experience.price} total - ${reservationRequest.experience.currency.value} ${reservationRequest.seats * reservationRequest.experience.price} discount) Coupon Successfully Applied`
                : `${reservationRequest.experience.currency.value} ${reservationRequest.seats * reservationRequest.experience.price}`
              }
            </h6>
            <p className="text-center">
              <small>
                <i>
                  * an authorization hold is done when the reservation request is
                  sent, but charge will incur only when the Host confirms the
                  reservation request. Host should confirm the reservation within 72
                  hours from receiving the reservation request, or else the request
                  will expire.
                </i>
              </small>
            </p>
          </div>
          {paymentMethod && <PaymentCard paymentMethod={paymentMethod} changeCard={previousPage} />}

          <p className="text-center">
            <small>
              By sending the reservation request, you agree to <br />BreakBread’s
              <a target="_blank" href="/terms-and-conditions">
                Terms & Conditions
              </a>{' '}
              and{' '}
              <a target="_blank" href="/privacy-policy">
                Privacy Policy
              </a>
            </small>
          </p>
        </div>
        <div className="form-footer">
          <button
            type="button"
            className="btn btn--darker form-footer--right"
            onClick={async () => {
              this.setState({paymentProceed:true})
              try{
                await reservationActions.bookExperience() //  Todo add voucher logic here
                this.setState({paymentProceed:false})
              }
              catch(error){
                this.setState({paymentProceed:false})
              }
            
              modalActions.hideReservationModal()
            }}
            disabled={couponValid|| this.state.paymentProceed}
          >
            Confirm and Send Request
          </button>
          <button
            type="button"
            className="btn btn--darker form-footer--right"
            onClick={modalActions.hideReservationModal}
          >
            Go Back
          </button>
          {/* Cancel */}
        </div>
      </React.Fragment>
    )
  }
}

PaymentConfirmation.propTypes = {
  reservationRequest: PropTypes.shape({}).isRequired,
  paymentMethod: PropTypes.shape({}).isRequired,
  modalActions: PropTypes.shape({}).isRequired,
  reservationActions: PropTypes.shape({}).isRequired,
  // previousPage: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  currentUser: state.reduxTokenAuth.currentUser,
  paymentMethod: state.paymentMethods.paymentMethod,
  reservationRequest: state.reservationRequest
})

const mapDispatchToProps = dispatch => ({
  reservationActions: bindActionCreators(reservationActionCreators, dispatch),
  modalActions: bindActionCreators(modalActionCreators, dispatch)
  
})

// You have to connect() to any reducers that you wish to connect to yourself
const connectedPaymentConfirmation = connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentConfirmation)

export default connectedPaymentConfirmation
