import ExperienceImage from '../images/placeholders/featured_experience_image@2x.png'
import ExperienceImage1 from '../images/placeholders/experience_image_1@2x.png'
import ExperienceImage2 from '../images/placeholders/experience_image_2@2x.png'
import ExperienceImage3 from '../images/placeholders/experience_image_3@2x.png'

import ChefAvatar from '../images/placeholders/chef-avatar@2x.png'
import ChefAvatar1 from '../images/placeholders/chef-avatar-1@2x.png'
import ChefAvatar2 from '../images/placeholders/chef-avatar-2@2x.png'

export const experience = {
  image: ExperienceImage,
  title: 'Lorem Cras Nibh Dolor Ornare',
  featured: true,
  location: 'London',
  host: {
    avatarUrl: ChefAvatar,
    fullname: 'Chef Name 0',
    specialties: ['Chef specialties 0'],
    hostReviewCount: 5,
    averageHostRating: 10
  }
}

export const experience1 = {
  image: ExperienceImage1,
  title: 'Lorem Cras Nibh Dolor Ornare',
  featured: true,
  location: 'Dubai',
  host: {
    avatarUrl: ChefAvatar1,
    fullname: 'Chef Name 1',
    specialties: ['Chef specialties 1'],
    hostReviewCount: 5,
    averageHostRating: 10
  }
}

export const experience2 = {
  image: ExperienceImage2,
  title: 'Lorem Cras Nibh Dolor Ornare',
  featured: true,
  location: 'Dubai',
  host: {
    avatarUrl: ChefAvatar1,
    fullname: 'Chef Name 1',
    specialties: ['Chef specialties 1'],
    hostReviewCount: 5,
    averageHostRating: 10
  }
}
export const experience3 = {
  image: ExperienceImage3,
  title: 'Lorem Cras Nibh Dolor Ornare',
  featured: true,
  location: 'Dubai',
  host: {
    avatarUrl: ChefAvatar2,
    fullname: 'Chef Name 2',
    specialties: ['Chef specialties 2'],
    hostReviewCount: 5,
    averageHostRating: 10
  }
}
