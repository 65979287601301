import React, { Component } from 'react'
import { connect } from 'react-redux'
import Link from 'react-router-dom/Link'
import SEO from '../Shared/Web/SEO'
import BlogCard from './BlogCard'
import { getBlogs } from '../../actions/blogs'
// const MobileDetect = require('mobile-detect')

class Blogs extends Component {
  constructor(props) {
    super(props)
    const { filter } = props

    this.state = {
      filter: {
        rating: null,
        cuisineTypes: null,
        perPage: 12,
        currentPage: 1
      },
      total_count: 5,
      isLoading: true
    }
    // this.onSearch();
  }

  /* componentWillMount() {
    this.fetch()
  }
 */

  async componentDidMount() {
    this.setState({isLoading: false})
    this.fetch()
  }

  loadMore = (currentPage) => {
    this.fetch(currentPage)
  }

  async fetch(currentPage) {
    const { getBlogs } = this.props
    this.setState({ isLoading: true })
    try {
      await getBlogs(currentPage)
      this.setState({ isLoading: false })
    } catch (e) {
      this.setState({ isLoading: false })
    }
  }


  render() {
    const { blogs, meta } = this.props
    const { filter, isLoading } = this.state
    const { totalCount, page } = meta

    const gridSize = 'grid__item medium-down--one-half medium--one-half large-up--one-third'
    const fieldGridSize = 'grid__item medium-down--one-third large-up--one-third'
    return (
      <div className="breakbread-container mb_5">
        <div className="section-header text-center">
          <SEO title="Blog" />
          <h1 className="page-header">Blog</h1>
        </div>

        <div>
          {/* <div className="grid grid--uniform"> */}
            {isLoading && typeof filteredHosts === 'undefined' && <div className="spinner spinner--empty" />}
            <div className="grid grid--uniform">
              {
                blogs.map((blog, idx) => (
                  <div key={idx} className={`${gridSize} simple-animated fadeInUpMed`}>
                    {/* <Link to={`blogs/${blog.slug}`}> */}
                      <BlogCard blog={blog} />                         
                    {/* </Link> */}
                  </div>
                ))
              }
            </div>
          {/* </div> */}
                
          {blogs.length < totalCount && (
            <div className="grid grid--uniform flexCenter mb_5">
              <button className="margin_5 breakbread-btn btn" onClick={() => this.loadMore(page + 1)}>
                LOAD MORE
              </button>
            </div>
          )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  blogs: state.blogs.blogs,
  meta: state.blogs.meta
  // cuisineTypes: state.host.cuisineTypes,
})

const mapDispatchToProps = (dispatch) => ({
  // setFilters: (filter) => dispatch(setFilter(filter)),
  // getCuisine: () => dispatch(getCuisine()),
  getBlogs: (currentPage) => dispatch(getBlogs(currentPage))
})

export default connect(mapStateToProps, mapDispatchToProps)(Blogs)
