import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import ExperienceCard from '../../Experiences/ExperienceCard'
import HostReviewLink from '../../Workflows/HostReview/HostReviewLink'

const Card = ({ experience }) => (
  <React.Fragment>
    <div className="reservation-card">
      <ExperienceCard experience={experience} hideAvatar />
      <div className="reservation-card__row">
        {experience.reviewsPending && (
          <div>
            <HostReviewLink className="btn btn--full" experience={experience}>
              Write Reviews
            </HostReviewLink>
            <p className="text-primary"> Reviews pending. </p>
          </div>
        )}

        {!experience.reviewsPending && (
          <p className="text-success"> Reviews completed! </p>
        )}
      </div>
      {/* <div className="reservation-card__row">
        <button
          type="button"
          onClick={() => viewReceipt(reservation)}
          className="btn btn--link"
          disabled={false}
        >
          Action
        </button>
      </div>
      */}
    </div>
  </React.Fragment>
)

export default Card
