import React, { Component } from 'react'
import ScrollToTop from '../../../containers/ScrollToTop'

class Content extends Component {
  componentWillMount() {}

  componentDidMount() {}

  render() {
    const { children } = this.props
    return (
      <React.Fragment>
        <ScrollToTop>
          <div id="content" className="breakbread-content">
            {children}
          </div>
        </ScrollToTop>
      </React.Fragment>
    )
  }
}

export default Content
