import {
  FRAMES_SCRIPT_LOAD_STATUS,
  FRAMES_SCRIPT_INITIALIZED
} from '../constants/actionTypes'

import {
  TokenFormStatus,
  PaymentMethodStatus,
  FramesScriptLoadStatus
} from '../constants/statuses'

// export const FramesScriptLoadStatus = {
//   STARTED: 'started',
//   LOADING: 'loading',
//   ERROR: 'error',
//   DONE: 'done'
// };

export function setframeScriptStatus(status) {
  return {
    type: FRAMES_SCRIPT_LOAD_STATUS,
    payload: status
  }
}

export function frameScriptInitialized(
  status = FramesScriptLoadStatus.INITIALIZED
) {
  return {
    type: FRAMES_SCRIPT_INITIALIZED,
    payload: status
  }
}
