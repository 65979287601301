import { SET_MENU } from '../../constants/actionTypes'

const initialState = {
  experience: {}
}

export default function(state = initialState, action) {
  if (action.type === SET_MENU) {
    return {
      ...state
      // experience: {
      //   menu: action.menu,
      // },
    }
  }
  return state
}
