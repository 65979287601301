import React from 'react'
import { Link } from 'react-router-dom'

const ViewBookings = ({ className, children, item }) =>{
  return (
    <React.Fragment>
      {/*!item.approved && (*/}
      {(
        <Link to={`/hosts/reservations/${item.id}`}>
          <button
            type="button"
            className={`${className}`}
          >
        
            {children}
          </button>
        </Link>
      )}
    </React.Fragment>
  )}
export default (ViewBookings)