import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import moment from 'moment'

/* CSS */
import 'flickity-fullscreen/fullscreen.css'

/* Components */
import Hero from '../Shared/Web/Hero'
import ChefHeroInfo from '../Chef/ChefHeroInfo'
// import Favorite from '../Shared/Web/Favorite';

import Review from '../Review/ReviewItem'
import ExperienceCard from '../Experiences/ExperienceCard'
import Slider from '../Shared/Web/Slider'

/* Action Creators */
import * as hostActionCreators from '../../actions/host'
import * as userActionCreators from '../../actions/user'
import * as experienceActionCreators from '../../actions/experience'
import * as reservationActionCreators from '../../actions/reservation'
import * as modalActionCreators from '../../actions/modals'
import { orderBy } from 'lodash'
import seo_meta from '../../utils/seo-meta.json'
import {
  COMMON_GRID_SIZE as gridSize,
  FEATURED_GRID_SIZE as featuredGridSize
} from '../../constants'
import SEO from '../Shared/Web/SEO'

const MobileDetect = require('mobile-detect')
const placeholderImage = require('../../images/home/home-hero.png')
const titles = {
  upcoming: 'Upcoming Experiences',
  past: 'Past Experiences'
}
const Sidebar = ({ host: { locationPhotos, foodPhotos } }) => {
  /* const photos = locationPhotos ? locationPhotos.concat(foodPhotos) : [] */
  const photos = locationPhotos ? locationPhotos : []
  
  return (
    <React.Fragment>
      {photos.length && (
        <Slider photos={photos} uniquename="foodPhotos" thumbnav />
      )}
    </React.Fragment>
  )
}
const HostHeader = ({ host }) => {
  if (!host) {
    return null
  }
  return (
    <div>
      <ChefHeroInfo displayLinks person={host} />
    </div>
  )
}

class HostShowScreen extends Component {
  constructor(props) {
    super(props)
    this.handleReadMore = this.handleReadMore.bind(this)
    this.state = {
      loading: true,
      experienceData: [],
      experienceType: 'upcoming',
      exactMobile: false
    }
  }

  componentWillMount() {
    this.fetch()
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.handleResize)
    }
  }

  async componentDidMount() {
    if (typeof window !== 'undefined') {
      this.responsiveState()
      window.addEventListener('resize', this.handleResize)
    }
  }

  async fetchExperienceData(type) {
    const { hostActions, match } = this.props
    const selectedHostId = match.params.hostSlug
    await hostActions.getHostExperiences({host_id: selectedHostId, page: 1})
    const futureExperiences = this.props.host.futureExperiences
    const pastExperiences = this.props.host.pastExperiences
    if (type === 'upcoming') {
      await this.setState({
        experienceData: futureExperiences,
        experienceType: 'upcoming'
      })
    }
    if (type === 'past') {
      await this.setState({
        experienceData: pastExperiences,
        experienceType: 'past'
      })
    }
    if (type === '') {
      if (futureExperiences && futureExperiences.length > 0) {
        await this.setState({
          experienceData: futureExperiences,
          experienceType: 'upcoming'
        })
      } else {
        if (pastExperiences && pastExperiences.length > 0) {
          await this.setState({
            experienceData: pastExperiences,
            experienceType: 'past'
          })
        }
      }
    }
  }

  isMobile = () => {
    if (typeof window === 'undefined') {
      return false
    }
    const device = new MobileDetect(window.navigator.userAgent)
    const isMobile = device.mobile() || device.tablet()
    const isPhone =
      device.is('iPhone') ||
      device.is('AndroidOS') ||
      device.is('Windows Phone')
    this.setState({ mobile: true })
    return isMobile || isPhone
  }

  handleResize = () => {
    this.responsiveState()
  }

  responsiveState = () => {
    if (typeof window === 'undefined') {
      return false
    }
    const w = window
    const d = document
    const { documentElement } = d.documentElement
    const body = d.getElementsByTagName('body')[0]
    const width =
      w.innerWidth || documentElement.clientWidth || body.clientWidth
    this.setState({ mobile: width < 993, exactMobile: width < 768  })
    return width < 993
  }

  async fetch() {
    const { hostActions, match } = this.props
    const selectedHostId = match.params.hostSlug
    await hostActions.getHost(selectedHostId)
    await this.fetchExperienceData('')
    this.setState({
      loading: false
    })
  }

  async handleLoadMore() {
    const { hostActions, host} = this.props
    const { id, experiences} = host
    const { filter } = experiences
    const page = filter.page + 1
    await hostActions.getHostExperiences({host_id: id, page})
  }

  handleReadMore = (review) => {
    const readmore = this.state[`readmore-${review.id}`]
    this.setState({[`readmore-${review.id}`]: typeof readmore === 'undefined' || !readmore? true : false })
  }

  render() {
    const { experienceData, experienceType, loading, exactMobile } = this.state
    const { host, modalActions } = this.props
    const { image, name, description, experiences, futureExperiences, pastExperiences, filter } = host
    const loadMoreShow = filter && filter.total ? filter.total > experiences.length : false
    // const guestReviews = []
    const { locationPhotos, foodPhotos, heroImage } = host
    const { latestGuestReviews } = host

    const sortedGuestReviews = orderBy(
      latestGuestReviews,
      'datePosted',
      'desc'
    )
    const mobile = false

    const mainPhoto =
      typeof locationPhotos === 'object' && locationPhotos.length
        ? locationPhotos.slice(0, 1).map(p => (p.big ? p.big : p))
        : placeholderImage
     
    const localHeroImage = heroImage ? heroImage: mainPhoto && mainPhoto[0] ? mainPhoto[0] : null
    // const sortedExperiences = experiences
    /* Return Loader when Loading */
    if (loading) {
      return (
        <div className="breakbread-container host-single">
          <div className="grid" style={{ minHeight: '60vh' }}>
            <div className="spinner" />
          </div>
        </div>
      )
    }

    const title = `Meet Our Hosts - ${host.fullname}`

    const maxCharCountReview = exactMobile ? 172 : 205 

    return (
      <div className="host-single fadeIn">
        <figure>
          <Hero heroImage={localHeroImage} textPosition="left" theme="dark" mobileHeroImage={localHeroImage} treatment="dark-half">
            <HostHeader {...this.props} />
          </Hero>
        </figure>
        <SEO title={title} description={title} image={localHeroImage} />
        <div className="breakbread-container">
          <div className="grid">
            <div className="grid__item medium-up--one-half">
              {/* <Favorite host={host} /> */}
              <section className="experience-single__overview">
                <div className="grid">
                  <div className="grid__item">
                    <div className="experienceOverview panel panel-default">
                      <div className="panel-body">
                        <h2 className="bb-host-page">Host Overview</h2>
                        {typeof host.culinaryJourney !== 'undefined' &&
                          host.culinaryJourney
                            .split('\n') // TODO: DEBUG HERE
                            .map((p, idx) => (
                              <p className="commonviewdesc" key={idx+"index"}>
                                {p}
                              </p>
                            ))}
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              {mobile && <Sidebar {...this.props} />}
            </div>
            {!mobile && (
              <div className="grid__item medium-up--one-half">
                <Sidebar {...this.props} handleSeatAmount={this.handleSeatAmount} seatSelectOptions={this.seatSelectOptions} />
              </div>
            )}
          </div>
          {/* {typeof host.featuredExperiences === 'object' && host.featuredExperiences.length > 0 && (
            <section className="experiences">
              <div className="section-header text-center">
                <h4>Upcoming Experiences</h4>
              </div>
              <section className="index-section">
                <div className="grid grid--uniform">
                  {host.featuredExperiences.slice(0, 1).map((experience) => (
                    <div className={gridSize} key={`ExperienceCard-${experience.id}`}>
                      <ExperienceCard experience={experience} />
                    </div>
                  ))}
                </div>
              </section>
            </section>
          )} */}

          {(experiences && experiences.length > 0) && (
            <section className="experiences" style={{padding: '0 !important'}}>
              <div className="section-header text-center">
                <h4>Experiences</h4>
              </div>
              <div className="account">
                <div className="account-tabs text-center">
                  <ul className="nav nav-tabs flexCenter newtabs">
                    { (futureExperiences && futureExperiences.length > 0) && (
                      <li className={experienceType === 'upcoming' ? 'nav-item active' : 'nav-item'}>
                        <button
                          type="button"
                          className="unbutton nav-link"
                          style={{ padding: '10px 10px' }}
                          href="#"
                          onClick={async (e) => {
                            e.preventDefault()
                            await this.fetchExperienceData('upcoming')
                          }}
                        >
                          {titles.upcoming}
                        </button>
                      </li>
                    )}
                    {(pastExperiences && pastExperiences.length > 0) && (
                      <li className={experienceType === 'past' ? 'nav-item active' : 'nav-item'}>
                        <button
                          type="button"
                          className="unbutton nav-link"
                          style={{ padding: '10px 10px' }}
                          href="#"
                          onClick={async (e) => {
                            e.preventDefault()
                            await this.fetchExperienceData('past')
                          }}
                        >
                          {titles.past}
                        </button>
                      </li>
                    )}
                  </ul>
                </div>

                {(experienceData && experienceData.length > 0) && (
                  <div className="grid grid--uniform">
                    {experienceData.map((experience) => (
                      <div className={gridSize} key={`ExperienceCard-${experience.id}`}>
                        <ExperienceCard experience={experience} />
                      </div>
                    ))}
                  </div>
                )}
              </div>
              {/* {sortedExperiences.length === 1 && !exactMobile ?  (
                <section className="index-section">
                  <div className="grid grid--uniform">
                    <div className={featuredGridSize}>
                      <ExperienceCard experience={sortedExperiences[0]} featured />
                    </div>
                  </div>
                </section>
              ) : (
                <section className="index-section">
                  <div className="grid grid--uniform">
                    {sortedExperiences.map((experience) => (
                      <div className={gridSize} key={`ExperienceCard-${experience.id}`}>
                        <ExperienceCard experience={experience} />
                      </div>
                    ))}
                  </div>
                </section>
              )} */}
              {/* <section className="index-section">
                <div className="grid grid--uniform">
                  {experiences.map((experience, i) => (
                    <div className={gridSize} key={"ExperienceCard-"+i}>
                      <ExperienceCard experience={experience} />
                    </div>
                  ))}
                </div>
              </section> */}
              {/* <div className="grid grid--uniform flexCenter mb_5">
                {loadMoreShow ? (
                  <button
                    className="btn  margin_5"
                    onClick={() => this.handleLoadMore()}
                    type="button"
                  >
                    Load More
                  </button>
                ) : (
                  ''
                )}
              </div> */}
            </section>
          )}

          {sortedGuestReviews && sortedGuestReviews.length > 0 && (
            <section className="reviews">
              <div className="section-header">
                <h2 className="bb-host-page" style={{ textAlign: 'left' }}>Here&apos;s What Our Community Thinks</h2>
                <div className="review-container reviews--list">
                  {sortedGuestReviews.map((item) => (
                    <Review key={item.id} review={item} user={item.guest} addPlaceholder={false} />
                  ))}
                </div>
              </div>
            </section>
          )}
        </div>
      </div>
    )
  }
}

HostShowScreen.propTypes = {
  host: PropTypes.shape({}).isRequired
}

const mapStateToProps = state => ({
  host: state.host.host
})

const mapDispatchToProps = dispatch => ({
  hostActions: bindActionCreators(hostActionCreators, dispatch),
  userActions: bindActionCreators(userActionCreators, dispatch),
  experienceActions: bindActionCreators(experienceActionCreators, dispatch),
  reservationActions: bindActionCreators(reservationActionCreators, dispatch),
  modalActions: bindActionCreators(modalActionCreators, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(HostShowScreen)
