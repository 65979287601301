import * as React from "react"

const Youtube = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="25"
    aria-label="YouTube"
    viewBox="0 0 512 512"
    fill="#ed1d24"
    {...props}
  >
    <rect width={512} height={512} rx="15%" />
    <path
      d="M427 169c-4-15-17-27-32-31-34-9-239-10-278 0-15 4-28 16-32 31-9 38-10 135 0 174 4 15 17 27 32 31 36 10 241 10 278 0 15-4 28-16 32-31 9-36 9-137 0-174"
      fill="#fff"
    />
    <path d="M220 203v106l93-53" />
  </svg>
)

export default Youtube

// This is LINE ICON

// import * as React from "react"

// const Youtube = (props) => (
//   <svg
//     width={25}
//     height={25}
//     viewBox="0 -38 256 256"
//     xmlns="http://www.w3.org/2000/svg"
//     preserveAspectRatio="xMidYMid"
//     {...props}
//   >
//     <path
//       d="M250.346 28.075A32.18 32.18 0 0 0 227.69 5.418C207.824 0 127.87 0 127.87 0S47.912.164 28.046 5.582A32.18 32.18 0 0 0 5.39 28.24c-6.009 35.298-8.34 89.084.165 122.97a32.18 32.18 0 0 0 22.656 22.657c19.866 5.418 99.822 5.418 99.822 5.418s79.955 0 99.82-5.418a32.18 32.18 0 0 0 22.657-22.657c6.338-35.348 8.291-89.1-.164-123.134Z"
//       fill="red"
//     />
//     <path fill="#FFF" d="m102.42 128.06 66.329-38.418-66.328-38.418z" />
//   </svg>
// )

// export default Youtube