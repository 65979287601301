import React from 'react'
import Modal from 'react-bootstrap/lib/Modal'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'

/* Components */
import HostReviewWorkflow from '../Workflows/HostReview/StepForm'

/* Actions */
import * as modalActionCreators from '../../actions/modals'

/* Centralized Proptypes */
import { currentUserPropTypes } from '../../constants/composed-proptypes'

const HostReviewModal = props => {
  const { show, modalActions } = props
  return (
    <Modal
      show={show}
      onHide={() => modalActions.hideHostReviewModal()}
      animation={false}
      bsSize="large"
      className="host-review-modal"
    >
      <Modal.Header closeButton />
      <Modal.Body>
        <HostReviewWorkflow {...props} />
      </Modal.Body>
    </Modal>
  )
}

HostReviewModal.propTypes = {
  currentUser: currentUserPropTypes.isRequired,
  modalActions: PropTypes.shape({}).isRequired,
  show: PropTypes.bool.isRequired
}
const mapStateToProps = state => ({
  currentUser: state.reduxTokenAuth.currentUser
})

const mapDispatchToProps = dispatch => ({
  modalActions: bindActionCreators(modalActionCreators, dispatch)
})
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HostReviewModal)
