// import moment from 'moment';
import { SET_EXPERIENCES,SET_SAMPLE_EXPERINCE } from '../../constants/actionTypes'

const SET_MY_EXPERIENCES = 'SET_MY_EXPERIENCES'

const initialState = {
  upcoming: [],
  past: [],
  sampleExperince:null
}

export default function(state = initialState, action) {
  if (action.type === SET_MY_EXPERIENCES) {
    return {
      ...state,
      upcoming: action.experiences
    }
  }

  if (action.type === SET_SAMPLE_EXPERINCE) {
    return {
      ...state,
      sampleExperince: action.payload.sampleExperince
    }
  }
  return state
}
