import moment from 'moment'
import { SET_FILTER } from '../../constants/actionTypes'

const initialState = {
  address: null,
  cuisineTypes: null,
  startDate: moment(),
  endDate: moment().add(1, 'y')
}

window.moment = moment

export default function(state = initialState, action) {
  if (action.type === SET_FILTER) {
    const { filter } = action
    return {
      ...state,
      ...filter
    }
  }

  return state
}
