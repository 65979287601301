import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import SEO from '../Shared/Web/SEO'

import seoMeta from '../../utils/seo-meta.json'

class PrivacyPolicy extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

    componentDidMount = () => {
    }

    render() {
      const { title, description } = seoMeta.privacy_policy
      return (
        <div className="breakbread-container">
          <SEO title={title} description={description} />

          <div className="page-header ">
            <h1> Privacy Policy </h1>
          </div>
          <div className="page-body grid grid--uniform ">
            <div className="grid__item medium-up--three-twelfths large-up-three-twelfths navbar-collapse collapse displayDesktop">
              <h4> </h4>
              <ul className="list-unstyled customList">
                <li ><Link to="/about-us"> About Us </Link></li>
                <li >
                  <Link to="/terms-and-conditions">Terms &amp; Conditions</Link>
                </li>
                <li ><Link to="/privacy-policy"> Privacy Policy </Link>
                </li>
                <li><Link to="/cookie-policy">Cookie Policy </Link> </li>
              </ul>
            </div>

            <div className="grid__item medium-up--nine-twelfths large-up-nine-twelfths policyPara">
              <div>
                <div>

                  <p><b><span>1. INTRODUCTION</span></b></p>
                  <p>
                    <span>
                      Thank you for using BreakBread!
                      Your trust is important to us and we’re committed to protecting the privacy and
                      security of your personal information.
                     </span>
                  </p>
                  <p >
                    <span>
                      The information that’s shared with us helps us to provide a great experience with
                      the BreakBread Services. This Privacy Policy describes how we collect, use,
                      process, and disclose your personal information, in conjunction with your
                      access to and use of the BreakBread Platform. Unless stated otherwise, this privacy
                      policy describes our privacy practices for all websites, platforms and services
                      that [<span >BreakBread Holdco</span>], a private
                      limited company incorporated under the laws of
                      [<span >BVI / Cayman </span>] with a registered office located at
                      [<span >Address</span>], registered under the company registration number [<span >•</span>]
                      at the [<span >Companies House</span>] operates.
                    </span>
                  </p>
                  <p ><span /></p>
                  <p ><b><span>1.1 Definitions</span></b></p>
                  <p ><span>If you see an undefined term in
                    this Privacy Policy (such as “<b>Invitation</b>” or “<b>Platform</b>”), it has
                    the same definition as in our Terms of Service (“<b>Terms</b>”).
                  </span>
                  </p>
                  <p><b><span>1.2 Data Controller</span></b></p>
                  <p>
                    <span>
                      When this policy mentions “Break Bread,” “we,” “us,” or “our,” it refers to
                      [<span>Break Bread Holdco</span>] that is responsible for your personal information under
                      this Privacy Policy (the “<b>Data Controller</b>”).
                    </span>
                  </p>
                  <p><b><span>1.3 Applicability to Payments</span></b></p>
                  <p>
                    <span>
                      This Privacy Policy also applies to
                      the payment processing services provided to you through or in connection with
                      your use of the Platform (“<b>Payments Services</b>”) by us or one of our third
                      party payment processors. When using the Payment Services, you acknowledge and
                      agree that you will also be providing your information, including personal
                      information, to us or other third parties, who will be the respective Data
                      Controller (the “<b>Payments Data Controller</b>”) of your
                      information related to the Payment Services depending on the nature of your
                      transaction over the Platform.
                    </span>
                  </p>
                  <p >
                  <span>For example, for (a) Diners, all
                    payment information (including credit card or other payment information)
                    provided over the Platform will not be collected or retained by us and the
                    Payments Data Controller for the Payment Services relating to your transaction
                    over the Platform is Stripe (or such other third party payment processing
                    service that we may use from time to time) and you should carefully review the
                    respective privacy policy at
                  </span>
                  <a target="_blank" href="https://stripe.com/en-sg/privacy#personal-data-definition" rel="noreferrer">https://stripe.com/en-sg/privacy#personal-data-definition</a><span >; and (b)
                        Hosts, we will be the Payments Data Controller in respect of your Hosting
                        Experiences over the Platform and the associated receipt of payments and we
                        will collect and store your personal information in accordance with this
                        Privacy Policy.
                                                                                                                                                                                                                    </span>
                  </p>
                  <p><b><span >2. INFORMATION WE COLLECT</span></b></p>
                  <p><span>There are three general categories of information we collect.</span></p>
                  <p><b><span >2.1 Information You Give to Us.</span></b></p>
                  <p><u><span >2.1.1 Information that is necessary for the use of the Platform.</span></u></p>
                  <p>
                    <span>
                      We ask for and collect the
                      following personal information about you when you use the Platform. This
                      information is necessary for the adequate performance of the contract between
                      you and us and to allow us to comply with our legal obligations. Without it, we
                      may not be able to provide you with all the requested services.
                    </span>
                  </p>
                  <ul type="disc">
                    <li>
                      <b><span>Account Information</span></b>
                      <span>.
                        When you sign up to BreakBread, we require certain
                        information such as your first name, last name, email address, mobile
                        phone number and date of birth.
                      </span>
                    </li>
                    <li>
                      <b><span >Profile and Invitation Information</span></b>
                      <span>.
                        To use certain features of the Platform (such as booking or
                        creating an Invitation), you may be asked to provide additional
                        information, including your address, phone number, and a profile picture.
                      </span>
                    </li>
                    <li>
                      <b><span >Identity Verification Information</span></b>
                      <span>.
                        To help maintain a trusted environment, and when required by
                        the applicable laws we may collect identity verification information (such
                        as images of your government issued ID, passport, national identity card,
                        or driving license, as permitted by applicable laws) or other background
                        and authentication information.
                      </span>
                    </li>
                    <li>
                      <b><span >Payment Information</span></b>
                      <span>.
                        To use certain features of the Platform, we may require you
                        to provide certain financial information (like your bank account or credit
                        card information) to the respective Payments Data Controller in order to
                        facilitate the processing of payments.
                      </span>
                    </li>
                    <li>
                      <b><span >Communications on the Platform</span></b>
                      <span>.
                        When you communicate with BreakBread or use the Platform
                        to communicate with other Users, we collect information about your
                        communication and any information you choose to provide.
                      </span>
                    </li>
                  </ul>
                  <p><u><span>2.1.2 Information you choose to give us.</span></u></p>
                  <p>
                    <span>
                      You may choose to provide us with
                      additional personal information in order to obtain a better user experience
                      when using the Platform. This additional information will be processed based on
                      our legitimate interest or when applicable, your consent.
                    </span>
                  </p>
                  <ul type="disc">
                    <li>
                      <b><span >Additional Profile Information</span></b>
                      <span>.
                        You may choose to provide additional information as part of
                        your User Account profile (such as gender, preferred language(s), city,
                        and a personal description). Some of this information as indicated in your
                        User Account settings is part of your public profile page, and may be
                        publicly visible to others.
                      </span>
                    </li>
                    <li><b>Address Book Contact Information</b>.
                      You may choose to import your address book
                      contacts or manually provide your contacts’ information to access certain
                      features of the Platform, like inviting them to use BreakBread.
                    </li>
                    <li>
                      <b><span >Other Information</span></b>
                      <span>.
                        Additionally, you may choose to volunteer information when
                        you fill in a form, update or add information to your User Account,
                        respond to surveys, post to community forums, participate in promotions,
                        communicate with our customer service team or use other features of the
                        Platform.
                      </span>
                    </li>
                  </ul>
                  <p><u><span>2.1.3 Information that is necessary for the use of the Payment Services.</span></u></p>
                  <p>
                    <span>
                      We or the respective Payments Data
                      Controller may need to collect the following information to comply with
                      applicable regulations (such as anti-money laundering regulations) and to
                      provide the Payment Services:
                    </span>
                  </p>
                  <ul type="disc">
                    <li>
                      <b><span>Payment Information</span></b>
                      <span>.
                        The respective Payments Data Controller requires certain
                        financial information (like your bank account or credit card information)
                        in order to process payments and comply with applicable law. If you are a
                        Diner, we will not process or retain any payment information and you will
                        have to provide this information to the respective third party Payments
                        Data Controller directly to access features of the Platform. Please review
                        the respective third party Payments Data Controllers’ privacy policy for
                        further information on how your information is processed.
                      </span>
                    </li>
                    <li>
                      <b><span>Identity Verification and Other Information</span></b>
                      <span>.
                        The Payments Data Controller may require
                        identity verification information (such as images of your government
                        issued ID, passport, national identity card, or driving license, as
                        permitted by applicable laws) or other authentication information, your
                        date of birth, your address, email address, phone number and other information
                        in order to verify your identity, provide the Payment Services to you, and
                        to comply with applicable law
                      </span>
                      <span >.</span>
                    </li>
                  </ul>
                  <p><u><span >2.1.4 Information We Automatically
                    Collect from Your Use of the Platform and Payment Services.
                  </span>
                  </u>
                  </p>
                  <p ><span >When you use the Platform and the
                    Payment Services, we automatically collect personal information about the
                    services you use and how you use them. This information is necessary for the
                    adequate performance of the contract between you and us, to enable us to comply
                    with legal obligations and given our legitimate interest in being able to
                    provide and improve the functionalities of the Platform and Payment Services.
                  </span>
                  </p>
                  <ul type="disc">
                    <li ><b>Geo-location</b>. When
                      you use certain features of the Platform, we may collect information about
                      your approximate or precise location as determined through information such
                      as your IP address or mobile device’s GPS to offer you an improved user
                      experience. Most mobile devices allow you to control or disable
                      the use of location services for applications in the device’s settings
                      menu. BreakBread may also collect this information even when you are not
                      using the app if this connection is enabled through your settings or
                      device permissions.
                    </li>
                    <li ><b>Platform Usage</b>. We
                      collect information about your interactions with the Platform such as the
                      pages or content you view, your searches for Invitations, bookings you
                      have made, and other actions on the Platform.
                    </li>
                    <li ><b><span >Log Data and Device Information</span></b><span >. We automatically collect device information and log data
                      when you access and use the Platform, even if you have not created a User Account
                      or logged in. That information includes, among other things: details about
                      how you’ve used the Platform (including whether you clicked on links to
                      third party applications), IP address, access dates and times, hardware
                      and software information, device information, device event information,
                      unique identifiers, crash data, cookie data, and the pages you’ve viewed
                      or engaged with before or after using the Platform.
                    </span>
                    </li>
                    <li ><b><span >Cookies and Similar Technologies</span></b><span >. We use cookies, web beacons, pixels, browser analysis
                      tools, server logs, and mobile identifiers and other similar technologies
                      when you use our Platform, use our mobile app, or engage with our online
                      ads or email communications to collect information. In many cases the
                      information we collect using cookies and other similar tools is only used
                      in a non-identifiable format without reference to personal information.
                      For example, we may use information we collect to better understand
                      website traffic patterns and to optimize our website experience. In some
                      cases we associate the information we collect using cookies and other
                      technology with your personal information. Our business partners may also
                      use these tracking technologies on the Platform or engage others to track
                      and analyse your behaviour on our behalf to improve our Platform.
                    </span>
                    </li>
                    <li ><b><span >Pixels and SDKs.</span></b><span >Third parties, including Facebook, may use cookies, web
                      beacons, and other storage technologies to collect or receive information
                      from our Platform and elsewhere on the internet and use that information
                      to provide measurement services and target ads.
                                                              </span>
                    </li>
                    <li ><b><span >Do Not Track Function</span></b><span >.</span></li>
                    <li ><b><span >Payment Transaction Information</span></b><span >. BreakBread or the respective third party Payments Data
                      Controller collects information related to your payment transactions
                      through the Platform. This information is necessary to allow the provision
                      of the BreakBread Services.
                    </span>
                    </li>
                  </ul>
                  <p ><u><span >2.1.5 Information We Collect from
                    Third Parties.
                  </span>
                  </u>
                  </p>
                  <p ><span >We may collect information,
                    including personal information, that others provide about you when they use
                    the Platform and the Payment Services, or obtain information from other
                    sources and combine that with information we collect through the Platform and
                    the Payment Services. We do not control, supervise or respond to how third
                    parties providing your information process your personal information, and any
                    information request regarding the disclosure of your personal information to us
                    should be directed to such third parties.
                  </span>
                  </p>
                  <ul type="disc">
                    <li ><b><span >Third Party Services</span></b><span >. If you link, connect, or login to your User Account with a
                      third party service (e.g. Google or Facebook), the third party service may
                      send us information such as your registration, friends list, and profile
                      information from that service. This information varies and is controlled
                      by that third party service or as authorized by you via your privacy
                      settings on that service.
                    </span>
                    </li>
                    <li ><b><span >Your References</span></b><span >. If someone has written a reference or review about you, it may
                      be published on your BreakBread public profile page.
                    </span>
                    </li>
                    <li ><b><span >Background Information</span></b><span >. To the extent permitted by applicable laws, we may obtain
                      reports from public records of criminal convictions, sex offender
                      registrations, police records, background or registered sex offender
                      checks (if applicable). We may use your information, including your full
                      name and date of birth, to obtain such reports.
                    </span>
                    </li>
                    <li ><b><span >Referrals.</span></b><span >If you are invited to BreakBread, the person who invited you
                      may submit personal information about you such as your email address or
                      other contact information.
                    </span>
                    </li>
                    <li ><b><span >Other Sources</span></b><span >. To
                      the extent permitted by applicable law, we may receive additional
                      information about you, such as demographic data or information to help
                      detect fraud and safety issues, from third party service providers and/or
                      partners, and combine it with information we have about you. For example,
                      we may receive background check results (with your consent where required)
                      or fraud warnings from service providers like identity verification
                      services for our fraud prevention and risk assessment efforts. We may
                      receive information about you and your activities on and off the Platform
                      through partnerships, or about your experiences and interactions from our
                      partner ad networks.
                    </span>
                    </li>
                  </ul>
                  <p ><b><span >2.2 </span></b><span ><b>Children’s
                    Data.
                  </b>
                  </span>
                  </p>
                  <p ><span >Our websites and applications are
                    not directed to children, minors or persons under 18 and we do not knowingly
                    collect any personal information directly from such individuals. If you believe
                    that we are processing the personal information pertaining to a child inappropriately,
                    we take this very seriously and urge you to contact us at
                  </span><a href="mailto:support@breakbread.com">support@breakbread.com</a> <span /><span > immediately.</span>
                  </p>
                  <p ><b><span >3. HOW WE
                    USE INFORMATION WE COLLECT
                  </span>
                  </b>
                  </p>
                  <p ><span >We may use, store, and process
                    personal information to (a) provide, understand, improve, and develop the
                    Platform, (b) create and maintain a safer and trusted environment (such as to
                    comply with our legal obligations and ensure compliance with BreakBread
                    Policies) and (c) provide, personalize, measure, and improve our advertising
                    and marketing.
                  </span>
                  </p>
                  <p ><b><span >3.1 </span></b><span ><b>Provide,
                    Improve, and Develop the Platform.
                  </b>We may use your personal
                    information to provide, improve, and develop the Platform such as to:
                  </span>
                  </p>
                  <ul type="disc">
                    <li ><span >enable you to access and use the Platform,</span></li>
                    <li ><span >enable you to communicate with other Users,</span></li>
                    <li ><span >operate, improve, protect, and optimize
                      the Platform and experience, such as by conducting research and performing
                      analytics,
                    </span>
                    </li>
                    <li ><span >provide customer service,</span></li>
                    <li ><span >send you service or support messages,
                      updates, security alerts, and account notifications,
                    </span>
                    </li>
                    <li ><span >if you provide us with your contacts’ information,
                      we may process this information: (i) to facilitate your referral
                      invitations, (ii) send your requests for references
                    </span><span >, (iii) for fraud detection and prevention, and (iv) for any
                           purpose you authorize at the point of collection,
                                                                            </span>
                    </li>
                    <li ><span >to operate, protect, improve, and optimize
                      the Platform and experience, and personalize and customize your experience
                      (such as making Invitation suggestions, ranking search results), and
                      facilitate claims, we may conduct profiling based on your interactions
                      with the Platform, your search and booking history, your profile
                      information and preferences, and other content you submit to the Platform,
                      and
                    </span>
                    </li>
                    <li ><span >enable your use of our enterprise products,
                      where applicable.
                    </span>
                    </li>
                  </ul>
                  <p ><span >We process this personal
                    information for these purposes given our legitimate interest in improving the
                    Platform and our Users’ experience with it, and where it is necessary for the
                    adequate performance of the contract with you.
                  </span>
                  </p>
                  <p ><b><span >3.2 </span></b><span ><b>Create
                    and Maintain a Trusted and Safer Environment.
                  </b>We may use the personal
                    information to create and maintain a trusted and secure environment such as to:
                  </span>
                  </p>
                  <ul type="disc">
                    <li ><span >detect and prevent fraud, abuse, spam,
                      security incidents, and other harmful activity,
                    </span>
                    </li>
                    <li ><span >conduct security investigations and risk
                      assessments on our own or with third parties,
                    </span>
                    </li>
                    <li ><span >verify or authenticate information
                      provided by you (such as to verify your address and Experience Venue or
                      compare your identification photo to another photo provided),
                    </span>
                    </li>
                    <li ><span >conduct checks against databases and other
                      information sources, including background or police checks, to the extent
                      permitted by applicable laws and with your consent where required,
                    </span>
                    </li>
                    <li ><span >comply with our legal obligations,</span></li>
                    <li ><span >Resolve any disputes amongst Users and
                      enforce our agreements with third parties,
                    </span>
                    </li>
                    <li ><span >enforce ourTerms of Serviceand
                      other policies, and
                    </span>
                    </li>
                    <li ><span >in connection with the activities above,
                      we may conduct profiling based on your interactions with the Platform,
                      your profile information and other content you submit to the Platform,
                      and information obtained from third parties. In limited cases, automated
                      processes may restrict or suspend access to the Platform if such processes
                      detect activity that we think poses a safety or other risk to the
                      Platform, our community, or third parties.
                    </span>
                    </li>
                  </ul>
                  <p ><span >We process this personal
                    information for these purposes given our legitimate interest in protecting the
                    Platform, to measure the adequate performance of our contract with you, and to
                    comply with applicable laws.
                  </span>
                  </p>
                  <p ><b><span >3.3 </span></b><span ><b>Provide,
                    Measure, Personalise, and Improve our Advertising and Marketing.
                  </b>We
                    may use the personal information to provide, personalize, measure, and improve
                    our advertising and marketing such as to:
                  </span>
                  </p>
                  <ul type="disc">
                    <li ><span >send you promotional messages, marketing,
                      advertising, and other information that may be relevant to you based on
                      your preferences (including information about BreakBread or partner
                      campaigns and services) and social media advertising through social media
                      platforms such as Facebook or Google),
                    </span>
                    </li>
                    <li ><span >personalize, measure, and improve our
                      advertising,
                    </span>
                    </li>
                    <li ><span >administer referral programs, rewards,
                      surveys, sweepstakes, contests, or other promotional activities or events
                      sponsored or managed by BreakBread or its third party partners,
                    </span>
                    </li>
                    <li ><span >conduct profiling on your characteristics
                      and preferences (based on the information you provide to us, your interactions
                      with the Platform, information obtained from third parties, and your
                      search and booking history) to send you promotional messages, marketing,
                      advertising and other information that we think may be relevant to you,
                    </span>
                    </li>
                    <li ><span >enrolling in an email subscription will
                      not affect the frequency of administrative emails that we may send in
                      connection with any User Account. No fee is charged for sending
                      promotional emails to you, but third-party data rates may apply. Note that
                      you may not be able to take advantage of certain promotions if you do not
                      have an User Account, and
                    </span>
                    </li>
                    <li ><span >invite you to events and relevant
                      opportunities.
                    </span>
                    </li>
                  </ul>
                  <p ><span >We will process your personal
                    information for the purposes described above given our legitimate interest in
                    undertaking marketing activities to offer you products or services that may be relevant
                    to you.
                  </span>
                  </p>
                  <p ><b><span >3.4 How the Payments Data
                    Controller uses the Personal Information Collected
                  </span>
                  </b><span >. We may
                        use the personal information as a part of Payment Services such as to:
                                                                             </span>
                  </p>
                  <ul type="disc">
                    <li ><span >Enable you to access and use the Payment
                      Services and other Platform services.
                    </span>
                    </li>
                    <li ><span >Detect and prevent fraud, abuse, security
                      incidents, and other harmful activity.
                    </span>
                    </li>
                    <li ><span >Conduct security investigations and risk
                      assessments.
                    </span>
                    </li>
                    <li ><span >Conduct checks against databases and other
                      information sources.
                    </span>
                    </li>
                    <li ><span >Comply with legal obligations (such as
                      anti-money laundering regulations).
                    </span>
                    </li>
                    <li ><span >Enforce payment terms and other payment
                      policies.
                         </span>
                    </li>
                    <li ><span >With your consent, send you promotional
                      messages, marketing, advertising, and other information that may be of
                      interest to you based on your preferences.
                    </span>
                    </li>
                  </ul>
                  <p ><span >The Payments Data Controller
                    processes this personal information given its legitimate interest in providing
                    and improving the Payment Services and its users’ experience with it, and where
                    it is necessary for the adequate performance of the contract with you and to
                    comply with applicable laws.
                  </span>
                  </p>
                  <p ><b><span >3.5 </span></b><span ><b>SMS
                    Terms
                  </b>
                  </span>
                  </p>
                  <p ><span >For text messaging services, by providing
                    your mobile number, requesting, joining, agreeing to, enrolling in, signing up
                    for, acknowledging, or otherwise consenting to receive one or more text
                    messages (“<b>Opting In</b>”)
                  </span><span >or using a Break
                        Bread arrangement in which BreakBread sends (or indicates that it may send, or
                        receives a request that it send) one or more text messages (“<b>Text Message
                      Service
                                                                           </b>”), you accept these SMS Terms (“<b>SMS Terms</b>”) and give consent
                        to the handling of your personal information as described in this Privacy
                        Policy, and agree to resolve disputes with us as described in our Terms of
                        Service. Message and data rates may apply.
                                                  </span>
                  </p>
                  <p ><span >BreakBread will use reasonable
                    commercial efforts to deliver the text messages
                  </span><span >to the
                        number you provide through compatible networks. Carriers and BreakBread are
                        not liable for delayed or undelivered messages. The short code we use for some
                        Text Message Services may not be supported on all network carriers.
                                                                     </span>
                  </p>
                  <p ><u><span >Opting In</span></u></p>
                  <p ><span >By Opting In to a Text Message
                    Service:
                      </span>
                  </p>
                  <ul type="disc">
                    <li ><span >You expressly authorize BreakBread to
                      send text messages to the mobile phone number provided. You also authorize
                      BreakBread to include marketing content in any such messages.
                    </span>
                    </li>
                    <li ><span >You confirm that you are the subscriber to
                      the relevant phone number or that you are the customary user of that
                      number on a family or business plan and that you are authorized to Opt In.
                    </span>
                    </li>
                    <li ><span >You consent to the use of an electronic
                      record to document your Opt-In. To withdraw that consent or update our
                      records with your contact information, please contact us at
                    </span><a href="mailto:support@breakbread.com">support@breakbread.com</a>
                    </li>
                  </ul>
                  <p ><span >After Opting In, in addition to the
                    main messages the service offers, you may receive one or more administrative or
                    welcome messages, such as (in some cases) a request to confirm your Opt-In.
                  </span>
                  </p>
                  <p ><u><span >About the Text Message Services and
                    Opting Out
                  </span>
                  </u>
                  </p>
                  <p ><span >Message and data rates may apply.
                    Unless otherwise noted, Text Message Services send multiple, recurring
                    messages. BreakBread may terminate any Text Message Service or your
                    participation in it at any time with or without notice, including, for example,
                    before you have received any or all messages that you otherwise would have
                    received, but these SMS Terms still will apply.
                  </span>
                  </p>
                  <p ><span >For additional help regarding our Text
                    Message Service, contact us at <a href="mailto:support@breakbread.com">support@breakbread.com </a>.
                  </span>
                  </p>
                  <p ><b><span >3.6 </span></b><span ><b>Your
                    Choices
                  </b>
                  </span>
                  </p>
                  <p ><span >You can choose the promotional
                    messages that you wish to receive.
                  </span>
                  </p>
                  <ul type="disc">
                    <li ><span >You can limit the information you provide
                      to BreakBread. Participation in promotions and marketing programs is
                      voluntary.
                    </span>
                    </li>
                    <li ><span >You can limit the communications that Break
                      Bread sends to you.
                    </span>
                    </li>
                    <ul type="circle">
                      <li ><span >To opt-out of marketing emails, simply
                        click the “unsubscribe” link at the bottom of any marketing email we send
                        you or by changing the relevant settings in your account.
                      </span>
                      </li>
                      <li ><span >You can always update your notification
                        settings within your BreakBread Account by changing the relevant account
                        settings.Please note that even if you opt-out of marketing
                        communications, we may still need to contact you with important
                        transactional information about your account.
                      </span><span > For example, even if you opt-out of emails, we may still
                             send you activity confirmations or fraud alerts.
                                                                        </span>
                      </li>
                    </ul>
                  </ul>
                  <p ><b><span >4. SHARING
                    & DISCLOSURE
                  </span>
                  </b>
                  </p>
                  <p ><b><span >4.1 Advertising and Social Media;
                    Sharing With Your Consent
                  </span>
                  </b><span >.</span>
                  </p>
                  <p ><span >Where you have provided consent, we
                    may share your information (including personal information) with partners and
                    third parties, such as when you authorize a third party application or website
                    to access your User Account or when you participate in promotional activities
                    conducted by BreakBread partners or third parties.
                  </span>
                  </p>
                  <p ><span >Where permissible according to
                    applicable law we may use certain limited personal information about you, such
                    as your email address, to hash it and to share it with social media platforms,
                    such as Facebook or Google, to generate leads, drive traffic to our websites or
                    otherwise promote our products and services or the Platform. These processing
                    activities are based on our legitimate interest in undertaking marketing
                    activities to offer you products or services that may be if your interest.
                  </span>
                  </p>
                  <p ><span >The third party social media
                    platforms with which we may share your personal information are not controlled
                    or supervised by BreakBread. Therefore, any questions regarding how your
                    social media platform service provider processes your personal information
                    should be directed to such third party provider.
                  </span>
                  </p>
                  <p ><span >Please note that you may, at any
                    time ask BreakBread to cease processing your data for these direct marketing purposes
                    by sending an e-mail <a href="mailto:support@breakbread.com">support@breakbread.com</a>.
                  </span>
                  </p>
                  <p ><b><span >4.2 </span></b><span ><b>Sharing
                    between Users
                  </b>.
                  </span>
                  </p>
                  <p ><span >To help facilitate bookings or
                    other interactions between Users, we may need to share certain information,
                    including personal information, with other Users, as it is necessary for the
                    adequate performance of the contract between you and us, as follows:
                  </span>
                  </p>
                  <ul type="disc">
                    <li ><span >When you as a Diner submit a booking
                      request, certain information about you is shared with the Host(s),
                      including your profile, full name, the full name of any additional Diners,
                      your cancellation history, and other information you agree to share. When
                      your booking is confirmed, we will disclose additional information to
                      assist with coordinating the Experience, like your phone number.
                    </span>
                    </li>
                    <li ><span >When you as a Host have a confirmed
                      booking, certain information is shared with the Diner (and the additional Diners
                      they may invite, if applicable) to coordinate the booking, such as your
                      profile, full name, phone number, and Experience Venue address.
                    </span>
                    </li>
                    <li ><span >When you as a Diner invite additional Diners
                      to a booking, your full name, travel dates, Host name, Invitation details,
                      the Experience Venue address, and other related information will be shared
                      with each additional Diner.
                    </span>
                    </li>
                  </ul>
                  <p ><b><span >4.3 </span></b><span ><b>Profiles,
                    Invitations, and other Public Information.
                  </b>
                  </span>
                  </p>
                  <p ><span >The Platform lets you publish
                    information, including personal information, that is visible to the general
                    public. For example:
                  </span>
                  </p>
                  <ul type="disc">
                    <li ><span >Parts of your public profile page, such as
                      your first name, your photograph and description, and city, are publicly
                      visible to others.
                    </span>
                    </li>
                    <li ><span >Invitation pages are publicly visible and
                      include information such as the Experience’s approximate location (neighbourhood
                      and city) or precise location (where you have provided your consent), Invitation
                      description, calendar availability, your public profile photo, aggregated
                      demand information (like page views over a period of time), and any
                      additional information you choose to share.
                    </span>
                    </li>
                    <li ><span >After completing a booking, Diners and
                      Hosts may write Reviews and rate each other. Reviews and Ratings are a
                      part of your public profile page and may also be surfaced elsewhere on the
                      Platform (such as the Invitation page).
                    </span>
                    </li>
                    <li ><span >If you submit content in a community or
                      discussion forum, blog or social media post, or use a similar feature on
                      the Platform, that content is publicly visible.
                    </span>
                    </li>
                  </ul>
                  <p ><span >Based on our legitimate interest to
                    promote the Platform we may display parts of the Platform (e.g., your Invitation
                    page) on sites operated by BreakBread’s business partners, using technologies
                    such as widgets or APIs. If your Invitations are displayed on a partner’s site,
                    information from your public profile page may also be displayed.
                  </span>
                  </p>
                  <p ><span >If you change your settings or your
                    public-facing content, these search engines may not update their databases. We
                    do not control the practices of third party search engines, and they may use
                    caches containing your outdated information.
                  </span>
                  </p>
                  <p ><b><span >4.4 </span></b><span ><b>Additional
                    Services by Hosts.
                  </b>
                  </span>
                  </p>
                  <p ><span >Hosts may need to use third party
                    services available through the Platform to assist with managing their Experience
                    or providing additional services requested by you, such as concierge, transport
                    or cleaning services. Hosts may use features on the Platform to share
                    information about the Diner (like check-in and check-out dates, Diner name, Diner
                    phone number) with such third party service providers for the purposes of
                    coordinating the Experience, managing the Experience Venue, or providing other
                    services. Hosts are responsible for third party service providers they use and
                    ensuring those service providers process Diner information securely and in
                    compliance with applicable law including data privacy and data protection laws.
                  </span>
                  </p>
                  <p ><b><span >4.5 </span></b><span ><b>Compliance
                    with Law, Responding to Legal Requests, Preventing Harm and Protection of our
                    Rights.
                  </b>
                  </span>
                  </p>
                  <p ><span >We may disclose your information,
                    including personal information, to courts, law enforcement, governmental
                    authorities, tax authorities, or authorized third parties, if and to the extent
                    we are required or permitted to do so by law or if such disclosure is reasonably
                    necessary to:
                  </span>
                  </p>
                  <p ><span >comply with our legal obligations, </span></p>
                  <p ><span >comply with a valid legal request or to respond to claims asserted
                    against BreakBread,
                  </span>
                  </p>
                  <p ><span >respond to a valid legal request relating to a criminal
                    investigation or alleged or suspected illegal activity or any other activity
                    that may expose us, you, or any other of our users to legal liability,
                  </span>
                  </p>
                  <p ><span >enforce and administer our Terms of Service, the payment terms or
                    other agreements with Users, or
                  </span>
                  </p>
                  <p ><span >protect the rights, property or personal safety of BreakBread,
                    its employees, its Users, or the public. For example, if permitted due to the
                    forgoing circumstances, Host information may be shared with tax authorities or
                    other governmental agencies.
                  </span>
                  </p>
                  <p ><span >These disclosures may be necessary
                    to comply with our legal obligations, for the protection of your or another
                    person's vital interests or for the purposes of our or a third party’s
                    legitimate interest in keeping the Platform secure, preventing harm or crime,
                    enforcing or defending legal rights, facilitating the collection of taxes and
                    prevention of tax fraud or preventing damage.
                  </span>
                  </p>
                  <p ><span >Where appropriate, we may notify Users
                    about legal requests unless: (i) providing notice is prohibited by the relevant
                    legal process, by court order we receive, or by applicable law, or (ii) we
                    believe that providing notice would be futile, ineffective, create a risk of
                    injury or bodily harm to an individual or group, or create or increase a risk
                    of fraud upon BreakBread’s property, its Users and the Platform. In instances
                    where we comply with legal requests without notice for these reasons, we may
                    attempt to notify that User about the request after the fact where appropriate
                    and where we determine in good faith that we are no longer prevented from doing
                    so.
                  </span>
                  </p>
                  <p ><b><span >4.6 Service Providers.</span></b></p>
                  <p ><span >We use a variety of third-party
                    service providers to help us provide services related to the Platform and the
                    Payment Services. Service providers may be located inside or outside of the
                    European Economic Area (“<b>EEA</b>”). In particular, our service providers are
                    based in Europe, Asia Pacific and North and South America.
                  </span>
                  </p>
                  <p ><span >For example, service providers may
                    help us: (i) verify your identity or authenticate your identification
                    documents, (ii) check information against public databases, (iii) conduct
                    background or police checks, fraud prevention, and risk assessment, (iv)
                    perform product development, maintenance and debugging, (v) allow the provision
                    of the BreakBread Services through third party platforms and software tools
                    (e.g. through the integration with our APIs), (vi) provide customer service,
                    advertising, or payments services, or (vii) process, handle or assess insurance
                    claims or other similar claims. These providers have limited access to your
                    personal information to perform these tasks on our behalf, and are
                    contractually bound to protect the personal information and only use the
                    personal information in accordance with our instructions to perform the
                    services associated with the Platform.
                  </span>
                  </p>
                  <p ><span >We will need to share your
                    information, including personal information, in order to ensure the adequate
                    performance of our contract with you.
                  </span>
                  </p>
                  <p ><b><span >4.7 Corporate Affiliates.</span></b></p>
                  <p ><span >To enable or support us in
                    providing the Platform and the Payment Services, we may share your information,
                    including personal information, within our corporate family of companies (both
                    financial and non-financial entities) that are related by common ownership or
                    control.
                      </span>
                  </p>
                  <ul type="disc">
                    <li ><b><span >Sharing with BreakBread affiliates.</span></b><span >Your information may be shared with BreakBread affiliates
                      that provide the technical infrastructure for the Platform, product
                      development and maintenance, customer support, trust and safety and other
                      business operation services to other BreakBread entities. The data
                      sharing is necessary for the performance of the contract between you and
                      us and is based on our legitimate interests in providing the services
                      through the Platform.
                    </span>
                    </li>
                    <li ><b><span >Sharing with third party payments
                      processors.
                    </span>
                    </b><span >In order to facilitate payments on
                           or through the Platform, certain information as described above in “<u>Information
                        that is necessary for the use of the Payment Services” section above
                                                                                    </u>,
                           may be shared with the respective third party payment processor (Stripe or
                           such other third party whom may from time to time provide the relevant
                           services). The data sharing is necessary for the performance of the
                           contract between you and us.
                                        </span>
                    </li>
                  </ul>
                  <p ><span >Additionally, we share your
                    information, including personal information, with our corporate affiliates in
                    order to support and integrate, promote, and to improve the Platform and our
                    affiliates’ services.
                  </span>
                  </p>
                  <p ><b><span >4.8 Collection and Remittance of
                    Taxes.
                  </span>
                  </b>
                  </p>
                  <p ><span >In jurisdictions where BreakBread
                    facilitates the collection and remittance Taxes (as described in the “Taxes”
                    section of the Terms of Service), Hosts and Diners, where legally
                    permissible according to applicable law, expressly grant us permission, without
                    further notice, to disclose Hosts’ and Diners’ data and other information
                    relating to them or to their transactions, bookings and Invitations to the
                    relevant tax authority, including, but not limited to, the Host’s or Diner’s
                    name, Experience Venue addresses, transaction dates and amounts, tax
                    identification number(s), the amount of taxes received (or due) by Hosts from Diners,
                    and contact information.
                  </span>
                  </p>
                  <p ><b><span >4.9 Government Registration.</span></b></p>
                  <p ><span >In jurisdictions where BreakBread
                    facilitates or requires a registration, notification, permit, or license
                    application of a Host with a local governmental authority through the Platform
                    in accordance with local law, we may share information of participating Hosts
                    with the relevant authority, both during the application process and, if
                    applicable, periodically thereafter, such as the Host’s full name and contact
                    details, Experience Venue address, tax identification number, Invitation
                    details, and other relevant information.
                  </span>
                  </p>
                  <p ><b><span >4.10 Information Provided to
                    Enterprise Customers.
                  </span>
                  </b>
                  </p>
                  <p ><span >If you have linked your User Account
                    to the User Account of a company or other organization (an “<b>Enterprise</b>”),
                    added your work email address, or have a booking facilitated via another party
                    (such as the future employer or other entity) or used a coupon in a similar
                    capacity in connection with an Enterprise
                  </span><span >, that
                        Enterprise will have access to your name, contact details, Diner information,
                        permissions and roles, and other information as required to enable use by you
                        and the Enterprise 
                                                              </span>
                  </p>
                  <p ><b><span >4.11 Business Transfers.</span></b></p>
                  <p ><span >If BreakBread undertakes or is
                    involved in any merger, acquisition, reorganization, sale of assets,
                    bankruptcy, or insolvency event, then we may sell, transfer or share some or
                    all of our assets, including your information in connection with such
                    transaction or in contemplation of such transaction (e.g., due diligence). In
                    this event, we will notify you before your personal information is transferred
                    and becomes subject to a different privacy policy.
                  </span>
                  </p>
                  <p ><b><span >4.12 Aggregated Data.</span></b></p>
                  <p ><span >We may also share aggregated
                    information (information about our users that we combine together so that it no
                    longer identifies or references an individual user) and other anonymized
                    information for regulatory compliance, industry and market analysis, research,
                    demographic profiling, marketing and advertising, and other business purposes.
                  </span>
                  </p>
                  <p ><b><span >5. OTHER
                    IMPORTANT INFORMATION
                  </span>
                  </b>
                  </p>
                  <p ><b><span >5.1 Analyzing your Communications.</span></b></p>
                  <p ><span >We may review, scan, or analyze
                    your communications on the Platform for fraud prevention, risk assessment,
                    regulatory compliance, investigation, product development, research, analytics,
                    and customer support purposes. For example, as part of our safety and fraud
                    prevention efforts, we may scan and analyze messages to mask contact
                    information and references to other websites. In some cases, we may also scan,
                    review, or analyze messages to debug, improve, and expand product offerings. We
                    use automated methods where reasonably possible. However, occasionally we may
                    need to manually review some communications, such as for fraud investigations
                    and customer support, or to assess and improve the functionality of these
                    automated tools. We will not review, scan, or analyze your messaging
                    communications to send third party marketing messages to you, and we will not
                    sell reviews or analyses of these communications.
                  </span>
                  </p>
                  <p ><span >These activities are carried out
                    based on BreakBread's legitimate interest in ensuring compliance with
                    applicable laws and our Terms, preventing fraud, promoting safety, and
                    improving and ensuring the adequate performance of our services.
                  </span>
                  </p>
                  <p ><b><span >5.2 Linking Third Party Accounts.</span></b></p>
                  <p ><span >You may link your User Account with
                    your account at a third party social networking service. Your contacts on these
                    third party services are referred to as “Friends.” When you create this link:
                  </span>
                  </p>
                  <ul type="disc">
                    <li ><span >some of the information you provide to us
                      from the linking of your accounts may be published on your User Account
                      profile;
                    </span>
                    </li>
                    <li ><span >your activities on the Platform may be
                      displayed to your Friends on the Platform and/or that third party site;
                    </span>
                    </li>
                    <li ><span >a link to your public profile on that
                      third party social networking service may be included in your BreakBread
                      public profile;
                    </span>
                    </li>
                    <li ><span >other BreakBread users may be able to see
                      any common Friends that you may have with them, or that you are a Friend
                      of their Friend if applicable;
                    </span>
                    </li>
                    <li ><span >other BreakBread users may be able to see
                      any schools, hometowns or other groups you have in common with them as
                      listed on your linked social networking service;
                    </span>
                    </li>
                    <li ><span >the information you provide to us from the
                      linking of your accounts may be stored, processed and transmitted for
                      fraud prevention and risk assessment purposes; and
                    </span>
                    </li>
                    <li ><span >the publication and display of information
                      that you provide to BreakBread through this linkage is subject to your
                      settings and authorizations on the Platform and the third party site.
                    </span>
                    </li>
                  </ul>
                  <p ><span >We only collect your information
                    from linked third party accounts to the extent necessary to ensure the adequate
                    performance of our contract with you, or to ensure that we comply with
                    applicable laws, or with your consent.
                  </span>
                  </p>
                  <p ><b><span >5.3 Third Party Partners &
                    Integrations
                  </span>
                  </b>
                  </p>
                  <p ><span >The Platform may contain links to
                    third party websites or services, such as third party integrations, co-branded
                    services, or third party-branded services (“<b>Third Party Partners</b>”). Break
                    Bread doesn’t own or control these Third Party Partners and when you interact
                    with them, you may be providing information directly to the Third Party
                    Partner, BreakBread, or both. These Third Party Partners will have their own
                    rules about the collection, use, and disclosure of information. We encourage
                    you to review the privacy policies of the other websites you visit.
                  </span>
                  </p>
                  <p ><span >Parts of the Platform may use third
                    party services such as Google Maps/Earth services, including the Google Maps
                    API(s), and Stripe. Use of these respective services is subject to their respective
                    privacy policies such as
                  </span><a target="_blank" href="http://www.google.com/intl/en_us/help/terms_maps.html" rel="noreferrer">Google
                        Maps/Earth Additional Terms of Use
                                             </a><span >, and
                                               the
                                                 </span><span ><a target="_blank" href="http://www.google.com/privacy.html" rel="noreferrer"><span >Google Privacy Policy</span></a></span><span >.</span>
                  </p>
                  <p ><b><span >6. YOUR
                    RIGHTS
                  </span>
                  </b>
                  </p>
                  <p ><span >Consistent with applicable law, you
                    may exercise any of the rights described in this section before your applicable
                    BreakBread Data Controller and Payments Data Controller. You may contact us at
                      </span><span ><a href="mailto:support@breakbread.com"> support@breakbread.com</a></span><span >
                    to submit a data rights request. Please note that we may ask you to verify your
                    identity and request before taking further action on your request and may
                    charge an administrative fee to assist in such requests.
                  </span>
                  </p>
                  <p ><b><span >6.1 Managing Your Information.</span></b></p>
                  <p ><span >You may access and update some of
                    your information through your Account Details. If you have chosen to connect
                    your User Account to a third-party application, like Facebook or Google, you
                    can change your settings and remove permission for the app through the
                    respective third-party application.
                  </span><span >You are
                        responsible for keeping your personal information up-to-date.
                                                           </span>
                  </p>
                  <p ><b><span >6.2 Rectification of Inaccurate or
                    Incomplete Information.
                  </span>
                  </b>
                  </p>
                  <p ><span >You have the right to ask us to
                    correct inaccurate or incomplete personal information about you (and which you
                    cannot update yourself within your User Account).
                  </span>
                  </p>
                  <p ><b><span >6.3 Data Access and Portability.</span></b></p>
                  <p ><span >In some jurisdictions, applicable
                    law may entitle you to request certain copies of your personal information held
                    by us. You may also be entitled to request copies of personal information that
                    you have provided to us in a structured, commonly used, and machine-readable
                    format.
                  </span>
                  </p>
                  <p ><b><span >6.4 Data Retention and Erasure.</span></b></p>
                  <p ><span >We generally retain your personal
                    information for as long as is necessary for the performance of the contract
                    between you and us and to comply with our legal obligations. In certain
                    jurisdictions, you can request to have all your personal information deleted
                    entirely. Please note that if you request the erasure of your personal
                    information:
                  </span>
                  </p>
                  <ul type="disc">
                    <li ><span >We may retain some of your personal
                      information as necessary for our legitimate business interests, such as
                      fraud detection and prevention and enhancing safety. For example, if we
                      suspend a User Account for fraud or safety reasons, we may retain certain
                      information from that User Account to prevent that User from opening a new
                      User Account in the future.
                    </span>
                    </li>
                    <li ><span >We may retain and use your personal
                      information to the extent necessary to comply with our legal obligations.
                      For example, we may keep some of your information for tax, legal reporting
                      and auditing obligations.
                    </span>
                    </li>
                    <li ><span >Information you have shared with others
                      (e.g., Reviews, forum postings) may continue to be publicly visible on the
                      Platform, even after your User Account is cancelled. However, attribution
                      of such information to you will be removed. Additionally, some copies of
                      your information (e.g., log records) may remain in our database, but are
                      disassociated from personal identifiers.
                    </span>
                    </li>
                    <li ><span >Because we maintain the Platform to
                      protect from accidental or malicious loss and destruction, residual copies
                      of your personal information may not be removed from our backup systems
                      for a limited period of time.
                    </span>
                    </li>
                  </ul>
                  <p ><b><span >6.5 Withdrawing Consent and
                    Restriction of Processing.
                  </span>
                  </b>
                  </p>
                  <p ><span >If we are processing your personal
                    information based on your consent you may withdraw your consent at any time by emailing
                    us at <a href="mailto:support@breakbread.com">support@breakbread.com</a> specifying which consent you are withdrawing.
                    Please note that the withdrawal of your consent does not affect the lawfulness
                    of any processing activities based on such consent before its withdrawal.
                    Additionally, in some jurisdictions, applicable law may give you the right to
                    limit the ways in which we use your personal information, in particular where
                    (i) you contest the accuracy of your personal information; (ii) the processing
                    is unlawful and you oppose the erasure of your personal information; (iii) we
                    no longer need your personal information for the purposes of the processing,
                    but you require the information for the establishment, exercise or defence of
                    legal claims; or (iv) you have objected to the processing pursuant to Section
                    6.6 and pending the verification whether the legitimate grounds of BreakBread
                    override your own.
                  </span>
                  </p>
                  <p ><b><span >6.6 Objection to Processing.</span></b></p>
                  <p ><span >In some jurisdictions, applicable
                    law may entitle you to require us not to process your personal information for
                    certain specific purposes (including profiling) where such processing is based
                    on legitimate interest. If you object to such processing, we will no longer
                    process your personal information for these purposes unless we can demonstrate
                    compelling legitimate grounds for such processing or such processing is
                    required for the establishment, exercise or defence of legal claims.
                  </span>
                  </p>
                  <p ><span >Where your personal information is
                    processed for direct marketing purposes, you may, at any time ask BreakBread
                    to cease processing your data for these direct marketing purposes by sending an
                    e-mail to <a href="mailto:support@breakbread.com">support@breakbread.com</a>.
                  </span>
                  </p>
                  <p ><b><span >6.7 Automated decision making and
                    profiling
                         </span>
                  </b>
                  </p>
                  <p ><span >You have the right not to be
                    subject to a decision by us based solely on automated processing, including
                    profiling, which produces legal effects concerning you or similarly
                    significantly affects you unless you have given your explicit consent or unless
                    otherwise permitted under the applicable law.
                  </span>
                  </p>
                  <p ><b><span >6.7 Lodging Complaints.</span></b></p>
                  <p ><span >You have the right to lodge
                    complaints about our data processing activities by filing a complaint with at
                    <a href="mailto:support@breakbread.com"> support@breakbread.com</a>.
                  </span>
                  </p>
                  <p ><b><span >7.
                    OPERATING GLOBALLY & INTERNATIONAL TRANSFERS
                  </span>
                  </b>
                  </p>
                  <p ><span >To facilitate our global operations
                    BreakBread may transfer, store, and process your information within our family
                    of companies, partners, and service providers based in beyond the UK and the EU.
                    Laws in these countries may differ from the laws applicable to your country of
                    residence. For example, information collected within the EEA may be
                    transferred, stored, and processed outside of the EEA for the purposes and on
                    the legal bases described in this Privacy Policy. Where
                    we transfer store, and process your personal information outside of the EEA we
                    have ensured that appropriate safeguards are in place to ensure an adequate
                    level of data protection. You can request further detail about the safeguards
                    that we have in place in respect of transfers of your personal information
                    outside of the EEA and where applicable a copy of the standard data protection
                    clauses that we have in place, by contacting us at <a href="mailto:support@breakbread.com">support@breakbread.com</a>.
                  </span>
                  </p>
                  <p ><b><span >8. SECURITY</span></b></p>
                  <p ><span >We are continuously implementing
                    and updating administrative, technical, and physical security measures to help
                    protect your information against unauthorized access, loss, destruction, or
                    alteration. Some of the safeguards we use to protect your information are firewalls
                    and data encryption, and information access controls. If you know or have
                    reason to believe that your User Account credentials have been lost, stolen,
                    misappropriated, or otherwise compromised or in case of any actual or suspected
                    unauthorized use of your User Account, please contact us immediately following
                    the instructions in the Contact Us section.
                  </span>
                  </p>
                  <p ><b><span >9. CHANGES
                    TO THIS PRIVACY POLICY
                  </span>
                  </b>
                  </p>
                  <p ><span >BreakBread reserves the right to
                    modify this Privacy Policy at any time in accordance with this provision. If we
                    make changes to this Privacy Policy, we will post the revised Privacy Policy on
                    the Platform and update the “Last Updated” date at the top of this Privacy
                    Policy. We will also provide you with notice of the modification by email at
                    least fourteen (14) days before the date they become effective. If you disagree
                    with the revised Privacy Policy, you may cancel your User Account. If you do
                    not cancel your User Account before the date the revised Privacy Policy becomes
                    effective, your continued access to or use of the Platform will be subject to
                    the revised Privacy Policy.
                  </span>
                  </p>
                  <p ><b><span >10. CONTACT
                    US
                  </span>
                  </b>
                  </p>
                  <p ><span >If you have any questions or
                    complaints about this Privacy Policy or BreakBread’s information handling
                    practices, you may email us at the email addresses provided in the relevant
                    sections above or contact us via mail at:
                  </span>
                  </p>

                  <div className="policyFooterText">
                    <p ><b><span >BreakBread Data Controller:
                      <a href="mailto:support@breakbread.com"> support@breakbread.com</a>
                    </span>
                    </b>
                    </p>

                    {/* <p ><b><span  >Payments Data Controller: [<span >please provide contact information for Stripe or
                  other third party payment processor]</span></span></b></p> */}
                  </div>

                </div>
                <div />
                <p style={{ textAlign: 'right' }}><b><span>  Version 1.0 as on April 1 2021</span></b></p>
              </div>
            </div>

          </div>

        </div>
      )
    }
}
export default PrivacyPolicy
