const FETCH_AMENTIY_LIST = "FETCH_AMENTIY_LIST"
const FETCH_WOWELEMENT_LIST = "FETCH_WOWELEMENT_LIST"
const FETCH_CUISINE_TYPE_LIST = "FETCH_CUISINE_TYPE_LIST"
let FETCH_EXPERIENCE_TYPE_LIST = "FETCH_EXPERIENCE_TYPE_LIST"
const FETCH_MENU_ALLERGENS_LIST = "FETCH_MENU_ALLERGENS_LIST"
const FETCH_MEAL_CATEGORY_LIST = "FETCH_MEAL_CATEGORY_LIST"
let FETCH_MENU_COURSE_LIST = "FETCH_MENU_COURSE_LIST"
// const DISABLE_LOGIN = 'DISABLE_LOGIN'

const initialState = {
  amenityList: [],
  wowElementList: [],
  cuisineTypes: [],
  experienceTypes: [],
  menuAllergens: [],
  mealCategories: [],
  menuCourses:[],
  disableLogin: false
}

export default function(state = initialState, action) {
  if (action.type === FETCH_AMENTIY_LIST) {
    return {
      ...state,
      amenityList: action.payload
    }
  }
  else if (action.type === FETCH_WOWELEMENT_LIST) {
    return {
      ...state,
      wowElementList: action.payload
    }
  }
  else if (action.type === FETCH_CUISINE_TYPE_LIST) {
    return {
      ...state,
      cuisineTypes: action.payload
    }
  }
  else if (action.type === FETCH_MENU_ALLERGENS_LIST) {
    return {
      ...state,
      menuAllergens: action.payload
    }
  }
  else if (action.type === FETCH_MEAL_CATEGORY_LIST) {
    return {
      ...state,
      mealCategories: action.payload
    }
  }
  else if (action.type === FETCH_MENU_COURSE_LIST) {
    return {
      ...state,
      menuCourses: action.payload
    }
  }
  else if (action.type === FETCH_EXPERIENCE_TYPE_LIST) {
    return {
      ...state,
      experienceTypes: action.payload
    }
  }
  
  /* else if(action.type === DISABLE_LOGIN){
    return{
      disableLogin: action.payload
    }
  } */

  return state
}
