import React, { Component } from 'react'
import { reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { experienceInitialValues } from '../initialValues'

import Menu from '../../Form/CommonForms/Experience/Menu'

import validate from './validate'
import { currentUserPropTypes } from '../../../constants/composed-proptypes'

const BuildMenu = props => {
  const formTitle = 'Edit the Menu for this Experience'
  return (
    <React.Fragment>
      <Menu {...props} formTitle={formTitle} buttonText="Continue" />
    </React.Fragment>
  )
}

BuildMenu.propTypes = {
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  valid: PropTypes.bool.isRequired,
  previousPage: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  currentUser: currentUserPropTypes.isRequired
}

// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
const reduxFormBuildMenu = reduxForm({
  form: 'experienceEdit',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  validate
})(BuildMenu)

// You have to connect() to any reducers that you wish to connect to yourself
const connectedBuildMenu = connect(state => ({
  formState: state.form,
  initialValues: experienceInitialValues(state.host.experienceEdit)
  // pull initial values from account reducer
}))(reduxFormBuildMenu)

export default connectedBuildMenu
