import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { validate } from './validate'
import { currentUserPropTypes } from '../../../constants/composed-proptypes'
import FileDropField from '../../Form/Fields/renderFileDropField'
import * as fields from '../../Form/Fields/formFields'
import { hostAddressFields } from '../../../constants/formFields'
import seo_meta from '../../../utils/seo-meta.json'
// import { amenities, wow_elements } from '../../../constants'
import _ from 'lodash'
import SEO from '../../Shared/Web/SEO'

function keysMatch(key, obj) {
  const keys = Object.keys(obj).map(x => x.toLowerCase())
  return keys.indexOf(key.toLowerCase()) !== -1
}

/* NOTE: if currentUser form values match,they will be updated via
 * the initialValues of connectedStep5 which connects to Redux.
 * this happens automagically for all fields on the redux connected
 * form hostRegistration
 * @see https://redux-form.com/7.0.2/examples/initializefromstate/
 */

class Step5 extends Component {
  constructor(props) {
    super(props)
  }

  disableFields = fieldArray => {
    const { currentUser } = this.props
    if (typeof currentUser === 'undefined' || !currentUser.isSignedIn) {
      return fieldArray
    }
    const obj = fieldArray
    Object.keys(obj).map(key => {
      obj[key].disabled = !!keysMatch(key, currentUser.attributes)
      return obj[key]
    })
    return obj
  }

  componentDidMount = () => {
    if(this.props.firstHostLocationObj) {
      if (this.props.firstHostLocationObj.streetAddress) {
        this.props.change('hostingLocationStreetAddress', this.props.firstHostLocationObj.streetAddress)
      }
      if (this.props.firstHostLocationObj.neighbourhood) {
        this.props.change('hostAddressFieldsNeighbourhood', this.props.firstHostLocationObj.neighbourhood)
      }
      if (this.props.firstHostLocationObj.postalCode) {
        this.props.change('hostAddressFieldsPostalCode', this.props.firstHostLocationObj.postalCode)
      }
    }
    if (
      this.props.hostAddressFieldsCountryCode == '' ||
      this.props.hostAddressFieldsCountryCode == null
    ) {
      if (
        this.props.currentUser.attributes.countryCode != '' ||
        this.props.currentUser.attributes.countryCode != null
      ) {
        const defaultCountry =  hostAddressFields.countryCode.options.find((data)=> data.value == this.props.currentUser.attributes.countryCode)
        if (!defaultCountry) {
          if (this.props.firstHostLocationObj) {
            if (this.props.firstHostLocationObj.countryCode) {
              const newData = hostAddressFields.countryCode.options.find((data)=> data.value === this.props.firstHostLocationObj.countryCode)
              if (newData) {
                this.props.change('hostAddressFieldsCountryCode', newData)
              }
            }
          }
        } else {
          this.props.change('hostAddressFieldsCountryCode', defaultCountry)
        }

      }else{
        
        this.props.change('hostAddressFieldsCountryCode', {
          name: 'United Arab Emirates',
          value: 'AE',
          label: 'United Arab Emirates',
          countryName: 'United Arab Emirates',
          code: 'AE'
        })
      }
      
    }

    if (
      this.props.hostAddressFieldsCity == '' ||
      this.props.hostAddressFieldsCity == null
    ) {

      if (
        this.props.currentUser.attributes.city != '' ||
        this.props.currentUser.attributes.city != null
      ) {
        let defaultCountry =  hostAddressFields.city.options.find((data)=> data.value == this.props.currentUser.attributes.city)
        if (!defaultCountry) {
          if (this.props.firstHostLocationObj) {
            if (this.props.firstHostLocationObj.city) {
              const newData = hostAddressFields.city.options.find((data)=> data.value == this.props.firstHostLocationObj.city)
              if (newData) {
                this.props.change('hostAddressFieldsCity', newData)
              }
            }
          }
        } else {
          this.props.change('hostAddressFieldsCity', defaultCountry)
        }
        // console.log('defaultCountry', defaultCountry, hostAddressFields.city.options)

      }else{
        this.props.change('hostAddressFieldsCity', {
          name: 'Dubai',
          value: 'Dubai',
          label: 'Dubai'
        })
      }
      
    }
    if (this.props.firstHostLocationObj) {
      if(this.props.firstHostLocationObj.amenities)  {
        this.props.change(
          'amenities',
          this.props.firstHostLocationObj.amenities.map(el=>({value: el.id, label: el.name}))
        )
      }
      if(this.props.firstHostLocationObj.wowElements)  {
        this.props.change(
          'wow_elements',
          this.props.firstHostLocationObj.wowElements.map(el=>({value: el.id, label: el.name}))
        )
      }
    } 
    if(this.props.firstHostLocationObj && this.props.firstHostLocationObj.galleryImages) {
      this.props.change('hostLocationPhotos', this.props.firstHostLocationObj.galleryImages)
    }
  }

  /* componentDidUpdate = (props, nextprops) => {
    
      
  } */

  limitText = limit => value => {
    if(value.length < 0) {
      return value
    } else if(value.length > limit) {
      return value.slice(0, (limit-1))
    } else {
      return value
    }
  }

  render() {
    let {
      handleSubmit,
      pristine,
      valid,
      submitting,
      previousPage,
      /* locationPhotosValues, */
      firstHostLocationObj,
      currentUser,
      initialValues,
      amenitiesOptions,
      wowElementOptions
    } = this.props
    const hostingPhotosPresent = () => {
      if (firstHostLocationObj) {
        if(firstHostLocationObj.galleryImages.length > 0) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    }    
    /* currentUser.attributes.hostingLocationStreetAddress =
      currentUser.attributes.hostingLocation &&
      currentUser.attributes.hostingLocation.streetAddress
    currentUser.attributes.hostAddressFieldsCity =
      currentUser.attributes.hostingLocation &&
      currentUser.attributes.hostingLocation.city
    currentUser.attributes.hostAddressFieldsPostalCode =
      currentUser.attributes.hostingLocation &&
      currentUser.attributes.hostingLocation.postalCode
    currentUser.attributes.hostAddressFieldsNeighbourhood =
      currentUser.attributes.hostingLocation &&
      currentUser.attributes.hostingLocation.neighbourhood
    currentUser.attributes.hostAddressFieldsCountryCode =
      currentUser.attributes.hostingLocation &&
      currentUser.attributes.hostingLocation.countryCode */
    
    const newAmenities =  _.sortBy(amenitiesOptions?amenitiesOptions.filter(el=>el.status === 'active').map((el)=>{return {value:el.id, label:el.name}}):[],"label")
    const newWowElements =  _.sortBy(wowElementOptions?wowElementOptions.filter(el=>el.status === 'active').map((el)=>{return {value:el.id, label:el.name}}):[],"label")

    /* if (
      typeof locationPhotosValues === 'undefined' && this.props.initialValues &&
      typeof this.props.initialValues.locationPhotos !== 'undefined' &&
      this.props.initialValues.locationPhotos.length > 0
    ) {
      locationPhotosValues = []
      locationPhotosValues = [
        {
          preview: this.props.initialValues.locationPhotos[0],
          contentType: 'image/jpeg'
        }
      ]
      // locationPhotosValues[0]['preview'] = this.props.initialValues.locationPhotos[0];
    } */
    let formValues = {hostingLocationStreetAddress: ''}
    /* if (this.props.formData['object Object']) {
      formValues = this.props.formData['object Object'].values
    } */
    if (this.props.formData.hostRegistration && this.props.formData.hostRegistration.values) {
      formValues = this.props.formData.hostRegistration.values
    }
    const {
      title,
      description,
      og_title,
      og_description,
      og_site_name,
      og_locale,
      og_type
    } = seo_meta.professional_details
    
    const otherRequiredFieldCheck = () => {
      return typeof formValues.hostingLocationStreetAddress === 'undefined' || typeof formValues.hostAddressFieldsNeighbourhood === 'undefined' || typeof formValues.hostAddressFieldsCity === 'undefined' || typeof formValues.hostAddressFieldsCountryCode === 'undefined'
    }
    return (
      <React.Fragment>
        <SEO title={title} description={description} />

        <form onSubmit={handleSubmit} className="step5">
          <div className="form-header">
            <h1>Hosting Space</h1>
          </div>
          <div className="form-body form-body--med">
            <div className="grid__item small--one-whole medium-up--full">
              {/* Address Fields */}
              {/* <fields.FieldSet
                fields={hostAddressFields}
                validate={validate}
                parent="hostingLocation"
                wrap
                legend="Address of hosting space"
                className="flat"
              /> */}

              <div className="grid grid--uniform">
                <div className="grid__item  customLabel medium-up ">
                  <Field
                    name="hostingLocationStreetAddress"
                    component={fields.renderField}
                    disabled={submitting}
                    // value={hostAddressFields.address.value}
                    placeholder={hostAddressFields.address.placeholder}
                    isClearable
                    inputType="fullText"
                    normalize={this.limitText(100)}
                    // className="customLabel"
                    label="Address of hosting space *"
                    displayLabel
                  />
                  <span className="limittexthostaddress">
                    <span className="field_limit_text__limit">{ 100 - (formValues.hostingLocationStreetAddress ? formValues.hostingLocationStreetAddress.length : 0) } characters remaining</span>
                  </span>
                </div>
              </div>
              <div className="grid grid--uniform">
                <div className="grid__item  medium-up--one-half large-up--two-third">
                  <Field
                    name="hostAddressFieldsNeighbourhood"
                    component={fields.renderField}
                    disabled={submitting}
                    // value={hostAddressFields.neighbourhood.value}
                    placeholder={hostAddressFields.neighbourhood.placeholder}
                    isClearable
                    inputType="fullText"
                    label="Neighbourhood *"
                    displayLabel
                  />
                </div>
                <div className="grid__item  medium-up--one-half large-up--two-third">
                  <Field
                    name="hostAddressFieldsPostalCode"
                    component={fields.renderField}
                    disabled={submitting}
                    // value={hostAddressFields.postalCode.value}
                    placeholder={hostAddressFields.postalCode.placeholder}
                    isClearable
                    inputType="fullText"
                    label="Postal Code"
                    displayLabel
                  />
                </div>
              </div>
              <div className="grid grid--uniform">
                {/* <div className="grid__item medium-up large-up--two-thirds mb-5">
                  <div className="grid grid--uniform"> */}
                <div className="grid__item  medium-up--one-half large-up--two-third">
                  <Field
                    name="hostAddressFieldsCity"
                    component={fields.renderSelectField}
                    disabled={submitting}
                    // value={hostAddressFields.city.value}
                    options={hostAddressFields.city.options}
                    placeholder={hostAddressFields.city.placeholder}
                    isClearable
                    inputType="fullText"
                    label="City *"
                    // displayLabel={false}
                  />
                </div>

                <div className="grid__item  medium-up--one-half large-up--two-third">
                  <Field
                    name="hostAddressFieldsCountryCode"
                    component={fields.renderSelectField}
                    disabled={submitting}
                    // value={hostAddressFields.countryCode.value}
                    options={hostAddressFields.countryCode.options}
                    placeholder={hostAddressFields.countryCode.placeholder}
                    isClearable
                    type="select"
                    selectType="country"
                    label="Country *"
                    // displayLabel={false}
                  />
                </div>
                {/* </div>
                </div> */}
              </div>
              <div className="grid grid--uniform">
                <div className="grid__item medium-up">
                  <Field
                    name="amenities"
                    component={fields.renderSelectField}
                    // disabled={submitting}
                    options={newAmenities}
                    placeholder="Select Amenities"
                    isClearable
                    isMulti
                    type="select"
                    selectType="amenity"
                    label="Amenities"
                    displayLabel
                  />
                </div>
                <div className="grid__item medium-up">
                  <Field
                    name="wow_elements"
                    component={fields.renderSelectField}
                    // disabled={submitting}
                    options={newWowElements}
                    placeholder="Select wow elements"
                    isClearable
                    isMulti
                    type="select"
                    selectType="wow_elements"
                    label="Wow Elements"
                    displayLabel
                  />
                </div>
              </div>
              {/* <div className="grid grid--uniform">
                <div className="grid__item medium-up large-up--one-third mb-5">
                  <Field
                    name="hostAddressFieldsCity"
                    component={fields.renderSelectField}
                    disabled={submitting}
                    value={hostAddressFields.city.value}
                    options={hostAddressFields.city.options}
                    placeholder={hostAddressFields.city.placeholder}
                    isClearable
                    inputType="fullText"
                    label="City *"
                    // displayLabel={false}
                  />
                </div>
                <div className="grid__item medium-up large-up--one-third ">
                  <Field
                    name="hostAddressFieldsCountryCode"
                    component={fields.renderSelectField}
                    disabled={submitting}
                    value={hostAddressFields.countryCode.value}
                    options={hostAddressFields.countryCode.options}
                    placeholder={hostAddressFields.countryCode.placeholder}
                    isClearable
                    type="select"
                    selectType="country"
                    label="Country *"
                    // displayLabel={false}
                  />
                </div>
                <div className="grid__item customLabel  medium-up large-up--one-third ">
                  <Field
                    name="hostAddressFieldsPostalCode"
                    component={fields.renderField}
                    disabled={submitting}
                    value={hostAddressFields.postalCode.value}
                    placeholder={hostAddressFields.postalCode.placeholder}
                    isClearable
                    inputType="fullText"
                    label="Postal Code"
                    displayLabel
                  />
                </div>                
              </div> */}
            </div>
            {/* Country for Personal Address */}

            <div className="grid__item small--one-whole medium-up--full">
              <label style={{ margin: '5px' }}>Upload Photos</label>
              <p className="m-5">
                Upload photos of your hosting space.
                <br />
                (JPEG or PNG less than 2 MB, 1280×780 pixels. Aspect ratio 16:9)
              </p>
              <Field
                name="hostLocationPhotos"
                label=""
                component={FileDropField}
                type="file"
                // imageFile={locationPhotosValues}
                parentComponent="locationPhotos" 
                locationId={firstHostLocationObj?firstHostLocationObj.id:null}
              />
            </div>
          </div>
          <div className="form-footer">
            <button
              type="button"
              disabled={submitting}
              className="btn btn--link form-footer--left"
              onClick={() => previousPage()}
            >
              Go Back a Step
            </button>
            <button
              type="submit"
              disabled={
                submitting || pristine || otherRequiredFieldCheck() || !hostingPhotosPresent()
              }
              className="btn btn--darker form-footer--right"
            >
              Continue
            </button>
          </div>
        </form>
      </React.Fragment>
    )
  }
}

Step5.propTypes = {
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  valid: PropTypes.bool.isRequired,
  previousPage: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  currentUser: currentUserPropTypes.isRequired
  // locationPhotosValues: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
}

// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
const reduxFormStep5 = reduxForm({
  form: 'hostRegistration',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  // enableReinitialize:true,
  validate
})(Step5)

// You have to connect() to any reducers that you wish to connect to yourself
const connectedStep5 = connect(state => ({
  // initialValues: state.reduxTokenAuth.currentUser.attributes,
  locationPhotosValues:
  state.form.hostRegistration && typeof state.form.hostRegistration.values !== 'undefined'
    ? state.form.hostRegistration.values.locationPhotos
    : null,
  formData: state.form
}))(reduxFormStep5)

export default connectedStep5
