import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Field, reduxForm } from 'redux-form'
import PropTypes from 'prop-types'

import FileDropField from '../../Form/Fields/renderFileDropField'
import renderFileField from '../../Form/Fields/renderFileField'
import * as formActionCreators from '../../../actions/forms'
import * as hostActionCreators from '../../../actions/host'
import * as userActionCreators from '../../../actions/user'

import SEO from '../../Shared/Web/SEO'
import { history } from '../../../configureStore'

class Step2 extends Component {
  componentDidMount() {
    const { currentUser } = this.props
    const { role, hostApplication } = currentUser.attributes

    if (role === 'host' || hostApplication === 'complete') {
      history.push('/become-a-host/')
    }
  }

  handleSubmit() {
    const { hostActions } = this.props
    hostActions.hostApplicationSubmitted()
  }

  render() {
    const {
      pristine,
      valid,
      submitting,
      hostGalleryImages,
      userActions,
      hostForm,
      stepForm,
      pathname,
      change
    } = this.props

    const formValues = hostForm && hostForm.values ? hostForm.values : null

    const foodPhotosPresent = () => {
      if (typeof hostGalleryImages === 'undefined' || !hostGalleryImages || (formValues && !formValues.heroImage)) {
        return false
      }
      return hostGalleryImages.length > 0 && formValues && formValues.heroImage && formValues.heroImage.big && formValues.heroImage.big !== 'defaults/profile_hero_images/big_default.png'
    }

    if (pathname === '/become-a-host/step-2') {
      stepForm.page = 2
    }

    return (
      <React.Fragment>
        <SEO title="Become A Host-step 2" />
        <h1>Become A Host</h1>
        {/* <div className="form-pages">
          Step {stepForm.page} of 2 -
          {[1, 2].map(step => (
            <div
              key={"step-"+step}
              className={`badge ${
                step <= stepForm.page ? 'badge--active' : 'badge--step'
              }`}
            >
              {step}
            </div>
          ))}
        </div> */}
        <form className="">
          <div className="form-header">
            <h4>Upload Cover Image</h4>
            <p>This best showcases your food experience! This could be a table spread or your famous dish. if you had one image to show off your experience, what would it be?</p>
          </div>
          <div className="form-body form-body--full text-center">
            <div className="grid__item small--one-whole medium-up--full">
              <div className="preview" >
                <div
                  className="menuItem_image dropzone"
                  style={{
                    backgroundImage: `url(${formValues && formValues.heroImage && formValues.heroImage.big ? `${formValues.heroImage.big}?${new Date().getTime()}` : ''})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: 'cover', height: '200px', marginBottom: '16px', backgroundColor: '#f3f7f9', border: '1px dashed #b9c9d1'
                  }}
                >
                  <Field
                    name="heroImage"
                    label="Dish Image"
                    id="fileUpload67"
                    type="file"
                    accept=".jpg, .jpeg, .png"
                    component={renderFileField}
                    style={{ opacity: 0, height: 0, margin: 0, padding: 0 }}
                    updateImageDisplay={() => {}}
                    singleImageUpload={async (e) => {
                      const heroImage = await userActions.userHeroImageUpload(e)
                      change('heroImage', heroImage)
                    }}
                  />
                  <button
                    type="button"
                    className="btn"
                    style={{ maxWidth: '240px' }}
                    id="btnUpload23"
                    // disabled={this.state.uploadingStarted}
                    onClick={() => {
                      document.getElementById('fileUpload67').click()
                    }}
                  >
                    Click to Upload
                  </button>
                  {/* <p className="dropzone--instructions">or Drag and Drop</p> */}
                </div>
              </div>
            </div>
          </div>
          <div className="form-header" style={{ marginTop: '-28px' }}>
            <h4>Upload at least one gallery Image</h4>
          </div>
          <div className="form-body form-body--full text-center">
            <div className="grid__item small--one-whole medium-up--full">
              {/* <label style={{ margin: "5px"}}>Profile Photos</label> */}
              <p className="m-5">
                Guests a sneak peak to<br />
                (JPEG or PNG less than 2 MB, 1280×780 pixels. Aspect ratio 16:9)
              </p>
              <Field
                name="hostGalleryImages"
                component={FileDropField}
                type="file"
                parentComponent="profilePhotos"
                imageFile={hostGalleryImages}
              />
            </div>
          </div>
          <div className="form-footer-button mb-3" >
            <button
              type="button"
              disabled={submitting}
              className="btn btn--darker"
              style={{ margin: '10px' }}
              onClick={() => history.push('/become-a-host/step-1')}
            >
              Go Back a Step
            </button>
            <button
              type="button"
              disabled={
                submitting || pristine || !valid || !foodPhotosPresent()
              }
              className="btn btn--darker"
              onClick={() => this.handleSubmit()}
            >
              Submit
            </button>
          </div>
        </form>
      </React.Fragment>
    )
  }
}

Step2.propTypes = {
  currentUser: PropTypes.shape({
    attributes: PropTypes.shape({
      role: PropTypes.string,
      hostApplication: PropTypes.string
    })
  }).isRequired,
  hostActions: PropTypes.shape({
    hostApplicationSubmitted: PropTypes.string
  }).isRequired,
  pristine: PropTypes.bool.isRequired,
  valid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  hostGalleryImages: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  userActions: PropTypes.shape({
    userHeroImageUpload: PropTypes.func
  }).isRequired,
  hostForm: PropTypes.shape({
    values: PropTypes.shape({})
  }).isRequired,
  stepForm: PropTypes.shape({
    page: PropTypes.number
  }).isRequired,
  pathname: PropTypes.string.isRequired,
  change: PropTypes.func.isRequired
}
// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
const Step2Redux = reduxForm({
  form: 'hostRegistration',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true // <------ unregister fields on unmount
})(Step2)

const mapDispatchToProps = dispatch => ({
  formActions: bindActionCreators(formActionCreators, dispatch),
  hostActions: bindActionCreators(hostActionCreators, dispatch),
  userActions: bindActionCreators(userActionCreators, dispatch)
})

const mapStateToProps = state => ({
  // initialValues: state.reduxTokenAuth.currentUser.attributes
  // pull initial values from account reducer
  pathname: state.router.location.pathname,
  currentUser: state.reduxTokenAuth.currentUser,
  stepForm: state.stepForm.hostRegistration,
  hostForm: state.form && state.form.hostRegistration ? state.form.hostRegistration : null,
  hostGalleryImages:
  state.form.hostRegistration && state.form.hostRegistration.values
    ? state.form.hostRegistration.values.hostGalleryImages
    : undefined
})

export default connect(mapStateToProps, mapDispatchToProps)(Step2Redux)
