import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
// import Flickity from 'flickity-fullscreen'
// import 'flickity-fullscreen/fullscreen.css'

/* Components */
// import Favorite from '../Shared/Web/Favorite';
import ChefOrHostCard from '../Chef/ChefOrHostCard'
import Hero from '../Shared/Web/Hero'
import SEO from '../Shared/Web/SEO'
import ThumbLink from '../Shared/Web/ThumbLink'
import Icon from '../Shared/Web/Icon'
import SeatRequest from './SeatRequest'
import Review from '../Review/ReviewItem'
import ExperienceCard from './ExperienceCard'
import Slider from '../Shared/Web/Slider'
import { currencyTypes } from '../../constants/currency'
// import { amenities, wow_elements } from '../../constants'

/* Action Creators */
import * as userActionCreators from '../../actions/user'
import * as experienceActionCreators from '../../actions/experience'
import * as reservationActionCreators from '../../actions/reservation'
import * as modalActionCreators from '../../actions/modals'

import {
  COMMON_GRID_SIZE as gridSize,
  FEATURED_GRID_SIZE as featuredGridSize
} from '../../constants'
import { trackSelectExperienceDate, trackSelectExperienceSeats } from '../../utils/tracking'

const MobileDetect = require('mobile-detect')

class ExperienceScreen extends Component {
  constructor(props) {
    super(props)

    const { reservationActions, experienceActions } = this.props

    this.handleSeatAmount = this.handleSeatAmount.bind(this)
    this.handleSchedule = this.handleSchedule.bind(this)
    this.handleCouponCode = this.handleCouponCode.bind(this)
    this.setPaymentModel = this.setPaymentModel.bind(this)
    this.seatSelectOptions = this.seatSelectOptions.bind(this)
    this.scheduleSelectOptions = this.scheduleSelectOptions.bind(this)
    this.getExperience = experienceActions.getExperience.bind(this)
    this.bookExperience = reservationActions.bookExperience.bind(this)
    this.setReservationRequestInfo = reservationActions.setReservationRequestInfo.bind(this)
    this.handleReadMore = this.handleReadMore.bind(this)

    this.state = {
      mobile: false,
      exactMobile: false,
      paymentModal: false,
      selectedSchedule: null,
      selectedSeats: null,
      dateError: null
    }
    this.isMobile = this.isMobile.bind(this)
  }

  componentDidMount() {
    const { match, setupIntent } = this.props
    this.getExperience(match.params.experienceSlug)
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.handleResize)
    }

    if (typeof window !== 'undefined') {
      this.responsiveState()
      window.addEventListener('resize', this.handleResize)
    }
    const { userActions } = this.props
    userActions.getCountryList()
    userActions.getMenuCourseList()
    if (setupIntent && setupIntent.modalType === null) {
      this.setReservationRequestInfo({
        schedules: 0,
        seats: 0,
        coupon: null,
        couponInfo: null,
        couponError: null,
        couponDetail: null
      })
    }

    setTimeout(() => {
      const { reservationRequest, experience } = this.props
      const reservationRequestExpIdMatchesExpId = reservationRequest && experience && reservationRequest.experience
        && experience.id && reservationRequest.experience.id && reservationRequest.experience.id === experience.id
      if (reservationRequestExpIdMatchesExpId && (reservationRequest.seats !== 0 || reservationRequest.schedules !== 0)) {
        this.setReservationRequestInfo({
          seats: reservationRequest.seats,
          schedules: reservationRequest.schedules
        })
      }
      if (reservationRequestExpIdMatchesExpId && reservationRequest.seats !== 0) {
        this.setState({ selectedSeats: reservationRequest.seats })
      }

      if (reservationRequestExpIdMatchesExpId && reservationRequest.schedules !== 0) {
        this.setState({ selectedSchedule: reservationRequest.schedules })
      }
    }, 1000)

    // const title = this.props.experience ? experience.title : ''
  }

  componentDidUpdate() {
    // if(this.props.experience != null && !this.props.experience.approved){
    const { match, experience, currentUser, history } = this.props
    let experienceId = parseInt(match.params.experienceSlug.split('-')[0])

    if (experience && experience.id !== experienceId) {
      this.getExperience(match.params.experienceSlug)
    }

    if (experience != null && !(experience.status.toLowerCase() === 'approved' || (experience.status.toLowerCase() === 'submitted' && experience.searchable))) {
      if (experience.host.id !== currentUser.attributes.id) {
        history.push('/404')
      }
      //
    }
  }

  handleSeatAmount(event) {
    const { experience } = this.props
    const seats = Number(event.target.value)
    if (event.target.value && seats !== 0) {
      this.setReservationRequestInfo({
        seats,
        experience
      })
      this.setState({ selectedSeats: seats })
      trackSelectExperienceSeats({ seats })
    } else {
      this.setReservationRequestInfo({
        seats: 0,
        experience
      })
      this.setState({ selectedSeats: null })
    }
  }

  handleSchedule(event) {
    const { experience } = this.props

    if (event.target.value && Number(event.target.value) !== 0) {
      const selectedSchedule = experience.schedules.find(el => el.id === Number(event.target.value))
      const blockBefore = selectedSchedule.blockBefore || experience.blockBefore || 4;
      const blockBeforeTime = moment(new Date()).add(blockBefore, 'hours')
      if (blockBeforeTime >= new Date(selectedSchedule.scheduleTime)) {
        this.setState({ dateError: 'Sorry, the booking is closed for this event' })
        // alertModal('Seats are sold out for this date, choose another date!', 'warning', 'Close')
        return false
      }
      this.setState({ dateError: null })
      this.setReservationRequestInfo({
        schedules: Number(event.target.value),
        experience
      })
      this.setState({ selectedSchedule: Number(event.target.value) })
      trackSelectExperienceDate()
    } else {
      this.setReservationRequestInfo({
        schedules: 0,
        experience
      })
      this.setState({ selectedSchedule: null })
    }
  }

  handleResize = () => {
    this.responsiveState()
  }

  handleCouponCode(_event) {
    const { experience } = this.props
    this.setReservationRequestInfo({
      experience
    })
  }

  async onReservation() {
    const { experience } = this.props
    await this.bookExperience()
    this.getExperience(experience.id)
  }

  setPaymentModel(flag) {
    this.setState({ paymentModal: flag })
  }

  responsiveState = () => {
    if (typeof window === 'undefined') {
      return false
    }
    const w = window
    const d = document
    const { documentElement } = d.documentElement
    const body = d.getElementsByTagName('body')[0]
    const width = w.innerWidth || documentElement.clientWidth || body.clientWidth
    this.setState({ mobile: width < 993, exactMobile: width < 768 })
    return width < 993
  }

  isMobile = () => {
    if (typeof window === 'undefined') {
      return false
    }
    const device = new MobileDetect(window.navigator.userAgent)
    const isMobile = device.mobile() || device.tablet()
    const isPhone = device.is('iPhone') || device.is('AndroidOS') || device.is('Windows Phone')
    this.setState({ mobile: true })
    return isMobile || isPhone
  }

  seatSelectOptions() {
    const { experience, reservationRequest } = this.props
    const { schedules } = experience
    const filteredSchedule = schedules.find((el) => el.id === reservationRequest.schedules)
    if (!filteredSchedule) {
      return []
    }

    // const maxSeats = seatsAvailable >= 2 ? 2 : seatsAvailable
    const maxSeats = filteredSchedule.seatsAvailable > 50 ? 50 : filteredSchedule.seatsAvailable
    let optionArray = []
    for (let i = 1; i <= maxSeats; i += 1) {
      if (i === 1) {
        optionArray.push(
          <option key={i} value={i}>
            {i} Seat
          </option>
        )
      } else {
        optionArray.push(
          <option key={i} value={i}>
            {i} Seats
          </option>
        )
      }
    }

    optionArray = [
      <option value={0} key={0}>
        Select your seats
      </option>,
      ...optionArray
    ]
    return optionArray
  }

  scheduleSelectOptions() {
    const { experience } = this.props
    const { schedulesAsc, time } = experience
    const dateFormat = 'ddd Do MMM YYYY'
    let optionArray = schedulesAsc
      .filter((el) => {
        if (moment(`${el.date}T${time}Z`).isBefore(moment(new Date()).utc()) || el.status !== 'active') {
          return false
        }
        return true
      })
      .map((eachSchedule) => {
        const { id, date } = eachSchedule
        return (
          <option key={id} value={id}>
            {moment(date).format(dateFormat)}
          </option>
        )
      })
    optionArray = [
      <option value={0} key={0}>
        Select a Date
      </option>,
      ...optionArray
    ]
    return optionArray
  }

  handleReadMore = (review) => {
    const readmore = this.state[`readmore-${review.id}`]
    this.setState({[`readmore-${review.id}`]: typeof readmore === 'undefined' || !readmore? true : false })
  }

  render() {
    // TODO: We need global redux state for mobile rather than here.
    const { mobile, exactMobile, selectedSchedule, selectedSeats } = this.state

    const { experience, experienceLoading, featuredExperiences, modalActions, menuCoursesOptions } = this.props
    // const { experience, experienceLoading, featuredExperiences, countryOptions } = this.props
    // const countryName = countryOptions && experience && experience.location && experience.location.countryCode
    //   ? countryOptions.find(el => el.alpha2 === experience.location.countryCode).continent : ''
    const localAmenities = experience && experience.location && experience.location.amenities ? experience.location.amenities : []
    /* amenities.map(el=>({name:el.label, id: el.value})) */
    const localWowElements = experience && experience.location && experience.location.wowElements ? experience.location.wowElements : []
    /* wow_elements.map(el=>({name:el.label, id: el.value})) */
    /* Return Loader when Loading */
    if (experienceLoading) {
      return (
        <div className="breakbread-container experience-single">
          <div className="grid" style={{ minHeight: '60vh' }}>
            <div className="spinner" />
          </div>
        </div>
      )
    }

    if (!experience) {
      return (
        <div className="breakbread-container experience-single">
          <div className="grid" style={{ minHeight: '60vh' }}>
            <h1>404 - Experience Not Found</h1>
          </div>
        </div>
      )
    }

    /* Experience Destructuring */
    const { photos, notes, guestReviews, heroImage, schedules, location, hostReviews } = experience

    const { host, menu } = experience

    let showBookAseat = true
    let showBookAseatForSoldOut = true

    setTimeout(() => {
      if (experience && schedules) {
        // this is commented because schedules do not have a paused status
        showBookAseat = true
        // if (schedules.filter(el => el.status !== 'paused').length > 0) {
        //   showBookAseat = true
        // }
        if (schedules.filter((el) => el.seatsAvailable > 0).length > 0) {
          showBookAseatForSoldOut = true
        }
      } else {
        showBookAseat = false
        showBookAseatForSoldOut = false
      }
    }, 1000)

    let locationGalleryImage = []
    if (location && location.galleryImages && location.galleryImages.length > 0) {
      locationGalleryImage = location.galleryImages
    }
    /* console.log('photos====', photos) */
    /* Nice currency */
    const currency = currencyTypes[experience.currency]
    const image = heroImage && heroImage.big ? heroImage.big : photos && photos[0] && photos[0].big ? photos[0].big : ''
    if (typeof currency !== 'undefined') {
      const cost = `${currency.symbol} ${experience.price} ${experience.currency}` // TODO SHOW DIFFERENT PRICE
    } else {
      const cost = `${experience.price} ${experience.currency}`
    }

    const sortedMenuItems = menu.menuItems
      .map((menuItem) => {
        // menuItem.weight = dishTypes
        const matchingDish = menuCoursesOptions?menuCoursesOptions.filter((dish) => dish.name === menuItem.course)[0]:null
        if (matchingDish) {
          menuItem.order = matchingDish.id
        } else {
          menuItem.order = 0
        }
        return menuItem
      })
      .sort((a, b) => a.order - b.order)
    /* Grid Class */
    const gridSizeThumbs = 'grid__item small--one-whole medium-down--one-half medium-up--one-half'
    /* Return the Experience */
    const menuAllergens = menu.menuAllergens.filter((item) => item.name !== 'None')
    const maxCharCountReview = exactMobile ? 172 : 138
    return (
      <div className="experience-single fadeIn">
        <SEO title={`${experience.title}`} description={experience.summary} image={image} />
        <figure>
          <Hero heroImage={image} textPosition="left" theme="dark" mobileHeroImage={image} topHero treatment="dark-half">
            <div className="breakbread-component-text">
              <h1 className="h1 mega-title">{experience.title}</h1>
              <div className="mega-subtitle">
                <p>{experience.summary}</p>
                <ul className="inline inline-list list-inline">
                  <li className="text-primary">
                    {experience.experienceType ? experience.experienceType.name : ''}
                    {`${experience.time ? ` | ${moment(experience.time, 'HH:mm').format('h:mm A')}` : ''}`}
                  </li>
                </ul>
                <ul className="inline inline-list list-inline">
                  <li>
                    {/* {experience.hasBooked && (
                      <React.Fragment>
                        {experience.location.streetAddress}{experience.location.streetAddress ? ',' : ''}
                      </React.Fragment>
                    )} */}
                    {experience.location.neighbourhood ? experience.location.neighbourhood : ''}
                    {experience.location.neighbourhood && experience.location.city ? ',' : ''} {experience.location.city}
                    {/* {experience.location.city && countryName?',':''} {countryName} */}
                  </li>
                </ul>
                <ul className="inline inline-list list-inline">
                  {experience.cuisineTypes.map((specialty, i) => (
                    /* eslint-disable-next-line react/no-array-index-key */
                    <li className="text-primary" key={specialty.name}>
                      {specialty.name}
                      {experience.cuisineTypes.length - 1 !== i && <span>, </span>}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </Hero>
        </figure>

        <div className="breakbread-container">
          <div className="grid">
            <div className="grid__item medium-down--one-half large-up--two-thirds">
              {mobile && (
                <SeatRequest
                  {...this.props}
                  coupon={this.coupon}
                  dateError={this.state.dateError}
                  setPaymentModel={this.setPaymentModel}
                  paymentModal={this.state.paymentModal}
                  handleCouponCode={this.handleCouponCode}
                  handleSeatAmount={this.handleSeatAmount}
                  handleSchedule={this.handleSchedule}
                  seatSelectOptions={this.seatSelectOptions}
                  scheduleSelectOptions={this.scheduleSelectOptions}
                  selectedSchedule={selectedSchedule}
                  showBookAseat={showBookAseat}
                  showBookAseatForSoldOut={showBookAseatForSoldOut}
                  selectedSeats={selectedSeats}
                />
              )}

              {/* <Favorite experience={experience} /> */}
              <section className="experience-single__overview" style={{ paddingBottom: '0px' }}>
                <div className="grid">
                  <div className="grid__item small--one-whole medium-down--one-thid medium--one-third large-up--one-quarter">
                    <ChefOrHostCard person={host} />
                  </div>
                  <div className="grid__item small--one-whole medium-down--two-thids medium--two-thirds large-up--three-quarters">
                    <div className="experienceOverview panel panel-default">
                      <div className="panel-body text-left">
                        <h5>Experience Overview</h5>
                        {typeof experience.description !== 'undefined' && experience.description.split('\n').map((p) => <p key={`${p.slice(0, 2)}`}>{p}</p>)}
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <section className="menu" style={{ paddingBottom: '0px', paddingTop: '10px', padding: '0px' }}>
                {menu.mealCategories.filter((el) => el.status === 'active').length > 0 && (
                  <React.Fragment>
                    <div className="section-header text-left" style={{ padding: '0 0px', marginBottom: '0rem' }}>
                      <h5 className="text-left">The Menu</h5>
                    </div>
                    <section className="allergens" style={{ padding: '0 0px', paddingBottom: '19px', marginLeft: '-3px' }}>
                      <ul className="diet-iconsss list--inline" style={{ textAlign: 'left' }}>
                        {menu.mealCategories
                          .filter((el) => el.status === 'active')
                          .map((element) => (
                            <li className="diet-iconss" key={element.id} style={{ margin: '3px', marginLeft: '0px', paddingLeft: '0px' }}>
                              {element.name === 'BYOW' ? (
                                <span className="badge badge-pill badge-secondary" style={{ padding: '9px ', fontSize: '17px' /* , background: 'gray' */ }}>
                                  <strong> {element.name} </strong> <span style={{ fontSize: '17px', fontWeight: '700' }}>(Bring your own wine)</span>
                                </span>
                              ) : (
                                <span className="badge badge-pill badge-secondary" style={{ padding: '9px ', fontSize: '17px' }}>
                                  {element.name}
                                </span>
                              )}
                            </li>
                          ))}
                      </ul>
                    </section>
                  </React.Fragment>
                )}
                {/* {menu.mealCategories && menu.mealCategories.length > 0 ? (
                  <ul className="experience-single__meal-categories panel panel-default list-inline">
                    {menu.mealCategories.map((item, i) => (
                      <li className="" key={`${item.name}${i}`} style={{ fontFamily: 'comfortaa-web-bold', fontSize: '18px' }}>
                        {item.name === 'BYOW' ? (
                          <i style={{ fontWeight: '900', fontFamily: 'comfortaa-web-bold' }} className="fas">
                            <span className="fas fa-glass-cheers" /> {item.name} {' '}
                            <span style={{ fontSize: '17px', fontWeight: '700' }}>(Bring your own wine)</span>
                          </i>
                        ) : (
                          item.name
                        )}
                      </li>
                    ))}
                  </ul>
                ) : ''} */}

                <div className="grid grid--no-gutters grid--uniform">
                  {sortedMenuItems
                    ? sortedMenuItems.map((menuItem) => (
                      <div key={menuItem.title} className={gridSizeThumbs}>
                        <ThumbLink image={menuItem.menu_image_urls.big} link="/" pre={menuItem.course} title={menuItem.title} desc={menuItem.description} />
                      </div>
                    ))
                    : ''}
                </div>
              </section>

              {menuAllergens.length > 0 && (
                <section className="allergens" style={{ padding: '0 0px' }}>
                  <div className="section-header text-left" style={{ marginBottom: '0rem' }}>
                    <h5 className="text-left" style={{ marginBottom: '0px' }}>
                      Food Allergens This Menu Contains:
                    </h5>
                  </div>
                  <ul className="diet-icons list--inline">
                    {menuAllergens.map((menuAllergen, i) => (
                      /* eslint-disable-next-line react/no-array-index-key */
                      <li className="diet-icon" style={{ paddingLeft: '0px', paddingRight: '0px', marginTop: '-41px', marginRight: '14px' }} key={`${menuAllergen.name}+${i}`}>
                        <Icon iconName={menuAllergen.name} />
                        <span>{menuAllergen.name}</span>
                      </li>
                    ))}
                  </ul>
                </section>
              )}

              {localAmenities.length > 0 && (
                <section className="allergens" style={{ padding: '0 0px' }}>
                  <div className="section-header text-left" style={{ marginBottom: '1rem' }}>
                    <h5 className="text-left" style={{ marginBottom: '-10px', marginTop: '10px' }}>
                      Amenities:
                    </h5>
                  </div>
                  <ul className="diet-iconsss list--inline" style={{ textAlign: 'left' }}>
                    {localAmenities.map((element) => (
                      <li className="diet-iconss" key={element.id} style={{ margin: '3px', marginLeft: '0px', paddingLeft: '0px' }}>
                        <span className="badge badge-pill badge-secondary" style={{ padding: '9px ', fontSize: '17px' }}>
                          {element.name}
                        </span>
                      </li>
                    ))}
                  </ul>
                </section>
              )}
              {localWowElements.length > 0 && (
                <section className="allergens" style={{ padding: '0 0px' }}>
                  <div className="section-header text-left" style={{ marginBottom: '1rem' }}>
                    <h5 className="text-left" style={{ marginBottom: '-10px', marginTop: '10px' }}>
                      Wow Elements:
                    </h5>
                  </div>
                  <ul className="diet-iconsss list--inline" style={{ textAlign: 'left' }}>
                    {localWowElements.map((element) => (
                      <li className="diet-iconss" key={element.id} style={{ margin: '3px', marginLeft: '0px', paddingLeft: '0px' }}>
                        <span className="badge badge-pill badge-secondary" style={{ padding: '9px', fontSize: '17px' }}>
                          {element.name}
                        </span>
                      </li>
                    ))}
                  </ul>
                </section>
              )}

              {photos.length > 0 && (
                <section className="photography" style={{ padding: '0px', marginTop: '20px' }}>
                  <div className="section-header text-left" style={{ marginBottom: '1rem' }}>
                    {/*  <h5 style={{ marginBottom: '-10px', marginTop: '10px' }}>Here&apos;s What You&apos;ll Experience</h5> */}
                    {photos.length && <Slider fromExperience={true} photos={[...photos, ...locationGalleryImage]} uniquename="photos" />}
                  </div>
                </section>
              )}

              {notes.length > 0 && (
                <section className="notes" style={{ padding: '0 0px' }}>
                  <div className="section-header text-left" style={{ marginBottom: '1rem' }}>
                    <h5 className="text-left">Notes</h5>
                  </div>

                  {typeof experience.notes !== 'undefined'
                    && experience.notes.split('\n').map((p, idx) => (
                      <p className="commonviewdesc" key={idx}>
                        {p}
                      </p>
                    ))}
                </section>
              )}

              {guestReviews.length > 0 && (
                <section className="reviews">
                  <div className="section-header">
                    <h2 className="bb-host-page" style={{ textAlign: 'left' }}>
                      Here&apos;s What Our Community Thinks
                    </h2>
                    <div className="review-container reviews--list">
                      {guestReviews.map((item) => (
                        <Review maxCharCountReview={maxCharCountReview} parentPage={"experience"} key={item.id} readMoreFlag={this.state[`readmore-${item.id}`]} handleReadMore={this.handleReadMore} review={item} user={item.guest} actions={modalActions} />
                      ))}
                    </div>
                  </div>
                </section>
              )}
            </div>
            {!mobile && (
              <div className="grid__item medium-down--one-half large-up--one-third">
                <SeatRequest
                  {...this.props}
                  coupon={this.coupon}
                  dateError={this.state.dateError}
                  handleCouponCode={this.handleCouponCode}
                  handleSeatAmount={this.handleSeatAmount}
                  handleSchedule={this.handleSchedule}
                  seatSelectOptions={this.seatSelectOptions}
                  scheduleSelectOptions={this.scheduleSelectOptions}
                  setPaymentModel={this.setPaymentModel}
                  paymentModal={this.state.paymentModal}
                  selectedSchedule={selectedSchedule}
                  selectedSeats={selectedSeats}
                  showBookAseatForSoldOut={showBookAseatForSoldOut}
                  showBookAseat={showBookAseat}
                />
              </div>
            )}
          </div>
        </div>

        {featuredExperiences && featuredExperiences.length > 0 && (
          <section className="recommended recommended-show-experience">
            <div className="section-header text-left">
              <h5 className="text-left">Other Recommended Experiences</h5>
            </div>
            <section className="index-section show-experience">
              <div className="grid grid--uniform">
                {
                  featuredExperiences.length === 1 && !exactMobile
                  ? (
                    <div className={featuredGridSize}>
                      <ExperienceCard experience={featuredExperiences[0]} featured/>
                    </div>
                    /* )) */
                  ) : (
                    featuredExperiences.slice(0, 3).map((e, i) => (
                      <div className={gridSize} key={e.id}>
                        <ExperienceCard experience={e} />
                      </div>
                    ))
                  )}
              </div>
            </section>
          </section>
        )}
      </div>
    )
  }
}

ExperienceScreen.defaultProps = {
  experience: null,
  userAction: undefined
}

ExperienceScreen.propTypes = {
  // experience: PropTypes.shape({}),
  experienceLoading: PropTypes.bool.isRequired,
  featuredExperiences: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  experience: PropTypes.shape({
    location: PropTypes.shape({}),
    status: PropTypes.string,
    searchable: PropTypes.bool,
    title: PropTypes.string,
    summary: PropTypes.string,
    notes: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.shape({}))]),
    description: PropTypes.string,
    currency: PropTypes.string,
    instant: PropTypes.bool
  }),
  currentUser: PropTypes.shape({}).isRequired,
  reservationActions: PropTypes.shape({
    bookExperience: PropTypes.func,
    setReservationRequestInfo: PropTypes.func
  }).isRequired,
  experienceActions: PropTypes.shape({
    getExperience: PropTypes.func
  }).isRequired,
  userAction: PropTypes.shape({
    getCountryList: PropTypes.func
  }),
  reservationRequest: PropTypes.shape({}).isRequired
}

const mapStateToProps = (state) => ({
  experience: state.experience.experienceSingle.data,
  featuredExperiences: state.featured.featuredExperiences,
  experienceLoading: state.experience.experienceSingle.loading,
  currentUser: state.reduxTokenAuth.currentUser,
  reservationRequest: state.reservationRequest,
  setupIntent: state.modal,
  countryOptions: state.user.countryCode.countryList ? state.user.countryCode.countryList.list : null,
  menuCoursesOptions: state.user.amenitiesAndWowElements.menuCourses
    ? state.user.amenitiesAndWowElements.menuCourses.list
    : null
})

const mapDispatchToProps = (dispatch) => ({
  userActions: bindActionCreators(userActionCreators, dispatch),
  experienceActions: bindActionCreators(experienceActionCreators, dispatch),
  reservationActions: bindActionCreators(reservationActionCreators, dispatch),
  modalActions: bindActionCreators(modalActionCreators, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(ExperienceScreen)
