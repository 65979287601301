import { DirectUpload } from 'activestorage/src/direct_upload'
import getHeadersFromStorage from './apiHeaders'
import { HOST, authHeaderKeys } from '../constants'

const RASURL = `${HOST}/rails/active_storage/direct_uploads`

/**
 * Promisify the create method provided by DirectUpload.
 * @param  {object} upload DirectUpload instance
 * @return {promise}       returns a promise to be used on async/await
 */
function createUpload(upload) {
  return new Promise((resolve, reject) => {
    upload.create((err, blob) => {
      if (err) reject(err)
      else resolve(blob)
    })
  })
}

class ActiveStorageUpload {
  constructor(file, callback) {
    this.upload = new DirectUpload(file, RASURL, this)
    this.directUploadProgress = callback
    this.setHeaders()
  }

  async setHeaders() {
    this.headers = await getHeadersFromStorage()
  }

  create(file) {
    try {
      // Add an appropriately-named hidden input to the form with a value of
      // blob.signed_id
      return createUpload(this.upload)
    } catch (error) {
      // Handle the error
      throw error
    }
  }

  directUploadWillCreateBlobWithXHR(xhr) {
    authHeaderKeys.forEach(key => {
      xhr.setRequestHeader(key, this.headers[key])
    })
  }

  directUploadWillStoreFileWithXHR(request) {
    request.upload.addEventListener('progress', event =>
      this.directUploadDidProgress(event)
    )
  }

  directUploadDidProgress(event) {
    // console.log(this, event);
    // Use event.loaded and event.total to update the progress bar
  }
}

/**
 * Upload to service using ActiveStorage DirectUpload module
 * @param  {Object} file image buffer to be uploaded
 * @return {Object}      blob object from server.
 * @see https://github.com/rails/rails/issues/32208
 */
async function activeStorageUpload(file) {
  let imageBlob
  const headers = await getHeadersFromStorage()

  // const directUploadDidProgress = event => {
  //   //console.log('DIRECT UPLOAD EVENT ', event);
  //   // Use event.loaded and event.total to update the progress bar
  // };

  const methods = {
    directUploadWillCreateBlobWithXHR: xhr => {
      authHeaderKeys.forEach(key => {
        xhr.setRequestHeader(key, headers[key])
      })
    },
    directUploadWillStoreFileWithXHR: request => {
      // request.upload.addEventListener('progress', event => callback(event));
    }
  }

  const upload = new DirectUpload(file, RASURL, methods)

  try {
    imageBlob = await createUpload(upload)
  } catch (err) {
    throw err
  }

  return imageBlob
}

export { ActiveStorageUpload, activeStorageUpload }
