import { SHOW_MODAL, HIDE_MODAL, AUTH_SIGNOUT_SUCCESS, HIDE_SCHEDULE_MODAL, SHOW_SCHEDULE_MODAL, SHOW_REVIEW_COMMENT_MODAL, HIDE_REVIEW_COMMENT_MODAL } from '../constants/actionTypes'

const initialState = {
  modalType: null,
  modalProps: {},
  modalScheduleType: null,
  modalScheduleProps: {}
}

const modal = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_MODAL:
      return {
        modalType: action.modalType,
        modalProps: action.modalProps
      }

    case HIDE_MODAL:
      return initialState

    case SHOW_SCHEDULE_MODAL:
      return {
        ...state,
        modalScheduleType: action.modalType,
        modalScheduleProps: action.modalProps
      }

    case HIDE_SCHEDULE_MODAL:
      return {
        ...state,
        modalScheduleType: null,
        modalScheduleProps: {}
      }
    case AUTH_SIGNOUT_SUCCESS:
      return initialState

    default:
      return state
  }
}

export default modal
