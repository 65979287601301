import React from "react"

const Lupins = props => (
  <svg viewBox="0 0 88 88" width="1em" height="1em" {...props}>
    <g fill="currentColor" data-name="Layer 2">
      <path d="M31.83 40.11A3.8 3.8 0 0 0 35.12 42h3.76a3.8 3.8 0 0 0 3.29-1.88l2.69-4.61a1 1 0 0 0 0-1l-2.69-4.62A3.8 3.8 0 0 0 38.88 28h-3.76a3.8 3.8 0 0 0-3.29 1.88l-2.69 4.62a1 1 0 0 0 0 1zm1.73-9.23a1.8 1.8 0 0 1 1.56-.88h3.76a1.8 1.8 0 0 1 1.56.89l2.4 4.11-2.4 4.11a1.8 1.8 0 0 1-1.56.89h-3.76a1.8 1.8 0 0 1-1.56-.89L31.16 35z" />
      <path d="M35 35a2 2 0 0 1 2-2 1 1 0 0 0 0-2 4 4 0 0 0-4 4 1 1 0 1 0 2 0zM58.86 43.49l-2.69-4.62A3.8 3.8 0 0 0 52.88 37h-3.76a3.8 3.8 0 0 0-3.29 1.88l-2.69 4.62a1 1 0 0 0 0 1l2.69 4.61A3.8 3.8 0 0 0 49.12 51h3.76a3.8 3.8 0 0 0 3.29-1.88l2.69-4.61a1 1 0 0 0 0-1.02zm-4.42 4.61a1.8 1.8 0 0 1-1.56.89h-3.76a1.8 1.8 0 0 1-1.56-.89l-2.4-4.1 2.4-4.11a1.8 1.8 0 0 1 1.56-.89h3.76a1.8 1.8 0 0 1 1.56.89l2.4 4.11z" />
      <path d="M51 40a4 4 0 0 0-4 4 1 1 0 1 0 2 0 2 2 0 0 1 2-2 1 1 0 0 0 0-2zM42.17 47.87A3.8 3.8 0 0 0 38.88 46h-3.76a3.8 3.8 0 0 0-3.29 1.88l-2.69 4.62a1 1 0 0 0 0 1l2.69 4.61A3.8 3.8 0 0 0 35.12 60h3.76a3.8 3.8 0 0 0 3.29-1.88l2.69-4.61a1 1 0 0 0 0-1zm-1.73 9.23a1.8 1.8 0 0 1-1.56.89h-3.76a1.8 1.8 0 0 1-1.56-.89l-2.4-4.1 2.4-4.11a1.8 1.8 0 0 1 1.56-.89h3.76a1.8 1.8 0 0 1 1.56.89l2.4 4.11z" />
      <path d="M37 49a4 4 0 0 0-4 4 1 1 0 1 0 2 0 2 2 0 0 1 2-2 1 1 0 0 0 0-2z" />
      <path d="M44 88a44 44 0 1 1 44-44 44.05 44.05 0 0 1-44 44zm0-86a42 42 0 1 0 42 42A42 42 0 0 0 44 2z" />
    </g>
  </svg>
)

export default Lupins
