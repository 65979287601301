import React from 'react'
import Modal from 'react-bootstrap/lib/Modal'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as modalActionCreators from '../../actions/modals'
import * as signUpActionCreators from '../../actions/signUp'

// Registration
import StepRegistration from '../Registration/Users/StepRegistrationForm'

const RegModal = props => {
  const { show, stepForm, modalActions, modalCallBack , loginStatus} = props
  return (
    <Modal
      backdrop="static"
      // backdropTransition={false}
      show={show}
      onHide={() => modalActions.hideRegModal()}
      animation={false}
    >
      <Modal.Header closeButton />
      <Modal.Body>
        <StepRegistration successAction={() => modalCallBack(props)} />
      </Modal.Body>
      {stepForm.page <= 1 && !loginStatus && (
        <Modal.Footer>
          <div className="text-center">
            <button
              type="button"
              className="btn btn--link"
              onClick={() => {
                // Shows the Reg Modal with a callback
                // to show the reservation modal on successful login.
                modalActions.showAuthModal({
                  show: true,
                  modalCallBack
                })
              }}
            >
              Already have an account? Sign In
            </button>
          </div>
        </Modal.Footer>
      )}
    </Modal>
  )
}

RegModal.defaultProps = {
  modalCallBack: props => {
    // Adding a default prop so it fails silently
    // console.log('AUTH MODAL - NO AFTER ACTION RECEIVED');
    props.modalActions.hideRegModal()
    props.modalActions.showUserConfirmation()
  }
}

RegModal.propTypes = {
  show: PropTypes.bool.isRequired,
  modalActions: PropTypes.shape({
    hideRegModal: PropTypes.func.isRequired,
    showAuthModal: PropTypes.func.isRequired
  }).isRequired,
  stepForm: PropTypes.shape({}).isRequired,
  modalCallBack: PropTypes.func
}

const mapStateToProps = state => ({
  currentUser: state.reduxTokenAuth.currentUser,
  stepForm: state.stepForm.registration,
  modalCallBack: state.modal.modalProps.modalCallBack,
  loginStatus: state.user.countryCode.disableLogin
})

const mapDispatchToProps = dispatch => ({
  modalActions: bindActionCreators(modalActionCreators, dispatch),
  signUpActions: bindActionCreators(signUpActionCreators, dispatch)
  /** NOTE: This would also be a nice way of doing it
   * https://github.com/reduxjs/react-redux/blob/master/docs/api.md#inject-todos-and-all-todoactioncreators-and-counteractioncreators-directly-as-props
   *
   * actions: bindActionCreators(Object.assign({}, modalActions), dispatch)
   */
})
export default connect(mapStateToProps, mapDispatchToProps)(RegModal)
