import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { showReceiptModal } from '../../../actions/modals'
/* Components */
// import ReviewsAboutMe from './AboutMe'
// import ReviewsByMe from './ByMe'
import NeedsReview from './NeedsReview'
// import HostReviewCard from './../../Experiences/HostReviewCard';
import UserReviewCard from '../../Experiences/UserReviewCard'
import SEO from '../../Shared/Web/SEO'

/* Actions */
import * as userActionCreators from '../../../actions/user'
import * as modalActionCreators from '../../../actions/modals'
import * as formActionCreators from '../../../actions/forms'
import * as hostActionCreators from '../../../actions/host'

import * as transactionActionCreators from '../../../actions/transactions'

import seoMeta from '../../../utils/seo-meta.json'

const gridSize = 'grid__item small--one-whole medium-down--one-half medium--one-half large-up--one-third widescreen-up--one-quarter'
const titles = {
  about: 'About You',
  by: 'By You',
  need: 'Needs Review'
}
class UserReviewsCardView extends Component {
  constructor(props) {
    super(props)
    this.state = {
      reviewData: [],
      reviewType: 'about',
      notFoundMessage: "You don't have any reviews.",
      loading: false
    }
  }

  async componentDidMount() {
    const { reviews, userActions, hostActions } = this.props

    await this.setState({ loading: true })
    // await userActions.getMyReviews('')
    await this.fetchData('reviews_about_me')
    // await hostActions.getExperiences()
    await this.setState({ loading: false })
  }

  async fetchData(type) {
    const { reviews, userActions, hostActions } = this.props
    await userActions.getMyReviews(type)
    if (type === 'reviews_by_me') {
      await this.setState({
        // reviewData: reviews.givenAsGuest,
        reviewData: this.props.reviews.guestReviews,
        reviewType: 'by',
        notFoundMessage: "You haven't reviewed any hosts."
      })
    }
    if (type === 'needs_review') {
      await this.setState({
        // reviewData: reviews.givenAsGuest,
        reviewData: this.props.reviews.receivedAsHost,
        reviewType: 'need',
        notFoundMessage: "You haven't reviewed any hosts."
      })
    }
    if (type === 'reviews_about_me') {
      await this.setState({
        // reviewData: reviews.givenAsGuest,
        reviewData: this.props.reviews.guestReviews,
        reviewType: 'about',
        notFoundMessage: "You don't have any reviews."
      })
    }
  }

  render() {
    const { transactions, transactionActions, currentUser } = this.props
    const { reviewType, reviewData, loading, notFoundMessage } = this.state
    return (
      <div className="breakbread-container">
        <SEO title={`${titles[reviewType]} | ${seoMeta.user_reviews.title}`} description={`${titles[reviewType]} | ${seoMeta.user_reviews.description}`} />
        <div className=" text-center">
          <h1>My Reviews (Diner)</h1>
        </div>
        <div className="account">
          <div className="account-tabs text-center">
            <ul className="nav nav-tabs flexCenter newtabs">
              <li className={reviewType === 'about' ? 'nav-item active' : 'nav-item'}>
                <button
                  type="button"
                  className="unbutton nav-link"
                  style={{ padding: '10px 10px' }}
                  aria-current="page"
                  href="#"
                  onClick={async (e) => {
                    e.preventDefault()
                    await this.fetchData('reviews_about_me')
                    /* this.setState({
                      reviewData: reviews.receivedAsGuest,
                      reviewType: 'about',
                      notFoundMessage: "You don't have any reviews."
                    }) */
                  }}
                >
                  {titles.about}
                </button>
              </li>
              <li className={reviewType === 'by' ? 'nav-item active' : 'nav-item'}>
                <button
                  type="button"
                  className="unbutton nav-link"
                  style={{ padding: '10px 10px' }}
                  href="#"
                  onClick={async (e) => {
                    e.preventDefault()
                    await this.fetchData('reviews_by_me')
                  }}
                >
                  {titles.by}
                </button>
              </li>
              {/* <li className={reviewType === 'need' ? 'nav-item active' : 'nav-item'}>
                <button
                  type="button"
                  className="unbutton nav-link"
                  style={{ padding: '10px 10px' }}
                  href="#"
                  onClick={async (e) => {
                    e.preventDefault()
                    await this.fetchData('needs_review')
                  }}
                >
                  {titles.need}
                </button>
              </li> */}
            </ul>
          </div>

          {reviewType === 'need' && (
            <NeedsReview
              reservations={transactions.reservations.made.past}
              fetch={() =>
                transactionActions.fetchReservationsMade({
                  needs_guest_reviews: true
                })
              }
            />
          )}
          {reviewType !== 'need' && reviewData.length > 0 && (
            <div className="grid grid--uniform">
              {reviewData.map((review) => (
                <div key={`${reviewType}-${review.id}`} className={gridSize}>
                  <UserReviewCard review={review} reviewType={reviewType} {...this.props} />
                </div>
              ))}
            </div>
          )}
          {!loading && reviewType !== 'need' && reviewData.length === 0 && (
            <div className="text-center text-warning">
              <div className="breakbread-container">
                <h5 className="text-center">{notFoundMessage}</h5>
              </div>
              <p>
                <Link to="/experiences" className="btn">
                  Explore Experiences
                </Link>
              </p>
            </div>
          )}
          {loading && reviewType !== 'need' && <div className="spinner spinner--empty" />}
        </div>
      </div>
    )
  }
}

UserReviewsCardView.defaultProps = {
  // onChange: null,
  // experiences: null,
}

UserReviewsCardView.propTypes = {
  userActions: PropTypes.shape({
    getMyReviews: PropTypes.func
  }).isRequired,
  reviews: PropTypes.shape({
    // receivedAsGuest: PropTypes.shape([]),
    // givenAsGuest: PropTypes.shape([]),
    // receivedAsHost: PropTypes.shape([])
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  }).isRequired,
  hostActions: PropTypes.shape({
    getExperiences: PropTypes.func
  }).isRequired,
  transactions: PropTypes.shape({
    reservations: PropTypes.shape({
      made: PropTypes.shape({
        past: PropTypes.arrayOf(PropTypes.shape({}))
      })
    })
  }).isRequired,
  transactionActions: PropTypes.shape({
    fetchReservationsMade: PropTypes.func
  }).isRequired
}

const mapStateToProps = (state) => ({
  currentUser: state.reduxTokenAuth.currentUser,
  // reviews: state.host.reviews,
  reviews: state.user.reviews,
  transactions: state.transactions,
  experiences: state.host.listings.past
})

const mapDispatchToProps = (dispatch) => ({
  formActions: bindActionCreators(formActionCreators, dispatch),
  userActions: bindActionCreators(userActionCreators, dispatch),
  hostActions: bindActionCreators(hostActionCreators, dispatch),
  modalActions: bindActionCreators(modalActionCreators, dispatch),
  transactionActions: bindActionCreators(transactionActionCreators, dispatch),
  showModal: state => {
    dispatch(showReceiptModal({ item: state, receiptType: 'guest' }))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(UserReviewsCardView)
