import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import HostExperienceCard from '../../Experiences/HostExperinceCard'
/* Components */
import { showCreateExperienceModal, showExpScheduleModal } from '../../../actions/modals'
import CreateExperienceLink from '../../Workflows/ExperienceCreate/CreateExperienceLink'
import seo_meta from '../../../utils/seo-meta.json'
import * as hostActionCreators from '../../../actions/host'
import SEO from '../../Shared/Web/SEO'
/* Action Creators */
const gridSize =
  'grid__item small--one-whole medium-down--one-half medium--one-half large-up--one-third'
const fieldGridSize = 'grid__item medium-down--one-half large-up--one-third'

class MyExperiencesCardView extends Component {
  constructor(props) {
    super(props)
    this.state = {
      experienceType: 'all',
      loading: false
    }
    // this.handleConfirmation.bind(this);
  }

  componentWillMount() {
    const {
      hostActions,
      futureExperiences,
      pastExperiences,
      fetchStatus,
      namespace,
      tabActions,
      tabs
    } = this.props
    if (pastExperiences.length === 0 && tabActions) {
      tabActions.changeSelectedTab('upcomingExperiences', namespace)
    }
  }

  componentDidMount = async () => {
    const { hostActions, currentUser } = this.props
    const { attributes } = currentUser
    if(attributes.id) {
      await hostActions.getExperiences('all')
    }
    this.setState({ loading: true })
    this.setState({ experienceData: this.props.allExperiences })
  }

  render() {
    const {
      showModal,
      location,
      item,
      hostActions,
      experienceType,
      futureExperiences,
      allExperiences,
      pastExperiences,
      showModalSchedule
    } = this.props
    if (location.pathname === `/hosts/experiences/create`) {
      showModal({ show: true, item })
    }

    const experienceData = []
    let allData = []

    switch (experienceType) {
    case 'past':
      allData = pastExperiences
      break
    case 'upcoming':
      allData = futureExperiences
      break
    default:
      allData = allExperiences
      break
    }

    for (const key in allData) {
      if (!allData[key].sample) {
        // option = this.state.experienceData[key];
        experienceData.push(allData[key])
      }
    }
    return (
      <div className="breakbread-container">
        <div className=" text-center">
          <SEO title="Hosted Experiences" description="Hosted Experiences" />
          <h1>Hosted Experiences</h1>
        </div>
        <div className="account">
          <div className="account-tabs text-center">
            {/* <TabActions namespace="experiences" data={experiencesTabs}>
                        {childProps => <TabButtons {...childProps} />}
        </TabActions> */}
            <div style={{ margin: '1rem' }}>
              <CreateExperienceLink className="btn">
                Create a new Experience
              </CreateExperienceLink>
            </div>

            {false && (
              <ul className="nav nav-tabs flexCenter newtabs">
                <li
                  className={
                    experienceType == 'all' ? 'nav-item active' : 'nav-item'
                  }
                >
                  <a
                    className="nav-link "
                    aria-current="page"
                    href="#"
                    onClick={e => {
                      e.preventDefault()
                      hostActions.getExperiences('all')
                      hostActions.setHostExperinceFilter({
                        experienceType: 'all'
                      })
                    }}
                  >
                    All
                  </a>
                </li>
                <li
                  className={
                    experienceType == 'upcoming' ? 'nav-item active' : 'nav-item'
                  }
                >
                  <a
                    className="nav-link"
                    href="#"
                    onClick={e => {
                      e.preventDefault()
                      hostActions.getExperiences('upcoming')
                      hostActions.setHostExperinceFilter({
                        experienceType: 'upcoming'
                      })
                    }}
                  >
                    Upcoming
                  </a>
                </li>
                <li
                  className={
                    experienceType == 'past' ? 'nav-item active' : 'nav-item'
                  }
                >
                  <a
                    className="nav-link"
                    href="#"
                    onClick={e => {
                      e.preventDefault()
                      hostActions.getExperiences('past')
                      hostActions.setHostExperinceFilter({
                        experienceType: 'past'
                      })
                    }}
                  >
                    Past
                  </a>
                </li>
              </ul>
            )}
          </div>

          <div className="grid grid--uniform">
            {
              experienceData.length > 0 ? (
                experienceData.map((experince, idx) => (
                  <div key={"HostExperienceCard-"+idx} className={gridSize}>
                    <HostExperienceCard
                      experience={experince}
                      displaySoldStatus
                      experienceType={this.state.experienceType}
                      showModalSchedule={showModalSchedule}
                    />
                    {/* <Link to={`/hosts/${host.id}`}> }
                  <ChefOrHostCard person={host} />
                  {/* </Link> */}
                  </div>
                ))
              ) : (
                <div>
                  <div className="section-header section-heading">
                    <h5>
                      {this.state.loading ? (
                        <React.Fragment>
                        You have not created any experience yet.{' '}
                          <CreateExperienceLink className="customLink">
                        Click here
                          </CreateExperienceLink>{' '}
                      to create one.
                        </React.Fragment>
                      ) : (
                        <div className="spinner spinner--empty" />
                      )}
                    </h5>
                  </div>
                </div>
              )
            }
            {}
          </div>
          <div className="grid grid--uniform">
            {/* <div className={"btn"}>Load More</div> */}
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  currentUser: state.reduxTokenAuth.currentUser,
  allExperiences: state.host.listings.data,
  futureExperiences: state.host.listings.future,
  pastExperiences: state.host.listings.past,
  experienceType:
    state.experience.experienceIndex.HostExperincefilter.experienceType,
  fetchStatus: state.host.listings.status
})

const mapDispatchToProps = dispatch => ({
  hostActions: bindActionCreators(hostActionCreators, dispatch),
  showModal: async state => {
    dispatch(showCreateExperienceModal(state))
  },
  showModalSchedule: state => {
    dispatch(showExpScheduleModal({ item: state }))
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MyExperiencesCardView)
