// Libraries
import React from 'react'
import PropTypes from 'prop-types'

export default function StatusCommon({ status }) {
  // status Active should be in green color, draft in gray and cancel in red, paused can be in orange color.
  let statusClass = 'badge '

  switch (status) {
  case 'confirmed':
  case 'approved':
  case 'active':
    statusClass = 'badge badge-success'
    break
  case 'cancelled':
    statusClass = 'badge badge-error'
    break
  case 'declined':
  case 'rejected':
  case 'draft':
    statusClass = 'badge badge-rejected'
    break
  case 'submitted':
  case 'paused':
  default:
    statusClass = 'badge badge-pending'
    break
  }

  return (
    <p
      className={statusClass}
      style={{
        fontSize: '14px',
        margin: '5px 5px 0 0',
        padding: '7px',
        color: 'white'
      }}
    >
      {status.charAt(0).toUpperCase() + status.slice(1)}
    </p>
  )
}

StatusCommon.defaultProps = {
  status: null
}

StatusCommon.propTypes = {
  status: PropTypes.string
}
