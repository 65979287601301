import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import * as modalActionCreators from '../../actions/modals'
import SEO from '../Shared/Web/SEO'

export const AboutScreen = props => 

  (
    <div>
      <SEO title="Breakbread - About Us" />
                  
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }}>
        <div className="page-header">
          <h1> About Us </h1>
        </div>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c1">
        <span className="c0">
        BreakBread is a digital platform, marketplace and hub for curated
        home-cooked food experiences with a mission to unite and connect people,
        chefs and food lovers around a central idea, &lsquo;food is best
        experienced home-cooked and shared&rsquo;.
        </span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c1">
        <span className="c0">
        Historically, cooking and eating food together has always united people
        across cultures and backgrounds, and its ability to exceed its unity and
        act as a catalyst for human interaction and engagement cannot be
        understated. The nostalgia of a meal shared with friends and family in a
        home unites and inspires us to revive, cultivate and share great home
        cooked food experiences.
        </span>
      </p>
      <p
        style={{ marginLeft: 'auto', marginRight: 'auto' }}
        className="c1"
        id="h.gjdgxs"
      >
        <span className="c0">
        We provide our diners with a simple and easy food discovery experience
        that is at its core, nostalgic, uncomplicated and accessible. We are a
        trusted hub for quality, relevant, contemporary and authentic home
        cooked food experiences.
        </span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c1">
        <span className="c0">
        We endeavor to be a useful member of the food industry. We cultivate and
        encourage a passion in the professionals, amateur chefs, as well as the
        food lovers we support, by providing a platform that prioritizes low
        risk experimentation, group engagement, growth and entrepreneurship.
        </span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c1">
        <span className="c0">
        In all our endeavors we strive to boldly but humbly break the mold and
        promise our chefs and diners one thing above all else - A platform that
        offers an engaging brand experience driven by the &lsquo;simple
        pleasures of great food experiences shared in a setting that feels like
        home&rsquo;.
        </span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c1">
        <span className="c0">We break bread together.</span>
      </p>
    </div>
  )

const TermsScreen = props => {
  props.hideCreateExperienceModal()
  return (
    <div>
      <SEO title="Terms &amp; Conditions" />
      <div className="page-header">
        <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c5">
          <h1 className="c17">Terms and Conditions</h1>
        </p>
      </div>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c4">
        <span className="c14" />
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c2">
        <span className="c9">THIS IS A LONG DOCUMENT CONTAINING SEVERAL IMPORTANT CLAUSES THAT RELATE TO YOUR RIGHTS AND OBLIGATIONS. BY USING THE WEBSITE ACCESSIBLE AT THE ADDRESSES&nbsp;</span>
        <span className="c1">
          <a className="c3" href="https://www.google.com/url?q=http://www.breakbread.com&amp;sa=D&amp;ust=1537861118386000">
            WWW.BREAKBREAD.COM
          </a>
        </span>
        <span className="c0">&nbsp;AND *.BREAKBREAD.*, YOU AGREE TO BE BOUND BY THESE TERMS AND CONDITIONS. WE WOULD ADVISE YOU TO PRINT A COPY FOR YOUR RECORDS.</span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c5">
        <span className="c6">1. Introduction</span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c2" id="h.gjdgxs">
        <span className="c0">1.1 BreakBread LTD is incorporated in the United Kingdom and owns Breakbread.com</span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c2">
        <span className="c0">
          1.2 Breakbread operates websites, social media channels, APIs and applications (&ldquo;Breakbread Websites&rdquo;) and provides a platform, services and market place by which users may issue
          invitations (the &ldquo;Breakbread Invitations&rdquo;) offering to host or facilitate meals (or other social gatherings) (&ldquo;Hosted Experiences&rdquo;) which others may accept.
        </span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c2">
        <span className="c0">
          1.3 Your use of the Breakbread Websites and the services offered by Breakbread (the &ldquo;Breakbread Services&rdquo;) is governed by these terms and conditions set out in this document and
          the documents referred to in this document (together the &ldquo;T&amp;Cs&rdquo;).
        </span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c2">
        <span className="c0">
          1.4 In these T&amp;Cs users who issue Breakbread Invitations are (in that capacity) referred to as &ldquo;Hosts&rdquo; and users who accept Breakbread Invitations are referred to (in that
          capacity) as &ldquo;Guests&rdquo;. A single user may be either a Host or a Guest in relation to different Hosted Experiences. Breakbread provides a global marketplace allowing Hosts and
          Guests to contract with one another.
        </span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c2">
        <span className="c0">
          1.5 Unless Breakbread expressly agrees to the contrary in writing, all your dealings with Breakbread will be on and subject to the terms of these T&amp;Cs. By using the Breakbread Websites
          and the Breakbread Services, you agree to be bound by these T&amp;Cs.
        </span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c2">
        <span className="c0">
          1.6 These T&amp;Cs may be updated from time to time, updates will be posted on the Breakbread website and we shall endeavour to notify registered users of updates, it is however your
          responsibility to ensure that you remain compliant with the current T&amp;Cs from time to time. We may also modify the operation of the Breakbread Services and the amounts charged and
          payment mechanisms. These changes will not have retrospective application. If the modified T&amp;Cs are not acceptable to you, your only recourse is to cease using the Breakbread Websites
          and Breakbread Services. If you do not cancel your Breakbread registration you will be deemed to have accepted the changes.
        </span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c2">
        <span className="c0">
          1.7 Subject to your compliance with these T&amp;Cs, Breakbread grants you a limited non-exclusive, non-transferable licence to download and install a copy of the applications forming a part
          of the Breakbread Websites on each mobile device or computer that you own or control and run that application solely for your own personal use. You agree to adhere, in addition to these
          T&amp;Cs, to the terms and conditions applicable to the device on which you run the application and store from which you download it. Breakbread reserves all rights in the application not
          expressly granted to you by these T&amp;Cs.
        </span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c2">
        <span className="c0">1.8 If you do not agree to be bound by these T&amp;Cs, you may not use the Breakbread Websites or Breakbread Services.</span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c5">
        <span className="c6">2. Registration</span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c2">
        <span className="c0">
          2.1 Unregistered visitors may view the Breakbread Websites. Only registered users may post content on the Breakbread Websites and issue or accept Breakbread Invitations. Registered users are
          identified on the Breakbread Website by their first name and registered city and country. Breakbread may rank or rate registered users. As a user you represent to Breakbread that you will
          only use the Breakbread Websites and Breakbread Services for lawful purposes and where it is lawful to so use them.
        </span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c2">
        <span className="c0">
          2.2 You acknowledge and agree that, by accessing or using the Breakbread Websites or Breakbread Services or by downloading or posting any content to or from the Breakbread Websites you are
          indicating that you have read, and that you understand and agree to be bound by these T&amp;Cs. If you accept or agree to these T&amp;Cs on behalf of a company or other legal entity, you
          represent and warrant that you have the authority to bind that company or other legal entity to these T&amp;Cs and, in such experience, &ldquo;you&rdquo; and &ldquo;your&rdquo; will refer
          and apply to that company or other legal entity.
        </span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c2">
        <span className="c0">
          2.3 By registering with Breakbread you agree to adhere to public policies published by Breakbread on the Breakbread Websites (such as the privacy policy). You may cancel your registration at
          any time by following the instructions on the Breakbread Websites. If you cancel your registration we shall delete all personally identifiable information to the extent required to comply
          with applicable laws. You accept that we may terminate any user&rsquo;s registration at any time.
        </span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c2">
        <span className="c0">
          2.4 Registration and access to the Breakbread Services are intended solely for persons who are 18 or older. Any access or use of the Breakbread Websites or Breakbread Services by anyone
          under 18 is expressly prohibited. By using the Breakbread Websites and Breakbread Services you represent and warrant that you are aged 18 years or older.
        </span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c2">
        <span className="c0">
          2.5 You agree to compete the registration process honestly and as completely as you are able, and to keep the registration and other information about you accurate and up-to-date. You may
          maintain only one registration with Breakbread and may not register as a Breakbread user on behalf of an individual other than yourself. Your registration with Breakbread is personal to you
          and may not be sold or transferred without Breakbread&rsquo;s prior written agreement. You agree never to disclose your login details or password (except for the purpose of your logging in
          to the Breakbread Websites yourself).
        </span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c2">
        <span className="c0">
          2.6 By registering as a user with Breakbread you agree that Breakbread may request consumer, credit rating and similar reports on you. If Breakbread requests any such report, it will request
          and use it in compliance with applicable law.
        </span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c2">
        <span className="c0">
          2.7 If you link your registration with an accepted third-party social networking site or service you represent that you are lawfully able to do so and that such linking complies with all
          terms you and the third party have agreed and will not result in Breakbread incurring any obligations or liabilities.
        </span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c2">
        <span className="c0">2.8 You will need to agree to our payment provider&rsquo;s terms and conditions when you register to make or receive payments.</span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c5 c15">
        <span className="c6" />
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c5">
        <span className="c6">3. Our content and your use of the Breakbread Website</span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c2">
        <span className="c0">
          3.1 Apart from the content generated by registered users, all the elements that make up the Breakbread Websites and Breakbread Services (including, without limiting the generality of this
          provision, its content, graphics, applications, software, functions and other features) belong to Breakbread (or the person from whom Breakbread licences it).You agree that you have no
          entitlement to any part of the Breakbread Websites or Breakbread Services otherwise than to view content and use the communications facilities in connection with arranging and promoting
          Hosted Experiences and issuing, accepting and paying for Breakbread Invitations in accordance, in each case, with these T&amp;Cs.You agree that you are not permitted to reproduce,
          distribute, emulate, publish or imitate any part of the Breakbread Websites and Breakbread Services without Breakbread&rsquo;s prior written agreement.
        </span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c2">
        <span className="c0">3.2 In using the Breakbread Website and Breakbread Services you agree that you will not:</span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c2">
        <span className="c0">
          3.2.1 Circumvent Breakbread&rsquo;s legitimate commercial interests by participating in a transaction related to a Hosted Experience independent of the Breakbread Websites and Breakbread
          Services or take any action (other than publish bona fide reviews) to dilute, tarnish or otherwise harm the Breakbread brand in any way;
        </span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c2">
        <span className="c0">
          3.2.2 Use any part of the Breakbread Website or Breakbread Services for any commercial or other purposes that are not expressly permitted by these T&amp;Cs or in a manner that falsely
          implies Breakbread endorsement, partnership or otherwise misleads others as to your affiliation with Breakbread or any of its users or partners;
        </span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c2">
        <span className="c0">
          3.2.3 Use manual or automated software, devices, scripts, robots, backdoors or other means or processes to (i) access, &ldquo;scrape,&rdquo; &ldquo;crawl&rdquo; or &ldquo;spider&rdquo; any
          web pages or other services contained in the Breakbread Website and Breakbread Services, or (ii) collect information from or otherwise interact with Breakbread Website or Breakbread
          Services, or (iii) systematically retrieve data or other content from any of the Breakbread Websites to create or compile, directly or indirectly, in single or multiple downloads, a
          compilation, database, directory or similar or analogous collection of information, or (iv) access, tamper with, or use non-public areas of any of the Breakbread Websites and Breakbread
          Services or the technical delivery systems of Breakbread&rsquo;s partners, or (v) attempt to probe, scan, or test the vulnerability of any part of any of the Breakbread Websites or
          Breakbread&rsquo;s Services or Breakbread&rsquo;s security or authentication measures, or (vi) damage, disable, overload or alter any part of the Breakbread Website or any other website,
          application or electronic communication, or (vii) broadcast any element containing computer viruses or any code, file, or computer program designed to interrupt, destroy or limit the
          software, features, IT or telecommunication equipment;
        </span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c2">
        <span className="c0">
          3.2.4 Access or use any of the Breakbread Website or Breakbread Services (i) to use, expose, or allow to be used or exposed, any content that is not publicly displayed by Breakbread in its
          search results pages or listing pages before a Breakbread Invitation is accepted; or (ii) in any way that is inconsistent with these T&amp;Cs or any privacy or other policy publicly adopted
          by Breakbread; or (iii) in any way that violates the privacy or any other rights of any other of Breakbread&rsquo;s users or any other third party;
        </span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c2">
        <span className="c0">
          3.2.5 Register and/or use any intellectual property of Breakbread (or persons from which it licences intellectual property) or in the name &ldquo;Breakbread&rdquo; or its logo (or derivative
          terms or terms that are confusingly similar) whether in any domain names, trade names, trademarks or other source identifiers, trademarks, taglines, promotional campaigns or otherwise or
          infringe the rights of Breakbread or the rights of any other person or entity (including, without limiting the generality of this provision, any intellectual property, privacy, publicity or
          contractual rights);
        </span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c2">
        <span className="c0">
          3.2.6 Copy, store or otherwise access or use any information contained on any of the Breakbread Websites for purposes not expressly permitted by these T&amp;Cs or use, display, mirror or
          frame the any of the Breakbread Websites or Breakbread Services, or any individual element within the any of the Breakbread Websites or Breakbread Services, Breakbread&rsquo;s name, any
          Breakbread trademark, logo or other proprietary information, or the layout and design of any page or form contained on a page in the Breakbread Websites and Breakbread Services, without
          Breakbread&rsquo;s express written consent;
        </span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c2">
        <span className="c0">
          3.2.7 Interfere with or damage any of the Breakbread Websites or Breakbread Services (including, without limiting the generality of this provision, by the use of viruses, cancel bots, Trojan
          horses, harmful code, flood pings, denial-of-service attacks, backdoors, packet or IP spoofing, forged routing or electronic mail address information or similar methods or technology) or in
          connection with the distribution of unsolicited emails or advertisements (other than Breakbread Invitations);
        </span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c2">
        <span className="c0">
          3.2.8 &ldquo;Stalk&rdquo; or harass any other user of any of the Breakbread Websites or Breakbread Services, or collect or store any personally identifiable information about any other user
          (other than for purposes of transacting as a Guest or Host);
        </span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c2">
        <span className="c0">3.2.9 Contact any Breakbread user otherwise than about a Breakbread Invitation;</span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c2">
        <span className="c0">
          3.2.10 Recruit or otherwise solicit any Breakbread user to join a third-party service or website whether or not it is competitive to Breakbread, without Breakbread&rsquo;s prior written
          approval;
        </span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c2">
        <span className="c0">
          3.2.11 Avoid, bypass, remove, deactivate, impair, scramble, descramble, or otherwise circumvent any technological measure implemented by Breakbread or any of Breakbread&rsquo;s providers or
          any other third party (including another user) to protect any part of the Breakbread Websites or Breakbread Services, forge any TCP/IP packet header or any part of the header information in
          any email or newsgroup posting, or in any way use any part of any of the Breakbread Websites or Breakbread Services to send altered, deceptive or false source-identifying information or
          attempt to decipher, decompile, disassemble or reverse engineer any of the software used to provide the any part of any of the Breakbread Websites or Breakbread Services; or
        </span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c2">
        <span className="c0">3.2.12 Advocate, encourage, or assist any third party in doing any of the foregoing.</span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c2">
        <span className="c0">
          3.3 You agree not to remove, alter or obscure any copyright, trademark, service mark or other proprietary rights notices incorporated in or accompanying the Breakbread Websites, Breakbread
          Services and ancillary documentation. You also agree not to use, copy, adapt, modify, prepare derivative works based upon, distribute, license, sell, transfer, publicly display, publicly
          perform, transmit, broadcast or otherwise exploit the Breakbread Website or Breakbread Services except as expressly permitted in these T&amp;Cs. No licenses or rights are granted to you by
          implication or otherwise under any intellectual property rights owned or controlled by Breakbread or its licensors, except for the licenses and rights expressly granted in these T&amp;Cs.
        </span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c5">
        <span className="c6">4 Your Content</span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c2">
        <span className="c9">
          4.1 Registered users may post content on the Breakbread Websites. Anything that you post (or otherwise cause to be visible) on a Breakbread Website is deemed for the purposes of these
          T&amp;Cs to be content published by you (&ldquo;Your Content
        </span>
        <span className="c19">&rdquo;).</span>{' '}
        <span className="c0">
          Breakbread&rsquo;s policy is not to edit or moderate Your Content unless required to do so to ensure compliance with these T&amp;Cs or with your prior consent. You agree that if you have
          linked your Breakbread registration with any third-party service, the services may exchange information and you shall be deemed to have published any information made available by the
          third-party service as a result of the accounts being linked.
        </span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c2">
        <span className="c0">4.2 You are responsible for all Your Content. By registering to use the Breakbread Websites you agree that:</span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c2">
        <span className="c0">4.2.1 Any representation that you publish is true;</span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c2">
        <span className="c0">4.2.2 You shall not use the Breakbread Websites or Breakbread Services to offer products or services other than Breakbread Invitations</span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c2">
        <span className="c0">
          4.2.3 You shall not post, upload, publish, submit or transmit anything that: (i) infringes, misappropriates or violates a third party&rsquo;s patent, copyright, trademark, trade secret,
          moral rights or other intellectual property rights, or rights of publicity or privacy or rights to an image; or (ii) violates, or encourages any conduct that would violate, any applicable
          law or regulation or would give rise to civil or criminal liability or might be expected to incite any anti-social behaviour; or (iii) is fraudulent, false, misleading (directly or by
          omission or failure to update information) or deceptive; or (iv) is defamatory, abusive, threatening, harassing or obscene, pornographic, vulgar or may otherwise be found to be offensive by
          a reasonable person; or (v) is discriminatory, prejudicially intolerant, racist, hate inspired, harassing or harmful toward any individual or group, anti-Semitic, paedophilic, inciting
          violence, racial or ethnic hatred, illegal or that is in any other way reprehensible; or (vi) is violent or threatening or promotes violence or actions that are threatening to any person or
          group; or (vii) promotes illegal or harmful activities or substances; or (viii) condones or promotes any of the things listed in (i) to (vii);
        </span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c2">
        <span className="c0">
          4.2.4 None of Your Content shall include any images which you do not own or which include or depict any identifiable person who has not consented to the use of the image (and, in the case of
          images taken at a Hosted Experience, in respect of which the relevant Host has consented to you publishing);
        </span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c2">
        <span className="c0">
          4.2.5 You shall not use the Breakbread Website or Breakbread Services to (i) transmit, distribute, post or submit any information concerning any other person or entity (including, without
          limiting the generality of this provision, photographs, contact or sensitive information or credit, debit, banking or other account numbers) without the relevant person&rsquo;s prior
          permission; or (ii) impersonate any person or entity, or falsify or otherwise misrepresent yourself or your affiliation with any person or entity;
        </span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c2">
        <span className="c0">4.2.6 All Your Content may be made public and associated with you;</span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c2">
        <span className="c0">
          4.2.7 Breakbread is under no obligation to store and/or to publish Your Content (and Breakbread may at any time and without prior notice delete Your Content from any part of the Breakbread
          Website);
        </span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c2">
        <span className="c0">
          4.2.8 Although you remain the owner of (and responsible for) Your Content, nothing in any relationship between you and Breakbread operates to transfer any intellectual property rights to
          you;
        </span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c2">
        <span className="c0">
          4.2.9 You grant (or shall procure the grant) to Breakbread of a worldwide, non-exclusive, transferable, sub-licensable, royalty-free license to use all content (and all related intellectual
          property) in any content made available by you on the Breakbread Website;
        </span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c2">
        <span className="c0">
          4.2.10 Breakbread may edit, reproduce (in whole or in part), translate, link to and from, group and otherwise deal with Your Content (or elements of it) as it deems appropriate;
        </span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c2">
        <span className="c0">
          4.2.11 Breakbread may use and exploit Your Content (and information that Breakbread learns about you) to communicate with you, target advertising to you and develop, implement and refine
          marketing campaigns and to promote Breakbread&rsquo;s Services generally; and
        </span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c2">
        <span className="c0">4.2.12 You shall not advocate, encourage, or assist any third party in doing any of the foregoing.</span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c2">
        <span className="c0">
          4.3 You agree that any profile photograph that you upload is an honest representation of you and does not infringe any intellectual property rights belonging to a third party and that you
          expressly authorize Breakbread to use your image.&nbsp;
        </span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c2">
        <span className="c0">
          4.4 By accepting these T&amp;Cs you grant Breakbread a worldwide, irrevocable, perpetual (or for the term of the protection), non-exclusive, transferable, royalty-free license, with the
          right to sublicense, to use, view, copy, adapt, translate, modify, distribute, license, sell, transfer, publicly display, publicly perform, transmit, stream, broadcast, access, view, and
          otherwise exploit Your Content (which may include publishing it on, through, by means of the Breakbread Websites and Breakbread Services and may also include promoting or marketing the
          Breakbread Websites and Breakbread Services). Breakbread does not claim any ownership rights in Your Content and nothing in these T&amp;Cs will be deemed to restrict any rights that you may
          have to use and exploit Your Content.
        </span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c2">
        <span className="c0">
          4.5 Notwithstanding the licences granted to Breakbread pursuant to clauses &lrm;4.2.8 and &lrm;4.3, Breakbread shall use reasonable endeavours to remove such of Your Content as you request
          us to remove from our current Breakbread Websites (the request to be made through your registered account and confirmed in a recorded delivery letter with signed proof of receipt including
          sufficient details for us to identify you and the relevant part of Your Content that you request is removed from the Breakbread Websites).
        </span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c2">
        <span className="c0">
          4.6 The Breakbread Websites and Breakbread Services may provide links or referrals to other websites, services, publications or applications. Breakbread makes and gives no representation,
          endorsement, guarantee or confirmation as to the reliability of any such website, service publication or application and by agreeing to these T&amp;Cs you agree that you shall not rely on
          Breakbread when assessing or using them and that Breakbread shall under no circumstance be responsible to you for any failure of any websites, services, publications or applications for
          which it is not responsible. Breakbread assumes no responsibility for the content of websites linked on our site. Breakbread will not be liable for any loss or damage that may arise from
          your use of them.In this context you confirm that Breakbread is not responsible for the actions or failures of its payment providers.
        </span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c2">
        <span className="c0">
          4.7 You agree and consent that Breakbread may (but is not obliged to) employ translators and copy editors (and may apply automated translation and copy editing services) to Your Content to
          change the style or language used by you. Except to the extent that anything done by Breakbread create an obligation or liability which would not otherwise have arisen, you agree that these
          processes shall not diminish any responsibility or liability you have for Your Content (and that you shall hold Breakbread harmless from any loss it may suffer as a result of hosting or
          otherwise dealing with Your Content).
        </span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c5">
        <span className="c6">5. Data</span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c2">
        <span className="c0">
          5.1 By accepting these T&amp;Cs you agree that Breakbread may collate and analyse data resulting from your use of the Breakbread Websites and Breakbread Services and may use and sell the
          resulting information.&nbsp;
        </span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c2">
        <span className="c0">
          5.2 Breakbread will use reasonable endeavours to ensure that unless you agree to the contrary your personal information will not be disclosed to third parties except to the extent required
          to undertake reasonable verification, necessary to provide the Breakbread Services or to operate the Breakbread Website or as permitted by clause &lrm;5.3.
        </span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c2">
        <span className="c0">
          5.3 You expressly grant us permission, without further notice, to store, transfer and disclose data and other information relating to you or to your transactions, including, but not limited
          to, personally identifiable information to any relevant tax or other governmental or competent regulatory or law enforcement authority or agency that claims to be entitled to such
          information.
        </span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c2">
        <span className="c0">
          5.4 To the extent required by applicable law, Breakbread shall on request of the relevant registered user delete all information that is personal to that registered user. Breakbread may
          however (subject to applicable laws) retain data that may have been obtained by previous interactions with Breakbread provided that that data cannot be linked to any individual person.
        </span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c2">
        <span className="c0">5.5 Breakbread reserves the right to market the results of this automatic data handling.</span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c2">
        <span className="c0">
          5.6 You agree not to do anything to create a false impression that you are endorsed by, partnering with, or acting on behalf of or for the benefit of Breakbread, including by inappropriately
          using any Breakbread intellectual property.
        </span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c5">
        <span className="c6">6. Hosted Experiences</span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c2">
        <span className="c0">
          6.1 The Breakbread Websites and Breakbread Services comprise an online platform designed to enable Hosts to create Breakbread Invitations for Hosted Experiences and for Guests to learn about
          and book Hosted Experiences directly with the Hosts. You understand and agree that Breakbread is not a party to any agreements between Hosts and Guests and that Breakbread has no control
          over (and not liability or obligation to you in respect of) the conduct of (i) Hosts, (ii) Guests, (iii) other users of the Breakbread Websites and Breakbread Services or (iv) others
          attending or facilitating Hosted Experiences. You agree that Breakbread is not responsible for any damage or harm resulting from your interactions with other users of the Breakbread Websites
          or Breakbread Services and you accept that (to the maximum extent permitted by law) Breakbread disclaims all liability resulting from the conduct or any such person.
        </span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c2">
        <span className="c0">
          6.2 By agreeing to these T&amp;Cs you agree to adhere to all applicable laws and regulations (including without limiting the generality of this provision, fiscal and administrative
          regulations) that apply to any Hosted Experience.
        </span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c2">
        <span className="c0">
          6.3 You agree that Breakbread shall under no circumstance be held responsible or liable for any representation in a Breakbread Invitation or for a Hosted Experience not running smoothly or
          as advertised or otherwise being unsatisfactory.
        </span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c2">
        <span className="c9">6.4 In certain circumstances, Breakbread may decide, in its sole discretion, that it is necessary or desirable to cancel a Hosted&nbsp;</span>
        <span className="c9">
          <a className="c3" href="https://www.google.com/url?q=http://event.in/&amp;sa=D&amp;ust=1537861118398000">
            Experience. In
          </a>
        </span>
        <span className="c0">
          &nbsp;these circumstances Breakbread may determine, in its sole discretion, to refund to the Guest part or all of the amounts charged to the Guest. You agree that Breakbread and the relevant
          Guest or Host will have no liability to you in respect of such cancellations or refunds.
        </span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c5">
        <span className="c6">7. Financial arrangements</span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c2">
        <span className="c0">
          7.1 When issuing Breakbread Invitations Hosts state the fees that they will charge each Guest, these fees are quoted inclusive of all sales and analogous or other taxes (the
          &ldquo;Host&rsquo;s Charges&rdquo;).Breakbread arranges a service for these fees to be collected and held in a third-party trust account on terms that the funds will be paid in accordance
          with these T&amp;Cs (arranging this collection and holding service constitutes a part of the Breakbread Services). Breakbread may charge Guests a fee for the use of the Breakbread Services
          (the &ldquo;Breakbread Fees&rdquo;). Acceptance of a Breakbread Invitation constitutes agreement to pay the Host&rsquo;s Charges and any Breakbread Fees (the &ldquo;Transaction Fees&rdquo;).
          The total amount of the Transaction Fees is the amount payable by the Guest in respect of each Breakbread Invitation at the time that the Guest accepts the Breakbread Invitation.
        </span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c2">
        <span className="c0">
          7.2 Where applicable, the Breakbread Fees are calculated as a variable percentage of the Host&rsquo;s Charges. Any Breakbread Fees are payable by the Guests at the same time as payment of
          the Host&rsquo;s Charges. To the extent that the Breakbread Fees are subject to sales taxes, such as VAT, these taxes will be included in the total amount payable by the Guest in respect of
          the Transaction Fees. Where Breakbread charges Breakbread Fees it shall on request provide an invoice in respect of those Breakbread Fees, separately identifying any sales taxes charged by
          Breakbread.
        </span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c2">
        <span className="c0">
          7.3 Hosts agree and acknowledge that they are responsible for discharging all of their own tax obligations (including sales taxes including, but not limited to, VAT) whether these are
          imposed on a state, local or other level. Breakbread assumes no obligations whatsoever in this regard. In particular, Breakbread assumes no responsibility (actual or deemed) to notify or
          explain to Hosts, or to enquire into, the VAT or sales taxes treatment of any Hosts or Hosted Experiences.&nbsp;
        </span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c2">
        <span className="c0">
          7.4 If Breakbread so requires for the purposes of its dealings with any tax or regulatory authority, Hosts and Guests shall provide to Breakbread, promptly on request, any information, data,
          documentation and/or copies of communications (whether in electronic format or otherwise) which relate to Hosted Experiences in which they have participated. Hosts shall, promptly on
          request, provide to Breakbread all reasonable assistance in any discussion or dispute with any tax authority with respect to the VAT or sales tax treatment of Hosted Experiences in which
          those Hosts have participated. Each Host agrees to indemnify Breakbread for any VAT or similar sales tax obligations which Breakbread is required to pay and which arise wholly from Hosted
          Experiences hosted by that Host.
        </span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c2">
        <span className="c0">
          7.5 You may only make payments in respect of Breakbread Invitations through the payment processing facilities provided on the Breakbread Websites, by special arrangement with Breakbread
          through its direct reservation services or of through a third-party agency that is authorised by Breakbread to provide those parts of the Breakbread Services relating to processing payments.
          Please check with Breakbread to ensure that any third-party with whom you are dealing is properly authorised by Breakbread. If the Breakbread Invitation is transacted through an authorised
          third-party you may not be required to pay any Breakbread Fees, but the authorised third-party agent may instead charge an administration fee.
        </span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c2">
        <span className="c0">
          7.6 You agree that the sole and exclusive role and responsibility of Breakbread (and any of its authorised third-party agents) in providing Breakbread Services relating to processing
          payments is to arrange the collection of payments from Guests and for the payment of the amounts collected in accordance with these T&amp;Cs (and you agree that they have no other or further
          liability to you in respect of any payment made).
        </span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c2">
        <span className="c0">
          7.7 Payments are made by Guests through selected payment providers. The terms on which the payment providers hold funds are stated in their terms. Breakbread will receive amounts due to
          Hosts from payment providers and be responsible for transferring to the Host (or, where appropriate, reimburse to the Guest).
        </span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c2">
        <span className="c0">
          7.8 Breakbread may &ldquo;round off&rdquo; numbers to the nearest functional base unit in the relevant currency. Breakbread uses commercial currency exchange operators and registered users
          accept the risks in fluctuations in currency exchange rates
        </span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c5">
        <span className="c6">8. Insurance, disputes, resolution and refunds</span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c2">
        <span className="c9">8.1 Guests may need to cancel a Breakbread Invitation that they have previously accepted, in such cases please contact Breakbread at&nbsp;</span>
        <span className="c1">
          <a className="c3" href="mailto:Breakbreadplatform@gmail.com">
            Support@breakbread.com
          </a>
        </span>
        <span className="c9">
          . Guests shall qualify for a full refund of the Host&rsquo;s Charges if (a) the Guest notifies Breakbread and the Host of cancellation during usual business hours in
        </span>{' '}
        <span className="c19">Dubai</span>
        <span className="c0">
          &nbsp;(being 9am to 5pm on weekdays other than public holidays) at least 48 hours before the date on which the relevant Hosted Experience is due, or (b) if the Host cancels the Hosted
          Experience for any reason.
        </span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c2" id="h.30j0zll">
        <span className="c9">
          8.2 We hope all Guests enjoy every Hosted Experience, however sometimes things go wrong and if a Guest believes that the Host failed to fulfil an essential obligation of the Hosted
          Experience then the Guest may until midnight on the day following the date on which the relevant Hosted Experience was scheduled to take place request that the whole or a part of the payment
          is refunded by using the claim form on the page summarizing the reservation or by sending an email to&nbsp;
        </span>
        <span className="c11">
          <a className="c3" href="mailto:Breakbreadplatform@gmail.com">
            Support@breakbread.com
          </a>
        </span>
        <span className="c0">
          &nbsp;specifying the Hosted Experience reference, identifying the Host, specifying the amount of the Transaction Fees, saying how much the Guest wishes to be reimbursed and giving as much
          information as possible in relation to the problem/ relevant failures/ reasons for believing that payment should be refunded.
        </span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c2">
        <span className="c9">8.3 Breakbread has an&nbsp;insurance policy&nbsp;in force which is intended to give the benefits and protections. Please notify Breakbread immediately at&nbsp;</span>
        <span className="c1">
          <a className="c3" href="mailto:Breakbreadplatform@gmail.com">
            Support@breakbread.com
          </a>
        </span>
        <span className="c9">
          &nbsp;if you have suffered loss which appears to be covered by the insurance policy. The notification should include all relevant details and include such information and evidence as is
          available to you to substantiate the claim. By sending the email you agree to provide us with all further information that is reasonably available to you which we request in relation to the
          claim, including any medical or travel details and expenses, details of any other insurance available to you and any proposed or agreed resolution of any of the circumstances relating to the
          claim with any third party. Breakbread agrees to indemnify or compensate you in respect of any such losses to the extent only that it is able to secure recovery from its insurance policy.
          Breakbread will not deduct any administration fees from any such amount so recovered. Save as provided in this clause &lrm;8.3
        </span>
        <span className="c16">&nbsp;</span>
        <span className="c0">Breakbread has no further or other obligation or liability in respect of its guarantee.</span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c2">
        <span className="c0">
          8.4 If you have a dispute relating to a Hosted Experience, we encourage you (although this is not compulsory) to contact us in order to try to resolve the dispute amicably. We can be
          contacted:
        </span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }}>
        <ul className="c7 lst-kix_list_1-0 start">
          <li className="c18">
            <span className="c9">By email at the following address:&nbsp;</span>
            <span className="c1">
              <a className="c3" href="mailto:Breakbreadplatform@gmail.com">
                Support@breakbread.com
              </a>
            </span>
          </li>
          <li className="c13">
            <span className="c9">By telephone on</span> <span className="c19">+971 6 554 7222</span>
          </li>
        </ul>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c15 c20">
        <span className="c10" />
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c2">
        <span className="c0">
          8.5 It is important that if, as a Guest, you are not satisfied with a Hosted Experience you notify Breakbread before midnight on the day following the date on which the relevant Hosted
          Experience was scheduled to take place. Once Breakbread has released funds to the Host, it is unable to offer Guests any remedy.
        </span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c2">
        <span className="c0">
          8.6 Following receipt of a refund request Breakbread may direct that an amount up to the entire value of the Transaction Fees paid by the Guest (less payment transaction costs and any
          third-party agency fees that Breakbread is not entitled to recover) is refunded to the Guest. If, however, Breakbread believes that the complaint is without merit, of limited merit or that
          Breakbread is not able to assess the merit of the complaint, then Breakbread may direct that the whole or any part of the Transaction Fees are paid to the Host and/ or that the balance (if
          any) is withheld pending resolution of the dispute between the relevant Host and the Guest. If no such resolution has been reached within six months, Breakbread may direct that 50% of the
          disputed element of the Host&rsquo;s Charges is paid to the Host and that 50% of the disputed element of the Host&rsquo;s Charges is paid to the Guest (and the Breakbread Fees shall be paid
          to Breakbread) or may make such other arrangements as then appear appropriate to it (which may include making payment as directed by a Court of competent jurisdiction). Breakbread shall have
          no further or other liability or responsibility to the Host and Guest in respect of any such claim to be refunded. You agree to abide by decisions made by Breakbread (even if the decision
          appears perverse to you).
        </span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c2">
        <span className="c0">
          8.7 Breakbread agrees to use reasonable endeavours to assess merits of claims and to act in good faith toward Guests and Hosts, however you agree that it has no responsibility or liability
          to you for any assessment it makes in relation to the merits of any claim between Guests and Hosts or any direction it gives as to the payments to be made to Guests or Hosts in accordance
          with this clause &lrm;8 and you also agree that Breakbread may make or decline refunds and payments pursuant to these T&amp;Cs and that if and to the extent it does so it shall have no
          further liability or obligation to you or the Host or Guest.
        </span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c2">
        <span className="c0">
          8.8 Breakbread will endeavour to make refunds to Guests through the same payment facility through which the payment was originally made. Where it is not possible to make such a refund, it
          will may require other verification before the refund can be processed. The Guest agrees to bear the cost of currency exchanges and risks in fluctuations in currency exchange rates.
        </span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c2">
        <span className="c0">
          8.9 By accepting these T&amp;Cs you agree that if Breakbread has followed the processes mentioned above in good faith it shall have no further or other liability or obligation to you.
        </span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c2">
        <span className="c0">8.10 Breakbread has not responsibility or liability in respect of any failing by a payment processor or authorised agent.</span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c5">
        <span className="c6">9 Exclusions and limitations to Breakbread&rsquo;s responsibility and liability</span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c2">
        <span className="c0">
          9.1 You agree by issuing or accepting a Breakbread Invitation you do not create any relationship with Breakbread save as expressly provided in these T&amp;Cs.Breakbread does not control, and
          has no right to control, your Breakbread Invitation, your offline activities associated with your Breakbread Invitation, or any other matters related to any Breakbread Invitation that you
          publish.
        </span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c2">
        <span className="c0">
          9.2 Registered users are required by these T&amp;Cs to provide accurate information about yourself and only to post reliable and accurate content to the Breakbread Websites and not, when
          using the Breakbread Websites or Breakbread Services, to make any factually incorrect, fraudulent or misleading statements or representations. By accepting these T&amp;Cs you agree that
          Breakbread, its payment providers and/or other partners may request identity verification from you (and you agree to comply with such requests as are reasonable made) and may use other
          information or resources available to them to verify your identity, to undertake regulatory checks and to help limit improper use of the Breakbread Websites, Breakbread Services and other
          services provided by the payment providers and other partners. Breakbread does not, however, undertake any obligation to you to undertake any such checks and you agree that Breakbread does
          not represent, endorse, guarantee or confirm the identity of any user or the accuracy of any content or representation made by any user or otherwise received from (or communicated in
          connection with or as a result of) any of the Breakbread Websites or Breakbread Services (and by agreeing to these T&amp;Cs you irrevocably agree that except to the extent otherwise
          expressly agreed by Breakbread you waive any entitlement to claim reliance on any such representation, endorsement, guarantee or confirmation).
        </span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c2">
        <span className="c0">
          9.3 To the extent permitted by law, Breakbread excludes all conditions, warranties, representations or other terms which may apply to the Breakbread Websites and Breakbread Services or any
          content on them, whether express or implied. Breakbread will not be liable to any user for any loss or damage, whether in contract, tort (including negligence), breach of statutory duty, or
          otherwise, even if foreseeable, arising under or in connection with:
        </span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c2">
        <span className="c0">9.3.1 Use of, or inability to use, any of the Breakbread Websites or Breakbread Services; or</span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c2">
        <span className="c0">9.3.2 Use of or reliance on any content displayed on any of the Breakbread Websites or Breakbread Services.</span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c2">
        <span className="c0">9.4 Subject as provided in clause &lrm;8.3, Breakbread will not be liable for:</span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c2">
        <span className="c0">9.4.1 Loss of profits, sales, business, or revenue;</span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c2">
        <span className="c0">9.4.2 Business interruption;</span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c2">
        <span className="c0">9.4.3 Loss of anticipated savings;</span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c2">
        <span className="c0">9.4.4 Loss of business opportunity, goodwill or reputation; or</span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c2">
        <span className="c0">9.4.5 Any indirect or consequential loss or damage.</span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c2">
        <span className="c0">
          9.5 Breakbread will not be liable for any loss or damage caused by a virus, distributed denial-of-service attack, or other technologically harmful material that may infect your computer
          equipment, computer programs, data or other proprietary material due to your use of our site or to your downloading of any content on it, or on any website linked to it.
        </span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c2">
        <span className="c0">
          9.6 By using the Breakbread Websites and Breakbread Services, you agree that (subject as provided in clause &lrm;8.3) any legal remedy or liability that you seek to obtain for actions or
          omissions of other users of the Breakbread Websites and Breakbread Services or other third parties will be limited to a claim against the individual or other third party who caused you harm.
          You agree not to attempt to impose liability on or seek any legal remedy from Breakbread with respect to such actions or omissions.
        </span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c2">
        <span className="c0">
          9.7 You agree that to the maximum extent permitted by law Breakbread has no liability or responsibility to you in respect of any general or specific failure, suspension or interruption in
          any services offered by any of the Breakbread Websites or error or omission in information or processing provided on or by the Breakbread Websites or loss of information provided to the
          Breakbread Websites.
        </span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c2">
        <span className="c0">
          9.8 Nothing in these terms of use excludes or limits our liability for death or personal injury arising from our negligence, or our fraud or fraudulent misrepresentation, or any other
          liability that cannot be excluded or limited by Sharjah law.
        </span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c2">
        <span className="c0">9.9 Save to the extent that liability may not be excluded by law:</span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c2">
        <span className="c0">9.9.1 Breakbread has no liability save to the extent admitted by clauses &lrm;9.8, &lrm;9.9.2 and &lrm;9.9.3;</span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c2">
        <span className="c0">
          9.9.2 Breakbread&rsquo;s liability to the Host is limited to the amount collected by Breakbread as agent of the Host in respect of the Hosted Experience to which the relevant claim relates
          and not paid to the Host (or, where appropriate, refunded to the Guest) in accordance with these T&amp;Cs and any amount recovered under the insurance policy referred to in clause &lrm;8.3;
          and
        </span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c2">
        <span className="c0">
          9.9.3 Breakbread&rsquo;s liability to the Guest is limited to the commission paid to it by the Guest in respect of the Hosted Experience to which the relevant claim relates and any amount
          recovered under the insurance policy referred to in clause &lrm;8.3.
        </span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c2">
        <span className="c0">
          9.10 You agree and confirm that if you choose to use any of the Breakbread Websites or the Breakbread Services to enter any arrangement with another person (whether in your capacity as a
          Guest or as a Host) you should enter an agreement with that other person (that agreement may be or comprise the terms and conditions on which the Breakbread Invitation is offered and
          accepted).You agree to adhere to the terms of any such agreement. You acknowledge and agree that you, and not Breakbread, are responsible for ensuring that each counter-party to any such
          agreement complies with the terms of that agreement and satisfies any obligations the counter-party has to you. By agreeing to these T&amp;Cs you also agree that Breakbread is not a party to
          any agreement between you and any other person (whether as Guests, Hosts, agents or in any other capacity) and that (except to the extent that Breakbread or any of its agents agrees on and
          subject to the terms of clause &lrm;7 and the other provisions of these T&amp;Cs and as part of the Breakbread Services to arrange the collection, holding and payment of Transaction Fees as
          part of the Breakbread Services) Breakbread has no liability to you or any other person arising from or related to any agreements between Guests and Hosts.
        </span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c2">
        <span className="c0">
          9.11 &nbsp;Subject to the provisions of clause &lrm;9, you agree that none of Breakbread, its directors, employees, agents or other representatives is liable to you for any damage, whether
          direct or indirect, resulting from use of the Breakbread Websites or the Breakbread Services even if Breakbread has knowledge or has been informed of the likelihood of the occurrence of such
          damage.
        </span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c5">
        <span className="c6">10 Cancellation and suspension</span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c2">
        <span className="c9">10.1 You may terminate your registration at any time by sending us an email from your registered email to&nbsp;</span>
        <span className="c1">
          <a className="c3" href="mailto:Breakbreadplatform@gmail.com">
            Support@breakbread.com
          </a>
        </span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c2">
        <span className="c0">10.2 Breakbread may terminate or suspend your registration for convenience at any time by giving you notice via email to your registered email address.</span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c2">
        <span className="c0">
          10.3 If your Breakbread registration is cancelled all Hosted Experiences in respect of which you had issued Breakbread Invitation will be automatically cancelled and all Hosted Experiences
          in respect of which you had accepted a Breakbread Invitation will also be automatically cancelled. Depending on the applicable cancelation policy, an appropriate refund may be issued.
        </span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c5">
        <span className="c6">11 Miscellaneous&nbsp;</span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c2">
        <span className="c0">11.1 You declare and guarantee to Breakbread that you are a person over 18 years of age and/or having the legal capacity to enter into a contract.</span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c2">
        <span className="c0">
          11.2 Some portions of the Breakbread platform implement Google Maps/Earth mapping services, including Google Maps API(s). Your use of Google Maps/Earth is subject to Google&rsquo;s terms of
          use.
        </span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c2" id="h.1fob9te">
        <span className="c9">
          11.3 If you become aware of any content on any of the Breakbread Websites that you suspect has been posted in breach of these T&amp;Cs please notify us by email at&nbsp;
        </span>
        <span className="c1">
          <a className="c3" href="mailto:Support@breakbread.com">
            Support@breakbread.com
          </a>
        </span>
        <span className="c21">. It</span>
        <span className="c0">
          &nbsp;will be helpful if you are able to include (a) details of the content concerned, (b) information to enable us to locate the content in question on the Breakbread Website, (c) your
          contact information (full name, postal address, telephone number, email address and, where applicable, the name of the body on whose behalf you are contacting us, its postal address, its
          registration number), (d) a statement, where applicable, that you are the owner of intellectual property rights or rights to an image or to the person concerned or authorized to act in the
          name of the owner.
        </span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c2">
        <span className="c0">
          11.4 You acknowledge and agree that Breakbread may store personal and other information about you on your electronic devices in the form of &ldquo;cookies&rdquo; and other programs and
          devices. The use of these solely concerns the functioning of the Breakbread Website. You acknowledge and agree that if you do not allow or limit the placing of cookies on your devices, this
          ban or restriction may have an adverse effect on your use of the Breakbread Websites. You acknowledge and agree that Breakbread may also store your personal information on computers and
          servers in Europe and beyond its borders. By using the Breakbread Website, you acknowledge and agree that Breakbread may, at its sole discretion store or divulge this information if required
          to do so by law or if there are serious reasons to think that storage or divulgence of this information is required to conform to a legal procedure.
        </span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c2">
        <span className="c0">
          11.5 T The Breakbread Website is hosted and managed by BreakBread LTD provides this Privacy Policy to inform you of our policies and procedures regarding the collection, use and disclosure
          of personal information we receive from users of Breakbread websites, APIs
        </span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c2">
        <span className="c0">11.6 For any question relating to the Breakbread Websites or Breakbread Services you can contact us by the following means:</span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }}>
        <ul className="c7 lst-kix_list_2-0 start">
          <li className="c18">
            <span className="c0">By post: Crescent Enterprises, PO Box 2222,Al Majaz 3, Sharjah, United Arab Emirates &nbsp;</span>
          </li>
          <li className="c13">
            <span className="c9">By email:&nbsp;</span>
            <span className="c1">
              <a className="c3" href="mailto:Breakbreadplatform@gmail.com">
                Support@breakbread.com
              </a>
            </span>
            <span className="c0">&nbsp;</span>
          </li>
          <li className="c12">
            <span className="c0">By telephone: +971 6 554 7222 (available Sunday - Thursday between 9am -5pm GST)</span>
          </li>
        </ul>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c2 c15">
        <span className="c0" />
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c2">
        <span className="c0">11.7 Reference to in these T&amp;Cs to:&nbsp;</span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c2">
        <span className="c0">
          11.7.1 A &ldquo;person&rdquo; or &ldquo;corporation&rdquo; includes a reference to all legal or natural persons, partnerships, trusts, companies, governments or local authority departments
          and other bodies and associations (whether corporate or unincorporated);
        </span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c2">
        <span className="c0">11.7.2 An individual includes where appropriate his personal representatives;</span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c2">
        <span className="c0">11.7.3 The singular includes a reference to the plural and vice versa; and</span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c2">
        <span className="c0">11.7.4 One gender includes each gender (feminine, masculine and neuter).</span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c2">
        <span className="c0">
          11.8 References to Breakbread in the context of obligations or liabilities owed to Breakbread or other benefits or assurances given to Breakbread shall be deemed to include reference also to
          any company which is a subsidiary undertaking of Breakbread
        </span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c2">
        <span className="c0">
          11.9 No failure or delay by Breakbread to exercise any right or remedy under these T&amp;Cs shall be construed as a waiver of that right or remedy nor shall any single or partial exercise of
          any right or remedy preclude the further exercise of that right or remedy. No waiver by Breakbread of any breach of these T&amp;Cs shall be considered as a waiver of a preceding or
          subsequent breach. The rights and remedies provided in these T&amp;Cs are cumulative and are not exclusive of any rights or remedies provided by law.
        </span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c2">
        <span className="c0">
          11.10 These T&amp;Cs and the documents referred to in these T&amp;Cs (as in each case updated from time to time) constitute the whole agreement between you and Breakbread governing our
          relations and supersede all other agreements between you and Breakbread prior to the date of these T&amp;Cs, which shall cease to have any further effect.
        </span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c2">
        <span className="c0">
          11.11 If a provision of these T&amp;Cs is held to be illegal or unenforceable, in whole or in part, under an enactment or rule of law, it shall to that extent be deemed not to form part of
          these T&amp;Cs and the enforceability of the remainder of these T&amp;Cs shall not be affected.
        </span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c2">
        <span className="c0">
          11.12 Breakbread may communicate with you by sending an email to your registered email address. It need only adduce proof that the email was sent and is not responsible for ensuring that you
          receive it.&nbsp;
        </span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c2">
        <span className="c0">
          11.13 It is not intended that a third party should have any right to enforce these T&amp;Cs other than that Breakbread (being the wider group definition in accordance with clause &lrm;11.7)
          may rely on and enforce these T&amp;Cs as if (in each case) named in these T&amp;Cs in place of Breakbread.
        </span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c2">
        <span className="c0">
          11.14 These T&amp;Cs shall be governed by and construed in accordance with Sharjah law and Breakbread and you each agree to submit any dispute, including disputes relating to any
          non-contractual obligations, which may arise out of, under, or in connection with this agreement to the exclusive jurisdiction of the Sharjah courts.
        </span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c8">
        <span className="c24" />
      </p>
    </div>
  )
}
export const TermsScreenWithRedux = connect(null, { hideCreateExperienceModal: modalActionCreators.hideCreateExperienceModal })(TermsScreen)

export const PrivacyScreen = props => {
  props.hideReservationModal()
  return (
    <div className="breakbread-container">
      <SEO title="Privacy Policy" />
      {' '}
      <div className="page-header">
        <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c13" id="h.gjdgxs">
          <h1 className="c9 c15">Privacy Policy</h1>
        </p>
      </div>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c4">
        <span className="c9 c16" />
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c1">
        <span className="c0">
          BreakBread LTD;we&rdquo; or &ldquo;us&rdquo;) provides this Privacy Policy to inform you of our policies and procedures regarding the collection, use and disclosure of personal information
          we receive from users of Breakbread websites, APIs and applications (&ldquo;Breakbread Websites&rdquo;) and provides a platform, services and market place by which users may issue
          invitations (the &ldquo;Breakbread Invitations&rdquo;) offering to host or facilitate meals (or other social gatherings) (&ldquo;Hosted Experiences&rdquo;) which others may accept.
        </span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c1">
        <span className="c10">
          This Privacy Policy applies only to information that you provide to us through the Site and Application. This Privacy Policy may be updated from time to time. We may update this Privacy
          Policy to reflect changes to our information practices. If we make any material changes we will notify you by email (sent to the e-mail address specified in your Breakbread Account) or by
          means of a notice on the Site and Application prior to the change becoming effective. We encourage you to periodically review this page for the latest information on our privacy practices.
        </span>{' '}
        <span className="c6">Unless otherwise defined in this Privacy Policy, terms used in this Privacy Policy have the same meanings as in our&nbsp;</span>
        <span className="c6 c12">Terms and Conditions</span>
        <span className="c6 c9">.</span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c1">
        <span className="c0">
          As used in this policy, the terms &ldquo;using&rdquo; and &ldquo;processing&rdquo; information include using cookies on a computer, subjecting the information to statistical or other
          analysis and using or handling information in any way, including, but not limited to collecting, storing, evaluating, modifying, deleting, using, combining, disclosing and transferring
          information within our organization or among our affiliates internationally.
        </span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c1">
        <span className="c10">Which information do we may collect? We may collect and process the following data about you: Information that you provide by filling in forms on our site&nbsp;</span>
        <span className="c10 c12 c18">
          <a className="c5" href="https://www.google.com/url?q=http://www.eatwith.com/&amp;sa=D&amp;ust=1537861278338000">
            www.Breakbread.com
          </a>
        </span>
        <span className="c0">
          &nbsp;(our site) and/or via a third party account (e.g. Facebook). This includes information provided at the time of registering to use our site, opening a host account, posting material,
          editing your profile, reservation a meal or requesting further services, and includes but is not limited to your name, address, email address, phone number and if you are a user with meals
          to book (a Host), bank account or PayPal details, or if you are a user who wishes to book a meal (a Guest) and make a reservation via our site, billing information. We may also ask you for
          information when you enter a competition or promotion sponsored by us, and when you report a problem with our site or a reservation. If you contact us, we may keep a record of that
          correspondence.
        </span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c1">
        <span className="c0">We may also ask you to complete surveys that we use for research purposes, although you do not have to respond to them.</span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c1">
        <span className="c0">Details of transactions you carry out through our site and of the fulfilment of your reservations.</span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c1">
        <span className="c0">
          Details of your visits to our site including, but not limited to, traffic data, location data, weblogs and other communication data, whether this is required for our own billing purposes or
          otherwise and the resources that you access.
        </span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c1">
        <span className="c0">
          We may monitor, record, store and use any telephone, email or other communication with you in order to check any instructions given to us, for training purposes, for crime prexperienceion
          and to improve the quality of our customer service.
        </span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c2">
        <span className="c3">1. IP Addresses</span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c1">
        <span className="c0">
          We may collect information about your computer, including where available your IP address, operating system and browser type, for system administration and to report aggregate information.
          This is statistical data about our users&rsquo; browsing actions and patterns, and does not identify any individual.
        </span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c2">
        <span className="c3">2. Cookies</span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c1">
        <span className="c0">
          For the same reason, we may obtain information about your general internet usage by using a cookie file which is stored on your browser or the hard drive of your computer. Cookies contain
          information that is transferred to your computer&rsquo;s hard drive. They help us to improve our site and to deliver a better and more personalised service. Some of the cookies we use are
          essential for the site to operate.
        </span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c1">
        <span className="c0">Different types of cookies.</span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c1">
        <span className="c10 c14">Strictly necessary cookies:</span>
        <span className="c0">
          &nbsp;These are cookies that are required for the operation of our site. They include, for example, cookies that enable you to log into secure areas of our site or make use of e-billing
          services.
        </span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c1">
        <span className="c10 c14">Analytical/performance cookies.&nbsp;</span>
        <span className="c0">
          They allow us to recognise and count the number of visitors and to see how users move around our site when they are using it. This helps us to improve the way our site works, for example, by
          ensuring that users are finding what they are looking for easily.
        </span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c1">
        <span className="c10 c14">Functionality cookies.&nbsp;</span>
        <span className="c0">
          These are used to recognise you when you return to our site. This enables us to personalise our content for you, greet you by name and remember your preference (for example, your choice of
          language or region). Targeting cookies. These cookies record your visit to our website, the pages you have visited and the links you have followed. Third parties use this information to make
          our site and the advertising displayed by advertising networks more relevant to your interests. We share this information with third parties for this purpose. Cookies are widely used in
          order to make websites work, or work more efficiently, as well as to provide information to the owners of the site. You can find more information about the individual cookies we use and the
          purposes for which we use them in them table below. If you register with us or if you continue to use our site, you agree to our use of cookies.
        </span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c2">
        <span className="c3">3. How do we protect your information?</span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c1">
        <span className="c0">
          We implement a variety of security measures to maintain the safety of your personal information when you place an order or enter, submit, or access your personal information. We offer the
          use of a secure server. All supplied sensitive/credit information is transmitted via Secure Socket Layer (SSL) technology and then encrypted into our Payment gateway providers database only
          to be accessible by those authorized with special access rights to such systems, and are required to keep the information confidential.
        </span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c2">
        <span className="c3">4. Do we disclose any information to outside parties?</span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c1">
        <span className="c10">
          We do not sell, trade, or otherwise transfer to outside parties your personally identifiable information. This does not include trusted third parties who assist us in operating our website,
          conducting our business, or servicing you, so long as those parties agree to keep this information confidential. We may also release your information when we believe release is appropriate
          to comply with the law, enforce our site policies, or protect ours or others rights, property, or safety. However, non-personally identifiable visitor information may be provided to other
          parties for marketing, advertising, or other uses. We may disclose your personal information to third parties: In the experience that we sell or buy any business or assets, in which case we
          may disclose your personal data to the prospective seller or buyer of such business or assets. If
        </span>{' '}
        <span className="c6">Breakbread</span>{' '}
        <span className="c0">
          or substantially all of its assets are acquired by a third party, in which case personal data held by it about its users will be one of the transferred assets. If we are under a duty to
          disclose or share your personal data in order to comply with any legal obligation, or in order to enforce or apply our Terms of Use and other agreements; or to protect the rights, property,
          or safety of Breakbread, our users, or others. This includes exchanging information with other companies and organisations for the purposes of fraud protection and credit risk reduction.
        </span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c2">
        <span className="c3">5. Changing or Deleting Your Information</span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c1">
        <span className="c10">All Members may review, update, correct or delete the Personal Information in their registration profile by contacting us at&nbsp;</span>
        <span className="c12 c17">
          <a className="c5" href="mailto:support@breakbread.com">
            support@breakbread.com
          </a>
        </span>
        <span className="c0">
          &nbsp;or editing the relevant part of their profile. If you would like us to cancel your Breakbread Account, please contact us and we will attempt to accommodate your request if we do not
          have any legal obligation or a legitimate business reason to retain the information contained in your Breakbread Account. Please note that, if you cancel your Breakbread Account, any reviews
          you have posted via the Site and Application will remain publicly viewable via the Site and Application.
        </span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c2">
        <span className="c3">6. Third party links</span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c1">
        <span className="c0">
          Occasionally, at our discretion, we may include or offer third party products or services on our website. These third party sites have separate and independent privacy policies. We therefore
          have no responsibility or liability for the content and activities of these linked sites. Nonetheless, we seek to protect the integrity of our site and welcome any feedback about these
          sites.
        </span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c2">
        <span className="c3">7. Online Privacy Policy Only</span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c1">
        <span className="c0">This online privacy policy applies only to information collected through our website and not to information collected offline.</span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c2">
        <span className="c3">8. Your Consent</span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c1">
        <span className="c0">By using our site, you consent to our online privacy policy.</span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c2">
        <span className="c3">9. Changes to our Privacy Policy</span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c1">
        <span className="c0">Any changes we may make to our privacy and cookies policy in the future will be posted on this page and, where appropriate, notified to you by email.&nbsp;</span>
      </p>
      <p style={{ marginLeft: 'auto', marginRight: 'auto' }} className="c7">
        <span className="c8" />
      </p>
    </div>
  )
}
export const PrivacyPolicyScreen = connect(null, { hideReservationModal: modalActionCreators.hideReservationModal })(PrivacyScreen)

export const RefundPolicy = () => (
  <div className="breakbread-container">
    <SEO title="Refund Policy" />
    {' '}
    <div className="page-header">
      <div
        style={{ marginLeft: 'auto', marginRight: 'auto', textAlign: 'center' }}
        className="c13"
        id="h.gjdgxs"
      >
        <h1 className="c9 c15">Refund Policy</h1>
      </div>
    </div>
    <p style={{ marginLeft: 'auto', marginRight: 'auto' }}>
      A full refund is issued for cancellations up to 48h before the event. If a
      confirmed reservation is cancelled by a diner 48 hours before the event
      date, the diner will be reimbursed the full reservation amount including
      any commissions and service fees that BreakBread already paid to process
      the initial booking. Hosts will not be issued a payment.
    </p>
    <p style={{ marginLeft: 'auto', marginRight: 'auto' }}>
      If a reservation is cancelled by a diner less than 48 hours before the
      event, then the diner will not be entitled to any return, credit or
      reimbursement. Hosts will be paid the full seat of the booking. In the
      case that a host cancels a confirmed reservation, BreakBread will credit
      the diner with the full reservation amount that the diner has paid. Break
      Bread reserves the right to charge the canceling Host with the reservation
      fees collected.
    </p>
  </div>
)
