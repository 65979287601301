import React from 'react'
import { reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import UploadProfileImage from '../Common/UploadProfileImage'
import validate from './validate'

/* Actions */
import * as signUpActionCreators from '../../../actions/signUp'
import * as modalActionCreators from '../../../actions/modals'
import * as formActionCreators from '../../../actions/forms'
import verifyImg from '../../../icons/status/success-icon.png'

/**
 * HOC that wraps the same common step so we can be DRY
 * @param {Object} props
 */
const UploadProfileImagetUser = props => {
  const { currentUser, VerifyMobileOtp } = props
  return (
    <React.Fragment>
      {currentUser.attributes.phoneNumber ? (
        <React.Fragment>
          {VerifyMobileOtp || currentUser.attributes.phoneVerified ? <img src={verifyImg} className="verifyImageOtp" alt="verifyImageOtp" /> : ''}
          <div className="form-header mtt_10">
            <h4>Upload a Photo</h4>
            {VerifyMobileOtp || currentUser.attributes.phoneVerified ? (
              <React.Fragment>
                <p className="mb_5">Your mobile number was successfully verified.</p>
                <p> Upload a picture and start enjoying experiences.</p>
              </React.Fragment>
            ) : (
              ''
            )}
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          {/* <img
            src={verifyImg}
            className="verifyImageOtp"
            style={{ marginBottom: "10px" }}
            alt="verifyImageOtp"
          /> */}

          {/* <div className="swal2-icon swal2-success swal2-animate-success-icon" style={{ display: 'flex' }}>
            <div className="swal2-success-circular-line-left" style={{backgroundColor: "rgb(255, 255, 255)"}}></div>
            <span className="swal2-success-line-tip"></span> <span className="swal2-success-line-long"></span>
            <div className="swal2-success-ring"></div> <div className="swal2-success-fix" style={{backgroundColor: "rgb(255, 255, 255)"}}></div>
            <div className="swal2-success-circular-line-right" style={{backgroundColor: "rgb(255, 255, 255)"}}></div>
          </div> */}

          {/* <div className="note note--success">You have successfully registered.</div> */}

          <div className="form-header">
            <h4>Upload your Photo</h4>
            <div id="swal2-content" style={{ display: 'block' }}>
              Upload a picture and start enjoying experiences.
            </div>
          </div>
        </React.Fragment>
      )}

      <UploadProfileImage {...props} />
    </React.Fragment>
  )
}

const reduxUploadProfileImagetUser = reduxForm({
  form: 'registration', // <------ same form name
  destroyOnUnmount: false, // <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  validate
})(UploadProfileImagetUser)

const mapStateToProps = state => ({
  stepForm: state.stepForm.hostRegistration,
  activeFormType: state.signUp.activeFormType,
  signUpPane: state.signUp.signUpPane,
  VerifyMobileOtp: state.forgotPassword.mobileVerified
})

const mapDispatchToProps = dispatch => ({
  formActions: bindActionCreators(formActionCreators, dispatch),
  signUpActions: bindActionCreators(signUpActionCreators, dispatch),
  modalActions: bindActionCreators(modalActionCreators, dispatch)
  /** NOTE: This would also be a nice way of doing it
   * https://github.com/reduxjs/react-redux/blob/master/docs/api.md#inject-todos-and-all-todoactioncreators-and-counteractioncreators-directly-as-props
   *
   * actions: bindActionCreators(Object.assign({}, modalActions), dispatch)
   */
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(reduxUploadProfileImagetUser)
