import React from 'react'
import Modal from 'react-bootstrap/lib/Modal'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'

/* Components */
import GuestReceipt from '../Receipt/GuestReceipt'
import HostReceipt from '../Receipt/HostReceipt'
import BookingDetail from '../Receipt/BookingDetails'
/* Actions */
import * as modalActionCreators from '../../actions/modals'

/* Centralized Proptypes */
import { currentUserPropTypes } from '../../constants/composed-proptypes'

const ReceiptModal = props => {
  const { show, modalActions, receiptType } = props
  // console.log('props', props)

  let recieptTitle = ''
  switch (receiptType) {
  case 'booking_detail':
    recieptTitle = `Booking - ${props.item.bookingNumber}`
    break
  case 'guest':
    // recieptTitle = props.item.experience.title
    recieptTitle = `RECEIPT - ${props.item.bookingNumber}`
    break
  case 'host':
    recieptTitle = `STATEMENT - PAYOUT ${props.item.experience.title}`
    break
  case 'statement':
    recieptTitle = `STATEMENT - PAYOUT ${props.item.title}`
    break
  default:
    recieptTitle = props.item.title
    break
  }
  return (
    <Modal
      show={show}
      onHide={() => modalActions.hideReceiptModal()}
      animation={false}
      bsSize="large"
      className="receipt-modal"
    >
      <Modal.Header closeButton className="customHeader">
        <h5>{recieptTitle}</h5>
      </Modal.Header>
      <Modal.Body>
        {/* {receiptType === 'guest' ? <GuestReceipt reservation={props.item} /> : <HostReceipt {...props} />} */}
        {receiptType === 'guest' ? (
          <GuestReceipt reservation={props.item} />
        ) : (
          <React.Fragment>
            {receiptType === 'statement' ? (
              <HostReceipt {...props} />
            ) : (
              <BookingDetail reservation={props.item} />
            )}
          </React.Fragment>
        )}
      </Modal.Body>
    </Modal>
  )
}

ReceiptModal.propTypes = {
  currentUser: currentUserPropTypes.isRequired,
  modalActions: PropTypes.shape({}).isRequired,
  show: PropTypes.bool.isRequired
}
const mapStateToProps = state => ({
  currentUser: state.reduxTokenAuth.currentUser
})

const mapDispatchToProps = dispatch => ({
  modalActions: bindActionCreators(modalActionCreators, dispatch)
})
export default connect(mapStateToProps, mapDispatchToProps)(ReceiptModal)
