import React, { Component } from 'react'
import { Field } from 'redux-form'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import moment from 'moment'
// import Modal from 'react-bootstrap/lib/Modal'
import IntlTelInput from 'react-intl-tel-input'
import { currentUserPropTypes } from '../../../../constants/composed-proptypes'
import * as fields from '../../Fields/formFields'
import alertModal from '../../../../utils/alert'
// import {Tooltip,OverlayTrigger} from 'react-bootstrap'
import {
  sendVerifyEmailLink,
  sendVerifyMobileLink,
  verifyMobile
} from '../../../../actions/forgotPassword'
import RenderDateField from '../../Fields/renderDateField'
import successIcon from '../../../../icons/status/success-icon.png'
import errorIcon from '../../../../icons/allergies_and_diets/SVG/icon_error.svg'
import {
  backgroundFieldCollection,
  personalFieldCollection,
  addressFieldCollection,
  city,
  countryCode,
  email,
  phoneNumber
} from '../../../../constants/formFields'

import AvatarUpload from '../../Fields/ChangeProfileImage'
// import { getUser } from '../../../../actions/user/getUser'
import './AccountDetails.css'
import 'react-intl-tel-input/dist/main.css'
// import { Right } from 'react-bootstrap/lib/Media'

function keysMatch(key, obj) {
  const keys = Object.keys(obj).map(x => x.toLowerCase())
  return keys.indexOf(key.toLowerCase()) !== -1
}

/* NOTE: if currentUser form values match,they will be updated via
 * the initialValues of connectedStep3 which connects to Redux.
 * this happens automagically for all fields on the redux connected
 * form hostRegistration
 * @see https://redux-form.com/7.0.2/examples/initializefromstate/
 */

class AccountDetails extends Component {
  constructor(props) {
    super(props)

    if (props.disableFields) {
      this.state = {
        personalFields: this.disableFields(personalFieldCollection),
        backgroundFields: this.disableFields(backgroundFieldCollection),
        addressFields: this.disableFields(addressFieldCollection),
        email: this.disableFields({ email }).email,
        phoneNumber: this.disableFields({ phoneNumber }).phoneNumber,
        maxNumber: '',
        veriFyEmailsent: false,
        show: false,
        confirmBox: false,
        verifyOtp: '',
        otPerror: '',
        phoneError: '',
        countResend: 0,
        verifyPhone: {
          show: false,
          resendCount: 0,
          totalSeconds: 30
        },
        expireDateOtp: null,
        timeStr: null
      }
    } else {
      this.state = {
        personalFields: personalFieldCollection,
        backgroundFields: backgroundFieldCollection,
        addressFields: addressFieldCollection,
        email,
        phoneNumber,
        maxNumber: '',
        veriFyEmailsent: false,
        show: false,
        confirmBox: false,
        verifyOtp: '',
        phoneError: '',
        otPerror: '',
        countResend: 0,
        verifyPhone: {
          show: false,
          resendCount: 0,
          totalSeconds: 30
        },
        expireDateOtp: null,
        timeStr: null
      }
    }
  }

  componentDidMount = async () => {
    const { currentUser, displayCurrency, updateAvatarStatus, initialValues, change } = this.props
    // getUser()
    const avatarPresent = currentUser.attributes && currentUser.attributes.profilePicture
      && typeof currentUser.attributes.profilePicture.thumb !== 'undefined'
      && !currentUser.attributes.profilePicture.thumb.includes('/thumb_default.png')

    if (!displayCurrency) {
      if (!avatarPresent) {
        updateAvatarStatus(false)
      } else {
        updateAvatarStatus(true)
      }
    }
    let count = 0
    const key = `mobile-otp-${currentUser.attributes && currentUser.attributes.phoneMetaData
      && currentUser.attributes.phoneMetaData.dialCode ? currentUser.attributes.phoneMetaData.dialCode : ''}${currentUser.attributes.phoneNumber}`
    const clickArray = this.getCookie(key).split(',')
    Object.keys(clickArray).forEach(k => {
      if (moment(clickArray[k]).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')) {
        count++
      }
    })
    const expireDateOfMobile = this.getCookie('expire_date_otp')
    this.setState({ expireDateOtp: expireDateOfMobile })
    // Moved setting of initial language to onmount
    if (initialValues.languages && initialValues.languages.length === 0) {
      change('languages', [
        {
          name: 'English',
          value: 'en',
          label: 'English',
          language: 'English',
          code: 'en'
        }
      ])

      change('preferred_currency', {
        name: 'UAE Dirham',
        value: 'AED',
        label: 'د.إ / AED',
        number: '784',
        symbol: 'د.إ'
      })
    }
    this.setState({ countResend: count })
    setTimeout(() => {
      this.duration()
    }, 1000)
  }

   duration = () => {
     const { expireDateOtp } = this.state
     const t1 = new Date()
     const t0 = expireDateOtp
     const d = (new Date(t0)) - (t1)
     const weekdays = Math.floor(d / 1000 / 60 / 60 / 24 / 7)
     const days = Math.floor(d / 1000 / 60 / 60 / 24 - weekdays * 7)
     const hours = Math.floor(d / 1000 / 60 / 60 - weekdays * 7 * 24 - days * 24)
     const minutes = Math.floor(d / 1000 / 60 - weekdays * 7 * 24 * 60 - days * 24 * 60 - hours * 60)
     const seconds = Math.floor(d / 1000 - weekdays * 7 * 24 * 60 * 60 - days * 24 * 60 * 60 - hours * 60 * 60 - minutes * 60)
     const t = {}
     ['weekdays', 'days', 'hours', 'minutes', 'seconds', 'milliseconds'].forEach(q => { if (eval(q) > 0) { t[q] = eval(q) } })
     //console.log('t===', t)
     const strData = `${t.hours > 0 ? t.hours + ' hours, ':''}${t.minutes > 0 ? t.minutes + ' minutes, ':''}${t.seconds > 0 ? t.seconds + ' seconds, ':''}`
     this.setState({ timeStr: strData })
     if (t.hours === 0 && t.minutes === 0 && t.seconds === 0) {
       this.setState({ countResend: 0 })
     }
     return strData
   }

   /* counterForDuration () {
     setTimeout(() => {
       this.duration()
     }, 500*1000)
   } */
   // componentDidUpdate = (props, nextprops) => {
   //   if (typeof this.props.initialValues.languages !== 'undefined') {
   //     if (this.props.initialValues.languages.length == 0) {
   //       this.props.change('languages', [
   //         {
   //           name: 'English',
   //           value: 'en',
   //           label: 'English',
   //           language: 'English',
   //           code: 'en'
   //         }
   //       ])
   //     }
   //   }
   //   /*
   //       if (this.props.initialValues.countryCode == "" || this.props.initialValues.countryCode == null) {
   //       if (typeof this.props.initialValues.countryCode != undefined &&  this.props.initialValues.countryCode == "" || this.props.initialValues.countryCode == null) {
   //         this.props.change('countryCode', {
   //           name: 'United Arab Emirates',
   //           value: "AE",
   //           label: "United Arab Emirates",
   //           countryName: "United Arab Emirates",
   //           code: "AE",
    
   //         })
   //         this.props.change('phoneCountryCode', "971");
   //       }
   //   */
   //   if (this.props.initialValues.languages && this.props.initialValues.languages.length == 0) {
   //     // if (typeof this.props.initialValues.preferred_currency != undefined &&  this.props.initialValues.preferred_currency == "") {
   //     this.props.change('preferred_currency', {
   //       name: 'UAE Dirham',
   //       value: 'AED',
   //       label: 'د.إ / AED',
   //       number: '784',
   //       symbol: 'د.إ'
   //     })
   //   }
   // }

  disableFields = fieldCollection => {
    const { currentUser } = this.props
    if (typeof currentUser === 'undefined' || !currentUser.isSignedIn) {
      return fieldCollection
    }
    const obj = fieldCollection
    const { attributes } = currentUser
    Object.keys(obj).map(key => {
      if (attributes[key] !== null) {
        /*  if (key == 'location') {
        } */
        obj[key].disabled = !!keysMatch(key, attributes)
      }
      return obj[key]
    })
    return obj
  }

  handleCountryCode = _e => {
    // console.log('e.value', e.value)
    // console.log('handleCountryCode')
    /* const { currentUser, countryOptions } = this.props
    if (this.props.countryOptions != undefined) {
      this.props.countryOptions.map(option => {
        if (option.alpha2 == e.value) {
          this.props.change('phoneCountryCode', option.country_code)
        }
      })
    } */
  }

  handleCountryCodeChange = _e => {
    // console.log('e.target.value handleCountryCodeChange', e.target.value)
    // this.props.change('phoneCountryCode', e.target.value)
  }

  setCookie = (cname, cvalue, exdays) => {
    const date = new Date()
    date.setTime(date.getTime() + Number(exdays) * 3600 * 1000)
    document.cookie = `${cname}=${cvalue}; path=/;expires = ${date.toGMTString()}`
    document.cookie = `expire_date_otp=${date.toGMTString()}; path=/;expires = ${date.toGMTString()}`
    this.setState({ expireDateOtp: date.toGMTString() })
    /*
    var d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    var expires = "expires="+ d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/"; */
  }

  getCookie = cname => {
    const name = `${cname}=`
    const decodedCookie = decodeURIComponent(document.cookie)
    const ca = decodedCookie.split(';')
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i]
      while (c.charAt(0) === ' ') {
        c = c.substring(1)
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length)
      }
    }
    return ''
  }

  setCookieCount = () => {
    const { currentUser } = this.props
    // event.preventDefault()
    /* const response = await this.props.sendVerifyMobileLink(
      currentUser.attributes.id,
      currentUser.attributes.phoneNumber,
      currentUser.attributes.phoneCountryCode
    ) */
    const key = `mobile-otp-${currentUser.attributes.phoneMetaData.dialCode}${currentUser.attributes.phoneNumber}`
    const add_value = []
    if (this.getCookie(key) !== '') {
      add_value.push(this.getCookie(key))
      add_value.push(moment().format('YYYY-MM-DD'))
    } else {
      add_value.push(moment().format('YYYY-MM-DD'))
    }
    this.setCookie(key, add_value, 4)
    let count = 0
    const clickArray = this.getCookie(key).split(',')

    Object.keys(clickArray).forEach(k => {
      if (
        moment(clickArray[k]).format('YYYY-MM-DD') ===
        moment().format('YYYY-MM-DD')
      ) {
        count++
      }
    })
    this.setState({ countResend: count })
  }

  render() {
    const { handleSubmit, submitting, validate, form, currentUser, dataError } = this.props
    const {
      personalFields,
      backgroundFields,
      addressFields,
      contactFields,
      email,
      phoneNumber,
      countResend,
      timeStr
    } = this.state

    const { birthday, gender, languages, preferredCurrency } = backgroundFields

    const {
      // countryCode,
      // city,
      address,
      postalCode,
      country_code
    } = addressFields.location
    // console.log('city', city, 'country_code', country_code)
    /* currentUser.attributes.city =
      currentUser.attributes.location && currentUser.attributes.location.city */
    /* currentUser.attributes.countryCode =
      currentUser.attributes.location &&
      currentUser.attributes.location.countryCode */
    currentUser.attributes.preferred_currency =
      currentUser.attributes.preferredCurrency
    currentUser.attributes.birthday = currentUser.attributes.birthday
      ? currentUser.attributes.birthday
      : '1970-01-01T17:30:00+05:30'
    let dialCode = ''
    if(currentUser && currentUser.attributes && currentUser.attributes.phoneMetaData && currentUser.attributes.phoneMetaData.dialCode) {
      dialCode = currentUser.attributes.phoneMetaData.dialCode
    }
    let formValues = { phoneNumber: '', phoneMetaData: {} }
    if (this.props.formState.accountEdit && this.props.formState.accountEdit.values) {
      formValues = this.props.formState.accountEdit.values
    }
    return (
      <form onSubmit={handleSubmit} id={form} className="">
        <div className="form-body form-body--full">
          <div className="grid grid--uniform">
            <div className="form-column grid__item large-up--one-quarter text-center">
              <AvatarUpload
                {...this.props}
                avatarUpload={this.props.updateAvatarStatus}
                changeAvatar={this.props.changeAvatar}
                goToNext={false}
                name="image"
                className="avatar-upload--small"
                selectCTA="Upload File"
              />
            </div>
            <div className="form-column grid__item small--one-whole large-up--three-quarters">
              <div className="grid grid--uniform">
                {/* Personal Info Fields */}
                <div className="grid__item  medium-up--one-third large-up--one-third">
                  <Field
                    name={personalFields.firstName.name}
                    component={fields.renderField}
                    disabled={submitting}
                    placeholder={personalFields.firstName.placeholder}
                    isClearable
                    type="text"
                    label="First Name *"
                    initialValue={personalFields.firstName.value}
                    inputType="fullText"
                    displayLabel={true}
                  />
                  {/* <fields.FieldSet
                    wrap
                    legend="Name *"
                    fields={personalFields}
                    validate={validate}
                    className="custom-margin flat"
                  /> */}
                </div>
                <div className="grid__item  medium-up--one-third large-up--one-third">
                  <Field
                    name={personalFields.lastName.name}
                    component={fields.renderField}
                    disabled={submitting}
                    placeholder={personalFields.lastName.placeholder}
                    isClearable
                    type="text"
                    label="Last Name *"
                    initialValue={personalFields.lastName.value}
                    inputType="fullText"
                    displayLabel={true}
                  />
                </div>
                {/* Gender */}
                <div className="grid__item medium-up--one-third large-up--one-third">
                  <Field
                    name="gender"
                    component={fields.renderSelectField}
                    disabled={submitting}
                    options={gender.options}
                    placeholder={gender.placeholder}
                    isClearable
                    type="select"
                    selectType="gender"
                    label="Gender *"
                    // value={gender.value}
                    initialValue={gender.value}
                  />
                </div>
              </div>

              <div className="grid grid--uniform">
                {/* Date of Birth */}
                <div className="grid__item medium-up--one-half large-up--one-third fullWidthDateInput">
                  {/* <label htmlFor="fields"> Your Name* </label> */}
                  <Field
                    // value={birthday.value}
                    initialValue={new Date("1970-01-01T17:30:00+05:30")}
                    name="birthday"
                    placeholder={birthday.placeholder}
                    past
                    onFocus={({ focused }) =>
                      this.setState(state => ({
                        ...state,
                        backgroundFields: {
                          ...state.backgroundFields,
                          birthday: {
                            ...state.backgroundFields.birthday,
                            focused
                          }
                        }
                      }))
                    }
                    focused={birthday.focused}
                    component={RenderDateField}
                    // onChange={(data)=>{ console.log('data=====', moment(data).format('MM/DD/YYYY'))}}
                    disabled={submitting}
                    type="date"
                    label="Date of Birth"
                  />
                </div>

                {/* Languages */}

                <div className="grid__item  medium-up--one-half large-up--two-thirds">
                  <Field
                    name="languages"
                    component={fields.renderSelectField}
                    disabled={submitting}
                    options={languages.options}
                    placeholder={languages.placeholder}
                    isClearable
                    isMulti
                    valueKey="code"
                    labelKey="language"
                    type="select"
                    selectType="languages"
                    label={`${languages.label} *`}
                  />
                </div>
              </div>

              <div className="grid grid--uniform">
                <div className="grid__item small--one-whole medium-up--full">
                  {/* <p
                    style={{
                      display: 'block',
                      marginBottom: '5px',
                      fontSize: '1rem',
                      fontWeight: 'bold',
                      color: '#667f8c',
                    }}
                  >
                    Personal Address
                  </p> */}
                  {/* Address Fields */}
                  {/* <fields.FieldSet
                    fields={addressFields}
                    validate={validate}
                    disabled={submitting}
                    parent="location"
                    wrap
                    legend="Personal Address"
                    className="flat"
                  /> */}
                  <div className="grid grid--uniform">
                    {/* <div className="grid__item medium-up--one-half large-up--two-thirds">
                      <Field
                        name="streetAddress"
                        component={fields.renderField}
                        disabled={submitting}
                        placeholder={address.placeholder}
                        isClearable
                        inputType="fullText"
                        label="street address"
                        // legend="Personal Address"
                      />
                    </div> */}

                    <div className="grid__item  medium-up--one-half large-up--one-third">
                      {/* <Field
                        name="city"
                        component={fields.renderSelectField}
                        disabled={submitting}
                        //value={city.value}
                        options={city.options}
                        placeholder={city.placeholder}
                        isClearable
                        type="select"
                        selectType="city"
                        // inputType="fullText"
                        label="City *"
                        displayLabel={true}
                      /> */}

                      <Field
                        name="city"
                        component={fields.renderField}
                        disabled={submitting}
                        type="text"
                        placeholder={city.placeholder}
                        isClearable
                        label="City *"
                        displayLabel
                        inputType="fullText"
                      />
                    </div>
                    <div className="grid__item  medium-up--one-half large-up--two-thirds">
                      <Field
                        name="countryCode"
                        component={fields.renderSelectField}
                        disabled={submitting}
                        // value={countryCode.value}
                        // options={countryCode.options}
                        options={this.props.countryOptions}
                        placeholder={countryCode.placeholder}
                        isClearable
                        type="select"
                        selectType="country"
                        label="Country *"
                        onChange={e => this.handleCountryCode(e)}
                      />
                    </div>
                  </div>
                  {/* <Field
                    name="postalCode"
                    component={fields.renderField}
                    disabled={submitting}
                    // value={postalCode.value}
                    placeholder={postalCode.placeholder}
                    isClearable
                    inputType="fullText"
                    label="postalCode"
                  /> */}
                </div>
                {/* Country for Personal Address */}
                {/* <div className="grid__item small--one-whole medium-up--full">
                  <Field
                    name="countryCode"
                    component={fields.renderSelectField}
                    disabled={submitting}
                    // value={countryCode.value}
                    options={countryCode.options}
                    placeholder={countryCode.placeholder}
                    isClearable
                    type="select"
                    selectType="country"
                    label="Country"
                  />
                </div> */}
              </div>

              {/* Email Field */}
              {/* <div className="form-group">
                <Field
                  name={email.name}
                  component={fields.renderField}
                  disabled
                  value={email.value}
                  placeholder={email.placeholder}
                  type={email.type}
                  autoComplete="off"
                  label={email.label}
                  field={email}
                  displayLabel={email.displayLabel}
                />
              </div> */}
              {/* Phone Field */}

              {/* <div className="grid grid--uniform">
                <div className="grid__item form_grid__item--input small--one-whole medium-up--one-half">
                  <Field
                    name={password.name}
                    component={fields.renderField}
                    disabled={password.disabled || submitting}
                    placeholder={password.placeholder}
                    type={password.type}
                    autoComplete="off"
                    label={password.label}
                    field={password}
                    displayLabel={password.displayLabel}
                  />
                </div>
                <div className="grid__item form_grid__item--input small--one-whole medium-up--one-half">
                  <Field
                    name={passwordConfirmation.name}
                    component={fields.renderField}
                    disabled={passwordConfirmation.disabled || submitting}
                    placeholder={passwordConfirmation.placeholder}
                    type={passwordConfirmation.type}
                    autoComplete={passwordConfirmation.autoComplete}
                    label={passwordConfirmation.label}
                    field={passwordConfirmation}
                    displayLabel={passwordConfirmation.displayLabel}
                  />
                </div>
              </div> */}

              {/* <input
                type="password"
                id="password"
                name="password"
                placeholder="Password"
                autoComplete="new-password"
                value={password}
                onChange={this.handleChange}
              /> */}

              <div className="grid grid--uniform">
                {this.props.displayCurrency ? (
                  <div className="grid__item medium-up--one-third large-up--one-third">
                    <Field
                      name="preferred_currency"
                      component={fields.renderSelectField}
                      disabled={submitting}
                      // value={preferredCurrency.value}
                      options={[]}
                      placeholder="Currency"
                      type="select"
                      selectType="preferredCurrency"
                      label="Currency *"
                      displayLabel
                    />
                  </div>
                ) : null}
              </div>

              <div className="grid grid--uniform">
                <div className={this.state.verifyPhone.show?"grid__item  medium-up--one-third large-up--one-third":"grid__item medium-up--one-half large-up--one-third"}>
                  <label className=" ">
                    {email.label}
                    <span className="verifyText">
                      {currentUser.attributes.confirmedAt !== null &&
                      currentUser.attributes.confirmedAt ? (
                          <React.Fragment>
                            <img
                              src={successIcon}
                              style={{ height: '20px', paddingRight: '10px' }}
                            />
                            <span className="success">Verified</span>
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            <img
                              src={errorIcon}
                              style={{ height: '20px', paddingRight: '10px' }}
                            />

                            <a
                              href="#"
                              className="verify-link"
                              style={{ textDecoration: 'underline' }}
                              onClick={async e => {
                                e.preventDefault()

                                const res = await this.props.sendVerifyEmailLink(
                                  currentUser.attributes.email
                                )
                                if (res.is_success) {
                                // this.setState({ show: true,confirmBox:false, veriFyEmailsent:true,showEmail: true });
                                  alertModal(
                                    `An email has been sent to ${currentUser.attributes.email} with email verification instructions`,
                                    'success',
                                    'Close'
                                  )
                                } else {
                                  alertModal(
                                    'There is some network issue please try again!',
                                    'error',
                                    'Close'
                                  )
                                }
                              }}
                            >
                              {' '}
                            Verify Email
                            </a>
                          </React.Fragment>
                        )}
                    </span>
                  </label>
                  <Field
                    name={email.name}
                    component={fields.renderField}
                    disabled
                    value={email.value}
                    placeholder={email.placeholder}
                    type={email.type}
                    autoComplete="off"
                    label={email.label}
                    field={email}
                    inputType="fullText"
                    displayLabel={false}
                  />
                </div>

                <div className={this.state.verifyPhone.show?"grid__item  medium-up--one-third large-up--one-third":"grid__item grid__item medium-up--one-half large-up--two-thirds "}>
                  <label className=" ">
                    {phoneNumber.label}

                    {currentUser.attributes.phoneCountryCode !== null &&
                    currentUser.attributes.phoneNumber !== null &&
                    currentUser.attributes.phoneCountryCode &&
                    currentUser.attributes.phoneNumber ? (
                        <span className="verifyText" style={{ paddingLeft: currentUser.attributes.phoneVerified?null:'0' }}>
                          {currentUser.attributes.phoneVerified !== null &&
                        currentUser.attributes.phoneVerified ? (
                              <React.Fragment>
                                <img
                                  src={successIcon}
                                  style={{ height: '20px', paddingRight: '10px' }}
                                />
                                <span className="success"> Verified</span>
                              </React.Fragment>
                            ) : (
                              <React.Fragment>
                                {/*  <img
                                  src={errorIcon}
                                  style={{ height: '20px', paddingRight: '10px' }}
                                /> */}
                                <span className="verify-link" style={{ color: 'red' }}>
                                  (Not Verified)
                                </span>
                              </React.Fragment>
                            )}
                        </span>
                      ) : (
                        ''
                      )}
                  </label>

                  {currentUser.attributes && currentUser.attributes.id ? (
                    <IntlTelInput
                      containerClassName="intl-tel-input fullWidth fullInputClass"
                      inputClassName="form-control"
                      preferredCountries={['ae', 'uk']}
                      defaultCountry={
                        currentUser.attributes.phoneMetaData != null
                          ? currentUser.attributes.phoneMetaData.iso2
                          : 'ae'
                      }
                      onSelectFlag={(phoneNumber, metadata)=>{
                        const {
                          areaCodes,
                          dialCode,
                          iso2,
                          name,
                          priority
                        } = metadata
                        
                        if (Number(phoneNumber.replace(/\D/g, '')) !== Number(currentUser.attributes.phoneNumber.replace(/\D/g, '')) || Number(dialCode) !== Number(currentUser.attributes.phoneMetaData.dialCode)) {
                          this.props.change('phoneNumber', phoneNumber)
                          this.props.change('phoneCountryCode', dialCode)
                          this.props.change('phoneMetaData', metadata)
                        }
                        if(this.state.phoneError !== '') {
                          this.setState({phoneError: ''})
                        } 
                        if (this.state.verifyPhone.show) {
                          if (Number(phoneNumber.replace(/\D/g, '')) !== Number(currentUser.attributes.phoneNumber.replace(/\D/g, '')) || Number(dialCode) !== Number(currentUser.attributes.phoneMetaData.dialCode)) {
                            this.setState({verifyPhone:{...this.state.verifyPhone, show: false}})
                          }
                        }
                      }}
                      onPhoneNumberChange={(flag, phoneNumber, metadata) => {
                        const {
                          areaCodes,
                          dialCode,
                          iso2,
                          name,
                          priority
                        } = metadata
                        if (Number(phoneNumber.replace(/\D/g, '')) !== Number(currentUser.attributes.phoneNumber.replace(/\D/g, '')) || Number(dialCode) !== Number(currentUser.attributes.phoneMetaData.dialCode)) {
                          this.props.change('phoneNumber', phoneNumber)
                          this.props.change('phoneCountryCode', dialCode)
                          this.props.change('phoneMetaData', metadata)
                        }
                        if(this.state.phoneError !== '') {
                          this.setState({phoneError: ''})
                        } 
                        if (this.state.verifyPhone.show) {
                          if (Number(phoneNumber.replace(/\D/g, '')) !== Number(currentUser.attributes.phoneNumber.replace(/\D/g, '')) || Number(dialCode) !== Number(currentUser.attributes.phoneMetaData.dialCode)) {
                            this.setState({verifyPhone:{...this.state.verifyPhone, show: false}})
                          }
                        }
                      }}
                      defaultValue={
                        currentUser.attributes.phoneNumber
                          ? currentUser.attributes.phoneNumber
                          : ''
                      }
                    />
                  ) : (
                    ''
                  )}
                  {this.state.phoneError || dataError ? (
                    <p>
                      <span className="form-error">
                        {this.state.phoneError?this.state.phoneError:''} {dataError && dataError.error_details?dataError.error_details:''}
                      </span>
                    </p>
                  ) : (
                    ''
                  )}
                  {!currentUser.attributes.phoneVerified
                    ? (
                      <div style={{ textAlign: 'left', marginTop: '12px' }}>
                        <button
                          href="#"
                          className="verify-link"
                          type="submit"
                          style={{ textDecoration: 'underline', border: 'none', backgroundColor: 'transparent', color: '#f8a48d' }}
                          onClick={async (e) => {
                            e.preventDefault()
                            /* this.setState({
                                  show: true,
                                  confirmBox: false,
                                  veriFyEmailsent: false,
                                  showEmail: false
                                }) */
                            if(Number(formValues.phoneNumber.replace(/\D/g, '')) === Number(currentUser.attributes.phoneNumber.replace(/\D/g, ''))
                              && Number(formValues.phoneMetaData.dialCode) === Number(currentUser.attributes.phoneMetaData.dialCode)) {
                              if (this.state.verifyPhone.resendCount === 0 && countResend < 5) {
                                await this.props.sendVerifyMobileLink(
                                  currentUser
                                )
                                
                                /* const key = `mobile-otp-${dialCode}${currentUser.attributes.phoneNumber}`
                                const value = [moment().format('YYYY-MM-DD')]
                                if (this.getCookie(key) == '') {
                                  this.setCookie(key, value, 24)
                                } */
                                this.setState({
                                  verifyPhone: { ...this.state.verifyPhone, show: true, resendCount: 1 }
                                })
                                const myVar = setInterval(() => {
                                  this.setState({
                                    verifyPhone: { ...this.state.verifyPhone, totalSeconds: this.state.verifyPhone.totalSeconds - 1 }
                                  })
                                }, 1000)
                                setTimeout(() => {
                                  this.setState({
                                    verifyPhone: { ...this.state.verifyPhone, resendCount: 0, totalSeconds: 30 }
                                  })
                                  clearInterval(myVar)
                                }, 30 * 1000)
                                this.setCookieCount()
                                // this.setState({ show: true, confirmBox: true })
                              } 
                            } else {
                              this.setState({
                                phoneError:
                                      'Please save this number first!'
                              })
                            }
                          }}
                        >
                          {' '}
                          Send OTP{this.state.verifyPhone.resendCount > 0 && countResend < 5? `, try after ${this.state.verifyPhone.totalSeconds} secs` : ''}
                          {countResend === 5 ? (<span onClick={()=>this.duration()} title="click here to know exact pending time.">, try after {timeStr} approx.</span>) : ''}
                        </button>
                      </div>
                    ) : ''}
                </div>
                {
                  this.state.verifyPhone.show?(<div className="grid__item  medium-up--one-third large-up--one-third">
                    <label>OTP</label>
                    <input
                      placeholder="Enter OTP"
                      name="verifyOtp"
                      type="text"
                      className=""
                      style={{width: "100%"}}
                      onChange={e => {
                        this.setState({
                          verifyOtp: e.target.value,
                          otPerror: ''
                        })
                      }}
                    />
                    {this.state.otPerror ? (
                    /*  <p> */
                      <span className="form-error">
                        {this.state.otPerror}
                      </span>
                    /*  </p> */
                    ) : (
                      ''
                    )}
                    <div style={{textAlign: "Right"}}>
                      <button
                        type="button"
                        className="btn"
                        style={{minWidth:0, marginTop: "10px", padding: "9px"}}
                        onClick={async e => {
                          e.preventDefault()
                          if (this.state.verifyOtp) {
                            try {
                              const response = await this.props.verifyMobile(
                                currentUser,
                                this.state.verifyOtp
                              )
                              if (!response.is_success) {
                                this.setState({ otPerror: 'Incorrect OTP!' })
                              } else {
                                this.setState({
                                  veriFyEmailsent: false,
                                  show: false,
                                  confirmBox: false,
                                  verifyOtp: '',
                                  otPerror: '',
                                  verifyPhone: {
                                    show: false,
                                    resendCount: 0,
                                    totalSeconds: 30
                                  }
                                })
  
                                alertModal(
                                  `Great Job! Your mobile number +${dialCode} ${currentUser.attributes.phoneNumber} has been verified`,
                                  'success',
                                  'Close'
                                )
                              }
                            } catch (error) {
                              this.setState({
                                otPerror:
                                      'There is some network issue please try again in some time!'
                              })
                            }
                          } else {
                            this.setState({ otPerror: 'Please enter OTP' })
                          }
                              
                        }}
                      >
                            Verify
                      </button>
                    </div>
                  </div>):''
                }
              </div>
              {/*  <Field
                  name="phoneMetaData"
                  component={fields.renderField}
                  type="hidden"
                  autoComplete="off"
                /> */}

              {/* <div className="grid grid--uniform">
                <div className="grid__item medium-up--one-half large-up--one-third country_code">
                  <Field
                    name="phoneCountryCode"
                    component={fields.renderField}
                    onChange={(e) => { this.handleCountryCodeChange(e) }}
                    // placeholder={postalCode.placeholder}
                    inputType="fullText"
                    label="Country Code"
                    displayLabel={true}
                  />
                </div>
                <div className="grid__item  medium-up--one-half large-up--two-thirds">
                <label  className=" ">{phoneNumber.label} 
                
                {currentUser.attributes.phoneCountryCode !== null && currentUser.attributes.phoneNumber !== null && currentUser.attributes.phoneCountryCode && currentUser.attributes.phoneNumber ?
                  <span className="verifyText">
                    {currentUser.attributes.phoneVerified !== null && currentUser.attributes.phoneVerified ?
                      <React.Fragment>
                        <img src={successIcon} style={{ height: "20px", paddingRight: "10px" }} />
                        <span className="success"> Verified</span>
                      </React.Fragment>
                      :
                      <React.Fragment>
                        <img src={errorIcon} style={{ height: "20px", paddingRight: "10px" }} />
                        <a href="#" className="verify-link" style={{ textDecoration: "underline" }} onClick={(e) => {
                          e.preventDefault();
                          this.setState({ show: true,confirmBox:false,veriFyEmailsent:false, showEmail: false });
                        }} > Verify Phone Number</a>
                      </React.Fragment>
                    }
                  </span> :
                  ""}
                
                </label>

                    <Field
                      name="phoneNumber"
                      component={fields.renderField}
                      // maxLength={10}
                      // disabled={true}
                      // value={phoneNumber.value}
                      placeholder={phoneNumber.placeholder}
                      type={phoneNumber.type}
                      autoComplete={phoneNumber.autoComplete}
                      label={phoneNumber.label}
                      //field="phone_number"
                      displayLabel={false}
                      inputType="phnNumber"
                    />
                </div>
              </div>
                  */}

              <div className="grid mtt-10 grid--uniform" />

              <div className="grid mtt-10 grid--uniform">
                <div className="grid__item medium-up--two-thirds verifyText mtt-10" />
              </div>
            </div>
          </div>
        </div>
      </form>
    )
  }
}

AccountDetails.defaultProps = {
  disableFields: true
}

AccountDetails.propTypes = {
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  valid: PropTypes.bool.isRequired,
  previousPage: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  currentUser: currentUserPropTypes.isRequired,
  disableFields: PropTypes.bool
}

const mapStateToProps = state => ({
  // forgotPassword: state.forgotPassword,
  formState: state.form,
  dataError: state.user.profile && state.user.profile.errorData ? state.user.profile.errorData : null,
  showPopup: state.forgotPassword.showPopup
})

const mapDispatchToProps = dispatch => ({
  sendVerifyEmailLink: state => dispatch(sendVerifyEmailLink(state)),
  sendVerifyMobileLink: state => dispatch(sendVerifyMobileLink(state)),
  verifyMobile: (id, otp) => dispatch(verifyMobile(id, otp))
})

export default connect(mapStateToProps, mapDispatchToProps)(AccountDetails)
// export default AccountDetails;
