import * as React from "react"

const Instagram = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 102 102" {...props}>
    <defs>
      <radialGradient
        id="a"
        cx={6.601}
        cy={99.766}
        r={129.502}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.09} stopColor="#fa8f21" />
        <stop offset={0.78} stopColor="#d82d7e" />
      </radialGradient>
      <radialGradient
        id="b"
        cx={70.652}
        cy={96.49}
        r={113.963}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.64} stopColor="#8c3aaa" stopOpacity={0} />
        <stop offset={1} stopColor="#8c3aaa" />
      </radialGradient>
    </defs>
    <path
      fill="url(#a)"
      d="M25.865 101.639A34.341 34.341 0 0 1 14.312 99.5a19.329 19.329 0 0 1-7.154-4.653A19.181 19.181 0 0 1 2.5 87.694 34.341 34.341 0 0 1 .364 76.142C.061 69.584 0 67.617 0 51s.067-18.577.361-25.14A34.534 34.534 0 0 1 2.5 14.312a19.4 19.4 0 0 1 4.654-7.158A19.206 19.206 0 0 1 14.309 2.5 34.341 34.341 0 0 1 25.862.361C32.422.061 34.392 0 51 0s18.577.067 25.14.361A34.534 34.534 0 0 1 87.691 2.5a19.254 19.254 0 0 1 7.154 4.653 19.267 19.267 0 0 1 4.655 7.156 34.341 34.341 0 0 1 2.14 11.553c.3 6.563.361 8.528.361 25.14s-.061 18.577-.361 25.14a34.5 34.5 0 0 1-2.14 11.552A20.6 20.6 0 0 1 87.691 99.5a34.342 34.342 0 0 1-11.553 2.14c-6.557.3-8.528.361-25.14.361s-18.577-.058-25.134-.361"
      data-name="Path 16"
    />
    <path
      fill="url(#b)"
      d="M25.865 101.639A34.341 34.341 0 0 1 14.312 99.5a19.329 19.329 0 0 1-7.154-4.653A19.181 19.181 0 0 1 2.5 87.694 34.341 34.341 0 0 1 .364 76.142C.061 69.584 0 67.617 0 51s.067-18.577.361-25.14A34.534 34.534 0 0 1 2.5 14.312a19.4 19.4 0 0 1 4.654-7.158A19.206 19.206 0 0 1 14.309 2.5 34.341 34.341 0 0 1 25.862.361C32.422.061 34.392 0 51 0s18.577.067 25.14.361A34.534 34.534 0 0 1 87.691 2.5a19.254 19.254 0 0 1 7.154 4.653 19.267 19.267 0 0 1 4.655 7.156 34.341 34.341 0 0 1 2.14 11.553c.3 6.563.361 8.528.361 25.14s-.061 18.577-.361 25.14a34.5 34.5 0 0 1-2.14 11.552A20.6 20.6 0 0 1 87.691 99.5a34.342 34.342 0 0 1-11.553 2.14c-6.557.3-8.528.361-25.14.361s-18.577-.058-25.134-.361"
      data-name="Path 17"
    />
    <path
      fill="#fff"
      d="M38.477 51.217a12.631 12.631 0 1 1 12.629 12.632 12.631 12.631 0 0 1-12.629-12.632m-6.829 0a19.458 19.458 0 1 0 19.458-19.458 19.457 19.457 0 0 0-19.458 19.458m35.139-20.229a4.547 4.547 0 1 0 4.549-4.545 4.549 4.549 0 0 0-4.547 4.545m-30.99 51.074a20.943 20.943 0 0 1-7.037-1.3 12.547 12.547 0 0 1-7.193-7.19 20.923 20.923 0 0 1-1.3-7.037c-.184-3.994-.22-5.194-.22-15.313s.04-11.316.22-15.314a21.082 21.082 0 0 1 1.3-7.037 12.54 12.54 0 0 1 7.193-7.193 20.924 20.924 0 0 1 7.037-1.3c3.994-.184 5.194-.22 15.309-.22s11.316.039 15.314.221a21.082 21.082 0 0 1 7.037 1.3 12.541 12.541 0 0 1 7.193 7.193 20.926 20.926 0 0 1 1.3 7.037c.184 4 .22 5.194.22 15.314s-.037 11.316-.22 15.314a21.023 21.023 0 0 1-1.3 7.037 12.547 12.547 0 0 1-7.193 7.19 20.925 20.925 0 0 1-7.037 1.3c-3.994.184-5.194.22-15.314.22s-11.316-.037-15.309-.22m-.314-68.509a27.786 27.786 0 0 0-9.2 1.76 19.373 19.373 0 0 0-11.083 11.083 27.794 27.794 0 0 0-1.76 9.2c-.187 4.04-.229 5.332-.229 15.623s.043 11.582.229 15.623a27.793 27.793 0 0 0 1.76 9.2 19.374 19.374 0 0 0 11.083 11.083 27.813 27.813 0 0 0 9.2 1.76c4.042.184 5.332.229 15.623.229s11.582-.043 15.623-.229a27.8 27.8 0 0 0 9.2-1.76 19.374 19.374 0 0 0 11.083-11.083 27.716 27.716 0 0 0 1.76-9.2c.184-4.043.226-5.332.226-15.623s-.043-11.582-.226-15.623a27.786 27.786 0 0 0-1.76-9.2 19.379 19.379 0 0 0-11.08-11.083 27.748 27.748 0 0 0-9.2-1.76c-4.041-.185-5.332-.229-15.621-.229s-11.583.043-15.626.229"
      data-name="Path 18"
    />
  </svg>
)

export default Instagram


// This is LINE ICON

// import * as React from "react"

// const Instagram = (props) => (
//   <svg
//     xmlns="http://www.w3.org/2000/svg"
//     viewBox="0 0 551.034 551.034"
//     width="25"
//     height="25"
//     style={{
//       enableBackground: "new 0 0 551.034 551.034",
//     }}
//     xmlSpace="preserve"
//     {...props}
//   >
//     <linearGradient
//       id="a"
//       gradientUnits="userSpaceOnUse"
//       x1={275.517}
//       y1={4.571}
//       x2={275.517}
//       y2={549.72}
//       gradientTransform="matrix(1 0 0 -1 0 554)"
//     >
//       <stop
//         offset={0}
//         style={{
//           stopColor: "#e09b3d",
//         }}
//       />
//       <stop
//         offset={0.3}
//         style={{
//           stopColor: "#c74c4d",
//         }}
//       />
//       <stop
//         offset={0.6}
//         style={{
//           stopColor: "#c21975",
//         }}
//       />
//       <stop
//         offset={1}
//         style={{
//           stopColor: "#7024c4",
//         }}
//       />
//     </linearGradient>
//     <path
//       style={{
//         fill: "url(#a)",
//       }}
//       d="M386.878 0H164.156C73.64 0 0 73.64 0 164.156v222.722c0 90.516 73.64 164.156 164.156 164.156h222.722c90.516 0 164.156-73.64 164.156-164.156V164.156C551.033 73.64 477.393 0 386.878 0zM495.6 386.878c0 60.045-48.677 108.722-108.722 108.722H164.156c-60.045 0-108.722-48.677-108.722-108.722V164.156c0-60.046 48.677-108.722 108.722-108.722h222.722c60.045 0 108.722 48.676 108.722 108.722v222.722z"
//     />
//     <linearGradient
//       id="b"
//       gradientUnits="userSpaceOnUse"
//       x1={275.517}
//       y1={4.571}
//       x2={275.517}
//       y2={549.72}
//       gradientTransform="matrix(1 0 0 -1 0 554)"
//     >
//       <stop
//         offset={0}
//         style={{
//           stopColor: "#e09b3d",
//         }}
//       />
//       <stop
//         offset={0.3}
//         style={{
//           stopColor: "#c74c4d",
//         }}
//       />
//       <stop
//         offset={0.6}
//         style={{
//           stopColor: "#c21975",
//         }}
//       />
//       <stop
//         offset={1}
//         style={{
//           stopColor: "#7024c4",
//         }}
//       />
//     </linearGradient>
//     <path
//       style={{
//         fill: "url(#b)",
//       }}
//       d="M275.517 133C196.933 133 133 196.933 133 275.516s63.933 142.517 142.517 142.517S418.034 354.1 418.034 275.516 354.101 133 275.517 133zm0 229.6c-48.095 0-87.083-38.988-87.083-87.083s38.989-87.083 87.083-87.083c48.095 0 87.083 38.988 87.083 87.083 0 48.094-38.989 87.083-87.083 87.083z"
//     />
//     <linearGradient
//       id="c"
//       gradientUnits="userSpaceOnUse"
//       x1={418.306}
//       y1={4.571}
//       x2={418.306}
//       y2={549.72}
//       gradientTransform="matrix(1 0 0 -1 0 554)"
//     >
//       <stop
//         offset={0}
//         style={{
//           stopColor: "#e09b3d",
//         }}
//       />
//       <stop
//         offset={0.3}
//         style={{
//           stopColor: "#c74c4d",
//         }}
//       />
//       <stop
//         offset={0.6}
//         style={{
//           stopColor: "#c21975",
//         }}
//       />
//       <stop
//         offset={1}
//         style={{
//           stopColor: "#7024c4",
//         }}
//       />
//     </linearGradient>
//     <circle
//       style={{
//         fill: "url(#c)",
//       }}
//       cx={418.306}
//       cy={134.072}
//       r={34.149}
//     />
//   </svg>
// )

// export default Instagram
