import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { reset } from 'redux-form'

import * as modalActionCreators from '../../../actions/modals'
import * as formActionCreators from '../../../actions/forms'
import * as userActionCreators from '../../../actions/user'
import * as transactionActionCreators from '../../../actions/transactions'

// Validations
import { currentUserPropTypes } from '../../../constants/composed-proptypes'

// Form Steps
import GuestReviewFirstPage from './Step1'

class GuestReviewStepForm extends Component {
  constructor(props) {
    super(props)
    this.closeAndExit = this.closeAndExit.bind(this)
  }

  handleGuestReviewData = async ({ data, reservation }) => {
    const { userActions, resetForm } = this.props
    const submitData = {
      ...data,
      reservation_id: reservation.id
    }

    resetForm()
    await userActions.reservationGuestReview(submitData)
    this.closeAndExit()
  };

  async closeAndExit() {
    const { modalActions, modalCallBack } = this.props
    await modalActions.hideGuestReviewModal()
    if (typeof modalCallBack !== 'undefined') {
      modalCallBack()
    }
  }

  async fetchGuestReview () {
    try {
      const { userActions } = this.props
      await userActions.getMyReviews()
    // eslint-disable-next-line no-empty
    } catch (error) {
    }
  }

  componentDidMount() {
    this.fetchGuestReview()
  }

  render() {
    const { currentUser, stepForm, reservation } = this.props
    let { page } = stepForm
    page = stepForm ? page : 1

    return (
      <div
        className="guest-review-item form-body form-body--full"
        key={reservation}
      >
        <GuestReviewFirstPage
          currentUser={currentUser}
          currentPage={page}
          reservationCount={1}
          guest={reservation.guest}
          experience={reservation.experience}
          reviewedByGuest={reservation.reviewedByGuest}
          reviewedByHost={reservation.reviewedByHost}
          guestReview={reservation.guestReview}
          hostReview={reservation.hostReview}
          reservation={reservation}
          exit={this.closeAndExit}
          onSubmit={data => {
            this.handleGuestReviewData({ data, reservation, page })
          }}
        />

        {currentUser.isLoading && <div className="spinner" />}
      </div>
    )
  }
}

GuestReviewStepForm.propTypes = {
  currentUser: currentUserPropTypes.isRequired,
  // experience: PropTypes.shape({}).isRequired,
  stepForm: PropTypes.shape({}).isRequired,
  userActions: PropTypes.shape({}).isRequired,
  modalActions: PropTypes.shape({}).isRequired,
  transactionActions: PropTypes.shape({}).isRequired,
  formActions: PropTypes.shape({}).isRequired,
  resetForm: PropTypes.func.isRequired
}

const mapDispatchToProps = dispatch => ({
  formActions: bindActionCreators(formActionCreators, dispatch),
  userActions: bindActionCreators(userActionCreators, dispatch),
  modalActions: bindActionCreators(modalActionCreators, dispatch),
  transactionActions: bindActionCreators(transactionActionCreators, dispatch),
  resetForm: () => dispatch(reset('guestReview'))
})

const mapStateToProps = state => ({
  pathname: state.router.location.pathname,
  currentUser: state.reduxTokenAuth.currentUser,
  stepForm: state.stepForm.hostReview,
  reviews: state.host.reviews,
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GuestReviewStepForm)
