import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { bindActionCreators } from 'redux'

/* Components */
import ReactTable from 'react-table'
import ViewReceipt from './ViewReceipt'

/* Action Creators */
import * as userActionCreators from '../../actions/user'
import * as transactionActionCreators from '../../actions/transactions'

/* Styles */
import 'react-table/react-table.css'

const columns = [
  {
    id: 'event-date',
    Header: 'Date',
    accessor:  e => `${moment(e.eventDate).format('MM/DD/YY')}` // String-based value accessors!
  },
  {
    id: 'experienceTitle', // Required because our accessor is not a string
    Header: 'Experience',
    accessor: d => d.experience.title, // String-based value accessors!
    className: 'padded-cells customBold'
  },
  {
    id: 'payTo',
    Header: 'Pay to',
    accessor: d => d.experience.host.fullname // Custom value accessors!
  },
  {

    id: 'totalAmt',
    Header: 'Amount',
    accessor: d => d.experience.price * d.seatsBooked, // String-based value accessors!
    Cell: props => (
      <span className="number">
        {props.value} {props.original.experience.currency}{' '}
      </span>
    )
  },
  {
    Header: 'Details',
    Cell: ({ row }) => (
      <ViewReceipt
        className="breakbread-btn btn btn--dark btn-sm btn-Mycolor"
        item={row._original}
      >
        View Receipts
      </ViewReceipt>
    )
  }
  // {
  //   Header: 'Status',
  //   accessor: 'status', // String-based value accessors!
  // },
]

class TransactionHistory extends React.Component {
  componentWillMount() {
    const { transactionActions, reservations } = this.props
    transactionActions.fetchReservationsMade()
  }

  // props.transactionActions.payments.fetchPaymentsMade();
  render() {
    const { reservations, fetchStatus } = this.props
    if (fetchStatus === 'error') {
      return (
        <div className="text-error error">
          There was an error fetching your transactions
        </div>
      )
    }

    if (fetchStatus !== 'success') {
      return <div className="spinner" />
    }

    if (reservations && reservations.length > 0) {
      return <ReactTable 
        defaultPageSize={reservations.length < 20 ? 5 : 20}
        pageSizeOptions = {[20,25, 30,35,40]} 
        data={reservations} columns={columns} />
    }
    return (
      <div className="text-center text-warning">
        <div className="section-header section-heading">
          <p style={{ fontSize: '20px' }}>You don't have any reservation transactions yet</p>
        </div>
        <p>
          <Link to="/experiences" className="btn">
            Explore Experiences
          </Link>
        </p>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  transactions: state.user.transactions,
  reservations: state.transactions.reservations.made.data,
  fetchStatus: state.transactions.reservations.made.status
})

const mapDispatchToProps = dispatch => ({
  userActions: bindActionCreators(userActionCreators, dispatch),
  transactionActions: bindActionCreators(transactionActionCreators, dispatch)
})

// You have to connect() to any reducers that you wish to connect to yourself
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TransactionHistory)
