import React, { Component } from 'react'
import { connect } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'

// import Intercom, { IntercomAPI } from 'react-intercom'
// import { hotjar } from 'react-hotjar'
// import LogRocket from 'logrocket'
// import setupLogRocketReact from 'logrocket-react'

// import Icon from 'react-native-vector-icons/Ionicons';
import WebRoutes from './WebRoutes'
import Main from '../containers/Main'
import ScrollToTopOnMount from '../components/Shared/Web/ScrollToTopOnMount'

import { history } from '../configureStore'

import { refreshPage } from '../actions/nav'

const getConfirmation = (message, callback) => {
  // Useful discussions on this
  // https://github.com/ReactTraining/react-router/issues/4635#issuecomment-300465164
  // https://github.com/ReactTraining/react-router/issues/4635#issuecomment-297828995
  const allowTransition = window.confirm(message)
  callback(allowTransition)
}
const supportsHistory = 'pushState' in window.history

class AppWithNavigationState extends Component {
  componentDidMount() {
    const { currentUser } = this.props
    
    // TODO: Protect and better handle these env variables d

    // if (process.env.NODE_ENV !== 'development') {
    //   // hotjar.initialize(1393690, 6)
    //   LogRocket.init('lbgqml/breakbread-react-dev') // DEV PROJECT
    //   setupLogRocketReact(LogRocket)
    // }
    // else {
    //   LogRocket.init('lbgqml/breakbread-react') // PROD PROJECT
    //   setupLogRocketReact(LogRocket)
    // }

    //hotjar.initialize(1393690, 6)
    //LogRocket.init('lbgqml/breakbread-react') // PROD PROJECT
    //setupLogRocketReact(LogRocket)
    
    // if (process.env.NODE_ENV !== 'development') {
    //   hotjar.initialize(1393690, 6)
    //   LogRocket.init('lbgqml/breakbread-react-dev') // DEV PROJECT
    //   setupLogRocketReact(LogRocket)
    // }

    // if (process.env.NODE_ENV !== 'development') {
    //   // hotjar.initialize(1393690, 6)
    //   LogRocket.init('lbgqml/breakbread-react-dev') // DEV PROJECT
    //   setupLogRocketReact(LogRocket)
    // }
  }

  componentDidUpdate(prevProps) {
    const { currentUser } = this.props
    const user = {
      user_id: currentUser.attributes.id,
      email: currentUser.attributes.email,
      name: currentUser.attributes.fullname
    }

    // if (currentUser.attributes.id) {
    //   LogRocket.identify(`${currentUser.attributes.id}`, {
    //     name: `${currentUser.attributes.fullName}`,
    //     email: `${currentUser.attributes.email}`,
    //     role: `${currentUser.attributes.role}`
    //   })
    // }

    if (
      currentUser !== prevProps.currentUser &&
      typeof window !== 'undefined'
    ) {
      // IntercomAPI('update', user);
    }
  }

  shouldComponentUpdate = (nextProps, nextState) => {
    if (this.props.currentUser.attributes.id != nextProps.currentUser.attributes.id) {
      return true
    } else {
      return false
    }
  }

  render() {
    const { currentUser, refreshPage } = this.props
    refreshPage();
    const user = {
      user_id: currentUser.attributes.id,
      email: currentUser.attributes.email,
      name: currentUser.attributes.fullname
    }
    return (
      <ConnectedRouter
        history={history}
        basename="/"
        forceRefresh={!supportsHistory || false}
        getUserConfirmation={getConfirmation}
        onUpdate={() => window.scrollTo(0, 0)}
      >
        <Main {...this.props}>
          <ScrollToTopOnMount />
          <WebRoutes />
          {/* <Intercom appID="ys0r2o11" {...user} /> */}
        </Main>
      </ConnectedRouter>
    )
  }
}

const mapStateToProps = state => ({
  currentUser: state.reduxTokenAuth.currentUser
})

const mapDispatchToProps = dispatch => ({
  refreshPage: state => dispatch(refreshPage())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AppWithNavigationState)
