import api from '../utils/api'

import { SET_BLOGS, SET_BLOG, BLOG_LOADING } from '../constants/actionTypes'

import { normalizeFeatured } from '../utils/normalization'

import { snakeToCamel } from '../utils/camelSnake'

export function setBlogs(blogItems, meta) {
  return { type: SET_BLOGS, blogItems, meta }
}

export function setBlog(blog) {
  return { type: SET_BLOG, blog }
}

export function setBlogLoading(loading) {
  return { type: BLOG_LOADING, loading }
}

export function getBlogs(page) {
  return dispatch => {
    dispatch(setBlogLoading(true))
    api(`/blog?per_page=9${page?'&page='+page:''}`)
      .then(response => {
        const json = response.data
        if (json.meta.is_success) {
          dispatch(setBlogs(snakeToCamel(json.blogs, 2), snakeToCamel(json.meta, 2)))
          dispatch(setBlogLoading(false))
        } else {
          dispatch(setBlogLoading(false))
          // dispatch(setBlogs(snakeToCamel(json.blogs)))
        }
      })
      .catch(e => console.log(e)) /* eslint-disable-line no-console */
  }
}

export function getBlog(slug) {
  return dispatch => {
    dispatch(setBlogLoading(true))
    api(`/blog/${slug}`)
      .then(response => {
        const json = response.data
        if (json.is_success) {
          dispatch(setBlog(snakeToCamel(json.blog, 2)))
          dispatch(setBlogLoading(false))
        } else {
          dispatch(setBlogLoading(false))
          // dispatch(setBlogs(snakeToCamel(json.blogs)))
        }
      })
      .catch(e => console.log(e)) /* eslint-disable-line no-console */
  }
}