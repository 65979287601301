import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import alertModal from '../../utils/alert'
/* Constants */
import { currentUserPropTypes } from '../../constants/composed-proptypes'

/* Actions */
import * as modalActionCreators from '../../actions/modals'
import * as signUpActionCreators from '../../actions/signUp'
import HomeScreen from '../../components/PagesMarketing/HomeScreen'
class SignIn extends Component {

  constructor(props) {
    super(props)
  }

      componentDidMount=()=>{
        window.scrollTo(0, 0)
        this.props.modalActions.showAuthModal({
          show: true,
          modalCallBack: () => {
            // This gets added to Modal object in Redux as a prop. Can then be used
            // to send the user to a previous state, next state and/or close the modal.
            /*alertModal(
                'Yay! You have succesfully logged in',
                'success',
                'continue'
              );
              */
            this.props.history.push("/")
              
          }
        })
      }
      
      render() {

        return (
          <HomeScreen pageType={"sign-in"} />
        )
      }
}

  
const mapStateToProps = state => ({
  currentUser: state.reduxTokenAuth.currentUser
})
  
const mapDispatchToProps = dispatch => ({
  modalActions: bindActionCreators(modalActionCreators, dispatch),
  signUpActions: bindActionCreators(signUpActionCreators, dispatch)
  /** NOTE: This would also be a nice way of doing it
     * https://github.com/reduxjs/react-redux/blob/master/docs/api.md#inject-todos-and-all-todoactioncreators-and-counteractioncreators-directly-as-props
     *
     * actions: bindActionCreators(Object.assign({}, modalActions), dispatch)
     */
})
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignIn)
  