import React from 'react'
import Modal from 'react-bootstrap/lib/Modal'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { signOutUser } from '../../actions/sessions'
import { hideRegHostModal, showAuthModal } from '../../actions/modals'
// import { SPLIT_GRID_SIZE } from '../../constants';

// Registration
import StepReservationForm from '../Workflows/Reservation/StepReservationForm'

// New Stripe Payment Component
import StripePayment from '../Workflows/Reservation/StripePayment'
// Stripe Wrapper
import StripeWrapper from '../CreditCard/StripeWrapper'

const HostModal = props => {
  const { show, closeModal, openAuthModal, stepForm } = props

  const stepCount = [1, 2, 3]
  const modalSize = 'large'
  return (
    <Modal
      show={show}
      onHide={() => closeModal()}
      animation={false}
      bsSize={modalSize}
    >
      <Modal.Header closeButton className="customHeader">
        <h5>Confirm Payment</h5>
      </Modal.Header>
      <Modal.Body>
        {/* <StepReservationForm /> */}
        <StripeWrapper>
          <StripePayment />
        </StripeWrapper>
      </Modal.Body>
      {/* {stepForm.page <= 1 && (
        <Modal.Footer>
          <div className="text-center">
            <div className="text-center">
              <button
                type="button"
                className="btn btn--link"
                onClick={() => openAuthModal({ show: true })}
              >
                Already have an account? Sign In
              </button>
            </div>
          </div>
        </Modal.Footer>
      )} */}
    </Modal>
  )
}

HostModal.defaultProps = {
  stepForm: {
    page: 1
  }
}

HostModal.propTypes = {
  show: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  openAuthModal: PropTypes.func.isRequired,
  stepForm: PropTypes.shape({})
}

const mapStateToProps = state => ({
  currentUser: state.reduxTokenAuth.currentUser,
  stepForm: state.stepForm.hostRegistration
})

const mapDispatchToProps = dispatch => ({
  openAuthModal: state => dispatch(showAuthModal(state)),
  closeModal: () => dispatch(hideRegHostModal()),
  signOut: user => dispatch(signOutUser(user))
})
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HostModal)
