import React from 'react'
import Modal from 'react-bootstrap/lib/Modal'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'

/* Components */
import ScheduleDetails from '../Receipt/ScheduleDetails'
/* Actions */
import * as modalActionCreators from '../../actions/modals'

const ReceiptModal = props => {
  const { show, modalActions, item } = props
  return (
    <Modal
      show={show}
      onHide={() => modalActions.hideExpScheduleModal()}
      animation={false}
      bsSize="large"
      className="receipt-modal"
    >
      <Modal.Header closeButton className="customHeader">
        <h5>{ item.title }</h5>
      </Modal.Header>
      <Modal.Body>
        <ScheduleDetails {...props} />
      </Modal.Body>
    </Modal>
  )
}

ReceiptModal.propTypes = {
  modalActions: PropTypes.shape({}).isRequired,
  show: PropTypes.bool.isRequired,
  item: PropTypes.object
}
const mapStateToProps = state => ({
  currentUser: state.reduxTokenAuth.currentUser
})

const mapDispatchToProps = dispatch => ({
  modalActions: bindActionCreators(modalActionCreators, dispatch)
})
export default connect(mapStateToProps, mapDispatchToProps)(ReceiptModal)
