import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import 'react-placeholder/lib/reactPlaceholder.css'

import Review from '../../Review/ReviewItem'

const ReviewsAboutMe = props => {
  const { reviews } = props

  if (!reviews.length) {
    return (
      <div className="text-center text-warning">
        <div className="section-header section-heading">
          <h5>None of your guests have left reviews for you yet.</h5>
        </div>
        <p>
          <Link to="/experiences" className="btn">
            Explore Experiences
          </Link>
        </p>
      </div>
    )
  }

  return (
    <div className="breakbread-container">
      <h5 className="text-center">What hosts are saying about you:</h5>
      {typeof reviews !== 'undefined' &&
        reviews.length &&
        reviews.map(review => (
          <Review
            key={"Review-"+review.id}
            review={review}
            user={review.guest}
            notFoundMessage="You don't have any reviews."
            addPlaceholder
          />
        ))}
    </div>
  )
}

ReviewsAboutMe.propTypes = {
  reviews: PropTypes.arrayOf(PropTypes.shape({})).isRequired
}

export default ReviewsAboutMe
