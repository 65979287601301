import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import * as modalActionCreators from '../../../actions/modals'
import * as experienceActionCreators from '../../../actions/experience'
import * as formActionCreators from '../../../actions/forms'
import * as hostActionCreators from '../../../actions/host'
import * as userActionCreators from '../../../actions/user'

// Validations
import { currentUserPropTypes } from '../../../constants/composed-proptypes'

/* import {
  normalizeExperienceData,
  normalizeHostData
} from '../../../utils/normalization'

import { handleUpdateHost } from '../../Registration/Common/utils' */

// Form Steps
import ExperienceFirstPage from './Step1'
import ExperienceSecondPage from './Step2'
import ExperienceThirdPage from './Step3'

class Experience extends Component {
  constructor(props) {
    super(props)
    this.nextPage = this.nextPage.bind(this)
    this.previousPage = this.previousPage.bind(this)
    this.submitAsDraft = this.submitAsDraft.bind(this)
    this.state={
      currentExperience: null,
      currentExperienceMenu: null
    }
  }

  handleExperienceData = async data => {
    const { experienceActions } = this.props
    const experienceData = data
    return experienceActions.editExperience(experienceData)
  };

  handleEditExperienceData = async data => {
    const { experienceActions, hostActions } = this.props
    // console.log('data==', data)
    const experienceData = 
     {
       id: data.id,
       title: data.title,
       time: data.time && data.time.value?data.time.value:data.time,
       duration: data.duration && data.duration.value?data.duration.value:data.duration,
       location_id: data.location_id && data.location_id.id?data.location_id.id:data.location_id,
       instant: data.instant,
       price:data.price,
       currency:data.currency && data.currency.value?data.currency.value:data.currency,
       seats:data.seats && data.seats.value?data.seats.value:data.seats,
       notes:data.notes,
       description:data.description,
       summary:data.summary,
       experience_type_id: data.experienceType.id
     }
    const a = await experienceActions.createNewExperience({
      experienceData
    })
    hostActions.getExperiences()
    this.setState({currentExperience: a.data.experience})
    return a 
  };

   handleCreateMenuForExperience = async experienceData => {
     const { experienceActions, hostActions } = this.props
     const response = await experienceActions.createMenuForExperience({
       experienceData
     })
     // hostActions.getExperiences()
     await this.setState({currentExperienceMenu: response.data.experience})
     return response
   }

  componentDidMount = async () => {
    await this.props.userActions.getCuisineTypesList()
    await this.props.userActions.getMenuAllergenList()
    await this.props.userActions.getMealCategoryList()
    await this.props.userActions.getMenuCourseList()
    await this.props.hostActions.getLocations()
  }

  closeAndExit() {
    const { modalActions, hostActions } = this.props
    hostActions.getExperiences()
    modalActions.hideEditExperienceModal()
  }

  nextPage() {
    const { stepForm, formActions } = this.props
    formActions.updateExperienceFormStep(stepForm.page + 1)
  }

  previousPage() {
    // const { page } = this.state;
    // this.setState({ page: page - 1 });
    const { stepForm, formActions } = this.props
    formActions.updateExperienceFormStep(stepForm.page - 1)
  }

  async submitAsDraft(formValues, submit) {
    const { experienceActions } = this.props
    let type = 'draft'
    if (submit) {
      type = 'submit'
    }
    await experienceActions.submitAsDraftSubmit({
      formValues,
      type
    }) 
    this.closeAndExit()
  }

  render() {
    const { currentUser, stepForm, cuisineTypesOptions, menuAllergenOptions, mealCategoriesOptions, menuCoursesOptions, userActions, isUpdating } = this.props
    const { page } = stepForm
    return (
      <React.Fragment>
        {page === 1 && (
          <ExperienceFirstPage
            isUpdating={isUpdating}
            currentUser={currentUser}
            currentExperience={this.state.currentExperience}
            previousPage={this.previousPage}
            onSubmit={async data => {
              await this.handleEditExperienceData(data)
              this.nextPage()
            }}
            nextPage={() => this.nextPage()}
          />
        )}
        {page === 2 && (
          <ExperienceSecondPage
            isUpdating={isUpdating}
            nextPage={this.nextPage}
            previousPage={this.previousPage}
            cuisineTypesOptions={cuisineTypesOptions}
            menuAllergenOptions={menuAllergenOptions}
            mealCategoriesOptions={mealCategoriesOptions}
            currentExperienceMenu={this.state.currentExperienceMenu}
            menuCoursesOptions={menuCoursesOptions}
            userActions={userActions}
            onSubmit={async data => {
              await this.handleCreateMenuForExperience(data)
              this.setState({currentExperienceMenu: null})
              this.nextPage()
              // this.closeAndExit();
            }}
            currentUser={currentUser}
          />
        )}
        {page === 3 && (
          <ExperienceThirdPage
            isUpdating={isUpdating}
            nextPage={this.nextPage}
            previousPage={this.previousPage}
            submitAsDraft={this.submitAsDraft}
            userActions={userActions}
            onSubmit={async data => {
              await this.submitAsDraft(data, 'submit')
              // this.closeAndExit()
            }}
            currentUser={currentUser}
          />
        )}
      </React.Fragment>
    )
  }
}

Experience.propTypes = {
  currentUser: currentUserPropTypes.isRequired,
  stepForm: PropTypes.shape({}).isRequired,
  formActions: PropTypes.shape({}).isRequired,
  experienceActions: PropTypes.shape({}).isRequired,
  modalActions: PropTypes.shape({}).isRequired,
  hostActions: PropTypes.shape({}).isRequired
}

const mapDispatchToProps = dispatch => ({
  formActions: bindActionCreators(formActionCreators, dispatch),
  experienceActions: bindActionCreators(experienceActionCreators, dispatch),
  modalActions: bindActionCreators(modalActionCreators, dispatch),
  hostActions: bindActionCreators(hostActionCreators, dispatch),
  userActions: bindActionCreators(userActionCreators, dispatch)
})

const mapStateToProps = state => ({
  pathname: state.router.location.pathname,
  currentUser: state.reduxTokenAuth.currentUser,
  stepForm: state.stepForm.experience,
  isUpdating: state.experience.experienceSingle.updating,
  cuisineTypesOptions: state.user.amenitiesAndWowElements.cuisineTypes
    ? state.user.amenitiesAndWowElements.cuisineTypes.list
    : null,
  menuAllergenOptions: state.user.amenitiesAndWowElements.menuAllergens
    ? state.user.amenitiesAndWowElements.menuAllergens.list
    : null,
  mealCategoriesOptions: state.user.amenitiesAndWowElements.mealCategories
    ? state.user.amenitiesAndWowElements.mealCategories.list
    : null,
  menuCoursesOptions: state.user.amenitiesAndWowElements.menuCourses
    ? state.user.amenitiesAndWowElements.menuCourses.list
    : null
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Experience)
