import React from "react"

const Sugar = props => (
  <svg viewBox="0 0 88 88" width="1em" height="1em" {...props}>
    <g fill="currentColor" data-name="Layer 2">
      <path d="M58.49 45.15L52 41.42V33a1 1 0 0 0-.51-.85l-7-4a1 1 0 0 0-1 0l-7 4A1 1 0 0 0 36 33v8.42l-6.5 3.71a1 1 0 0 0-.5.87v9a1 1 0 0 0 .5.87l7 4a1.09 1.09 0 0 0 .5.13 1 1 0 0 0 .5-.13l6.5-3.72 6.5 3.72a1.09 1.09 0 0 0 .5.13 1 1 0 0 0 .5-.13l7-4A1 1 0 0 0 59 55v-9a1 1 0 0 0-.51-.85zM56 46l-5 2.85L47.68 47 46 46l1.66-1L51 43.15zm-11-8.42l5-2.86v6.7l-5 2.86zm-1-7.43L49 33l-5 2.85L40.68 34 39 33zm-7 13l3.32 1.9L42 46l-1.54.88-3.44 2L32 46zm6 11.27l-6 3.43-6-3.43v-6.7l5 2.86V55a1 1 0 0 0 2 0v-4.42l5-2.86zM39 42l-1-.6v-6.7l4 2.3 1 .57v6.7zm18 12.4l-6 3.43-6-3.43v-6.7l5 2.86V55a1 1 0 0 0 2 0v-4.42l5-2.86z" />
      <path d="M44 88a44 44 0 1 1 44-44 44.05 44.05 0 0 1-44 44zm0-86a42 42 0 1 0 42 42A42 42 0 0 0 44 2z" />
    </g>
  </svg>
)

export default Sugar
