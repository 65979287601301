import React, { Component } from 'react'
// Styles
import '../../scss/styleguide.css'

// Containers
import Main from '../../containers/Main'

// Images
import BreadInHand from '../../images/home/home-hero@2x.png'

import ExperienceImage from '../../images/placeholders/featured_experience_image@2x.png'
import ExperienceImage1 from '../../images/placeholders/experience_image_1@2x.png'
import ExperienceImage2 from '../../images/placeholders/experience_image_2@2x.png'
import ExperienceImage3 from '../../images/placeholders/experience_image_3@2x.png'

import PlatedRisotto from '../../images/lifestyle/plated-risotto@2x.png'

import ChefAvatar from '../../images/placeholders/chef-avatar@2x.png'
import ChefAvatar1 from '../../images/placeholders/chef-avatar-1@2x.png'
import ChefAvatar2 from '../../images/placeholders/chef-avatar-2@2x.png'

import ValueProp1 from '../../images/lifestyle/value-prop-1.png'
import ValueProp2 from '../../images/lifestyle/value-prop-2.png'
import ValueProp3 from '../../images/lifestyle/value-prop-3.png'

// Icon Components
import * as Icon from '../../icons/allergies_and_diets/icons'

// Components
import Typography from './Typography'
import Hero from '../Shared/Web/Hero'
import ExperienceCard from '../Experiences/ExperienceCard'
import ChefOrHostCard from '../Chef/ChefOrHostCard'

import ThumbLink from '../Shared/Web/ThumbLink'
import ValueProp from '../Shared/Web/ValueProp'

// Sections
import BackgroundSection from '../Sections/BackgroundSection'

// Placeholder Data
import {
  experience,
  experience1,
  experience2,
  experience3
} from '../../constants/mockdata'
import SEO from '../Shared/Web/SEO'

export default class ComponentsAndElements extends Component {
  componentDidMount() {}

  componentWillUnmount() {}

  render() {
    const gridSize =
      'grid__item small--one-whole medium-down--one-half medium--one-half large-up--one-third'
    const featuredGridSize =
      'grid__item small--one-whole medium-down--one-whole medium--one-whole large-up--one-whole'
    const gridSizeThumbs =
      'grid__item small--one-whole medium-down--one-half medium-up--one-half'
    return (
      <main className="main-content style-guide" id="MainContent" role="main">
        <SEO title="Styles" />
        <section className="breakbread-section">
          <div className="breakbread-container">
            <div className="section-header text-center">
              <h1 className="page-header">
                Component List and Elements
              </h1>
              <p>
                This page is here to show off components and elements like hero
                units, <span>grid</span> elements and more.{' '}
                <code>
                  components/Shared/Web/styleguides/ComponentsAndElements.js
                </code>.
              </p>
            </div>
          </div>
          <hr />
          <div className="breakbread-container">
            <div className="design-collective--docs-section">
              <h1 id="type">
                Hero Units<small> States</small>
              </h1>
              <p>
                This hero unit can be placed inside or outside of the containers
                so that it can be flush with the screen.
              </p>
              <Hero
                title="Dark Background / Light Text"
                subtitle="Use a hero to display a section with an image, text and parallax"
                buttonCTA="Button Cta"
                heroImage={BreadInHand}
                textPosition="left"
                theme="dark"
                mobileHeroImage={BreadInHand}
              />

              <hr />
              <Hero
                title="Light Background / Dark Text"
                subtitle="Use a hero to display a section with an image, text and parallax"
                buttonCTA="Button Cta"
                heroImage={ExperienceImage1}
                textPosition="right"
                theme="light"
                mobileHeroImage={ExperienceImage1}
              />

              <hr />
              <Hero
                title="Centered Text State"
                subtitle="Use a hero to display a section with an image, text and parallax"
                buttonCTA="Button Cta"
                heroImage={ExperienceImage2}
                textPosition="center"
                theme="dark"
                mobileHeroImage={ExperienceImage2}
              />
            </div>
            <hr />
          </div>
          <div className="breakbread-container">
            <div className="design-collective--docs-section">
              <h2 id="type">
                Experience Card <small> Featured </small>
              </h2>
              <div className="grid grid--uniform">
                <div className={featuredGridSize}>
                  <ExperienceCard experience={experience} featured />
                </div>
              </div>
            </div>
          </div>
          <div className="breakbread-container">
            <div className="design-collective--docs-section">
              <h2 id="experience-card">
                Experience Card <small> States</small>
              </h2>
              <div className="grid grid--uniform">
                <div className={gridSize}>
                  <h5 id="type">
                    Default <small> Card</small>
                  </h5>
                  <ExperienceCard experience={experience1} />
                </div>
                <div className={gridSize}>
                  <h5 id="type">
                    Hover <small> Card</small>
                  </h5>
                  <ExperienceCard experience={experience2} forceHoverState />
                </div>
                <div className={gridSize}>
                  <h5 id="type">
                    Hide Avatar <small> Card</small>
                  </h5>
                  <ExperienceCard experience={experience3} hideAvatar />
                </div>
              </div>
            </div>
            <hr />
          </div>
          <div className="breakbread-container">
            <div className="design-collective--docs-section">
              <h2 id="chef-or-host">Chef / Host</h2>
              <div className="grid grid--uniform">
                <div className={gridSize}>
                  <ChefOrHostCard person={experience.host} />
                </div>
                <div className={gridSize}>
                  <ChefOrHostCard person={experience1.host} />
                </div>
                <div className={gridSize}>
                  <ChefOrHostCard person={experience3.host} />
                </div>
              </div>
            </div>
            <hr />
          </div>
          <div className="breakbread-container">
            <h2 id="chef-or-host">
              BackgroundSection <small>Container</small>
            </h2>
            <p>
              Chooose from the following classes to make up the different
              heights. <code>x-small small medium large x-large </code>
            </p>
            <p>
              Use the <code>image</code> property to add a background image to
              the container. Then add any elements as children of the{' '}
              <code>BackgroundSection</code> component. The content will
              automatically be vertically centered. For now we have chosen to
              always center the content hortizontally.
            </p>

            <BackgroundSection size="x-large" image={PlatedRisotto}>
              <h2>Any type of content can go in this box</h2>
              <div className="mega-subtitle">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam
                  faucibus maximus nulla vitae tristique. Nulla facilisi.
                  Curabitur tellus diam, porttitor in ligula quis.
                </p>
              </div>
              <div className="grid grid--uniform">
                <div className={gridSize}>
                  <ChefOrHostCard person={experience.host} />
                </div>
                <div className={gridSize}>
                  <ChefOrHostCard person={experience1.host} />
                </div>
                <div className={gridSize}>
                  <ChefOrHostCard person={experience3.host} />
                </div>
              </div>
              <a
                href="{{ section.settings.button_link }}"
                className="breakbread-btn"
              >
                CTA
              </a>
            </BackgroundSection>
          </div>
          <div className="breakbread-container">
            <h2 id="thumb-link">Overlay Thumbnail & Gutterless Grid</h2>
            <p>
              The following grid uses the <code>grid--no-gutters</code> modifier
              className. To display the thumbs, use <code> ThumbLink </code>
            </p>
            <div className="grid grid--no-gutters">
              <div className="grid grid--uniform">
                <div className={gridSizeThumbs}>
                  <ThumbLink
                    image={ExperienceImage1}
                    link="/"
                    pre="Menu Item Type"
                    title="Donec id elit non mi porta gravida at eget metus."
                    desc="Cras mattis consectetur purus sit amet fermentum. Curabitur blandit tempus porttitor."
                  />
                </div>
                <div className={gridSizeThumbs}>
                  <ThumbLink
                    image={ExperienceImage2}
                    link="/"
                    pre="Menu Item Type"
                    title="Nullam id dolor id nibh ultricies vehicula ut id elit."
                    desc="Cras mattis consectetur purus sit amet fermentum. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Donec id elit non mi porta gravida at eget metus."
                  />
                </div>
                <div className={gridSizeThumbs}>
                  <ThumbLink
                    image={ExperienceImage3}
                    link="/"
                    pre="Menu Item Type"
                    title="Vestibulum id ligula porta felis euismod semper."
                    desc="Cras mattis consectetur purus sit amet fermentum. Curabitur blandit tempus porttitor."
                  />
                </div>
                <div className={gridSizeThumbs}>
                  <ThumbLink
                    image={BreadInHand}
                    link="/"
                    pre="Menu Item Type"
                    title="Cras mattis consectetur purus sit amet fermentum."
                    desc="Cras mattis consectetur purus sit amet fermentum. Curabitur blandit tempus porttitor."
                  />
                </div>
              </div>
            </div>
            <hr />
          </div>
          <div className="breakbread-container">
            <div className="design-collective--docs-section">
              <h2 id="buttons">Value Props</h2>

              <div className="grid grid--uniform">
                <div className={gridSize}>
                  <ValueProp
                    image={ValueProp1}
                    title="Value Prop 1"
                    desc="Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Etiam faucibus maximus nulla vitae tristique. Nulla
                    facilisi."
                  />
                </div>

                <div className={gridSize}>
                  <div className="break-bread--value-prop">
                    <ValueProp
                      image={ValueProp2}
                      title="Value Prop 2"
                      desc="Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Etiam faucibus maximus nulla vitae tristique. Nulla
                      facilisi."
                    />
                  </div>
                </div>

                <div className={gridSize}>
                  <ValueProp
                    image={ValueProp3}
                    title="Value Prop 3"
                    desc="Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Etiam faucibus maximus nulla vitae tristique. Nulla
                    facilisi."
                  />
                </div>
              </div>
            </div>
            <hr />
          </div>
          <div className="breakbread-container">
            <div className="design-collective--docs-section">
              <h2 id="buttons">Buttons</h2>
              <h4>Default</h4>
              <div className="design-collective--example">
                <p>
                  <a href="#top" className="breakbread-btn">
                    Default button
                  </a>
                  <a href="#top" className="breakbread-btn" disabled>
                    Default Disabled
                  </a>
                </p>
              </div>
              <h4>Outline / Flat </h4>
              <div className="design-collective--example">
                <p>
                  <a href="#top" className="breakbread-btn btn--flat">
                    Button outline
                  </a>{' '}
                  <a href="#top" className="breakbread-btn btn--flat" disabled>
                    Outline Disabled
                  </a>
                </p>
              </div>
              <h4>Dark</h4>
              <div className="design-collective--example">
                <p>
                  <a href="#top" className="breakbread-btn btn--flat btn--dark">
                    Dark Button
                  </a>{' '}
                  <a href="#top" className="breakbread-btn -dark" disabled>
                    Dark Disabled
                  </a>
                </p>
              </div>
              <h4>Wide</h4>
              <div className="design-collective--example">
                <p>
                  <a href="#top" className="breakbread-btn btn--full">
                    Wide button (no defaults)
                  </a>
                </p>
              </div>
              <hr />
            </div>
          </div>
          <div id="svg-icons" className="breakbread-container">
            <div className="design-collective--docs-section">
              <h2>SVG icons</h2>
              <p>
                Read more about using, creating, and editing SVG icons in this
                theme documentation.
              </p>
              <h3>Allergies and Diet icons</h3>

              <ul className="diet-icons list--inline">
                <li className="AlmondsIcon">
                  <Icon.AlmondsIcon />
                </li>
                <li className="CeleryIcon">
                  <Icon.CeleryIcon />
                </li>
                <li className="ChefHatIcon">
                  <Icon.ChefHatIcon />
                </li>
                <li className="CornIcon">
                  <Icon.CornIcon />
                </li>
                <li className="CrustaceanShellfishIcon">
                  <Icon.CrustaceanShellfishIcon />
                </li>
                <li className="DairyIcon">
                  <Icon.DairyIcon />
                </li>
                <li className="EggIcon">
                  <Icon.EggIcon />
                </li>
                <li className="FishIcon">
                  <Icon.FishIcon />
                </li>
                <li className="GlutenIcon">
                  <Icon.GlutenIcon />
                </li>
                <li className="GmoIcon">
                  <Icon.GmoIcon />
                </li>
                <li className="HoneyIcon">
                  <Icon.HoneyIcon />
                </li>
                <li className="LupinsIcon">
                  <Icon.LupinsIcon />
                </li>
                <li className="MolluskIcon">
                  <Icon.MolluskIcon />
                </li>
                <li className="MustardIcon">
                  <Icon.MustardIcon />
                </li>
                <li className="OrganicIcon">
                  <Icon.OrganicIcon />
                </li>
                <li className="PeanutsIcon">
                  <Icon.PeanutsIcon />
                </li>
                <li className="SesameIcon">
                  <Icon.SesameIcon />
                </li>
                <li className="SoybeansIcon">
                  <Icon.SoybeansIcon />
                </li>
                <li className="SpicyIcon">
                  <Icon.SpicyIcon />
                </li>
                <li className="SugarIcon">
                  <Icon.SugarIcon />
                </li>
                <li className="SulfateIcon">
                  <Icon.SulfateIcon />
                </li>
                <li className="TreeNutsIcon">
                  <Icon.TreeNutsIcon />
                </li>
                <li className="VegetarianIcon">
                  <Icon.VegetarianIcon />
                </li>
              </ul>

              <h3>Payment icons</h3>
              <p> Still to come </p>

              <h3>General icons</h3>
              <p> Still to come </p>

              <hr />
            </div>
          </div>
        </section>

        <Typography />
      </main>
    )
  }
}
