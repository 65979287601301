import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import validate from './validate'
import CreateAnAccount from '../Common/CreateAnAccount'
import * as signUpActionCreators from '../../../actions/signUp'

import * as userActionCreators from '../../../actions/user'

/**
 * HOC that wraps the same common step so we can be DRY
 * @param {Object} props
 */
const Step1 = props => <CreateAnAccount {...props} />

const reduxFormStep1 = reduxForm({
  form: 'registration', // <------ same form name
  destroyOnUnmount: false, // <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  validate
})(Step1)

const mapStateToProps = state => ({
  currentUser: state.reduxTokenAuth.currentUser,
  stepForm: state.stepForm.hostRegistration,
  signUpPane: state.signUp.signUpPane
})

const mapDispatchToProps = dispatch => ({
  signUpActions: bindActionCreators(signUpActionCreators, dispatch),
  /** NOTE: This would also be a nice way of doing it
   * https://github.com/reduxjs/react-redux/blob/master/docs/api.md#inject-todos-and-all-todoactioncreators-and-counteractioncreators-directly-as-props
   *
   * actions: bindActionCreators(Object.assign({}, modalActions), dispatch)
   */
  userActions: bindActionCreators(userActionCreators, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(reduxFormStep1)
