import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Modal from 'react-bootstrap/lib/Modal'
import { bindActionCreators } from 'redux'
import { orderBy } from 'lodash'
import { reset } from 'redux-form'
import { scheduleCut } from '../../utils/normalizeAssociations'
import { normalizeScheduleds } from '../../utils/normalization'
import moment from 'moment'
import jsPDF from 'jspdf'
import html2canvas from 'html2canvas'
import { SingleDatePicker } from 'react-dates'
import * as modalActionCreators from '../../actions/modals'
import * as formActionCreators from '../../actions/forms'
import * as userActionCreators from '../../actions/user'
import * as transactionActionCreators from '../../actions/transactions'
import * as experienceActionCreators from '../../actions/experience'
import * as hostActionCreators from '../../actions/host'

import { currencyTypes } from '../../constants/currency'

// Validations
import { currentUserPropTypes } from '../../constants/composed-proptypes'

import AmericanExpressIcon from '../../icons/creditCards/AmericanExpressIcon'
import MastercardIcon from '../../icons/creditCards/MastercardIcon'
import PaypalIcon from '../../icons/creditCards/PaypalIcon'
import VisaIcon from '../../icons/creditCards/VisaIcon'
import IconClose from '../../icons/global/SVGJS/IconClose'
import IconPencil from '../../icons/global/SVGJS/IconPencil'
import IconPlay from '../../icons/global/SVGJS/IconPlay'
import IconPause from '../../icons/global/SVGJS/IconPause'
import IconCheck from '../../icons/global/SVGJS/IconCheck'
import StatusCommon from '../Shared/Status'
import { tr } from 'react-dom-factories'
import { editExperience } from '../../actions/experience'

const PAYMENT_ICONS = {
  AMERICANEXPRESS: AmericanExpressIcon,
  MASTERCARD: MastercardIcon,
  PAYPAL: PaypalIcon,
  VISA: VisaIcon
  /* other icons */
}

const initialStateData = {
  seats: 0,
  dateValue: moment(),
  focused: false,
  id: null,
  status: 'draft',
  revenueSharePercentage: 0
} 
class ScheduleDetails extends Component {
  constructor(props) {
    super(props)
    // const { receipt } = props.reservation
    // receipt = JSON.parse(receipt);
    this.textInput = React.createRef()
    this._submitSchedule = this._submitSchedule.bind(this)
    this._editSchedule = this._editSchedule.bind(this)
    this._showScheduleForm = this._showScheduleForm.bind(this)
    this._deleteSchedule = this._deleteSchedule.bind(this)
    this._pauseOrActive = this._pauseOrActive.bind(this)
    this._onCancel = this._onCancel.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this._afterConfirm = this._afterConfirm.bind(this)

    this.state = {
      ...initialStateData,
      loading: false,
      showForm: false,
      errorMessages: [],
      localSchedules: [],
      isDisabled: false,
      disabledDate: false,
      confirmShow: false,
      dialogMessage: null,
      currentObj: null, 
      currentType: null,
      /* showForm: false,
      seats: 0,
      dateValue: moment(),
      focused: false,
      id: null,
      status: 'draft' */
    }
    // this.setState({...initialStateData})
  }

  async componentDidMount () {
    const { item, experienceActions } = this.props
    this.setState({loading: true})
    const response =  await experienceActions.getSchedules({experienceId: item.id})
    const resSchedules = response.data && response.data.experience_schedules?response.data.experience_schedules:[]
    const formatedSchedules = normalizeScheduleds(resSchedules)
    const shortedSchedule = orderBy(
      formatedSchedules,
      'date',
      'desc'
    )
    this.setState({localSchedules: [...shortedSchedule], loading: false})
  }
  
  _submitSchedule = async (e) => {
    e.preventDefault()
    this.setState({isDisabled: true, errorMessages: []})
    const { item, experienceActions, hostActions } = this.props
    const { seats, dateValue, status, id } = this.state
    const submitObj = {
      experienceId: item.id,
      id,
      submitData: {
        seats,
        date: moment(dateValue).format('DD/MM/YYYY'),
        status
      },
      type: 'addEdit'
    }
    const response = await experienceActions.submitSchedule(submitObj)
    if (typeof response.data.is_success !== 'undefined' && !response.data.is_success) {
      this.setState({errorMessages: response.data.error, isDisabled: false})
    } else {
      const localScheduleData = [...this.state.localSchedules]
      let updatedSchedule
      if (id) {
        updatedSchedule = localScheduleData.map((el)=>{
          if (el.id === id) {
            return {
              ...el,
              seats
              //...scheduleCut(response.data.schedule)
            }
          } else {
            return el
          }
        })
      } else {
        const responseSchedule = scheduleCut(response.data.schedule)
        updatedSchedule = [...localScheduleData, responseSchedule]
      }
      this.setState({isDisabled: false})
      const shortedSchedule = orderBy(
        updatedSchedule,
        'date',
        'desc'
      )
      this.setState({...initialStateData, showForm: false, localSchedules: [...shortedSchedule]})
      hostActions.getExperiences()
    }
  }

  
  _editSchedule = async (schObj) => {
    const { seats, revenueSharePercentage, date, id, status } = schObj
    this.setState({seats: Number(seats), revenueSharePercentage: Number(revenueSharePercentage), dateValue: moment(date), id, status, disabledDate: true, showForm: true, errorMessages: [] })
    setTimeout(() => {
      if(this.textInput && this.textInput.current) {
        this.textInput.current.focus()
      }
    }, 500)
  }

  _deleteSchedule = async (schObj) => {
    if(confirm('Are you sure to delete this?')) {
      this.setState({errorMessages: []})
      const { item, experienceActions, hostActions } = this.props
      const { id } = schObj
      const submitObj = {
        experienceId: item.id,
        id,
        submitData: null,
        type: 'delete'
      }
      const response = await experienceActions.submitSchedule(submitObj)
      if (response.data.is_success) {
        const localScheduleData = [...this.state.localSchedules]
        this.setState({localSchedules: [...localScheduleData.filter(el=>el.id !== id)]})
        hostActions.getExperiences()
      } else {
        this.setState({errorMessages: response.data.error_message})
      }
    }
  }
   
  _pauseOrActive = async (schObj, actionType) => {
    let dialogMessage = null
    switch (actionType) {
    case 'resume':
      dialogMessage = 'Are you sure to resume the schedule?'
      break
    case 'pause':
      dialogMessage = 'Are you sure to pause the schedule?'
      break
    case 'active':
      dialogMessage = 'Are you sure to active the schedule?'
      break
    case 'cancel':
      dialogMessage = 'Are you sure to cancel the schedule?'
      break
    }
    this.setState({confirmShow: true, dialogMessage, currentObj: schObj, currentType: actionType })  
  }

  _afterConfirm = async () => {
    this.setState({errorMessages: []})
    const { item, experienceActions, hostActions } = this.props
    const { currentType, currentObj } = this.state
    const { id } = currentObj
    const submitObj = {
      experienceId: item.id,
      id,
      submitData: null,
      type: currentType
    }
    this.handleClose()
    const response = await experienceActions.submitSchedule(submitObj)
    if (response.data.is_success) {
      const localScheduleData = [...this.state.localSchedules]
      this.setState({localSchedules: [...localScheduleData.map((el)=>{
        if (id === el.id) {
          return {
            ...el,
            status: currentType === 'cancel'?'cancelled':currentType === 'pause'?'paused':currentType === 'resume'?'active':currentType
          }
        } else {
          return el
        }
      })]})
      hostActions.getExperiences()
    } else {
      this.setState({errorMessages: response.data.error_message})
    }
  }

   _showScheduleForm = () => {
    const { currentUser } = this.props
    const { revenueSharePercentage } = currentUser && currentUser.attributes
     this.setState({...initialStateData, revenueSharePercentage: revenueSharePercentage, showForm: true, disabledDate: false})
     setTimeout(() => {
       if(this.textInput && this.textInput.current) {
         this.textInput.current.focus()
       }
     }, 500)
   }

   _onCancel = () => {
     this.setState({...initialStateData, showForm: false})
   }

   handleClose = () => {
     this.setState({confirmShow: false, currentObj: null, currentType: null})
   }

   render() {
     const { currentUser, item, modalActions } = this.props
     const { showForm, dateValue, seats, revenueSharePercentage, focused, localSchedules, errorMessages, isDisabled, disabledDate, id, loading, confirmShow, dialogMessage } = this.state
     const { schedules } = item

     const experienceTitle = item ? item.title : ''
     let recieptTitle = <span>Manage Schedules {/* of `<b>{experienceTitle}</b>` */}</span>

     return (
       <div
         className="guest-receipt-item form-body form-body--full"
       >
         <h5>{recieptTitle}</h5>
         { errorMessages && errorMessages.length > 0 && <ul style={{textAlign: 'left'}}>
           {errorMessages.map((el, index)=>(<li key={index+'error'} style={{listStyle: 'none', color: 'red'}}>{el}</li>))}
         </ul>
         }
         <div className="grid">
           <div className="grid__item medium-up--one-full">
             <div style={{textAlign: 'right'}}><button style={{paddingTop: '5px', paddingBottom: '5px'}} className="btn form-footer--right" onClick={()=>this._showScheduleForm()}>+ Add Schedule</button></div>
             <table className="responsive-table responsive-table--no-labels responsive-table--no-borders">
               <thead className="table-heading">
                 <tr>
                   <th colSpan="2" style={{textAlign: 'center'}}>#</th>
                   <th colSpan="2" style={{textAlign: 'center'}}>Date</th>
                   <th colSpan="2" style={{textAlign: 'center'}}>Seats</th>
                   <th colSpan="2" style={{textAlign: 'center'}}>Booked Seats</th>
                   <th colSpan="2" style={{textAlign: 'center'}}>Status</th>
                   <th colSpan="2">Actions</th>
                 </tr>
               </thead>
               <tbody>
                 {localSchedules && localSchedules.map((el, index)=>(<tr key={index} style={{backgroundColor: id==el.id?'lightgray':null}} className="responsive-table-row">
                   <td colSpan="2" style={{textAlign: 'center'}}>
                     {index+1}
                   </td>
                   <td colSpan="2" style={{textAlign: 'center'}}>
                     {moment(el.date).format('MMM DD, YYYY') }
                   </td>
                   <td colSpan="2" style={{textAlign: 'center'}}>
                     {el.seats}
                   </td>
                   <td colSpan="2" style={{textAlign: 'center'}}>
                     {el.seatsBooked}
                   </td>
                   <td colSpan="2" style={{textAlign: 'center'}}>
                     <StatusCommon status={el.status} />
                   </td>
                   <td colSpan="2" style={{textAlign: 'center'}}>
                     {/*   Edit, Cancel, Pause, Resume and Activate */}
                     {el.status !== 'cancelled' && !moment(el.scheduleTime).isBefore(moment(new Date()).utc())?(<React.Fragment>
                       {el.status === 'draft' ?(<button 
                         style={{padding: '5px', marginRight: '5px', color: 'green'}}
                         type="button"
                         className="btn--link"
                         title="Activate"
                         onClick={()=>this._pauseOrActive(el, 'active')}
                       >
                         {/* Activate */}
                         <IconCheck />
                       </button>):''} 
                       {el.status === 'active'?(<button 
                         style={{/* padding: '5px', marginRight: '5px', */ color: 'green'}}
                         type="button"
                         className="btn--link"
                         title="Pause"
                         onClick={()=>this._pauseOrActive(el, 'pause')}
                       >
                         <IconPause />
                         {/* Pause */}
                       </button>):''} 

                       {el.status === 'paused' ?(<button 
                         style={{/* padding: '5px', marginRight: '5px', */ color: 'gray'}}
                         type="button"
                         className="btn--link"
                         title="Resume"
                         onClick={()=>this._pauseOrActive(el, 'resume')}
                       >
                         <IconPlay />
                         {/* Active */}
                       </button>):''}
                       {/* {el.status !== 'cancelled' ?(<button 
                         style={{ color: 'red'}}
                         type="button"
                         className="btn--link"
                         title="Cancel"
                         onClick={()=>this._pauseOrActive(el, 'cancel')}
                       >
                         <IconClose />
                       </button>):''} */}
                       <button 
                         style={{color: id==el.id?'green':'blue'}}
                         type="button"
                         className="btn--link btn--remove"
                         title="Edit"
                         onClick={()=>this._editSchedule(el)}
                       >
                      
                         <IconPencil />
                       </button>
                       {/* <button
                       style={{color: 'red'}}
                       type="button"
                       className="btn--link btn--remove"
                       title="Remove"
                       onClick={()=>this._deleteSchedule(el)}
                     >
                       <IconClose />
                     </button> */}</React.Fragment>):''}
              
                   </td>
                 </tr>))}
               </tbody>
             </table>
             {loading?(<div className="spinner" />):''}
             {showForm?(<form className="scheduleform" onSubmit={(e)=>this._submitSchedule(e)}>
               <div className="scheduleform_div">
                 <div style={{marginLeft: '10px'}}>
                   <label>Seats*</label>
                   <input ref={this.textInput} name="seats" value={seats} /* defaultValue={seats}  */ onChange={(event)=>{
                     this.setState({seats: Number(event.target.value)})
                   }} type="number"/>
                 </div>
                 <div style={{marginLeft: '10px'}}>
                   <label>Date*</label>
                   {/* <input name="date"/> */}
                   <SingleDatePicker
                     numberOfMonths={1}
                     date={dateValue} // momentPropTypes.momentObj or null
                     onDateChange={date => this.setState({ dateValue: date })} // PropTypes.func.isRequired
                     focused={focused} // PropTypes.bool
                     onFocusChange={({ focused }) => this.setState({ focused })} // PropTypes.func.isRequired
                     disabled={disabledDate}
                     id="your_unique_id" // PropTypes.string.isRequired,
                     placeholder={'Select Date'}
                   />
                 </div>
                 <div style={{marginLeft: '10px'}}>
                   <label>Revenue Share (Percentage)*</label>
                   <input ref={this.textInput} name="revenue_share_percentage" value={revenueSharePercentage} type="number" disabled/>
                 </div>
                 <div style={{marginLeft: '10px', marginTop: '45px' }}>
                   <button style={{paddingTop: '10px', paddingBottom: '10px'}} disabled={isDisabled || seats < 1} type="submit" className="btn form-footer--right" >Submit</button>
                 </div>
                 <div style={{marginLeft: '10px', marginTop: '45px' }}>
                   <button style={{paddingTop: '10px', paddingBottom: '10px'}} type="button" className="btn form-footer--right" onClick={()=>this._onCancel()}>Cancel</button>
                 </div>
               </div>
             </form>):''}
             <Modal
               show={confirmShow}
               onHide={()=>this.handleClose()}
               animation={false}
             >
               <Modal.Header closeButton>
                 <Modal.Title style={{textAlign: 'center'}}>Confirm!</Modal.Title>
               </Modal.Header>
    
               <Modal.Body>
                 <p>{dialogMessage}</p>
               </Modal.Body>
    
               <Modal.Footer>
                 <button style={{paddingTop: '10px', paddingBottom: '10px'}} className="btn form-footer--right" onClick={()=>{this._afterConfirm()}} >OK</button>
                 <button style={{paddingTop: '10px', paddingBottom: '10px'}} className="btn form-footer--right" onClick={()=>{this.handleClose()}} >Cancel</button>
               </Modal.Footer>
             </Modal>
           </div>
         </div>
         {/* {currentUser.isLoading && <div className="spinner" />} */}
       </div>
     )
   }
}

ScheduleDetails.propTypes = {
  /*  currentUser: currentUserPropTypes.isRequired,
  userActions: PropTypes.shape({}).isRequired, */
  modalActions: PropTypes.shape({}).isRequired
  /* transactionActions: PropTypes.shape({}).isRequired */
}

const mapDispatchToProps = dispatch => ({
  /*  formActions: bindActionCreators(formActionCreators, dispatch),
  userActions: bindActionCreators(userActionCreators, dispatch), */
  modalActions: bindActionCreators(modalActionCreators, dispatch),
  experienceActions: bindActionCreators(experienceActionCreators, dispatch),
  hostActions: bindActionCreators(hostActionCreators, dispatch)
  /* transactionActions: bindActionCreators(transactionActionCreators, dispatch),
  resetForm: () => dispatch(reset('guestReview')) */
})

const mapStateToProps = state => ({
  // pathname: state.router.location.pathname,
  currentUser: state.reduxTokenAuth.currentUser
})

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleDetails)
