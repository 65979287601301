import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import StarRatingComponent from 'react-star-rating-component'
import ChefAvtarDiner from '../Chef/ChefAvtarDiner'
import ChefAvtarHost from '../Chef/ChefAvtarHost'
import ChefInfo from '../Chef/ChefInfo'
import moment from 'moment'
import locationIcon from '../../icons/location.png'
// import ChefAvatarImage from '../../images/placeholders/chef-avatar@2x.png'

import EditExperienceLink from '../Workflows/ExperienceEdit/EditExperienceLink'
import HostReviewLink from '../Workflows/HostReview/HostReviewLink'
import ViewReceipt from '../Host/MyExperiences/ViewReceipt'
import PauseReservation from '../Host/MyExperiences/PauseReservation'
import { showReviewCommenModal } from '../../actions/modals'
// /import EditExperienceLink from './../../Workflows/ExperienceEdit/EditExperienceLink';
// import ViewReceipt from './ViewReceipt';
export default class HostReviewCard extends Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {}

  componentWillUnmount() {}

  handleModal(comment) {
    this.props.dispatch(showReviewCommenModal(...this.props, { comment: comment }))
  }

  render() {
    const { review, featured, hideAvatar, forceHoverState, displaySoldStatus, reviewType, currentUser } = this.props
    const { datePosted, experience, comment, guest, host, reservation, star, status } = review
    const now = new Date()

    let extraClassNames = ''
    extraClassNames += featured ? ' experience-card--featured' : ''
    extraClassNames += forceHoverState ? ' grid-view-item--force-hover' : ''
    extraClassNames += hideAvatar ? ' experience-card--avatar-hidden' : ''
    // experience.readableTime = experience.time ? experience.time : experience.readableTime;
    // console.log("review data", review);
    let commentStr = comment

    commentStr = commentStr.length > 100 ? `${commentStr.substring(0, 100)}....` : commentStr
    const userData = reviewType !== 'by' ? host : host.id === currentUser.attributes.id ? host : guest.id === currentUser.attributes.id ? guest : host
    return (
      <React.Fragment>
        <div className={`experience-card grid-view-item ${extraClassNames}`}>
          <div className="experience-card__image--container grid-view-item__link grid-view-item__image-container">
            <div className="grid-view-item__image-wrapper js imageWrapper " />
          </div>

          <div className="experience-card__meta grid-view-item__meta">
            {!hideAvatar && (
              <React.Fragment>
                {reviewType === 'about' ? (
                  <ChefAvtarHost hideLink={true} person={guest} styleAttributes={{ width: '20%' }} />
                ) : (
                  <ChefAvtarHost hideLink={true} person={userData} styleAttributes={{ width: '20%' }} />
                )}
              </React.Fragment>
            )}

            <div className="experience-card__text grid-view-item__text">
              {!hideAvatar && (
                <React.Fragment>{reviewType === 'about' ? <ChefInfo displaySpecialties={false} person={guest} /> : <ChefInfo displaySpecialties={false} person={userData} />}</React.Fragment>
              )}

              <div className="experience-card__title grid-view-item__title">
                <Link to={`/experiences/${experience.slug}`}>
                  <h5>{experience.title}</h5>
                </Link>
              </div>
              <p className="bookingId">{`RES-${reservation.id}`}</p>
              <StarRatingComponent name="rate1" starCount={5} value={star ? Number(star) : 0} onStarClick={(args) => {}} />

              {commentStr ? (
                <div className="center-wrap reviewCard component reviewComment ">
                  <blockquote className="sidekick" style={{ margin: 0 }}>
                    {commentStr}{comment && comment.length > 100 && (
                      <span style={{ cursor: 'pointer', fontWeight: 'bold' }} onClick={this.handleModal.bind(this, comment)}>
                      Read More
                      </span>
                    )}
                  </blockquote>
                </div>
              ) : (
                ''
              )}
              
            </div>

            {/* <div className="experience-card__text grid-view-item__text">
              <div className="experience-card__title grid-view-item__title">
                <Link to={`/experiences/${experience.slug}`}>
                  <h5>{experience.title}</h5>
                </Link>
              </div>
            </div> */}
            <span>
              <p>Reviewed on: {moment(datePosted, 'YYYY-MM-DD').format('ddd DD-MMM, YYYY HH:MM a')} </p>
            </span>
            {status && status == 'pending' ? (
              <span>
                <p style={{ marginBottom: '10px' }}>
                  Review activation is <i>pending</i>
                  <div className="tooltip">
                    &nbsp;<span className="fas fa-question-circle" />
                    <span className="tooltiptext"><i>* The review is pending activation and will be active and show up when both parties submit their feedback or after 14-day since it was submitted, whichever comes first.</i></span>
                  </div>
                </p>
              </span>
            ) : (
              <span>
                <p>
                  <br></br>
                </p>
              </span>
            )}
          </div>

          {/* <div className="experience-card__reservation-info group actionCard">

                        {comment}

                    </div> */}
        </div>
      </React.Fragment>
    )
  }
}

HostReviewCard.defaultProps = {
  featured: false,
  hideAvatar: false,
  forceHoverState: false
}
/*
HostReviewCard.propTypes = {
  experience: PropTypes.shape({
    title: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    host: PropTypes.shape({
      fullname: PropTypes.string,
      avatarUrl: PropTypes.string,
      specialties: PropTypes.arrayOf(PropTypes.shape({})).isRequired
    })
  }).isRequired,
  featured: PropTypes.bool,
  hideAvatar: PropTypes.bool,
  forceHoverState: PropTypes.bool
} */
