import { getAllParams } from '../utils/parse-url'
import { store } from '../configureStore'

import { getUserAttributesFromResponse } from '../utils/auth-tools'
import api from '../utils/api'

import alertModal from '../utils/alert'

// import fetch from '../utils/fetch';
import _openPopup from '../utils/popup'

export const OAUTH_SIGN_IN_START = 'OAUTH_SIGN_IN_START'
export const OAUTH_SIGN_IN_COMPLETE = 'OAUTH_SIGN_IN_COMPLETE'
export const OAUTH_SIGN_IN_ERROR = 'OAUTH_SIGN_IN_ERROR'

export const SET_HAS_VERIFICATION_BEEN_ATTEMPTED =
  'redux-token-auth/SET_HAS_VERIFICATION_BEEN_ATTEMPTED'

export const VERIFY_TOKEN_REQUEST_SUCCEEDED =
  'redux-token-auth/VERIFY_TOKEN_REQUEST_SUCCEEDED'

export const VERIFY_TOKEN_REQUEST_FAILED =
  'redux-token-auth/VERIFY_TOKEN_REQUEST_FAILED'

export const VERIFY_TOKEN_REQUEST_SENT =
  'redux-token-auth/VERIFY_TOKEN_REQUEST_SENT'

export const setHasVerificationBeenAttempted = hasVerificationBeenAttempted => ({
  type: SET_HAS_VERIFICATION_BEEN_ATTEMPTED,
  payload: {
    hasVerificationBeenAttempted
  }
})

export const verifyTokenRequestSucceeded = userAttributes => ({
  type: VERIFY_TOKEN_REQUEST_SUCCEEDED,
  payload: {
    userAttributes
  }
})

export const verifyTokenRequestFailed = () => ({
  type: VERIFY_TOKEN_REQUEST_FAILED
})

export const verifyTokenRequestSent = () => ({
  type: VERIFY_TOKEN_REQUEST_SENT
})

const openPopup = _openPopup

/**
 *  Sends a verification request to the server]
 * @param  {object} params - params used to send authentication
 * @return {Promise}  The authenticated user object
 */
const verifyTokenAsynch = (params, dispatch) =>
  new Promise((resolve, reject) => {
    // /api/v1/auth/validate_token
    // return verifyCredentials(store);
    store.dispatch(verifyTokenRequestSent())
    return api({
      method: 'GET',
      url: 'auth/validate_token',
      params
    })
      .then(response => {
        const json = response.data
        if (json.success) {
          const userAttributesToSave = getUserAttributesFromResponse(response)
          // Save our User to Store
          dispatch(verifyTokenRequestSucceeded(userAttributesToSave))
          resolve(json)
        } else {
          reject(json)
        }
      })
      .catch(error => {
        dispatch(verifyTokenRequestFailed())
        reject(error)
      })
  }).catch(err => {
    throw new Error(err.message)
  })

/**
 * Promisify the create method provided by getAllParams
 */
async function getAllParamsAsync(location) {
  try {
    return await getAllParams(location)
  } catch (error) {
    throw error
  }
}

/**
 *  A promise that will resolve the response from the token verification
 *  and close the popup.
 * @async
 * @param  {[type]} popup - an instance of the a popup created
 * @return {Promise} - If no credentials were found, it resolves without throwing
 *  an error so we can recursively check the promise for credentials until they are returned.
 */
const getParamsFromPopUpAsynch = async (popup, dispatch) => {
  let creds
  try {
    creds = await getAllParamsAsync(popup.location)
  } catch (error) {
    if (error instanceof DOMException) return error
    throw error
  }

  try {
    if (creds && creds.uid) {
      window.popup = popup
      popup.close()
      const headers = {
        'access-token': creds.auth_token,
        client: creds.client_id,
        uid: creds.uid,
        expiry: creds.expiry
      }
      const response = await verifyTokenAsynch(headers, dispatch)
      if (response instanceof Error) {
        throw response
      }
      return response
    }
    if (popup.closed) {
      const error = new Error(
        'Popup was closed before OAuth credentials were received.'
      )
      throw error
    }
  } catch (error) {
    throw error
  }
}

const listenAndRetry = async (popup, dispatch, n, cb) => {
  try {
    const params = await getParamsFromPopUpAsynch(popup, dispatch)
    const isSuccess = params && params.is_success
    const isError = params && params instanceof Error
    const isDomException = params && params instanceof DOMException

    if ((!isSuccess && !isError) || isDomException) {
      setTimeout(() => listenAndRetry(popup, dispatch, n - 1, cb), 300) // recurse
    } else {
      cb(params)
    }
  } catch (error) {
    cb(error)
    return error
  }
}

export function oAuthSignIn({
  provider,
  endpoint,
  n = 40,
  tab = false,
  oAuthSignInCallback
}) {
  return async dispatch => {
    const name = tab ? '_blank' : provider
    const popup = await openPopup(provider, endpoint, name)
    try {
      await listenAndRetry(popup, dispatch, n, response => {
        if (response && response.is_success) {
          const currentUserName = response.data.first_name
          alertModal(
            `Yay! ${currentUserName}. You have succesfully logged in`,
            'success',
            'continue'
          )
          if (typeof oAuthSignInCallback !== 'undefined') {
            oAuthSignInCallback()
          }
        }
        if (response instanceof Error) {
          const err = response
          alertModal(err, 'error', 'try again')
        }
      })
    } catch (err) {
      //console.log('Error ocurred in OAUTH sign in', err);
    }
  }
}
