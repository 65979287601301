import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class BackgroundSection extends Component {
  componentDidMount() {}

  render() {
    const { size, image, color } = this.props
    //console.log(size, image);
    return (
      <div
        className={`breakbread-backgroundSection breakbread-backgroundSection--${size} box ratio-container lazyload js`}
        data-bgset="{% include 'bgset', image: section.settings.image %}"
        data-sizes="auto"
        data-parent-fit="cover"
        style={{
          backgroundPosition: image ? '50% 50%' : null,
          backgroundImage: image ? `url(${image})` : null,
          backgroundColor: color || null
        }}
      >
        <div className="breakbread-backgroundSection__inner">
          <div className="breakbread-container static text-center">
            {this.props.children}
          </div>
        </div>
      </div>
    )
  }
}
