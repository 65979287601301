import alertModal from '../../../utils/alert'

import { camelToSnake } from '../../../utils/camelSnake'

/* TODO: These function belong in the action folder
   and should use the Redux THUNK pattern instead.
*/

async function handleRegistration(data, props) {
  const { register } = props // redux connected dispatchable action
  let registrationData
  try {
    registrationData = await register(data)
    /*alertModal('Welcome to Breakbread', 'success', 'Close');*/
  } catch (error) {
    /* eslint-disable-next-line no-console */
    // console.log(error);
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      alertModal(error.response, 'error', 'Try Again')
      /* eslint-disable-next-line no-console */
      console.error('Error in registration', error.response)
    } else if (error.request) {
      alertModal(error, 'error', 'Try Again')
      /* eslint-disable-next-line no-console */
      console.error('Error on Request', error.request)
    } else {
      alertModal(error.response, 'error', 'Try Again')
      /* eslint-disable-next-line no-console */
      console.error('Error in registration', error.message)
    }
  }

  return registrationData
}

export { handleRegistration }
