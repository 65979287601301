import React from "react"

const IconLock = props => (
  <svg viewBox="0 0 17 20" className="icon" width="1em" height="1em" {...props}>
    <path
      fill="#FFF"
      d="M15.725 8.333H15.3V6.875C15.3 3.02 12.313 0 8.5 0 4.687 0 1.7 3.02 1.7 6.875v1.458h-.425C.571 8.333 0 8.893 0 9.583v9.167C0 19.44.571 20 1.275 20h14.45c.704 0 1.275-.56 1.275-1.25V9.583c0-.69-.571-1.25-1.275-1.25zM4.25 6.875C4.25 4.381 6.077 2.5 8.5 2.5s4.25 1.88 4.25 4.375v1.458h-8.5V6.875zm5.95 8.142c0 .91-.754 1.65-1.683 1.65h-.034c-.93 0-1.683-.74-1.683-1.65v-1.7c0-.911.754-1.65 1.683-1.65h.034c.93 0 1.683.739 1.683 1.65v1.7z"
    />
  </svg>
)

export default IconLock
