import React from 'react'
import { reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import UploadProfileImage from '../Common/UploadProfileImage'
import validate from './validate'

/* Actions */
import * as signUpActionCreators from '../../../actions/signUp'
import * as modalActionCreators from '../../../actions/modals'
import * as formActionCreators from '../../../actions/forms'
import verifyImg from '../../../icons/status/success-icon.png'
/**
 * HOC that wraps the same common step so we can be DRY
 * @param {Object} props
 */
const UploadProfileImagetHost = props => {
  const { currentUser } = props
  return (
    <React.Fragment>
      <img src={verifyImg} className="verifyImageOtp" alt="verifyImageOtp" />
      <div className="form-header">
        <h4>Upload a photo</h4>
        <p className="mb_5">Your mobile number was successfully verified.</p>
        <p> Upload a picture / go to next step to become a host.</p>
      </div>

      <UploadProfileImage {...props} />
    </React.Fragment>
  )
}

const reduxUploadProfileImagetHost = reduxForm({
  form: 'registration', // <------ same form name
  destroyOnUnmount: false, // <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  validate
})(UploadProfileImagetHost)

const mapStateToProps = state => ({
  stepForm: state.stepForm.hostRegistration,
  activeFormType: state.signUp.activeFormType,
  signUpPane: state.signUp.signUpPane
})

const mapDispatchToProps = dispatch => ({
  formActions: bindActionCreators(formActionCreators, dispatch),
  signUpActions: bindActionCreators(signUpActionCreators, dispatch),
  modalActions: bindActionCreators(modalActionCreators, dispatch)
  /** NOTE: This would also be a nice way of doing it
   * https://github.com/reduxjs/react-redux/blob/master/docs/api.md#inject-todos-and-all-todoactioncreators-and-counteractioncreators-directly-as-props
   *
   * actions: bindActionCreators(Object.assign({}, modalActions), dispatch)
   */
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(reduxUploadProfileImagetHost)
