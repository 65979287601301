import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { bindActionCreators } from 'redux'

/* Action Creators */
import * as tabActionCreators from '../../actions/tabs'

class Tabs extends React.Component {
  componentDidMount() {
    const { namespace, data, tabActions, selectedTab } = this.props
    if (typeof data.defaultTab !== 'undefined' && selectedTab === null) {
      tabActions.changeSelectedTab(data.defaultTab, namespace)
    }

    if (typeof data.defaultTab === 'undefined' && selectedTab === null) {
      tabActions.changeSelectedTab(data.tabs[0].name, namespace)
    }
  }

  render() {
    const {
      children,
      selectedTab,
      namespace,
      tabs,
      tabActions,
      data
    } = this.props
    // console.log('TAB ACTIONS', this.props);
    return children({
      changeTab: tabActions.changeSelectedTab,
      namespace,
      tabs: data.tabs,
      selectedTab: tabs[namespace]
    })
  }
}

const mapStateToProps = state => ({
  tabs: state.tabs
})

const mapDispatchToProps = dispatch => ({
  tabActions: bindActionCreators(tabActionCreators, dispatch)
})

// You have to connect() to any reducers that you wish to connect to yourself
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Tabs)
