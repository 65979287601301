import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Link } from 'react-router'
import * as modalActionCreators from '../../../actions/modals'
import * as experienceActionCreators from '../../../actions/experience'
import * as formActionCreators from '../../../actions/forms'
import * as hostActionCreators from '../../../actions/host'
import * as userActionCreators from '../../../actions/user'

// Validations
import { currentUserPropTypes } from '../../../constants/composed-proptypes'

// Form Steps
import ExperienceFirstPage from './GeneralInfoStep'
import ExperienceSecondPage from './SampleMenuStep'
import ExperienceThirdPage from './BuildMenuStep'
import ExperienceFourthPage from './PhotosStep'

class Experience extends Component {
  constructor(props) {
    super(props)
    this.nextPage = this.nextPage.bind(this)
    this.previousPage = this.previousPage.bind(this)
    this.submitAsDraft = this.submitAsDraft.bind(this)
    this.state = {
      currentExperience: null,
      currentExperienceMenu: null
    }
  }

  handleExperienceData = async data => {
    const { experienceActions } = this.props
    const experienceData = data

    delete experienceData.id
    experienceData.date = experienceData.start_date
    delete experienceData.time

    const modalMessage =
      'By submitting this experience, you agree to ' +
      `BreakBread's <a target='_blank' href='/terms-and-conditions'> Terms & Conditions</a> for Hosts`

    const a = await experienceActions.createExperience({
      experienceData,
      modalMessage
    })
    this.closeAndExit()
    // return a
  };

  handleCreateExperienceData = async data => {
    const { experienceActions, hostActions } = this.props
    // console.log('data==', data)
    const experienceData = 
    {
      title: data.title,
      date: data.date,
      time: data.time && data.time.value?data.time.value:data.time,
      duration: data.duration && data.duration.value?data.duration.value:data.duration,
      location_id: data.location_id && data.location_id.id?data.location_id.id:data.location_id,
      instant: data.instant,
      price:data.price,
      currency:data.currency && data.currency.value?data.currency.value:data.currency,
      seats:data.seats && data.seats.value?data.seats.value:data.seats,
      notes:data.notes,
      description:data.description,
      summary:data.summary,
      experience_type_id: data.experienceType.id
      /* "cuisine_types":[
             { "id":56, "name":"North Indian"}
        ],
        "menu_allergens":[
             { "id":56, "name":"Gluten Free"}
        ] */
    }
    if (data.id) {
      experienceData.id = data.id
    }
    const a = await experienceActions.createNewExperience({
      experienceData
    })
    // this.props.change('id', a.data.experience.id)
    await this.setState({currentExperience: a.data.experience})
    //this.closeAndExit()
    // hostActions.getExperiences()
    return a
  };

  handleCreateMenuForExperience = async experienceData => {
    const { experienceActions, hostActions } = this.props
    const response = await experienceActions.createMenuForExperience({
      experienceData
    })
    this.setState({currentExperienceMenu: response.data.experience})
    hostActions.getExperiences()
    return response
  }

  componentDidMount = async () => {
    await this.props.userActions.getCuisineTypesList()
    await this.props.userActions.getMenuAllergenList()
    await this.props.userActions.getMealCategoryList()
    await this.props.userActions.getMenuCourseList()
    await this.props.hostActions.getLocations()
    await this.props.hostActions.getSampleExperiences()
  }

  handleInitialExperienceData = async data => {
    const { experienceActions } = this.props
    const experienceData = data

    const modalMessage =
      'By submitting this experience, you agree to ' +
      `BreakBread's <a target='_blank' href='/terms-and-conditions'> Terms & Conditions</a> for Hosts`

    return experienceActions.createInitialExperience({
      experienceData,
      modalMessage
    })
  };

  closeAndExit() {
    const { modalActions, hostActions } = this.props
    hostActions.getExperiences()
    modalActions.hideCreateExperienceModal()
  }

  nextPage() {
    const { stepForm, formActions } = this.props
    formActions.updateExperienceFormStep(stepForm.page + 1)
  }

  previousPage() {
    // const { page } = this.state;
    // this.setState({ page: page - 1 });
    const { stepForm, formActions } = this.props
    formActions.updateExperienceFormStep(stepForm.page - 1)
  }

  async submitAsDraft(formValues, submit) {
    const { experienceActions } = this.props
    let type = 'draft'
    if (submit) {
      type = 'submit'
    }
    
    await experienceActions.submitAsDraftSubmit({
      formValues,
      type
    }) 

    const a = await experienceActions.createExperience({
      formValues,
      modalMessage: 'dummy'
    })
    this.closeAndExit()
  }

  render() {
    const { currentUser, stepForm, cuisineTypesOptions, menuAllergenOptions, mealCategoriesOptions, menuCoursesOptions, userActions, isUpdating } = this.props
    const { page } = stepForm
    return (
      <React.Fragment>
        {page === 1 && (
          <ExperienceFirstPage
            isUpdating={isUpdating}
            currentUser={currentUser}
            currentExperience={this.state.currentExperience}
            previousPage={this.previousPage}
            onSubmit={async data => {
              // console.log("@Page 1---", data)
              await this.handleCreateExperienceData(data)
              this.nextPage()
            }}
            nextPage={() => this.nextPage()}
          />
        )}
        {page === 2 && (
          <ExperienceSecondPage
            currentUser={currentUser}
            currentExperience={this.state.currentExperience}
            previousPage={this.previousPage}
            onSubmit={async data => {
              // console.log("@Page 2---", data)
              // await this.handleInitialExperienceData(data);
              this.nextPage()
            }}
            nextPage={() => this.nextPage()}
          />
        )}
        {page === 3 && (
          <ExperienceThirdPage
            isUpdating={isUpdating}
            nextPage={this.nextPage}
            previousPage={this.previousPage}
            currentExperience={this.state.currentExperience}
            cuisineTypesOptions={cuisineTypesOptions}
            menuAllergenOptions={menuAllergenOptions}
            mealCategoriesOptions={mealCategoriesOptions}
            currentExperienceMenu={this.state.currentExperienceMenu}
            menuCoursesOptions={menuCoursesOptions}
            userActions={userActions}
            onSubmit={async data => {
              await this.handleCreateMenuForExperience(data)
              this.nextPage()
            }}
            currentUser={currentUser}
          />
        )}
        {page === 4 && (
          <ExperienceFourthPage
            isUpdating={isUpdating}
            nextPage={this.nextPage}
            submitAsDraft={this.submitAsDraft}
            currentExperience={this.state.currentExperience}
            previousPage={this.previousPage}
            userActions={userActions}
            onSubmit={ async (data) => {
              // console.log("@Page 4---", data)
              // this.handleExperienceData(data)
              await this.submitAsDraft(data, 'submit')
            }}
            currentUser={currentUser}
          />
        )}
      </React.Fragment>
    )
  }
}

Experience.propTypes = {
  currentUser: currentUserPropTypes.isRequired,
  stepForm: PropTypes.shape({}).isRequired,
  formActions: PropTypes.shape({}).isRequired,
  experienceActions: PropTypes.shape({}).isRequired,
  modalActions: PropTypes.shape({}).isRequired,
  hostActions: PropTypes.shape({}).isRequired
}

const mapDispatchToProps = dispatch => ({
  formActions: bindActionCreators(formActionCreators, dispatch),
  experienceActions: bindActionCreators(experienceActionCreators, dispatch),
  modalActions: bindActionCreators(modalActionCreators, dispatch),
  hostActions: bindActionCreators(hostActionCreators, dispatch),
  userActions: bindActionCreators(userActionCreators, dispatch)
})
const mapStateToProps = state => ({
  pathname: state.router.location.pathname,
  currentUser: state.reduxTokenAuth.currentUser,
  stepForm: state.stepForm.experience,
  isUpdating: state.experience.experienceSingle.updating,
  cuisineTypesOptions: state.user.amenitiesAndWowElements.cuisineTypes
    ? state.user.amenitiesAndWowElements.cuisineTypes.list
    : null,
  menuAllergenOptions: state.user.amenitiesAndWowElements.menuAllergens
    ? state.user.amenitiesAndWowElements.menuAllergens.list
    : null,
  mealCategoriesOptions: state.user.amenitiesAndWowElements.mealCategories
    ? state.user.amenitiesAndWowElements.mealCategories.list
    : null,
  menuCoursesOptions: state.user.amenitiesAndWowElements.menuCourses
    ? state.user.amenitiesAndWowElements.menuCourses.list
    : null
})

export default connect(mapStateToProps, mapDispatchToProps)(Experience)
