import React, { Component } from 'react'

class PesonalDetail extends Component {

  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (

      <div className="breakbread-container">
      </div>

    )
  }
}

export default PesonalDetail
