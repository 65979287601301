import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { validate } from '../Registration/Hosts/validate'
import FileDropField from '../Form/Fields/renderFileDropField'
import renderFileField from '../Form/Fields/renderFileField'
/* ./../../Fields/renderFileField */
import alertModal from '../../utils/alert'
import * as userActionCreators from '../../actions/user'
import seo_meta from '../../utils/seo-meta.json'
import SEO from '../Shared/Web/SEO'

function keysMatch(key, obj) {
  const keys = Object.keys(obj).map(x => x.toLowerCase())
  return keys.indexOf(key.toLowerCase()) !== -1
}

/* NOTE: if currentUser form values match,they will be updated via
 * the initialValues of connectedStep5 which connects to Redux.
 * this happens automagically for all fields on the redux connected
 * form hostRegistration
 * @see https://redux-form.com/7.0.2/examples/initializefromstate/
 */

class ProfilePhotos extends Component {
  constructor(props) {
    super(props)
  }

  disableFields = fieldArray => {
    const { currentUser } = this.props
    if (typeof currentUser === 'undefined' || !currentUser.isSignedIn) {
      return fieldArray
    }
    const obj = fieldArray
    Object.keys(obj).map(key => {
      obj[key].disabled = !!keysMatch(key, currentUser.attributes)
      return obj[key]
    })
    return obj
  }

  /* handleHostData = async data => {
    const { userActions } = this.props
    const dataUser = {
      user: {
        food_photos: data.foodPhotos.map(el=>el.signedId).filter(el=>el),
      }
    }
    const response = await userActions.updateHostingFoodPhotos(dataUser, false, 'profile_photos')
    if (response.status == 200) {
      alertModal(
        'Profile Photos have been saved successfully.',
        'success',
        'Close'
      )
    } else {
      alertModal(
        'There is some network problem. Please try later.',
        'error',
        'Close'
      )
    }
  } */

  componentDidMount = async () => {
  }

  render() {
    const {
      valid,
      hostGalleryImages,
      userActions,
      hostForm
    } = this.props

    const profilePhotosPresent = () =>
      typeof hostGalleryImages !== 'undefined'

    const formValues = hostForm && hostForm.values ? hostForm.values : null

    return (
      <React.Fragment>
        <SEO title="Profile Picture | Breakbread" description="Profile Picture | Breakbread" />
        <form className="step5 bb-generic-form">
          <div className="page-header text-center">
            <h3>Profile Picture</h3>
          </div>
          <div className="form-body form-body--med">
            <div className="grid__item small--one-whole medium-up--full">
              {/* <label style={{ margin: "5px", textAlign: 'center' }}>Main Profile Picture</label> */}
              <div className="preview" >
                <div className="menuItem_image" style={{
                  backgroundImage: `url(${formValues && formValues.heroImage  && formValues.heroImage.big?formValues.heroImage.big + '?' + new Date().getTime() :''})`, backgroundRepeat: 'no-repeat', backgroundPosition:'center', backgroundSize:'cover', height: '200px', marginBottom: '16px', backgroundColor: '#f1f1f1'
                }} />
              </div>
              {/*  <div className="menuItems-field-group--dropzone"> */}
              <Field
                name="heroImage"
                label="Dish Image"
                id="fileUpload"
                type="file"
                accept=".jpg, .jpeg, .png"
                component={renderFileField}
                style={{ opacity: 0, height: 0, margin: 0, padding: 0 }}
                onClickCapture={()=>this.setState({uploadingStarted:true})}
                onChangeCapture={() => this.setState({ uploadingStarted: true })}
                updateImageDisplay={() => {}}
                singleImageUpload={async (e) => {
                  const heroImage =  await userActions.userHeroImageUpload(e)
                  this.props.change('heroImage', heroImage)
                  alertModal('Successfully Uploaded!', 'success', 'Close')
                }}
              />
              <button
                type="button"
                className="btn menu-image-btn"
                style={{maxWidth: '240px'}}
                id="btnUpload"
                // disabled={this.state.uploadingStarted}
                onClick={() => {
                  document.getElementById('fileUpload').click()
                }}
              >
              Click to Upload
              </button>
              {/*  </div> */}
            </div>
          </div>
          <div className="text-center" style={{marginTop: '-28px'}}>
            <h3> Photo Gallery </h3>
          </div>
          <div className="form-body form-body--med">
            <div className="grid__item small--one-whole medium-up--full">
              {/*  <label style={{ marginLeft: '15px', marginTop: '15px', textAlign: 'center'}}>Photo Gallery</label> */}
              <p className="m-5">
              Profile photos that best depict your experience.
                <br />
                (JPEG or PNG less than 2 MB, 1280×780 pixels. Aspect ratio 16:9)
              </p>
              <Field name="hostGalleryImages" label="" component={FileDropField} type="file" parentComponent="profilePhotos" imageFile={hostGalleryImages} />
            </div>
          </div>
          {/*  <div className="form-footer" style={{ marginTop: '10px' }}>
            <button
              type="button"
              disabled={!profilePhotosPresent()}
              onClick={async () => {
                await this.handleHostData(this.props.hostForm.values)
              }}
              className="btn btn--darker"
            >
              Save
            </button>
          </div> */}
        </form>
      </React.Fragment>
    )
  }
}

ProfilePhotos.propTypes = {
  // pristine: PropTypes.bool.isRequired,
  // submitting: PropTypes.bool.isRequired,
  // valid: PropTypes.bool.isRequired,
  // previousPage: PropTypes.func.isRequired,
  // handleSubmit: PropTypes.func.isRequired,
  // currentUser: currentUserPropTypes.isRequired,
  // locationPhotosValues: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
}

// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
const ProfilePhoto = reduxForm({
  form: 'hostRegistration',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  validate,
  enableReinitialize: true
})(ProfilePhotos)

const mapDispatchToProps = dispatch => ({
  /* register: user => dispatch(registerUser(user)),
  signIn: user => dispatch(signInUser(user)),
  signOut: user => dispatch(signOutUser(user)),
  dispatchUpdateUser: attributes => dispatch(updateUser(attributes)), */
  userActions: bindActionCreators(userActionCreators, dispatch)
})

const mapStateToProps = state => ({
  pathname: state.router.location.pathname,
  currentUser: state.reduxTokenAuth.currentUser,
  stepForm: state.stepForm.hostRegistration,
  initialValues: state.reduxTokenAuth.currentUser.attributes,
  /* foodPhotos:
    typeof state.form.hostRegistration !== 'undefined'
      ? state.form.hostRegistration.values.foodPhotos
      : null, */
  hostGalleryImages:
  state.form.hostRegistration && state.form.hostRegistration.values
    ? state.form.hostRegistration.values.hostGalleryImages
    : undefined,
  hostForm: state.form.hostRegistration
})

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePhoto)
