import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import ExperienceCard from '../../Experiences/ExperienceCard'
import ReceiptLink from '../../Receipt/ReceiptLink'
import GuestReviewLink from '../../Workflows/GuestReview/GuestReviewLink'

import { GUEST_RECEIPT } from '../../../constants/actionTypes'

const Card = ({
  reservation,
  viewReceipt,
  readReview,
  experience,
  callbackParams
}) => (
  <React.Fragment>
    <div className="reservation-card">
      <ExperienceCard experience={experience} displaySoldStatus />
      <div className="reservation-card__row">
        {!reservation.reviewedByGuest && reservation.status === 'Confirmed' && (
          <React.Fragment>
            <GuestReviewLink
              className="btn btn--full"
              reservation={reservation}
              callbackParams={callbackParams}
            >
              Write a Review
            </GuestReviewLink>

            <p>
              <span className="text-primary">
                Don't forget to leave a review!
              </span>
              {/* <small>Booking was {reservation.status}</small> */}
            </p>
          </React.Fragment>
        )}

        {reservation.reviewedByGuest && reservation.reviewedByHost && (
          <Link to="/users/diners">
            <button
              type="button"
              onClick={() => readReview(reservation)}
              disabled={reservation.status !== 'Confirmed'}
              className="btn btn--full"
            >
              Read Review About You1
              {/* <small>Booking was {reservation.status}</small> */}
            </button>
          </Link>
        )}

        {reservation.reviewedByGuest && !reservation.reviewedByHost ? (
          <GuestReviewLink
            className="btn btn--full"
            reservation={reservation}
            callbackParams={callbackParams}
          >
            Read your Review
          </GuestReviewLink>
        ) : (
          <GuestReviewLink
            className="btn btn--full"
            reservation={reservation}
            callbackParams={callbackParams}
          >
            Submit Review
          </GuestReviewLink>
        )}

        {reservation.status !== 'Confirmed' && (
          <h6>Status: {reservation.status}</h6>
        )}
      </div>
      {/* <div className="reservation-card__row">
        <ReceiptLink
          className="btn btn--dark"
          reservation={reservation}
          type={GUEST_RECEIPT}
          callbackParams={{ type: GUEST_RECEIPT }}
        >
          {reservation.status !== 'Confirmed' && (
            <React.Fragment>Booking not confirmed</React.Fragment>
          )}
          {reservation.status === 'Confirmed' && (
            <React.Fragment>View Receipt</React.Fragment>
          )}
        </ReceiptLink>
      </div> */}
    </div>
  </React.Fragment>
)

export default Card
