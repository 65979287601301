import React from "react"

const IconPlay = props => (
  <svg
    aria-hidden="true"
    className="icon icon-play"
    viewBox="0 0 18 32"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      d="M.263 0l17.071 15.944L.264 31.89"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
)

export default IconPlay
