import React from "react"

const Mustard = props => (
  <svg viewBox="0 0 88 88" width="1em" height="1em" {...props}>
    <g fill="currentColor" data-name="Layer 2">
      <circle cx={41} cy={55} r={1} />
      <path d="M51 38h-1v-2a1 1 0 0 0-1-1h-1.25L46 28.73a1 1 0 0 0-1-.73h-2a1 1 0 0 0-1 .73L40.25 35H39a1 1 0 0 0-1 1v2h-1a1 1 0 0 0-1 1v20a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V39a1 1 0 0 0-1-1zm-11-1h3a1 1 0 0 0 0-2h-.67l1.42-5h.5L46 36.27a1 1 0 0 0 1 .73h1v1h-8zm10 21H38V40h12z" />
      <path d="M41 45a1 1 0 0 0-1 1v6a1 1 0 0 0 2 0v-6a1 1 0 0 0-1-1z" />
      <path d="M44 88a44 44 0 1 1 44-44 44.05 44.05 0 0 1-44 44zm0-86a42 42 0 1 0 42 42A42 42 0 0 0 44 2z" />
    </g>
  </svg>
)

export default Mustard
