// Reducer

import {
  CHANGE_SELECTED_TAB,
  AUTH_SIGNOUT_SUCCESS
} from '../constants/actionTypes'

const initialState = {
  wallet: null,
  experiences: null,
  reviews: null
}

function tabs(state = initialState, action) {
  switch (action.type) {
  case CHANGE_SELECTED_TAB:
    return {
      ...state,
      [action.namespace]: action.tab
    }

  case AUTH_SIGNOUT_SUCCESS:
    return initialState

  default:
    return state
  }
}

export default tabs
