import React from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'

/* ICONS */
import AmericanExpressIcon from '../../icons/creditCards/AmericanExpressIcon'
import MastercardIcon from '../../icons/creditCards/MastercardIcon'
import PaypalIcon from '../../icons/creditCards/PaypalIcon'
import VisaIcon from '../../icons/creditCards/VisaIcon'

const PAYMENT_ICONS = {
  americanexpress: AmericanExpressIcon,
  mastercard: MastercardIcon,
  paypal: PaypalIcon,
  visa: VisaIcon
}

const PaymentCard = ({ paymentMethod, changeCard, isChecked, title }) => {
  const parentClassNames = `credit-card ${isChecked ? 'credit-card_checked' : ''}`
  const YieldIcon = paymentMethod ? PAYMENT_ICONS[paymentMethod.brand] : ''
  const paymentType = paymentMethod ? _.toLower(paymentMethod.brand) : 'empty'

  return (
    <div className={parentClassNames} onClick={() => changeCard(paymentMethod)} role="button" tabIndex={title ? 1 : 0} onKeyPress={() => null}>
      <div className={`credit-card__body credit-card_${paymentType}`}>
        {title && (
          <div className="credit-card__title">
            { title }
          </div>
        )}
        {!title && (
          <React.Fragment>
            <div className="credit-card__start">
              <div className="credit-card__name">
                <div className="credit-card__logo">
                  <YieldIcon className="credit-card__image" />
                </div>
              </div>
              <div v-if="showNumber" className="credit-card__number">
                *{paymentMethod.last4}
              </div>
            </div>
            <div className="credit-card__space" />
            <div className="credit-card__end">
              <div className="credit-card__exp">
                Exp {paymentMethod.exp_month}/{paymentMethod.exp_year}
              </div>
            </div>
            {isChecked && <div className="credit-card__mark" />}
          </React.Fragment>
        )}
      </div>
    </div>
  )
}

PaymentCard.defaultProps = {
  paymentMethod: null,
  isChecked: false,
  title: null
}

PaymentCard.propTypes = {
  paymentMethod: PropTypes.shape({
    exp_month: PropTypes.number.isRequired,
    exp_year: PropTypes.number.isRequired,
    last4: PropTypes.string.isRequired,
    brand: PropTypes.string.isRequired
  }),
  changeCard: PropTypes.func.isRequired,
  isChecked: PropTypes.bool,
  title: PropTypes.string
}
export default PaymentCard
