import React from 'react'
// import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import 'gsap/CSSPlugin'

/* Nav Dropdowns */
// import NavDropdown from 'react-bootstrap/lib/NavDropdown'
// import { LinkContainer } from 'react-router-bootstrap'
// import MenuItem from 'react-bootstrap/lib/MenuItem'
// import IconBell from '../../../icons/global/SVGJS/IconBell'
import NavAccountState from '../../User/NavAccountState'
/* ICONS */

export const RightNavItems = ({ currentUser, isMobile = false, toggleMobileMenu }) => {
  const menuClass = isMobile ? 'breakbread-panel-heading panel-heading' : ''
  const containerClass = isMobile ? 'breakbread-panel panel panel-default' : 'breakbread-navbar-transactional nav navbar-nav pull-right breakbread-navbar-nav'

  const handleMobileMenu = () => {
    if (typeof toggleMobileMenu !== 'undefined') {
      return false
    }
    return toggleMobileMenu({ closed: true })
  }
  return (
    <React.Fragment>
      <ul className={containerClass}>
        {/* Account Sate */}
        <NavAccountState isMobile />
      </ul>

      <ul className={containerClass}>
        {/*{currentUser.attributes.role !== 'host' && !isMobile && (*/}
        {!isMobile && (
          <li className="mobile-nav-hidden">
            {/*  <Link to="/become-a-host" className="breakbread-btn btn" onClick={() => handleMobileMenu()}>
              Become A Host
            </Link> */}
            <Link to="/experiences" className="breakbread-btn btn" onClick={() => handleMobileMenu()}>
              Book Experiences
            </Link>
          </li>
        )}

        {/*{currentUser.attributes.role === 'host' && (
          <li className={menuClass}>
             <Link to="/hosts/experiences/" className="breakbread-navbar-nav-item breakbread-navbar-nav-item--hosted-expriences" onClick={() => handleMobileMenu()}>
              Hosted Experiences
            </Link> 
            <Link to="/experiences" className="breakbread-btn btn" onClick={() => handleMobileMenu()}>
              Book Experiences
            </Link>
          </li>
        )}*/}

        {/* {currentUser.isSignedIn &&
          !isMobile && (
            <NavDropdown
              title={
                <React.Fragment>
                  <IconBell className="icon nav-icon" />
                </React.Fragment>
              }
              id="notification-dropdown"
              className="menuClass"
            >
              <MenuItem eventKey="1">
                <LinkContainer to="/users/experiences/">
                  <span>Experienes</span>
                </LinkContainer>
              </MenuItem>
              <MenuItem eventKey="2">
                <LinkContainer to="/users/reviews">
                  <span>
                    Reviews <span className="small"> (Guest)</span>
                  </span>
                </LinkContainer>
              </MenuItem>
              <MenuItem divider />
              <MenuItem eventKey="3">
                <LinkContainer to="/notifications">
                  <span>View All Notifications</span>
                </LinkContainer>
              </MenuItem>
            </NavDropdown>
          )} */}

        {/* {currentUser.isSignedIn &&
          isMobile && (
            <li className={menuClass}>
              <Link
                to="/notifications"
                className="breakbread-navbar-nav-item dropdown-toggle"
                data-toggle="dropdown"
                role="button"
                aria-expanded="false"
                onClick={() => handleMobileMenu()}
              >
                <IconBell className="icon nav-icon" /> Notifications
              </Link>
            </li>
          )} */}
      </ul>
    </React.Fragment>
  )
}
export const LeftNavItems = props => {
  const { currentUser, isMobile, toggleMobileMenu } = props
  const menuClass = isMobile ? 'breakbread-panel-heading panel-heading' : 'dropdown'
  const containerClass = isMobile ? 'breakbread-panel panel panel-default' : 'breakbread-navbar-main nav navbar-nav navbar-left breakbread-navbar-nav'

  // const handleMobileMenu = () => {
  //   if (typeof toggleMobileMenu !== 'undefined') {
  //     return false
  //   }
  //   return toggleMobileMenu({ closed: true })
  // }

  return (
    <ul className={containerClass}>
      <li className={menuClass}>
        <Link to="/" className="breakbread-navbar-nav-item dropdown-toggle" data-toggle="dropdown" role="button" aria-expanded="false" onClick={() => toggleMobileMenu()}>
          Home
        </Link>
      </li>
      {isMobile && (
        <li className={menuClass}>
          <Link to="/experiences" className="breakbread-navbar-nav-item dropdown-toggle" data-toggle="dropdown" role="button" aria-expanded="false" onClick={() => toggleMobileMenu()}>
            Book Experiences
          </Link>
        </li>
      )}
      <li className={menuClass}>
        <Link to="/hosts" className="breakbread-navbar-nav-item dropdown-toggle" data-toggle="dropdown" role="button" aria-expanded="false" onClick={() => toggleMobileMenu()}>
          Our hosts
        </Link>
      </li>

      {currentUser.attributes.role !== 'host' && !isMobile && (
        <li className={menuClass}>
          {/* <Link to="/experiences" className="breakbread-navbar-nav-item dropdown-toggle" data-toggle="dropdown"
            role="button" aria-expanded="false" onClick={() => toggleMobileMenu()}>
            Experiences
          </Link> */}
          <Link to="/become-a-host" className="breakbread-navbar-nav-item dropdown-toggle" data-toggle="dropdown" role="button" aria-expanded="false" onClick={() => toggleMobileMenu()}>
            Become A Host
          </Link>
        </li>
      )}
      {currentUser.attributes.role !== 'host' && isMobile && (
        <li className={menuClass}>
          <Link to="/become-a-host" className="breakbread-navbar-nav-item dropdown-toggle" onClick={() => toggleMobileMenu()}>
            Become A Host
          </Link>
        </li>
      )}

      <li className={menuClass}>
        <Link to="/blog/17-private-events" className="breakbread-navbar-nav-item dropdown-toggle" data-toggle="dropdown" role="button" aria-expanded="false" onClick={() => toggleMobileMenu()}>
          Private Events
        </Link>
      </li>

      {/* <li className={menuClass}>
        <Link to="/how-it-works" className="breakbread-navbar-nav-item dropdown-toggle"
          data-toggle="dropdown" role="button" aria-expanded="false" onClick={() => toggleMobileMenu()}>
          How It Works
        </Link>
      </li> */}

      <li className={menuClass}>
        <Link to="/blog" className="breakbread-navbar-nav-item dropdown-toggle" data-toggle="dropdown" role="button" aria-expanded="false" onClick={() => toggleMobileMenu()}>
          Blog
        </Link>
      </li>

      {/* {isMobile ? (
        <li className={menuClass}>
          <Link to="/about-us" className="breakbread-navbar-nav-item dropdown-toggle" data-toggle="dropdown" role="button" aria-expanded="false" onClick={() => toggleMobileMenu()}>
            Our Story
          </Link>
        </li>
      ) : (
        ''
      )}

      {isMobile ? (
        <li className={menuClass}>
          <Link to="/terms-and-conditions" className="breakbread-navbar-nav-item dropdown-toggle"
            data-toggle="dropdown" role="button" aria-expanded="false" onClick={() => toggleMobileMenu()}>
            Terms &amp; Conditions
          </Link>
        </li>
      ) : (
        ''
      )}
      {isMobile ? (
        <li className={menuClass}>
          <Link to="/privacy-policy" className="breakbread-navbar-nav-item dropdown-toggle"
            data-toggle="dropdown" role="button" aria-expanded="false" onClick={() => toggleMobileMenu()}>
            Privacy Policy
          </Link>
        </li>
      ) : (
        ''
      )}
      {isMobile ? (
        <li className={menuClass}>
          <Link to="/cookie-policy" className="breakbread-navbar-nav-item dropdown-toggle"
            data-toggle="dropdown" role="button" aria-expanded="false" onClick={() => toggleMobileMenu()}>
            Cookie Policy
          </Link>
        </li>
      ) : (
        ''
      )} */}
    </ul>
  )
}
