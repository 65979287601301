import React, {Component} from 'react'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'

import * as reservationActionCreators from '../../../actions/reservation'

/**
 * HOC that wraps the same common step so we can be DRY
 * @param {Object} props
*/

class PaymentForm extends Component {
  constructor(props) {
    super(props)
    const { dispatch, reservationActions } = this.props

    this.setCouponCode = reservationActions.setCouponCode.bind(this)

    // this.reservationCreators = bindActionCreators(reservationActionCreators, dispatch)
    // console.log('this.setcouponcode', this.setCouponCode)
  }

  componentDidMount() {
    const payNowButton = document.getElementById('pay-now-button')
    payNowButton.disabled = true
  }

  render() {
    const { nextPage, reservationActions } = this.props
    const { setCouponCode } = reservationActions
    // console.log('props for paymentform ', this.props)
    // console.log('setcouponcode in render ', setCouponCode)

    return (
      <React.Fragment>
        <div className="form-body">
          <div className="form-header">
            <h4>Add a voucher or coupon code if you have one</h4>
            <input
              component="input"
              type="text"
              name="couponCode"
              onChange={e => {
                const code = e.target.value
                const codeRegex = /\b[a-zA-Z]{13}\b/
                const payNowButton = document.getElementById('pay-now-button')
                if (code || code.match(codeRegex)) {
                  payNowButton.disabled = false
                  // console.log('Coupon code is ' + code)
                  setCouponCode({ coupon: code })
                } else payNowButton.disabled = true
              }}
              placeholder="Enter coupon code"
            />
          </div>
        </div>
        <div className="form-footer">
          <button
            id="pay-now-button"
            className="btn btn--darker form-footer--right"
            type="submit"
            form="payment-form"
            disabled={false}
            onClick={e => {
              e.preventDefault()
              nextPage()
            }}
          >
            Add Code
          </button>
        </div>
      </React.Fragment>
    )
  }
}

PaymentForm.propTypes = {
  nextPage: PropTypes.func.isRequired,
  coupon: PropTypes.string
}

// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
// const paymentForm = reduxForm({
//   form: 'coupon',
//   destroyOnUnmount: false,
//   forceUnregisterOnUnmount: false // <------ unregister fields on unmount
//   // validate
// })(PaymentForm);

const mapStateToProps = state => ({
  coupon: state.coupon
})

const mapDispatchToProps = dispatch => ({
  reservationActions: bindActionCreators(reservationActionCreators, dispatch)
})

// You have to connect() to any reducers that you wish to connect to yourself
// const connectedPaymentForm = connect(mapStateToProps)(PaymentForm);
const connectedPaymentForm = connect(mapStateToProps, mapDispatchToProps)(PaymentForm)

export default connectedPaymentForm
