import { REHYDRATE } from 'redux-persist'

import {
  FRAMES_SCRIPT_LOAD_STATUS,
  FRAMES_SCRIPT_INITIALIZED
} from '../constants/actionTypes'

import { FramesScriptLoadStatus } from '../constants/statuses'

// import TokenFormStatus from '../actions/paymentMethods';

const initialState = {
  scriptLoadStatus: FramesScriptLoadStatus.STARTED
}

export default function(state = initialState, action) {
  switch (action.type) {
  case FRAMES_SCRIPT_LOAD_STATUS:
    return {
      ...state,
      scriptLoadStatus: action.payload
    }
  case FRAMES_SCRIPT_INITIALIZED:
    return {
      ...state,
      scriptLoadStatus: action.payload
    }
  case REHYDRATE:
    return {
      ...state,
      scriptLoadStatus: FramesScriptLoadStatus.STARTED
    }
  default:
    return state
  }

  // if (action.type === REHYDRATE) {
  //   // const savedUser = action.payload.user || state;
  //   // return {
  //   //   ...state,
  //   //   accessToken: savedUser.accessToken,
  //   //   profile: savedUser.profile,
  //   //   payment: savedUser.payment
  //   // };
  // }
  //
  // return state;
}
