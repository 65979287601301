import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { goBack } from 'connected-react-router'
import { bindActionCreators } from 'redux'

// Validations
import { currentUserPropTypes } from '../../../constants/composed-proptypes'

// Actions
import * as modalActionCreators from '../../../actions/modals'
import * as formActionCreators from '../../../actions/forms'
import * as paymentMethodActionCreators from '../../../actions/paymentMethods'

/* Statuses */
import {
  TokenFormStatus,
  PaymentMethodStatus
} from '../../../constants/statuses'

// Form Steps
import PaymentForm from './Step1'
import PaymentConfirmation from './Step2'
// import ReservationSuccess from './Step3';

// import { SPLIT_GRID_SIZE } from '../../../constants';

class WalletForm extends Component {
  constructor(props) {
    super(props)
    this.nextPage = this.nextPage.bind(this)
    this.previousPage = this.previousPage.bind(this)

    // const { currentUser, formActions, stepForm } = this.props;
  }

  componentWillMount() {
    const { stepForm, formActions, paymentMethods } = this.props
    if (paymentMethods.paymentMethod !== null) {
      formActions.updateReservationFormStep(2)
    } else {
      formActions.updateReservationFormStep(1)
    }
  }

  componentDidMount() {}

  savePaymentMethod = async data => {
    // console.log('savePaymentMethod form data', data);
    /* We could use the data, or the token from our
    redux reducer directly */
    const { latestToken, paymentMethodActions } = this.props
    await paymentMethodActions.addPayment(latestToken)
    this.nextPage()
  };

  nextPage() {
    const { stepForm, formActions } = this.props
    formActions.updateReservationFormStep(stepForm.page + 1)
  }

  previousPage() {
    const { stepForm, formActions } = this.props
    formActions.updateReservationFormStep(stepForm.page - 1)
  }

  render() {
    const { currentUser, stepForm } = this.props
    const { page } = stepForm
    return (
      <React.Fragment>
        {page === 1 && (
          <PaymentForm
            nextPage={this.nextPage}
            previousPage={this.previousPage}
            onSubmit={data => this.savePaymentMethod(data)}
            currentUser={currentUser}
          />
        )}
        {page === 2 && (
          <PaymentConfirmation
            nextPage={this.nextPage}
            previousPage={this.previousPage}
            onSubmit={async data => {
              // console.log('CONFIRMATION SUBMIT', data);
            }}
            currentUser={currentUser}
          />
        )}
      </React.Fragment>
    )
  }
}

WalletForm.propTypes = {
  currentUser: currentUserPropTypes.isRequired,
  stepForm: PropTypes.shape({}).isRequired,
  formActions: PropTypes.shape({}).isRequired
}

const mapDispatchToProps = dispatch => ({
  backToPage: () => dispatch(goBack()),
  /* Bound Action Creators */
  modalActions: bindActionCreators(modalActionCreators, dispatch),
  formActions: bindActionCreators(formActionCreators, dispatch),
  paymentMethodActions: bindActionCreators(
    paymentMethodActionCreators,
    dispatch
  )
  /** NOTE: This would also be a nice way of doing it
   * https://github.com/reduxjs/react-redux/blob/master/docs/api.md#inject-todos-and-all-todoactioncreators-and-counteractioncreators-directly-as-props
   *
   * actions: bindActionCreators(Object.assign({}, modalActions), dispatch)
   */
})

const mapStateToProps = state => ({
  pathname: state.router.location.pathname,
  currentUser: state.reduxTokenAuth.currentUser,
  paymentMethods: state.paymentMethods,
  latestToken: state.paymentMethods.latestToken,
  stepForm: state.stepForm.reservation
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WalletForm)
