import { combineReducers } from 'redux'
// import { reduxTokenAuthReducer } from 'redux-token-auth';
// import { reducer as reduxFormReducer } from 'redux-form';
import { reducer as snacks } from 'react-notification-system-redux'
import user from './user'
import authExtended from './authExtended'
import experience from './experience'
import host from './host'
import modal from './modal'
import form from './form'
import stepForm from './stepForm'
import signUp from './signUp'
import paymentMethods from './paymentMethods'
import frameScript from './frameScript'
import reservationRequest from './reservationRequest'
import tabs from './tabs'
import transactions from './transactions'
import featured from './featured'
import notifications from './notifications'
import forgotPassword from './forgotPassword'
import cuisineTypes from './cuisineTypes'
import blogs from './blogs'
import coupons from './coupons'

export default combineReducers({
  user,
  experience,
  host,
  modal,
  form,
  stepForm,
  signUp,
  paymentMethods,
  frameScript,
  reservationRequest,
  tabs,
  transactions,
  featured,
  notifications,
  snacks,
  reduxTokenAuth: authExtended,
  forgotPassword,
  cuisineTypes,
  blogs,
  coupons
  // auth: reduxTokenAuthReducer
})
