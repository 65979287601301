import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import validate from './validate'

import { experienceInitialValues } from '../initialValues'

import General from '../../Form/CommonForms/Experience/General'

const GeneralInfo = props => {
  const formTitle = 'Edit Experience'
  return (
    <React.Fragment>
      <General {...props}  formtype="edit" formTitle={formTitle} />
    </React.Fragment>
  )
}

GeneralInfo.propTypes = {
  submitting: PropTypes.bool.isRequired,
  previousPage: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired
  // currentUser: currentUserPropTypes.isRequired,
}

// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
const reduxFormGeneralInfo = reduxForm({
  form: 'experienceEdit',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  validate
})(GeneralInfo)

// You have to connect() to any reducers that you wish to connect to yourself
const connectedGeneralInfo = connect(state => ({
  initialValues: experienceInitialValues(state.host.experienceEdit)
  // pull initial values from account reducer
}))(reduxFormGeneralInfo)

export default connectedGeneralInfo
