import React, { Component } from 'react'
// import loadjs from 'loadjs';
import PropTypes from 'prop-types'
import Navigation from '../components/Shared/Web/Navigation'
import Footer from '../components/Shared/Web/Footer'
import ModalRoot from '../components/Modal/ModalRoot'
import Content from '../components/Shared/Web/Content'
import SnackContainer from './SnackContainer'

export default class Main extends Component {
  constructor(props) {
    super(props)
    this.state = { isLoading: false }

    // hoisted binding required
  }

  getChildContext() {
    const { location } = this.props
    // Check if window exists
    const windowExists = typeof window !== 'undefined'
    // global grabs the variable from the index.html file
    const siteUrl = windowExists ? global.siteUrl : location.siteUrl

    return {
      siteUrl
    }
  }

  load = () => {
    this.setState({ isLoading: false })
  }

  componentWillMount = () => {
  }

  render() {
    const { children } = this.props
    const { isLoading } = this.state
    return (
      <React.Fragment>
        {isLoading
          ? null
          : (
            <div className="site-wrap">
              <Navigation />
              {/* eslint-disable-next-line react/jsx-props-no-spreading */}
              <Content {...this.props}>{children}</Content>
              <Footer />

              <ModalRoot />
              <div className="snack-container">
                <SnackContainer />
              </div>
            </div>
          )}
      </React.Fragment>
    )
  }
}

Main.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.func
  ]).isRequired,
  location: PropTypes.shape({
    siteUrl: PropTypes.string.isRequired
  }).isRequired
}
