// Libraries
import React from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

// Components
import BookExperienceLink from '../Workflows/Reservation/BookExperienceLink'
import HostReviewLink from '../Workflows/HostReview/HostReviewLink'
// import ConvertCurrency from '../../utils/convertCurrency'

export default function SeatRequest({
  experience,
  handleSeatAmount,
  handleSchedule,
  seatSelectOptions,
  scheduleSelectOptions,
  reservationRequest,
  currentUser,
  setPaymentModel,
  paymentModal,
  selectedSchedule,
  selectedSeats,
  showBookAseat,
  showBookAseatForSoldOut,
  reservationActions,
  dateError
}) {
  const cost = `${experience.currency} ${experience.price}`

  let inPast = false

  if (experience.dateWithTime) {
    if (moment(experience.dateWithTime).isBefore(moment(new Date()).utc())) {
      inPast = true
    }
  } else {
    inPast = true
  }
  // eslint-disable-next-line no-console
  // console.log('showBookAseatForSoldOut', showBookAseatForSoldOut, 'showBookAseat', showBookAseat, inPast, moment(new Date()).utc(), moment(experience.date).format('YYYY-MM-DD') + 'T' + experience.time + 'Z')

  let cardTitle = 'Request a Booking'

  if (experience.instant) {
    cardTitle = 'Make a Booking'
  }
  if (experience.status !== 'approved') {
    cardTitle = 'Experience Details'
  }

  if (experience.hasPassed) {
    cardTitle = 'Experience has ended'
  } else if (experience.status && experience.status === 'paused') {
    cardTitle = 'Experience is Paused'
  } else if (scheduleSelectOptions() && scheduleSelectOptions().length === 0) {
    cardTitle = 'Experience Sold Out'
  }

  const matchingSelectedSchedule = experience.schedules.find((s) => s.id === selectedSchedule)

  const flagFalse = false
  /*
  Case 1 - Schedule is yet to be created (schedule array is empty)
  Case 2 - Schedule is active and bookings can be made
  Case 3 - Schedule is active and But all seats are sold out
  Case 4 - Schedule is active but Bookings Closed (24 hour time)
  Case 5 - Schedule is paused - schedule status is paused or experience status is paused
  Case 6 - Schedule is over - Past Event 
  */
  return (
    <aside className="experience-single__seat-requests">
      <div className="experience-single__seat-request--header">
        <h4>{cardTitle}</h4>
      </div>
      <div className="experience-single__seat-request--content" style={{ textAlign: 'left', padding: '25px' }}>
        {experience.status === 'approved' && (
          <React.Fragment>
            <ul className="list-unstyled" style={{ marginBottom: '20px' }}>
              <li>
                <label>Time</label>
                <p>{moment(experience.time, 'HH:mm').format('h:mm A')}</p>
              </li>
              <li>
                <label>Duration</label>
                <p>{experience.duration.toString().replace('.0', '')} hours</p>
              </li>
              {/* <li>
                <label>Time</label>
                {experience.time}
              </li> */}
              {/* <li>
                <label>Start Time</label>
                {moment(experience.start_time, 'HH:mm').format('h:mm A')}
              </li>
              <li>
                <label>End Time</label>
                {moment(experience.end_time, 'HH:mm').format('h:mm A')}
              </li> */}
              <li>
                <label>Location</label>
                <p>
                  {experience.location.neighbourhood}, {experience.location.city}
                </p>
              </li>
              <li>
                <label>Cost</label>
                <p>{cost}</p>
              </li>
              {false && (
                <React.Fragment>
                  <li>
                    <label>Total Seats</label>
                    <p>{experience.seats}</p>
                  </li>
                  <li>
                    <label>Seats Available</label>
                    <p>{experience.seatsAvailable < 0 ? 0 : experience.seatsAvailable}</p>
                  </li>
                </React.Fragment>
              )}
            </ul>
            {/* {scheduleSelectOptions() && scheduleSelectOptions().length > 1 ? ( */}
            <p className="form-note" style={{ margin: '20px 0px' }}>
              {!experience.instant && 'Reservations are subject to approval from the host.'}
              {!experience.instant && <br />}
              {experience.id !== 549 && 'Reservations can be cancelled with full refund up to 48 hours before the start of the experience.'}
            </p>
            {/* ) : (<p>New Schedule Coming Soon</p>)} */}

            {flagFalse && currentUser.attributes.id === experience.host.id ? (
              <div className="experience-single__seat-request--content__btn-group ">
                <Link to={`/hosts/reservations/${experience.id}`}>
                  <button type="button" className="btn btn--full mb-5" style={{ minWidth: '240px' }}>
                    View Bookings{' '}
                  </button>
                </Link>

                {/* {experience.reviewsPending ? ( */}
                <HostReviewLink className="btn btn--full " experience={experience}>
                  Complete Guest Reviews
                </HostReviewLink>
                {/* ) : (
                  ''
                )} */}
              </div>
            ) : (
              <React.Fragment>
                {inPast ? (
                  <div>
                    <p className="text-success" style={{ color: '#f8a48d', fontWeight: 700, fontSize: '1.5rem', textAlign: 'center' }}>
                      New Schedule Coming Soon
                    </p>
                  </div>
                ) : (
                  <div className="experience-single__seat-request--content__btn-group">
                    {showBookAseatForSoldOut ? (
                      <div>
                        {experience.status !== 'paused' && showBookAseat ? (
                          <React.Fragment>
                            {scheduleSelectOptions() && scheduleSelectOptions().length > 1 && (
                              <p>
                                <select value={reservationRequest.schedules} onChange={handleSchedule}>
                                  {scheduleSelectOptions()}
                                </select>
                                {dateError ? <span style={{ color: 'red' }}>{dateError}</span> : ''}
                              </p>
                            )}
                            {(matchingSelectedSchedule && experience.id !== 549) && (
                              <React.Fragment>
                                <ul className="list-unstyled" style={{ marginBottom: '10px' }}>
                                  <li style={{ marginBottom: '11.1px' }}>
                                    <label>Seats Available</label>
                                    <p>
                                      {matchingSelectedSchedule.seatsAvailable <= 0 ? 0 : matchingSelectedSchedule.seatsAvailable} / {matchingSelectedSchedule.seats}
                                    </p>
                                  </li>
                                </ul>
                              </React.Fragment>
                            )}
                            {selectedSchedule && matchingSelectedSchedule && matchingSelectedSchedule.seatsAvailable > 0 ? (
                              <React.Fragment>
                                {selectedSchedule && (
                                  <p>
                                    <select value={reservationRequest.seats} onChange={handleSeatAmount}>
                                      {seatSelectOptions()}
                                    </select>
                                  </p>
                                )}
                                <p>
                                  <BookExperienceLink
                                    reservationActions={reservationActions}
                                    setPaymentModel={setPaymentModel}
                                    paymentModal={paymentModal}
                                    className="btn btn--full book-a-seat"
                                    selectedSeats={selectedSeats}
                                    selectedSchedule={selectedSchedule}
                                  >
                                    {experience.instant ? 'Book Now' : 'Request Booking'}
                                  </BookExperienceLink>
                                </p>
                              </React.Fragment>
                            ) : (
                              <React.Fragment>
                                {!selectedSchedule ? (
                                  <p>
                                    <BookExperienceLink
                                      reservationActions={reservationActions}
                                      setPaymentModel={setPaymentModel}
                                      paymentModal={paymentModal}
                                      className="btn btn--full book-a-seat"
                                      selectedSeats={selectedSeats}
                                      selectedSchedule={selectedSchedule}
                                    >
                                      {experience.instant ? 'Book Now' : 'Request Booking'}
                                    </BookExperienceLink>
                                  </p>
                                ) : (
                                  <p className="text-success" style={{ color: '#f8a48d', fontWeight: 700, fontSize: '2rem', textAlign: 'center' }}>
                                    Sold Out
                                  </p>
                                )}
                              </React.Fragment>
                            )}
                          </React.Fragment>
                        ) : (
                          <div>
                            <p style={{ color: '#f8a48d', fontWeight: 700, fontSize: '2rem', textAlign: 'center' }}>Paused</p>
                            <p>
                              <button type="button" className="btn btn--full mb-5" style={{ minWidth: '240px' }} disabled>
                                Book a Seat
                              </button>
                            </p>
                          </div>
                        )}
                      </div>
                    ) : (
                      <div>
                        <p className="text-success" style={{ color: '#f8a48d', fontWeight: 700, fontSize: '2rem', textAlign: 'center' }}>
                          Sold Out
                        </p>
                      </div>
                    )}
                  </div>
                )}
              </React.Fragment>
            )}
          </React.Fragment>
        )}
        {experience.status !== 'approved' && (
          <React.Fragment>
            <p className="text-success" style={{ color: '#f8a48d', fontWeight: 700, fontSize: '1.5rem', textAlign: 'center' }}>
              Coming Soon
            </p>
          </React.Fragment>
        )}
      </div>
    </aside>
  )
}

SeatRequest.defaultProps = {
  selectedSchedule: null,
  selectedSeats: null,
  showBookAseat: true,
  showBookAseatForSoldOut: false
}

SeatRequest.propTypes = {
  experience: PropTypes.shape({
    hasPassed: PropTypes.bool,
    paused: PropTypes.string,
    seatsAvailable: PropTypes.number || PropTypes.string,
    date: PropTypes.instanceOf(Date),
    dateWithTime: PropTypes.instanceOf(Date),
    price: PropTypes.number,
    currency: PropTypes.string,
    duration: PropTypes.string,
    time: PropTypes.string,
    status: PropTypes.string,
    reviewsPending: PropTypes.bool,
    hasBooked: PropTypes.bool,
    host: PropTypes.shape({
      id: PropTypes.number
    }),
    schedules: PropTypes.arrayOf(
      PropTypes.shape({
        status: PropTypes.string,
        seatsAvailable: PropTypes.number || PropTypes.string
      })
    ),
    seats: PropTypes.number,
    location: PropTypes.shape({
      city: PropTypes.string,
      countryCode: PropTypes.string,
      neighbourhood: PropTypes.string
    }),
    id: PropTypes.number,
    instant: PropTypes.bool
  }).isRequired,
  handleSeatAmount: PropTypes.func.isRequired,
  handleSchedule: PropTypes.func.isRequired,
  seatSelectOptions: PropTypes.func.isRequired,
  scheduleSelectOptions: PropTypes.func.isRequired,
  reservationRequest: PropTypes.shape({
    schedules: PropTypes.number,
    seats: PropTypes.number
  }).isRequired,
  setPaymentModel: PropTypes.func.isRequired,
  paymentModal: PropTypes.bool.isRequired,
  currentUser: PropTypes.shape({
    attributes: PropTypes.shape({
      id: PropTypes.number
    })
  }).isRequired,
  selectedSchedule: PropTypes.number,
  selectedSeats: PropTypes.number,
  showBookAseat: PropTypes.bool,
  showBookAseatForSoldOut: PropTypes.bool,
  dateError: PropTypes.string
}
