import React, { Component } from 'react'
import { connect } from 'react-redux'
import Select from 'react-select'
import ReactPaginate from 'react-paginate'
import QueryString from 'qs'
import PropTypes from 'prop-types'
// import { Link } from 'react-router-dom'
// import _ from 'lodash'

import ChefOrHostCard from '../Chef/ChefOrHostCard'
import SEO from '../Shared/Web/SEO'
// import Main from '../../containers/Main'

import { setFilter, getHosts } from '../../actions/host'
import { getCuisine } from '../../actions/cusineTypes'
// import { resetRoute } from '../../actions/nav'
// import Slider from 'react-slick'
// import seo_meta from '../../utils/seo-meta.json'
// import { object } from 'react-dom-factories'
// import ImageCarousel from '../ImageCarousel/ImageCarousel'
// import HostCarousel from './HostCarousel'
// import { Pagination } from 'react-bootstrap'
// import 'bootstrap-sass/assets/stylesheets/bootstrap/_pagination'

// const MobileDetect = require('mobile-detect')

const ratingOptions = [
  {
    value: '9',
    label: 'Rating - 9 & Up'
  },
  {
    value: '8',
    label: 'Rating - 8 & Up'
  },
  {
    value: '7',
    label: 'Rating - 7 & Up'
  },
  {
    value: '6',
    label: 'Rating - 6 & Up'
  },
  {
    value: '5',
    label: 'Rating - 5 & Up'
  },
  {
    value: '4',
    label: 'Rating - 4 & Up'
  },
  {
    value: '3',
    label: 'Rating - 3 & Up'
  },
  {
    value: '2',
    label: 'Rating - 2 & Up'
  },
  {
    value: '1',
    label: 'Rating - 1 & Up'
  }
]
class ExploreHostsTab extends Component {
  constructor(props) {
    super(props)
    this.state = {
      filter: {
        rating: null,
        cuisineTypes: null,
        perPage: 12,
        currentPage: 1
      },
      isLoading: true
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  async onRouteChanged() {
    const { location } = this.props
    const { filter } = this.state
    if(location.pathname === '/hosts') {
      await this.setState({
        filter: { ...filter, cuisineTypes: null }
      })
      await this.onSearch()
    }
  }

  async componentDidMount() {
    const { getAllCuisine, setFilters, location, match } = this.props
    const serchQuery = match.params
    if (location.search) {
      const { filter } = this.state
      const queryObj = QueryString.parse(location.search)
      const pageQuery = queryObj['?page'] || queryObj.page
      if (pageQuery) {
        await this.setState({ filter: { ...filter, currentPage: Number(pageQuery) } })
      }
    }
    const { filter } = this.state
    await setFilters(filter)
    getAllCuisine()

    // let rating = ''
    let cousine = ''
    // if (typeof serchQuery.rating !== 'undefined') {
      // rating = serchQuery.rating === 'rating-all' ? '' : serchQuery.rating.split('-')[1]
    cousine = serchQuery.cuisine === 'all-cuisines' ? '' : serchQuery.cuisine
    const cuisineTypes = []
      // let ratingValue = {}
      // if (rating) {
      //   Object.keys(ratingOptions).forEach(key => { // for (const key in ratingOptions) {
      //     if (rating === ratingOptions[key].value) {
      //       ratingValue = ratingOptions[key]
      //     }
      //   })
      // }

      // this.setCapitalize(

      if (cousine) {
        const allCuisine = cousine.split(',')
        Object.keys(allCuisine).forEach(key => { // for (const key in allCuisine) {
          cuisineTypes.push({
            value: allCuisine[key],
            label: allCuisine[key]
          })
        })
      }

      // "Meet Our Hosts (Cuisine Type: "+cousine+") (Rating: "+rating+")";

      this.setState(
        {
          filter: { ...filter, cuisineTypes }
          // , rating: ratingValue }
        },
        () => {
          this.onSearch()
        }
      )
    // } else {
    //   this.fetch()
    // }

    // const rating_title = rating ? `(Rating: ${rating})` : ''
    // const cuisine_title = cousine ? `(Cuisine Type: ${cousine})` : ''
    // const PageTitle = serchQuery ? `Meet Our Hosts ${cuisine_title} ${rating_title}` : seo_meta.hosts.title
  }

  async onSearch() {
    const { filter } = this.state
    const { setFilters } = this.props
    setFilters(filter)
    this.fetch()
  }

  handleRatingChange = (rating) => {
    const { filter } = this.state
    this.setState(
      {
        filter: { ...filter, currentPage: 1, rating }
      },
      () => {
        this.onSearch()
        this.redirectPath()
      }
    )
  }

  loadMore = (currentPage) => {
    const { filter } = this.state
    this.setState(
      {
        filter: { ...filter, currentPage }
      },
      () => {
        this.onSearch()
        this.redirectPath()
      }
    )
  }

  handleCuisineTypeChange = (cuisineTypes) => {
    const { filter } = this.state
    this.setState(
      {
        filter: { ...filter, currentPage: 1, cuisineTypes }
      },
      () => {
        this.onSearch()
        this.redirectPath()
      }
    )
  }

  createCuisineTypeList = (_hosts) => {
    const { cuisineTypes } = this.props
    return cuisineTypes.map(c => ({ value: c.name, label: c.name }))
  }

  handlePageClick = async (data) => {
    const { selected } = data
    await this.loadMore(selected + 1)
    window.scrollTo({
      top: 100,
      behavior: 'smooth'
    })
  }

  pageHrefBuilder = currentPage => {
    const { filter } = this.state
    const rating = filter.rating ? filter.rating.value : ''
    // const currentPage = filter.currentPage ? filter.currentPage + 1 : 2
    const cuisineTypes = filter.cuisineTypes ? filter.cuisineTypes.map((cuisine) => cuisine.value) : []
    const queryStringCuisine = cuisineTypes.toString() ? cuisineTypes.toString() : 'all-cuisines'
    // const queryStringRating = rating ? `rating-${rating}` : 'rating-all'
    return `/hosts/${queryStringCuisine.toLowerCase()}${currentPage ? `?page=${currentPage}` : ''}`
  }

  resetFiltering() {
    this.setState(
      {
        filter: {
          rating: null,
          cuisineTypes: null
        }
      },
      () => {
        this.onSearch()
        this.redirectPath()
      }
    )
  }

  async fetch() {
    const { fetchHosts } = this.props
    this.setState({ isLoading: true })
    try {
      await fetchHosts()
      this.setState({ isLoading: false })
    } catch (e) {
      this.setState({ isLoading: false })
    }
  }

  setCapitalize = str => {
    return str.charAt(0).toUpperCase() + str.slice(1)
  }

  redirectPath() {
    const { filter } = this.state
    // const rating = filter.rating ? filter.rating.value : ''
    const currentPage = filter.currentPage ? filter.currentPage : 1
    const cuisineTypes = filter.cuisineTypes ? filter.cuisineTypes.map((cuisine) => cuisine.value) : []

    const queryStringCuisine = cuisineTypes.toString() ? cuisineTypes.toString() : 'all-cuisines'
    // const queryStringRating = rating ? `rating-${rating}` : 'rating-all'

    // const { history } = this.props
    // history.push({ pathname: `/hosts/${queryStringCuisine}/${queryStringRating}${currentPage ? `?page=${currentPage}` : ''}` })
    // , null, `/hosts/${queryStringCuisine}/${queryStringRating}${currentPage ? `?page=${currentPage}` : ''}`)
    history.pushState({}, null, `/hosts/${queryStringCuisine.toLowerCase()}${currentPage ? `?page=${currentPage}` : ''}`)
  }

  render() {
    const { hosts } = this.props
    const { filter, isLoading } = this.state
    const filteredHosts = hosts.hosts
    const { total_count: totalCount, per_page: perPage } = hosts.hostsMeta

    const cuisineTypeOptions = this.createCuisineTypeList(hosts.hosts)
    const currentPage = filter.currentPage ? filter.currentPage - 1 : 0
    const gridSize = 'grid__item small--one-half medium-down--one-half medium--one-half large-up--one-quarter'
    const fieldGridSize = 'grid__item medium-down--one-third large-up--one-third'

    const cuisineTypevalue = (filter.cuisineTypes !== null) ? filter.cuisineTypes.map(x => ({
      value: this.setCapitalize(x['value']),
      label: this.setCapitalize(x['value'])
    })) : null
    return (
      <div className="breakbread-container">
        <div className="section-header text-center">
          <SEO title="Meet Our Hosts" />
          <h1 className="page-header">Meet Our Hosts</h1>
          <div className="field-grid--container">
            <div className="grid field-grid">
              <div className={fieldGridSize}> </div>
              <div className={fieldGridSize}>
                <Select
                  placeholder="Cuisine Type"
                  options={cuisineTypeOptions}
                  onChange={this.handleCuisineTypeChange}
                  value={cuisineTypevalue}
                  className="react-select"
                  isClearable
                  classNamePrefix="select"
                  isMulti
                />
              </div>
              {false && (
                <div className={fieldGridSize}>
                  <Select placeholder="Rating" options={ratingOptions} onChange={this.handleRatingChange} value={filter.rating} className="react-select" isClearable classNamePrefix="select" />
                </div>
              )}
              <div className={fieldGridSize}>
                {((filter.cuisineTypes && filter.cuisineTypes.length > 0) || (filter.rating && Object.keys(filter.rating).length > 0)) && (
                  <button type="button" className="breakbread-btn btn--flat" onClick={() => this.resetFiltering()}>
                    Clear Search
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className="grid grid--uniform">
            {isLoading && typeof filteredHosts === 'undefined' && <div className="spinner spinner--empty" />}
            {!isLoading && filteredHosts.length === 0 ? (
              <div className="text-center text-warning">
                <div className="section-header section-heading">
                  <h5>No Results were found for your selection</h5>
                </div>
                <p>
                  <button
                    type="button"
                    onClick={() => {
                      this.resetFiltering()
                    }}
                    className="btn"
                  >
                    Clear Search
                  </button>
                </p>
              </div>
            ) : (
              <div className="grid grid--uniform">
                {filteredHosts.length > 0
                  ? (
                    <React.Fragment>
                      {filteredHosts.map((host) => (
                        <div key={host.id} className={gridSize}>
                          <ChefOrHostCard person={host} />
                        </div>
                      ))}
                    </React.Fragment>
                  ) : ''}
              </div>
            )}
          </div>

          {totalCount > perPage && (
            <div className="grid grid--uniform flexCenter mb_5">
              <div className="margin_5">
                <ReactPaginate
                  previousLabel="Back"
                  nextLabel="Next"
                  breakLabel="..."
                  breakClassName="break-me-hosts"
                  containerClassName="pagination"
                  pageCount={Math.ceil(totalCount / perPage)}
                  hrefBuilder={this.pageHrefBuilder}
                  marginPagesDisplayed={1}
                  pageRangeDisplayed={2}
                  onPageChange={this.handlePageClick}
                  forcePage={currentPage}
                  subContainerClassName="pages pagination"
                  activeLinkClassName="btn"
                  pageLinkClassName=""
                  pageClassName="pagination__text"
                  activeClassName="active"
                />
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }
}

ExploreHostsTab.propTypes = {
  getAllCuisine: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  setFilters: PropTypes.func.isRequired,
  location: PropTypes.shape({
    search: PropTypes.string
  }).isRequired,
  hosts: PropTypes.shape({
    hosts: PropTypes.arrayOf(PropTypes.shape({})),
    hostsMeta: PropTypes.shape({
      total_count: PropTypes.number,
      per_page: PropTypes.number
    })
  }).isRequired,
  fetchHosts: PropTypes.func.isRequired,
  cuisineTypes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  match: PropTypes.shape({
    params: { rating: PropTypes.string, cuisine: PropTypes.string }
  }).isRequired
}

const mapStateToProps = (state) => ({
  hosts: state.host.hosts,
  filter: state.host.filter,
  cuisineTypes: state.cuisineTypes.allCuisine
  // cuisineTypes: state.host.cuisineTypes,
})

const mapDispatchToProps = (dispatch) => ({
  setFilters: (filter) => dispatch(setFilter(filter)),
  getAllCuisine: () => dispatch(getCuisine()),
  fetchHosts: () => dispatch(getHosts())
})

export default connect(mapStateToProps, mapDispatchToProps)(ExploreHostsTab)
