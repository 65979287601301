import {
  validateStringValues,
  validateSelect,
  selectPresent,
  nestedPresent,
  isValidURL,
  photoPresent
} from '../../../utils/validation'
import moment from 'moment'
const validate = values => {
  let errors = {}
  const menuErrors = {}
  const stringValues = ['title', 'price', 'description', 'summary']
  errors = validateStringValues(stringValues, values, errors)

  const singleSelects = ['time', 'currency', 'seats', 'experienceType', 'duration', 'location_id']
  errors = validateSelect(singleSelects, values, errors)

  const multiSelects = ['cuisineTypesAttributes']
  errors = validateSelect(multiSelects, values, errors)

  /* if(moment(values.start_date).isAfter(values.end_date)){
    errors['end_date']="Please select end Date greater than start date."
  } */
  /* if(values.StartTime != undefined &&  values.EndTime != undefined){
    let startDate = moment(values.start_date).format("L") 
    let diff = moment(startDate+" "+values.EndTime.label).diff(moment(startDate+" "+values.StartTime.label), 'hours')
    if(diff < 2){
      errors['EndTime']="There should be 2 hour difference in start time and end time."
    }
  } */
  
  /* On Nested Attributes */
  if (nestedPresent(values.menuAttributes, 'menuAllergensAttributes')) {
    // if (!values.menuAttributes.menuAllergensAttributes.length) {
    //   menuErrors = validateSelect(
    //     ['menuAllergensAttributes'],
    //     values,
    //     menuErrors
    //   );
    // }
  }

  /* On Nested Attributes */
  if (!nestedPresent(values.menuAttributes, 'menuItemsAttributes')) {
    errors.menuAttributes.menuItemsAttributes = {
      _error: 'At least one menuItem must be entered'
    }
  }

  if (menuErrors) {
    errors.menuAttributes = menuErrors
  }

  if (nestedPresent(values.menuAttributes, 'menuItemsAttributes')) {
    const menuItemsArrayErrors = []
    values.menuAttributes.menuItemsAttributes.forEach(
      (menuItem, menuItemIndex) => {
        let menuItemErrors = {}

        // Validate all strings Fields
        menuItemErrors = validateStringValues(
          ['title', 'description'],
          menuItem,
          menuItemErrors
        )

        // Validate all select Fields
        menuItemErrors = validateSelect(['course'], menuItem, menuItemErrors)

        /* if (!photoPresent(menuItem.menu_item_photo)) {
          const message = {
            _error: 'Dish Image is missing'
          }
          menuItemErrors.menu_item_photo = message
        } */

        menuItemsArrayErrors[menuItemIndex] = menuItemErrors
      }
    )
    if (
      menuItemsArrayErrors.length &&
      typeof errors.menuAttributes !== 'undefined'
    ) {
      errors.menuAttributes.menuItemsAttributes = menuItemsArrayErrors
    }
  }

  if (!values.location) {
    const locationErrors = {}
    locationErrors.postalCode = 'Required'
    locationErrors.countryCode = 'Required'
    locationErrors.streetAddress = 'Required'
    locationErrors.city = 'Required'
  } else {
    const locationErrors = {}

    // if (!values.location.postalCode) {
    //   locationErrors.postalCode = 'Postal Code is Required';
    // }
    if (!values.location.countryCode) {
      locationErrors.countryCode = 'Country Code is Required'
    } else if (values.location.countryCode.length === 0) {
      if (!values.location.countryCode.value) {
        locationErrors.countryCode = 'Country Code is Required'
      }
    }

    if (!values.location.streetAddress) {
      locationErrors.streetAddress = 'Street Address is Required'
    }

    if (!values.location.city) {
      locationErrors.city = 'City is Required'
    }

    if (locationErrors) {
      errors.location = locationErrors
    }
  }

  return errors
}

export default validate
