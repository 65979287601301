import React, { Component } from 'react'
/* import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux' */
import ExperienceCard from '../../Experiences/ExperienceCard'
import { Link } from 'react-router-dom'
import Flickity from 'flickity-fullscreen'
import _ from 'lodash'
import 'flickity-fullscreen/fullscreen.css'
import 'flickity-as-nav-for'

class ExperienceSlider extends Component {
  constructor(props) {
    super(props)
    this.state = {
      flkty: null,
      flktynav: null
    }
  }

  componentDidMount() {
    this.initSlider()
    if (typeof window !== 'undefined') {
      // window.addEventListener('resize', this.handleResize);
    }
  }

  componentDidUpdate() {
    // this.initSlider();
  }

  initSlider = () => {
    // const { uniquename, thumbnav } = this.props
    const elem = document.querySelector(`#experiencecard`)
    if (elem) {
      const flkty = new Flickity(elem, {
        fullscreen: false,
        cellAlign: 'left',
        pageDots: false
      })
      this.setState({ flkty })
    }
    /* if (thumbnav) {
      const navElem = document.querySelector(`#${uniquename}-nav`)
      if (elem) {
        const flktyNav = new Flickity(navElem, {
          fullscreen: false,
          asNavFor: `#${uniquename}`,
          contain: true,
          pageDots: false
        })
        this.setState({ flktyNav })
      }
    } */
  };

  render() {
    const { sortedExperiences, isMobile, gridSize } = this.props
    return (
      <React.Fragment>
        {sortedExperiences.length && (
        /*  <section className="photography"> */
          <div className="image-carousel-fullscreen" id="experiencecard">
            {sortedExperiences.map((experience, index) => {
              // TODO: fix handling brackets with escaping('\') instead of replacing
              return (
              /* eslint-disable-next-line react/no-array-index-key */
                <div className={gridSize} key={`ExperienceCard-${experience.id}`}>
                  <ExperienceCard experience={experience} />
                </div>
              )
            })}
          </div>
        )}
      </React.Fragment>
    )
  }
}
export default ExperienceSlider
