import React from "react"

const Gmo = props => (
  <svg viewBox="0 0 88 88" width="1em" height="1em" {...props}>
    <g fill="currentColor" data-name="Layer 2">
      <path d="M50 44h-2a1 1 0 0 0-1 1v11a3 3 0 0 1-6 0V46h2v6a1 1 0 0 0 2 0v-9.1c2.49-.38 4-2.22 4-5 0-3-3.78-8-4.21-8.53a1 1 0 0 0-1.58 0c-.43.55-4.21 5.5-4.21 8.53a4.77 4.77 0 0 0 4 5V44h-5a1 1 0 0 0 0 2h1v10a5 5 0 0 0 10 0V46h1a1 1 0 0 0 0-2zm-9-6.1c0-1.47 1.61-4.25 3-6.23 1.39 2 3 4.76 3 6.23 0 2.55-1.63 3.1-3 3.1a2.85 2.85 0 0 1-3-3.1z" />
      <path d="M44 88a44 44 0 1 1 44-44 44.05 44.05 0 0 1-44 44zm0-86a42 42 0 1 0 42 42A42 42 0 0 0 44 2z" />
    </g>
  </svg>
)

export default Gmo
