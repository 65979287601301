import { combineReducers } from 'redux'

import cuisineTypes from './cuisineTypes'
import experienceSingle from './experienceSingle'
import filter from './filter'
import experienceIndex from './experienceIndex'

export default combineReducers({
  filter,
  experienceSingle,
  experienceIndex,
  cuisineTypes
})
