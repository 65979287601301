import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import PropTypes from 'prop-types'
import _ from 'lodash'
import axios from 'axios'

// Components
import ChefOrHostCard from '../../Chef/ChefOrHostCard'
import CMSSection from '../../CMS/Section'

// Constants
import staticCmsData from '../../../utils/cmsData.json'
import { HOST } from '../../../constants'
import { snakeToCamel } from '../../../utils/camelSnake'

// import seo_meta from '../../../utils/seo-meta.json'
// Sections
import BackgroundSection from '../../Sections/BackgroundSection'

// Actions
import { getFeatured } from '../../../actions/featured'

// Icons & SVGs
import StepIcon1 from '../../../icons/landing-pages/Icon1BecomeHost'
import StepIcon2 from '../../../icons/landing-pages/Icon2BecomeHost'
import StepIcon3 from '../../../icons/landing-pages/Icon3BecomeHost'
import SEO from '../../Shared/Web/SEO'

const CMS_SECTION_API_ENDPOINT = `${HOST}/api/v2`

class BecomeAChef extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: true,
      cmsData: undefined
    }
  }

  /* componentWillMount() {
    this.fetch()
  } */

  componentDidMount() {
    this.fetch()
    this.fetchCmsData({ page: 'becomeahost' })
  }

  setCmsDataToState(cmsData) {
    this.setState({
      cmsData,
      isLoading: false
    })
  }

  async fetch() {
    const { fetchFeatured } = this.props
    const { isLoading } = this.state
    if (typeof isLoading !== 'undefined') {
      this.setState({ isLoading: true })
    }
    await fetchFeatured()
    if (typeof isLoading !== 'undefined') {
      this.setState({ isLoading: false })
    }
  }

  async fetchCmsData({ page }) {
    try {
      await axios.get(`${CMS_SECTION_API_ENDPOINT}/cms/pages/${page}`, { mode: 'no-cors' })
        .then((res) => {
          if (res.data.is_success) {
            this.setCmsDataToState(snakeToCamel(res.data.page_info, 4))
          } else {
            const matchingPage = _.find(staticCmsData, { page })
            this.setCmsDataToState(matchingPage)
          }
        })
    } catch (e) {
      const matchingPage = _.find(staticCmsData, { page })
      this.setCmsDataToState(matchingPage)
    }
  }

  render() {
    const { featuredHosts = [], currentUser } = this.props
    const { hostApplication } = currentUser.attributes
    const { isLoading, cmsData } = this.state

    const gridSize = 'grid__item small--one-whole medium-down--one-half medium--one-half large-up--one-third'

    return (
      <React.Fragment>
        <SEO title="Become A Host" />
        {hostApplication === 'approved' ? (
          <CMSSection data={cmsData} isLoading={isLoading} sectionName="hero4" type="hero" theme="dark" page="becomeahost" currentUser={currentUser}/>
        ) : (
          <CMSSection data={cmsData} isLoading={isLoading} sectionName="hero5" type="hero" theme="dark" page="becomeahost" currentUser={currentUser}/>
        )}

        <BackgroundSection size="medium" color="#ffffff">
          <div className="grid grid--uniform">
            <div className={gridSize}>
              <div className="break-bread--value-prop">
                <StepIcon1 width="195" />
                <h4>Apply to be a Host</h4>
                <p>
                  Apply here, and we’ll help get you started. Be creative! Tell us about the first dish you burned or how you turned someone into your biggest fan!
                </p>
              </div>
            </div>

            <div className={gridSize}>
              <div className="break-bread--value-prop">
                <StepIcon2 width="195" />
                <h4>Create Your Experience</h4>
                <p>
                  As a host, the next step is to go live with your experience! This is when people can find your supperclub and book an experience with you.
                </p>
              </div>
            </div>

            <div className={gridSize}>
              <div className="break-bread--value-prop">
                <StepIcon3 width="195" />
                <h4>Host your Experience!</h4>
                <p>
                  Congrats! You have people booking your supperclub, now you get to share your love of cooking and hosting, and most importantly, your delicious food!
                </p>
              </div>
            </div>
          </div>
        </BackgroundSection>

        {/* <CMSSection data={cmsData} isLoading={isLoading} sectionName="hero6" type="hero" theme={null} page="becomeahost" currentUser={currentUser}/> */}
        <BackgroundSection size="medium" color="#9f9f9f">
          <h2>Meet Our Hosts</h2>

          <div className="grid grid--uniform">
            {isLoading && typeof featuredHosts === 'undefined'
            && <div className="spinner" />}

            {!isLoading && featuredHosts.length > 0
            && featuredHosts.slice(0, 3).map((user, i) => (
              /* eslint-disable-next-line react/no-array-index-key */
              <div className="grid__item medium-up--one-third" key={`featuredHosts-${i}`}>
                <ChefOrHostCard person={user} />
                <p>{user.description}</p>
              </div>
            ))}
          </div>

          <Link to="/hosts" className="breakbread-btn breakbread-btn-browseallhost">
            Browse Hosts
          </Link>
        </BackgroundSection>

        <CMSSection data={cmsData} isLoading={isLoading} sectionName="hero7" type="hero" theme="dark" page="becomeahost" currentUser={currentUser}/>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  featuredExperiences: state.featured.featuredExperiences,
  featuredHosts: state.featured.featuredHosts,
  // featuredReviews: state.featured.featuredReviews,
  currentUser: state.reduxTokenAuth.currentUser
})

BecomeAChef.defaultProps = {
  featuredHosts: []
}

BecomeAChef.propTypes = {
  fetchFeatured: PropTypes.func.isRequired,
  currentUser: PropTypes.shape({}).isRequired,
  featuredHosts: PropTypes.arrayOf(PropTypes.shape({}))
}

const mapDispatchToProps = dispatch => ({
  fetchFeatured: () => dispatch(getFeatured())
})

export default connect(mapStateToProps, mapDispatchToProps)(BecomeAChef)
