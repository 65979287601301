import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import ChefAvtarHost from '../Chef/ChefAvtarHost'
import ChefInfo from '../Chef/ChefInfo'
import HostActionButttons from './Host/HostActionButttons'
import moment from 'moment'
// import ChefAvatarImage from '../../images/placeholders/chef-avatar@2x.png'
// /import EditExperienceLink from './../../Workflows/ExperienceEdit/EditExperienceLink';
// import ViewReceipt from './ViewReceipt';

export default class HostExperienceCard extends Component {
  // constructor(props) {
  //   super(props);
  // }
  componentDidMount() {}

  componentWillUnmount() {}

  render() {
    const {
      experience,
      featured,
      hideAvatar,
      forceHoverState,
      displaySoldStatus,
      experienceType,
      showModalSchedule
    } = this.props
    const now = new Date()
    const hasPassed = experience.date < now.toISOString()
    let extraClassNames = ''
    extraClassNames += featured ? ' experience-card--featured' : ''
    extraClassNames += forceHoverState ? ' grid-view-item--force-hover' : ''
    extraClassNames += hideAvatar ? ' experience-card--avatar-hidden' : ''
    experience.readableTime = experience.time
      ? experience.time
      : experience.readableTime
    // console.log('experience', experience)
    let statusClass = 'badge '

    switch (experience.status) {
    case 'approved':
      statusClass = 'badge badge-success'
      break
    case 'paused':
      statusClass = 'badge badge-warn'
      break
    case 'rejected':
      statusClass = 'badge badge-rejected'
      break
    case 'submitted':
      statusClass = 'badge badge-pending'
      break
    default:
      statusClass = 'badge badge-pending'
      break
    }
    const sortedSchedules = experience.schedules?experience.schedules.filter(e => moment(e.date, 'YYYY-MM-DD').isSameOrAfter()).sort((a, b) => moment(a.date, 'YYYY-MM-DD') - moment(b.date, 'YYYY-MM-DD')).splice(0, 3):[] // .sort((a, b) => a.dat)
    return (
      <React.Fragment>
        <div className={`experience-card grid-view-item ${extraClassNames}`}>
          <div className="experience-card__image--container grid-view-item__link grid-view-item__image-container">
            <div className="grid-view-item__image-wrapper js">
              <Link to={`/experiences/${experience.slug}`}>
                <div id="" className="grid-view-item__image-wrapper js">
                  <div className="grid-view-item__image-container">
                    <div
                      className="grid-view-item__overlay box ratio-container lazyload js"
                      // NOTE: NEED TO ADD LAZY lOAD SUPPORT
                      data-sizes="auto"
                      data-parent-fit="cover"
                      style={{
                        backgroundImage: `url("${experience.image}")`
                      }}
                    />
                    <div className="experience-card__hover-meta grid-view-item_hover-el">
                      <span>
                        {experience.summary.length > 120
                          ? `${experience.summary.substring(0, 120)}....`
                          : experience.summary}
                      </span>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
          <div className="experience-card__meta grid-view-item__meta">
            <div className="experience-card__high-level">
              <div className="experience-card__location"> {experience.location?experience.location.city:''}</div>
              {/* <div className="experience-card__tools">Save Icon</div> */}
              <div className="experience-card__tools text-uppercase">
                <b>
                  {/* {experience.status === 'paused' ? (
                    'Paused'
                  ) : experience.featured ? (
                    'Featured'
                  ) : (
                    ''
                  )} */}
                  <span className={statusClass}>
                    {experience.status === 'submitted'
                      ? 'Pending'
                      : experience.status.charAt(0).toUpperCase() + experience.status.slice(1)}
                  </span>
                </b>
              </div>
            </div>
            {!hideAvatar && (
              <ChefAvtarHost displayInfo={false} person={experience.host} />
            )}
            <div className="experience-card__text grid-view-item__text">
              {!hideAvatar && (
                <ChefInfo displaySpecialties={false} person={experience.host} />
              )}

              <div className="experience-card__title grid-view-item__title">
                <Link to={`/experiences/${experience.slug}`}>
                  <h5>{experience.title}</h5>
                </Link>
              </div>

              {experience.cuisineTypes &&
                experience.cuisineTypes.map((specialty, i) => (
                  /* eslint-disable-next-line react/no-array-index-key */
                  <small
                    // className="badge"
                    key={specialty.name}
                    // style={{ margin: '5px 5px 0 0', padding: '7px' }}
                  >
                    {specialty.name}
                    {experience.cuisineTypes.length - 1 !== i && (
                      <span>, </span>
                    )}
                  </small>
                ))}
            </div>
          </div>
          <div className="experience-card__reservation-info group">
            <div style={{ minHeight: '120px', maxHeight: '120px', overflow: 'hidden' }}>
              {/* {experience.readableDate} <br />{' '} */}
              {/* {experience.date ? moment(experience.time, 'HH:mm').format('h:mm A') : ''} */}
              {sortedSchedules && sortedSchedules.length > 0 ? (
                <React.Fragment>
                  <p><b>Date - Seats</b></p>
                  {sortedSchedules.map((e) => (
                    <p
                      key={e.id}
                      style={{ marginBottom: 0 }}
                    >
                      {moment(e.date, 'YYYY-MM-DD').format('ddd DD-MMM')} - {e.seatsBooked}/{e.seats} seats
                    </p>
                  ))}
                </React.Fragment>
              ) : ''}
              {/* <p>
              Status:
              </p> */}
            </div>
          </div>

          <div className="experience-card__reservation-info group ">
            <div className="experience-card__price">
              {experience.currency}
              <br/>
              <p>
                {experience.price}
              </p>
            </div>
            {/* <div className="experience-card__price">
              Status{' '}<br/>
              <p
                className={statusClass}
                style={{
                  fontSize: '14px',
                  margin: '5px 5px 0 0',
                  padding: '7px',
                  color: 'white'
                }}
              >
                {experience.status === 'submitted'
                  ? 'Pending'
                  : experience.status.charAt(0).toUpperCase() + experience.status.slice(1)}
              </p>
            </div> */}
            {/* <div
              className="experience-card__price"
              style={{ borderLeft: 'none' }}
            >
              Guests{' '}
              <br/>
              <p>
                {`${experience.seats - experience.seatsAvailable}/${
                  experience.seats
                }`}{' '}
              </p>
            </div> */}
            <div
              className="experience-card__date"
              style={{ borderLeft: 'none' }}
            >
              <span style={{ color: '#f8a48d' }}>Type</span>
              <br/>
              { experience.experienceType ? (<p>{experience.experienceType.name}</p>) : ''}
            </div>
          </div>
          <HostActionButttons experience={experience} showModalSchedule={showModalSchedule}/>
        </div>
      </React.Fragment>
    )
  }
}

HostExperienceCard.defaultProps = {
  featured: false,
  hideAvatar: false,
  forceHoverState: false
}

HostExperienceCard.propTypes = {
  experience: PropTypes.shape({
    title: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    host: PropTypes.shape({
      fullname: PropTypes.string,
      avatarUrl: PropTypes.string,
      specialties: PropTypes.arrayOf(PropTypes.shape({})).isRequired
    })
  }).isRequired,
  featured: PropTypes.bool,
  hideAvatar: PropTypes.bool,
  forceHoverState: PropTypes.bool
}
