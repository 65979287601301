import React from 'react'

const IconEmail = props => (
  <svg viewBox="0 0 20 17" width="1em" height="1em" {...props}>
    <text
      transform="translate(10 16)"
      fill="#fff"
      fontSize={16}
      fontFamily="BlackTie-Solid,Black Tie"
    >
      <tspan x={-9.143} y={0}>
        
      </tspan>
    </text>
  </svg>
)

export default IconEmail
