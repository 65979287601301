import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { validate } from './validate'
import { currentUserPropTypes } from '../../../constants/composed-proptypes'
import FileDropField from '../../Form/Fields/renderFileDropField'
import renderFileField from '../../Form/Fields/renderFileField'
import seo_meta from '../../../utils/seo-meta.json'
import { addressFieldCollection } from '../../../constants/formFields'
import SEO from '../../Shared/Web/SEO'

function keysMatch(key, obj) {
  const keys = Object.keys(obj).map(x => x.toLowerCase())
  return keys.indexOf(key.toLowerCase()) !== -1
}

/* NOTE: if currentUser form values match,they will be updated via
 * the initialValues of connectedStep6 which connects to Redux.
 * this happens automagically for all fields on the redux connected
 * form hostRegistration
 * @see https://redux-form.com/7.0.2/examples/initializefromstate/
 */

class Step6 extends Component {
  componentWillMount() { }

  /**
   * Disables a field if the same key is present in the currentUser object,
   * the user will not be able to update the field, but it will display the value.
   * @param  {object} fieldArray  an array of fields with keys and values.
   * @param  {object} currentUser the current signed in user
   * @return {object}             the updated field array
   */
  disableFields = fieldArray => {
    const { currentUser } = this.props
    if (typeof currentUser === 'undefined' || !currentUser.isSignedIn) {
      return fieldArray
    }
    const obj = fieldArray
    Object.keys(obj).map(key => {
      obj[key].disabled = !!keysMatch(key, currentUser.attributes)
      return obj[key]
    })
    return obj
  };


  componentDidMount = () => {
  }



  render() {
    const {
      handleSubmit,
      pristine,
      valid,
      submitting,
      previousPage,
      hostGalleryImages,
      userActions,
      hostForm
    } = this.props

    const formValues = hostForm && hostForm.values ? hostForm.values : null

    const foodPhotosPresent = () => {
      if (typeof hostGalleryImages === 'undefined' || !hostGalleryImages || (formValues && !formValues.heroImage)) {
        return false
      }
      return hostGalleryImages.length > 0 && formValues && formValues.heroImage && formValues.heroImage.big && formValues.heroImage.big !== 'defaults/profile_hero_images/big_default.png'
    }

    // console.log('formValues.heroImage', formValues.heroImage)

    let { title, description, og_title, og_description, og_site_name, og_locale, og_type } = seo_meta.food_photos
    return (
      <React.Fragment>
        <SEO title={title} description={description} />

        <form onSubmit={handleSubmit} className="">
          <div className="form-header">
            <h3>Profile Picture</h3>
          </div>
          <div className="form-body form-body--full text-center">
            <div className="grid__item small--one-whole medium-up--full">
              {/*  <label style={{ margin: "5px"}}>Profile Hero Image</label> */}
              <div className="preview" >
                <div className="menuItem_image" style={{
                  backgroundImage: `url(${formValues && formValues.heroImage  && formValues.heroImage.big?formValues.heroImage.big + '?' + new Date().getTime():''})`, backgroundRepeat: 'no-repeat', backgroundPosition:'center', backgroundSize:'cover', height: '200px', marginBottom: '16px', backgroundColor: '#f1f1f1'
                }} />
              </div>
              {/* <div className="menuItems-field-group--dropzone"> */}
              <Field
                name="heroImage"
                label="Dish Image"
                id="fileUpload67"
                type="file"
                accept=".jpg, .jpeg, .png"
                component={renderFileField}
                style={{ opacity: 0, height: 0, margin: 0, padding: 0 }}
                onClickCapture={()=>this.setState({uploadingStarted:true})}
                onChangeCapture={() => this.setState({ uploadingStarted: true })}
                updateImageDisplay={() => {}}
                singleImageUpload={async (e) => {
                  const heroImage =  await userActions.userHeroImageUpload(e)
                  this.props.change('heroImage', heroImage)
                }}
              />
              <button
                type="button"
                className="btn menu-image-btn"
                style={{maxWidth: '240px'}}
                id="btnUpload23"
                // disabled={this.state.uploadingStarted}
                onClick={() => {
                  document.getElementById('fileUpload67').click()
                }}
              >
              Click to Upload
              </button>
              {/* </div> */}
            </div>
          </div>
          <div className="form-header" style={{marginTop: '-28px'}}>
            <h3>Photo Gallery</h3>
          </div>
          <div className="form-body form-body--full text-center">
            <div className="grid__item small--one-whole medium-up--full">
              {/* <label style={{ margin: "5px"}}>Profile Photos</label> */}
              <p className="m-5">
                Upload photos you would like to showcase on your profile<br />
                (JPEG or PNG less than 2 MB, 1280×780 pixels. Aspect ratio 16:9)
              </p>
              <Field
                name="hostGalleryImages"
                component={FileDropField}
                type="file"
                parentComponent="profilePhotos"
                imageFile={hostGalleryImages}
              />
            </div>
          </div>
          <div className="form-footer">
            <button
              type="button"
              disabled={submitting}
              className="btn btn--link form-footer--left"
              onClick={() => previousPage()}
            >
              Go Back a Step
            </button>
            <button
              type="submit"
              disabled={
                submitting || pristine || !valid || !foodPhotosPresent()
              }
              className="btn btn--darker form-footer--right"
            >
              Continue
            </button>
          </div>
        </form>
      </React.Fragment>
    )
  }
}

Step6.propTypes = {
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  valid: PropTypes.bool.isRequired,
  previousPage: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  currentUser: currentUserPropTypes.isRequired
  // foodPhotoValues: PropTypes.arrayOf(PropTypes.shape({})).isRequired
}

// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
const reduxFormStep6 = reduxForm({
  form: 'hostRegistration',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  validate
})(Step6)

// You have to connect() to any reducers that you wish to connect to yourself
const connectedStep6 = connect(state => ({
  // initialValues: state.reduxTokenAuth.currentUser.attributes
  // pull initial values from account reducer
  hostForm: state.form && state.form.hostRegistration ? state.form.hostRegistration : null,
  hostGalleryImages:
    state.form.hostRegistration && state.form.hostRegistration.values
      ? state.form.hostRegistration.values.hostGalleryImages
      : undefined
}))(reduxFormStep6)

export default connectedStep6
