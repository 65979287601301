import React from "react"

const Peanuts = props => (
  <svg viewBox="0 0 88 88" width="1em" height="1em" {...props}>
    <g fill="currentColor" data-name="Layer 2">
      <circle cx={44} cy={33} r={1} />
      <circle cx={47} cy={37} r={1} />
      <circle cx={42} cy={38} r={1} />
      <circle cx={39} cy={50} r={1} />
      <path d="M44.5 54a4.49 4.49 0 0 1-3.5-1.64 1 1 0 0 0-1.41-.13 1 1 0 0 0-.13 1.41 6.47 6.47 0 0 0 5 2.36 1 1 0 0 0 0-2z" />
      <path d="M51.43 43.31a2.85 2.85 0 0 1-.5-3.31 8 8 0 0 0-5.33-11.85 8.11 8.11 0 0 0-6.69 1.67 8 8 0 0 0-1.91 10 2.75 2.75 0 0 1-.23 3.24A9.88 9.88 0 0 0 34 49.87 10 10 0 0 0 43.7 60h.3a10 10 0 0 0 7.43-16.69zm-1.86 12.43A7.92 7.92 0 0 1 43.76 58 8.15 8.15 0 0 1 36 49.9a8 8 0 0 1 2.21-5.41 4.73 4.73 0 0 0 .54-5.59A6 6 0 0 1 44 30a6.26 6.26 0 0 1 1.2.12 6 6 0 0 1 4 8.87 4.84 4.84 0 0 0 .74 5.66 8 8 0 0 1-.37 11.09z" />
      <path d="M44 88a44 44 0 1 1 44-44 44.05 44.05 0 0 1-44 44zm0-86a42 42 0 1 0 42 42A42 42 0 0 0 44 2z" />
    </g>
  </svg>
)

export default Peanuts
