// import qs from 'qs';
import Snacks, { success } from 'react-notification-system-redux'
import api from '../../utils/api'
import {
  SET_USER_ONLINE_STATUS,
  NOTIFICATIONS_LOADING,
  SET_NOTIFICATIONS,
  SET_CABLE_NOTIFICATION
} from '../../constants/actionTypes'

export function setNotifications(notifications) {
  /* eslint-disable-next-line no-console */
  // console.log('WE SET THE NOTIFICATIONS', notifications);
  return {
    type: SET_NOTIFICATIONS,
    payload: notifications
  }
}

export function setCableNotification() {
  /* eslint-disable-next-line no-console */
  // console.log('WE SET THE CABLE NOTIFICATION');
  return {
    type: SET_CABLE_NOTIFICATION
  }
}

export function setOnlineState(state) {
  /* eslint-disable-next-line no-console */
  // console.log('WE SET THE ONLINE STATE');
  return {
    type: SET_USER_ONLINE_STATUS,
    payload: state
  }
}

export function notificationState(state) {
  /* eslint-disable-next-line no-console */
  // console.log('WE SET THE ONLINE STATE');
  return {
    type: NOTIFICATIONS_LOADING,
    payload: state
  }
}

const notificationOpts = (message, router) => ({
  title: message,
  message: 'Ready to checkout?',
  position: 'br',
  autoDismiss: 6,
  action: {
    label: 'See notifications',
    callback: () => {
      // router.push({ pathname: '/cart' });
    }
  }
})
// uid: 'once-please', // you can specify your own uid if required

export function addCableNotification(notification) {
  /* eslint-disable-next-line no-unused-vars */
  return async (dispatch, getState) => {
    dispatch(setCableNotification())
    const notifications = getState().notifications.data
    const newSet = [notification, ...notifications]

    // console.log('FIRE SUCCESS');
    dispatch(success(notificationOpts(notification.content)))

    dispatch(setNotifications(newSet))
  }
}

export function getNotifications() {
  /* eslint-disable-next-line no-unused-vars */
  return async (dispatch, getState) => {
    // const { id } = getState().auth.currentUser.attributes;
    let response
    try {
      response = await api.get(`/notifications`)
      const json = response.data
      if (json.is_success) {
        dispatch(setNotifications(json.notifications))
      } else {
        // console.log(json.error);
      }
    } catch (err) {
      /* eslint-disable-next-line no-console */
      console.log('notification error ', err)
      throw err
    }
  }
}
