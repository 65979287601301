import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Avatar from 'react-avatar'
import NavDropdown from 'react-bootstrap/lib/NavDropdown'
import MenuItem from 'react-bootstrap/lib/MenuItem'
import { Link } from 'react-router-dom'
import { LinkContainer } from 'react-router-bootstrap'
import { currentUserPropTypes } from '../../constants/composed-proptypes'
import { signOutUser } from '../../actions/sessions'

import CreateExperienceLink from '../Workflows/ExperienceCreate/CreateExperienceLink'
import IconChevronDown from '../../icons/global/SVGJS/IconChevronDown'

const AvatarToggle = ({ currentUser }) => {
  
  const avatarPresent = currentUser.attributes && currentUser.attributes.profilePicture && currentUser.attributes.profilePicture.thumb && typeof currentUser.attributes.profilePicture.thumb !== 'undefined' && !currentUser.attributes.profilePicture.thumb.includes('/thumb_default.png')

  return (
    <div
      className="chef-or-host__avatar"
      style={{ backgroundImage: `url(${currentUser.attributes.profilePicture && currentUser.attributes.profilePicture.thumb && !currentUser.attributes.profilePicture.thumb.includes('/thumb_default.png')? currentUser.attributes.profilePicture.thumb + '?' + new Date().getTime() :''})` }}
    >
      <img
        className="lazyload lazysizes avatar profileAvatarDrop__avatar hidden"
        alt={currentUser.attributes.fullName}
        src={currentUser.attributes.profilePicture && currentUser.attributes.profilePicture.thumb && !currentUser.attributes.profilePicture.thumb.includes('/thumb_default.png')? currentUser.attributes.profilePicture.thumb:'/defaults/profile_pictures/default.png'}
        data-src={currentUser.attributes.profilePicture && currentUser.attributes.profilePicture.thumb && !currentUser.attributes.profilePicture.thumb.includes('/thumb_default.png')? currentUser.attributes.profilePicture.thumb:'/defaults/profile_pictures/default.png'}
        data-widths="[180, 360, 540, 720, 900, 1080, 1296, 1512, 1728, 2048]"
      />

      {!avatarPresent && (
        <div className="profileAvatarDrop chef-or-host__avatar">
          <Avatar
            name={currentUser.attributes.fullName}
            color="#f8a48d"
            size="100%"
            round
          />
        </div>
      )}
    </div>
  )
}

AvatarToggle.propTypes = {
  currentUser: currentUserPropTypes.isRequired
}

const ProfileAvatarDrop = ({ currentUser, signOut }) => (
  <React.Fragment>
    <NavDropdown
      title={<AvatarToggle currentUser={currentUser} />}
      id="breakbread-profile-avatar-drop"
    >
      {currentUser.attributes.role === 'host' && (
        <MenuItem header>Diner</MenuItem>
      )}

      {/* <MenuItem eventKey="1">
        <LinkContainer to="/users/experiences/">
          <span>My Experiences</span>
        </LinkContainer>
      </MenuItem> */}

      <MenuItem eventKey="1">
        <LinkContainer to="/diners/bookings">
          <span>My Bookings</span>
        </LinkContainer>
      </MenuItem>

      <MenuItem eventKey="2">
        <LinkContainer to="/diners/reviews">
          <span>
            My Reviews <span className="small"> (Diner)</span>
          </span>
        </LinkContainer>
      </MenuItem>
      <MenuItem eventKey="3">
        <LinkContainer to="/my_coupons">
          <span>My Coupons</span>
        </LinkContainer>
      </MenuItem>
      <MenuItem divider />

      {/* TODO: Seems like the React.Fragment nesting is causing
        the drop down not to close when clicked.
      */}
      {currentUser.attributes.role === 'host' ? (
        <MenuItem header>Host</MenuItem>
      ) : (
        ''
      )}
      {currentUser.attributes.role === 'host' ? (
        <MenuItem eventKey="1">
          <CreateExperienceLink className="faux-link">
            Create a new Experience
          </CreateExperienceLink>
        </MenuItem>
      ) : (
        ''
      )}

      {currentUser.attributes.role === 'host' ? (
        <MenuItem eventKey="2">
          <LinkContainer to="/hosts/experiences/">
            <span>Hosted Experiences</span>
          </LinkContainer>
        </MenuItem>
      ) : (
        ''
      )}
      {currentUser.attributes.role === 'host' ? (
        <MenuItem eventKey="3">
          <LinkContainer to="/hosts/reservations">
            <span>
              Reservations <span className="small"> (Host)</span>
            </span>
          </LinkContainer>
        </MenuItem>
      ) : (
        ''
      )}
      {currentUser.attributes.role === 'host' ? (
        <MenuItem eventKey="1">
          <LinkContainer to="/hosts/reviews">
            <span>
              Reviews <span className="small"> (Host)</span>
            </span>
          </LinkContainer>
        </MenuItem>
      ) : (
        ''
      )}
      {currentUser.attributes.role === 'host' ? <MenuItem divider /> : ''}

      <MenuItem header>Profile</MenuItem>
      {/* <MenuItem eventKey="3">
        <LinkContainer to="/users/wallet/">
          <span>My Wallet</span>
        </LinkContainer>
      </MenuItem> */}
      {currentUser.attributes.role === 'host' && (
        <MenuItem eventKey="1">
          <LinkContainer to="/users/account">
            <span>Personal Details</span>
          </LinkContainer>
        </MenuItem>
      )}
      {currentUser.attributes.role === 'host' && (
        <MenuItem eventKey="2">
          <LinkContainer to="/users/professional_details">
            <span>Professional Details</span>
          </LinkContainer>
        </MenuItem>
      )}

      {currentUser.attributes.role === 'host' && (
        <MenuItem eventKey="3">
          <LinkContainer to="/users/hosting_space_detail">
            <span>Hosting Space Details</span>
          </LinkContainer>
        </MenuItem>
      )}

      {currentUser.attributes.role === 'host' && (
        <MenuItem eventKey="3">
          <LinkContainer to="/users/profile_photos">
            <span>Profile Photos</span>
          </LinkContainer>
        </MenuItem>
      )}

      {currentUser.attributes.role === 'host' && (
        <MenuItem eventKey="10">
          <LinkContainer to={`/host/${currentUser.attributes.id}`}>
            <span>
              Host Profile <span className="small"> (Diner&apos;s view)</span>
            </span>
          </LinkContainer>
        </MenuItem>
      )}

      {currentUser.attributes.role != 'host' && (
        <MenuItem eventKey="1">
          <LinkContainer to="/users/account">
            <span>Account Details</span>
          </LinkContainer>
        </MenuItem>
      )}

      {/* {currentUser.attributes.role === 'host' && ( */}
      <MenuItem eventKey="3">
        <LinkContainer to="/users/covid_badge">
          <span>COVID Badge</span>
        </LinkContainer>
      </MenuItem>
      {/*     )} */}


      <MenuItem divider />
      <MenuItem header>Settings</MenuItem>
      {currentUser.attributes.role === 'host' && (
        <MenuItem eventKey="3">
          <LinkContainer to="/users/bank_account_details">
            <span>Bank Account Details</span>
          </LinkContainer>
        </MenuItem>
      )}
      <MenuItem eventKey="1">
        <LinkContainer to="/users/change_password">
          <span>Change Password</span>
        </LinkContainer>
      </MenuItem>
      {/*
      <MenuItem header>Settings</MenuItem>

      <MenuItem eventKey="1">
        <LinkContainer to={"/users/account/change_email"}>
          <span>Change Email</span>
        </LinkContainer>
      </MenuItem>

      <MenuItem eventKey="2">
        <LinkContainer to={"/users/account/change_phonenumber"}>
          <span>Change Phone Number</span>
        </LinkContainer>
      </MenuItem>
      <MenuItem eventKey="3">
        <LinkContainer to={"/users/account/change_phonenumber"}>
          <span>Change Password</span>
        </LinkContainer>
      </MenuItem>

      <MenuItem divider />
*/}
      <MenuItem divider />
      <MenuItem
        eventKey="4"
        onClick={() => {
          signOut(currentUser) // TODO: prevent session cancelled
          // this.props.history.push('/')
        }}
      >
        Logout
      </MenuItem>
    </NavDropdown>
  </React.Fragment>
)

ProfileAvatarDrop.propTypes = {
  signOut: PropTypes.func.isRequired,
  currentUser: currentUserPropTypes.isRequired
}

const mapStateToProps = state => ({
  currentUser: state.reduxTokenAuth.currentUser
})

const mapDispatchToProps = dispatch => ({
  signOut: user => dispatch(signOutUser(user))
})
export default connect(mapStateToProps, mapDispatchToProps)(ProfileAvatarDrop)
