import React, { Component } from 'react'
import { Field } from 'redux-form'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import renderField from './renderField'
import renderSelectField from './renderSelectField'
import FileDropField from './renderFileDropField'
import renderFileField from './renderFileField'
import { menuItemFieldCollection } from '../../../constants/formFields'
// import { setMenuFilesObjects } from '../../../reducers/user'
import IconClose from '../../../icons/global/SVGJS/IconClose'
import { retrieveNestedStates, renderErrors } from '../../../utils/validation'
import { title } from 'react-dom-factories'

const { course } = menuItemFieldCollection

const fileTypes = ['image/jpeg', 'image/pjpeg', 'image/png']

function validFileType(file) {
  for (let i = 0; i < fileTypes.length; i += 1) {
    if (file.type === fileTypes[i]) {
      return true
    }
  }
  return false
}

function returnFileSize(number) {
  if (number < 1024) {
    return `${number}bytes`
  }
  if (number >= 1024 && number < 1048576) {
    return `${(number / 1024).toFixed(1)}KB`
  }
  if (number >= 1048576) {
    return `${(number / 1048576).toFixed(1)}MB`
  }
  return `${number}bytes`
}
class renderMenuItemField extends Component {
  constructor(props) {
    super(props)
    this.state = {
      allPreviewImages:[],
      uploadingStarted: false,
      avatarStyles: [],
      avatarTextContext: '',
      newlyUploaded: false
    }
  }

  limitText = limit => value => {
    if (value.length < 0) {
      return value
    }
    if (value.length > limit) {
      return value.slice(0, limit - 1)
    }
    return value
  }


  updateImageDisplay(index) {
    const { currentUser, menuFIleArray } = this.props
    // console.log('menuFIleArray', menuFIleArray)
    const indexFile = menuFIleArray[index]
    // const input = document.getElementById('fileUpload'+index)
    //const curFiles = input.files
    // console.log('indexFile', indexFile)
    /* if (curFiles.length === 0) {
      this.setState({
        avatarTextContext: 'Select a file by clicking the button below.'
      })
    } else */ if (validFileType(indexFile)) {
      const fileTextContent = `File name ${
        indexFile.name
      }, file size ${returnFileSize(indexFile.size)}.`

      const fileSrc = window.URL.createObjectURL(indexFile)
      const avatarStyles = [...this.state.avatarStyles]
      avatarStyles[index]= { backgroundImage: `url(${fileSrc})`, backgroundSize: '300px', height: '200px', width: '300px', marginBottom: '16px' }
      this.setState({avatarStyles, newlyUploaded: true,
        avatarTextContext: fileTextContent,
        uploadingStarted: false})
      /* this.setState({
        avatarStyles: {
          backgroundImage: `url(${fileSrc})`
        },
        newlyUploaded: true,
        avatarTextContext: fileTextContent,
        uploadingStarted: false
      }) */
    } else {
      const fileTextContent = `File name: ${indexFile.name}: Not a valid file type. Update your selection.`
      // this.setState({uploadingStarted:true});
      this.setState({
        avatarTextContext: fileTextContent,
        uploadingStarted: false
      })
    }
  }

  render() {
    const { fields, form, formState, array, userActions, menuFIleArray, currentUser, localMenuCoursesOptions } = this.props
    // console.log('this.props', this.props)
    const currentFormFields = formState[form].fields
    const currentFormErrors = formState[form].syncErrors
    const allFieldsStates = retrieveNestedStates(
      currentFormFields, // From State
      fields.name // Name of Field i.e. ('menuAttributes.menuItemsAttributes')
    )
    
    const errors = retrieveNestedStates(currentFormErrors, fields.name)
    if (
      typeof fields.getAll() === 'undefined' ||
      fields.getAll().length === 0
    ) {
      fields.push({}) // Add a single empty field set
    }
    const formValues = formState.sampleExperience && formState.sampleExperience.values
      ? formState.sampleExperience.values.menuAttributes.menuItemsAttributes
      : formState.experienceCreate && formState.experienceCreate.values
        ? formState.experienceCreate.values.menuAttributes.menuItemsAttributes
        : formState.experienceEdit && formState.experienceEdit.values
          ? formState.experienceEdit.values.menuAttributes.menuItemsAttributes
          : []
    let fullClass = false
    /* if (form == 'experienceEdit' || form == 'experienceCreate') {
      fullClass = true
    } */
    /*  const dataTime = formValues[0] && formValues[0].menu_item_urls && formValues[0].menu_image_urls.thumb ? formValues[0].menu_image_urls.thumb:''
    console.log('dataTime===', formValues[0].menu_image_urls.thumb, dataTime) */
    return (
      <div className="menuItems-field-array">
        {fields.map((menuItem, index) => (
          <div
            className={
              fullClass
                ? 'menuItems-field-group createExperince'
                : 'menuItems-field-group'
            }
            key={`Dish${index + 1}`}
          >
            {fields.length > 0 && <hr />}
            {index > 0 ? (
              <button
                type="button"
                className="btn--link btn--remove"
                title="Remove Menu Item"
                onClick={() => {
                  userActions.removeMenuFilesObject(index)
                  fields.remove(index)
                } }
              >
                <IconClose />
              </button>
            ) : (
              ''
            )}
            <label> Dish #{index + 1}</label>
            {allFieldsStates && errors && (
              <p className="common-p-error">
                <span className="form-error">
                  {renderErrors(errors, allFieldsStates, index)}
                </span>
              </p>
            )}
            <p />

            <div className="grid">
              <div
                className={
                  fullClass
                    ? 'grid--item grid__item '
                    : 'grid--item grid__item small--one-whole medium-down--one-third medium--one-third large-up--one-third'
                }
              >
                <div className="preview">
                  {menuFIleArray && menuFIleArray[index]?(<div className="menuItem_image" style={this.state.avatarStyles[index]} />):(<div className="menuItem_image" style={{
                    backgroundImage: `url(${formValues[index] && formValues[index].menu_image_urls && formValues[index].menu_image_urls.big?formValues[index].menu_image_urls.big:''})`, backgroundSize: '300px', height: '200px', width: '300px', backgroundColor:'#f1f1f1', marginBottom: '16px'
                  }} />)}
                </div>
                <div className="menuItems-field-group--dropzone">
                  <Field
                    name={`${menuItem}.menu_item_photo`}
                    label="Dish Image"
                    id={"fileUpload"+index}
                    type="file"
                    accept=".jpg, .jpeg, .png"
                    component={renderFileField}
                    style={{ opacity: 0, height: 0, margin: 0, padding: 0 }}
                    onClickCapture={()=>this.setState({uploadingStarted:true})}
                    onChangeCapture={() => this.setState({ uploadingStarted: true })}
                    updateImageDisplay={() => {}}
                    singleImageUpload={async (e) => {
                      await userActions.setMenuFilesObjects(e, index)
                      this.updateImageDisplay(index)
                      /* await userActions.profileImageUpload(e)
              if(nextPage && goToNext) {
                setTimeout(() => {
                  nextPage()
                }, 1000);
              } */
                    }}
                  />
                  <button
                    type="button"
                    className="btn minWidth200 menu-image-btn"
                    id="btnUpload"
                    // disabled={this.state.uploadingStarted}
                    onClick={() => {
                      document.getElementById('fileUpload'+index).click()
                    }}
                  >
              Select Image
                  </button>
                  {/*  <Field
                    name={`${menuItem}.menu_item_photo`}
                    label="Dish Image"
                    component={FileDropField}
                    type="file"
                    multiple={false}
                    isMenuItem
                  /> */}
                </div>
              </div>
              <div
                className={
                  fullClass
                    ? 'grid--item grid__item '
                    : 'grid--item grid__item small--one-whole medium-down--two-thirds medium--two-thirds large-up--two-thirds '
                }
              >
                <div className="grid grid--uniform">
                  <div
                    className={
                      fullClass
                        ? 'grid--item grid__item fullInputClass mb_5'
                        : 'grid--item grid__item  medium-up--one-half medium--one-half large-up--one-half fullWidthInputField nameofdishformargin'
                    }
                  >

                    <Field
                      name={`${menuItem}.title`}
                      type="text"
                      component={renderField}
                      normalize={this.limitText(60)}
                      placeholder="Name of Dish"
                      label="Title"
                      displayLabel={false}
                      removeLabelCompletely
                    />
                    <span className="limittextmenutitle">
                      <span className="field_limit_text__limit">
                        {60 -
                          (formValues[index]
                            ? formValues[index].title
                              ? formValues[index].title.length
                              : 0
                            : 0)}{' '}
                        characters remaining
                      </span>
                    </span>
                  </div>

                  <div
                    className={
                      fullClass
                        ? 'grid--item grid__item '
                        : 'grid--item grid__item  medium-up--one-half medium--one-half large-up--one-half '
                    }
                  >
                    <Field
                      name={`${menuItem}.course`}
                      component={renderSelectField}
                      options={localMenuCoursesOptions}
                      placeholder={course.placeholder}
                      isClearable
                      type="select"
                      selectType="course"
                      label="Course"
                      displayLabel={false}
                    />
                  </div>
                </div>

                <div className="grid grid--uniform mtt_10">
                  <div className="grid--item grid__item  large-up--full ">
                    <Field
                      name={`${menuItem}.description`}
                      inputType="textArea"
                      component={renderField}
                      normalize={this.limitText(200)}
                      label="Description"
                      placeholder="Short Description"
                    />
                    <span className="limittextmenudescription">
                      <span className="field_limit_text__limit">
                        {200 -
                          (formValues[index]
                            ? formValues[index].description
                              ? formValues[index].description.length
                              : 0
                            : 0)}{' '}
                        characters remaining
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <p />
            {/* {fields.length > 1 && (
              <p>
              <button
                  type="button"
                  className="btn--link btn--remove"
                  title="Remove Menu Item"
                  onClick={() => fields.remove(index)}
                >
                  <IconClose /> Remove Dish #{index + 1}
                </button>
              </p>
            )} */}
          </div>
        ))}
        <div>
          <button
            type="button"
            className="btn btn--link"
            onClick={() => fields.push({})}
          >
            + Add Menu Item
          </button>
        </div>

        {fields.length > 1 && <hr />}
      </div>
    )
  }
}

renderMenuItemField.propTypes = {
  fields: PropTypes.shape({}).isRequired,
  formState: PropTypes.shape({}).isRequired,
  form: PropTypes.string.isRequired, // Name of Form
  array: PropTypes.shape({}).isRequired
}

// You have to connect() to any reducers that you wish to connect to yourself
export default connect(state => ({
  formState: state.form,
  menuFIleArray: state.user.fileUploads.menuFIleArray
    ? state.user.fileUploads.menuFIleArray
    : null
}))(renderMenuItemField)
