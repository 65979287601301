import React, { Component } from 'react'

import '../../scss/styleguide.css'

export default class Typography extends Component {
  componentDidMount() {}

  componentWillUnmount() {}

  render() {
    return (
      <React.Fragment>
        <main className="main-content style-guide" id="MainContent" role="main">
          <section className="breakbread-section">
            <div className="breakbread-container">
              <div className="section-header text-center">
                <h1 className="page-header">Typography</h1>
                <p>
                  This page is here to show off styles for standard page
                  elements such as typography, form elements, and icons. These
                  are hard coded in a component named{' '}
                  <code>components/Shared/Web/styleguides/Typography.js</code>.
                </p>
              </div>
            </div>

            <hr />
          </section>
          <div className="breakbread-container">
            <div className="design-collective--docs-section">
              <h1 id="type">Typography</h1>
              <div className="row">
                <div className="col-md-6">
                  <h2 id="type-headings">Leads - Comfortaa Light</h2>
                  <small className="label">XL 36/50</small>
                  <h1 className="lead-xl">
                    Food experiences that feel just like
                    home.
                  </h1>
                  <small className="label">L 32/40</small>
                  <h2 className="lead-lg">
                    Food experiences that feel just like
                    home.
                  </h2>
                  <small className="label">M 26/40</small>
                  <h3 className="lead-md">
                    Food experiences that feel just like
                    home.
                  </h3>
                  <small className="label">S 24/35</small>
                  <h4 className="lead-sm">
                    Food experiences that feel just like
                    home.
                  </h4>
                  <small className="label">T 20/30</small>
                  <h5 className="lead-t">
                    Food experiences that feel just like
                    home.
                  </h5>
                </div>
                <div className="col-md-6">
                  <h2 id="type-headings">Body - font Roboto</h2>
                  <small className="label">XL 22/30</small>
                  <p className="text-xl">
                    Cum sociis natoque penatibus et magnis dis parturient
                    montes, nascetur ridiculus mus. Praesent commodo cursus
                    magna, vel scelerisque nisl consectetur et.
                  </p>
                  <p className="text-xl">
                    Nullam id dolor id nibh ultricies vehicula ut id elit. Nulla
                    vitae elit libero, a pharetra augue. Etiam porta sem
                    malesuada magna mollis euismod.
                  </p>
                  <small className="label">L 18/30</small>
                  <p className="text-lg">
                    Cum sociis natoque penatibus et magnis dis parturient
                    montes, nascetur ridiculus mus. Praesent commodo cursus
                    magna, vel scelerisque nisl consectetur et.
                  </p>
                  <p className="text-lg">
                    Nullam id dolor id nibh ultricies vehicula ut id elit. Nulla
                    vitae elit libero, a pharetra augue. Etiam porta sem
                    malesuada magna mollis euismod.
                  </p>
                  <small className="label">M 16/22</small>
                  <p className="text-md">
                    Cum sociis natoque penatibus et magnis dis parturient
                    montes, nascetur ridiculus mus. Praesent commodo cursus
                    magna, vel scelerisque nisl consectetur et.
                  </p>
                  <p className="text-md">
                    Nullam id dolor id nibh ultricies vehicula ut id elit. Nulla
                    vitae elit libero, a pharetra augue. Etiam porta sem
                    malesuada magna mollis euismod.
                  </p>
                  <small className="label">Lists 16/30</small>
                  <p>Line 1</p>
                  <p>Line 2</p>
                  <p>Line 3</p>
                  <small className="label">S 14/20</small>
                  <p className="text-sm">
                    Cum sociis natoque penatibus et magnis dis parturient
                    montes, nascetur ridiculus mus. Praesent commodo cursus
                    magna, vel scelerisque nisl consectetur et.
                  </p>
                  <p className="text-sm">
                    Nullam id dolor id nibh ultricies vehicula ut id elit. Nulla
                    vitae elit libero, a pharetra augue. Etiam porta sem
                    malesuada magna mollis euismod
                  </p>
                </div>
              </div>
              <hr />
              <div className="design-collective--docs-section">
                <h2 id="type">Semantic Headings - Comfortaa Bold</h2>
                <p>
                  All HTML headings, <code>&lt;h1&gt;</code> through{' '}
                  <code>&lt;h6&gt;</code>, are available. <code>.h1</code>{' '}
                  through <code>.h6</code> classes are also available, for when
                  you want to match the font styling of a heading but still want
                  your text to be displayed inline.
                </p>
                <p>
                  Create lighter, secondary text in any heading with a generic{' '}
                  <code>&lt;small&gt;</code> tag or the <code>.small</code>{' '}
                  class.
                </p>
              </div>
              <div
                className="design-collective--example design-collective--example-type"
                data-example-id="small-headings"
              >
                <table className="table">
                  <tbody>
                    <tr>
                      <td>
                        <h1>
                          h1. BreakBread heading{' '}
                          <small>Secondary text - Subheading</small>
                        </h1>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <h2>
                          h2. BreakBread heading{' '}
                          <small>Secondary text - Subheading</small>
                        </h2>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <h3>
                          h3. BreakBread heading{' '}
                          <small>Secondary text - Subheading</small>
                        </h3>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <h4>
                          h4. BreakBread heading{' '}
                          <small>Secondary text - Subheading</small>
                        </h4>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <h5>
                          h5. BreakBread heading{' '}
                          <small>Secondary text - Subheading</small>
                        </h5>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <h6>
                          h6. BreakBread heading{' '}
                          <small>Secondary text - Subheading</small>
                        </h6>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <hr />
              <h2 id="type-body-copy">Paragraphs &amp; Body</h2>
              <p>
                BreakBreads global default <code>font-size</code> is{' '}
                <strong>16px</strong>, with a <code>line-height</code> of{' '}
                <strong>1.428</strong>. This is applied to the{' '}
                <code>&lt;body&gt;</code> and all paragraphs. In addition,{' '}
                <code>&lt;p&gt;</code> (paragraphs) receive a bottom margin of
                half their computed line-height (10px by default).
              </p>
              <div
                className="design-collective--example"
                data-example-id="body-copy"
              >
                <p>
                  Nullam quis risus eget urna mollis ornare vel eu leo. Cum
                  sociis natoque penatibus et magnis dis parturient montes,
                  nascetur ridiculus mus. Nullam id dolor id nibh ultricies
                  vehicula. <strong>Strong</strong> <em>Emphasis</em>{' '}
                  <a href="#top">Inline link</a> <s>Strike</s> <sup>Sup</sup>{' '}
                </p>
                <p>
                  Maecenas sed diam eget risus varius blandit sit amet non
                  magna. Donec id elit non mi porta gravida at eget metus. Duis
                  mollis, est non commodo luctus, nisi erat porttitor ligula,
                  eget lacinia odio sem nec elit.
                </p>
              </div>
              <hr />
              <h2 id="lead-body-copy">Lead Copy</h2>
              <p>
                Make a paragraph stand out by adding <code>.lead</code> class.
              </p>
              <div
                className="design-collective--example"
                data-example-id="lead-copy"
              >
                <p className="lead">
                  Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor
                  auctor. Duis mollis, est non commodo luctus.
                </p>
              </div>
              <hr />
              <h2 id="type-addresses">Addresses</h2>
              <p>
                Present contact information for the nearest ancestor or the
                entire body of work. Preserve formatting by ending all lines
                with <code>&lt;br&gt;</code>.
              </p>
              <div
                className="design-collective--example"
                data-example-id="simple-address"
              >
                {' '}
                <address>
                  {' '}
                  <strong>Twitter, Inc.</strong>
                  <br /> 1355 Market Street, Suite 900<br /> San Francisco, CA
                  94103<br /> <abbr title="Phone">P:</abbr> (123) 456-7890{' '}
                </address>{' '}
                <address>
                  {' '}
                  <strong>Full Name</strong>
                  <br /> <a href="mailto:#">first.last@example.com</a>{' '}
                </address>{' '}
              </div>
              <hr />
              <h2 id="type-blockquotes">Blockquotes</h2>
              <p>
                For quoting blocks of content from another source within your
                document.
              </p>
              <p>
                Wrap <code>&lt;blockquote&gt;</code> around any{' '}
                <abbr title="HyperText Markup Language">HTML</abbr> as the
                quote. For straight quotes, we recommend a{' '}
                <code>&lt;p&gt;</code>.
              </p>
              <div
                className="design-collective--example"
                data-example-id="simple-blockquote"
              >
                <blockquote>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Integer posuere erat a ante.
                  </p>
                </blockquote>
              </div>
              <hr />
              <h2 id="type-lists">Lists</h2>
              <table>
                <tbody>
                  <tr>
                    <td width="40%">
                      <h6>
                        <code>ul</code> (default)
                      </h6>
                    </td>
                    <td>
                      <ul>
                        <li>One</li>
                        <li>Two</li>
                        <li>Three</li>
                        <li>Four</li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td width="40%">
                      <h6>
                        <code>ul</code> (colored)
                      </h6>
                    </td>
                    <td>
                      <ul className="list-colored">
                        <li>One</li>
                        <li>Two</li>
                        <li>Three</li>
                        <li>Four</li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h6>
                        Numeric bullet <code>ol</code> (default)
                      </h6>
                    </td>
                    <td>
                      <ol>
                        <li>One</li>
                        <li>Two</li>
                        <li>Three</li>
                        <li>Four</li>
                      </ol>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h6>Child lists (to match the RTE styles)</h6>
                    </td>
                    <td>
                      <ul>
                        <li>One</li>
                        <li>Two</li>
                        <li>
                          Three, with child list
                          <ul>
                            <li>First sub item</li>
                            <li>Second sub item</li>
                            <li>
                              Third sub item, with child list
                              <ul>
                                <li>Third level item</li>
                                <li>Another third level item</li>
                              </ul>
                            </li>
                          </ul>
                        </li>
                        <li>Four</li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h6>Inline</h6>
                    </td>
                    <td>
                      <ul className="list-inline">
                        <li>Lorem ipsum</li>
                        <li>Phasellus iaculis</li>
                        <li>Nulla volutpat</li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h6>Unstyled</h6>
                    </td>
                    <td>
                      <ul className="list-unstyled">
                        <li>Lorem ipsum dolor sit amet</li>
                        <li>Consectetur adipiscing elit</li>
                        <li>Integer molestie lorem at massa</li>
                        <li>Facilisis in pretium nisl aliquet</li>
                        <li>
                          Nulla volutpat aliquam velit
                          <ul>
                            <li>Phasellus iaculis neque</li>
                            <li>Purus sodales ultricies</li>
                            <li>Vestibulum laoreet porttitor sem</li>
                            <li>Ac tristique libero volutpat at</li>
                          </ul>
                        </li>
                        <li>Faucibus porta lacus fringilla vel</li>
                        <li>Aenean sit amet erat nunc</li>
                        <li>Eget porttitor lorem</li>
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <h3>
              Rich Text Editor <small>RTE headings</small>
            </h3>
            <div className="rte">
              <h1>RTE heading one</h1>
              <h2>RTE heading two</h2>
              <h3>RTE heading three</h3>
              <h4>RTE heading four</h4>
              <h5>RTE heading five</h5>
              <h6>RTE heading six</h6>
            </div>
            <h3>
              Paragraphs <small>RTE</small>
            </h3>
            <p className="rte">
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam
              nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam
              erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci
              tation ullamcorper.
            </p>
            <p>
              Suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis
              autem vel eum iriure dolor in hendrerit in vulputate velit esse
              molestie consequat.
            </p>
            <h3>
              Type styles <small>RTE</small>
            </h3>
            <p>
              {' '}
              Note: for visibility the container of the text below has{' '}
              <code>text-lg</code> class.
            </p>
            <div className="text-lg">
              <ul className="list-inline rte">
                <li>
                  <strong>Strong</strong>
                </li>
                <li>
                  <em>Emphasis</em>
                </li>
                <li>
                  <a href="#top">Inline link</a>
                </li>
                <li>
                  <span style={{ textDecoration: 'line-through' }}>Strike</span>
                </li>
                <li>
                  <sup>Sup</sup>
                </li>
              </ul>
            </div>
            <hr />
            <h2>Responsive tables</h2>
            <p>
              {' '}
              On the <code>table</code> element, use the class{' '}
              <code>.responsive-table</code>
            </p>
            <table className="responsive-table">
              <thead>
                <tr>
                  <th>Order</th>
                  <th>Date</th>
                  <th>Payment Status</th>
                  <th>Fulfillment Status</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                <tr className="responsive-table-row">
                  <td data-label="Order">#1001</td>
                  <td data-label="Date">December 22, 2015</td>
                  <td data-label="Payment Status">Authorized</td>
                  <td data-label="Fulfillment Status">Unfulfilled</td>
                  <td data-label="Total">$43.03</td>
                </tr>
                <tr className="responsive-table-row">
                  <td data-label="Order">#1002</td>
                  <td data-label="Date">December 23, 2015</td>
                  <td data-label="Payment Status">Authorized</td>
                  <td data-label="Fulfillment Status">Unfulfilled</td>
                  <td data-label="Total">$44.03</td>
                </tr>
                <tr className="responsive-table-row">
                  <td data-label="Order">#1003</td>
                  <td data-label="Date">December 24, 2015</td>
                  <td data-label="Payment Status">Authorized</td>
                  <td data-label="Fulfillment Status">Unfulfilled</td>
                  <td data-label="Total">$45.03</td>
                </tr>
                <tr className="responsive-table-row">
                  <td data-label="Order">#1004</td>
                  <td data-label="Date">December 25, 2015</td>
                  <td data-label="Payment Status">Authorized</td>
                  <td data-label="Fulfillment Status">Unfulfilled</td>
                  <td data-label="Total">$46.03</td>
                </tr>
                <tr className="responsive-table-row">
                  <td data-label="Order">#1005</td>
                  <td data-label="Date">December 26, 2015</td>
                  <td data-label="Payment Status">Authorized</td>
                  <td data-label="Fulfillment Status">Unfulfilled</td>
                  <td data-label="Total">$47.03</td>
                </tr>
                <tr className="responsive-table-row">
                  <td data-label="Order">#1006</td>
                  <td data-label="Date">December 27, 2015</td>
                  <td data-label="Payment Status">Authorized</td>
                  <td data-label="Fulfillment Status">Unfulfilled</td>
                  <td data-label="Total">$48.03</td>
                </tr>
              </tbody>
            </table>
            <hr />
            <h2>Forms</h2>
            <h4>Default form elements</h4>
            <form className="form-horizontal">
              <label htmlFor="textHorizontal1">Horizontal Label</label>
              <input type="text" id="textHorizontal1" placeholder="Input 1" />
              <label htmlFor="textHorizontal2">Horizontal Label 2</label>
              <input type="text" id="textHorizontal2" placeholder="Input 2" />
              <select id="selectHorizontal1">
                <option value={0}>-- Choose --</option>
                <option value={1}>Option 1</option>
                <option value={2}>Option 2</option>
                <option value={3}>Option 3</option>
              </select>{' '}
              <input type="checkbox" id="checkInline" />{' '}
              <label htmlFor="checkInline" className="inline">
                Checkbox Label
              </label>
            </form>
            <h4>Form Inline</h4>
            <p>Still to come</p>
            <h4>Invisible labels</h4>
            <p>
              Add a class of <code>label-hidden</code> to a label to visually
              hide it, while keeping it accessible to screen readers. Use the{' '}
              <code>placeholder</code> attribute as your visible label.
            </p>
            <p>
              <small>
                <strong>Note:</strong> The <code>placeholder</code> attribute
                only works in IE10+, so invisible labels are disabled in IE9 and
                below.
              </small>
            </p>
            <label htmlFor="testInvisibleLabel1" className="label-hidden">
              First Name
            </label>
            <input
              type="text"
              id="testInvisibleLabel1"
              name="testInvisibleLabel1"
              placeholder="First Name"
            />
            <label htmlFor="testInvisibleLabel2" className="label-hidden">
              Last Name
            </label>
            <input
              type="text"
              id="testInvisibleLabel2"
              name="testInvisibleLabel2"
              placeholder="Last Name"
            />
            <label htmlFor="testInvisibleLabel3" className="label-hidden">
              Email
            </label>
            <input
              type="text"
              id="testInvisibleLabel3"
              name="testInvisibleLabel3"
              placeholder="Email"
            />
            <hr />
            <h4>Vertical forms (customer, contact, etc)</h4>
            <p>
              No styles for this exist in this theme, but are used in most
              themes. Style elements inside a form/div with class
              `form-vertical` to see your styles here.
            </p>
            <p>
              You cannot add a class directly to most form elements. Instead,
              wrap the form in a div with the class <code>.form-vertical</code>{' '}
              to achieve the same effect.
            </p>
            <form className="form-vertical">
              <label htmlFor="text1">Text Field</label>
              <input type="text" id="text1" />
              <label htmlFor="text2" id="text2Label">
                Placeholder
              </label>
              <input type="text" id="text2" placeholder="Placeholder text" />
              <label htmlFor="text3">Disabled Field</label>
              <input type="text" id="text3" disabled="disabled" />
              <label htmlFor="text4">Label</label>
              <input type="text" id="text4" />
              <label htmlFor="text5" className="error">
                Text Field (Error)
              </label>
              <input
                type="text"
                id="text5"
                className="input--error"
                defaultValue="Invalid Entry"
              />
              <label htmlFor="select1">Select Field</label>
              <select id="select1">
                <option value={0}>-- Choose --</option>
                <option value={1}>Option 1</option>
                <option value={2}>Option 2</option>
                <option value={3}>Option 3</option>
              </select>
              <fieldset>
                <legend>Checkboxes</legend>
                <input type="checkbox" id="check1" />
                <label htmlFor="check1" className="inline">
                  Checkbox Field
                </label>
                <br />
                <input type="checkbox" id="check2" />
                <label htmlFor="check2" className="inline">
                  Checkbox Field
                </label>
                <br />
                <input type="checkbox" id="check3" />
                <label htmlFor="check3" className="inline">
                  Checkbox Field
                </label>
              </fieldset>
              <fieldset>
                <legend>Radios</legend>
                <input type="radio" name="radio" id="radio1" />
                <label htmlFor="radio1" className="inline">
                  Option1
                </label>
                <br />
                <input type="radio" name="radio" id="radio2" />{' '}
                <label htmlFor="radio2" className="inline">
                  Option1
                </label>
                <br />
                <input type="radio" name="radio" id="radio3" />
                <label htmlFor="radio3" className="inline">
                  Option1
                </label>
              </fieldset>
              <label htmlFor="textarea1">Textarea</label>
              <textarea
                id="textarea1"
                placeholder="Placeholder text"
                defaultValue=""
              />
              <label htmlFor="file1">File Field</label>
              <input id="file1" type="file" />
            </form>
            <h3>Notes and form errors</h3>
            <p>
              No note styles exist in this theme but are commonly added to
              handle form errors. Example styles:
            </p>
            <div className="note">This is a standard note</div>
            <div className="note note--success">This is a success message</div>
            <div className="note note--error">
              <p>This is an error message.</p>
              <ul>
                <li>Bullets can offer more insight to the error</li>
                <li>Bullets can offer more insight to the error</li>
                <li>Bullets can offer more insight to the error</li>
                <li>Bullets can offer more insight to the error</li>
              </ul>
            </div>
            <hr />
            <h2>Miscellaneous elements</h2>
            <h4>Horizontal Rules</h4>
            <p>
              There are no default <code>hr</code> styles in this theme, but
              these are common ones to add.
            </p>
            <p>
              Default <code>hr</code>
            </p>
            <hr />
            <p>
              Invisible <code>hr.hr--clear</code>
            </p>
            <hr className="hr--clear" />
            <p>
              Small <code>hr.hr--small</code>
            </p>
            <hr className="hr--small" />
          </div>

          <div
            id="shopify-section-newsletter"
            className="shopify-section index-section"
          >
            <section className="breakbread-newsletter-section">
              <div className="breakbread-container">
                <div className="breakbread-newsletter-section-left">
                  <label htmlFor="Email" className="h5">
                    Don’t get left behind – Join the BreakBread club and get
                    10% off your next experience reservation.
                  </label>
                </div>
                <div className="breakbread-newsletter-section-right">
                  <div className="breakbread-newsletter-form">
                    <div className="breakbread-newsletter-form-inner">
                      <form
                        method="post"
                        action="/contact#contact_form"
                        id="contact_form"
                        acceptCharset="UTF-8"
                        className="contact-form"
                      >
                        <input
                          type="hidden"
                          name="form_type"
                          defaultValue="customer"
                        />
                        <input type="hidden" name="utf8" defaultValue="✓" />
                        <div className="input-group">
                          <input
                            type="hidden"
                            name="contact[tags]"
                            defaultValue="newsletter"
                          />
                          <input
                            type="email"
                            name="contact[email]"
                            id="Email"
                            className="input-group__field newsletter__input"
                            defaultValue
                            placeholder="Email address"
                            autoCorrect="off"
                            autoCapitalize="off"
                          />
                          <span className="input-group__btn">
                            <button
                              type="submit"
                              className="breakbread-btn btn--flat -inverse newsletter__submit"
                              name="commit"
                              id="Subscribe"
                            >
                              <span className="newsletter__submit-text--large">
                                Subscribe
                              </span>
                            </button>
                          </span>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </main>
      </React.Fragment>
    )
  }
}
