import api from '../utils/api'

import { SET_MY_COUPONS, COUPON_LOADING, SET_MY_REFERRAL_COUPON } from '../constants/actionTypes'
import { snakeToCamel } from '../utils/camelSnake'
import { normalizeCoupons } from '../utils/normalization'

export function setCouponLoading(loading) {
  return { type: COUPON_LOADING, loading }
}

export function setMyCoupons(coupons, meta) {
  return { type: SET_MY_COUPONS, coupons, meta }
}

export function setMyReferralCoupon(refCoupon) {
  return { type: SET_MY_REFERRAL_COUPON, refCoupon}
}

export function getMyReferralCoupon() {
  return (dispatch) => {
    dispatch(setCouponLoading(true))
    api.get(`/coupons?type=referral`)
      .then(response => {
        const json = response.data
        if (json.meta.is_success) {
          let coupons = (json.coupons.length > 0) ? json.coupons : []
          dispatch(setMyReferralCoupon(snakeToCamel(coupons[0], 2)))
          dispatch(setCouponLoading(false))
        } else {
          dispatch(setCouponLoading(false))
        }
      })
      .catch(e => console.log(e))
  }
};


export function getMyCoupons() {
  return (dispatch) => {
    dispatch(setCouponLoading(true))
    api.get('/coupons')
      .then(response => {
        const json = response.data
        if (json.meta.is_success) {
          dispatch(setMyReferralCoupon((json.referral === null) ? null : (snakeToCamel(json.referral, 2))))
          dispatch(setMyCoupons(snakeToCamel(json.reward_or_regular, 2), snakeToCamel(json.meta, 2)))
          dispatch(setCouponLoading(false))
        } else {
          dispatch(setCouponLoading(false))
        }
      })
      .catch(e => console.log(e))
  }
};