import React, { Component } from 'react'
// import { Field, FieldArray } from 'redux-form'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
// import { SPLIT_GRID_SIZE } from '../../../constants'
import { loadMenu } from '../../../actions/host/other'
// import * as fields from '../../Form/Fields/formFields'
import * as hostActionCreators from '../../../actions/host'
import Select from 'react-select'
import {
  professionalFieldCollection,
  menuItemFieldCollection
} from '../../../constants/formFields'
/* eslint-disable-next-line no-unused-vars */
const renderError = ({ meta: { touched, error } }) => {
  /* eslint-disable-next-line no-unused-expressions */
  touched && error ? <span>{error}</span> : false
}
class Step2 extends Component {
  constructor(props) {
    super(props)
    this.state = {
      professionalFields: professionalFieldCollection,
      menuItemFields: menuItemFieldCollection,
      selectedMenu: 0,
      menuAttributes: {
        menuItemsAttributes: []
      }
    }

    this.loadAndNextPage = this.loadAndNextPage.bind(this)
  }

  componentWillMount() {
    const { hostActions } = this.props
    //this.setState({});
    hostActions.getExperiences()
  }
  
  loadAndNextPage = menu => {
    const { fetchMenu, nextPage } = this.props
    fetchMenu(menu)
    nextPage()
  };

  render() {
    const {
      handleSubmit,
      pristine,
      valid,
      submitting,
      previousPage,
      nextPage,
      form,
      formState,
      initialValues,
      array,
      formTitle,
      allExperiences,
      sampleExperience
    } = this.props

    let localAllExperiences = allExperiences?[...allExperiences]:[]
    const { professionalFields, menuItemFields } = this.state
    const { cuisine } = professionalFields
    // const { allergens, mealCategories } = menuItemFields
    let menuOptions = []
    let SampleMenu = []
    if (sampleExperience) {
      let localSampleExperience = {...sampleExperience}
      localSampleExperience.menu['cuisineTypesAttributes'] = localSampleExperience.cuisineTypes.map((el)=>({value: el.id, label: el.name, id: el.id}))
      if(localSampleExperience.menu.menuAllergens.length ==0){
        localSampleExperience.menu['menuAllergens'] =[{ value: 'None', label: 'None' }]
      }
      SampleMenu = localSampleExperience.menu
    }
    let farmValues
    if(this.props.formtype =="edit") {
      farmValues = this.props.form && this.props.form.experienceEdit && this.props.form.experienceEdit.values?this.props.form.experienceEdit.values:{}
    } else {
      farmValues = this.props.form && this.props.form.experienceCreate && this.props.form.experienceCreate.values?this.props.form.experienceCreate.values:{}
    }
    if(farmValues) {
      for (let key in localAllExperiences) {
        if(localAllExperiences[key].id !== farmValues.id && localAllExperiences[key].menu && localAllExperiences[key].menu.menuItems && localAllExperiences[key].menu.menuItems.length > 0) {
          let menuOption = {}
          menuOption = { value: key, label: localAllExperiences[key].title }
          menuOptions.push(menuOption)
        }
      }
    }

    return (
      <form onSubmit={handleSubmit} className="form-step--load-menu">
        <div className="form-header">
          <h4>Create Your Experience</h4>
          <p>Use the options below to either load the menu you submitted with your host application or create a menu from scratch. testingsdsd</p>
        </div>
        <div className="form-body form-actions form-body--full">
          <div className="text-center">
            <button type="button" disabled={submitting || pristine} className="btn btn--default minWidth200" onClick={() => nextPage()}>
              Create A New Menu
            </button>
          </div>
          {localAllExperiences.length > 1 ? (
            <React.Fragment>
              <div className="text-center mb_10 mtt_10">{'   -Or-  '}</div>
              <div className="text-center">
                <Select
                  onChange={e => {
                    this.setState({ selectedMenu: e })
                    localAllExperiences[e.value].menu['cuisineTypesAttributes'] = localAllExperiences[e.value].cuisineTypes.map((el)=>({value: el.id, label: el.name, id: el.id}))
                    if(localAllExperiences[e.value].menu['menuAllergens'].length ==0){
                      localAllExperiences[e.value].menu['menuAllergens'] =[{ value: 'None', label: 'None' }]
                    } else {
                      localAllExperiences[e.value].menu['menuAllergens'] = localAllExperiences[e.value].menu['menuAllergens'].map((el)=>({value: el.id, label: el.name, id: el.id}))
                    }
                    if(localAllExperiences[e.value].menu['mealCategories'].length ==0){
                      localAllExperiences[e.value].menu['mealCategories'] =[{ value: 'None', label: 'None' }]
                    } else {
                      localAllExperiences[e.value].menu['mealCategories'] = localAllExperiences[e.value].menu['mealCategories'].map((el)=>({value: el.id, label: el.name, id: el.id}))
                    }
                    this.loadAndNextPage(localAllExperiences[e.value].menu)
                  }}
                  //onBlur={() => {
                  //  input.onBlur(selected || input.value)
                  //}}
                  options={menuOptions}
                  placeholder={'SELECT PREVIOUS MENU'}
                  value={this.state.selectedMenu}
                  className={'react-select centerSelectBtn'}
                  classNamePrefix="select"
                />
              </div>
            </React.Fragment>
          ) : (
            ''
          )}

          <div className="text-center mb_10 mtt_10">{'   -Or-  '} </div>
          <div className=" text-center">
            <button
              type="button"
              disabled={submitting || pristine}
              className="btn btn--dark minWidth200"
              // onClick={() => this.loadAndNextPage(allExperiences[0].menu)}

              onClick={() => {
                //allExperiences[e.value].menu['cuisineTypesAttributes'] = allExperiences[e.value].cuisineTypes;
                this.loadAndNextPage(SampleMenu)
              }}
            >
              Use Sample Menu
            </button>
          </div>
        </div>
        <div className="form-footer">
          <button type="button" disabled={submitting} className="btn btn--link form-footer--left" onClick={() => previousPage()}>
            Go Back a Step
          </button>
        </div>
      </form>
    )
  }
}

Step2.defaultProps = {
  pristine: false,
  submitting: false,
  valid: true,
  handleSubmit: () => null
}
Step2.propTypes = {
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  valid: PropTypes.bool,
  previousPage: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func
}
const mapStateToProps = state => ({
  // hosts: state.user.hosts,
  form: state.form,
  allExperiences: state.host.listings.data,
  futureExperiences: state.host.listings.future,
  pastExperiences: state.host.listings.past,
  fetchStatus: state.host.listings.status,
  sampleExperience: state.user.experiences.sampleExperince,
  tabs: state.tabs
})
const mapDispatchToProps = dispatch => ({
  hostActions: bindActionCreators(hostActionCreators, dispatch),
  fetchMenu: menu => dispatch(loadMenu(menu))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Step2)
