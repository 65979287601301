import {
  SET_HOST,
  SET_HOST_EXPERIENCES,
  AUTH_TOKEN_FAILED,
  AUTH_SIGNOUT_SUCCESS,
  SET_HOST_APPLICATION_STATUS
} from '../../constants/actionTypes'

const initialState = {}

export default function(state = initialState, action) {
  if (action.type === SET_HOST) {
    //  console.log('action host', action);
    return {
      ...state,
      ...action.host
    }
  }
  if (action.type === SET_HOST_EXPERIENCES) {
    const experiences = action.filter.page > 1?[...state.experiences,...action.experiences]:[...action.experiences]
    return {
      ...state,
      experiences,
      futureExperiences: [...action.futureExperiences],
      pastExperiences: [...action.pastExperiences],
      filter: {...action.filter}
    }
  }

  if (action.type === SET_HOST_APPLICATION_STATUS) {
    //  console.log('action host', action);
    return {
      ...state,
      hostApplicationStatus: action.payload.status
    }
  }

  if (action.type === AUTH_SIGNOUT_SUCCESS) {
    return initialState
  }

  if (action.type === AUTH_TOKEN_FAILED) {
    return initialState
  }
  return state
}
