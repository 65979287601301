// import moment from 'moment';

import { SET_DINER_RESERVATIONS ,SET_DINER_RESERVATION_TYPE} from '../../constants/actionTypes'

const initialState = {
  dinersReservation: [],
  filter:{reservationType : 'all'}
  
}

export default function(state = initialState, action) {
  if (action.type === SET_DINER_RESERVATIONS) {
    return {
      ...state,
      dinersReservation: action.reservation
    }
  }

  if (action.type === SET_DINER_RESERVATION_TYPE) {
    return {
      ...state,
      filter: action.filter
    }
  }

  return state
}
