import React from 'react'
import Modal from 'react-bootstrap/lib/Modal'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

/* Actions */
import * as modalActionCreators from '../../actions/modals'
import * as signUpActionCreators from '../../actions/signUp'

/* Centralized Proptypes */
import { currentUserPropTypes } from '../../constants/composed-proptypes'

const UserConfirmationModal = props => {
  const { show, modalActions, currentUser } = props
  return (
    <Modal show={show} onHide={() => modalActions.hideUserConfirmation()} animation={false} backdrop="static" className="user-confirmation-modal">
      <Modal.Body>
        <div className="modal-inner modal-inner--centered">
          <h5>{currentUser.attributes.firstName},</h5>
          <h4>Welcome to BreakBread!</h4>
          <p>Thank you for signing up.</p>
          <p>Are you ready for a unique dining experience?</p>
          <Link to="/experiences" className="btn" onClick={() => modalActions.hideUserConfirmation()}>
            Explore Experiences
          </Link>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <div className="text-center">
          <button type="button" className="btn btn--link" onClick={() => modalActions.hideUserConfirmation()}>
            Close
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}

UserConfirmationModal.propTypes = {
  currentUser: currentUserPropTypes.isRequired,
  modalActions: PropTypes.shape({}).isRequired,
  show: PropTypes.bool.isRequired
}

const mapStateToProps = state => ({
  currentUser: state.reduxTokenAuth.currentUser
})

const mapDispatchToProps = dispatch => ({
  modalActions: bindActionCreators(modalActionCreators, dispatch),
  signUpActions: bindActionCreators(signUpActionCreators, dispatch)
  /** NOTE: This would also be a nice way of doing it
   * https://github.com/reduxjs/react-redux/blob/master/docs/api.md#inject-todos-and-all-todoactioncreators-and-counteractioncreators-directly-as-props
   *
   * actions: bindActionCreators(Object.assign({}, modalActions), dispatch)
   */
})
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserConfirmationModal)
