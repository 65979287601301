import qs from 'qs'
import api from '../../utils/api'
import { getUserAttributesFromResponse } from '../../utils/auth-tools'

import { camelToSnake, snakeToCamel } from '../../utils/camelSnake'

/* eslint-disable-next-line no-unused-vars */
import { normalizeLocations, normalizeLocation } from '../../utils/normalization'

import alertModal from '../../utils/alert'

import { SET_USER_ATTRIBUTES, SET_LOCATIONS, SET_SINGLE_LOCATION } from '../../constants/actionTypes'
// import { isObject } from 'util'
import { isObject } from 'lodash'

/* export const setHostAttributes = userAttributes => ({
  type: SET_USER_ATTRIBUTES,
  payload: {
    userAttributes
  }
}) */

export const setAllLocations = location => ({
  type: SET_LOCATIONS,
  payload: {
    location
  }
})

export const setLocation = ({location}) => ({
  type: SET_SINGLE_LOCATION,
  payload: {
    location
  }
})
 
export function getLocations() {
  return (dispatch, getState) => {
    return api.get('hosts/locations')
      .then(response => {
        const json = response.data
        if (json.meta.is_success) {
          dispatch(
            setAllLocations(
              normalizeLocations(json.locations)
            )
          )
        } else {
          alertModal(json.error, 'error', 'Close')
        }
      })
      .catch(e => {
        // alertModal(e.message, 'error', 'Close');
        throw e
      })
  }
}

export function deleteLocation({id}) {
  return (dispatch, getState) => {
    return api.delete(`hosts/locations/${id}`)
      .then(response => {
        const json = response.data
        if (json.meta.is_success) {
          const currentLocations = getState().host.location.allLocations
          dispatch(setAllLocations([currentLocations.filter(el=>el.id != id)]))
        } else {
          alertModal(json.error_details.toString(), 'error', 'Close')
        }
      })
      .catch(e => {
        alertModal(e.response.data.error_details.toString(), 'error', 'Close')
        throw e
      })
  }
}

export function getSingleLocation({id}) {
  return (dispatch, getState) => {
    return api.get('hosts/locations/'+id)
      .then(response => {
        const json = response.data
        if (json.meta.is_success) {
          dispatch(
            setLocation(
              normalizeLocation(json.location)
            )
          )
        } else {
          alertModal(json.error, 'error', 'Close')
        }
      })
      .catch(e => {
        // alertModal(e.message, 'error', 'Close');
        throw e
      })
  }
}
export function createUpdateLocation(locationData) {
  /* eslint-disable-next-line no-unused-vars, no-console */
  return async (dispatch, getState) => {
    // const location = {}
    const currentLocations = getState().host.location.allLocations
    const hostLocation = {}
    if(locationData) {
      hostLocation.neighbourhood = locationData.neighbourhood,
      hostLocation.city = isObject(locationData.city)?locationData.city.value:locationData.city
      hostLocation.country_code = isObject(locationData.countryCode)?locationData.countryCode.value:locationData.countryCode
      hostLocation.postal_code = locationData.postalCode
      hostLocation.destination_link = locationData.destinationLink
      hostLocation.street_address = locationData.streetAddress
      hostLocation.amenities = locationData.amenities
      hostLocation.wow_elements = locationData.wowElements
    }
    let response
    // eslint-disable-next-line no-useless-catch
    try {
      if (locationData.id) {
        response = await api.put(`hosts/locations/${locationData.id}`, hostLocation)
      } else {
        response = await api.post(`hosts/locations`, hostLocation)
      }
      let updateLocations
      if(currentLocations.length > 0 && currentLocations.find(el=>el.id === response.data.location.id)) {
        updateLocations = currentLocations.map((el)=>{
          if (el.id === response.data.location.id) {
            return normalizeLocation(response.data.location)
          } else {
            return el
          }
        })
      } else if(currentLocations.length > 0 && !currentLocations.find(el=>el.id === response.data.location.id)) {
        updateLocations = [...currentLocations, normalizeLocation(response.data.location)]
      } 
      else {
        updateLocations = [normalizeLocation(response.data.location)]
      }
      // console.log('updateLocations', updateLocations)
      dispatch(setAllLocations(updateLocations))
      return response
    } catch (err) {
      // alertModal(err.response, 'error', 'Try Again');
      throw err
    }
  }
}


