import React from "react"

const Dairy = props => (
  <svg viewBox="0 0 88 88" width="1em" height="1em" {...props}>
    <g fill="currentColor" data-name="Layer 2">
      <path d="M54.87 39.5L51 32.73V29a1 1 0 0 0-1-1H39a1 1 0 0 0-1 1v3.68l-4.81 6.74a.54.54 0 0 0-.07.13v.1A1 1 0 0 0 33 40v19a1 1 0 0 0 1 1h20a1 1 0 0 0 1-1V40a1.09 1.09 0 0 0-.13-.5zM49 30v2h-9v-2zM35 58V41h6a1 1 0 0 0 0-2h-5.06l3.57-5h8.77l-3.15 5.5a1.09 1.09 0 0 0-.13.5v18zm18 0h-6V40.27L50 35l3 5.25z" />
      <path d="M44 88a44 44 0 1 1 44-44 44.05 44.05 0 0 1-44 44zm0-86a42 42 0 1 0 42 42A42 42 0 0 0 44 2z" />
    </g>
  </svg>
)

export default Dairy
