import React, { Component } from 'react'
import PropTypes from 'prop-types'
// import { Parallax, Background } from 'react-parallax';

import ExperienceCard from '../../Experiences/ExperienceCard'
import ExperienceSlider from '../../Shared/Web/ExperienceSlider'

import {
  COMMON_GRID_SIZE as gridSize,
  FEATURED_GRID_SIZE as featuredGridSize
} from '../../../constants'

export default class HomeExperiences extends Component {
  componentDidMount() {}

  render() {
    const { featuredExperiences, exactMobile } = this.props
    if (typeof featuredExperiences === 'undefined') {
      return null
    }
    if (featuredExperiences.length === '0') {
      return <div className="spinner" />
    }

    const sortedExperiences = featuredExperiences
    // .sort(
    //   (a, b) => new Date(a.date) - new Date(b.date)
    // )/* .slice(0, 3) */
    return (
      <React.Fragment>
        <div className="breakbread-container">
          {
            sortedExperiences.length === 1 && !exactMobile && 
              <section className="index-section">
                <div className="grid grid--uniform">
                  <div className={featuredGridSize}>
                    <ExperienceCard experience={sortedExperiences[0]} featured />
                  </div>
                </div>
              </section>
          } 
          {
            sortedExperiences.length === 1 && exactMobile && 
            <section className="index-section">
              <div className="grid grid--uniform">
                <div className={gridSize}>
                  <ExperienceCard experience={sortedExperiences[0]} />
                </div>
              </div>
            </section>
          }
          {
            (sortedExperiences.length === 3 || sortedExperiences.length === 2) && !exactMobile && 
            <section className="index-section">
              <div className="grid grid--uniform">
                {sortedExperiences.map((experience) => (
                  <div className={gridSize} key={`ExperienceCard-${experience.id}`}>
                    <ExperienceCard experience={experience} />
                  </div>
                ))}
              </div>
            </section>
          }
          { ((sortedExperiences.length > 3 && !exactMobile) || (sortedExperiences.length > 1 && exactMobile)) &&
            <section className="index-section">
              <div className="grid grid--uniform">
                <ExperienceSlider sortedExperiences={sortedExperiences} gridSize={gridSize}  />
              </div>
            </section>
          }
        </div>
      </React.Fragment>
    )
  }
}

HomeExperiences.defaultProps = {
  featuredExperiences: []
}
HomeExperiences.propTypes = {
  featuredExperiences: PropTypes.arrayOf(PropTypes.shape({}))
}
