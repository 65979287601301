const userRegistrationAttributes = {
  firstName: 'first_name',
  lastName: 'last_name',
  phoneCountryCode:"phone_country_code",
  phoneMetaData:'phone_meta_data',
  phoneNumber: 'phone_number',
  email: 'email',
  image: 'image',
  passwordConfirmation: 'password_confirmation',
  provider: 'provider',
  firebaseUid: 'firebase_uid',
  socialId:'social_id',
  socialImage: 'social_image'
}

export default userRegistrationAttributes
