import React, { Component } from 'react'
import { Field } from 'redux-form'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import moment from 'moment'
import { connect } from 'react-redux'
import _ from 'lodash'

import * as fields from '../../Fields/formFields'
import RenderField from '../../Fields/renderField'
import RenderDateField from '../../Fields/renderDateField'
import RenderSelectField from '../../Fields/renderSelectField'
import { generalInfoFields } from '../../../../constants/experienceFormFields'
import { addressFieldCollection } from '../../../../constants/formFields'
import * as userActionCreators from '../../../../actions/user'
import SEO from '../../../Shared/Web/SEO'

/* function keysMatch(key, obj) {
  const keys = Object.keys(obj).map(x => x.toLowerCase())
  return keys.indexOf(key.toLowerCase()) !== -1
} */

/* eslint-disable-next-line no-unused-vars */
const renderError = ({ meta: { touched, error } }) => {
  /* eslint-disable-next-line no-unused-expressions */
  touched && error ? <span>{error}</span> : false
}

const FormType = {
  EDIT: 'edit',
  CREATE: 'create'
}

class General extends Component {
  constructor(props) {
    super(props)
    const { form } = this.props
    /* this.textInput = React.createRef();
    this.focusTextInput = this.focusTextInput.bind(this); */
    this.state = {
      generalFields: generalInfoFields,
      formType: form === 'experienceEdit' ? FormType.EDIT : FormType.CREATE,
      mountAddressFields: false,
      addressChanged: false
    }
  }

  /*  focusTextInput() {
    // Explicitly focus the text input using the raw DOM API
    // Note: we're accessing "current" to get the DOM node
    console.log('this.textInput', this.textInput)
    // this.textInput.focus();
  } */
  componentWillMount() {
    const { formType } = this.state
    switch (formType) {
    case FormType.CREATE:
      this.showAddressFields({ show: false })
      break
    default:
      this.showAddressFields({ show: false })
    }
  }

  componentDidUpdate () {
    const {
      currentExperience
    } = this.props
    let farmValues
    if(this.props.formtype =="edit"){
      // end_date = this.props.form.experienceEdit.values.end_date
      farmValues = this.props.form.experienceEdit.values
    }else{
      farmValues = this.props.form.experienceCreate.values
    }
    if (currentExperience && !farmValues.id) {
      this.props.change('id', currentExperience.id)
    }
    if (farmValues && currentExperience && farmValues.status !== currentExperience.status) {
      this.props.change('status', currentExperience.status)
    }
  }
  
  componentDidMount() {
    const { initialValues,currentUser, userActions, formType } = this.props
    // console.log('currentExperience', currentExperience)
    userActions.getExperienceTypesList()

    const { invalid, pristine } = this.props
    if (invalid && !pristine) {
      //this.showAddressFields({ show: true })
    }
    /*  if (!this.locationPresent()) {
      // this.showAddressFields({ show: true })
    } */
    /* if (currentExperience) {
      this.props.change('id', currentExperience.id)
    } */
    if(!this.props.initialValues.currency ){
      this.props.change('currency', {
        name: 'UAE Dirham',
        value: "AED",
        label: 'د.إ / AED',
        number: '784',
        symbol: 'د.إ'
      })
    }
    if(this.props.initialValues.location && this.props.initialValues.location.id){
      this.props.change('location_id', {
        label: this.props.initialValues.location.neighbourhood?this.props.initialValues.location.neighbourhood:this.props.initialValues.location.streetAddress,
        value: this.props.initialValues.location.id?this.props.initialValues.location.id:null,
        id: this.props.initialValues.location.id?this.props.initialValues.location.id:null
      })
    }

    if(this.props.initialValues.experienceType){
      this.props.change('experienceType', this.props.initialValues.experienceType)
    }

    
    let farmValues
    if(this.props.formtype =="edit"){
      // end_date = this.props.form.experienceEdit.values.end_date
      farmValues = this.props.form.experienceEdit.values
    }else{
      farmValues = this.props.form.experienceCreate.values
      this.props.change('instant', true)
    }
    
    let tomorrow = new Date()
    tomorrow.setDate(tomorrow.getDate()+1)

    if(!this.props.initialValues.date && !farmValues.date){
      this.props.change('date', tomorrow)
    }
    /* if(currentUser.attributes != null && currentUser.attributes.hostingLocation != null  && initialValues.location == null && currentUser.attributes.hostingLocation.countryCode){
      
      this.props.change('location.countryCode',currentUser.attributes.hostingLocation.countryCode)
    }
    if(currentUser.attributes != null  && currentUser.attributes.hostingLocation != null && initialValues.location == null  && currentUser.attributes.hostingLocation.city){
      
      this.props.change('location.city',currentUser.attributes.hostingLocation.city)
    }

    if(currentUser.attributes != null  && currentUser.attributes.hostingLocation != null && initialValues.location == null  && currentUser.attributes.hostingLocation.streetAddress){
      
      this.props.change('location.streetAddress',currentUser.attributes.hostingLocation.streetAddress)
    }

    if(currentUser.attributes != null  && currentUser.attributes.hostingLocation != null && initialValues.location == null  && currentUser.attributes.hostingLocation.postalCode){
     
      this.props.change('location.postalCode',currentUser.attributes.hostingLocation.postalCode)
    } */
      
    


  }

  /*
  componentDidUpdate(prevProps) {
    const { submitFailed } = this.props;
    if (submitFailed !== prevProps.submitFailed && submitFailed) {
      this.showAddressFields({ show: true });
    }

  
    if(!this.props.initialValues.currency ){
      this.props.change('currency', {
        name: 'UAE Dirham',
        value: "AED",
        label: 'د.إ / AED',
        number: '784',
        symbol: 'د.إ',
      })
    }
  }*/
  /* locationPresent = () => {
    const { initialValues } = this.props
    if ( initialValues.location != null) {
      return initialValues.location.streetAddress.length > 0
    }
    return false
  }; */

  showAddressFields({ show = true } = {}) {
    this.setState({
      mountAddressFields: show
    })
  }

  limitText = limit => value => {
    if(value.length < 0) {
      return value
    } else if(value.length > limit) {
      return value.slice(0, (limit-1))
    } else {
      return value
    }
  }

  render() {
    const {
      handleSubmit,
      pristine,
      submitting,
      previousPage,
      fieldsToMount,
      formTitle,
      experienceTypesOptions,
      allLocations,
      isUpdating,
      invalid
    } = this.props

    const localExperienceTypes = _.sortBy(experienceTypesOptions?experienceTypesOptions.map(el=>({label: el.name, value: el.id, id: el.id})):[], 'label')
    const localLocations = _.sortBy(allLocations?allLocations.map(el=>({label: el.neighbourhood?el.neighbourhood:el.streetAddress, value: el.id, id: el.id})):[], 'label')
    const { generalFields } = this.state
    const {
      title,
      experienceType,
      duration,
      // startTime,
      // endTime,
      date,
      time,
      // startDate,
      // endDate,
      seats,
      price,
      currency,
      notes,
      description,
      instant,
      summary
    } = generalFields

    const { mountAddressFields, addressChanged } = this.state
    let { countryCode, address, city, postalCode } = addressFieldCollection.location
    const { initialValues } = this.props
    // console.log('initialValues====', initialValues)
    let end_date = moment()
    let farmValues
    let formErrors
    if(this.props.formtype =="edit"){
      // end_date = this.props.form.experienceEdit.values.end_date
      farmValues = this.props.form.experienceEdit.values
      formErrors = this.props.form.experienceEdit.syncErrors
    }else{
      farmValues = this.props.form.experienceCreate.values
      formErrors = this.props.form.experienceCreate.syncErrors
    }

    let currencyLabelErrorStyle
    if (formErrors && formErrors.currency) {
      currencyLabelErrorStyle = { color: '#d20000', fontSize: '14px', padding: '10px', display: 'inline-block' }
    }

    const currentAddressObj = allLocations && farmValues.location_id && farmValues.location_id.id?allLocations.find(el=>el.id === farmValues.location_id.id):null
    return (
      <form onSubmit={handleSubmit} className="">
        <SEO title={formTitle} description={formTitle} />
        <div className="form-header">
          <h4>{formTitle}</h4>
        </div>

        <div className="form-body form-body--full">
          <div className="grid grid--uniform form-vertical">
            <div className="grid__item medium-up--full ">
              {/* Title */}
              <Field
                name={title.name}
                component={fields.renderField}
                value={title.value}
                placeholder={title.placeholder}
                normalize={this.limitText(40)}
                type={title.type}
                autoComplete={title.autoComplete}
                label={title.label +" *"}
                field={title}
                displayLabel={false}
              />
              <span className="limittext">
                <span className="field_limit_text__limit">{ 40 - (farmValues.title?farmValues.title.length:0) } characters remaining</span>
              </span>
            </div>
          </div>

          {/*  <div className="grid grid--uniform form-vertical">
            <div className="grid__item medium-up--full ">
              <Field
                name={experienceType.name}
                component={RenderSelectField}
                options={localExperienceTypes}
                placeholder={experienceType.placeholder}
                field={experienceType}
                isClearable
                type="select"
                selectType="experience type"
                label="Experience Type *"
                displayLabel={true}
              />
            </div>
          </div> */}
          <div className="grid grid--uniform form-vertical">
            <div className="form-row">
              {/* Personal Info Fields */}
              {this.props.formtype!=="edit"?(<div className="grid__item  medium-up--one-third large-up--one-third">
                <Field
                  value={date.value}
                  initialValue={date.value}
                  name={date.name}
                  placeholder={date.placeholder}
                  onFocus={({ focused }) =>
                    this.setState(state => ({
                      ...state,
                      generalFields: {
                        ...state.generalFields,
                        date: {
                          ...state.generalFields.date,
                          focused
                        }
                      }
                    }))
                  }
                  focused={date.focused}
                  component={RenderDateField}
                  type="date"
                  label="Select Date *"
                  /* onChange={(value)=>{
                      if(moment(value).isAfter(end_date)){
                        this.props.change('end_date',moment(value))
                      }

                    }} */
                  past={false}
                />
              </div>):''} 
              <div className={`grid__item  medium-up--one-${this.props.formtype!=="edit"?'third':'half'} large-up--one-${this.props.formtype!=="edit"?'third':'half'}`}>
                <Field
                  name={time.name}
                  component={RenderSelectField}
                  options={time.options}
                  placeholder={time.placeholder}
                  isClearable
                  type="select"
                  selectType="startTime"
                  /* onChange={(startTime)=>{
                
                      let startDate = moment(farmValues.start_date).format("L") 
                      if(typeof farmValues.EndTime == "undefined" || typeof farmValues.EndTime == null || typeof farmValues.EndTime== ""){
                  
                        this.props.change('EndTime',{label :moment(startDate+" "+startTime.label).add(2, 'hours').format("h:mm a"),value :moment(startDate+" "+startTime.label).add(2, 'hours').format("HH:mm")})
                      }else{

                        let diff = moment(startDate+" "+farmValues.EndTime.label).diff(moment(startDate+" "+startTime.label), 'hours')
                        if(diff < 2){
                          this.props.change('EndTime',{label :moment(startDate+" "+startTime.label).add(2, 'hours').format("h:mm a"),value :moment(startDate+" "+startTime.label).add(2, 'hours').format("HH:mm")})
                        }

                      }
                    }} */
                  label="Time *"
                  displayLabel
                />
              </div>
              {/* Gender */}
              <div className={`grid__item medium-up--one-${this.props.formtype!=="edit"?'third':'half'} large-up--one-${this.props.formtype!=="edit"?'third':'half'}`}>
                <Field
                  name={duration.name}
                  component={RenderSelectField}
                  options={duration.options}
                  placeholder={duration.placeholder}
                  isClearable
                  type="select"
                  selectType="duration"
                  label="Duration *"
                  property="value"
                  displayLabel={true}
                />
              </div>
            </div>
          </div>

          {/* <div className="grid grid--uniform form-vertical">
            <div className="form-row mb-5 grid  grid--uniform">



            </div>
          </div> */}
          <div className="grid grid--uniform form-vertical">
            <div className="form-row">
              {/* {this.props.formtype!=="edit"?( */}<div className="grid__item medium-up--one-half">
                {/* <label>How Many Seats Are Available? *</label> */}
                <Field
                  name={seats.name}
                  component={RenderSelectField}
                  options={seats.options}
                  placeholder={seats.placeholder}
                  isClearable
                  type="select"
                  selectType="number"
                  property="value"
                  label="How Many Seats Are Available? *"
                  displayLabel={true}
                />
              </div>{/* ):''}  */}
              <div className={`grid__item medium-up--one-${this.props.formtype!=="edit"?'half':'half'}`}>
                <label style={currencyLabelErrorStyle}>What Is The Cost Per Seat {/* (Including VAT) */}*</label>

                <div className="input-group input-group--with-select currencyInput">
                  <div className="input-group__select-wrap">
                  
                    <Field
                      name={currency.name}
                      component={RenderSelectField}
                      options={currency.options}
                      placeholder={currency.placeholder}
                      isClearable
                      type="select"
                      selectType={currency.name}
                      field={currency}
                      // label="What Is The Cost Per Seat? *"
                      displayLabel={false}
                    />
                  </div>
                  <Field
                    name={price.name}
                    component={RenderField}
                    options={price.options}
                    placeholder={price.placeholder}
                    field={price}
                    displayLabel={false}
                  />
                </div>
              </div>
            </div>
            <div className="form-row">
              <div className="grid__item medium-up--one-half">
                {/* <label>Location *</label> */}
                <Field
                  name="location_id"
                  component={RenderSelectField}
                  options={localLocations}
                  placeholder="Select Hosting Location"
                  isClearable
                  type="select"
                  property="value"
                  label="Location *"
                  displayLabel={true}
                />
              </div>
              <div className="grid__item medium-up--one-half">
                {/* <label>Location *</label> */}
                <Field
                  name={experienceType.name}
                  component={RenderSelectField}
                  options={localExperienceTypes}
                  placeholder={experienceType.placeholder}
                  // field={experienceType}
                  isClearable
                  type="select"
                  selectType="experience type"
                  label="Experience Type *"
                  displayLabel={true}
                />
              </div>
            </div>
            <div className="form-row">
              <div
                className={`grid__item small--one-whole medium-up--full ${
                  !farmValues.location_id ? 'hidden' : ''
                }`}
              >
                {/* Address Fields */}
                {farmValues.location_id && currentAddressObj?(
                  <div>
                    <label>Host Address</label>
                    <p>
                      {currentAddressObj.neighbourhood},{" "}
                      {currentAddressObj.streetAddress},{" "}
                      {currentAddressObj.postalCode},{" "}
                      {currentAddressObj.city},{" "}
                      {currentAddressObj.stateCode} {" "}
                      {currentAddressObj.countryCode}
                    </p>
                    {/* <button
                      type="button"
                      className="btn--link"
                      onClick={this.focusTextInput}
                    >
                      + Change Address
                    </button> */}
                  </div>
                ):''}
              </div>
            </div>
            <div className="form-row">
              <div className="grid__item medium-up--full">
                {/* <label>Short Summary *</label> */}
                <Field
                  name={summary.name}
                  component={RenderField}
                  type="text"
                  inputType="text"
                  normalize={this.limitText(250)}
                  placeholder={summary.placeholder}
                  field={summary}
                  label="Short Summary *"
                  displayLabel={true}
                />
                <span className="limittext">
                  <span className="field_limit_text__limit">{ 250 - (farmValues.summary?farmValues.summary.length:0) } characters remaining</span>
                </span>
              </div>
            </div>

            <div className="form-row">
              <div className="grid__item medium-up--full">
                {/* <label>Description *</label> */}
                <Field
                  name={description.name}
                  component={RenderField}
                  type="textArea"
                  inputType="textArea"
                  normalize={this.limitText(1000)}
                  placeholder={description.placeholder}
                  field={description}
                  label="Description *"
                  displayLabel={true}
                />
                <span className="limittext">
                  <span className="field_limit_text__limit">{ 1000 - (farmValues.description?farmValues.description.length:0) } characters remaining</span>
                </span>
              </div>
            </div>

            <div className="form-row">
              <div className="grid__item medium-up--full">
                <label>Notes</label>
                <Field
                  name={notes.name}
                  component={RenderField}
                  type="textArea"
                  inputType="textArea"
                  normalize={this.limitText(1000)}
                  placeholder={notes.placeholder}
                  field={notes}
                  displayLabel={false}
                />
                <span className="limittext">
                  <span className="field_limit_text__limit">{ 1000 - (farmValues.notes?farmValues.notes.length:0) } characters remaining</span>
                </span>
              </div>
            </div>
            {/* <div className="form-row">
              <div className="grid__item medium-up--full" style={{marginTop: '10px', display: 'flex'}}>
                <Field
                  name={instant.name}
                  component={RenderField}
                  type="checkbox"
                  inputType="checkbox"
                  placeholder={instant.placeholder}
                />
                <label style={{ marginTop: '0px', textAlign: 'left', marginLeft: '14px' }}>
                  Auto approve all bookings instantly (If left unchecked, you will need to confirm all diner bookings individually and any pending ones will be auto-approved after 72 hours from the booking time)
                </label>
              </div>
            </div> */}
          </div>
        </div>

        <div className="form-footer">
          <button type="submit" className="btn btn--darker form-footer--right" disabled={isUpdating}>
            Save And Continue
          </button>
        </div>
      </form >
    )
  }
}

General.propTypes = {
  submitting: PropTypes.bool.isRequired,
  submitFailed: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  previousPage: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
  initialValues: PropTypes.shape({}).isRequired
  // currentUser: currentUserPropTypes.isRequired,
}

const mapDispatchToProps = dispatch => ({
  userActions: bindActionCreators(userActionCreators, dispatch)
})

const mapStateToProps = state => ({
  form: state.form,
  experienceTypesOptions: state.user.amenitiesAndWowElements.experienceTypes
    ? state.user.amenitiesAndWowElements.experienceTypes.list
    : null,
  allLocations: state.host.location.allLocations?state.host.location.allLocations:[]
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(General)

//export default General;
