
import { SET_PROFILE_PIC_OBJ } from '../../constants/actionTypes'

const initialState = {}

export default function(state = initialState, action) {

  if (action.type === SET_PROFILE_PIC_OBJ) {
    return {
      ...state,
       profilePicObj: action.payload.profilePic
    }
  }

  if (action.type === 'SET_MENU_FILE_OBJECT') {
    let previousArr = state.menuFIleArray?state.menuFIleArray:[]
    previousArr[action.payload.indexData] = action.payload.fileData
    return {
      ...state,
       menuFIleArray: previousArr
    }
  }

  if (action.type === 'RESET_SET_MENU_FILE_OBJECT') {
    return {
      ...state,
       menuFIleArray: action.payload.fileData
    }
  }

  if (action.type === 'REMOVE_MENU_FILE_OBJECT') {
    let previousArr = state.menuFIleArray?state.menuFIleArray:[]
    // console.log('before previousArr', previousArr)
    if (previousArr[action.payload.indexData]) {
      previousArr.splice(action.payload.indexData, 1)
    }
    // console.log('after previousArr', previousArr)
    return {
      ...state,
       menuFIleArray: [...previousArr]
    }
  }

  

  return state
}
