import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Swal from 'sweetalert2/dist/sweetalert2'
import moment from 'moment'
import { bindActionCreators } from 'redux'
import { Link } from 'react-router-dom'

/* Action Creators */
import * as userActionCreators from '../../../actions/user'
import * as transactionActionCreators from '../../../actions/transactions'
import * as tabActionCreators from '../../../actions/tabs'

/* Components */
import FutureCard from './FutureCard'
import PastCard from './PastCard'

const gridSize =
  'grid__item medium-down--one-half medium--one-half large-up--one' + '-third'

class MyExperiencesList extends Component {
  constructor(props) {
    super(props)
    this.handleCancellation.bind(this)
  }

  componentWillMount() {
    const {
      transactionActions,
      futureReservations,
      pastReservations,
      namespace,
      tabActions
    } = this.props
    transactionActions.fetchReservationsMade()
    if (pastReservations.length === 0) {
      tabActions.changeSelectedTab('upcomingExperiences', namespace)
    }
  }

  viewReceipt(reservation) {
    return false
  }

  readReview(reservation) {
    // console.log('reservation', reservation)
    return false
  }

  handleCancellation(reservation) {
    const { transactionActions } = this.props

    const seatWording = reservation.seats > 1 ? 'Seats' : 'Seat'
    const message = `Are you sure you want to cancel your booking for <b>${reservation.seatsBooked} ${seatWording}</b> to <i>${reservation.experience.title}</i>?`

    const text =
      'Confirmed bookings are not refundable if cancelled within less than 48 hours before start of experience.'

    const sweetness = Swal.mixin({
      confirmButtonClass: 'btn btn--link',
      cancelButtonClass: 'btn',
      buttonsStyling: false,
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonText: 'Explore Experiences',
      padding: '2rem'
    })

    sweetness({
      title: 'Cancel Confirmation',
      html: `<h6>${message}</h6><p><small>${text}</small></p>`,
      type: '',
      showCancelButton: true,
      cancelButtonText: 'No, Dont Cancel',
      confirmButtonText: `Yes, I'm Sure`,
      padding: '3rem'
    }).then(result => {
      if (result.value) {
        transactionActions.cancelReservation(reservation.id)
      }
    })
  }

  render() {
    const {
      allReservations,
      tabs,
      futureReservations,
      pastReservations,
      transactionActions
    } = this.props

    let reservations = []
    let keyword
    let Card

    switch (tabs.experiences) {
    case 'pastExperiences':
      reservations = pastReservations
      Card = PastCard
      keyword = 'past'
      break
    case 'upcomingExperiences':
      reservations = futureReservations
      Card = FutureCard
      keyword = 'upcoming'
      break
    default:
      reservations = []
    }
    if (typeof reservations === 'undefined') {
      return <div className="spinner" />
    }
    if (reservations.length > 0) {
      return (
        <div className="grid grid--uniform">
          {reservations.map(item => (
            <div key={"Card-"+item.id} className={gridSize}>
              <Card // Past or Future based on view
                reservation={item}
                experience={item.experience}
                cancelReservation={() => this.handleCancellation(item)}
                readReview={() => this.readReview(item)}
                viewReceipt={() => this.viewReceipt(item)}
              />
            </div>
          ))}
        </div>
      )
    }
    return (
      <div className="text-center text-warning">
        <div className="section-header section-heading">
          <h5>You don't have any {keyword == 'past' ? keyword : ''} bookings</h5>
        </div>
        <p>
          <Link to="/experiences" className="btn">
            Explore Experiences
          </Link>
        </p>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  transactions: state.transactions,
  allReservations: state.transactions.reservations.made.data,
  futureReservations: state.transactions.reservations.made.future,
  pastReservations: state.transactions.reservations.made.past,
  fetchStatus: state.transactions.reservations.made.status,
  tabs: state.tabs
})

const mapDispatchToProps = dispatch => ({
  userActions: bindActionCreators(userActionCreators, dispatch),
  transactionActions: bindActionCreators(transactionActionCreators, dispatch),
  tabActions: bindActionCreators(tabActionCreators, dispatch)
})

// You have to connect() to any reducers that you wish to connect to yourself
export default connect(mapStateToProps, mapDispatchToProps)(MyExperiencesList)
