import React, { Component } from 'react'
import PropTypes from 'prop-types'

const TabButtons = ({ selectedTab, namespace, changeTab, tabs }) => {
  const isMobile = window.matchMedia('(max-width: 1005px)').matches
  const classForButtons = isMobile ? '' : 'button-group btn-group'
  const mobileStyle = isMobile ? 'mb-5' : ''
  return (
    <React.Fragment>
      <div className={classForButtons}>
        {tabs.map(tab => {
          const activeTabClass = 'btn--darker'
          const defaultTabClass = 'btn--dark'
          const tabClass =
            tab.name === selectedTab ? activeTabClass : defaultTabClass
          return (
            <button
              type="button"
              className={`btn btn--flat btn--medium ${tabClass} ${mobileStyle}`}
              key={tab.name}
              onClick={() => changeTab(tab.name, namespace)}
            >
              {tab.title}
            </button>
          )
        })}
      </div>
    </React.Fragment>
  )
}

export default TabButtons
