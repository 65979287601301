import {
  SET_HOSTS,
  AUTH_TOKEN_FAILED,
  AUTH_SIGNOUT_SUCCESS,
  SET_HOSTS_META
} from '../../constants/actionTypes'

const initialState = {
  hosts: [],
  hostsMeta:[]
}

export default function(state = initialState, action) {


  if (action.type === SET_HOSTS) {
    // console.log('action hosts', action);
    // console.log("statehost",state,action.hosts);
    // let total_hosts = state.hosts.concat(action.hosts);
    let total_hosts = action.hosts
    // console.log("total host",total_hosts);
    // return  total_hosts;
    return  {
      ...state,
      hosts: total_hosts
    }
  }

  if (action.type === SET_HOSTS_META) {
    // console.log('action hosts', action);
    return {
      ...state,
      hostsMeta: action.hostsMeta
    }
  }


  /*
  if (action.type === SET_HOSTS) {
    return [...action.hosts];
  }*/
  if (action.type === AUTH_SIGNOUT_SUCCESS) {
    return initialState
  }

  if (action.type === AUTH_TOKEN_FAILED) {
    return initialState
  }
  return state
}
