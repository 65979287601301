import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Flickity from 'flickity-fullscreen'
import _ from 'lodash'
import 'flickity-fullscreen/fullscreen.css'
import 'flickity-as-nav-for'

class Slider extends Component {
  constructor(props) {
    super(props)
    this.state = {
      flkty: null,
      flktynav: null
    }
  }

  componentDidMount() {
    this.initSlider()
    if (typeof window !== 'undefined') {
      // window.addEventListener('resize', this.handleResize);
    }
  }

  componentDidUpdate() {
    // this.initSlider();
  }

  initSlider = () => {
    const { uniquename, thumbnav } = this.props
    const elem = document.querySelector(`#${uniquename}`)
    if (elem) {
      const flkty = new Flickity(elem, {
        fullscreen: true,
        pageDots: false
      })
      this.setState({ flkty })
    }
    if (thumbnav) {
      const navElem = document.querySelector(`#${uniquename}-nav`)
      if (elem) {
        const flktyNav = new Flickity(navElem, {
          fullscreen: false,
          asNavFor: `#${uniquename}`,
          contain: true,
          pageDots: false
        })
        this.setState({ flktyNav })
      }
    }
  };

  render() {
    const { photos, uniquename, thumbnav, fromExperience } = this.props
    return (
      <React.Fragment>
        {photos.length && (
          <section className="photography" style={{padding: fromExperience?'0px': null}}>
            <div className="image-carousel-fullscreen" id={`${uniquename}`}>
              {photos.map((item, index) => {
                const rawUrl = item.big ? item.big : item
                const url = _.replace(_.replace(rawUrl, '(', '%28'), ')', '%29')

                // TODO: fix handling brackets with escaping('\') instead of replacing
                return (
                  /* eslint-disable-next-line react/no-array-index-key */
                  <div className="carousel-cell" key={`${url+'big'+index}`}>
                    <div
                      className="image"
                      style={{ backgroundImage: `url(${url})` }}
                    />
                  </div>
                )
              })}
            </div>
            {thumbnav && (
              <div
                className="image-carousel-fullscreen--nav"
                id={`${uniquename}-nav`}
              >
                {photos.map((item, index) => {
                  const rawUrl = item.small ? item.small : item
                  // TODO: fix handling brackets here as well
                  const url = _.replace(
                    _.replace(rawUrl, '(', '%28'),
                    ')',
                    '%29'
                  )
                  return (
                    /* eslint-disable-next-line react/no-array-index-key */
                    <div className="carousel-cell" key={`${url+'thumbnav'+index}`}>
                      <div
                        className="image"
                        style={{ backgroundImage: `url(${url})` }}
                      />
                    </div>
                  )
                })}
              </div>
            )}
          </section>
        )}
      </React.Fragment>
    )
  }
}
export default Slider
