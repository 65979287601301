import React, { Component } from 'react'
import { useState } from 'react'
import { Field, reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { validate } from './validate'
import { currentUserPropTypes } from '../../../constants/composed-proptypes'

import * as fields from '../../Form/Fields/formFields'

import RenderDateField from '../../Form/Fields/renderDateField'

import {
  backgroundFieldCollection,
  personalFieldCollection,
  addressFieldCollection,
  contactFieldCollection,
  email,
  phoneNumber
} from '../../../constants/formFields'

import { SPLIT_GRID_SIZE } from '../../../constants'

import AvatarUpload from '../../Form/Fields/AvatarUpload'

import AccountDetailsHost from '../../Form/CommonForms/User/AccountDetailsHost'

function keysMatch(key, obj) {
  const keys = Object.keys(obj).map(x => x.toLowerCase())
  return keys.indexOf(key.toLowerCase()) !== -1
}

/* NOTE: if currentUser form values match,they will be updated via
 * the initialValues of connectedStep3 which connects to Redux.
 * this happens automagically for all fields on the redux connected
 * form hostRegistration
 * @see https://redux-form.com/7.0.2/examples/initializefromstate/
 */

class Step3 extends Component{
 state = { isAvatar : false} 

  updateAvatarStatus = (value) => {
    this.setState({isAvatar : value})
  }

  componentDidMount=()=>{
    if(this.props.currentUser.attributes.profilePicture && this.props.currentUser.attributes.profilePicture.thumb !="blank.jpg"){
      this.setState({isAvatar : true})
    }
  }

  render (){
    const {
      handleSubmit,
      pristine,
      submitting,
      valid,
      previousPage,
      form
    } = this.props
    return (
      <React.Fragment>
        <div className="form-header">
          <h4>Personal Info</h4>
        </div>
        <AccountDetailsHost displayAvtarError={true} {...this.props} isAvatar ={this.state.isAvatar} updateAvatarStatus = {this.updateAvatarStatus} changeAvatar={true} />
        <div className="form-footer">
          <button
            type="button"
            className="btn btn--link form-footer--left"
            onClick={() => previousPage()}
          >
            Go Back one Step
          </button>
          <button
            type="submit"
            disabled={submitting || !this.state.isAvatar}
            form={form}
            className="btn btn--darker form-footer--right"
          >
            Continue
          </button>
        </div>
      </React.Fragment>
    )
  }
 
};

Step3.propTypes = {
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  valid: PropTypes.bool.isRequired,
  previousPage: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  currentUser: currentUserPropTypes.isRequired
}

// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
const reduxFormStep3 = reduxForm({
  form: 'hostRegistration',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  enableReinitialize:true,
  validate
})(Step3)

// You have to connect() to any reducers that you wish to connect to yourself
const connectedStep3 = connect(state => ({
  initialValues: state.reduxTokenAuth.currentUser.attributes
  // pull initial values from account reducer
}))(reduxFormStep3)

export default connectedStep3
