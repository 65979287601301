import { REHYDRATE } from 'redux-persist'

import {
  // RESERVATIONS
  // RESERVATIONS_RECEIVED_REQUEST_SENT,
  // RESERVATIONS_RECEIVED_REQUEST_SUCCESS,
  // RESERVATIONS_RECEIVED_REQUEST_FAILED,
  // SET_RESERVATIONS_RECEIVED_REQUEST_DATA,
  AUTH_SIGNOUT_SUCCESS,
  AUTH_TOKEN_FAILED
} from '../../../constants/actionTypes'

// import { GenericRequestStatus } from '../../../constants/statuses';

const initialState = {
  status: null,
  data: []
}

export default function(state = initialState, action) {
  switch (action.type) {
  case AUTH_SIGNOUT_SUCCESS:
    return initialState
  case AUTH_TOKEN_FAILED:
    return initialState
  default:
    return state
  }
}
