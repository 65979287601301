import qs from 'qs'
import api from '../../utils/api'
import { getUserAttributesFromResponse } from '../../utils/auth-tools'

import { camelToSnake } from '../../utils/camelSnake'

/* eslint-disable-next-line no-unused-vars */
import { normalizeProfile } from '../../utils/normalization'

import alertModal from '../../utils/alert'

import { SET_USER_ATTRIBUTES } from '../../constants/actionTypes'

export const setHostAttributes = userAttributes => ({
  type: SET_USER_ATTRIBUTES,
  payload: {
    userAttributes
  }
})

export function updateHost(attributes) {
  /* eslint-disable-next-line no-unused-vars, no-console */
  return async (dispatch, getState) => {
    // const location = {};
    const hosting_location = {}
    let user = { ...attributes }
    user.location = user.location ? user.location : {}
    user.location.city = user.city ? user.city : null
    user.location.countryCode = user.countryCode && user.countryCode.value ? user.countryCode.value : user.country_code

    if (
      user.hostAddressFieldsCity ||
      user.hostAddressFieldsCountryCode ||
      user.hostingLocationStreetAddress ||
      user.hostAddressFieldsPostalCode
    ) {
      hosting_location.city = user.hostAddressFieldsCity.value
      hosting_location.country_code = user.hostAddressFieldsCountryCode.value
      hosting_location.postal_code = user.hostAddressFieldsPostalCode
      hosting_location.street_address = user.hostingLocationStreetAddress
    }

    const locationPhotos = []
    const foodPhotos = []
    if (
      typeof user.locationPhotos !== 'undefined' &&
      user.locationPhotos.length > 0
    ) {
      user.locationPhotos.forEach(object => {
        locationPhotos.push(object.signedId)
      })
    }

    if (typeof user.foodPhotos !== 'undefined' && user.foodPhotos.length > 0) {
      user.foodPhotos.forEach(object => {
        foodPhotos.push(object.signedId)
      })
    }

    const { image } = user
    if (typeof image !== 'undefined' && image !== 'object') {
      user = {
        ...user,
        image: image.signed_id ? image.signed_id : undefined
      }
    }

    user = {
      ...user,
      locationPhotos,
      foodPhotos,
      // location,
      hosting_location
    }

    let birthday = user.birthday

    // Snake case out data to match server side
    user = camelToSnake(user, 2) // Snake Case Keys (data, depth)

    user.birthday = birthday
    let response
    try {
      user.gender = user.gender ? user.gender.value : user.gender
      user.preferred_currency = user.preferred_currency ? user.preferred_currency.value : user.preferred_currency
      response = await api.post(`/users/update_profile`, { user })
      const userAttributes = getUserAttributesFromResponse(response)
      dispatch(setHostAttributes(userAttributes))
      return response
    } catch (err) {
      // alertModal(err.response, 'error', 'Try Again');
      throw err
    }
  }
}



export function updateHostingSpace(attributes) {
  /* eslint-disable-next-line no-unused-vars, no-console */
  return async (dispatch, getState) => {
    // const location = {};
    let user = { ...attributes }
    const hosting_location = {}
   
    hosting_location.city = typeof user.hostAddressFieldsCity=="string"? user.hostAddressFieldsCity:  user.hostAddressFieldsCity.value
    hosting_location.country_code = typeof user.hostAddressFieldsCountryCode=="string"? user.hostAddressFieldsCountryCode:  user.hostAddressFieldsCountryCode.value
    hosting_location.postal_code = user.hostAddressFieldsPostalCode
    hosting_location.street_address = user.hostingLocationStreetAddress
    hosting_location.neighbourhood = user.hostAddressFieldsNeighbourhood
    hosting_location.amenities = user.amenities
    hosting_location.wow_elements = user.wow_elements

    const locationPhotos = []
    const foodPhotos = []
    if (
      typeof user.locationPhotos !== 'undefined' &&
      user.locationPhotos.length > 0
    ) {
      user.locationPhotos.forEach(object => {
        locationPhotos.push(object.signedId)
      })
    }
    user = {
      location_photos: locationPhotos,
      hosting_location
    }
    // Snake case out data to match server side
    user = camelToSnake(user, 2) // Snake Case Keys (data, depth)

    let response
    try {
     
      response = await api.post(`hosts/update_hosting_space_info`, { user })
      const userAttributes = getUserAttributesFromResponse(response)
      dispatch(setHostAttributes(userAttributes))
      return response
    } catch (err) {
      // alertModal(err.response, 'error', 'Try Again');
      throw err
    }
  }
}

