import React from "react"

const Vegetarian = props => (
  <svg viewBox="0 0 88 88" width="1em" height="1em" {...props}>
    <g fill="currentColor" data-name="Layer 2">
      <path d="M57.34 32.49l-.34-.07 2.72-2.71a1 1 0 0 0 0-1.41 1 1 0 0 0-1.42 0L55.58 31c0-.12 0-.24-.07-.35a3.83 3.83 0 0 0-2.1-2.57 1 1 0 0 0-1.32.5 1 1 0 0 0 .49 1.32 1.87 1.87 0 0 1 1 1.26 2.8 2.8 0 0 1-.37 2.21l-.92.92a1 1 0 0 0 0 1.41 1 1 0 0 0 .71.3 1 1 0 0 0 .71-.29l.91-.92a2.76 2.76 0 0 1 2.21-.36 1.85 1.85 0 0 1 1.26 1A1 1 0 0 0 59 36a1 1 0 0 0 .41-.09 1 1 0 0 0 .5-1.32 3.83 3.83 0 0 0-2.57-2.1zM46.41 34a7.51 7.51 0 0 0-5.36 2.23c-3 3-9.68 12.72-12.4 18-1 1.86-.85 3.71.28 4.84a3.27 3.27 0 0 0 2.37.93 5.52 5.52 0 0 0 2.46-.65c5.34-2.75 15.1-9.46 18-12.38a7.62 7.62 0 0 0 0-10.74A7.53 7.53 0 0 0 46.41 34zm4 11.55a42.27 42.27 0 0 1-4.07 3.33l-1.63-1.58a1 1 0 0 0-1.42 0 1 1 0 0 0 0 1.41l1.37 1.37a110.11 110.11 0 0 1-11.82 7.49c-1.06.55-2 .58-2.5.09s-.45-1.45.09-2.5 1.18-2.17 1.91-3.4l.95 1A1 1 0 0 0 34 53a1 1 0 0 0 .71-1.7L33.43 50c2.28-3.61 5-7.51 7.07-10.1l1.79 1.79A1 1 0 0 0 43 42a1 1 0 0 0 .71-1.7l-1.92-1.92c.24-.28.47-.54.67-.74a5.59 5.59 0 0 1 7.89 7.91z" />
      <path d="M38.71 47.3a1 1 0 0 0-1.42 0 1 1 0 0 0 0 1.41l2 2A1 1 0 0 0 40 51a1 1 0 0 0 .71-1.7zM48.71 41.3a1 1 0 0 0-1.42 0 1 1 0 0 0 0 1.41l1 1A1 1 0 0 0 49 44a1 1 0 0 0 .71-1.7z" />
      <path d="M44 88a44 44 0 1 1 44-44 44.05 44.05 0 0 1-44 44zm0-86a42 42 0 1 0 42 42A42 42 0 0 0 44 2z" />
    </g>
  </svg>
)

export default Vegetarian
