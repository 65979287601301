import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import { RightNavItems, LeftNavItems } from './Navs'

import transitions from '../../../utils/transitions'
import { calcNavHeight } from '../../../utils/navbarScroll'
import 'gsap/CSSPlugin'

class MobileMenu extends Component {
  componentDidMount(props) {
    const selectors = {
      $body: $('body'),
      $navbar: $('#mainNav'),
      $announcementBar: $('[data-banner-location=top]'),
      $mobileMenu: $('.breakbread-mobile-menu'),
      $button: $('.breakbread-burger-button'),
      $container: $('.breakbread-mobile-menu-container'),
      iconClass: '.breakbread-animated-menu-icon'
    }

    const { $body, $navbar, $announcementBar } = selectors
    const tl = transitions.mobileMenu.open($('.breakbread-mobile-menu'))
    const padding = calcNavHeight({ $body, $navbar, $announcementBar })
    $('.breakbread-mobile-menu-container').css('padding-top', padding)
    tl.play()
  }

  render() {
    const { currentUser, toggleMobileMenu } = this.props
    var d = new Date()
    var year = d.getFullYear()
    return (
      <div className="breakbread-mobile-menu" style={{ display: 'block' }}>
        <div className="breakbread-mobile-menu-backdrop" />
        <div className="breakbread-mobile-menu-box">
          <div className="breakbread-mobile-menu-background" />
          <div className="breakbread-mobile-menu-inner">
            <div className="breakbread-mobile-menu-container">
              <div className="breakbread-mobile-menu-content">
                <nav className="breakbread-mobile-menu-main-nav nav navbar-nav">
                  <ul className="breakbread-list-group">
                    <LeftNavItems {...this.props} isMobile toggleMobileMenu={() => toggleMobileMenu({ closed: true })} />
                  </ul>
                </nav>
                <div className="breakbread-mobile-menu__content">
                  <h6>Get in touch</h6>
                  <p>
                    <a href="mailto:support@breakbread.com" target="_top">
                      support@breakbread.com
                    </a>
                    <br />
                    {/* +971 (6) 554 7222<br />
                    M-F, 9:00AM - 5:00PM GST */}
                    © {year}, <a href="/">BreakBread.com</a>
                    <br />
                    BreakBread LTD
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default MobileMenu
