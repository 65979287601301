import React, { Component } from 'react'

import ImageWithOverlaySquare from './Web/ImageWithOverlaySquare'
import RegLink from '../Registration/Users/RegLink'
import CommunityImage from '../../images/home/join-the-community.jpeg'
import newsletterSignup from '../../actions/newsletter'

export default class SignUpAndSubscribe extends Component {
  constructor(props) {
    super(props)
    this.state = { email: '' }
  }

  signUp = () => {
    const { email } = this.state
    newsletterSignup(email)
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  render() {
    const isMobile = window.matchMedia('(max-width: 1005px)').matches
    const themeForHero = isMobile ? null : 'light'
    const { email } = this.state

    return (
      <div className="grid grid--no-gutters grid--uniform">
        <div className="grid__item small--one-whole medium-up--one-half">
          <ImageWithOverlaySquare image={CommunityImage} theme={themeForHero === 'light' ? null : themeForHero}>
            <h2>Join Our Community</h2>
            <p className="text-20">Join our community of passionate foodies!</p>
            <RegLink className="btn">
              <span>Sign Up</span>
            </RegLink>
          </ImageWithOverlaySquare>
        </div>
        <div className="grid__item small--one-whole medium-up--one-half">
          <ImageWithOverlaySquare theme="color">
            <h2>Stay Up to Date</h2>
            <p className="text-20">Subscribe to our newsletter to receive updates on experiences, promotions, and events!</p>
            <div className="breakbread-newsletter-form">
              <div className="breakbread-newsletter-form-inner">
                <div className="input-group">
                  <input
                    type="email"
                    name="email"
                    id="Email"
                    value={email}
                    onChange={(e) => this.handleChange(e)}
                    className="input-group__field newsletter__input"
                    placeholder="Email address"
                    autoCorrect="off"
                    autoCapitalize="off"
                  />
                  <span className="input-group__btn">
                    <button
                      type="submit"
                      onClick={() => this.signUp()}
                      className="btn btn--darker newsletter__submit"
                      name="commit"
                      id="Subscribe"
                    >
                      <span className="newsletter__submit-text--large">Subscribe</span>
                    </button>
                  </span>
                </div>
              </div>
            </div>
          </ImageWithOverlaySquare>
        </div>
      </div>
    )
  }
}
