import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { validateProfessional } from './validate'
import { currentUserPropTypes } from '../../../constants/composed-proptypes'
import RenderField from '../../Form/Fields/renderField'
import seo_meta from '../../../utils/seo-meta.json'
import * as fields from '../../Form/Fields/formFields'
import * as userActionCreators from '../../../actions/user'
import _ from 'lodash'

import { professionalFieldCollection } from '../../../constants/formFields'
import SEO from '../../Shared/Web/SEO'

function keysMatch(key, obj) {
  const keys = Object.keys(obj).map(x => x.toLowerCase())
  return keys.indexOf(key.toLowerCase()) !== -1
}

/* NOTE: if currentUser form values match,they will be updated via
 * the initialValues of connectedStep4 which connects to Redux.
 * this happens automagically for all fields on the redux connected
 * form hostRegistration
 * @see https://redux-form.com/7.0.2/examples/initializefromstate/
 */

class Step4 extends Component {
  constructor(props) {
    super(props)

    this.state = {
      professionalFields: professionalFieldCollection
    }
  }

  componentDidMount = async () => {
    await this.props.userActions.getCuisineTypesList()
    if(this.props.initialValues && this.props.initialValues.specialties) {
      this.props.change('specialties', this.props.initialValues.specialties.map((el)=>({id: el.id, label: el.name, value: el.id})))
    }
  }

  componentWillMount() { }

  /**
   * Disables a field if the same key is present in the currentUser object,
   * the user will not be able to update the field, but it will display the value.
   * @param  {object} fieldArray  an array of fields with keys and values.
   * @param  {object} currentUser the current signed in user
   * @return {object}             the updated field array
   */
  disableFields = fieldArray => {
    const { currentUser } = this.props
    if (typeof currentUser === 'undefined' || !currentUser.isSignedIn) {
      return fieldArray
    }
    const obj = fieldArray
    Object.keys(obj).map(key => {
      obj[key].disabled = !!keysMatch(key, currentUser.attributes)
      return obj[key]
    })
    return obj
  };

  limitText = limit => value => {
    if(value.length < 0) {
      return value
    } else if(value.length > limit) {
      return value.slice(0, (limit-1))
    } else {
      return value
    }
  }

  render() {
    const {
      handleSubmit,
      pristine,
      submitting,
      previousPage,
      valid,
      cuisineTypesOptions,
      initialValues
    } = this.props
    const localCuisineTypes = _.sortBy(cuisineTypesOptions?cuisineTypesOptions.filter(el=>el.status === 'active').map(el=>({label: el.name, value: el.id, id: el.id})):[], 'label')
    // console.log('localCuisineTypes', localCuisineTypes)
    const { professionalFields } = this.state
    const { specialties, culinaryJourney, socialLinks, acceptTerms } = professionalFields
    let { title, description, og_title, og_description, og_site_name, og_locale, og_type } = seo_meta.hosting_space_detail
    let formValues = {culinaryJourney: ''}
    /* if (this.props.formData['object Object']) {
      formValues = this.props.formData['object Object'].values
    } */
    if (this.props.formData.hostRegistration) {
      formValues = this.props.formData.hostRegistration.values
    }
    return (
      <React.Fragment>
        <SEO title={title} description={description} />

        <form onSubmit={handleSubmit} className="LeftLableForm">
          <div className="form-header">
            <h4>Professional Info</h4>
          </div>

          <div className="form-body">
            <div className="grid grid--uniform">
              {/* Cuisine / Specialties */}
              <div className="grid__item small--one-whole medium-up--full">
                <Field
                  name={specialties.name}
                  component={fields.renderSelectField}
                  disabled={submitting}
                  options={localCuisineTypes}
                  placeholder={specialties.placeholder}
                  isClearable
                  isMulti
                  type="select"
                  selectType="cuisine"
                  label="Cuisine / Specialties *"
                />
              </div>
              {/* Personal Info Fields */}
              <div className="grid__item small--one-whole medium-up--full">
                <Field
                  key={culinaryJourney.name}
                  field={culinaryJourney}
                  type={culinaryJourney.type}
                  name={culinaryJourney.name}
                  label={culinaryJourney.placeholder}
                  value={culinaryJourney.value}
                  disabled={culinaryJourney.disabled}
                  component={fields.renderField}
                  normalize={this.limitText(1000)}
                  inputType="textArea"
                  displayLabel
                  placeholder={culinaryJourney.placeholder}
                />
                <span className="limittextculinary">
                  <span className="field_limit_text__limit">{ 1000 - (formValues.culinaryJourney?formValues.culinaryJourney.length:0) } characters remaining</span>
                </span>
              </div>
              <div className="grid__item small--one-whole medium-up--full">
                <fields.FieldSet
                  wrap
                  legend="Social Networks *"
                  fields={socialLinks}
                  validate={validateProfessional}
                  className="flat"
                />
              </div>
              <div className="grid__item small--one-whole medium-up--full">
                {/* <input type="checkbox" checked={acceptTerms.value} name="accept_terms" id="agreeTermsAndConditions" className="form-check-input" onChange={e => {
                  this.setState({accept_terms: !accept_terms })
                }}/> */}
                <Field
                  name={acceptTerms.name}
                  component={RenderField}
                  type="checkbox"
                  inputType="checkbox"
                  placeholder={acceptTerms.placeholder}
                />
                <label htmlFor="agreeTermsAndConditions" className="inline" style={{ paddingLeft: '10px' }}>
                  <small>
                    &nbsp; I agree to BreakBread&apos;s
                    <a href="/terms-and-conditions" target="_blank">
                      &nbsp; terms and conditions
                    </a>
                  </small>
                </label>
              </div>
            </div>
          </div>
          <div className="form-footer">
            <button
              type="button"
              disabled={submitting}
              className="btn btn--link form-footer--left"
              onClick={() => previousPage()}
            >
              Go Back a Step
            </button>
            <button
              type="submit"
              disabled={submitting || !valid || (formValues && !formValues.acceptTerms)}
              className="btn btn--darker form-footer--right"
            >
              Continue
            </button>
          </div>
        </form>
      </React.Fragment>
    )
  }
}

Step4.propTypes = {
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  valid: PropTypes.bool.isRequired,
  previousPage: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  currentUser: currentUserPropTypes.isRequired
}

// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
const reduxFormStep4 = reduxForm({
  form: 'hostRegistration',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  //enableReinitialize:true,
  validate: validateProfessional
})(Step4)

const mapDispatchToProps = dispatch => ({
  userActions: bindActionCreators(userActionCreators, dispatch)
})
// You have to connect() to any reducers that you wish to connect to yourself
const connectedStep4 = connect(state => ({
  initialValues: state.reduxTokenAuth.currentUser.attributes,
  formData: state.form,
  cuisineTypesOptions: state.user.amenitiesAndWowElements.cuisineTypes
    ? state.user.amenitiesAndWowElements.cuisineTypes.list
    : null
  // pull initial values from account reducer
}), mapDispatchToProps)(reduxFormStep4)

export default connectedStep4
