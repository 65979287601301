import React from 'react'
import PropTypes from 'prop-types'
import PastCard from '../MyExperiences/PastCard'

const gridSize =
  'grid__item medium-down--one-half medium--one-half large-up--one-third'

const NeedsReview = props => {
  const { experiences, modalActions } = props
  const filteredExperiences = experiences.length > 0 && experiences.filter(item => item.sample === false)
  return (
    <div className="breakbread-container">
      {filteredExperiences.length !== 0 && (
        <h5 className="text-center">
          Review guests from your latest experiences:
        </h5>
      )}
      <div className="grid grid--uniform" style={{ marginTop: '2rem' }}>
        {filteredExperiences.length === 0 && (
          <h6 className="text-center m-auto">
            Great! Looks like you have no reviews pending!
          </h6>
        )}
        {filteredExperiences && filteredExperiences.length
          ? typeof filteredExperiences !== 'undefined' &&
          filteredExperiences.length &&
          filteredExperiences.map(item => (
            <div key={`PastCard-${item.id}`} className={gridSize}>
              <PastCard
                key={item.id}
                experience={item}
                writeReviews={() => {
                  modalActions.showHostReviewModal({
                    ...props,
                    experience: item
                  })
                }}
              />
            </div>
          ))
          : null}
      </div>
    </div>
  )
}

NeedsReview.propTypes = {
  experiences: PropTypes.arrayOf(PropTypes.shape({})).isRequired
}

export default NeedsReview
