export default values => {
  const errors = {}
  if (typeof values === 'undefined') {
    return errors
  }

  if (!values.starRating) {
    errors.starRating = 'Required'
  }
  if (values.comment != undefined && values.comment.length > 1000) {
    errors.comment = 'Comment should have maximum 1000 charcters'
  }

  if (!values.star) {
    errors.star = 'Required'
  }
  // if (!values.comment) {
  //   errors.comment = 'Required';
  // }

  return errors
}
