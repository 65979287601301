import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import ChefAvtarDiner from '../Chef/ChefAvtarDiner'
import ChefInfo from '../Chef/ChefInfo'
// import ReceiptLink from '../../Receipt/ReceiptLink'
import { showReceiptModal } from '../../actions/modals'
import moment from 'moment'
import HostActionButttons from './Host/HostActionButttons'
import HostReviewLink from '../Workflows/HostReview/HostReviewLink'

class HostReservationCard extends Component {
  componentDidMount() {}

  componentWillUnmount() {}

  render() {
    const { reservation, updateReservation } = this.props
    const {
      bookingNumber,
      experience,
      bookedDate,
      status,
      seatsBooked,
      date,
      featured,
      hideAvatar,
      paymentStatus,
      netTotal,
      currency,
      forceHoverState,
      displaySoldStatus,
      guest,
      schedule,
      guestReview,
      hostReview
    } = reservation

    const now = new Date()
    // const hasPassed = experience.date < now.toISOString()
    let extraClassNames = ''
    extraClassNames += featured ? ' experience-card--featured' : ''
    extraClassNames += forceHoverState ? ' grid-view-item--force-hover' : ''
    extraClassNames += hideAvatar ? ' experience-card--avatar-hidden' : ''
    // experience.readableTime = experience.time ? experience.time : experience.readableTime;

    let statusClass = 'badge '

    switch (status) {
    case 'confirmed':
      statusClass = 'badge badge-success'
      break
    case 'cancelled':
      statusClass = 'badge badge-error'
      break
    case 'declined':
      statusClass = 'badge badge-rejected'
      break
    default:
      statusClass = 'badge badge-pending'
      break
    }

    const paymentStatusColor = 'grey'
    let paymentStatusClass = 'badge '
    switch (paymentStatus) {
    case 'succeeded':
      paymentStatusClass = 'badge badge-success'
      break
    case 'cancelled':
    case 'failed':
      paymentStatusClass = 'badge badge-error'
      break
    case 'declined':
    case 'flagged':
      paymentStatusClass = 'badge badge-rejected'
      break
    default:
      paymentStatusClass = 'badge badge-pending'
      break
    }
    let paymentStatusStyle = { color: paymentStatusColor }

    const after72Date = moment(bookedDate).add(72, 'hours')
    const currentDate = moment(new Date())

    return (
      <React.Fragment>
        <div className={`experience-card grid-view-item ${extraClassNames}`}>
          <div className="experience-card__image--container grid-view-item__link grid-view-item__image-container">
            <div className="grid-view-item__image-wrapper js">
              <div
                className="grid-view-item__overlay box ratio-container lazyload js"
                // NOTE: NEED TO ADD LAZY lOAD SUPPORT
                data-sizes="auto"
                data-parent-fit="cover"
                style={{
                  backgroundImage: `url("${experience.image}")`
                }}
              />
            </div>
          </div>

          <div className="experience-card__meta grid-view-item__meta">
            {!hideAvatar && (
              <div
                /* onClick={() => {
                  this.props.showModal(reservation)
                }} 
                style={{ cursor: 'pointer' }}*/
              >
                <ChefAvtarDiner displayInfo={false} hideLink person={guest} />
              </div>
            )}
            <div className="experience-card__text grid-view-item__text">
              {!hideAvatar && (
                <div
                  /*  onClick={() => {
                    this.props.showModal(reservation)
                  }} 
                  style={{ cursor: 'pointer' }}*/
                >
                  <span style={{ /* cursor: 'pointer', */ color: '#f8a48d' }}>
                    {guest.fullname}
                  </span>
                  {/* <ChefInfo displaySpecialties={false} person={guest} /> */}
                </div>
              )}
              <div className="experience-card__title grid-view-item__title">
                <Link to={`/experiences/${experience.slug}`}>
                  <h5>{experience.title}</h5>
                </Link>
              </div>
            </div>

            <span  onClick={() => {
              this.props.showModal(reservation)
            }} 
            style={{ cursor: 'pointer' }}>{bookingNumber}</span>
            <br></br>
            <span className="cardDate">
              <p style={{paddingBottom: '0px', marginBottom: '0px', paddingTop: '0px'}}>Exp. Date: {date}</p>
              <p style={{paddingBottom: '0px', marginBottom: '0px', paddingTop: '0px'}}>Booked On: {bookedDate}</p>
            </span>
          </div>

          <div className="experience-card__reservation-info group">
            <div
              className="experience-card__date"
              style={{ borderLeft: 'none' }}
            >
              <span style={{ color: '#f8a48d' }}>
                Booking Status{' '}
              </span>
              <span>
                <p
                  style={{
                    fontSize: '14px',
                    margin: '0 auto',
                    marginTop: '5px',
                    padding: '7px',
                    color: 'white'
                  }}
                  className={statusClass}
                >
                  {status.charAt(0).toUpperCase() + status.slice(1)}
                </p>
              </span>
            </div>
            <div className="experience-card__price">
              Seats{' '}
              <span>
                <p>{seatsBooked}</p>
              </span>
            </div>

            <div className="experience-card__date">
              <span style={{ color: '#f8a48d' }}>
                Payment Status{' '}
              </span>
              <p
                className={paymentStatusClass}
                style={{
                  fontSize: '14px',
                  margin: '0 auto',
                  marginTop: '5px',
                  padding: '7px',
                  color: 'white'
                  // ...paymentStatusStyle
                }}
              >
                {paymentStatus.charAt(0).toUpperCase() + paymentStatus.slice(1)}
              </p>
            </div>
            <div className="experience-card__price">
              {currency}
              <p>
                { parseFloat(netTotal).toFixed(2) }
              </p>
            </div>
            {/* <div className="experience-card__price">
              Cost
              <p style={paymentStatusStyle}>
                { netTotal } {currency}
              </p>
            </div>
            <div className="experience-card__price">
              Status{' '}
              <p
                className={statusClass}
                style={{
                  fontSize: '14px',
                  margin: '5px 5px 0 0',
                  padding: '7px',
                  color: 'white'
                }}
              >
                {status.charAt(0).toUpperCase() + status.slice(1)}
              </p> */}
            {/* <span className="cardDate" style={{fontSize: '11px'}}>
              <p>{bookedDate}</p>
            </span> */}
            {/* </div>
            <div className="experience-card__price">
              Seats{' '}
              <span>
                <p>{seatsBooked}</p>
              </span>
            </div> */}
          </div>

          {/** first view of card 
                    <div className="experience-card__reservation-info group">
                        <div className="experience-card__date color_orange">
                            Status <span><p>{status}</p></span>
                        </div>
                        <div className="experience-card__price">
                            Seats <span><p>{seatsBooked}</p></span>
                        </div>
                    </div>

                    <div className="experience-card__reservation-info group">
                        <div className="experience-card__date color_orange">
                            Booking Date <span><p>{bookedDate}</p></span>
                        </div>
                        <div className="experience-card__price">
                            <p>{bookingNumber}</p>
                        </div>
                    </div>
                    */}

          {/* {paymentStatus === 'pending' && (status === "rejected" || status === "pending" && currentDate <= after72Date) && 
          <div
            className="experience-card__reservation-info group actionCard"
            style={{ height: '4.5rem' }}
          >
            {paymentStatus !== 'succeeded' ? (<React.Fragment>
              {paymentStatus === 'pending' && ((status === 'rejected') || (status === 'pending' &&  currentDate <= after72Date ) ) ? (<div
                style={{ padding: '10px' }}
                className="breakbread-btn btn btn--dark btn-sm btn-Mycolor"
                onClick={() => updateReservation('approve', reservation)}
              >
                  Accept
              </div>) : (paymentStatus === 'pending' &&  ((status === 'pending' && currentDate <= after72Date ) || (status === 'approved'))) ? (<div
                style={{ padding: '10px', marginLeft: '5px' }}
                className="breakbread-btn btn btn--dark btn-sm btn-Mycolor"
                onClick={() => updateReservation('decline', reservation)}
              >
                  Reject
              </div>): ''}

            </React.Fragment>
            ):''}
          </div>
          } */}
          <HostActionButttons experience={{...experience, schedule: schedule, selectedReservation: reservation.id, isHostReservationPage: true, hostReview: hostReview }} reservation={reservation} updateReservation={updateReservation} currentDate={currentDate} after72Date={after72Date} showModal={this.props.showModal}/>
        </div>
      </React.Fragment>
    )
  }
}

HostReservationCard.defaultProps = {
  featured: false,
  hideAvatar: false,
  forceHoverState: false
}

const mapStateToProps = state => ({
  // hosts: state.user.hosts,
  reviews: state.host.reviews.reviews,
  allExperiences: state.host.listings.data
})

const mapDispatchToProps = dispatch => ({
  // getReservations: () => dispatch(getReservationsAction()),
  // getHostReviews: id => dispatch(getHostReviews(id)),
  // transactionActions: bindActionCreators(transactionActionCreators, dispatch),
  // hostActions: bindActionCreators(hostActionCreators, dispatch),
  showModal: state => {
    dispatch(showReceiptModal({ item: state, receiptType: 'booking_detail' }))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(HostReservationCard)
