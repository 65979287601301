import React, { Component } from 'react'
import moment from 'moment'
import GuestReviewLink from '../../Workflows/GuestReview/GuestReviewLink'

export default class DinerBookingActionButtons extends Component {
  render() {
    const {
      reservation,
      updateReservation,
      cancelReservation,
      getReservations,
      isCancellable
    } = this.props

    const renderButton = Button => {
      const ButtnData = []

      switch (Button) {
      case 'ViewReceipt':
        ButtnData.push(
          <div  
            key={"actionDinerButton-"+Button}
            onClick={() => {
              this.props.showModal(reservation)
            }}
            style={{ padding: '10px', margin: '10px' }}
            className="breakbread-btn btn btn--dark btn-sm btn-Mycolor"
          >
              View Receipt
          </div>
        )
        break

      case 'Review':
        ButtnData.push(
          <GuestReviewLink
            key={"actionDinerButton-"+Button}
            className="breakbread-btn btn btn--dark btn-sm btn-Mycolor custom-button-review-card"
            reservation={reservation}
            callbackParams={{ needs_guest_reviews: true }}
            getReservations={getReservations}
          >
            {(Object.keys(reservation.guestReview).length) ? 'Read my Review' : 'Write a Review'}
          </GuestReviewLink>
        )
        break
      case 'CancelBooking':
        ButtnData.push(
          <div
            key={`actionDinerButton-${Button}`}
            onClick={() => cancelReservation(reservation)}
            style={{ padding: '10px' }}
            className="breakbread-btn btn btn--dark btn-sm btn-Mycolor"
          >
                Cancel Booking
          </div>
        )
        break
      default:
        ''
      }
      return ButtnData
    }
    const actionButtons = []
    let inPast = false

    if(reservation.schedule.scheduleTime) {
      if (moment(reservation.schedule.scheduleTime).isBefore(moment(new Date()).utc())) {
        inPast = true
      }
    } else {
      inPast = true
    }

    if (reservation.status != 'declined') {
      actionButtons.push('ViewReceipt')

      if (inPast && reservation.status === "confirmed" && reservation.paymentStatus === "succeeded" ) {
        actionButtons.push('Review')
      }
      if (isCancellable(reservation) && reservation.status !== "cancelled") {
        actionButtons.push('CancelBooking')
      }
    }

    return (
      <div className="experience-card__reservation-info group actionCard">
        {actionButtons.map(button => (
          <React.Fragment key={"renderDinerButton-"+button}>{renderButton(button, reservation)}</React.Fragment>
        ))}
      </div>
    )
  }
}
