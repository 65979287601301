import React, { Component } from 'react'
import { connect } from 'react-redux'
import AccountsWrapper from './AccountsWrapper'
import AccountForm from '../Workflows/Account/AccountForm'
import reactNavigation from 'react-navigation'

import { bindActionCreators } from 'redux'
import * as userActionCreators from '../../actions/user'

import seoMeta from '../../utils/seo-meta.json'
import SEO from '../Shared/Web/SEO'

class AccountDetails extends Component {

  constructor(props) {
    super(props)
    this.state = {
      formType: "account"
    }
  }

  render() {
    const { currentUser } = this.props

    const title = currentUser.attributes.role === 'host'
      ? seoMeta.users_personal_detail.title
      : 'Account Details | Breakbread'
    return (

      <React.Fragment>
        <SEO title={title} description={title} />

        <div className="breakbread-container">

          <div className="grid grid--uniform " >
            <div className="grid__item ">
              <div className="">
                <div className="page-header text-center">
                  <h1 >
                    <React.Fragment>
                      {currentUser.attributes.role === 'host'
                        ? 'Personal Details'
                        : 'Account Details'}
                    </React.Fragment>
                  </h1>
                </div>

                <div className="account">
                  <AccountForm form="account" />
                </div>

              </div>

            </div>

          </div>

        </div>

      </React.Fragment>

    )
  }
}

const mapStateToProps = state => ({
  
  currentUser: state.reduxTokenAuth.currentUser
})

const mapDispatchToProps = dispatch => ({
  userActions: bindActionCreators(userActionCreators, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountDetails)
