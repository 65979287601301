import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import moment from 'moment'
import IntlTelInput from 'react-intl-tel-input'
import { currentUserPropTypes } from '../../../../constants/composed-proptypes'
import {
  sendVerifyEmailLink,
  sendVerifyMobileLink,
  verifyMobile
} from '../../../../actions/forgotPassword'
/** * not usefull now
import successIcon from '../../../../icons/status/success-icon.png'
import errorIcon from '../../../../icons/allergies_and_diets/SVG/icon_error.svg'
* **** */

import { phoneNumber } from '../../../../constants/formFields'
import './AccountDetails.css'
import 'react-intl-tel-input/dist/main.css'

import { history } from '../../../../configureStore'

/* NOTE: if currentUser form values match,they will be updated via
 * the initialValues of connectedStep3 which connects to Redux.
 * this happens automagically for all fields on the redux connected
 * form hostRegistration
 * @see https://redux-form.com/7.0.2/examples/initializefromstate/
 */

class VerifyMobile extends Component {
  constructor(props) {
    super(props)
    this.state = {
      phoneNumber,
      mobile: '',
      show: props.justRegister,
      confirmBox: props.justRegister,
      verifyOtp: '',
      otPerror: '',
      countResend: 0,
      didMount: false,
      verifyPhone: {
        show: false,
        resendCount: 0,
        totalSeconds: 30
      }
    }
  }

  componentDidMount = async () => {
    const { currentUser, change, justRegister } = this.props
    if (currentUser.attributes && currentUser.attributes.phoneNumber) {
      const { phoneMetaData } = currentUser.attributes
      await this.setState({
        mobile: currentUser.attributes.phoneNumber.trim(),
        phone_country_code: phoneMetaData.dialCode,
        phone_meta_data: phoneMetaData
      })
      change('phoneNumber', currentUser.attributes.phoneNumber.trim())
      change('phoneMetaData', phoneMetaData)
      change('phoneCountryCode', phoneMetaData.dialCode)
      if (justRegister) {
        this.startCounter()
      }
    }
    // getUser();
    let count = 0
    const key = `mobile-otp-${currentUser.attributes.phoneCountryCode}${currentUser.attributes.phoneNumber}`
    const clickArray = this.getCookie(key).split(',')
    Object.keys(clickArray).forEach(k => {
      if (moment(clickArray[k]).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')) {
        count++
      }
    })

    this.setState({
      countResend: count,
      mobile: currentUser.attributes.phoneNumber.trim(),
      didMount: true
    })
  }

  handleCountryCode = e => {
    const { countryOptions, change } = this.props
    if (countryOptions !== undefined) {
      countryOptions.map(option => {
        if (option.alpha2 === e.value) {
          change('phoneCountryCode', option.country_code)
        }
      })
    }
  }

  handleCountryCodeChange = e => {
    const { change } = this.props
    change('phoneCountryCode', e.target.value)
  }

  setCookie = (cname, cvalue, exdays) => {
    const date = new Date()
    date.setTime(date.getTime() + Number(exdays) * 3600 * 1000)
    document.cookie = `${cname}=${cvalue}; path=/;expires = ${date.toGMTString()}`
  }

  verifyMobileOtpButton = async _e => {
    const { currentUser, nextPage, canSkip, verifyMobile } = this.props
    const { verifyOtp } = this.state 
    if (verifyOtp) {
      try {
        const response = await verifyMobile(
          currentUser,
          verifyOtp
        )
        if (!response.is_success) {
          this.setState({ otPerror: 'Incorrect OTP!' })
        } else {
          this.setState({
            veriFyEmailsent: false,
            show: false,
            confirmBox: false,
            verifyOtp: '',
            otPerror: ''
          })
          if (canSkip) {
            history.push('/become-a-host/step-1')
          } else {
            nextPage()
          }
          // alertModal('Great Job! Your mobile number +' + currentUser.attributes.phoneCountryCode + ' ' + currentUser.attributes.phoneNumber
          // + ' has been verified', 'success', 'Close');
        }
      } catch (error) {
        // console.log('error', error, error.message)
        this.setState({
          otPerror: 'There is some network issue please try again in some time!'
        })
      }
    } else {
      this.setState({ otPerror: 'Please enter OTP' })
    }
  }

  getCookie = cname => {
    const name = `${cname}=`
    const decodedCookie = decodeURIComponent(document.cookie)
    const ca = decodedCookie.split(';')
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i]
      while (c.charAt(0) === ' ') {
        c = c.substring(1)
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length)
      }
    }
    return ''
  }

  startCounter () {
    const { currentUser } = this.props
    const key = `mobile-otp-${currentUser.attributes.phoneCountryCode}${currentUser.attributes.phoneNumber}`
    const value = [moment().format('YYYY-MM-DD')]
    if (this.getCookie(key) == '') {
      this.setCookie(key, value, 4)
    }
    this.setState({ show: true, confirmBox: true })
    this.setState({
      verifyPhone: {...this.state.verifyPhone, show: true, resendCount: 1}
    })
    let myVar = setInterval(() => {
      this.setState({
        verifyPhone: {...this.state.verifyPhone, totalSeconds: this.state.verifyPhone.totalSeconds - 1}
      })
    }, 1000)
    setTimeout(() => {
      this.setState({
        verifyPhone: {...this.state.verifyPhone, resendCount: 0, totalSeconds: 30}
      })
      clearInterval(myVar)
    }, 30 * 1000)
  }

  setCookieCount = async event => {
    const { currentUser, sendVerifyMobileLink } = this.props
    const { verifyPhone, mobile, phone_country_code, phone_meta_data } = this.state
    event.preventDefault()
    if (verifyPhone.resendCount===0) {
      const response = await sendVerifyMobileLink(
        currentUser.attributes.id,
        mobile.trim(),
        phone_country_code,
        phone_meta_data,
        currentUser
      )
      this.setState({ show: true, confirmBox: true })
      this.setState({ verifyPhone: { ...verifyPhone, show: true, resendCount: 1 } })
      let myVar = setInterval(() => {
        this.setState({ verifyPhone: { ...verifyPhone, totalSeconds: verifyPhone.totalSeconds - 1 } })
      }, 1000)
      setTimeout(() => {
        this.setState({ verifyPhone: { ...verifyPhone, resendCount: 0, totalSeconds: 30 } })
        clearInterval(myVar)
      }, 30 * 1000)
      const key = `mobile-otp-${currentUser.attributes.phoneCountryCode}${currentUser.attributes.phoneNumber}`
      const add_value = []
      if (this.getCookie(key) != '') {
        add_value.push(this.getCookie(key))
        add_value.push(moment().format('YYYY-MM-DD'))
      } else {
        add_value.push(moment().format('YYYY-MM-DD'))
      }
      this.setCookie(key, add_value, 4)
      let count = 0
      const clickArray = this.getCookie(key).split(',')
      Object.keys(clickArray).forEach(k => {
        if (
          moment(clickArray[k]).format('YYYY-MM-DD') ===
        moment().format('YYYY-MM-DD')
        ) {
          count++
        }
      })
      this.setState({ countResend: count })
    }
  }

  render() {
    const {
      handleSubmit,
      form,
      currentUser,
      sendVerifyMobileLink,
      change,
      dataError,
      justRegister,
      formActions,
      nextPage,
      canSkip
    } = this.props
    const { phoneNumber, show, confirmBox, otPerror, countResend } = this.state
    currentUser.attributes.city = currentUser.attributes.location && currentUser.attributes.location.city
    currentUser.attributes.countryCode = currentUser.attributes.location
      && currentUser.attributes.location.countryCode

    return (
      <form onSubmit={handleSubmit} id={form} className="">
        <div className="form-body form-body--full">
          <div className="grid grid--uniform">
            <div className="grid__item ">
              <div className="form-column grid__item large-up--full">
                <div className="grid ">
                  <div className="form-column grid__item medium--one-third large-up--one-third">
                    <label className="verify_mobile_lebel" htmlFor="verify_mobile_lebel">
                      {phoneNumber.label}
                    </label>
                  </div>
                  <div className="form-column grid__item medium--two-thirds large-up--two-thirds">
                    {this.state.didMount ? (
                      <IntlTelInput
                        containerClassName="intl-tel-input intl-tel-input fullWidth fullInputClass"
                        inputClassName="form-control"
                        defaultCountry={currentUser.attributes.phoneMetaData != null && currentUser.attributes.phoneMetaData.iso2 != null ? currentUser.attributes.phoneMetaData.iso2 : 'ae'}
                        onSelectFlag={(flag, metadata) => {
                          const { dialCode } = metadata
                          console.log({ flag: flag, dialCode: dialCode })
                          this.setState({
                            phone_country_code: dialCode,
                            phone_meta_data: metadata,
                            show: false,
                            confirmBox: false,
                            otPerror: ''
                          })
                          if (justRegister) {
                            formActions.updateForJustReg(false)
                          }
                          change('phoneMetaData', metadata)
                        }}
                        // onSelectFlag={(flag, metadata) => {
                        //   console.log({ flag: flag, metadata: metadata })
                        //   const { dialCode } = metadata
                        //   if (
                        //     phone && Number(phone.replace(/\D/g, '')) !== Number(currentUser.attributes.phoneNumber.replace(/\D/g, '')) ||
                        //     Number(dialCode) !== Number(currentUser.attributes.phoneCountryCode)
                        //   ) {
                        //     this.setState({
                        //       phone_country_code: dialCode,
                        //       phone_meta_data: metadata,
                        //       show: false,
                        //       confirmBox: false,
                        //       otPerror: ''
                        //     })
                        //     if (justRegister) {
                        //       formActions.updateForJustReg(false)
                        //     }
                        //     change('phoneMetaData', metadata)
                        //   }
                        // }}
                        onPhoneNumberChange={(flag, phone, metadata) => {
                          console.log({ flag: flag, phone: phone, metadata: metadata })
                          const { dialCode } = metadata
                          if (
                            (phone && Number(phone.replace(/\D/g, '')) !== Number(currentUser.attributes.phoneNumber.replace(/\D/g, ''))) ||
                            Number(dialCode) !== Number(currentUser.attributes.phoneCountryCode)
                          ) {
                            this.setState({
                              mobile: phone.trim(),
                              phone_country_code: dialCode,
                              phone_meta_data: metadata,
                              show: false,
                              confirmBox: false,
                              otPerror: ''
                            })
                            change('phoneNumber', phone.trim())
                            change('phoneMetaData', metadata)
                            change('phoneCountryCode', dialCode)
                            if (justRegister) {
                              formActions.updateForJustReg(false)
                            }
                          }
                        }}
                        defaultValue={currentUser.attributes.phoneNumber ? currentUser.attributes.phoneNumber : ''}
                      />
                    ) : (
                      ''
                    )}
                    {this.state.phoneError || dataError ? (
                      <p>
                        <span className="form-error">
                          {this.state.phoneError ? this.state.phoneError : ''} {dataError && dataError.error_message ? dataError.error_message[0] : ''}
                        </span>
                      </p>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </div>
            </div>

            {!show ? (
              <div className="grid__item ">
                <div className="form-column grid__item medium--full large-up--full mtt_10">{otPerror ? <p className="note note--error">{this.state.otPerror}</p> : ''}</div>
                <p className="mtt_10">
                  <button
                    type="button"
                    href="#"
                    className="btn minWidth200"
                    onClick={async (e) => {
                      e.preventDefault()
                      if (!this.state.mobile) {
                        this.setState({
                          otPerror: 'Please enter mobile number!'
                        })
                      } else {
                        if (this.state.verifyPhone.resendCount === 0) {
                          const response = await this.props.sendVerifyMobileLink(
                            currentUser.attributes.id,
                            this.state.mobile.trim(),
                            this.state.phone_country_code,
                            this.state.phone_meta_data,
                            currentUser
                          )
                          if (response.is_success) {
                            /* const key = `mobile-otp-${currentUser.attributes.phoneCountryCode}${currentUser.attributes.phoneNumber}`
                            const value = [moment().format('YYYY-MM-DD')]
                            if (this.getCookie(key) == '') {
                              this.setCookie(key, value, 24)
                            }
                            this.setState({ show: true, confirmBox: true })
                            this.setState({
                              verifyPhone: {...this.state.verifyPhone, show: true, resendCount: 1}
                            })
                            let myVar = setInterval(() => {
                              this.setState({
                                verifyPhone: {...this.state.verifyPhone, totalSeconds: this.state.verifyPhone.totalSeconds - 1}
                              })
                            }, 1000)
                            setTimeout(() => {
                              this.setState({
                                verifyPhone: {...this.state.verifyPhone, resendCount: 0, totalSeconds: 30}
                              })
                              clearInterval(myVar)
                            }, 30 * 1000) */
                            this.startCounter()
                          }
                          // this.setState({ show: true, confirmBox: true })
                        }
                      }
                    }}
                  >
                    {' '}
                    Send OTP{this.state.verifyPhone.resendCount > 0 ? `, try after ${this.state.verifyPhone.totalSeconds} secs` : ''}
                  </button>
                </p>
              </div>
            ) : null}

            {!confirmBox ? null : (
              <React.Fragment>
                <div className="grid__item mtt_10">
                  <div className="form-column grid__item medium--one-third large-up--one-third">
                    <label htmlFor="verifyOtp" className="verify_mobile_lebel">
                      Enter OTP
                    </label>
                  </div>
                  <div className="form-column grid__item medium--two-thirds large-up--two-thirds">
                    <input
                      placeholder="Enter OTP"
                      name="verifyOtp"
                      type="text"
                      className="fullWidthInput "
                      onChange={(e) => {
                        this.setState({
                          verifyOtp: e.target.value,
                          otPerror: ''
                        })
                      }}
                    />
                  </div>
                </div>
                <div className="grid_item ">
                  <div className="form-column grid__item medium--full large-up--full mtt_10">
                    {otPerror ? <p className="note note--error">{this.state.otPerror}</p> : ''}

                    {countResend < 5 ? (
                      <p>
                        Haven’t received OTP ?{' '}
                        <a
                          href="#"
                          style={{ textDecoration: 'underline' }}
                          onClick={async (e) => {
                            this.setCookieCount(e)
                          }}
                        >
                          {' '}
                          Resend OTP{this.state.verifyPhone.resendCount > 0 ? `, try after ${this.state.verifyPhone.totalSeconds} secs` : ''}
                        </a>
                      </p>
                    ) : (
                      <p className="note note--error"> You have exceeded the maximum number of pin attempts. Please wait for 4 hours to request to resend new OTP.</p>
                    )}
                  </div>
                  <div className="form-column grid__item medium--full large-up--full">
                    <button type="button" className="btn minWidth200" onClick={(e) => this.verifyMobileOtpButton(e)}>
                      Verify
                    </button>
                  </div>
                </div>
              </React.Fragment>
            )}
            <button
              type="button"
              className="btn btn--text btn--link"
              onClick={() => {
                if (canSkip) {
                  history.push('/become-a-host/step-1')
                } else {
                  nextPage()
                }
              }}
            >
              SKIP VERIFICATION
            </button>
          </div>
        </div>
      </form>
    )
  }
}

VerifyMobile.defaultProps = {
  disableFields: true
}

VerifyMobile.propTypes = {
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  valid: PropTypes.bool.isRequired,
  previousPage: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  currentUser: currentUserPropTypes.isRequired,
  disableFields: PropTypes.bool,
  justRegister: PropTypes.bool
}

const mapStateToProps = state => ({
  // forgotPassword: state.forgotPassword,
  showPopup: state.forgotPassword.showPopup,
  dataError: state.user.profile && state.user.profile.errorData ? state.user.profile.errorData : null
})

const mapDispatchToProps = dispatch => ({
  sendVerifyEmailLink: state => dispatch(sendVerifyEmailLink(state)),
  sendVerifyMobileLink: (id, phone, phoneCountryCode, phoneMetaData, currentUser) => dispatch(
      sendVerifyMobileLink(id, phone, phoneCountryCode, phoneMetaData, currentUser)
    ),
  verifyMobile: (id, otp) => dispatch(verifyMobile(id, otp))
})

export default connect(mapStateToProps, mapDispatchToProps)(VerifyMobile)
// export default AccountDetails;
