import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import scriptLoader from 'react-async-script-loader'

/* Redux Form */
import { Field } from 'redux-form'

import alertModal from '../../../utils/alert'

/* Action Creators */
import * as userActionCreators from '../../../actions/user'
import * as paymentMethodActionCreators from '../../../actions/paymentMethods'
import * as frameScriptActionCreators from '../../../actions/frameScript'

/* Constants */
import {
  PaymentMethodStatus,
  TokenFormStatus,
  FramesScriptLoadStatus
} from '../../../constants/statuses'

/* Form Styles */
import checkoutInputStyles from '../../../constants/checkoutInputStyles'

/* Centralized Proptypes */
import { currentUserPropTypes } from '../../../constants/composed-proptypes'

/* Icons */
import CreditCardIconGroup from '../../../icons/creditCards/CreditCardIconGroup'
import LockIcon from '../../../icons/global/SVGJS/IconLock'
// const { Frames } = global;
import { history } from '../../../configureStore'

class GetPaymentToken extends Component {
  constructor(props) {
    super(props)

    const { paymentMethodActions, frameScriptActions } = this.props

    /* Helpers */
    this.paymentMethodActions = paymentMethodActions
    this.frameScriptActions = frameScriptActions
    this.setframeScriptStatus = frameScriptActions.setframeScriptStatus

    this.getToken = this.getToken.bind(this)
    this.initCheckoutScript = this.initCheckoutScript.bind(this)
    this.Frames = {}
  }

  componentWillMount() {
    // const { isScriptLoaded, isScriptLoadSucceed } = this.props
    this.setframeScriptStatus(FramesScriptLoadStatus.STARTED)
  }

  componentDidMount() {
    // this.initThirdPartyScripts();
  }

  componentWillReceiveProps({ isScriptLoaded, isScriptLoadSucceed }) {
    const { isScriptLoaded: propIsScriptLoaded } = this.props
    if (isScriptLoaded && !propIsScriptLoaded) {
      // load finished
      if (isScriptLoadSucceed) {
        this.setframeScriptStatus(FramesScriptLoadStatus.DONE)
        this.initThirdPartyScripts()
      } else this.setframeScriptStatus(FramesScriptLoadStatus.ERROR)
    }
  }

  componentDidUpdate(prevProps) {
    const {
      tokenStatus,
      latestToken,
      handleSubmit,
      scriptLoadStatus
    } = this.props

    /* If the latest token and the token status has been recieved
    then use redux-form handleSubmit method. */
    if (
      latestToken !== prevProps.latestToken &&
      tokenStatus === TokenFormStatus.RECIEVED
    ) {
      /* We make sure this is generic so its bubbles up with redux-form */
      handleSubmit() // redux-form method
    }

    if (
      scriptLoadStatus !== prevProps.scriptLoadStatus &&
      scriptLoadStatus === FramesScriptLoadStatus.ERROR
    ) {
      alertModal(
        'There was an errror loading the credit card form. Please check your network connection.',
        'error',
        'Reload'
      ).then(result => {
        if (result.value) {
          if (typeof window !== 'undefined') {
            history.go(0)
          }
        }
      })
    }
  }

  getToken = event => {
    const { Frames } = global
    // console.log("Get TOKEN---->", Frames)
    event.preventDefault()
    // Frames.submitCard()
    // Frames.unblockFields();
    return true
  };

  initThirdPartyScripts = () => {
    const { isScriptLoaded, isScriptLoadSucceed, currentUser } = this.props
    this.Frames = global.Frames
    this.setframeScriptStatus(FramesScriptLoadStatus.DONE)
    if (currentUser.isSignedIn) {
      this.initCheckoutScript()
    }
  };

  handleCardToken(event) {
    const { change } = this.props
    const payNowButton = document.getElementById('pay-now-button')

    const { cardToken } = event.data
    this.paymentMethodActions.tokenRecieved()
    this.paymentMethodActions.setToken(cardToken)
    // We use this to add a the value to the hidden field
    change('paymentToken', cardToken)
    payNowButton.disabled = !Frames.isCardValid()
    // If all this is successfull let redux handle the rest.
  }

  initCheckoutScript() {
    const { Frames } = this
    const { currentUser, change } = this.props
    const paymentForm = document.getElementById('payment-form')
    const payNowButton = document.getElementById('pay-now-button')

    // const { Frames } = global;
    const self = this
    Frames.init({
      // publicKey: 'pk_test_e0f10c66-0928-49a9-bd06-4dac3ec0f272',
      publicKey: 'pk_test_51H56waKELvmBtznyE6LahXcDOAz264zFyYxKqNBpXGxxb7fOwLwOQEtlcrGno1SQYVLsPuDdAJHp0XQ35Lkwhlsy00BB9GHIyW',
      containerSelector: '.frames-container',
      style: checkoutInputStyles,
      cardValidationChanged() {
        // if all fields contain valid information, the Pay now
        // button will be enabled and the form can be submitted
        payNowButton.disabled = !Frames.isCardValid()
      },
      cardSubmitted(event) {
        // console.log("cardSubmitted--->", event)
        payNowButton.disabled = true
        self.paymentMethodActions.tokenFormSubmitted()
      },
      async cardTokenised(event) {},
      cardTokenisationFailed(event) {
        // console.log("cardTokenisationFailed--->", event)
        // catch the error
        self.paymentMethodActions.tokenFormFailed()
        /* eslint-disable-next-line no-console */
        // console.log('some error occurred when tokenizing the card', event);
        alertModal(
          'Something went wrong, please try again',
          'error',
          'Try Again'
        )
        self.paymentMethodActions.setToken(undefined)
      }
    })

    Frames.setCustomerName(currentUser.attributes.fullName)

    Frames.addEventHandler('frameActivated', () => {
      this.frameScriptActions.frameScriptInitialized()
    })

    Frames.addEventHandler('cardTokenised', event =>
      this.handleCardToken(event)
    )
  }

  render() {
    const {
      scriptLoadStatus,
      tokenStatus,
      paymentMethodStatus,
      latestToken
    } = this.props
    return (
      <form id="payment-form" onSubmit={e => this.getToken(e)}>
        <CreditCardIconGroup />
        <div className="frames-container">
          {tokenStatus === TokenFormStatus.SENT && <div className="spinner" />}

          {scriptLoadStatus !== FramesScriptLoadStatus.INITIALIZED && (
            <div className="spinner" />
          )}

          {paymentMethodStatus === PaymentMethodStatus.SENT && (
            <div className="spinner" />
          )}

          {/* <!-- form will be added here --> */}
        </div>

        <Field
          type="hidden"
          name="paymentToken"
          label=""
          value={latestToken}
          component="input"
        />

        {/* NOTE: USE FOR TESTING */}
        {/* {latestToken} */}
        <div
          className="secure-badge text-success text-center"
          style={{ marginTop: '1em' }}
        >
          <p className="text-success">
            <LockIcon /> Encrypted / SSL Secure
          </p>
        </div>
      </form>
    )
  }
}

GetPaymentToken.propTypes = {
  change: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  tokenStatus: PropTypes.string,
  scriptLoadStatus: PropTypes.string.isRequired,
  paymentMethodStatus: PropTypes.string.isRequired,
  latestToken: PropTypes.string,
  paymentMethodActions: PropTypes.shape({}).isRequired,
  frameScriptActions: PropTypes.shape({}).isRequired,
  currentUser: currentUserPropTypes.isRequired
}

const mapStateToProps = state => ({
  currentUser: state.reduxTokenAuth.currentUser,
  paymentMethods: state.paymentMethods,
  stepForm: state.stepForm.reservation,
  cardAdded: state.user.payment,
  paymentMethodStatus: state.paymentMethods.status,
  tokenStatus: state.paymentMethods.tokenStatus,
  latestToken: state.paymentMethods.latestToken,
  scriptLoadStatus: state.frameScript.scriptLoadStatus
})

const mapDispatchToProps = dispatch => ({
  userActions: bindActionCreators(userActionCreators, dispatch),
  frameScriptActions: bindActionCreators(frameScriptActionCreators, dispatch),
  paymentMethodActions: bindActionCreators(
    paymentMethodActionCreators,
    dispatch
  )
})

const GetPaymentTokenWithScript = scriptLoader([
  '//cdn.checkout.com/js/frames.js'
])(GetPaymentToken)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GetPaymentTokenWithScript)
