const validate = values => {
  const errors = {}
  if (!values.firstName) {
    errors.firstName = 'Required'
  }
  if (!values.lastName) {
    errors.lastName = 'Required'
  }
  if (!values.email) {
    errors.email = 'Required'
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Invalid email address'
  }
  if (!values.password) {
    errors.password = 'Required'
  }
  // if (!values.passwordConfirmation) {
  //   errors.passwordConfirmation = 'Required';
  // } else if (values.passwordConfirmation !== values.password) {
  //   errors.passwordConfirmation = 'Passwords need to match';
  // }
  if (!values.gender) {
    errors.gender = 'Required'
  }
  if (!values.languages) {
    errors.languages = 'Required'
  }
  if (values.location && !values.location.city) {
    errors.location = 'Required'
  }

  if (!values.location) {
    errors.location = 'Required'
  }
  // if (!values.location.countryCode) {
  //   errors.location.countryCode = 'Required';
  // }

  if (!values.birthday) {
    errors.birthday = 'Required'
  }
  return errors
}

export default validate
