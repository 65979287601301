import React, { Component } from 'react'
import { Modal, Button } from 'react-bootstrap'
import CloseButton from 'react-bootstrap'
import ImageWithOverlaySquare from '../../Shared/Web/ImageWithOverlaySquare'
import RegLink from '../../Registration/Users/RegLink'
import newsletterSignup from '../../../actions/newsletter'
import '../../../../src/scss/breakbread/components/subscription_modal.scss'
import '../../../../src/scss/breakbread/custom.module.scss'
import { GrClose } from 'react-icons/gr'

const setCookie = (name, value, days) => {
  var expires = ""
  if (days) {
    var date = new Date()
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000))
    expires = "; expires=" + date.toUTCString()
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/"
}
const getCookie = (name) => {
  var nameEQ = name + "="
  var ca = document.cookie.split(';')
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i]
    while (c.charAt(0) == ' ') c = c.substring(1, c.length)
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length)
  }
  return null
}

class SubscriptionModal extends Component {
  constructor(props) {
    super()
    this.state = {
      show: false,
      email: ''
    }
  }

  componentDidMount(){
    // this.handleShow()
  }

  handleClose = () => {
    let getNSCookie = getCookie('getSubscribedNS')
    if (!getNSCookie) {
      setCookie('getNSExpiry', 'Newsletter is not subscribed', 30)
    }
    this.setState({ show: false })
  }

  handleShow = () => {
    let getNSCookie = getCookie('getSubscribedNS')
    let getNSExpiryCookie = getCookie('getNSExpiry')

    if ((!getNSExpiryCookie && getNSCookie) || (getNSExpiryCookie === 'Newsletter is not subscribed' && !getNSCookie)) {
      this.setState({ show: false })
    } else {
      setTimeout(() => { this.setState({ show: true }) }, 10000)
    }
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  signUp = () => {
    // const { apiSignUp } = this.props;
    this.setNSCookie()
    newsletterSignup(this.state.email)
  }

  setNSCookie = () => {
    setCookie('getSubscribedNS', true, '')
    this.setState({ show: false })
  }

  render() {
    const isMobile = window.matchMedia('(max-width: 1005px)').matches
    const isMobileLocal = window.matchMedia('(max-width: 480px)').matches
    const themeForHero = isMobile ? null : 'light'
    const { isLoading, email } = this.state
    return (
      <div>
        <Modal bsSize={'large'} backdrop="static" show={this.state.show} onHide={this.handleClose} className="main_modal custom_modal_content" id="subsModal">
          <Modal.Body className="sub_modal_body">
            <button className="close_button" onClick={this.handleClose}>
              <GrClose />
            </button>
            <div className="grid grid--no-gutters grid--uniform">
              <div className="grid__item small--one-whole medium-up--one-half p-0">
                <ImageWithOverlaySquare image={this.props.image1} theme={themeForHero === 'light' ? null : themeForHero}>
                  
                  <h2>{this.props.title}</h2>
                  <p className="text-20">{this.props.description}</p>
                  <RegLink className="btn">
                    <span>{this.props.signBtn}</span>
                  </RegLink>
                </ImageWithOverlaySquare>
              </div>
              <div className="grid__item small--one-whole medium-up--one-half">
                <ImageWithOverlaySquare theme="color">
                  <h2>{this.props.title2}</h2>
                  <p className="text-20 p-0">{this.props.description2}</p>
                  <div className="breakbread-newsletter-form">
                    <div className="breakbread-newsletter-form-inner">
                      <div className="input-group">
                        <input
                          type="email"
                          name="email"
                          id="Email"
                          value={email}
                          onChange={(e) => this.handleChange(e)}
                          className="input-group__field newsletter__input"
                          placeholder="Email address"
                          autoCorrect="off"
                          autoCapitalize="off"
                        />
                        <span className="input-group__btn">
                          <button type="submit" onClick={this.signUp} className="btn btn--darker newsletter__submit" name="commit" id="Subscribe">
                            <span className="newsletter__submit-text--large">{this.props.subsBtn}</span>
                          </button>
                        </span>
                      </div>
                    </div>
                  </div>
                </ImageWithOverlaySquare>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    )
  }
}
export default SubscriptionModal
