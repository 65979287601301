import * as React from "react";

const Email = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-label="Email"
    width="25"
    height="25"
    viewBox="0 0 512 512"
    {...props}
  >
    <rect width={512} height={512} rx="15%" fill="gray" />
    <rect width={356} height={256} x={78} y={128} fill="#fff" rx="8%" />
    <path
      fill="none"
      stroke="gray"
      strokeWidth={20}
      d="M434 128 269 292c-7 8-19 8-26 0L78 128m0 256 129-128m227 128L305 256"
    />
  </svg>
);

export default Email;
