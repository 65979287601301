import React from "react"

const Organic = props => (
  <svg viewBox="0 0 88 88" width="1em" height="1em" {...props}>
    <g fill="currentColor" data-name="Layer 2">
      <path d="M54 47.47c0-6.74-8.82-18.59-9.2-19.09a1 1 0 0 0-1.6 0c-.38.5-9.2 12.35-9.2 19.09 0 4.3 1.78 6.77 3.28 8.09A10 10 0 0 0 43 58v1a1 1 0 0 0 2 0v-1c5.66-.45 9-4.3 9-10.53zM45 56v-4.39l5.45-2.72a1 1 0 0 0 .44-1.35 1 1 0 0 0-1.34-.44L45 49.37v-3.76l3.45-1.73a1 1 0 0 0-.9-1.79L45 43.37V37a1 1 0 1 0-2 0v6.39l-2.55-1.28a1 1 0 0 0-.9 1.79L43 45.61v3.76l-4.55-2.27a1 1 0 0 0-1.34.44 1 1 0 0 0 .44 1.35L43 51.61v4.32a8.08 8.08 0 0 1-4.4-1.87 8.39 8.39 0 0 1-2.6-6.59c0-5 5.83-13.7 8-16.78 2.17 3.08 8 11.81 8 16.78 0 6.64-4 8.22-7 8.53z" />
      <path d="M44 88a44 44 0 1 1 44-44 44.05 44.05 0 0 1-44 44zm0-86a42 42 0 1 0 42 42A42 42 0 0 0 44 2z" />
    </g>
  </svg>
)

export default Organic
