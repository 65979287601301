import { reducer as formReducer } from 'redux-form'

import {
  SHOW_MODAL,
  HIDE_MODAL,
  BECOME_HOST,
  REG,
  SET_MENU,
  HOST_CONFIRMATION,
  USER_CONFIRMATION,
  AUTH_TOKEN_FAILED,
  AUTH_SIGNOUT_SUCCESS,
  EXPERIENCE
} from '../constants/actionTypes'

/**
 * Reducer which undefines form data when modal is closed.
 * @see https://redux-form.com/6.0.0-alpha.4/docs/faq/howtoclear.md/
 *
 */
export default formReducer.plugin({
  hostRegistration: (state, action) => {
    // <------ 'hostRegistration' is name of form given to reduxForm()
    switch (action.type) {
    /* NOTE: We are are also blowing away data when the USER / HOST
       * Confirmation is shown to avoid data leakage.
       */
    case SHOW_MODAL:
      switch (action.modalType) {
      case HOST_CONFIRMATION:
        return undefined // <--- blow away form data
      case USER_CONFIRMATION:
        return undefined // <--- blow away form data
      default:
        return state
      }
    case HIDE_MODAL:
      switch (action.modalType) {
      case BECOME_HOST:
        return undefined // <--- blow away form data
      default:
        return state
      }

    case AUTH_SIGNOUT_SUCCESS:
      return undefined

    case AUTH_TOKEN_FAILED:
      return undefined

    default:
      return state
    }
  },
  hostLocation: (state, action) => {
    // <------ 'hostRegistration' is name of form given to reduxForm()
    switch (action.type) {
    /* NOTE: We are are also blowing away data when the USER / HOST
       * Confirmation is shown to avoid data leakage.
       */
    case SHOW_MODAL:
      switch (action.modalType) {
      case HOST_CONFIRMATION:
        return undefined // <--- blow away form data
      case USER_CONFIRMATION:
        return undefined // <--- blow away form data
      default:
        return state
      }
    case HIDE_MODAL:
      switch (action.modalType) {
      case BECOME_HOST:
        return undefined // <--- blow away form data
      default:
        return state
      }

    case AUTH_SIGNOUT_SUCCESS:
      return undefined

    case AUTH_TOKEN_FAILED:
      return undefined

    default:
      return state
    }
  },
  registration: (state, action) => {
    // <------ 'registration' is name of form given to reduxForm()
    switch (action.type) {
    /* NOTE: We are are also blowing away data when the USER / HOST
       * Confirmation is shown to avoid data leakage.
       */
    case SHOW_MODAL:
      switch (action.modalType) {
      case HOST_CONFIRMATION:
        return undefined // <--- blow away form data
      case USER_CONFIRMATION:
        return undefined // <--- blow away form data
      default:
        return state
      }
    case HIDE_MODAL:
      switch (action.modalType) {
      case REG:
        return undefined // <--- blow away form data
      default:
        return state
      }

    case AUTH_SIGNOUT_SUCCESS:
      return undefined

    case AUTH_TOKEN_FAILED:
      return undefined

    default:
      return state
    }
  },
  experience: (state, action) => {
    // <------ 'registration' is name of form given to reduxForm()
    switch (action.type) {
    /* NOTE: We are are also blowing away data when the USER / HOST
       * Confirmation is shown to avoid data leakage.
       */
    case SHOW_MODAL:
      switch (action.modalType) {
      case EXPERIENCE:
        return undefined // <--- blow away form data
      case BECOME_HOST:
        return undefined // <--- blow away form data
      case USER_CONFIRMATION:
        return undefined // <--- blow away form data
      default:
        return state
      }
    case HIDE_MODAL:
      switch (action.modalType) {
      case EXPERIENCE:
        return undefined // <--- blow away form data
      default:
        return state
      }

    case AUTH_SIGNOUT_SUCCESS:
      return undefined

    case AUTH_TOKEN_FAILED:
      return undefined

    default:
      return state
    }
  },
  experienceEdit: (state, action) => {
    // <------ 'registration' is name of form given to reduxForm()
    switch (action.type) {
    /* NOTE: We are are also blowing away data when the USER / HOST
       * Confirmation is shown to avoid data leakage.
       */
    case SHOW_MODAL:
      switch (action.modalType) {
      case EXPERIENCE:
        return undefined // <--- blow away form data
      case BECOME_HOST:
        return undefined // <--- blow away form data
      case USER_CONFIRMATION:
        return undefined // <--- blow away form data
      default:
        return state
      }
    case HIDE_MODAL:
      switch (action.modalType) {
      case EXPERIENCE:
        return undefined // <--- blow away form data
      default:
        return state
      }

    case AUTH_SIGNOUT_SUCCESS:
      return undefined

    case AUTH_TOKEN_FAILED:
      return undefined

    default:
      return state
    }
  },
  experienceCreate: (state, action) => {
    // <------ 'registration' is name of form given to reduxForm()
    switch (action.type) {
    /* NOTE: We are are also blowing away data when the USER / HOST
       * Confirmation is shown to avoid data leakage.
       */
    case SHOW_MODAL:
      switch (action.modalType) {
      case EXPERIENCE:
        return undefined // <--- blow away form data
      case BECOME_HOST:
        return undefined // <--- blow away form data
      case USER_CONFIRMATION:
        return undefined // <--- blow away form data
      default:
        return state
      }
    case HIDE_MODAL:
      switch (action.modalType) {
      case EXPERIENCE:
        return undefined // <--- blow away form data
      default:
        return state
      }
    case SET_MENU:
      return {
        ...state,
        values: {
          ...state.values,
          cuisineTypesAttributes:[...action.menu.cuisineTypesAttributes],
          menuAttributes: { 
            menuItemsAttributes: [...action.menu.menuItems.map(el=>({ course:el.course, title: el.title, description: el.description, menu_image_urls: el.menu_image_urls, sample_menu_image_urls: el.menu_image_urls }))],
            mealCategories: [...action.menu.mealCategories],
            menuAllergensAttributes: [...action.menu.menuAllergens]
          }
        }
      }
    case AUTH_SIGNOUT_SUCCESS:
      return undefined

    case AUTH_TOKEN_FAILED:
      return undefined

    default:
      return state
    }
  }
})
