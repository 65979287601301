import React, { Component } from 'react'
import Select from 'react-select'
import { bindActionCreators } from 'redux'
import { updateUserProfessionalDetails } from '../../actions/user/update'
import * as userActionCreators from '../../actions/user'
import { connect } from 'react-redux'
import { professionalFieldCollection } from '../../constants/formFields'
import SimpleReactValidator from 'simple-react-validator'
import seo_meta from '../../utils/seo-meta.json'
import _ from 'lodash'
import SEO from '../Shared/Web/SEO'
class ProfessionalDetails extends Component {

  constructor(props) {
    super(props)
    this.state = {
      professionalFields: professionalFieldCollection,
      selectedOption: [],
      specialties: [],
      culinaryJourney: "",
      socialLinks: {
        facebook: "",
        instagram: "",
        twitter: ""
      },
      acceptTerms: false
    }
    this.validator = new SimpleReactValidator()
  }

  componentDidMount = async () => {
    await this.props.userActions.getCuisineTypesList()
    let { specialties, culinaryJourney, socialLinks, acceptTerms } = this.props.currentUser.attributes
    // let { specialties, culinaryJourney, socialLinks } = professionalFields;
    let selectedOption = this.state.professionalFields.specialties.options.filter((data) => {
      let specialtiesOptions = specialties
      let match = false
      for (let key in specialtiesOptions) {
        if (specialtiesOptions[key].name == data.value) {
          match = true
          return data
        }

      }
    })
    
    if (socialLinks == null) {
      socialLinks = {
        facebook: "",
        instagram: "",
        twitter: ""
      }
    }
    this.setState({ specialties, culinaryJourney, socialLinks, selectedOption, acceptTerms })

  }

  setFieldValue = (value, field) => {
    if (field === 'culinaryJourney') {
      this.setState({ [field]: this.limitText(1000, value) })
    } else {
      this.setState({ [field]: value })
    }
  }

  setSocialFieldValue = (value, field) => {
    let socialLinks = this.state.socialLinks
    socialLinks[field] = value
    this.setState({ socialLinks })
  }

  handleSubmit = () => {

    if (this.validator.allValid()) {
      let { selectedOption, culinaryJourney, socialLinks, acceptTerms } = this.state
      this.props.updateUserProfessionalDetails({ specialties: selectedOption, culinaryJourney, socialLinks, accept_terms: acceptTerms })
    } else {
      this.validator.showMessages()
      // rerender to show messages for the first time
      // you can use the autoForceUpdate option to do this automatically`
      this.forceUpdate()
    }
  }

  handleChange = (value) => {
    if (value != null) {
      let specialties = this.state.specialties
      this.setState({ selectedOption: value })
      // this.setFieldValue(specialties.push(value), "selectedOption");

    } else {
      this.setFieldValue([], "selectedOption")
    }

    // this.setState({ selectedCountry: value });
  }

  limitText = (limit, value) => {
    if(value.length < 0) {
      return value
    } else if(value.length > limit) {
      return value.slice(0, (limit-1))
    } else {
      return value
    }
  }

  render() {
    const { professionalFields, acceptTerms } = this.state
    let { specialties, culinaryJourney, socialLinks } = professionalFields
    const localCuisineTypes = _.sortBy(this.props.cuisineTypesOptions?this.props.cuisineTypesOptions.filter(el=>el.status === 'active').map(el=>({label: el.name, value: el.id, id: el.id})):[], 'label')
    return (
      <div className="breakbread-container">
        <SEO title="Professional Details | Breakbread" description="Professional Details | Breakbread" />
        <form className="LeftLableForm  bb-generic-form">
          <div className="page-header text-center">
            <h1>Professional Details</h1>
          </div>

          <div className="form-body">
            <div className="grid grid--uniform">
              {/* Cuisine / Specialties */}
              <div className="grid__item small--one-whole medium-up--full">
                <label htmlFor="specialties" className="">
                  Cuisine / Specialties *
                </label>
                <Select
                  value={this.state.selectedOption}
                  onChange={value => {
                    this.handleChange(value)
                  }}
                  name="specialties"
                  options={localCuisineTypes}
                  isClearable
                  isMulti={true}
                  className="react-select"
                  classNamePrefix="select"
                />
                {this.validator.message('specialties', this.state.selectedOption, 'required|array', { messages: { default: 'Please select Cuisine / Specialties.' }, className: 'form-error' })}
              </div>
              {/* Personal Info Fields */}
              <div className="grid__item small--one-whole medium-up--full">
                <label> Culinary Journey *</label>
                <textarea
                  className="fullWidthInput"
                  name="culinaryJourney"
                  value={this.state.culinaryJourney}
                  onChange={e => {
                    this.setFieldValue(e.target.value, 'culinaryJourney')
                  }}
                  placeholder="Give us a brief background of your culinary journey and past experience"
                />
                <span className="limittextculinary">
                  <span className="field_limit_text__limit">{ 1000 - (this.state.culinaryJourney?this.state.culinaryJourney.length:0) } characters remaining</span>
                </span>
                {this.validator.message('culinaryJourney', this.state.culinaryJourney, 'required|max:1000', {
                  messages: { default: 'Give us a brief background of your culinary journey and past experience and it should be have than 1000 character!' },
                  className: 'form-error'
                })}
              </div>
              <div className="grid__item small--one-whole medium-up--full">
                <fieldset className="flat">
                  <legend>Social Networks *</legend>
                  {this.validator.message('socialLinks', this.state.socialLinks.facebook || this.state.socialLinks.instagram || this.state.socialLinks.twitter, 'required|url', {
                    messages: { default: 'Please enter atleast one valid social' },
                    className: 'form-error'
                  })}

                  <label htmlFor="socialLinks.facebook" className="label-hidden ">
                    https://www.facebook.com/
                  </label>

                  <div className="input-group">
                    <div className="input-group-addon">
                      <i className="icon icon--facebook"></i>
                    </div>
                    <input
                      placeholder="https://www.facebook.com/"
                      name="socialLinks.facebook"
                      id="socialLinks.facebook"
                      type="text"
                      onChange={e => {
                        this.setSocialFieldValue(e.target.value, 'facebook')
                      }}
                      value={this.state.socialLinks.facebook || ''}
                    />
                  </div>
                  {this.validator.message('socialLinks.facebook', this.state.socialLinks.facebook, 'url', {
                    messages: { default: 'Please enter valid url' },
                    className: 'form-error'
                  })}
                  <label htmlFor="socialLinks.instagram" className="label-hidden ">
                    https://www.instagram.com/
                  </label>
                  <div className="input-group">
                    <div className="input-group-addon">
                      <i className="icon icon--instagram"></i>
                    </div>
                    <input
                      placeholder="https://www.instagram.com/"
                      name="socialLinks.instagram"
                      id="socialLinks.instagram"
                      type="text"
                      onChange={e => {
                        this.setSocialFieldValue(e.target.value, 'instagram')
                      }}
                      value={this.state.socialLinks.instagram || ''}
                    />
                  </div>
                  {this.validator.message('socialLinks.instagram', this.state.socialLinks.instagram, 'url', {
                    messages: { default: 'Please enter valid url' },
                    className: 'form-error'
                  })}
                  <label htmlFor="socialLinks.twitter" className="label-hidden ">
                    https://www.twitter.com/
                  </label>
                  <div className="input-group">
                    <div className="input-group-addon">
                      <i className="icon icon--twitter"></i>
                    </div>
                    <input
                      placeholder="https://www.twitter.com/"
                      name="socialLinks.twitter"
                      id="socialLinks.twitter"
                      type="text"
                      onChange={e => {
                        this.setSocialFieldValue(e.target.value, 'twitter')
                      }}
                      value={this.state.socialLinks.twitter || ''}
                    />
                  </div>
                  {this.validator.message('socialLinks.twitter', this.state.socialLinks.twitter, 'url', {
                    messages: { default: 'Please enter valid url' },
                    className: 'form-error'
                  })}
                </fieldset>
              </div>
              <div className="grid__item small--one-whole medium-up--full">
                <input type="checkbox" checked={acceptTerms} name="accept_terms" id="agreeTermsAndConditions" /* defaultValue={accept_terms} */ className="form-check-input" onChange={e => {
                  this.setState({acceptTerms: !acceptTerms })
                }}/>
                <label htmlFor="agreeTermsAndConditions" className="inline" style={{ paddingLeft: '10px' }}>
                  <small>
                    &nbsp; I agree to BreakBread&apos;s
                    <a href="/terms-and-conditions" target="_blank">
                      &nbsp; terms and conditions
                    </a>
                  </small>
                </label>
              </div>
            </div>
          </div>

          <div className="form-footer with-separator" style={{ marginTop: '80px' }}>
            <button type="button" onClick={this.handleSubmit} disabled={!acceptTerms} className="btn btn--darker form-footer--center minWidth200">
              {' '}
              Save{' '}
            </button>
          </div>
        </form>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  currentUser: state.reduxTokenAuth.currentUser,
  formData: state.form,
  cuisineTypesOptions: state.user.amenitiesAndWowElements.cuisineTypes
    ? state.user.amenitiesAndWowElements.cuisineTypes.list
    : null
})

const mapDispatchToProps = dispatch => ({
  updateUserProfessionalDetails: state => dispatch(updateUserProfessionalDetails(state)),
  userActions: bindActionCreators(userActionCreators, dispatch)
})
export default connect(mapStateToProps, mapDispatchToProps)(ProfessionalDetails)
