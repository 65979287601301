/* eslint-disable max-classes-per-file */
// Libraries
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import {
  // Elements,
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement
  // StripeProvider,
} from 'react-stripe-elements'
import PropTypes from 'prop-types'

// Internal Utils
// import alertModal from '../../utils/alert'
import createStripeStyles from '../../utils/createStripeStyles'
import CreditCardIconGroup from '../../icons/creditCards/CreditCardIconGroup'

import * as paymentActionCreators from '../../actions/transactions/payments'
import * as reservationActionCreators from '../../actions/reservation'

// Assets
// import CreditCardIconGroup from '../../icons/creditCards/CreditCardIconGroup'

// Constants
// const STRIPE_API_KEY = 'pk_test_SFffLB6N7dioAI2uK7f85SUk00XlFVBdlm'

const handleBlur = () => {
  // console.log('[blur]');
}

// const handleClick = () => {
//   // console.log('[click]');
// }
const handleFocus = () => {
  // console.log('[focus]');
}
const handleReady = () => {
  // console.log('[ready]');
}

// export default Setupnewcard

export class SetupNewCard extends Component {
  constructor() {
    super()
    this.state = {
      saveCard: true
    }
  }

  componentDidMount() {
    const { paymentActions, reservationActions } = this.props
    const { saveCard } = this.state
    paymentActions.createSetupIntent()
    reservationActions.setIsSavedCard(saveCard)
  }

  render() {
    const { fontSize, reservationActions, errors, handleError } = this.props
    const { saveCard } = this.state
    // const { email, id } = currentUser

    const handleSaveCard = async () => {
      await this.setState({ saveCard: !saveCard })
      await reservationActions.setIsSavedCard(!saveCard)
    }

    return (
      <div className="Checkout">
        <div className="form-header">
          <CreditCardIconGroup />
        </div>
        <span>
          <label>Card number</label>
          <CardNumberElement onBlur={handleBlur} onChange={handleError} onFocus={handleFocus} onReady={handleReady} style={createStripeStyles(fontSize)} />
        </span>

        <span>
          <label>Expiration date</label>
          <CardExpiryElement onBlur={handleBlur} onChange={handleError} onFocus={handleFocus} onReady={handleReady} style={createStripeStyles(fontSize)} />
        </span>

        <span>
          <label>CVC</label>
          <CardCVCElement onBlur={handleBlur} onChange={handleError} onFocus={handleFocus} onReady={handleReady} style={createStripeStyles(fontSize)} />
        </span>

        <div className="Checkout__checkbox">
          <input type="checkbox" onClick={handleSaveCard} checked={saveCard} onChange={handleSaveCard} id="Checkout__checkbox__input" className="form-check-input" />
          <label htmlFor="Checkout__checkbox__input" className="inline Checkout__checkbox__label">
            <small>
              I acknowledge this card will be used as a default for all my future transactions on BreakBread.
            </small>
          </label>
        </div>
        {Object.keys(errors).map((error) => <p key={error} id="card-errors">{errors[error]}</p>)}
      </div>
    )
  }
}

SetupNewCard.defaultProps = {
  fontSize: '14px'
  // paymentMethod: null,
  // cancel: () => null
}

SetupNewCard.propTypes = {
  fontSize: PropTypes.string,
  currentUser: PropTypes.shape({
    email: PropTypes.string,
    id: PropTypes.number
  }).isRequired,
  paymentActions: PropTypes.shape({
    createSetupIntent: PropTypes.func.isRequired
  }).isRequired,
  reservationActions: PropTypes.shape({
    setIsSavedCard: PropTypes.func.isRequired
  }).isRequired,
  handleError: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  currentUser: state.reduxTokenAuth.currentUser
})

const mapDispatchToProps = dispatch => ({
  paymentActions: bindActionCreators(paymentActionCreators, dispatch),
  reservationActions: bindActionCreators(reservationActionCreators, dispatch)
})

const connectedSetupNewCard = connect(
  mapStateToProps,
  mapDispatchToProps
)(SetupNewCard)

export default connectedSetupNewCard
