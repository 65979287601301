import React from "react"

const Celery = props => (
  <svg viewBox="0 0 88 88" width="1em" height="1em" {...props}>
    <g fill="currentColor" data-name="Layer 2">
      <path d="M48.85 30.06A3 3 0 0 0 46 28a3 3 0 0 0-2 .77 3 3 0 0 0-2-.77 3 3 0 0 0-2.85 2.06C36.8 30.38 35 32 35 34a1 1 0 0 0 1 1c1.24 0 2 1.75 2 3v16a6 6 0 0 0 12 0V38c0-1.25.76-3 2-3a1 1 0 0 0 1-1c0-2-1.8-3.62-4.15-3.94zM48 38v14.5a1.5 1.5 0 0 1-3 0V38a2.43 2.43 0 0 1 1.45-2.11 1 1 0 1 0-.9-1.78A4.83 4.83 0 0 0 44 35.33a4.83 4.83 0 0 0-1.55-1.22 1 1 0 1 0-.9 1.78A2.43 2.43 0 0 1 43 38v14.5a3.5 3.5 0 0 0 3.5 3.5 3.29 3.29 0 0 0 1-.18A4 4 0 0 1 40 54V38a5.14 5.14 0 0 0-2.76-4.77A3.33 3.33 0 0 1 40 32a1 1 0 0 0 1-1 1 1 0 0 1 2 0 1 1 0 0 0 2 0 1 1 0 0 1 2 0 1 1 0 0 0 1 1 3.33 3.33 0 0 1 2.76 1.23A5.14 5.14 0 0 0 48 38z" />
      <path d="M44 88a44 44 0 1 1 44-44 44.05 44.05 0 0 1-44 44zm0-86a42 42 0 1 0 42 42A42 42 0 0 0 44 2z" />
    </g>
  </svg>
)

export default Celery
