import React, { Component } from 'react'
import { Field } from 'redux-form'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import StarRatingComponent from 'react-star-rating-component'
import StarRatingField from '../Fields/renderStarRatingField'

/* Custom Components */
import UserCard from '../../User/UserCard'
import ExperienceCard from '../../Experiences/ExperienceCard'

/* Fields */
import RenderField from '../Fields/renderField'

/* Centralized PropTypes */
import { currentUserPropTypes } from '../../../constants/composed-proptypes'
import ChefAvatarHost from '../../Chef/ChefAvtarHost'
import ChefAvatarDiner from '../../Chef/ChefAvtarDiner'

const reviewField = {
  name: 'comment',
  type: 'textarea',
  autoComplete: '',
  placeholder:
    'Please leave a detailed review of what you would like to share about this diner and it should have max 1000 character.'
}

class ReviewForm extends Component {
  constructor(props) {
    super(props)
  }

  componentWillMount() {}

  disableFields = fieldCollection => {
    const { currentUser } = this.props
    if (typeof currentUser === 'undefined' || !currentUser.isSignedIn) {
      return fieldCollection
    }
    const obj = fieldCollection
    const { attributes } = currentUser
    Object.keys(obj).map(key => {
      if (attributes[key] !== null) {
        // eslint-disable-next-line no-undef
        obj[key].disabled = !!keysMatch(key, attributes)
      }
      return obj[key]
    })
    return obj
  }

  render() {
    const {
      handleSubmit,
      pristine,
      submitting,
      nextPage,
      form,
      currentPage,
      reservationCount,
      guest,
      experience,
      reviewedByHost,
      reviewedByGuest,
      hostReview,
      guestReview,
      reservation,
      exit
    } = this.props
    
    let isReview = Object.keys(hostReview).length === 0 ? false : true
   
    return (
      <form onSubmit={handleSubmit} id={form} className="">
        <div className="text-center form-header">
          {!isReview ? (
            <React.Fragment>
              <h4>You are writing a review for:</h4>
              <h5>{guest.fullname}</h5>
              {/* <h5>Reviewing guest {currentPage}/{reservationCount}</h5> */}
            </React.Fragment>
          ) : (
            <React.Fragment>
              <h5>Thanks for reviewing the guest {guest.fullname}</h5>
            </React.Fragment>
          )}
        </div>
        <hr className="hr--clear" />
        <div className="grid grid--uniform">
          <div className="form-column grid__item medium-up--two-thirds">
            {/* <h5>Reviewing host:</h5> */}
            <div className="grid grid--uniform">
              <div className="grid__item medium-up--one-quarter text-center">
                <ChefAvatarDiner person={reservation.guest} />
                <p>
                  <strong>{reservation.guest.fullname}</strong>
                </p>
              </div>

              <div className="grid__item medium-up--three-quarters text-left">
                {isReview && (
                  <React.Fragment>
                    <StarRatingComponent
                      name="starRating"
                      value={hostReview.star?Number(hostReview.star):0}
                      starCount={5}
                    />
                    <h5> Your Review: </h5>
                    <div className="review_comment">
                      <blockquote className="sidekick">{hostReview.comment}</blockquote>
                    </div>
                    <p className="schedule_date_text">{experience.title}, {reservation.schedule.date ? moment.utc(reservation.schedule.date).format('LL') : ''}</p>
                    <div>
                      <span className="booking_number_text left">{reservation.bookingNumber}</span>
                      <span className="time_posted_text right">{hostReview.timePosted}</span>
                    </div>
                    <br/><br/>
                  </React.Fragment>
                )}
                { hostReview.status == 'pending' ? (
                  <div>
                    <br/>
                    <br/>
                    <p><i>* The review is pending activation and will be active and show up when both parties submit their feedback or after 14-day since it was submitted, whichever comes first.</i></p>
                  </div>
                ) : ''
                }
                {!isReview && (
                  <React.Fragment>
                    <label>What is your overall rating of this diner?</label>
                    <Field name="star" component={StarRatingField}/>
                    <p>
                      <label>
                         Please include a review of having this diner as part of
                         your experience.
                      </label>
                      <Field
                        name={reviewField.name}
                        component={RenderField}
                        type="textArea"
                        inputType="textArea"
                        placeholder={reviewField.placeholder}
                        displayLabel={false}
                        field={reviewField}
                      />
                    </p>
                    <div className="form-action">
                      <button
                        type="submit"
                        disabled={pristine || !this.props.valid}
                        form={form}
                        className="btn btn--darker pull-right"
                      >
                        Submit
                      </button>
                    </div>
                  </React.Fragment>
                )}
              </div>
            </div>
          </div>

          <div className="form-card-column grid__item medium-up--one-third">
            {typeof experience !== 'undefined' && (
              <ExperienceCard
                experience={experience}
                hideAvatar
                displaySoldStatus
              />
            )}
          </div>
        </div>
      </form>
      // <form onSubmit={handleSubmit} id={form} className="">
      //   <div className="text-center form-header">
      //     {!reviewedByHost ? (
      //       <React.Fragment>
      //         <h4>You are writing a review for:</h4>
      //         <h5>{guest.fullname}</h5>
      //         <h5>
      //         Reviewing guest {currentPage}/{reservationCount}
      //         </h5>
      //       </React.Fragment>
      //     ) : (
      //       <React.Fragment>
             
    //         <h5>Thanks for reviewing {guest.fullname}!</h5>
    //       </React.Fragment>
    //     )}

          
         
    //   </div>
    //   <hr className="hr--clear" />
    //   <div className="grid grid--uniform">
    //     <div className="form-column grid__item medium-up--two-thirds">
            
    //       <div className="grid grid--uniform">
    //         <div className="grid__item medium-up--one-quarter text-center userCard">
    //           <UserCard person={guest} />
    //           <label>{guest.fullname}</label>
    //         </div>

    //         <div className="grid__item medium-up--three-quarters text-left">
    //           {reviewedByHost && (
    //             <React.Fragment>
                    
    //               <StarRatingComponent
    //                 name="starRating"
    //                 value={hostReview.star}
    //                 starCount={5}
    //               />
    //               <h5> Your Review: </h5>
    //               <p>{hostReview.comment}</p>
    //               <p>
    //                 To view reviews by your guests,
    //                 <Link to={`/experiences/${experience.slug}`}>
    //                   click here.
    //                 </Link>
    //               </p>
    //             </React.Fragment>
    //           )}
    //           {!reviewedByHost && (
    //             <React.Fragment>
    //               <label>What is your overall rating of this diner?</label>
    //               <Field name="star" component={StarRatingField} />
    //               <p>
    //                 <label>
    //                   Please include a review of having this diner as part of
    //                   your experience.{reviewField.name}
    //                 </label>
    //                 <Field
    //                   name={reviewField.name}
    //                   component={RenderField}
    //                   type="textArea"
    //                   inputType="textArea"
    //                   placeholder={reviewField.placeholder}
    //                   displayLabel={false}
    //                   field={reviewField}
    //                 />
    //               </p>
    //             </React.Fragment>
    //           )}

    //           <div className="form-action">
    //             {!reviewedByHost && (
    //               <button
    //                 type="submit"
    //                 disabled={submitting || pristine}
    //                 form={form}
    //                 className="btn btn--darker pull-right"
    //               >
    //                 Submit
    //               </button>
    //             )}

    //             {reviewedByHost && currentPage < reservationCount && (
    //               <p>
    //                 <button
    //                   type="button"
    //                   className="btn btn--darker pull-right"
    //                   onClick={() => nextPage()}
    //                 >
    //                   View Next
    //                 </button>
    //               </p>
    //             )}

    //             {reviewedByHost && currentPage === reservationCount && (
    //               <p>
    //                 <button
    //                   type="button"
    //                   className="btn btn--darker pull-right"
    //                   onClick={() => exit()}
    //                 >
    //                   Finish
    //                 </button>
    //               </p>
    //             )}
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //     <div className="form-card-column grid__item medium-up--one-third">
    //       {typeof experience !== 'undefined' && (
    //         <ExperienceCard
    //           experience={experience}
    //           hideAvatar
    //           displaySoldStatus
    //         />
    //       )}
    //     </div>
    //   </div>
    // </form>
    )
  }
}

ReviewForm.defaultProps = {
  disableFields: true
}

ReviewForm.propTypes = {
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  valid: PropTypes.bool.isRequired,
  // nextPage: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  currentUser: currentUserPropTypes.isRequired,
  guest: PropTypes.shape({}).isRequired,
  // validate: PropTypes.shape({}).isRequired,
  form: PropTypes.string.isRequired,
  currentPage: PropTypes.number.isRequired,
  reservationCount: PropTypes.number.isRequired,
  experience: PropTypes.shape({}).isRequired,
  reviewedByGuest: PropTypes.bool.isRequired,
  reviewedByHost: PropTypes.bool.isRequired,
  reservation: PropTypes.shape({}).isRequired,
}

export default ReviewForm
