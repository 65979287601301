// import React, { useState, useEffect } from 'react'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { orderBy } from 'lodash'
import PropTypes from 'prop-types'
import ReactTable from 'react-table'
import Avatar from 'react-avatar'
import moment from 'moment'
import Modal from 'react-bootstrap/lib/Modal'
import ReactPlaceholder from 'react-placeholder'
import StarRatingComponent from 'react-star-rating-component'
import { Field, reduxForm, change } from 'redux-form'
// import * as fields from '../../Fields/formFields';
import Select from 'react-select'
import { bindActionCreators } from 'redux'
import { RouterActionType } from 'connected-react-router'
import ReactPaginate from 'react-paginate'
import * as hostActionCreators from '../../actions/host'
import * as fields from '../Form/Fields/formFields'
import UserReview from './Reviews/UserReview'
import ChefAvtarDiner from '../Chef/ChefAvtarDiner'
import UserCard from '../User/UserCard'
import { getReservations as getReservationsAction, getDinerReviews } from '../../actions/host'
import api from '../../utils/api'
import alertModal from '../../utils/alert'
import seo_meta from '../../utils/seo-meta.json'
import HostReservationCard from '../Experiences/HostReservationCard'
import SEO from '../Shared/Web/SEO'

const gridSize = 'grid__item small--one-whole medium-down--one-half medium--one-half large-up--one-third widescreen-up--one-quarter'
const fieldGridSize = 'grid__item medium-down--one-half large-up--one-quarter'
class ReservationsCardView extends Component {
  constructor(props) {
    super(props)
    this.state = {
      reservations: [],
      sortedReservations: [],
      show: false,
      user: null,
      selectedExperience: '',
      userInfo: [],
      options: [],
      startIndex: 0,
      endIndex: 1,
      loading: false,
      sortBy: [{ value: 'bookedDate', label: 'Sort By - Booking Date' }],
      disable: false,
      sortingOption: [
        { value: 'status', label: 'Sort By - Status' },
        { value: 'bookedDate', label: 'Sort By - Booking Date' },
        { value: 'seats', label: 'Sort By - Seats' }
      ]
    }
  }

  fetchData = async () => {
    const { getReservations } = this.props
    const result = await getReservations(this.props)

    let reservationList = result.reservations
    const experience_id = this.props.match.params.reservationId
    if (experience_id) {
      reservationList = reservationList.filter((data) => data.experience.id == experience_id)
      // this.setState({ reservations: reservationList });
    }

    const newlySortedReservations = orderBy(reservationList, [(obj) => new Date(obj.bookedDate)], ['desc'])
    
    this.setState({
      reservations: result.reservations,
      loading: true,
      sortedReservations: newlySortedReservations
    })
  }

  handlePageClick = (data) => {
    const { selected } = data
    const startIndex = Math.ceil(selected * 5)
    const endIndex = Math.ceil((selected + 1) * 5 - 1)

    this.setState({ startIndex, endIndex })
  }

  componentDidMount = () => {}

  componentWillMount() {
    const { hostActions } = this.props
    hostActions.getExperiences()

    // let reservationList = [];
    let selectedExperience = null
    let disableExperience = false
    this.fetchData()
    const experience_id = this.props.match.params.reservationId
    const options = []
    let option = {}

    for (const key in this.props.allExperiences) {
      if (!this.props.allExperiences[key].sample) {
        option = {
          value: this.props.allExperiences[key].id,
          label: this.props.allExperiences[key].title
        }
        options.push(option)
      }

      if (experience_id == this.props.allExperiences[key].id) {
        selectedExperience = option
        disableExperience = true
        // this.setState({ });
      }
    }

    this.setState({ options, selectedExperience, disable: disableExperience })
  }

  updateReservation = (type, reservation) => {
    if (type === 'decline') {
      api
        .post(`hosts/reservations/${reservation.id}/decline`, { reservation })
        .then((response) => {
          const json = response.data
          if (json.is_success) {
            alertModal('Reservation request rejected.', 'success', 'Close')
            const fetchData = async () => {
              const result = await this.props.getReservations(this.props)
              const reservationList = result.reservations
              const newlySortedReservations = orderBy(reservationList, [(obj) => new Date(obj.bookedDate)], ['desc'])
              this.setState({ reservations: reservationList })
              this.setState({ sortedReservations: [...newlySortedReservations] })
            }

            fetchData()
          } else {
            alertModal('there is some network issue', 'error', 'Close')
          }
        })
        .catch((e) => alertModal(e.message, 'error', 'Close'))
    } else if (type === 'approve') {
      api
        .post(`hosts/reservations/${reservation.id}/approve`, { reservation })
        .then((response) => {
          const json = response.data
          if (json.is_success) {
            alertModal('Reservation request approved.', 'success', 'Close')
            const fetchData = async () => {
              const result = await this.props.getReservations(this.props)
              const reservationList = result.reservations
              const newlySortedReservations = orderBy(reservationList, [(obj) => new Date(obj.bookedDate)], ['desc'])
              this.setState({ reservations: reservationList })
              this.setState({ sortedReservations: [...newlySortedReservations] })
            }
            fetchData()
          } else {
            alertModal('there is some network issue', 'error', 'Close')
          }
        })
        .catch((e) => alertModal(e.message, 'error', 'Close'))
    }
  }

  handleChange = (selectedExperience) => {
    if (selectedExperience != null) {
      this.setState({ selectedExperience })
      let reservationList = this.state.reservations
      const experience_id = selectedExperience.value
      if (experience_id) {
        reservationList = reservationList.filter((data) => data.experience.id == experience_id)
      }
      const newlySortedReservations = orderBy(reservationList, [(obj) => new Date(obj.bookedDate)], ['desc'])
      // this.setState({ reservations: reservationList });
      this.setState({ sortedReservations: newlySortedReservations })
    } else {
      const reservationList = this.state.reservations
      this.setState({
        selectedExperience: '',
        sortedReservations: reservationList
      })
    }
    // this.props.history.push(`/hosts/reservations/${selectedExperience.value}`);
  }

  updateSelection = (target) => {
    this.setState({ sortBy: target })
    const selected = target.value
    const data = this.state.reservations
    let newlySortedReservations = []
    if (selected === 'bookedDate') {
      newlySortedReservations = orderBy(data, [(obj) => new Date(obj.bookedDate)], ['desc'])
    } else if (selected === 'status') {
      newlySortedReservations = orderBy(data, 'status', 'desc')
    } else if (selected === 'seats') {
      newlySortedReservations = orderBy(data, 'seatsBooked', 'desc')
    }
    this.setState({ sortedReservations: newlySortedReservations })
  }

  render() {
    const { reservations, sortedReservations } = this.state
    const data = reservations
    function hasPassed(reservation) {
      const parsedReservationDate = Date.parse(reservation.event_date)
      const currentDate = Date.now()

      return parsedReservationDate < currentDate
    }

    const columns = [
      {
        Header: 'Booking No',
        className: 'padded-cells',
        // width: "80%",
        Cell: ({ row, index }) => (
          <div className="padded-cells" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
            {row._original.bookingNumber}
          </div>
        )
      },
      {
        Header: 'Experience Name',
        className: 'padded-cells customBold',
        Cell: ({ row }) => (
          <div className="padded-cells" style={{ fontWeight: 'lighter' }}>
            {row._original.experience.title}
          </div>
        )
      },
      {
        Header: 'Guest Name',
        className: 'padded-cells displayFlex customRow ',
        // width:"300%",
        Cell: ({ row }) => (
          <React.Fragment>
            {/* <div
                className="chef-or-host__avatar cursor tableAvtar"
                style={{ backgroundImage: `url(${row._original.guest.avatar_url})`, margin: "0px" }}
                onClick={() => { 
                  this.props.getDinerReviews(row._original.guest.id);
                  this.setState({ show: true, user: row._original.guest }) }}
              >
                <img
                  className="lazyload lazysizes avatar profileAvatarDrop__avatar hidden"
                  alt={row._original.guest.fullname}
                  style={{ style: "40%" }}
                  src={row._original.guest.avatar_url}
                  data-src={row._original.guest.avatar_url}
                  data-widths="[180, 360, 540, 720, 900, 1080, 1296, 1512, 1728, 2048]"
                />
              </div> */}
            <div
              className="padded-cells"
              style={{ paddingLeft: 'px', paddingRight: '0px' }}
              onClick={() => {
                this.props.getDinerReviews(row._original.guest.id)
                this.setState({ show: true, user: row._original.guest })
              }}
            >
              {row._original.guest.fullname.length > 15 ? `${row._original.guest.fullname.substring(0, 15)}....` : row._original.guest.fullname}{' '}
            </div>
          </React.Fragment>
        )
      },
      {
        Header: 'Date of Booking',
        className: 'padded-cells',
        Cell: ({ row }) => <div className="padded-cells"> {moment.utc(row._original.bookedDate).format('Do MMM YYYY')}</div>
      },
      {
        Header: 'Booking Status',
        className: 'padded-cells',
        Cell: ({ row }) => <div className="padded-cells">{row._original.status}</div>
      },
      {
        Header: 'No of Seats',
        // width: "100%",
        Cell: ({ row }) => <div className="padded-cells">{row._original.seatsBooked}</div>
      },
      {
        Header: 'Action',
        Cell: ({ row }) => (
          <div className="padded-cells" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
            {row._original.status == 'submitted' ? (
              <React.Fragment>
                <div style={{ padding: '10px' }} className="breakbread-btn btn btn--dark btn-sm btn-Mycolor" onClick={() => this.updateReservation('approve', row._original)}>
                  Accept
                </div>
                <div style={{ padding: '10px', marginLeft: '5px' }} className="breakbread-btn btn btn--dark btn-sm btn-Mycolor" onClick={() => this.updateReservation('decline', row._original)}>
                  Reject
                </div>
              </React.Fragment>
            ) : (
              <React.Fragment>
                {row._original.status == 'declined' ? (
                  <div style={{ padding: '10px' }} className="breakbread-btn btn btn--dark btn-sm btn-Mycolor" onClick={() => this.updateReservation('approve', row._original)}>
                    Accept
                  </div>
                ) : (
                  <React.Fragment>
                    {row._original.status == 'cancelled' ? (
                      ''
                    ) : (
                      <div style={{ padding: '10px' }} className="breakbread-btn btn btn--dark btn-sm btn-Mycolor" onClick={() => this.updateReservation('decline', row._original)}>
                        Reject
                      </div>
                    )}
                  </React.Fragment>
                )}
              </React.Fragment>
            )}
          </div>
        )
      }
    ]
    const experience_id = this.props.match.params.reservationId
    const detaNotFoundMessage = !experience_id ? 'No Reservation found. Try reseting the filter ' : 'No Reservation found.'
    return (
      <div className="breakbread-container">
        <SEO title="My Bookings (Host)" description="My Bookings (Host)" />
        {this.state.user != null ? (
          <Modal
            show={this.state.show}
            onHide={() => {
              this.setState({ show: false })
            }}
            animation={false}
            bsSize="small"
            className="reviewModal"
          >
            <Modal.Header closeButton />
            <Modal.Body>
              <React.Fragment>
                <div className="grid grid--uniform mb-5">
                  <div className="grid grid--uniform mb-5">
                    <div className="grid__item medium-down--one-half medium--one-half large-up--one-quarter mb-5 bd-r">
                      {/* FIXME - Manish - the below code is throwing error in JS Console */}
                      <ChefAvtarDiner person={this.state.user} />
                      <div className="mb-5" style={{ color: 'black' }}>
                        <b>{this.state.user.fullname}</b>
                      </div>
                      <span className="breakbread-btn btn btn-sm btn-Mycolor"> Accept Request</span>
                      <span className="breakbread-btn btn btn--dark btn-sm btn-Mycolor"> Reject Request</span>
                    </div>

                    <div className="grid__item medium-down--one-half medium--one-half large-up--three-quarters" style={{ paddingLeft: '50px' }}>
                      {this.props.reviews.length > 0
                        ? this.props.reviews.map((review, index) => {
                          if (index >= this.state.startIndex && index <= this.state.endIndex) return <UserReview review={review} />
                        })
                        : 'No Review found.'}
                      {this.props.reviews.length > 0 ? (
                        <ReactPaginate
                          previousLabel="<"
                          nextLabel=">"
                          breakLabel="..."
                          breakClassName="break-me"
                          containerClassName="pagination"
                          pageCount={Math.ceil(this.props.reviews.length / 5)}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={5}
                          onPageChange={this.handlePageClick}
                          subContainerClassName="pages pagination"
                          activeLinkClassName="btn btn--darker"
                          pageLinkClassName="btn"
                          pageClassName="pagination__text"
                          activeClassName="active"
                        />
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </div>
              </React.Fragment>
            </Modal.Body>
          </Modal>
        ) : (
          ''
        )}
        <h1>Reservations (Host) </h1>
        <div
          className="grid grid--uniform mb-5"
          style={{
            zIndex: '99',
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '2rem'
          }}
        >
          <Select
            value={this.state.selectedExperience}
            isDisabled={this.state.disable}
            placeholder="Select Experience"
            import="Select Experience"
            onChange={this.handleChange}
            className="react-select centerSelectBtn1"
            classNamePrefix="select"
            isClearable
            options={this.state.options}
          />

          <Select
            // value={}
            onChange={this.updateSelection}
            value={this.state.sortBy}
            className="react-select centerSelectBtn1"
            classNamePrefix="select"
            placeholder="Sort by"
            import="Sort by"
            disable
            options={this.state.sortingOption}
          />
        </div>
        <div className="grid grid--uniform">
          {sortedReservations.length > 0 ? (
            sortedReservations.map((reservation, idx) => (
              <div key={idx} className={gridSize}>
                <HostReservationCard reservation={reservation} displaySoldStatus updateReservation={this.updateReservation} />
                {/* <Link to={`/hosts/${host.id}`}> }
                <ChefOrHostCard person={host} />
                {/* </Link> */}
              </div>
            ))
          ) : (
            <React.Fragment>
              <div className="grid__item">
                <h5>{this.state.loading ? detaNotFoundMessage : <div className="spinner spinner--empty" />}</h5>
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
    )
  }
}

ReservationsCardView.propTypes = {
  getReservations: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  // hosts: state.user.hosts,
  allExperiences: state.host.listings.data,
  reviews: state.host.reviews.diners_reviews
})

const mapDispatchToProps = (dispatch) => ({
  getReservations: () => dispatch(getReservationsAction()),
  getDinerReviews: (id) => dispatch(getDinerReviews(id)),
  hostActions: bindActionCreators(hostActionCreators, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(ReservationsCardView)
