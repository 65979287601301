
import api from '../../utils/api'
let FETCH_AMENTIY_LIST = "FETCH_AMENTIY_LIST"
let FETCH_WOWELEMENT_LIST = "FETCH_WOWELEMENT_LIST"
let FETCH_CUISINE_TYPE_LIST = "FETCH_CUISINE_TYPE_LIST"
let FETCH_EXPERIENCE_TYPE_LIST = "FETCH_EXPERIENCE_TYPE_LIST"
let FETCH_MENU_ALLERGENS_LIST = "FETCH_MENU_ALLERGENS_LIST"
let FETCH_MEAL_CATEGORY_LIST = "FETCH_MEAL_CATEGORY_LIST"
let FETCH_MENU_COURSE_LIST = "FETCH_MENU_COURSE_LIST"
// let DISABLE_LOGIN = "DISABLE_LOGIN"
// import alertModal from '../../utils/alert';
export function getAmenty(list) {
  return {
    type: FETCH_AMENTIY_LIST,
    payload: {
      list
    }
  }
}

export function getWowElement(list) {
  return {
    type: FETCH_WOWELEMENT_LIST,
    payload: {
      list
    }
  }
}

export function getCuisineTypes(list) {
  return {
    type: FETCH_CUISINE_TYPE_LIST,
    payload: {
      list
    }
  }
}

export function getExperienceTypes(list) {
  return {
    type: FETCH_EXPERIENCE_TYPE_LIST,
    payload: {
      list
    }
  }
}

export function getMenuAllergens(list) {
  return {
    type: FETCH_MENU_ALLERGENS_LIST,
    payload: {
      list
    }
  }
}

export function getMealCategories(list) {
  return {
    type: FETCH_MEAL_CATEGORY_LIST,
    payload: {
      list
    }
  }
}

export function getMenuCourses(list) {
  return {
    type: FETCH_MENU_COURSE_LIST,
    payload: {
      list
    }
  }
}

export function getAmentyList() {
  return async (dispatch, getState) => {
    try {
      const response = await api.get(`masters/location_amenities`)
      dispatch(getAmenty(response.data.amenities))
    } catch (err) {
      console.log(err)
      throw err
    }
  }
}

export function getWowElementList() {
  return async (dispatch, getState) => {
    try {
      const response = await api.get(`masters/location_wow_elements`)
      dispatch(getWowElement(response.data.wow_elements))
    } catch (err) {
      console.log(err)
      throw err
    }
  }
}

export function getCuisineTypesList() {
  return async (dispatch, getState) => {
    try {
      const response = await api.get(`masters/cuisine_types`)
      dispatch(getCuisineTypes(response.data.cuisine_types))
    } catch (err) {
      console.log(err)
      throw err
    }
  }
}

export function getExperienceTypesList() {
  return async (dispatch, getState) => {
    try {
      const response = await api.get(`masters/experience_types`)
      dispatch(getExperienceTypes(response.data.data))
    } catch (err) {
      console.log(err)
      throw err
    }
  }
}

export function getMenuAllergenList() {
  return async (dispatch, getState) => {
    try {
      const response = await api.get(`masters/menu_allergens`)
      dispatch(getMenuAllergens(response.data.menu_allergens))
    } catch (err) {
      console.log(err)
      throw err
    }
  }
}

export function getMealCategoryList() {
  return async (dispatch, getState) => {
    try {
      const response = await api.get(`masters/meal_categories`)
      dispatch(getMealCategories(response.data.meal_categories))
    } catch (err) {
      console.log(err)
      throw err
    }
  }
}

export function getMenuCourseList() {
  return async (dispatch, getState) => {
    try {
      const response = await api.get(`masters/menu_courses`)
      dispatch(getMenuCourses(response.data.menu_courses))
    } catch (err) {
      console.log(err)
      throw err
    }
  }
}

/* export function disableLogin (bool) {
  return {
    type: 'DISABLE_LOGIN',
    payload: bool
  }
} */

// export { getCountryList };
