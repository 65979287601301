import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
// import { goBack } from 'connected-react-router'
import { bindActionCreators } from 'redux'
// import alertModal from '../../../utils/alert';
import {
  registerUser,
  signInUser,
  signOutUser
} from '../../../auth/redux-token-auth-config'
// Actions
import {
  // fileUpload,
  updateUser
  // updateProfilePic,
  // verifileMobileWithOtp
} from '../../../actions/user'
// import { getSampleExperiences } from '../../../actions/host'
import * as modalActionCreators from '../../../actions/modals'
import * as experienceActionCreators from '../../../actions/experience'
import * as formActionCreators from '../../../actions/forms'
import * as hostActionCreators from '../../../actions/host'
import * as userActionCreators from '../../../actions/user'
import { trackHostApplication, trackGetStartedHostApplication } from '../../../utils/tracking'
import { history } from '../../../configureStore'

// Validations
import { currentUserPropTypes } from '../../../constants/composed-proptypes'
// Local Common Utils
import {
  handleRegistration
  // handleUpdate,
  // handleUpdateHost,
} from '../Common/utils'

import {
  // normalizeExperienceData,
  normalizeHostData
} from '../../../utils/normalization'

// Form Steps
import StepCreateAnAccount from './Step1'
import StepUpdateProfileImage from './Step2'
import Step2Verify from './Step2Verify'
import RegistrationThirdPage from './Step3'
import RegistrationFourthPage from './Step4'
import RegistrationFifthPage from './Step5'
import RegistrationSixthPage from './Step6'
import RegistrationSeventhPage from './Step7'
// import RegistrationBankDetailPage from './Step3bank';

class Registration extends Component {
  constructor(props) {
    super(props)
    this.nextPage = this.nextPage.bind(this)
    this.previousPage = this.previousPage.bind(this)
    const { currentUser, formActions, stepForm } = this.props

    /** NOTE: This checker can be a little ackward.
     * We want to skip the the 1st and second steps when the user is signed in.
     * We also check if the form is in its default state inorder to allow users
     * to reload the page and keep their place. stepForm.page will always reset
     * to 1 when the modal is closed.
     * */
    const isFirstTry = currentUser.isSignedIn && stepForm.page === 1
    formActions.updateHostFormStep(isFirstTry ? 2 : stepForm.page)
  }

  componentDidMount() {
    const { userActions, hostActions, currentUser, stepForm } = this.props
    userActions.getCountryList()
    userActions.getAmentyList()
    userActions.getCuisineTypesList()
    if (currentUser.attributes && currentUser.attributes.id) {
      hostActions.getLocations()
    }
    userActions.getWowElementList()
    userActions.getMenuCourseList()
    /* if (currentUser.attributes.id) {
      userActions.getUser(currentUser.attributes.id)
    } */
    // console.log("countryOptions--->", this.props.countryOptions)
    trackHostApplication({ stepNumber: stepForm.page })
  }

  handleAccountData = async data => {
    const { userActions, profilePicObj } = this.props
    const userData = data
    delete userData.foodPhotos
    delete userData.locationPhotos
    delete userData.hostingLocation
    if (profilePicObj) {
      await userActions.updateProfilePic({ image: profilePicObj })
      await userActions.setProfileObj(null)
    }
    return userActions.updateUserAccount(userData, false, 'fromBecomeHost')
  }

  handleProfessionalInfo = async data => {
    const { userActions } = this.props
    const dataUser = {
      user: {
        accept_terms: data.acceptTerms,
        social_links: data.socialLinks,
        culinary_journey: data.culinaryJourney,
        specialties: data.specialties.map((el) => { if (el.name) { return el } return { name: el.label, id: el.id } })
      }
    }
    // console.log('userActions===', data)
    return userActions.updateProfessionalInfo(dataUser, false)
    // const { userActions } = this.props
    /*  const userData = data
    delete userData.avatarUrl
    delete userData.foodPhotos
    delete userData.locationPhotos
    delete userData.hostingLocation */
    //
  }

  createDefaultLocation = async () => {
    const { hostActions } = this.props
    const locationData = {
      neighbourhood: '',
      city: 'Dubai',
      countryCode: 'AE',
      postalCode: '',
      streetAddress: '',
      amenities: [],
      wowElements: []
    }
    const response = await hostActions.createUpdateLocation(locationData)
    return response
  }

  handleHostingSpaceDetail = async data => {
    // eslint-disable-next-line no-console
    const { hostActions, firstHostLocationObj } = this.props
    const hostingSpaceData = {
      id: firstHostLocationObj ? firstHostLocationObj.id : null,
      countryCode: typeof data.hostAddressFieldsCountryCode === 'string' ? data.hostAddressFieldsCountryCode : data.hostAddressFieldsCountryCode.value,
      postalCode: data.hostAddressFieldsPostalCode,
      streetAddress: data.hostingLocationStreetAddress,
      neighbourhood: data.hostAddressFieldsNeighbourhood,
      city: typeof data.hostAddressFieldsCity === 'string' ? data.hostAddressFieldsCity : data.hostAddressFieldsCity.value,
      // locationPhotos: data.locationPhotos,
      amenities: data.amenities ? data.amenities.map((el) => ({ id: el.value, name: el.label })) : [],
      wowElements: data.wow_elements ? data.wow_elements.map((el) => ({ id: el.value, name: el.label })) : []
    }

    return hostActions.createUpdateLocation(hostingSpaceData)
  }

  handleHostingFoodPhotos = async data => {
    const { userActions } = this.props
    const dataUser = {
      user: {
        food_photos: data.foodPhotos.map(el => el.signedId).filter(el => el)
      }
    }
    /* {
    "user": {
    "location_photos": [    "eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBbEVCIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--2734975fc1f62cec56cd19a8d38c3f32aa067f42",
                        "eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBbElCIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--528f9acacf074f7ef1ca2caa82afbb82b55143a8"
    ],
    "hosting_location": {
      "city": "Mumbai",
      "country_code": "IN",
      "postal_code": "3984567",
      "street_address": "Ambanis Cafe"
    }
    }
    } */
    if (dataUser.user.food_photos.length > 0) {
      return userActions.updateHostingFoodPhotos(dataUser, false)
    }
  }

  handleHostData = async data => {
    const { hostActions } = this.props
    const user = normalizeHostData(data)

    hostActions.updateHost(user)
  }

  markApplicationStarted = async () => {
    const { hostActions } = this.props
    hostActions.hostApplicationStarted()
  }

  handleExperienceData = async data => {
    const { experienceActions } = this.props
    const experienceData = data
    experienceData.sample = '1'
    // return false
    const modalMessage = 'By submitting the application, you agree to BreakBread\'s '

    return experienceActions.createFirstExperience({
      experienceData,
      modalMessage
    })
  }

  async register(values) {
    // const { page } = this.state;
    await handleRegistration(values, this.props)
    // Needs to be defined after registration so that
    // currentUser is updated with latest values
    const { currentUser, hostActions, formActions, modalActions } = this.props
    if (values.phoneNumber) {
      formActions.updateForJustReg(true)
    }
    if (currentUser.isSignedIn) {
      // hostActions.hostApplicationStarted()
      // if required to showing mobile verification, and profile upload step then uncomment next if else condition
      /* if ((currentUser.attributes.phoneNumber && currentUser.attributes.phoneVerified !== null && currentUser.attributes.phoneVerified) || !currentUser.attributes.phoneNumber){ */
      modalActions.showRegHostModal({show: false})
      trackGetStartedHostApplication({ status: 'user' })
      await hostActions.hostApplicationStarted()
      history.push('/become-a-host/step-1')
      /* } else {
        this.nextPage()
      } */
    }
  }

  oAuthSuccess() {
    this.nextPage()
  }

  closeAndExit() {
    const { modalActions } = this.props
    modalActions.showHostConfirmation()
  }

  nextPage() {
    const { formActions } = this.props
    // const { page } = this.state;
    // this.setState({ page: page + 1 });
    const { stepForm } = this.props
    formActions.updateHostFormStep(stepForm.page + 1)
    trackHostApplication({ stepNumber: stepForm.page + 1 })
  }

  previousPage() {
    // const { page } = this.state;
    // this.setState({ page: page - 1 });
    const { stepForm, formActions } = this.props
    formActions.updateHostFormStep(stepForm.page - 1)
    trackHostApplication({ stepNumber: stepForm.page - 1 })
  }

  render() {
    const { currentUser, stepForm, userActions, countryOptions, dataError, firstHostLocationObj, justRegister } = this.props
    const { page } = stepForm
    // console.log('sampleExperince', sampleExperince)
    // this.props.dispatchUpdateUser = this.props.userActions.updateUser;
    // this.props.uploadAvatar = this.props.userActions.attachAvatar;
    return (
      <React.Fragment>
        {page === 1 && (
          <StepCreateAnAccount
            {...this.props}
            onSubmit={values => this.register(values)}
            currentUser={currentUser}
            oAuthSuccess={() => this.oAuthSuccess()}
            nextPage={() => this.nextPage()}
          />
        )}

        {page === 2 && (
          <Step2Verify
            {...this.props}
            nextPage={this.nextPage}
            previousPage={this.previousPage}
            canSkip={true}
            onSubmit={async _data => {
              // NOTE: THIS IS USING THE UPDATE USER FUNCTION
              // await userActions.verifileMobileWithOtp(data);
              // this.nextPage();
            }}
            currentUser={currentUser}
          />
        )}

        {page === 3 && (
          <StepUpdateProfileImage
            {...this.props}
            nextPage={this.nextPage}
            previousPage={this.previousPage}
            onSubmit={async _data => {
              // NOTE: THIS IS USING THE UPDATE USER FUNCTION
              // await userActions.updateProfilePic(data)
              this.nextPage()
            }}
            currentUser={currentUser}
          />
        )}
        {page === 4 && (
          <RegistrationThirdPage
            {...this.props}
            previousPage={this.previousPage}
            onSubmit={async data => {
              // console.log('Step3 DATA---------->', data)
              await this.handleAccountData(data)
              // console.log('resp', this.props.dataError)
              if (!dataError) {
                this.nextPage()
              }
            }}
            currentUser={currentUser}
            countryOptions={countryOptions}
          />
        )}

        {/* page === 4 && (
          <RegistrationBankDetailPage
            {...this.props}
            previousPage={this.previousPage}
            onSubmit={async data => {
              // console.log("Step4 DATA---------->", data)
              this.nextPage();
            }}
            currentUser={currentUser}
          />
          ) */}

        {page === 5 && (
          <RegistrationFourthPage
            {...this.props}
            previousPage={this.previousPage}
            onSubmit={async data => {
              // console.log('Step4 DATA---------->', data)
              await this.handleProfessionalInfo(data)
              if (!firstHostLocationObj) {
                await this.createDefaultLocation()
              }
              this.nextPage()
            }}
            currentUser={currentUser}
          />
        )}
        {page === 6 && (
          <RegistrationFifthPage
            {...this.props}
            previousPage={this.previousPage}
            onSubmit={async data => {
              await this.handleHostingSpaceDetail(data)
              // console.log('Step5 DATA---------->', data)
              this.nextPage()
            }}
            currentUser={currentUser}
          />
        )}
        {page === 7 && (
          <RegistrationSixthPage
            {...this.props}
            previousPage={this.previousPage}
            onSubmit={async _data => {
              // console.log('Step6 DATA---------->', data)
              // await this.handleHostData(data, this.props)
              // await this.handleHostingFoodPhotos(data, this.props)
              // await this.markApplicationStarted()
              this.nextPage()
            }}
            currentUser={currentUser}
          />
        )}
        {page === 8 && (
          <RegistrationSeventhPage
            {...this.props}
            previousPage={this.previousPage}
            onSubmit={async data => {
              // console.log('Step7 DATA---------->', data)
              await this.handleExperienceData(data, this.props)
              // userActions.getUser(currentUser.attributes.id)
            }}
            currentUser={currentUser}
          />
        )}
      </React.Fragment>
    )
  }
}

Registration.propTypes = {
  currentUser: currentUserPropTypes.isRequired,
  stepForm: PropTypes.shape({}).isRequired,
  modalActions: PropTypes.shape({}).isRequired,
  formActions: PropTypes.shape({}).isRequired,
  experienceActions: PropTypes.shape({}).isRequired,
  hostActions: PropTypes.shape({}).isRequired
}

const mapDispatchToProps = dispatch => ({
  register: user => dispatch(registerUser(user)),
  signIn: user => dispatch(signInUser(user)),
  // verifyTokenAsynch:()=>dispatch(verifyTokenAsynch()),
  signOut: user => dispatch(signOutUser(user)),
  // getSampleExperiences: userId => dispatch(getSampleExperiences(userId)),
  dispatchUpdateUser: attributes => dispatch(updateUser(attributes)),
  formActions: bindActionCreators(formActionCreators, dispatch),
  experienceActions: bindActionCreators(experienceActionCreators, dispatch),
  modalActions: bindActionCreators(modalActionCreators, dispatch),
  hostActions: bindActionCreators(hostActionCreators, dispatch),
  userActions: bindActionCreators(userActionCreators, dispatch)
})

const mapStateToProps = state => ({
  pathname: state.router.location.pathname,
  currentUser: state.reduxTokenAuth.currentUser,
  justRegister: state.stepForm.justRegister,
  // sampleExperince: state.user.experiences.sampleExperince,
  stepForm: state.stepForm.hostRegistration,
  menuFIleArray: state.user.fileUploads.menuFIleArray
    ? state.user.fileUploads.menuFIleArray
    : null,
  profilePicObj: state.user.fileUploads.profilePicObj
    ? state.user.fileUploads.profilePicObj
    : null,
  cuisineTypesOptions: state.user.amenitiesAndWowElements.cuisineTypes
    ? state.user.amenitiesAndWowElements.cuisineTypes.list
    : null,
  countryOptions: state.user.countryCode.countryList
    ? state.user.countryCode.countryList.list
    : null,
  amenitiesOptions: state.user.amenitiesAndWowElements.amenityList
    ? state.user.amenitiesAndWowElements.amenityList.list
    : null,
  wowElementOptions: state.user.amenitiesAndWowElements.wowElementList
    ? state.user.amenitiesAndWowElements.wowElementList.list
    : null,
  experienceTypesOptions: state.user.amenitiesAndWowElements.experienceTypes
    ? state.user.amenitiesAndWowElements.experienceTypes.list
    : null,
  menuCoursesOptions: state.user.amenitiesAndWowElements.menuCourses
    ? state.user.amenitiesAndWowElements.menuCourses.list
    : null,
  dataError: state.user.profile && state.user.profile.errorData ? state.user.profile.errorData : null,
  firstHostLocationObj: state.host.location.allLocations ? state.host.location.allLocations[0] : null
})

export default connect(mapStateToProps, mapDispatchToProps)(Registration)
