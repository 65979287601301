import moment from 'moment'
import { USD, AED, GBP, currencyTypes } from './currency'
import { experienceDuration } from '../constants'

const languages = require('language-list')()

const optionsMock = [
  {
    value: 'value1',
    label: 'Label 1'
  },
  {
    value: 'value2',
    label: 'Label 2'
  },
  {
    value: 'value3',
    label: 'Label 3'
  },
  {
    value: 'value4',
    label: 'Label 4'
  }
]

function getTimeStops(start, end) {
  const startTime = moment.utc(start, 'HH:mm')
  const endTime = moment.utc(end, 'HH:mm')

  if (endTime.isBefore(startTime)) {
    endTime.add(1, 'day')
  }

  const timeStops = []

  while (startTime <= endTime) {
    timeStops.push({
      label: `${moment.utc(startTime).format('hh:mm')} ${moment
        .utc(startTime)
        .format('a')}`,
      value: moment.utc(startTime).format('HH:mm')
    })
    startTime.add(30, 'minutes')
  }
  return timeStops
}

const seatOptions = () => {
  const collection = []
  let i = 0
  while (i < 300) {
    i += 1
    collection.push({
      value: i,
      label: i
    })
  }

  return collection
}
let tomorrow = new Date()
tomorrow.setDate(tomorrow.getDate()+1)
const generalInfoFields = {
  title: {
    name: 'title',
    type: 'text',
    autoComplete: '',
    placeholder: 'Name your Experience'
  },
  experienceType: {
    name: 'experienceType',
    type: 'select',
    placeholder: 'Select Experience Type',
    autoComplete: ''
  },
  date: {
    name: 'date',
    placeholder: `${moment
      .utc(Date.now())
      .add(1, 'days')
      .format('DD-MM-YYYY')}`,
    value: tomorrow, // Needs to be a moment obj
    label: 'Date'
  },
  /* startDate: {
    name: 'StartDate',
    placeholder: `${moment
      .utc(Date.now())
      .add(1, 'days')
      .format('MM/DD/YYYY')}`,
    value: `${moment
      .utc(Date.now())
      .add(1, 'days')
      .format('MM/DD/YYYY')}`, // Needs to be a moment obj
    label: 'start Date'
  }, */
  /* endDate: {
    name: 'EndDate',
    placeholder: `${moment
      .utc(Date.now())
      .add(1, 'days')
      .format('MM/DD/YYYY')}`,
    value: `${moment
      .utc(Date.now())
      .add(1, 'days')
      .format('MM/DD/YYYY')}`, // Needs to be a moment obj
    label: 'End Date'
  }, */
  time: {
    name: 'time',
    placeholder: 'Time',
    options: getTimeStops('0:00', '23:59'),
    value: null
  },
  duration: {
    name: 'duration',
    type: 'select',
    options: experienceDuration.map(el=>({label:el, value: el})),
    placeholder: 'Select Duration in Hours',
    autoComplete: ''
  },
  instant: {
    name: 'instant',
    type: 'checkbox',
    placeholder: 'instant',
    autoComplete: ''
  },
  day: {
    name: 'day',
    placeholder: '29',
    options: optionsMock,
    value: null // Needs to be a moment obj
  },
  month: {
    name: 'month',
    placeholder: '08',
    options: optionsMock,
    value: null // Needs to be a moment obj
  },
  year: {
    name: 'year',
    placeholder: '2019',
    options: optionsMock,
    value: null // Needs to be a moment obj
  },
  /* startTime: {
    name: 'StartTime',
    placeholder: 'Start Time',
    options: getTimeStops('0:00', '23:59'),
    value: null
  },
  endTime: {
    name: 'EndTime',
    placeholder: 'End Time',
    options: getTimeStops('0:00', '23:59'),
    value: null
  }, */
  seats: {
    name: 'seats',
    placeholder: 'Select Seats',
    options: seatOptions()
  },
  price: {
    name: 'price',
    type: 'number',
    inputType: 'number',
    autoComplete: '',
    value: '',
    placeholder: '99'
  },
  currency: {
    name: 'currency',
    autoComplete: '',
    options: [
      // currencyTypes[USD], 
      currencyTypes[AED]], 
    //currencyTypes[GBP]],
    value: '',
    placeholder: 'Currency'
  },
  notes: {
    name: 'notes',
    type: 'textArea',
    autoComplete: '',
    value: '',
    placeholder:
      'Any other information you want your guests to know. Get creative with bullet characters and emojis. 🚀',
    label: 'Notes'
  },
  description: {
    name: 'description',
    type: 'textArea',
    autoComplete: '',
    value: '',
    placeholder: 'Description as seen on the experience profile',
    label: 'Description'
  },
  summary: {
    name: 'summary',
    type: 'text',
    autoComplete: '',
    value: '',
    placeholder:
      'Summary at 140 characters or less for display on listing indexes and search.',
    label: 'Summary'
  },
  languages: {
    name: 'languages',
    options: languages.getData(),
    autoComplete: '',
    placeholder: 'Languages',
    label: 'Languages (Select more than one)'
  }
}

export { generalInfoFields }
