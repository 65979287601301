import React from 'react'
import PropTypes from 'prop-types'
import bbLogo from '../../icons/logo.png'

const Avatar = ({ person, hideInfo: _hideInfo }) => (
  <React.Fragment>
    <div className="guest_card__avatar">
      <img
        style={{ objectFit: 'cover' }}
        className="lazyload lazysizes avatar"
        alt={person.fullname}
        src={person.avatarUrl}
        data-src={person.avatarUrl}
        data-widths="[180, 360, 540, 720, 900, 1080, 1296, 1512, 1728, 2048]"
      />
    </div>
    {person.guestRating === 0 ? (
      <div
        className="guest_card__rating badge badge--rating"
        // style={{ paddingTop: '50px' }}
      >
        <small /><img className="logo" src={bbLogo} alt="logo" /> {}
      </div>
    ) : (
      <div className="guest_card__rating badge badge--rating">
        <small> ★ {person.guestReviewsReceivedCount} </small>
        {person.guestRating}
      </div>
    )}
  </React.Fragment>
)

Avatar.defaultProps = {
  hideInfo: false
}
Avatar.propTypes = {
  person: PropTypes.shape({
    avatarUrl: PropTypes.string,
    rating: PropTypes.string,
    fullname: PropTypes.string,
    guestRating: PropTypes.number,
    guestReviewsReceivedCount: PropTypes.number
  }).isRequired,
  hideInfo: PropTypes.bool
}

export default Avatar
