import React, { Component } from 'react'
import { Field } from 'redux-form'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { currentUserPropTypes } from '../../../../constants/composed-proptypes'
import FileDropField from '../../Fields/renderFileDropField'
import renderFileField from './../../Fields/renderFileField'

import { generalInfoFields } from '../../../../constants/experienceFormFields'

function keysMatch(key, obj) {
  const keys = Object.keys(obj).map(x => x.toLowerCase())
  return keys.indexOf(key.toLowerCase()) !== -1
}

/* eslint-disable-next-line no-unused-vars */
const renderError = ({ meta: { touched, error } }) => {
  /* eslint-disable-next-line no-unused-expressions */
  touched && error ? <span>{error}</span> : false
}

class General extends Component {
  constructor(props) {
    super(props)

    this.state = {
      focus: false
    }
  }

  componentDidMount() {
    const {
      formState
    } = this.props
    const formValues = formState && formState.experienceEdit && formState.experienceEdit.values
      ? formState.experienceEdit.values
      : formState && formState.experienceCreate && formState.experienceCreate.values
        ? formState.experienceCreate.values
        : null
  }

  render() {
    const {
      handleSubmit,
      pristine,
      valid,
      submitting,
      previousPage,
      submitAsDraft,
      photosValues,
      buttonText,
      formState,
      userActions,
      isUpdating
    } = this.props

    const formValues = formState && formState.experienceEdit && formState.experienceEdit.values
      ? formState.experienceEdit.values
      : formState && formState.experienceCreate && formState.experienceCreate.values
        ? formState.experienceCreate.values
        : null

    const photosPresent = () =>
      typeof photosValues !== 'undefined' && photosValues.length > 0 && (formValues && formValues.heroImage  && formValues.heroImage.big)

    let fullClass = false
    return (
      <form onSubmit={handleSubmit} className="">
        <div className="form-header">
          <h4>Experience Photos</h4>
        </div>
        <div className="form-body form-body--med">
          <div className="grid__item small--one-whole medium-up--full">
            <label style={{ margin: "5px"}}>Cover Photo</label>
            <p className="m-5">Upload a wide image to display as cover photo in the experience page (JPEG or PNG less than 5 MB, 1280×780 pixels. Aspect ratio 16:9)</p>
            <div className="preview" >
              <div className="menuItem_image" style={{
                backgroundImage: `url(${formValues && formValues.heroImage  && formValues.heroImage.big?formValues.heroImage.big + '?' + new Date().getTime() :''})`, backgroundRepeat: 'no-repeat', backgroundPosition:'center', height: '200px', marginBottom: '16px', backgroundSize: 'cover', WebkitBackgroundSize: 'cover', MozBackgroundSize: 'cover', backgroundColor: '#f1f1f1', OBackgroundSize: 'cover'
              }} />
            </div>
            {/*   <div className="menuItems-field-group--dropzone"> */}
            <Field
              name="heroImage"
              label="Dish Image"
              id="fileUpload12"
              type="file"
              accept=".jpg, .jpeg, .png"
              component={renderFileField}
              style={{ opacity: 0, height: 0, margin: 0, padding: 0 }}
              onClickCapture={()=>this.setState({uploadingStarted:true})}
              onChangeCapture={() => this.setState({ uploadingStarted: true })}
              updateImageDisplay={() => {}}
              singleImageUpload={async (e) => {
                const experienceId = formValues?formValues.id:null
                const heroImage = await userActions.heroExpImageUpload(e, experienceId)
                this.props.change('heroImage', heroImage)
              }}
            />
            <button
              type="button"
              className="btn menu-image-btn"
              style={{maxWidth: '240px'}}
              id="btnUpload45"
              // disabled={this.state.uploadingStarted}
              onClick={() => {
                document.getElementById('fileUpload12').click()
              }}
            >
              Click to Upload
            </button>
            {/* </div> */}
          </div>
        </div>
        <div className="form-body form-body--med">
          <div className="grid__item small--one-whole medium-up--full">
            <label style={{ margin: "5px"}}>Upload Experience Photos</label>
            <p className="m-5">
            Upload photos that best depict your experience. These photos will be displayed in the image gallery in the experience page. 
            (JPEG or PNG less than 5 MB, 1280×780 pixels. Aspect ratio 16:9)
            </p>
            <Field
              name="photos"
              label=""
              component={FileDropField}
              parentComponent="experiencePhotos" 
              experienceId={formValues?formValues.id:null}
              imageFile={photosValues}
              type="file"
            />
          </div>
        </div>
        <div className="form-footer">
          <button
            type="button"
            disabled={submitting}
            className="btn btn--link form-footer--left"
            onClick={() => previousPage()}
          >
            Go Back a Step
          </button>
          <button
            type="button"
            disabled={isUpdating}
            className="btn btn--darker"
            onClick={() => submitAsDraft(formValues)}
          >
            {'Save as Draft'}
          </button>
          <button
            type="submit"
            disabled={isUpdating || submitting || pristine || !valid || !photosPresent()}
            className="btn btn--default form-footer--right"
          >
            {'Submit for Approval'}
          </button>
        </div>
      </form>
    )
  }
}

General.propTypes = {
  submitting: PropTypes.bool.isRequired,
  previousPage: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired
  // currentUser: currentUserPropTypes.isRequired,
}
export default connect(state => ({
  formState: state.form
}))(General)
