import React from 'react'

/* ICONS */

import AmericanExpressIcon from '../../icons/creditCards/AmericanExpressIcon'
import MastercardIcon from '../../icons/creditCards/MastercardIcon'
import PaypalIcon from '../../icons/creditCards/PaypalIcon'
import VisaIcon from '../../icons/creditCards/VisaIcon'

const PAYMENT_ICONS = {
  AMERICANEXPRESS: AmericanExpressIcon,
  MASTERCARD: MastercardIcon,
  PAYPAL: PaypalIcon,
  Visa: VisaIcon
  /* other icons */
}

const PaymentCard = ({ paymentMethod, changeCard }) => {
  const YieldIcon = PAYMENT_ICONS[paymentMethod.payment_type]
  return (
    <div className="card payment-card">
      <h5>{paymentMethod.payment_type}</h5>
      <h6>
        XXXX XXXX XXXX {paymentMethod.last4}
      </h6>
      <h6>
        {paymentMethod.expiry_month}/{paymentMethod.expiry_year}
      </h6>
      <div className="payment-icon-wrapper">
        <YieldIcon className="icon icon--payment" />
      </div>

      <button type="button" className="btn--link" onClick={() => changeCard()}>
        Change Card
      </button>
    </div>
  )
}

export default PaymentCard
