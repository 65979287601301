import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import alertModal from '../../utils/alert'
/* Actions */
import { verifyEmailToken } from '../../actions/forgotPassword'
import * as userActionCreators from '../../actions/user'
import seoMeta from '../../utils/seo-meta.json'
import SEO from '../Shared/Web/SEO'

class VerifyEmail extends Component {
  constructor() {
    super()
    this.state = {
      tokenExpired: false
    }
  }

  componentDidMount = async () => {
    const { match, verifyCurrentEmailToken, history } = this.props
    const { token } = match.params
    try {
      const res = await verifyCurrentEmailToken(token)
      if (res.is_success) {
        // this.setState({ token });
        alertModal('Email is verified now', 'success', 'Close')
        // const { userActions, currentUser } = this.props
        /* if (currentUser.isSignedIn) {
          userActions.getUser(currentUser.attributes.id)
        } */

        setTimeout(() => {
          history.push('/')
        }, 2000)
      } else {
        this.setState({ tokenExpired: true })
      }
    } catch (err) {
      history.push('/404')
    }
  }

  render() {
    const { title, description } = seoMeta.verify_mail
    const { tokenExpired } = this.state
    return (
      <div className="experience-single fadeIn">
        <SEO title={title} description={description} />
        <div
          className="breakbread-container flexcenter"
          style={{ marginTop: '10%' }}
        >
          <figure>
            <div className="breakbread-component-text">
              <h1 className="h1 mega-title">
                {' '}
                {tokenExpired ? <p>Your token is expired!</p> : ''}
              </h1>
            </div>
          </figure>
        </div>
      </div>
    )
  }
}

VerifyEmail.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      token: PropTypes.string
    })
  }).isRequired,
  history: PropTypes.arrayOf(PropTypes.string).isRequired,
  verifyCurrentEmailToken: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  forgotPassword: state.forgotPassword,
  currentUser: state.reduxTokenAuth.currentUser
})

const mapDispatchToProps = dispatch => ({
  // verifyToken: state => dispatch(verifyToken(state)),
  verifyCurrentEmailToken: state => dispatch(verifyEmailToken(state)),
  userActions: bindActionCreators(userActionCreators, dispatch)
})
export default connect(mapStateToProps, mapDispatchToProps)(VerifyEmail)

// export default ResetPassword;
