import React, { Component } from 'react'
import { connect } from 'react-redux'
import { goBack } from 'connected-react-router'

import { signInUser } from '../../../auth/redux-token-auth-config'
import BankDetails from '../../Form/CommonForms/User/BankDetails'
import VerifyDetails from '../../Form/CommonForms/User/VerifyDetails'
import StepForm from './StepForm'
import Step4 from './../../Registration/Hosts/Step4'
import { bindActionCreators } from 'redux'
import * as userActionCreators from '../../../actions/user'

class AccountForm extends Component {

  constructor(props) {
    super(props)
  }

  componentWillMount() {
    if(this.props.form == "bank_details"){
      this.props.userActions.getCountryList()
    }
   
  }

  renderForm=(formType,props)=>{

    switch (formType) {
    case "account": return <StepForm />
    case "bank_details": return  <BankDetails {...props} />
    case "professional_details": return  <Step4 {...props} />
    default: return <StepForm />
    }

  }

  render() {
    let userDetails  = this.props.currentUser.attributes
    return (
      <div className="breakbread-container">
        {this.renderForm(this.props.form,this.props)}
        {/* this.props.form == "account" ?
        <StepForm />
      :
        <React.Fragment>
     
      { this.props.form == "bank_details" ?
          <React.Fragment>
            <BankDetails {...this.props} />
              
            </React.Fragment>
            :
            <React.Fragment>
            <VerifyDetails  userDetails={userDetails} />
             
            </React.Fragment>
          }
         </React.Fragment>

        */}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  search: state.router.location.search,
  hash: state.router.location.hash,
  pathname: state.router.location.pathname,
  currentUser: state.reduxTokenAuth.currentUser,
  countryOptions: state.user.countryCode.countryList ? state.user.countryCode.countryList.list : null
})

const mapDispatchToProps = dispatch => ({
  signIn: user => dispatch(signInUser(user)),
  backToPage: () => dispatch(goBack()),
  userActions: bindActionCreators(userActionCreators, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountForm)
