import React from 'react'
import { Field } from 'redux-form'
import PropTypes from 'prop-types'
import renderField from './renderField'

const FieldCollection = ({ fields, wrap, legend }) => (
  <React.Fragment>
    {/*
          NOTE: just because we are using an array of fields here,
          it does not mean its dynamic. Pushing values to this array will not
          render and register new fields. For that, you need to use fieldArrays or register
          the fields manually.
          https://redux-form.com/7.1.2/examples/fieldarrays/
        */}
    {Object.keys(fields).map(key => {
      const field = fields[key]
      if (!field.excludeInSet) {
        const label =
          typeof field.label !== 'undefined' ? field.label : field.placeholder
        return (
          <React.Fragment key={field.name}>
            <Field
              field={field}
              type={field.type}
              name={field.name}
              label={label}
              value={field.value}
              disabled={field.disabled}
              component={renderField}
              displayLabel={field.displayLabel}
            />
          </React.Fragment>
        )
      }
      return ''
    })}
  </React.Fragment>
)
// FieldSet is HOC that can return the wrapped FieldCollection
const FieldSet = props => {
  const { fields, wrap, legend, className } = props
  if (wrap) {
    return (
      <fieldset className={className}>
        <legend>{legend}</legend>
        <FieldCollection {...props} />
      </fieldset>
    )
  }
  return <FieldCollection {...props} />
}

FieldSet.propTypes = {
  fields: PropTypes.shape({}).isRequired
}

export default FieldSet
