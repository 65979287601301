import oldCurrentUser from 'redux-token-auth/dist/reducers/current-user'
// import initialState from 'redux-token-auth/dist/initial-state';
import reduceReducers from 'reduce-reducers'
import { combineReducers } from 'redux'

import { snakeToCamel } from '../utils/camelSnake'

// import { REHYDRATE, PURGE } from 'redux-persist';
import {
  SET_USER_ATTRIBUTES,
  SET_FULL_REGISTRATION_STATE,
  AUTH_FORCE_RESET,
  AUTH_SIGNOUT_SUCCESS,
  AUTH_TOKEN_FAILED,
  AUTH_TOKEN_VERIFIED,
  AUTH_SIGNIN_SUCCESS,
  AUTH_RELOAD_PAGE,
  FETCH_CURRENT_USER_START
} from '../constants/actionTypes'

const initialState = {
  currentUser: {
    registrationStateComplete: false,
    isSignedIn: false,
    isLoading: false,
    hasVerificationBeenAttempted: false,
    attributes: {},
    isRequestFaild: false,
  }
}

const { currentUser: initialUser } = initialState

const extendedCurrentUser = (state = initialUser, action) => {
  switch (action.type) {
  case SET_USER_ATTRIBUTES: {
    // console.log('SET_USER_ATTRIBUTES', action.payload.userAttributes)
    const atts = snakeToCamel(action.payload.userAttributes, 2) // Snake Case Keys (data, depth)
    return {
      ...state,
      attributes: { ...atts },
      isLoading: false
    }
  }
  case FETCH_CURRENT_USER_START:
    return {
      ...state,
      isLoading: true
    }
  case SET_FULL_REGISTRATION_STATE:
    return {
      ...state,
      registrationStateComplete: action
    }

  case AUTH_TOKEN_VERIFIED: {
    const atts = snakeToCamel(action.payload.userAttributes, 2) // Snake Case Keys (data, depth)
    return {
      ...state,
      attributes: { ...atts }
    }
  }

  case AUTH_SIGNIN_SUCCESS: {
    const atts = snakeToCamel(action.payload.userAttributes, 2) // Snake Case Keys (data, depth)
    return {
      ...state,
      attributes: { ...atts },
      isRequestFaild: false
    }
  }
  case AUTH_FORCE_RESET:
    return initialUser

  case AUTH_SIGNOUT_SUCCESS:
    return initialUser

  case AUTH_TOKEN_FAILED:
    return {
      ...state,
      isRequestFaild: true,
      attributes: {}
    }

  case AUTH_RELOAD_PAGE:
    return {
      ...state,
      isRequestFaild: false
    }

  default:
    return state
  }
}

const currentUser = reduceReducers(
  oldCurrentUser,
  extendedCurrentUser,
  initialState.currentUser
)

// Hack to get the keys
const authExtended = combineReducers({
  currentUser
})

export default authExtended
