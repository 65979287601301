import React, { Component } from 'react'
import { Field, reduxForm, change } from 'redux-form'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { currentUserPropTypes } from '../../../../constants/composed-proptypes'

import * as fields from '../../Fields/formFields'
import { sendVerifyEmailLink } from '../../../../actions/forgotPassword'
import api from '../../../../utils/api'

import RenderDateField from '../../Fields/renderDateField'
import Modal from 'react-bootstrap/lib/Modal'


import {
  backgroundFieldCollection,
  personalFieldCollection,
  addressFieldCollection,
  email,
  phoneNumber,
  password,
  passwordConfirmation
} from '../../../../constants/formFields'

import AvatarUpload from '../../Fields/AvatarUpload'
import { getUser } from '../../../../actions/user/getUser'
import '../User/AccountDetails.css'

function keysMatch(key, obj) {
  const keys = Object.keys(obj).map(x => x.toLowerCase())
  return keys.indexOf(key.toLowerCase()) !== -1
}

/* NOTE: if currentUser form values match,they will be updated via
 * the initialValues of connectedStep3 which connects to Redux.
 * this happens automagically for all fields on the redux connected
 * form hostRegistration
 * @see https://redux-form.com/7.0.2/examples/initializefromstate/
 */

class VerifyDetails extends Component {
  constructor(props) {
    super(props)

    if (props.disableFields) {
      this.state = {
        personalFields: this.disableFields(personalFieldCollection),
        backgroundFields: this.disableFields(backgroundFieldCollection),
        addressFields: this.disableFields(addressFieldCollection),
        email: this.disableFields({ email }).email,
        phoneNumber: this.disableFields({ phoneNumber }).phoneNumber,
        maxNumber: '',
        show: false,
        confirmBox: false,
        showEmail: false,
        veriFyEmailsent:false
      }
    } else {
      this.state = {
        personalFields: personalFieldCollection,
        backgroundFields: backgroundFieldCollection,
        addressFields: addressFieldCollection,
        email,
        phoneNumber,
        show: false,
        maxNumber: '',
        confirmBox: false,
        showEmail: false,
        veriFyEmailsent:false
      }
    }
  }

  componentDidMount = async () => {
    let { currentUser } = this.props
    // getUser()

    const avatarPresent =
      typeof currentUser.attributes.avatarUrl !== 'undefined' &&
      currentUser.attributes.avatarUrl.includes('/thumb_default.png')

    if (!this.props.displayCurrency) {
      if (!avatarPresent) {
        this.props.updateAvatarStatus(false)
      } else {
        this.props.updateAvatarStatus(true)
      }
    }
  }

  disableFields = fieldCollection => {
    const { currentUser } = this.props
    if (typeof currentUser === 'undefined' || !currentUser.isSignedIn) {
      return fieldCollection
    }
    const obj = fieldCollection
    const { attributes } = currentUser
    Object.keys(obj).map(key => {
      if (attributes[key] !== null) {
        if (key == 'location') {
        }
        obj[key].disabled = !!keysMatch(key, attributes)
      }
      return obj[key]
    })
    return obj
  };

  handleCountryCode = (e) => {
    let { currentUser, countryOptions } = this.props
    this.props.countryOptions.map(option => {
      if (option.alpha2 == e.value) {
        this.props.change('code_country', option.country_code)
      }
    })
  }

  render() {
    let {
      handleSubmit,
      pristine,
      submitting,
      valid,
      previousPage,
      validate,
      form,
      currentUser,
      countryOptions
    } = this.props

    const {

      email,
      phoneNumber
    } = this.props.userDetails

    //console.log("userdetails", this.props.userDetails);

    return (
      <form onSubmit={handleSubmit} id={form} className="mtt_10">
        <Modal
          show={this.state.show}
          onHide={() => {
            this.setState({ show: false })
          }}
          animation={false}
          bsSize={'small'}
        >
          <Modal.Header closeButton />
          <Modal.Body>
            {this.state.showEmail ? (
              <React.Fragment>
                {this.state.veriFyEmailsent ? (
                  <div>
                    <p>Please check yur email to verify</p>
                  </div>
                ) : (
                  <div className="form-body">
                    <button
                      className="btn"
                      onClick={async () => {
                        let res = await this.props.sendVerifyEmailLink(this.props.userDetails.email)
                        if (res.is_success) {
                          this.setState({ veriFyEmailsent: true })
                        }
                      }}
                    >
                      Verify Email
                    </button>
                  </div>
                )}
              </React.Fragment>
            ) : (
              <React.Fragment>
                {!this.state.confirmBox ? (
                  <React.Fragment>
                    <div>
                      <p> Your mobile '12345' is not verified. Click below to receive an OTP</p>
                    </div>
                    <div className="form-body">
                      <button className="btn minWidth200" onClick={() => this.setState({ confirmBox: true })}>
                        Send OTP
                      </button>
                    </div>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <div>
                      <p> Please enter the OTP you have received</p>
                    </div>
                    <div className="form-body">
                      <p>
                        <input placeholder="Enter OTP" name="verifyOtp" type="text" className="" />
                      </p>
                      <p>
                        <button className="btn" onClick={() => this.setState({ confirmBox: false })}>
                          submit
                        </button>
                      </p>
                    </div>
                  </React.Fragment>
                )}
              </React.Fragment>
            )}
          </Modal.Body>
        </Modal>
        <div className="form-body form-body--full mtt_10 " style={{ marginTop: '5%' }}>
          <div className="grid grid--uniform mb_10 mtt_10 flexCenter">
            <div className="grid__item medium-up--one-third large-up--two-thirds">
              <input disabled name="phoneNumber" type="text" value="" className="fullWidthInput" />
            </div>
            <div className="grid__item  medium-up--one-half large-up--one-third">
              <span
                onClick={() => {
                  this.setState({ show: true, showEmail: false })
                }}
              >
                * verify phone number
              </span>
            </div>
          </div>

          <div className="grid grid--uniform flexCenter">
            <div className="grid__item medium-up--one-third large-up--two-thirds">
              <input name="email" disabled type="text" value={email} className="fullWidthInput" />
            </div>

            <div className="grid__item  medium-up--one-half large-up--one-third">
              <span
                onClick={() => {
                  this.setState({ show: true, showEmail: true })
                }}
              >
                * verify email address
              </span>
            </div>
          </div>
        </div>
      </form>
    )
  }
}


const mapStateToProps = state => ({
  forgotPassword: state.forgotPassword
})

const mapDispatchToProps = dispatch => ({

  verifyToken: state => dispatch(verifyToken(state)),
  sendVerifyEmailLink: state => dispatch(sendVerifyEmailLink(state))

})
export default connect(mapStateToProps, mapDispatchToProps)(VerifyDetails)
//export default ;
