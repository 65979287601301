import {
  SET_RESERVATIONS,
  AUTH_TOKEN_FAILED,
  AUTH_SIGNOUT_SUCCESS
} from '../../constants/actionTypes'

const initialState = []

export default function(state = initialState, action) {
  if (action.type === SET_RESERVATIONS) {
    return [...action.reservations]
  }

  if (action.type === AUTH_SIGNOUT_SUCCESS) {
    return initialState
  }

  if (action.type === AUTH_TOKEN_FAILED) {
    return initialState
  }
  return state
}
