import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import validate from './validate'

import { currentUserPropTypes } from '../../../constants/composed-proptypes'
import { experienceInitialValues } from '../initialValues'

import ExperiencePhotos from '../../Form/CommonForms/Experience/Photos'

const Photos = props => {
  const formTitle = 'Create an Experience'
  return (
    <React.Fragment>
      <ExperiencePhotos
        {...props}
        formTitle={formTitle}
        buttonText="Submit Your Experience"
      />
    </React.Fragment>
  )
}

Photos.propTypes = {
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  valid: PropTypes.bool.isRequired,
  previousPage: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  currentUser: currentUserPropTypes.isRequired,
  // hostingSpacePhotosValues: PropTypes.arrayOf(PropTypes.shape({})).isRequired
}

// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
const reduxFormPhotos = reduxForm({
  form: 'experienceEdit',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  validate
})(Photos)

// You have to connect() to any reducers that you wish to connect to yourself
const connectedFormPhotos = connect(state => ({
  initialValues: experienceInitialValues(state.host.experienceEdit),
  photosValues:
    state.form.experienceEdit && state.form.experienceEdit.values
      ? state.form.experienceEdit.values.photos
      : []
}))(reduxFormPhotos)

export default connectedFormPhotos
