import React, { Component } from 'react'
import { Link } from 'react-router-dom'
// import { Parallax, Background } from 'react-parallax';

export default class ValueProp extends Component {
  componentDidMount() {}

  render() {
    const { image, title, desc } = this.props
    return (
      <div className="break-bread--value-prop">
        <div
          className="avatar avatar--value-prop"
          style={{ backgroundImage: `url(${image})` }}
        />
        <h2 className="h5">{title}</h2>
        <p>{desc}</p>
      </div>
    )
  }
}
