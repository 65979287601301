import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import SEO from '../Shared/Web/SEO'

class LegalTermsCondition extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount = () => {
  }

  render() {
    return (
      <div className="breakbread-container">
        <SEO title="Terms And Conditions" />
        <div className="page-header ">
          <h1> Terms And Conditions </h1>
        </div>
        <div className="page-body grid grid--uniform ">

          <div className="grid__item medium-up--three-twelfths large-up-three-twelfths navbar-collapse collapse displayDesktop">
            <h4> </h4>
            <ul className="list-unstyled customList">
              <li ><Link to="/about-us"> About Us </Link></li>
              <li ><Link to="/terms-and-conditions">Terms &amp; Conditions</Link></li>

              <li ><Link to="/privacy-policy"> Privacy Policy </Link>
              </li>
              <li><Link to="/cookie-policy">Cookie Policy </Link> </li>
            </ul>
          </div>

          <div className="grid__item medium-up--nine-twelfths large-up-nine-twelfths policyPara">

            <div >
              <p ><b><span> GENERAL</span></b></p>

              <p ><span>  Please carefully review
              these Terms of Service (“<b>Terms</b>”) as they contain important information
              about your legal rights, remedies and obligations. By accessing or using BreakBread’s
              Services (defined below), you agree to comply with and be bound by these Terms.</span></p>
              <p ><span>  
              These Terms (together with the documents referred to herein) informs you of the
              terms of use on which you may use and access our Site (defined below) and
              Application(s) (defined below), whether as a guest or a registered user. Use of
              our site includes accessing, browsing or registering to use our sites. By using
              our Services (defined below), you confirm that you accept these Terms and that
              you agree to comply with them. If you do not use these Terms, you must not use
              our Site and Application.</span></p>

              <p ><span>  Thank you for using
              BreakBread!</span></p>

              <p ><b><span>  Introduction</span></b></p>

              <p ><span>  These Terms constitute
              a legally binding agreement ("<b>Agreement</b>") between you and
              BreakBread (as defined below) governing your access to and use of the Break
              Bread website, [www.breakbread.com<span >]</span><span >.</span> including any subdomains thereof, and any
              other websites or applications through which BreakBread makes its services
              available (collectively, "<b>Site</b>"), our mobile, tablet and other
              smart device applications, and application program interfaces (collectively,
              "<b>Application</b>") and all associated services (collectively,
              "<b>BreakBread Services</b>"). The Site, Application and BreakBread
              Services together are hereinafter collectively referred to as the “<b>Platform</b>”.
              Our User Policies {/*found at: [<span >link</span> to  FAQ] */}
              including BreakBread’s [Cancellation Policy / <span >Diner Refund Policy, Nondiscrimination Policy]</span><span >.</span> and other Policies applicable to your use of
              the Platform are incorporated by reference into this Agreement.</span></p>

              <p ><span>  When these Terms
              mention “BreakBread,” “we,” “us,” or “our,” it refers to [<span >BreakBread Holdco</span>], a </span><span>  private
              limited company incorporated under the laws of [<span >BVI
                / Cayman</span>] with a registered office located at [<span >Address</span>], registered under the company registration number [<span >•</span>] at the [<span >Companies
                House</span>].</span></p>

              <p ><span>  Our collection and use
              of personal information in connection with your access to and use of the
              Platform is described in our Privacy Policy.</span></p>

              <p ><span>  [Any and all payment
              processing services through or in connection with your use of the Platform
              ("<b>Payment Services</b>") are provided to you by one or more Break
              Bread Payments entities (individually and collectively, as appropriate, "<b>Break
              Bread Payments</b>") as set out in the Payments Terms of Service
              {/* as detailed in our [link to FAQ page] */}
              ("<b>Payments Terms</b>").]</span></p>

              <p ><span>  All Users, and Hosts in
              particular, are solely responsible for identifying, understanding, and
              complying with all laws, rules and regulations that apply to their Invitations
              and Experiences (as defined below). For example, some jurisdictions have
              regulations that restrict their ability to host paying Diners for short periods
              or provide certain Experiences. In many cities, Hosts may have to register, get
              a permit or obtain a license before providing certain Experiences (such as
              serving alcohol whether or not for sale; preparing, serving and labelling food;
              or using certain venues for particular activities). Host are solely responsible
              for identifying and obtaining any required licenses, permits, or registrations
              for any Experiences they offer. Certain types of Experiences may be prohibited
              altogether. Penalties may include fines or other enforcement.
              {/* We provide some information in our [<span >FAQ page</span>] to help
              you identify some of the obligations that apply to you. */}
              If you have questions
              about how local laws apply to your Invitation(s) and Host Service(s) on Break
              Bread, you should always seek independent legal guidance.</span></p>

              <p ><b><span>  Table of Contents</span></b></p>
              <ol>
                <li>
                  <p >Scope of BreakBread Services</p>
                </li>
                <li>
                  <p >Eligibility, Using the Platform, User Verification</p>
                </li>
                <li>
                  <p >Modification of these Terms</p>
                </li>
                <li>
                  <p >Account Registration</p>
                </li>
                <li>
                  <p >Content</p>
                </li>
                <li>
                  <p >Service Fees</p>
                </li>
                <li>
                  <p >Terms specific for Hosts</p>
                </li>
                <li>
                  <p >Terms specific for Diners</p>
                </li>
                <li>
                  <p >Booking Modifications, Cancellations and Refunds, Resolution Center</p>
                </li>
                <li>
                  <p >Ratings and Reviews</p>
                </li>
                <li>
                  <p >Damage to Experience Venues, Disputes between Users</p>
                </li>
                <li>
                  <p >Rounding off, Currency conversion</p>
                </li>
                <li>
                  <p >Taxes</p>
                </li>
                <li>
                  <p >Prohibited Activities</p>
                </li>
                <li>
                  <p >Term and Termination, Suspension and other Measures</p>
                </li>
                <li>
                  <p >Disclaimers</p>
                </li>
                <li>
                  <p >Liability</p>
                </li>
                <li>
                  <p >Indemnification</p>
                </li>
                <li>
                  <p >Dispute Resolution</p>
                </li>
                <li>
                  <p >Feedback</p>
                </li>
                <li>
                  <p >Applicable Law and Jurisdiction</p>
                </li>
              </ol>
              
              <p ><b><span >General Provisions</span></b></p>

              <p ><b><span>  1. Scope of BreakBread
                Services</span></b></p>

              <p ><span>  1.1</span><span>  The Platform is an
              online marketplace that enables registered users (“<b>Users</b>”) and certain
              third parties to create invitations for other Users to participate and share in
              meals and dining experiences at specified venues (Users and third parties who
              create Invitations are “<b>Hosts</b>” and the meals and dining experiences they
              invite other Users to participate in are “<b>Experiences</b>”, the venues where
              these Experiences are conducted are “<b>Experience Venues</b>”), to publish
              such invitations to participate in Experiences on the Platform (“<b>Invitations</b>”)
              and to communicate and transact directly with Users that are seeking to book
              such Experiences (Users participating in such Experiences are “<b>Diners</b>”).
              </span></p>
              
              <p ><span>  1.2</span><span>  As the provider of the
              Platform, BreakBread does not own, create, sell, resell, provide, control,
              manage, offer, deliver, or supply any Invitations or Experiences, nor is Break
              Bread an organizer or retailer of Experiences. Hosts alone are responsible for
              their Invitations and Experiences. When Users make or accept a booking, they
              are entering into a contract directly with each other. BreakBread is not and
              does not become a party to or other participant in any contractual relationship
              between Users, nor is BreakBread an employment agent, food retailer,
              wholesaler, manufacturer or caterer. BreakBread is not acting as an agent in
              any capacity for any User.</span></p>
              
              <p ><span>  1.3</span><span>  While we may facilitate
              dispute resolution between Users, BreakBread has no control over and does not
              guarantee (i) the existence, quality, safety, suitability, or legality of any
              Invitations or Experiences, (ii) the truth or accuracy of any Invitation
              descriptions, Ratings, Reviews, or other User Content (as defined below), or
              (iii) the performance or conduct of any User or third party. BreakBread does
              not endorse any User, Invitation or Experience. Any references to a User being
              "verified", “vetted” or any other similar language only indicates
              that the User has completed relevant verification or identification process and
              nothing else. Any such description is not an endorsement, certification or
              guarantee by BreakBread about any User, including of the User’s identity or
              background or whether the User is trustworthy, safe or suitable. Users should
              always exercise due diligence and care when deciding whether to participate in
              an Experience, accept a booking request from a Diner, or communicate and
              interact with other Users, whether online or in person. Verified Images (as
              defined below) are intended only to indicate a photographic representation of
              the Experience at the time the photograph was taken and are therefore not an
              endorsement by BreakBread of any Host or Invitation. </span></p>

              <p ><span>  1.4</span><span>  If you choose to use
              the Platform as a Host, your relationship with BreakBread is limited to being
              an independent, third-party contractor, and not an employee, agent, joint
              venturer or partner of BreakBread for any reason, and you act exclusively on
              your own behalf and for your own benefit, and not on behalf, or for the
              benefit, of BreakBread. BreakBread does not, and shall not be deemed to,
              direct or control you generally or in your performance under these Terms
              specifically, including in connection with your provision of the Experiences.
              You acknowledge and agree that you have complete discretion whether to list Invitations
              and Experiences or otherwise engage in other business or employment activities.</span></p>
              
              <p ><span>  1.5</span><span>  To promote the Platform
              and to increase the exposure of Invitations to potential Diners, Invitations
              and other User Content may be displayed on other websites, in applications,
              within emails, and in online and offline advertisements. To assist Users who
              speak different languages, Invitations and other User Content may be
              translated, in whole or in part, into other languages. BreakBread cannot
              guarantee the accuracy or quality of such translations and Users are
              responsible for reviewing and verifying the accuracy of such translations. The
              Platform may contain translations powered by Google or other third parties and
              we hereby disclaim all warranties related to the translations, express or
              implied, including any warranties of accuracy, reliability, and any implied
              warranties for merchantability, fitness for a particular purpose and
              non-infringement.</span></p>
              
              <p ><span>  1.6</span><span>  The Platform may
              contain links to third-party websites or resources (“<b>Third-Party Services</b>”)
              that are subject to different terms and conditions and privacy practices. Break
              Bread is not responsible or liable for the availability or accuracy of such
              Third-Party Services, or the content, products, or services available from such
              Third-Party Services. Links to such Third-Party Services are not an endorsement
              by BreakBread of such Third-Party Services.</span></p>
              
              <p ><span>  1.7</span><span>  Due to the nature of
              the Internet and telephony services, BreakBread cannot guarantee the
              continuous and uninterrupted availability and accessibility of the Platform.
              BreakBread may restrict the availability of the Platform or certain areas or
              features thereof, if this is necessary in view of capacity limits, the security
              or integrity of our servers, or to carry out maintenance measures that ensure
              the proper or improved functioning of the Platform. BreakBread may improve,
              enhance and modify the Platform and introduce new BreakBread Services from
              time to time.</span></p>
              
              
              
              <p ><b><span>  2. Eligibility, Using
                the Platform, User Verification</span></b></p>
              
              <p ><span>  2.1</span><span>  In order to access and
              use the Platform or register a User Account, you must be an individual of at
              least 18 years of age or such other higher age of majority in accordance with
              your country of residence and able to enter into legally binding contracts. 
                
              </span></p>
              <p ><span>  2.2</span><span>  BreakBread may make
              access to and use of the Platform by Users, or certain areas or features of the
              Platform, subject to certain conditions or requirements, such as completing a
              verification process, meeting specific quality or eligibility criteria, meeting
              Ratings or Reviews thresholds, or a User’s booking and cancellation
              history. 
                
              </span></p>
              <p ><span>  2.3</span><span>  User verification on
              the Internet is difficult and we do not assume any responsibility for the
              confirmation of any User’s identity. Notwithstanding the above, for
              transparency and fraud prevention purposes, and as permitted by applicable
              laws, we may, but have no obligation to (i) ask Users to provide a form of
              government identification or other information or undertake additional checks designed
              to help verify the identities or backgrounds of Users, (ii) screen Users
              against third party databases or other sources and request reports from service
              providers, and (iii) where we have sufficient information to identify a User,
              obtain reports from public records of criminal convictions or sex offender
              registrations or an equivalent version of background or registered sex offender
              checks in your local jurisdiction (if available).
                
              </span></p>
              <p ><span>  2.4</span><b><span>  Specific policies</span></b><span>  . The access
              to or use of certain areas and features of the Platform may be subject to
              separate policies, standards or guidelines, or may require that you accept
              additional terms and conditions, before you can access the relevant areas or
              features of the Platform. If there is a conflict between these Terms and terms
              and conditions applicable to a specific area or feature of the Platform, the
              latter terms and conditions will take precedence with respect to your access to
              or use of that area or feature, unless specified otherwise in the latter terms and
              conditions.</span></p>
              
              <p ><span>  2.5</span><span>  Where applicable,
              should you access or download the Application from the Apple App Store, you
              agree to Apple’s Licensed Application End User License Agreement. Some areas of
              the Platform may implement Google Maps/Earth mapping services, including Google
              Maps API(s). Your use of Google Maps/Earth is subject to the Google Maps/Google
              Earth Additional Terms of Service.</span></p>

              <p ><b><span>  3. Modification of
                these Terms</span></b></p>

              <p ><span>  BreakBread reserves
              the right to modify these Terms at any time at its sole discretion. If we make
              changes to these Terms, [we will post the revised Terms on the Platform and
              update the “Last Updated” date at the top of these Terms. We will also provide
              you with notice of the modifications by email at least thirty (30) days before
              the date they become effective.] If you disagree with the revised Terms, you
              may terminate this Agreement with immediate effect, and you must stop using the
              Platform. If you do not terminate your Agreement before the date the revised
              Terms become effective, your continued access to or use of the Platform will
              constitute acceptance of the revised Terms.</span></p>
              

              <p ><b><span>  4. Account Registration</span></b></p>

              <p ><span>  4.1</span><span>  You must register an
              account ("<b>User Account</b>") to access and use certain features of
              the Platform, such as publishing or booking an Invitation. If you are
              registering a User Account for a business, organization or other legal entity,
              you represent and warrant that you have the authority to legally bind that
              entity and grant us all permissions and licenses provided in these Terms.
                
              </span></p>
              <p ><span>  4.2</span><span>  You can register a User
              Account using an email address and creating a password, or through your account
              with certain third-party social networking services, such as Google or Facebook
              ("<b>SNS Account</b>"). You have the ability to disable the
              connection between your User Account and your SNS Account at any time, by
              accessing the "Settings" section of the Platform.</span></p>
              
              <p ><span>  4.3</span><span>  You must provide
              accurate, current and complete information during the registration process and
              keep your User Account and public User Account profile page information up-to-date
              at all times. BreakBread may from time to time request for identification
              documents or perform any background verification and Users shall ensure that
              all information supplied to BreakBread is accurate, current and complete.</span></p>
              
              <p ><span>  4.4</span><span>  You may not register
              more than one (1) User Account unless BreakBread expressly authorizes you to
              do so. You may not assign or otherwise transfer your User Account to another
              party.</span></p>
              
              <p ><span>  4.5</span><span>  You are responsible for
              maintaining the confidentiality and security of your User Account credentials
              and must not disclose your credentials to any third party. You must immediately
              notify BreakBread if you know or have any reason to suspect that your
              credentials have been lost, stolen, misappropriated, or otherwise compromised
              in any way or in case of any actual or suspected unauthorized use of your User
              Account. You are liable for any and all activities conducted through your User
              Account.</span></p>
              
              <p ><span>  4.6</span><span>  BreakBread may enable
              features that allow you to authorize other Users or certain third parties to
              take certain actions that affect your User Account. For example, we may enable
              eligible Users or certain third parties to book Invitations on behalf of other
              Users, or where applicable, we may enable Hosts to add other Users as co-hosts
              to help manage their Invitations]<span >.</span>. These
              features do not require that you share your credentials with any other person.
              No third party is authorized by BreakBread to ask for your credentials, and
              you shall not request the credentials of another User.</span></p>
              
              <p ><span>  4.7</span><b><span>  Compliance with laws and
                regulations</span></b><span>  . You represent and warrant that by signing up as a User,
              agreeing to these Terms or by using the BreakBread platform, you shall:</span></p>
              
              <p  ><span>  a.</span><span>  not breach
              or result in the breach of any agreements you have entered into with any third
              parties, or any rules or regulations that you are bound by, such as applicable
              rules of any body corporate that manages the use of the common property, any
              homeowners association, any building management statement, or other
              agreements, laws or regulations; and</span></p>
              
              <p  ><span>  b.</span><span>  comply with
              all applicable local laws and regulations (including zoning laws, tax
              requirements, liquor licensing and consumption rules, health and safety
              regulations, and food safety regulations) and you shall where necessary have
              obtained all required permits, licenses and registrations.</span></p>


              <p ><b><span>  5. Content</span></b></p>

              <p ><span>  5.1</span><span>  BreakBread may, at its
              sole discretion, enable Users to (a) create, upload, post, send, receive and
              store content, such as text, photos, audio, video, or other materials and
              information on or through the Platform ("<b>User Content</b>"); and
              (b) access and view User Content and any content that BreakBread itself makes
              available on or through the Platform, including proprietary BreakBread content
              and any content licensed or authorized for use by or through BreakBread from a
              third party ("<b>BreakBread Content</b>" and together with User
              Content, "<b>Collective Content</b>"). BreakBread has the absolute
              discretion to edit, modify or delete any User Content.</span></p>
              
              <p ><span>  5.2</span><span>  The Platform, Break
              Bread Content and User Content may in its entirety or in part be protected by
              copyright, trademark, and/or other laws and regulations. You acknowledge and
              agree that the Platform and BreakBread Content, including all associated
              intellectual property rights, are the exclusive property of BreakBread and/or
              its licensors or authorizing third-parties. You will not remove, alter or
              obscure any copyright, trademark, service mark or other proprietary rights
              notices incorporated in or accompanying the Platform, BreakBread Content or
              User Content. All trademarks, service marks, logos, trade names, and any other
              source identifiers of BreakBread used on or in connection with the Platform
              and BreakBread Content are trademarks and property of BreakBread. Trademarks,
              service marks, logos, trade names and any other proprietary designations of
              third parties used on or in connection with the Platform, BreakBread Content,
              and/or Collective Content are used for identification purposes only and may be
              the property of their respective owners.</span></p>

              <p ><span>  5.3</span><span>  You will not use, copy,
              adapt, modify, prepare derivative works of, distribute, license, sell,
              transfer, publicly display, publicly perform, transmit, broadcast or otherwise
              exploit the Platform or Collective Content, except to the extent you are the
              legal owner of certain User Content or as expressly permitted in these Terms.
              No licenses or rights are granted to you by implication or otherwise under any
              intellectual property rights owned or controlled by BreakBread or its
              licensors, except for the licenses and rights expressly granted in these Terms.</span></p>
              
              <p ><span>  5.4</span><span>  Subject to your
              compliance with these Terms, BreakBread grants you a limited, non-exclusive,
              non-sublicensable, revocable, non-transferable license to (a) download and use
              the Application on your personal device(s); and (b) access and view any Collective
              Content made available on or through the Platform and accessible to you, solely
              for your personal and non-commercial use.</span></p>
              
              <p ><span>  5.5</span><span>  By creating, uploading,
              posting, sending, receiving, storing, or otherwise making available any User
              Content on or through the Platform, you grant to BreakBread a non-exclusive,
              worldwide, royalty-free, irrevocable, perpetual (or for the term of the
              protection), sub-licensable and transferable license to such User Content to
              access, use, store, copy, modify, prepare derivative works of, distribute,
              publish, transmit, stream, broadcast, and otherwise exploit in any manner such
              User Content to provide and/or promote the Platform, in any media or platform.
              Unless you provide specific consent, BreakBread does not claim any ownership
              rights in any User Content and nothing in these Terms will be deemed to
              restrict any rights that you may have to use or exploit your User Content.</span></p>
              
              <p ><span>  5.6</span><span>  BreakBread may offer
              Hosts the option of having professional photographers take photographs of their
              Experiences, which are made available by the photographer to Hosts to include
              in their Invitations with or without a watermark or tag bearing the words
              ["BreakBread.com Verified Photo"] or similar wording ("Verified
              Images") and additional third party fees may be payable. You are
              responsible for ensuring that your Experience is accurately represented in the
              Verified Images and you will stop using the Verified Images on or through the
              Platform if they no longer accurately represent your Experience, if you stop
              hosting the Experience featured, or if your User Account is terminated or
              suspended for any reason. You acknowledge
                and agree that BreakBread shall have the right to use any Verified Images in
                accordance with Section 5.5 for advertising, marketing and/or any other
                business purposes in any media or platform, whether in relation to your
                Invitation or otherwise, without further notice or compensation to you.
              Where BreakBread is not the exclusive owner of Verified Images, by using such
              Verified Images on or through the Platform, you grant to BreakBread an
              exclusive, worldwide, royalty-free, irrevocable, perpetual (or for the term of
              the protection), sub-licensable and transferable license to use such Verified
              Images for advertising, marketing and/or any other business purposes in any
              media or platform, whether in relation to your Invitation or otherwise, without
              further notice or compensation to you. BreakBread in turn grants you a
              limited, non-exclusive, non-sublicensable, revocable, non-transferable license
              to use Verified Images outside of the Platform solely for your personal and
              non-commercial use.</span></p>
              
              <p ><span>  5.7</span><span>  You are solely
              responsible for all User Content that you make available on or through the
              Platform. Accordingly, you represent and warrant that: (a) you either are the
              sole and exclusive owner of all User Content that you make available on or
              through the Platform or you have all rights, licenses, consents and releases
              that are necessary to grant to BreakBread the rights in and to such User
              Content, as contemplated under these Terms; and (b) neither the User Content
              nor your posting, uploading, publication, submission or transmittal of the User
              Content or BreakBread's use of the User Content (or any portion thereof) as
              contemplated under these Terms will infringe, misappropriate or violate a third
              party's patent, copyright, trademark, trade secret, moral rights or other
              proprietary or intellectual property rights, or rights of publicity or privacy,
              or result in the violation of any applicable law or regulation.</span></p>
              
              <p ><span>  5.8</span><span>  You will not post,
              upload, publish, submit or transmit any User Content that: (a) is fraudulent,
              false, misleading (directly or by omission or failure to update information) or
              deceptive; (b) is defamatory, libelous, obscene, pornographic, vulgar or offensive;
              (c) promotes discrimination, bigotry, racism, hatred, harassment or harm
              against any individual or group; (d) is violent or threatening or promotes
              violence or actions that are threatening to any other person or animal; (e)
              promotes illegal or harmful activities or substances; or (f) violates Break
              Bread’s Content Policy or any other BreakBread policy. BreakBread may,
              without prior notice, remove or disable access to any User Content that Break
              Bread determines to be in violation of applicable law, these Terms or Break
              Bread’s then-current Policies or Standards, or otherwise may be harmful or
              objectionable to BreakBread, its Users, third parties, or property.</span></p>
              
              <p ><span>  5.9</span><span>  BreakBread respects
              copyright law and expects its Users to do the same. If you believe that any
              content on the Platform infringes copyrights you own, please notify us at
              [support@breakbread.com].</span></p>
              
              <p ><b><span>  6. Service Fees</span></b></p>

              <p ><span>  6.1</span><span>  In consideration for
              the use of the Platform, BreakBread may charge fees to Hosts ("<b>Host
              Fees</b>") and/or Diners ("<b>Diner Fees</b>") (collectively,
              "<b>Service Fees</b>").
              {/* More information about when Service Fees
              apply and how they are calculated can be found on our Service Fees page at
              [link to FAQ page]. */}
                
              </span></p>
              <p ><span>  6.2</span><span>  [Any applicable Service
              Fees (including any applicable Taxes) will be displayed to a Host or Diner
              prior to publishing or booking an Invitation.] BreakBread reserves the right
              to change the Service Fees at any time [and will provide Users adequate notice
              of any fee changes before they become effective. Such fee changes will not
              affect any bookings made prior to the effective date of the fee change.]
                
              </span></p>
              <p ><span>  6.3</span><span>  You are
              responsible for paying any Service Fees that you owe to BreakBread. The
              applicable Service Fees (including any applicable Taxes) are collected by [<span >BreakBread Payments / BreakBread UkCo</span>].
              BreakBread Payments will deduct any Host Fees from the <span >Invitation Fee</span> before remitting the payout to the Host. Except
              as otherwise provided on the Platform, Service Fees are non-refundable.</span></p>

              <p ><b><span >7. Terms specific for Hosts</span></b></p>

              <p ><b><span>  7.1</span></b><b><span>  Terms
                applicable to all Invitations</span></b></p>
              <p ><b><span>  </span></b></p>
              <p ><span>  7.1.1.</span><span>  When
              creating an Invitation through the Platform you must:</span></p>
              
              <ol type="i">
                <li>
                  <p >provide complete, precise and accurate information about your Experience (such as Invitation description, location, date, timing and duration of the Experience, calendar availability, format of the meal, menu, ingredients used in the meal, likelihood of the presence of animals in the hosting venue, maximum number of Diners who may participate in an Experience);</p>
                </li>
                <li>
                  <p >disclose any deficiencies, restrictions (such as house rules) and requirements that apply (such as any minimum age, language proficiency, accessibility or fitness requirements for an Experience); and</p>
                </li>
                <li>
                  <p >provide any other pertinent information requested by BreakBread.</p>
                </li>
              </ol>
        
              
              <p ><span>  7.1.2.</span><span>  Hosts are solely
              responsible for setting the compensation sum due from each Diner (including any
              Taxes if applicable, or charges such as cleaning fees) relating to each
              Invitation (“<b>Invitation Fee</b>”) that shall be charged to the respective
              Diner(s), in respect of the time spent and ingredients purchased to prepare the
              Experience. Once a Diner requests a booking of a Host’s Invitation, a Host may
              not request that the Diner pays a higher amount than what is specified in the
              booking request relating to that Invitation.</span></p>
              
              <p ><span>  7.1.3</span><span>  Any terms and
              conditions included in your Invitation, in particular in relation to
              cancellations and refunds (where applicable), must not conflict with these
              Terms or the relevant cancellation policy for your Invitation. </span></p>
              
              <p ><span>  7.1.4.</span><b><span>  Accepting a booking
                request for an Invitation.</span></b><span>   Unless a Host has allowed for an Invitation to
                be instantly booked by a Diner, Hosts must accept or decline any booking
                request submitted by a Diner in relation to an Invitation within 72 hours or
              such a booking request shall automatically lapse.</span></p>
              
              <p ><span>  7.1.5.</span><span>  In any event, once a
              booking request relating to an Invitation has been accepted by a Host, an
              Experience shall not be cancelled by the Host except in accordance with the
              Cancellation Policy or a cancellation fee (“<b>Cancellation Fee</b>”) may be
              imposed. [If a Host must cancel an Experience, Hosts agree to pay a
              Cancellation Fee to compensate Diners and BreakBread
              </span></p>
              <p ><span>  7.1.6.</span><span>  Any videos, pictures,
              animations or illustrations (collectively, "<b>Images</b>") used in
              your Invitations must accurately reflect the condition and quality of your
              Experiences. BreakBread reserves the right to require that Invitations have a
              minimum number of Images of a certain specifications, format, size and resolution.</span></p>
              
              <p ><span>  7.1.7.</span><span>  The placement and
              ranking of Invitations in search results on the Platform may vary and depend on
              various factors, such as Diner search parameters and preferences, Host
              requirements, price and calendar availability, location, number and quality of
              Images, customer service and cancellation history, Reviews and Ratings, type of
              Host Service, and/or ease of booking.
              {/* [<span >More
              information about the factors that determine how your Invitation appears in
              search results can be found at [link to FAQ].] </span> */}
              </span></p>
              
              <p ><span>  7.1.8.</span><span>  When you accept or have
              pre-approved a booking request by a Diner, you are entering into a legally
              binding agreement with the Diner upon the terms contained in the Invitation and
              are required to provide your Host Experience to the Diner(s) as described in
              your Invitation when the booking request is made.</span></p>
              
              <p ><span>  7.1.9.</span><span>  For the avoidance of
              doubt,[ Host Fees shall include any applicable taxes and] Hosts agree to pay
              BreakBread the applicable Host Fee relating to each confirmed Invitation. The
              balance due after the Host Fees are deducted from the Invitation Fees shall be
              paid to the Host after successful completion of the Experience as long as any
              Diner does not complain to BreakBread that the Experience was not completed in
              accordance with the terms of the Invitation. </span></p>
              
              <p ><span>  7.1.10.</span><span>  Hosts shall obtain
              appropriate insurance for their Experiences. Please review any respective
              insurance policy carefully, and in particular make sure that you are familiar
              with and understand any exclusions to, and any deductibles that may apply for,
              such insurance policy including but not limited to whether or not your
              insurance policy will cover situations where Diners may suffer from harm (e.g.
              food poisoning) or where Hosts suffer from harm through the actions or
              inactions of Diners (and the individuals the Diner has booked for, if
              applicable) while participating in your Experience.</span></p>
              
              <p ><span>  7.1.11.</span><span>  [BreakBread may, but is not obliged to, obtain third party liability
              insurance for which Hosts may have to pay additional fees for separately.]</span></p>
              
              <p ><span>  7.1.12.</span><b><span>  Compliance with laws
                and regulations</span></b><span>  . You represent and warrant that any Invitation you post
              and the booking of an Experience shall:</span></p> 
              
          
              <ol type="i">
                <li>
                  <p >not breach or result in the breach of any agreements you have entered into with any third parties or are bound by, such as homeowners association, condominium, or other agreements; and</p>
                </li>
           
                <li>
                  <p >comply with all applicable laws and regulations (including zoning laws, tax requirements, liquor licensing and consumption rules, health and safety regulations, and food safety regulations) and you shall where necessary have obtained all required permits, licenses and registrations.</p>
                </li>
              </ol>
          
        
              
              <p ><span>  7.1.13.</span><span>  As a Host, you must
              guarantee the safety of Diners in the premises where the Experience is
              conducted. You are responsible for your own acts and omissions and are also
              responsible for the acts and omissions of any individuals who reside at or are
              otherwise present at the venue where the Experience is conducted, excluding the
              Diner and any individuals the Diner invites to the Experience.</span></p>

              <p ><b><span>  7.2 Specific
                Obligations and Payments to Hosts</span></b></p>

              <p  ><span>  7.2.1.</span><span>  Hosts agree
              not to draw a professional income from using BreakBread’s Website, Application
              and Services. All payments received by Hosts from Diners are strictly
              compensation for the time and expense incurred in preparation of the
              Experience. </span></p>
              
              <p  ><span>  7.2.2.</span><span>  Hosts undertake
              not post Invitations and organize Experiences on a regular basis so as not to
              be considered to be conducting any trade by the relevant authorities. If Hosts
              are regarded as professional chefs or conducting any other trade by the
              relevant authorities, Hosts shall comply with applicable regulations and
              account to the authorities independently for any taxes payable. Should Break
              Bread be liable to account to any applicable authority for any taxes or other
              payments due for and on behalf of Host(s), you hereby authorize BreakBread to
              withhold payments due to you to satisfy these obligations.</span></p>
              
              <p  ><span>  7.2.3.</span><span>  Hosts acknowledge and agree:</span></p>

              <ol type="i">
                <li>
                  <p >to guarantee the absolute safety of Diners with regard to the hygiene, quality and conditions of the food and drink provided during the Experience;</p>
                </li>
                <li>
                  <p >to inform Diners of [any accessibility or other requirements for the Experience] with ample notice;</p>
                </li>
                <li>
                  <p >respect and abide by all applicable laws, rules and regulations imposed by the relevant authorities whether these are fiscal, administrative, quasi-governmental or otherwise;</p>
                </li>
                <li>
                  <p >not to offer, propose or serve any alcohol to Diners except in accordance with applicable laws and regulations;</p>
                </li>
                <li>
                  <p >not to charge Diners any fees for alcoholic beverages, or charge any other kind of consideration-in-kind for such alcoholic beverages;</p>
                </li>
                <li>
                  <p >that their use of the Platform does not contravene or infringe any laws, regulations, agreements or third party rights;</p>
                </li>
                <li>
                  <p >to provide us with accurate and updated bank account details at all times; and</p>
                </li>
                <li>
                  <p >to accept any and all risks associated with being a Host, organizing an Experience and/or using BreakBread’s services and that BreakBread shall not be liable for any harm or damage howsoever arising. Hosts warrant that they are have adequate public liability insurance in this regard.</p>
                </li>
              </ol>
              <p ><b></b></p>
              <p ><b><span >8. Terms specific to Diners</span></b></p>

              <p ><b><span>  8.1 Terms applicable to
                all bookings</span></b></p>

              <p  ><span>  8.1.1.<span >
              </span></span><span>  Subject
              to meeting any requirements (such as completing any identity verification
              processes or credit card and payment requirements) set by BreakBread and/or
              the Host, you can book an Invitation available on the Platform by following the
              respective booking process. All applicable fees, including the <span >Invitation Fee, deposit (if applicable), <s>Diner Fee</s>
                and any applicable taxes (collectively, “<b>Total Fees</b>”)</span> will be
              presented to you prior to booking an Invitation. You agree to pay the Total
              Fees for any booking requested in connection with your User Account.</span></p>
              
              <p  ><span>  8.1.2.<span >
              </span></span><span>  Break
              Bread uses a third party (Stripe) as its payment processer who in turn may or
              may not use various providers, each provider controlling the terms applicable
              to its own payment processes. You acknowledge and agree to be bound by the
              terms and conditions of such third party payment processer which can be found
              at <span >Terms and Conditions of Stripe</span>),
              which are subject to change at any time, and it is your responsibility to
              periodically review the terms and relevant documentation in order to ensure
              that you are aware of, and comply with, the applicable requirements.</span></p>
              
              <p  ><span>  8.1.3.<span >
              </span></span><span>  Upon
              confirmation of an Invitation booking, a <span >legally
                binding agreement is formed between you and your Host</span>, subject to any
              additional terms and conditions of the Host that apply, including in particular
              the applicable cancellation policy and any rules and restrictions specified in
              the Invitation. BreakBread Payments will collect the Total Fees upon confirmation
              of the Invitation acceptance or upon successful completion of the Experience. </span></p>
              
              <p  ><span>  8.1.4.<span >
              </span></span><span>  [Once
              an Invitation is accepted, <span >confirmed
              Experiences shall not be cancelled less than 48 hours from the commencement of
              the Experience or cancellation fees determined at BreakBread’s sole discretion may apply</span>.
              {/* Further details of our Cancellation Policy can be found at [link to FAQ page].] */}
              </span></p>
              
              <p  ><span>  8.1.5.<span >
              </span></span><span>  [<span >If you book a Host Service on behalf of additional
              Diners, you are required to ensure that every additional Diner meets any
              requirements set by the Host, and is made aware of and agrees to these Terms
              and any terms and conditions, rules and restrictions set by the Host. You are
                not permitted to make a booking for an additional Diner who is a minor</span>.]</span></p>
              
              
              <p  ><b><span>  8.2
                Experience Venues</span></b></p>
              <p  ><b><span>  </span></b></p>
              <p  ><span>  8.2.1.<span >
              </span></span><span>  You
              understand that a confirmed booking of an Experience is a limited license
              granted to you by the Host to enter, occupy and use the relevant premises (“<b>Experience
                Venue</b>”) for the duration of your Experience in accordance with the terms of
              the Invitation.</span></p>
              
              <p  ><span>  8.2.2.<span >
              </span></span><span>  You
              agree to leave the Experience Venue no later than the 1 hour from the time of
              conclusion of the Experience or such time that the Host specifies in the
              Invitation, whichever is the [<span >earlier/ later</span>].
              If you stay past the agreed upon time without the Host's consent (“<b>Overstay</b>”),
              you no longer have a license to enter and occupy the Experience Venue and the
              Host is entitled to make you leave in a manner consistent with applicable law.
              In addition, you agree to pay, if requested by the Host, for each twenty-four
              (24) hour period (or any portion thereof) that you Overstay, an additional fee
              of up to five (5) times the Invitation Fee payable by you to cover the
              inconvenience suffered by the Host, plus all applicable Diner Fees, taxes, and
              any legal or administrative expenses incurred by the Host to make you leave
              (collectively, "<b>Overstay Fees</b>"). If you Overstay at an
              Experience Venue, you authorize BreakBread to charge and collect Overstay Fees
              from you. </span></p>

              <p ><b><span>  8.3 Specific
                Obligations for Diners</span></b></p>

              <p  ><span>  8.3.1.<span >
              </span></span><span>  You
              should carefully review the description of any Invitation and Experience you
              intend to book to ensure you (and any additional Diners you are booking for)
              meet any minimum age, proficiency, accessibility, fitness or other requirements
              which the Host has specified in their Invitation. You shall inform the Host of
              any dietary, medical or physical conditions, or other circumstances that may
              impact your and any additional Diner’s ability to participate in any
              Experience. You acknowledge that Hosts may not be able to cater to any special
              requirements even if you have informed Hosts of such requirements before
              commencement of the Experience.</span></p>
              
              <p  ><span>  8.3.2.<span >
              </span></span><span>  In
              addition, certain regulations like the minimum legal drinking age in the
              location of the Experience, may also apply. You are responsible for
              identifying, understanding, and complying with all laws, rules and regulations
              that apply to your participation in an Experience.</span></p>
              
              <p  ><span>  8.3.3.<span >
              </span></span><span>  Before
              and during an Experience, you must at all times adhere to the Hosts’
              instructions to ensure your safety and wellbeing.</span></p>
              
              <p  ><span>  8.3.4.<span >
              </span></span><span>  You
              shall not bring any additional individuals to an Experience unless such an
              individual was added by you as an additional Diner during the booking process
              on the Platform and the Host has been informed in advance.</span></p>

              <p  ><span>  8.3.5.<span >
              </span></span><span>  Diners acknowledge and agree:</span></p>
              
             
              <ol type="i">
                <li>
                  <p >to give the Host ample and exhaustive notice of any allergies or dietary restrictions and to make the necessary enquiries to ensure that any food and drink offered, or any part of the Experience is safe for their own consumption;</p>
                </li>
               
                <li>
                  <p >to assume and accept any and all risk and responsibility associated with being a Guest, participating in an Experience and occupying the Experience Venue, including but not limited to the risk that other Diners or the Host may: damage your person or property, be sick and infect you; divulge and use personal information communicated to them by you (such as your name), and that BreakBread shall not be liable for any damages under any circumstances;</p>
                </li>
               
                <li>
                  <p >avoid and cancel your Invitation booking in the event that you or any of your fellow Diners are sick or ill, or have reason to suspect that there is a risk of infecting your Host and/or other Guests;</p>
                </li>
                <li>
                  <p >to obtain the necessary personal insurance for yourself and the other Diners you have booked for to participate in the Experience;</p>
                </li>
                <li>
                  <p >that the photographs and description provided by the Host or in an Invitation may not accurately represent the food, drink or Experience that you will be offered;</p>
                </li>
                <li>
                  <p >not to book any Invitations if they have any remaining doubts or questions after verifying details of an Invitation;</p>
                </li>
                <li>
                  <p >to respect the Host and other Diners and participants in the Experience and to treat other individuals in a non-discriminatory manner;</p>
                </li>
                <li>
                  <p >to respect the neighbors and other members of the household at the Experience Venue by not causing a nuisance, excessive noise or otherwise;</p>
                </li>
                <li>
                  <p >to respect all household rules imposed by the Host and to protect any animals and the property belonging to other individuals at the Experience Venue;</p>
                </li>
                <li>
                  <p >do all things reasonably necessary to facilitate an enjoyable Experience for others;</p>
                </li>
                <li>
                  <p >that in the event that you should accept an Experience booking without having obtained prior confirmation from your Host, or in the knowledge of the composition of the meal offered or of one or several of these foods or drinks, then the Invitation Fee and all other applicable fees and taxes shall remain payable. You shall not ask for a dish to be replaced and shall consume the food and drinks offered at your own risk.</p>
                </li>
              </ol>
              <p ><b><span>  9. Booking
                Modifications, Cancellations and Refunds, Resolution Center</span></b></p>

              <p  ><span>  9.1.<span >
              </span></span><span>  Hosts
              and Diners are responsible for any modifications to a booking that they make
              via the Platform or direct BreakBread customer service to make ("<b>Booking
                Modifications</b>"), and agree to pay any additional Invitation Fees, Host
              Fees or Diner Fees, administrative fees and/or taxes associated with such
              Booking Modifications.</span></p>
              
              <p  ><span>  9.2.<span >
              </span></span><span>  [Diners
              can cancel a confirmed booking up to 48 hours prior to the commencement of any
              Experience in accordance with the Cancellation Policy. Unless extenuating circumstances
              exist, any portion of the Total Fees due to the Host under the applicable
              cancellation policy will be remitted to the Host by BreakBread Payments
              pursuant to the Payments Terms.</span></p>

              <p  ><span>  9.3.<span >
              </span></span><span>  If
              a Host cancels a confirmed booking prior to the commencement of the Experience,
              the Diner will receive a full refund of any fees paid for such booking. [In
              some instances, BreakBread may allow the Diner to apply the refund to a new
              booking, in which case BreakBread Payments will credit the amount against the
              Diner’s subsequent booking at the Diner’s direction. Further, BreakBread may
              publish an automated review on the Invitation cancelled by the Host indicating
              that a booking was cancelled. In addition, BreakBread may (i) keep the
              calendar for the Invitation unavailable or blocked for the dates of the
              cancelled booking, and/or (ii) impose a cancellation fee, unless the Host has a
              valid reason for cancelling the booking (determined at BreakBread’s sole
              discretion) or has legitimate concerns about the Diner’s behavior.</span></p>

              <p  ><span>  9.4.<span >
              </span></span><span>  If
              the weather poses a safety risk to Diners, or if circumstances beyond the
              Host’s control prevents a Host from carrying out an Experience (for example,
              power failure), Hosts may cancel the Host Service with BreakBread’s prior
              approval. Hosts may also cancel the Host Service if other conditions exist that
              would prevent the Host from offering the Host Service safely. 
                  
              </span></p>
              <p  ><span>  9.5.<span >
              </span></span><span>  In
              certain circumstances, BreakBread may decide, in its sole discretion, that it
              is necessary to cancel a pending or confirmed booking and initiate
              corresponding refunds and payouts. This may be (i) for reasons determined
              solely at BreakBread's discretion such as public health and safety concerns,
              or (ii) where BreakBread believes in good faith, while taking the legitimate
              interests of both parties into account, this is necessary to avoid significant
              harm to BreakBread, other Users, third parties or property, or (iii) for any
              of the reasons set out in these Terms.</span></p>
              
              <p  ><span>  9.6.<span >
              </span></span><span>  [In
              the event of a dispute between a Host and Diner User, you agree to pay all
              amounts determined by BreakBread in its sole discretion through its dispute
              resolution processes or investigations (“<b>Resolution Centre</b>”) and you
              authorize BreakBread to recover such payments through your User Account.]</span></p>

              <p ><b><span>  [10. Ratings and
                Reviews</span></b></p>

              <p  ><span>  11.0.<span >
              </span></span><span>  Within
              a certain timeframe after completing a booking, Diners and Hosts can leave a
              public review (“<b>Review</b>”) and submit a star rating (“<b>Rating</b>”)
              about each other. Ratings or Reviews reflect the opinions of individual Users
              and do not reflect the opinion of BreakBread. Ratings and Reviews are not
              verified by BreakBread for accuracy and may be incorrect or misleading.
                  
              </span></p>
              <p  ><span>  12.0.<span >
              </span></span><span>  Ratings
              and Reviews posted by Users must be accurate and shall not contain any
              offensive or defamatory language. For the avoidance of doubt, Ratings and
              Reviews are subject to Section 5 and must comply with BreakBread’s directions
              relating to content.</span></p>
              
              <p  ><span>  13.0.<span >
              </span></span><span>  Users
              are prohibited from manipulating the Ratings and Reviews system in any manner,
              such as instructing a third party to write a positive or negative Review about
              another User.</span></p>

              <p  ><span>  14.0.<span >
              </span></span><span>  Ratings
              and Reviews are part of a User’s public profile and may also be surfaced
              elsewhere on the Platform (such as the Invitation page) together with other
              relevant information such as number of bookings, number of cancellations,
              average response time and other information relating to the User’s usage of the
              Platform.]</span></p>

              <p ><b><span>  [11. Damage to
                Experience Venues, Disputes between Users</span></b></p>

              <p  ><span>  11.1.<span >
              </span></span><span>  As
              a Diner, you are responsible for leaving the Experience Venue (including any
              personal or other property located at the Experience Venue) in the condition it
              was in when you arrived. You are responsible for your own acts and omissions
              and are also responsible for the acts and omissions of any individuals or other
              Diners whom you invite to, or otherwise provide access to, the Experience
              Venue, excluding the Host (and the individuals the Host invites to the
              Experience Venue, if applicable).</span></p>
              
              <p  ><span>  11.2.<span >
              </span></span><span>  If
              a Host or other User claims and provides evidence that you as a Diner have
              damaged an Experience Venue or any person, animal or other property at an
              Experience Venue ("<b>Damage Claim</b>"), the Host can seek payment
              from you through the Resolution Center. If a Host escalates a Damage Claim to
              BreakBread, the respective Diner(s) will be given an opportunity to respond.
              If you agree to pay the Host, or BreakBread determines in its sole discretion
              that you are responsible for such Damage Claim, BreakBread shall collect any
              such sums from you and/or against the outstanding payments due to you (if
              applicable) required to cover the Damage Claim. BreakBread also reserves the
              right to otherwise collect payment from you and pursue any remedies available
              in this regard in situations in which you are responsible for a Damage Claim.</span></p>
              
              <p  ><span>  11.3.<span >
              </span></span><span>  Users
              agree to cooperate with and assist BreakBread in good faith, and to provide
              BreakBread with such information and take such actions as may be reasonably
              requested by BreakBread, in connection with any Damage Claims or other
              complaints or claims made by Users relating to (a) Experience Venues or any
              personal or other property located at an Experience Venue, (b) Experiences or
              (c) any use of the Platform. A User shall, upon BreakBread's reasonable
              request and at no cost to the User, participate in mediation or a similar
              resolution process with another User, which process will be conducted by Break
              Bread or a third party nominated by BreakBread or its insurer, with respect to
              losses for which a User is requesting payment from BreakBread and/or another
              User.]</span></p>

              <p ><b><span>  12. Rounding off</span></b></p>

              <p ><span>  BreakBread generally
              supports payment amounts that are payable from or to Diners or Hosts to the
              smallest unit supported by a given currency (i.e., pence, U.S. cents, Euro
              cents or other supported currencies). Where BreakBread’s third-party payment
              services provider does not support payments in the smaller unit supported by a
              given currency, BreakBread may, in its sole discretion, round up or round down
              the displayed amounts that are payable from or to Diners or Hosts to the
              nearest whole functional base unit in which the currency is denominated (i.e.
              to the nearest dollar, Euro or other supported currency); for example, Break
              Bread may round up an amount of $101.50 to $102.00, and round down an amount of
              $101.49 to $101.00.</span></p>

              <p ><b><span>  13. Taxes</span></b></p>

              <p  ><span>  13.1.<span >
              </span></span><span>  As
              a User you are solely responsible for determining your obligations to report,
              collect, remit or include in your Invitation Fees or otherwise any applicable
              VAT or other indirect sales taxes, occupancy tax, tourist or other visitor
              taxes or income taxes ("<b>Taxes</b>").</span></p>
              
              <p  ><span>  13.2.<span >
              </span></span><span>  Tax
              regulations may require us to collect appropriate Tax information from Hosts,
              or to withhold Taxes from payouts to Hosts, or both. If a Host fails to provide
              us with the required documentation under applicable law (e.g., a tax number)
              that we determine to be sufficient to discharge our obligation (if any) to
              withhold Taxes from payouts to you, we reserve the right to withhold payouts as
              required by law, until resolution.</span></p>

              <p  ><span>  13.3.<span >
              </span></span><span>  You
              understand that any appropriate governmental agency, department and/or authority
              ("<b>Tax</b> <b>Authority</b>") where your Experience Venue is
              located may require Taxes to be collected from Diners or Hosts on Invitation
              Fees, and to be remitted to the respective Tax Authority. The laws in
              jurisdictions may vary, but these Taxes may be required to be collected and
              remitted as a percentage of the Invitation Fees set by Hosts, a set amount per
              day, or other variations.</span></p>
              
              <p  ><span>  13.4.<span >
              </span></span><span>  In
              certain jurisdictions, BreakBread may decide in its sole discretion to
              facilitate collection and remittance of Taxes from or on behalf of Diners or
              Hosts, in accordance with these Terms ("<b>Collection and Remittance</b>")
              if such government authority in the relevant jurisdiction asserts BreakBread
              or Users have a Tax collection and remittance obligation. In any jurisdiction
              in which we decide to facilitate direct Collection and Remittance, you hereby
              instruct and authorize BreakBread to collect Taxes from Diners on the Host's
              behalf at the time Invitation Fees are collected, and to remit such Taxes to
              the relevant tax authorities. BreakBread does not assume any liability for the
              failure of a participating User to comply with any applicable tax reporting or
              remittance obligations. [The amount of taxes, if any, collected and remitted by
              BreakBread shall be visible to and separately stated to Users on their
              respective transaction documents.]</span></p>
              
              <p  ><span>  13.5.<span >
              </span></span><span>  Users
              agree that we may seek additional amounts from you in the event that the Taxes
              collected and/or remitted are insufficient to fully discharge your obligations
              to the relevant tax authorities and agree that your sole remedy for taxes
              collected and paid is a refund of such taxes from the applicable Tax Authority
              in accordance with applicable procedures set by that Tax Authority.</span></p>
              
              <p  ><span>  13.6.<span >
              </span></span><span>  Break
              Bread reserves the right, with prior notice to Hosts, to cease the Collection
              and Remittance in any jurisdiction for any reason at which point Users are once
              again solely responsible and liable for the collection and/or remittance of any
              and all Taxes that may apply to Experience or Experience Venues in that jurisdiction.</span></p>

              <p ><b><span>  14. Prohibited
                Activities</span></b></p>

              <p  ><span>  14.1.<span >
              </span></span><span>  You
              are solely responsible for compliance with any and all laws, rules,
              regulations, and tax obligations that may apply to your use of the Platform. </span></p>
              
              <p  ><span>  14.2.<span >
              </span></span><span>  In
              connection with your use of the Platform, you will not and will not assist or
              enable others to:</span></p>
              
              
              <ol type="a">
                <li>
                  <p >breach or circumvent any applicable laws or regulations, agreements with third-parties, third-party rights, or our Terms, Policies or Standards;</p>
                </li>
                <li>
                  <p >use the Platform or Collective Content for any commercial or other purposes that are not expressly permitted by these Terms or in a manner that falsely implies BreakBread endorsement, partnership or otherwise misleads others as to your affiliation with BreakBread;</p>
                </li>
                <li>
                  <p >copy, store or otherwise access or use any information, including personally identifiable information about any other User, contained on the Platform in any way that is inconsistent with BreakBread’s Privacy Policy or these Terms or that otherwise violates the privacy rights of Users or third parties;</p>
                </li>
                <li>
                  <p >use the Platform in connection with the distribution of unsolicited commercial messages ("spam");</p>
                </li>
                <li>
                  <p >offer, as a Host, any Experience Venue that you do not yourself own or have permission to make available to third parties through the Platform;</p>
                </li>
                <li>
                  <p >unless BreakBread explicitly permits otherwise, book any Invitation if you will not actually be using the Experiences yourself;</p>
                </li>
                <li>
                  <p >contact another User for any purpose other than asking a question related to a your own booking, Invitation, or the User's use of the Platform, including, but not limited to, recruiting or otherwise soliciting any User to join third-party services, applications or websites, without our prior written approval;</p>
                </li>
                <li>
                  <p >use the Platform to request, make or accept a booking independent of the Platform, to circumvent any Service Fees or for any other reason;</p>
                </li>
                <li>
                  <p >request, accept or make any payment for Invitation Fees outside of the Platform or BreakBread Payments. If you do so, you acknowledge and agree that you: (i) would be in breach of these Terms; (ii) accept all risks and responsibility for such payment, and (iii) hold BreakBread harmless from any liability for such payment;</p>
                </li>
                <li>
                  <p >discriminate against or harass anyone on the basis of race, national origin, ethnicity, religion, gender, gender identity, physical or mental disability, medical condition, marital status, age or sexual orientation, familial or parental status, income or any other individual attribute or otherwise engage in any violent, harmful, abusive or disruptive behavior;</p>
                </li>
                <li>
                  <p >misuse or abuse any Invitations or services associated with the Platform as determined by BreakBread in its sole discretion;</p>
                </li>
                <li>
                  <p >use, display, mirror or frame the Platform or Collective Content, or any individual element within the Platform, BreakBread's name, any BreakBread trademark, logo or other proprietary information, or the layout and design of any page or form contained on a page in the Platform, without BreakBread's express written consent;</p>
                </li>
                <li>
                  <p >dilute, tarnish or otherwise harm the BreakBread brand in any way, including through unauthorized use of Collective Content, registering and/or using BreakBread or derivative terms in domain names, trade names, trademarks or other source identifiers, or registering and/or using domains names, trade names, trademarks or other source identifiers that closely imitate or are confusingly similar to BreakBread domains, trademarks, taglines, promotional campaigns or Collective Content;</p>
                </li>
                <li>
                  <p >use any robots, spider, crawler, scraper or other automated means or processes to access, collect data or other content from or otherwise interact with the Platform for any purpose;</p>
                </li>
                <li>
                  <p >avoid, bypass, remove, deactivate, impair, descramble, or otherwise attempt to circumvent any technological measure implemented by BreakBread or any of BreakBread's providers or any other third party to protect the Platform or otherwise;</p>
                </li>
                <li>
                  <p >attempt to decipher, decompile, disassemble or reverse engineer any of the software used to provide the Platform;</p>
                </li>
                <li>
                  <p >take any action that damages or adversely affects, or could damage or adversely affect the performance or proper functioning of the Platform; or</p>
                </li>
                <li>
                  <p >violate or infringe anyone else’s rights or otherwise cause harm to anyone through whatever means.</p>
                </li>
              </ol>
              <p  ><span>  14.3.<span >
              </span></span><span>  You
              acknowledge that BreakBread has no obligation to monitor the access to or use
              of the Platform by any User or to review, disable access to, or edit any User
              Content, but has the right to do so to (i) operate, secure and improve the
              Platform (including without limitation for fraud prevention, risk assessment,
              investigation and customer support purposes); (ii) ensure Users’ compliance
              with these Terms; (iii) comply with applicable law or the order or requirement
              of a court, law enforcement or other administrative agency or governmental
              body; (iv) respond to User Content that it determines is harmful or
              objectionable; or (v) as otherwise set forth in these Terms. Users agree to
              cooperate with and assist BreakBread in good faith, and to provide BreakBread
              with such information and take such actions as may be reasonably requested by
              BreakBread with respect to any investigation undertaken by BreakBread or a
              representative of BreakBread regarding the use or abuse of the Platform.</span></p>
              
              <p  ><span >14.4.<span >
              </span></span><span>  If
              you feel that any User you interact with, whether online or in person, is
              acting or has acted inappropriately, including but not limited to anyone who
              (i) engages in offensive, violent or sexually inappropriate behavior, (ii) you
              suspect of stealing from you, or (iii) engages in any other intimidating or
              disturbing conduct, you should immediately report such person to the
              appropriate authorities and then to BreakBread by contacting us with your
              police station and report number (if available). You agree that any report you
              make will not obligate us to take any action (beyond that required by law, if
              any).</span></p>

              <p ><b><span>  15. Term and
                Termination, Suspension and other Measures</span></b></p>

              <p  ><span>  15.1.</span><span>  This
              Agreement shall be effective from the time you access our Platform until such
              time when you or BreakBread terminate the Agreement in accordance with these
              Terms. Each time you access our Platform, you are renewing your agreement to be
              bound by these Terms. </span></p>
              
              <p  ><span>  15.2.</span><span>  You may
              terminate this Agreement at any time by giving us thirty (30) days’ notice and
              sending us an email at support@breakbread.com. If you cancel your User Account
              as a Host, any confirmed booking(s) will be immediately cancelled and your
              Diners will not be charged. If you cancel your User Account as a Diner, any
              confirmed booking(s) will be automatically cancelled and any refund (where
              applicable) will depend upon the terms of the Invitation’s cancellation policy.</span></p>

              <p  ><span>  15.3.</span><span>  Without
              limiting our rights specified below, BreakBread may terminate this Agreement
              for any reason at any time by giving you thirty (30) days' notice via email to
              your registered email address.</span></p>

              <p  ><span>  15.4.</span><span>  BreakBread
              may immediately, without notice, terminate this Agreement and/or stop providing
              access to the Platform if (i) you have breached your obligations under these
              Terms, the Payments Terms, our Policies or Standards; (ii) you have violated
              applicable laws, regulations or third party rights; (iii) BreakBread believes
              in good faith that such action is reasonably necessary to protect the personal
              safety or property of BreakBread, its Users, or third parties (for example in
              the case of fraudulent behavior of a User); or (iv) you do not comply with
              BreakBread’s directions in relation to the use of the Platform or Services.</span></p>
              
              <p  ><span>  15.5.</span><span>  In addition,
              BreakBread may take any of the following measures (i) to comply with
              applicable law, or the order or request of a court, law enforcement or other
              administrative agency or governmental body, or if (ii) you have breached these
              Terms, the Payments Terms, our Policies or Standards, applicable laws,
              regulations, or third party rights, (iii) you have provided inaccurate,
              fraudulent, outdated or incomplete information during the User Account
              registration, Invitation process or thereafter, (iv) you and/or your
              Invitations or Experiences at any time fail to meet any applicable quality or
              eligibility criteria, (v) you have repeatedly received poor Ratings or Reviews
              or BreakBread otherwise becomes aware of or has received complaints about your
              performance or conduct, (vi) you have repeatedly cancelled confirmed bookings
              or failed to respond to booking requests with or without reason, or (vii) Break
              Bread believes in good faith that such action is reasonably necessary to
              protect the reputation, personal safety or property of BreakBread, its Users,
              or third parties, or to prevent fraud or other illegal activity:</span></p>


              
              <ol type="a">
                <li>
                  <p >refuse to surface, delete or delay any Invitations, Ratings, Reviews, or other User Content;</p>
                </li>
                <li>
                  <p >cancel any pending or confirmed bookings;</p>
                </li>
                <li>
                  <p >limit your access to or use of the Platform;</p>
                </li>
                <li>
                  <p >temporarily or permanently revoke any special status associated with your User Account;</p>
                </li>
                <li>
                  <p >temporarily or in case of severe or repeated offences permanently suspend your User Account and stop providing access to the Platform.</p>
                </li>
              </ol>
              


              
              <p  ><span>  In case of
              non-material breaches and where appropriate, you will be given notice of any
              intended measure by BreakBread and an opportunity to resolve the issue to
              BreakBread's reasonable satisfaction.</span></p>

              <p  ><span>  15.6.</span><span>  If we take
              any of the measures described above (i) we may refund your Diners in full for
              any and all confirmed bookings that have been cancelled, irrespective of
              pre-existing cancellation policies or terms of the Invitation, and (ii) you
              will not be entitled to any compensation for pending or confirmed bookings that
              were cancelled.</span></p>

              <p  ><span>  15.7.</span><span>  When this
              Agreement has been terminated, you are not entitled to a restoration of your
              User Account or any of your User Content. If your access to or use of the
              Platform has been limited or your User Account has been suspended or this
              Agreement has been terminated by us, you may not register a new User Account or
              access and use the Platform through an User Account of another User.</span></p>

              <p  ><span >15.8.<span >
              </span></span><span>  Sections
              5 and 16 to 22 of these Terms shall survive any termination or expiration of
              this Agreement.</span></p>

              <p ><b><span>  16. Disclaimers</span></b></p>

              <p  ><span>  16.1.</span><span>  If you
              choose to use the Platform or Collective Content, you do so voluntarily and at
              your sole risk. The Platform and Collective Content is provided “as is”,
              without warranty of any kind, either express or implied.</span></p>
              
              <p  ><span>  16.2.</span><span>  You agree
              that you have the necessary opportunity to investigate the BreakBread
              Services, laws, rules, or regulations that may be applicable to your Invitations
              and/or Experiences you are providing or receiving and that you are not relying
              upon any statement of law or fact made by BreakBread relating to an
              Invitation.</span></p>
              
              <p  ><span>  16.3.</span><span>  If we choose
              to conduct identity verification or background checks on any User, to the extent
              permitted by applicable law, we disclaim warranties of any kind, either express
              or implied, that such checks will identify prior misconduct by a User or
              guarantee that a User will not engage in misconduct in the future.</span></p>
              <p  ><span>  </span></p>
              <p  ><span>  16.4.</span><span>  You agree
              that some Experiences may carry inherent risk, and by participating in such
              services, you choose to assume those risks voluntarily. For example, some
              Experiences may carry risk of illness, bodily injury, disability, or death (for
              example choking or allergic reactions), and you freely and willfully assume
              those risks by choosing to participate in those Experiences. You assume full
              responsibility for the choices you make before, during and after your
              participation in an Experience. If you are bringing an additional Diner to an Experience,
              you are solely responsible for the supervision of that additional Diner
              throughout the duration of the Experience and to the maximum extent permitted
              by law, you agree to release and hold harmless BreakBread from all liabilities
              and claims that arise in any way from any injury, death, loss or harm that
              occurs to the additional Diner(s) during the Experience or in any way related
              to your Experience.</span></p>
              <p ><span>  </span></p>
              <p  ><span>  16.5.</span><span>  The
              foregoing disclaimers apply to the maximum extent permitted by law. You may
              have other statutory rights. However, the duration of statutorily required
              warranties, if any, shall be limited to the maximum extent permitted by law.</span></p>
              

              <p ><b><span>  17. Liability</span></b></p>

              <p  ><span>  17.1.</span><span>  You
              acknowledge and agree that, to the maximum extent permitted by law, the entire
              risk arising out of your access to and use of the Platform and Collective
              Content, your publishing or booking of any Invitation via the Platform, your
              presence at any Experience Venue, participation in any Experience or any other
              interaction you have with other Users whether in person or online remains with
              you. </span></p>
              
              <p  ><span>  17.2.</span><span>  Neither
              BreakBread nor any other party involved in creating, producing, or delivering
              the Platform or Collective Content will be liable for any incidental, special,
              exemplary or consequential damages, including lost profits, loss of data or
              loss of goodwill, service interruption, computer damage or system failure or
              the cost of substitute products or services, or for any damages for personal or
              bodily injury or emotional distress arising out of or in connection with (i)
              these Terms, (ii) from the use of or inability to use the Platform or
              Collective Content, (iii) from any communications, interactions or meetings
              with other Users or other persons with whom you communicate, interact or meet
              with as a result of your use of the Platform, or (iv) from your publishing or
              booking of an Invitation, including the provision, participation or use of an
              Experience, whether based on warranty, contract, tort (including negligence),
              product liability or any other legal theory, and whether or not BreakBread has
              been informed of the possibility of such damage, even if a limited remedy set
              forth herein is found to have failed of its essential purpose.</span></p>
              
              <p  ><span>  17.3.</span><span>  [Except for
              our obligations to pay amounts due to applicable Hosts pursuant to these Terms,
              in no event will BreakBread’s aggregate liability arising out of or in
              connection with these Terms and your use of the Platform including, but not
              limited to, from your publishing or booking of any Invitations via the
              Platform, or from the use of or inability to use the Platform or Collective
              Content and in connection with any Experience Venue, Experiences or
              interactions with any other Users, exceed the amounts you have paid BreakBread
              or owe for bookings via the Platform as a Diner in the twelve (12) month period
              prior to the event giving rise to the liability, or if you are a Host, the
              amounts paid by that particular Host to BreakBread in the twelve (12) month
              period prior to the event giving rise to the liability, as applicable. The
              limitations of damages set forth above are fundamental elements of the basis of
              the bargain between BreakBread and you.]</span></p>

              <p ><b><span>  18. Indemnification</span></b></p>

              <p ><span>  To the maximum extent
              permitted by applicable law, you agree to release, defend (at BreakBread’s
              option), indemnify, and hold BreakBread and its affiliates and subsidiaries,
              including but not limited to [BreakBread UkCo] and their officers, directors,
              employees and agents, harmless from and against any claims, liabilities,
              damages, losses, and expenses, including, without limitation, reasonable legal
              and accounting fees, arising out of or in any way connected with (i) your
              breach of these Terms or our Policies or Standards, (ii) your improper use of
              the Platform or any BreakBread Services, (iii) your interaction with any User,
              entry and stay at an Experience Venue or participation in an Experience
              including without limitation any injuries, losses or damages (whether
              compensatory, direct, incidental, consequential or otherwise) of any kind
              arising in connection with or as a result of such interaction, stay, visit,
              participation or use, (iv) BreakBread’s Collection and Remittance of Taxes,
              (v) your breach of any laws, regulations or third party rights, or (vi) your
              failure to comply with any directions from BreakBread.</span></p>

              <p ><b><span>  [19. Dispute Resolution
                and Arbitration Agreement</span></b></p>
              
              <p  ><span>  19.1.</span><b><span>  Overview of
                Dispute Resolution Process.</span></b><span>   BreakBread is committed to participating in a
                consumer-friendly dispute resolution process. To that end, these Terms provide
                for a two-part process for individuals to whom Section 19.1 applies: (1) an
                informal negotiation directly with BreakBread’s customer service team, and (2)
                a binding arbitration administered by the DIFC-LCIA Arbitration Centre in Dubai
              [</span><span >·</span><span>  ]<a href="#_ftn2" name="_ftnref2" title><span className="FootnoteAnchor"><span className="FootnoteAnchor"><span>  [2]</span></span></span></a>.</span></p>
              
              <p  ><span>  19.2.</span><b><span>  Pre-Arbitration
                Dispute Resolution and Notification</span></b><span>  . Prior to initiating
                an arbitration, you and BreakBread each agree to notify the other party of the
                dispute and attempt to negotiate an informal resolution to it first. We will
                contact you at the email address you have provided to us; you can contact Break
                Bread’s customer service team by emailing us. If after a good faith effort to
                negotiate one of us feels the dispute has not and cannot be resolved
                informally, the party intending to pursue arbitration agrees to notify the
              other party via email prior to initiating the arbitration. </span></p>

              <p  ><span>  19.3.</span><b><span>  Agreement to
                Arbitrate. </span></b><span>  You and BreakBread mutually agree that any dispute, claim
                or controversy arising out of or relating to these Terms or the applicability,
                breach, termination, validity, enforcement or interpretation thereof, or to the
              use of the Platform, the Experiences or the Collective Content (collectively, “<b>Disputes</b>”)
              will be settled by binding individual arbitration (the “<b>Arbitration
                Agreement</b>”). If there is a dispute about whether this Arbitration Agreement
              can be enforced or applies to our Dispute, you and BreakBread agree that the
              arbitrator will decide that issue.</span></p>

              <p  ><span>  19.4.</span><b><span>  Exceptions
                to Arbitration Agreement.</span></b><span>   You and BreakBread each agree that the
                following claims are exceptions to the Arbitration Agreement and will be
                brought in a judicial proceeding in a court of competent jurisdiction: (i) any
                claim related to actual or threatened infringement, misappropriation or
                violation of a party’s copyrights, trademarks, trade secrets, patents, or other
                intellectual property rights; (ii) any claim seeking emergency injunctive
                relief based on exigent circumstances (e.g., imminent danger or commission of a
              crime, hacking, cyber-attack).</span></p>
              <p  ><span>  19.5.</span><span>  In
              the event of a dispute arising out of or relating to this contract, including
              any question regarding its existence, validity or termination, the parties
              shall first seek settlement of that dispute by mediation in accordance with
              Clause 19.2 above. If the dispute is not settled by mediation within 30 days of
              the commencement of the mediation, or such further period as the parties shall
              agree in writing, the dispute shall be referred to and finally resolved by
              arbitration under the Arbitration Rules of the DIFC-LCIA Arbitration Centre,
              which Rules are deemed to be incorporated by reference into this clause.</span></p>
              <ol type="a">
                <li>
                  <p ><span lang="en-SG">The language to be used in the mediation and in the arbitration shall be English.</span></p>
                </li>
                <li>
                  <p ><span lang="en-SG">The governing law of the contract shall be the substantive law of England and Wales.</span></p>
                </li>
                <li>
                  <p ><span lang="en-SG">In any arbitration commenced pursuant to this clause,</span></p>
                  <ol type="i">
                    <li>
                      <p ><span lang="en-SG">the number of arbitrators shall be [</span><span lang="en-SG">one/three</span><span lang="en-SG">]; and</span></p>
                    </li>
                    <li>
                      <p ><span lang="en-SG">the seat, or legal place, of arbitration shall be [Dubai, UAE] .</span></p>
                    </li>
                  </ol>
                </li>
              </ol>
              <p  ><span>  19.6.</span><span>  Arbitrator’s
              Decision. The arbitrator’s decision will include the essential findings and
              conclusions upon which the arbitrator based the award. Judgment on the
              arbitration award may be entered in any court with proper jurisdiction. The
              arbitrator may award declaratory or injunctive relief only on an individual
              basis and only to the extent necessary to provide relief warranted by the
              claimant’s individual claim.</span></p>

              <p  ><span>  19.7.</span><span>  No Class
              Actions or Representative Proceedings. You and BreakBread acknowledge and
              agree that, to the fullest extent permitted by law, we are each waiving the
              right to participate as a plaintiff or class User in any purported class action
              lawsuit, class-wide arbitration, private attorney general action, or any other
              representative proceeding as to all Disputes. Further, unless you and Break
              Bread both otherwise agree in writing, the arbitrator may not consolidate more
              than one party’s claims and may not otherwise preside over any form of any
              class or representative proceeding. If the “class action lawsuit” waiver or the
              “class-wide arbitration” waiver in this Section 19.11 is held unenforceable
              with respect to any Dispute, then the entirety of the Arbitration Agreement
              will be deemed void with respect to such Dispute and the Dispute must proceed
              in court. If the “private attorney general action” waiver or the
              “representative proceeding” waiver in this Section 19.11 is held unenforceable
              with respect to any Dispute, those waivers may be severed from this Arbitration
              Agreement and you and BreakBread agree that any private attorney general
              claims and representative claims in the Dispute will be severed and stayed,
              pending the resolution of any arbitrable claims in the Dispute in individual
              arbitration.</span></p>
              
              <p  ><span>  19.8.</span><b><span>  Severability</span></b><span>  . In the
              event that any portion of this Agreement is deemed illegal or unenforceable,
              such provision shall be severed and the remainder of the Arbitration Agreement
              shall be given full force and effect.</span></p>

              <p  ><span>  19.9.</span><b><span>  Changes</span></b><span>  .
              Notwithstanding the provisions of Section 3 (“<b>Modification of these Terms</b>”),
              if BreakBread changes this Section 19 (“<b>Dispute Resolution and Arbitration
                Agreement</b>”) after the date you last accepted these Terms (or accepted any
              subsequent changes to these Terms), you may reject any such change by sending
              us written notice (including by email to support@breakbread.com) within thirty
              (30) days of the date such change became effective, as indicated in the “Last
              Updated” date above or in the date of BreakBread’s email to you notifying you
              of such change. Rejecting a new change, however, does not revoke or alter your
              prior consent to any earlier agreements to arbitrate any Dispute between you
              and BreakBread (or your prior consent to any subsequent changes thereto),
              which will remain in effect and enforceable as to any Dispute between you and
              BreakBread.</span></p>

              <p  ><span>  19.10.</span><span>  Survival.
              This Section 19 will survive any termination of these Terms and will continue
              to apply even if you stop using the Platform or terminate your User Account.]</span></p>

              <p ><b><span>  [20. Feedback</span></b></p>
              <p ><span>  We welcome and
              encourage you to provide feedback, comments and suggestions for improvements to
              the Platform (“<b>Feedback</b>”). You may submit Feedback by emailing us,
              through the “Contact” section of the Platform, or by other means of
              communication. Any Feedback you submit to us will be considered
              non-confidential and non-proprietary to you. By submitting Feedback to us, you
              grant us a non-exclusive, worldwide, royalty-free, irrevocable, sub-licensable,
              perpetual license to use and publish those ideas and materials for any purpose,
              without compensation to you.]</span></p>

              <p ><b><span>  21. Applicable Law and
                Jurisdiction</span></b></p>
              <p ><span>  These Terms will be
              interpreted in accordance with English law, without regard to conflict-of-law
              provisions. </span></p>

              <p ><b><span>  22. General Provisions</span></b></p>

              <p  ><span>  22.1.</span><span>  Except as
              they may be supplemented by additional terms and conditions, policies,
              guidelines or standards, these Terms constitute the entire Agreement between
              BreakBread and you pertaining to the subject matter hereof, and supersede any
              and all prior oral or written understandings or agreements between BreakBread
              and you in relation to the access to and use of the Platform.</span></p>
              
              <p  ><span>  22.2.</span><span>  No joint
              venture, partnership, employment, or agency relationship exists between you and
              BreakBread as a result of this Agreement or your use of the Platform.</span></p>

              <p  ><span>  22.3.</span><span>  These Terms
              do not and are not intended to confer any rights or remedies upon any person
              other than Hosts and Diners.</span></p>

              <p  ><span>  22.4.</span><span>  If any
              provision of these Terms is held to be invalid or unenforceable, such provision
              will be struck and will not affect the validity and enforceability of the
              remaining provisions.</span></p>

              <p  ><span>  22.5.</span><span>  Break
              Bread’s failure to enforce any right or provision in these Terms will not
              constitute a waiver of such right or provision unless acknowledged and agreed
              to by us in writing. Except as expressly set forth in these Terms, the exercise
              by either party of any of its remedies under these Terms will be without
              prejudice to its other remedies under these Terms or otherwise permitted under law.</span></p>

              <p  ><span>  22.6.</span><span>  You may not
              assign, transfer or delegate this Agreement and your rights and obligations
              hereunder without BreakBread's prior written consent. BreakBread may without
              restriction assign, transfer or delegate this Agreement and any rights and obligations
              hereunder, at its sole discretion, with 14 days prior notice. Your right to
              terminate this Agreement remains unaffected.</span></p>

              <p  ><span>  22.7.</span><span>  Unless
              specified otherwise, any notices or other communications to Users permitted or
              required under this Agreement, will be provided electronically and given by
              BreakBread via email, Platform notification, or messaging service (including
              SMS and WeChat). </span></p>

              <p  ><span>  22.8.</span><span>  If you have
              any questions about these Terms please email us at [<span >support@breakbread.com</span>].</span></p>

              <p style={{textAlign:"right"}}><b><span>  Version 1.0 as on April 1 2021</span></b></p>
            </div>


          </div>
        </div>
      </div>

    )
  }
}
export default LegalTermsCondition
