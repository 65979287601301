import { combineReducers } from 'redux'

import profile from './profile'
import experiences from './experiences'
import reviews from './reviews'
import countryCode from './countryCode'
import reservation from './reservation'
import amenitiesAndWowElements from './amenitiesAndWowElements'
import fileUploads from './fileUploads'

export default combineReducers({
  profile,
  experiences,
  reviews,
  countryCode,
  amenitiesAndWowElements,
  reservation,
  fileUploads
})
