import qs from 'qs'
import api from '../../utils/api'
let FETCH_COUNTRY_LIST = "FETCH_COUNTRY_LIST"
let DISABLE_LOGIN = "DISABLE_LOGIN"
// import alertModal from '../../utils/alert';
export function getcountry(list) {
  return {
    type: FETCH_COUNTRY_LIST,
    payload: {
      list
    }
  }
}

export function getCountryList() {
  return async (dispatch, getState) => {
    try {
      const response = await api.get(`/masters/countries`)
      dispatch(getcountry(response.data.data))
    } catch (err) {
      console.log(err)
      throw err
    }
  }
}

export function disableLogin (bool) {
  return {
    type: 'DISABLE_LOGIN',
    payload: bool
  }
}

// export { getCountryList };
