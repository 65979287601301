const FETCH_COUNTRY_LIST = 'FETCH_COUNTRY_LIST'
const DISABLE_LOGIN = 'DISABLE_LOGIN'

const initialState = {
  countryList: [],
  disableLogin: false
}

export default function(state = initialState, action) {
  if (action.type === FETCH_COUNTRY_LIST) {
    return {
      ...state,
      countryList: action.payload
    }
  }
  else if(action.type === DISABLE_LOGIN){
    return{
      disableLogin: action.payload
    }
  }

  return state
}
