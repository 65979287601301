const createOptions = (fontSize, padding) => ({
  base: {
    fontSize,
    color: '#424770',
    letterSpacing: '0.025em',
    fontFamily: 'Source Code Pro, monospace',
    '::placeholder': {
      color: '#aab7c4'
    },
    padding
  },
  invalid: {
    color: '#9e2146'
  }
})

export default createOptions
