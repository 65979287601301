import React, { Component } from 'react'

import EditExperienceLink from '../../Workflows/ExperienceEdit/EditExperienceLink'
import HostReviewLink from '../../Workflows/HostReview/HostReviewLink'
import ViewBookings from '../../Host/MyExperiences/ViewBookings'
import PauseReservation from '../../Host/MyExperiences/PauseReservation'
import { experienceCut } from '../../../utils/normalizeAssociations'
import moment from 'moment'

export default class HostActionButtton extends Component {
  render() {
    const { experience, experienceType, reservation, updateReservation, currentDate, after72Date } = this.props

    const renderButton = (Button) => {
      const buttonData = []
      switch (Button) {
      case 'ViewReceipt':
        buttonData.push(
          <div
            key={'actionDinerButton-' + Button}
            onClick={() => {
              this.props.showModal(reservation)
            }}
            style={{ padding: '10px', marginLeft: '5px' }}
            className="breakbread-btn btn btn--dark btn-sm btn-Mycolor"
          >
              View Receipt
          </div>
        )
        break
      case 'ViewReject':
        buttonData.push(
          <div
            key={'actionDinerButton-' + Button}
            onClick={() => updateReservation('decline', reservation)}
            style={{ padding: '10px', marginLeft: '5px' }}
            className="breakbread-btn btn btn--dark btn-sm btn-Mycolor"
          >
              Reject
          </div>
        )
        break
      case 'ViewAccept':
        buttonData.push(
          <div
            key={'actionDinerButton-' + Button}
            style={{ padding: '10px', marginLeft: '5px' }}
            className="breakbread-btn btn btn--dark btn-sm btn-Mycolor"
            onClick={() => updateReservation('approve', reservation)}
          >
              Accept
          </div>
        )
        break
      case 'Resume':
        buttonData.push(
          <PauseReservation key={'ActionHostButton-' + Button} className="padded-cells" data={experience}>
            <div style={{ padding: '10px', marginRight: '5px' }} className="breakbread-btn btn btn--smaller mb_5 btn--dark btn-sm btn-Mycolor">
                Resume
            </div>
          </PauseReservation>
        )
        break

      case 'pause':
        buttonData.push(
          <PauseReservation key={'ActionHostButton-' + Button} className="padded-cells" data={experience}>
            <div style={{ padding: '10px', marginRight: '5px' }} className="breakbread-btn  btn btn--smaller mb_5 btn--dark btn-sm btn-Mycolor">
                Pause
            </div>
          </PauseReservation>
        )
        break

      case 'Edit':
        buttonData.push(
          <EditExperienceLink key={'ActionHostButton-' + Button} className="breakbread-btn btn btn--smaller mb_5 btn--dark btn-sm btn-Mycolor" item={experience}>
              Edit
          </EditExperienceLink>
        )
        break

      case 'Resubmit':
        buttonData.push(
          <EditExperienceLink key={'ActionHostButton-' + Button} className="breakbread-btn btn btn--smaller mb_5  btn-sm btn--dark btn-Mycolor" item={experience}>
              Resubmit
          </EditExperienceLink>
        )
        break

      case 'ViewBookings':
        buttonData.push(
          <ViewBookings key={'ActionHostButton-' + Button} className="breakbread-btn btn btn--smaller mb_5 btn--dark btn-sm btn-Mycolor card-btn" item={experience}>
              View Bookings
          </ViewBookings>
        )
        break

      case 'ViewSchedules':
        buttonData.push(
          <button
            key={'ActionHostButton-' + Button}
            className="breakbread-btn btn btn--smaller mb_5 btn--dark btn-sm btn-Mycolor card-btn"
            onClick={() => {
              this.props.showModalSchedule(experience)
            }}
          >
              View Schedules
          </button>
        )
        break

      case 'CompleteGuestReviews':
        buttonData.push(
          <HostReviewLink style={{ padding: '10px', marginLeft: '5px' }} className="breakbread-btn btn btn--dark btn-sm btn-Mycolor" experience={experience} key={'ActionHostButton-' + Button}>
            {/* <span
              className="text-primary btn btn--smaller  btn-sm btn-Mycolor"
              style={{ whiteSpace: 'normal' }}
            > */}
            {Object.keys(reservation.hostReview).length > 0  && reservation.reviewedByHost ? 'Read my Review' : 'Write a Review'}
            {/*  </span> */}
          </HostReviewLink>
        )
        break
      default:
        ''
      }
      return buttonData
    }

    const renderRejectAccept = () => {}

    const actionButtons = []
    if (!experience.isHostReservationPage) {
      let inPast = false
      if(experience.dateWithTime) {
        if (moment(experience.dateWithTime).isBefore(moment(new Date()).utc())) {
          inPast = true
        }
      } else {
        inPast = true
      }
      /* if (experience.date) {
        if (moment(moment(experience.date).format('YYYY-MM-DD') + 'T' + experience.time + 'Z').isBefore(moment(new Date()).utc())) {
          inPast = true
        }
      } else {
        inPast = true
      } */
      if (experience.status && experience.status === 'paused') {
        actionButtons.push('Resume')
      }

      if (experience.status === 'approved' && !inPast) {
        actionButtons.push('pause')
      }

      /* if (['draft', 'submitted', 'approved', 'rejected', 'cancelled'].includes(experience.status)) {
      } */
      
      if (['draft', 'rejected', 'cancelled'].includes(experience.status)) {
        actionButtons.push('Edit')
      }
      actionButtons.push('ViewSchedules')

      if (experience.status === 'rejected') actionButtons.push('Resubmit')

      if (['approved', 'paused'].includes(experience.status)) {
        actionButtons.push('ViewBookings')
      }
      /* if (inPast && experience.status == 'approved') {
        actionButtons.push('CompleteGuestReviews')
      } */
    } else {
      let inPast = false
      if (reservation.schedule.scheduleTime) {
        if (reservation.paymentStatus == 'succeeded' && reservation.status == 'confirmed' && moment(reservation.schedule.scheduleTime).isBefore(moment(new Date()).utc())) {
          inPast = true
        }
      } else {
        // inPast = false
      }
      
      if (inPast && experience.status == 'approved') {
        actionButtons.push('CompleteGuestReviews')
      }
      if (reservation.status != 'declined') {
        actionButtons.push('ViewReceipt')
      }

      if (reservation.paymentStatus === 'pending') {
        switch (reservation.status) {
        case 'pending':
          currentDate <= after72Date && actionButtons.push('ViewAccept')
          actionButtons.push('ViewReject')
          break
        case 'declined':
          currentDate <= after72Date && actionButtons.push('ViewAccept')
          break
        /* case 'confirmed':
          actionButtons.push('ViewReject')
          break */
        default:
          break
        }
      }

      /* if (reservation.paymentStatus === 'pending') {
        if (reservation.status === 'pending') {
          actionButtons.push('ViewAccept')
          actionButtons.push('ViewReject')
        } else if (reservation.status === 'declined') {
          actionButtons.push('ViewAccept')
        } else if (reservation.status === 'confirmed') {
          actionButtons.push('ViewReject')
        }
      } */
    }

    return (
      <div className="experience-card__reservation-info group actionCard">
        {actionButtons.map((button) => (
          <React.Fragment key={'renderHostButton-' + button}>{renderButton(button, experience)}</React.Fragment>
        ))}
        {/* {experience.isHostReservationPage && reservation.paymentStatus === 'pending' && (reservation.status === "rejected" || (reservation.status === "confirmed" && currentDate <= after72Date)) && 
          <React.Fragment>
            {reservation.paymentStatus !== 'succeeded' ? (<React.Fragment>
              {reservation.paymentStatus === 'pending' && ((reservation.status === 'rejected') || (reservation.status === 'pending' &&  currentDate <= after72Date ) ) ? (<div
                style={{ padding: '10px', marginLeft: '5px' }}
                className="breakbread-btn btn btn--dark btn-sm btn-Mycolor"
                onClick={() => updateReservation('approve', reservation)}
              >
                  Accept
              </div>) : (reservation.paymentStatus === 'pending' &&  ((reservation.status === 'pending' && currentDate <= after72Date ) || reservation.status === 'confirmed')) ? (<div
                style={{ padding: '10px', marginLeft: '5px' }}
                className="breakbread-btn btn btn--dark btn-sm btn-Mycolor"
                onClick={() => updateReservation('decline', reservation)}
              >
                  Reject
              </div>):''}
            </React.Fragment>
            ):''}
          </React.Fragment>
        } */}
      </div>
    )
  }
}
