import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { authUrl } from '../../constants'
import { oAuthSignIn } from '../../actions/oauth-sign-in'
import { FacebookButton, GoogleButton } from './OAuthButtons'

import { currentUserPropTypes } from '../../constants/composed-proptypes'

const setCallbackUrl = provider => {
  if (typeof window !== 'undefined') {
    return `${window.location.origin}/omniauth/${provider}/`
  }
  const host = process.env.PUBLIC_URL
  const port = process.env.PORT
  const protocol = process.env.PROTOCOL
  if (typeof port === 'undefined') {
    return `${protocol}://${host}/omniauth/${provider}/`
  }
  return `${protocol}://${host}:${port}/omniauth/${provider}/`
}

const OAuthButtonContainer = ({
  provider,
  currentUser,
  oAuthenticate,
  handleSuccess,
  action,
  extraClassName
}) => {
  const callbackUrl = setCallbackUrl(provider)
  // Only pass callback prop if handleSuccess is defined.
  // console.log('EXTRA CLASSNAME', extraClassName);
  const oAuthSignInCallback = () => {
    if (typeof handleSuccess === 'function') {
      return handleSuccess()
    }
    return undefined
  }
  const callback = typeof handleSuccess !== 'undefined'
    ? () => {
      // console.log('handle success here');
    }
    : undefined

  const endpoint = `${authUrl}/${provider}?auth_origin_url=${callbackUrl}`
  let text
  switch (provider) {
  case 'facebook':
    text = `${action} with ${provider}`
    return (
      <FacebookButton
        onClickFunc={async () => {
          await oAuthenticate({
            provider,
            endpoint,
            oAuthSignInCallback
          })
        }}
        disabled={currentUser.isSignedIn}
        text={text}
        extraClassName={extraClassName}
      />
    )
  case 'google_oauth2':
    text = `${action} with Google`
    return (
      <GoogleButton
        onClickFunc={async () => {
          await oAuthenticate({
            provider,
            endpoint,
            callback
          })
        }}
        disabled={currentUser.isSignedIn}
        text={text}
        extraClassName={extraClassName}
      />
    )
  default:
    throw new Error('Add a provider i.e. facebook')
  }
}

OAuthButtonContainer.propTypes = {
  // text: PropTypes.string,
  oAuthenticate: PropTypes.func.isRequired,
  currentUser: currentUserPropTypes.isRequired,
  provider: PropTypes.string.isRequired,
  handleSuccess: PropTypes.func.isRequired,
  action: PropTypes.func.isRequired,
  extraClassName: PropTypes.string.isRequired
}

const mapStateToProps = state => ({
  currentUser: state.reduxTokenAuth.currentUser
})

const mapDispatchToProps = dispatch => ({
  oAuthenticate: provider => dispatch(oAuthSignIn(provider))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OAuthButtonContainer)
