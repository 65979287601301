import React from "react"

const CrustaceanShellfish = props => (
  <svg viewBox="0 0 88 88" width="1em" height="1em" {...props}>
    <g fill="currentColor" data-name="Layer 2">
      <path d="M49 34h-7.18a3 3 0 0 0-5.64 0H32a2 2 0 0 1 0-4h14a1 1 0 0 0 0-2H32a4 4 0 0 0 0 8h4.18a3 3 0 0 0 5.64 0H47v7h-6a3.93 3.93 0 0 1-3.61-2.46A5.91 5.91 0 0 0 32 37a1 1 0 0 0 0 2 3.93 3.93 0 0 1 3.61 2.46A5.91 5.91 0 0 0 41 45h7a3 3 0 0 1 0 6h-7a1 1 0 0 0-1 1c0 4.2 1.57 6.87 4.67 8a1.26 1.26 0 0 0 .33 0 1 1 0 0 0 .45-.11 1 1 0 0 0 .5-.59c.78-2.47 1.55-3.3 3-3.3a11 11 0 0 0 0-22zm-10 2a1 1 0 1 1 1-1 1 1 0 0 1-1 1zm10 7.1V36a9 9 0 0 1 8.52 6.12l-5.61 2.81A5 5 0 0 0 49 43.1zm-4.59 14.54C43 56.8 42.2 55.29 42 53h6a5 5 0 0 0 3.88-1.88L54 52.51A9 9 0 0 1 49 54c-2.6 0-3.81 1.64-4.59 3.64zm11.13-6.48l-2.74-1.83A4.92 4.92 0 0 0 53 48a5 5 0 0 0-.19-1.29L58 44.14V45a9 9 0 0 1-2.46 6.16z" />
      <path d="M44 88a44 44 0 1 1 44-44 44.05 44.05 0 0 1-44 44zm0-86a42 42 0 1 0 42 42A42 42 0 0 0 44 2z" />
    </g>
  </svg>
)

export default CrustaceanShellfish
