import React from "react"

const Mollusk = props => (
  <svg viewBox="0 0 88 88" width="1em" height="1em" {...props}>
    <g fill="currentColor" data-name="Layer 2">
      <path d="M42 32a14 14 0 0 0-14 14 12 12 0 0 0 12 12 10 10 0 0 0 10-10 8 8 0 0 0-8-8 6 6 0 0 0-6 6 5 5 0 0 0 5 5 4 4 0 0 0 4-4 1 1 0 1 0-2 0 2 2 0 0 1-2 2 3 3 0 0 1-3-3 4 4 0 0 1 4-4 6 6 0 0 1 6 6 8 8 0 0 1-8 8 10 10 0 0 1-10-10 12 12 0 0 1 12-12 16 16 0 0 1 16 16v2a2 2 0 0 1-2 2h-5a1 1 0 0 0 0 2h5a4 4 0 0 0 4-4v-2a18 18 0 0 0-18-18z" />
      <path d="M44 88a44 44 0 1 1 44-44 44.05 44.05 0 0 1-44 44zm0-86a42 42 0 1 0 42 42A42 42 0 0 0 44 2z" />
    </g>
  </svg>
)

export default Mollusk
