import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import moment from 'moment'

export default class BlogCard extends Component {
  // constructor(props) {
  //   super(props);
  // }
  componentDidMount() {}

  componentWillUnmount() {}

  render() {
    const {
      blog
    } = this.props
    
    return (
      <React.Fragment>
        <div className={`experience-card grid-view-item`}>
          <Link to={`/blog/${blog.slug}`} style={{ textDecoration: 'none' }}>
            <div className="experience-card__image--container grid-view-item__link grid-view-item__image-container">
              <div className="grid-view-item__image-wrapper js">
                <div id="" className="grid-view-item__image-wrapper js">
                  <div className="grid-view-item__image-container">
                    <div
                      className="grid-view-item__overlay box ratio-container lazyload js"
                      data-sizes="auto"
                      data-parent-fit="cover"
                      style={{
                        backgroundImage: `url("${blog.heroImage?blog.heroImage.big:''}")`
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Link>
          <Link to={`/blog/${blog.slug}`} style={{ textDecoration: 'none' }}>
            <div className="experience-card__meta_new blog_card_info">
              <div className="experience-card__high-level">
                <div className="experience-card__location" style={{ width: '33%', fontSize: '12px' }}>
                  {blog.author?'By:' + blog.author:''}
                </div>
                <div className="experience-card__location" style={{ float: 'right', fontSize: '12px', textAlign: 'right' }}>
                  {blog.publishedDate?moment(blog.publishedDate).format('DD MMM, YYYY'):''}
                </div>
              </div>
              <div className="experience-card__blogtitletext grid-view-item__blogtitletext" >
                <div className="experience-card__title grid-view-item__title">
                  <h5>{blog.title}</h5>
                </div>
              </div>
            </div>
            <div className="experience-card__reservation-info group ">
              <div
                className="experience-card__date experience-card__descripton"
                style={{ borderLeft: 'none', borderTop: 'none', width: '100%' }}
              > 
                <p style={{textAlign: 'left', color: '#4b4b4b', fontSize: '16px', fontWeight: '100'}}>{blog.description && blog.description.length > 190 ? blog.description.slice(0, 187) + '...': blog.description}{' '}</p>
              </div>
            </div>
          </Link>
        </div>
      </React.Fragment>
    )
  }
}

BlogCard.defaultProps = {
  featured: false,
  hideAvatar: false,
  forceHoverState: false
}

BlogCard.propTypes = {
  blog: PropTypes.shape({
    title: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    heroImage: PropTypes.shape({
      big: PropTypes.string,
      original: PropTypes.string,
      thumb: PropTypes.string,
      id: PropTypes.number
    }),
    author: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    publishedDate: PropTypes.Date
  }).isRequired
}
