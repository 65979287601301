import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { validate } from '../Registration/Hosts/validate'
import FileDropField from '../Form/Fields/renderFileDropField'
import renderFileField from '../Form/Fields/renderFileField'
/* ./../../Fields/renderFileField */
import alertModal from '../../utils/alert'
import * as userActionCreators from '../../actions/user'
import seo_meta from '../../utils/seo-meta.json'
import SEO from '../Shared/Web/SEO'

function keysMatch(key, obj) {
  const keys = Object.keys(obj).map(x => x.toLowerCase())
  return keys.indexOf(key.toLowerCase()) !== -1
}

/* NOTE: if currentUser form values match,they will be updated via
 * the initialValues of connectedStep5 which connects to Redux.
 * this happens automagically for all fields on the redux connected
 * form hostRegistration
 * @see https://redux-form.com/7.0.2/examples/initializefromstate/
 */

class CovidBadges extends Component {
  constructor(props) {
    super(props)
    this.state = {
      imageSizeError: null
    }
  }

  disableFields = fieldArray => {
    const { currentUser } = this.props
    if (typeof currentUser === 'undefined' || !currentUser.isSignedIn) {
      return fieldArray
    }
    const obj = fieldArray
    Object.keys(obj).map(key => {
      obj[key].disabled = !!keysMatch(key, currentUser.attributes)
      return obj[key]
    })
    return obj
  }

  /* handleHostData = async data => {
    const { userActions } = this.props
    const dataUser = {
      user: {
        food_photos: data.foodPhotos.map(el=>el.signedId).filter(el=>el),
      }
    }
    const response = await userActions.updateHostingFoodPhotos(dataUser, false, 'profile_photos')
    if (response.status == 200) {
      alertModal(
        'Profile Photos have been saved successfully.',
        'success',
        'Close'
      )
    } else {
      alertModal(
        'There is some network problem. Please try later.',
        'error',
        'Close'
      )
    }
  } */

  componentDidMount = async () => {
  }

  render() {
    const {
      valid,
      userActions,
      hostForm
    } = this.props

    const formValues = hostForm && hostForm.values ? hostForm.values : null

    return (
      <React.Fragment>
        <SEO title="Covid Badge | Breakbread" description="Covid Badge | Breakbread" />
        <form className="step5 bb-generic-form">
          <div className="page-header text-center">
            <h3>COVID Certificate</h3>
            <p className="m-5">
                Upload your COVID vaccination certificate as a jpg or pdf.
            </p>
          </div>
          <div className="form-body form-body--med">
            <div className="grid__item small--one-whole medium-up--full">
              {/* <label style={{ margin: "5px", textAlign: 'center' }}>Main Profile Picture</label> */}
              <div className="preview" >
                { formValues && formValues.covidCertificate  && formValues.covidCertificate.thumb && !formValues.covidCertificate.thumb.includes('defaults/covid_certificates/thumb_default.png')?(<a href={formValues && formValues.covidCertificate  && formValues.covidCertificate.thumb?formValues.covidCertificate.thumb:''} target="_blank" rel="noreferrer">
                  <img src={formValues && formValues.covidCertificate  && formValues.covidCertificate.thumb?formValues.covidCertificate.thumb + '?' + new Date().getTime() :''} style={{marginBottom: '16px', height: '400px', width: 'auto'}}/>
                </a>):(<div className="menuItem_image" style={{
                  backgroundImage: `url(${formValues && formValues.covidCertificate  && formValues.covidCertificate.thumb?formValues.covidCertificate.thumb + '?' + new Date().getTime():''})`, backgroundRepeat: 'no-repeat', backgroundPosition:'center', backgroundColor:'#f1f1f1', height: '400px', marginBottom: '16px'
                }} />)}
              </div>
              <div className="menuItems-field-group--dropzone">
                <Field
                  name="covidCertificate"
                  label="Dish Image"
                  id="fileUpload2"
                  type="file"
                  accept=".jpg, .jpeg, .png"
                  component={renderFileField}
                  style={{ opacity: 0, height: 0, margin: 0, padding: 0 }}
                  onClickCapture={()=>this.setState({uploadingStarted:true})}
                  onChangeCapture={() => this.setState({ uploadingStarted: true })}
                  updateImageDisplay={() => {}}
                  singleImageUpload={async (e) => {
                    this.setState({imageSizeError: null})
                    const covidCertificateImage =  await userActions.userCovidCertificateUpload(e)
                    if (!covidCertificateImage.error) {
                      this.props.change('covidCertificate', covidCertificateImage)
                      alertModal('Certificate Successfully Uploaded!', 'success', 'Close')
                    } else {
                      this.setState({imageSizeError: covidCertificateImage.message})
                    }
                  }}
                />
                {this.state.imageSizeError?(<p style={{color: 'red'}}>{this.state.imageSizeError}</p>):''}
              
                <button
                  type="button"
                  className="btn menu-image-btn"
                  style={{maxWidth: '240px'}}
                  id="btnUpload"
                  // disabled={this.state.uploadingStarted}
                  title="Upload Certificate"
                  onClick={() => {
                    document.getElementById('fileUpload2').click()
                  }}
                >
                  Upload Certificate
                </button>
              </div>
            </div>
          </div>

        </form>
      </React.Fragment>
    )
  }
}

CovidBadges.propTypes = {
  // pristine: PropTypes.bool.isRequired,
  // submitting: PropTypes.bool.isRequired,
  // valid: PropTypes.bool.isRequired,
  // previousPage: PropTypes.func.isRequired,
  // handleSubmit: PropTypes.func.isRequired,
  // currentUser: currentUserPropTypes.isRequired,
  // locationPhotosValues: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
}

// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
const CovidBadge = reduxForm({
  form: 'hostRegistration',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  validate,
  enableReinitialize: true
})(CovidBadges)

const mapDispatchToProps = dispatch => ({
  /* register: user => dispatch(registerUser(user)),
  signIn: user => dispatch(signInUser(user)),
  signOut: user => dispatch(signOutUser(user)),
  dispatchUpdateUser: attributes => dispatch(updateUser(attributes)), */
  userActions: bindActionCreators(userActionCreators, dispatch)
})

const mapStateToProps = state => ({
  pathname: state.router.location.pathname,
  currentUser: state.reduxTokenAuth.currentUser,
  stepForm: state.stepForm.hostRegistration,
  initialValues: state.reduxTokenAuth.currentUser.attributes,
  /* foodPhotos:
    typeof state.form.hostRegistration !== 'undefined'
      ? state.form.hostRegistration.values.foodPhotos
      : null, */
  hostForm: state.form.hostRegistration
})

export default connect(mapStateToProps, mapDispatchToProps)(CovidBadge)
