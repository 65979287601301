// import moment from 'moment';

import { SET_MY_REVIEWS } from '../../constants/actionTypes'

const initialState = {
  receivedAsGuest: [],
  givenAsGuest: [],
  receivedAsHost: [],
  guestReviews: []
}

export default function(state = initialState, action) {
  if (action.type === SET_MY_REVIEWS) {
    return {
      ...state,
      // receivedAsGuest: [...action.payload.received_as_guest],
      // givenAsGuest: [...action.payload.given_as_guest],
      // receivedAsHost: [...action.payload.received_as_host],
      guestReviews: [...action.payload.guest_reviews]
    }
  }

  return state
}
