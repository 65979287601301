import AlmondsIcon from './SVGJS/Almonds'
import CeleryIcon from './SVGJS/Celery'
import ChefHatIcon from './SVGJS/ChefHat'
import CornIcon from './SVGJS/Corn'
import CrustaceanShellfishIcon from './SVGJS/CrustaceanShellfish'
import DairyIcon from './SVGJS/Dairy'
import EggIcon from './SVGJS/Egg'
import FishIcon from './SVGJS/Fish'
import GlutenIcon from './SVGJS/Gluten'
import GmoIcon from './SVGJS/Gmo'
import HoneyIcon from './SVGJS/Honey'
import LupinsIcon from './SVGJS/Lupins'
import MolluskIcon from './SVGJS/Mollusk'
import MustardIcon from './SVGJS/Mustard'
import OrganicIcon from './SVGJS/Organic'
import PeanutsIcon from './SVGJS/Peanuts'
import SesameIcon from './SVGJS/Sesame'
import SoybeansIcon from './SVGJS/Soybeans'
import SpicyIcon from './SVGJS/Spicy'
import SugarIcon from './SVGJS/Sugar'
import SulfateIcon from './SVGJS/Sulfate'
import TreeNutsIcon from './SVGJS/TreeNuts'
import VegetarianIcon from './SVGJS/Vegetarian'

export {
  AlmondsIcon,
  CeleryIcon,
  ChefHatIcon,
  CornIcon,
  CrustaceanShellfishIcon,
  DairyIcon,
  EggIcon,
  FishIcon,
  GlutenIcon,
  GmoIcon,
  HoneyIcon,
  LupinsIcon,
  MolluskIcon,
  MustardIcon,
  OrganicIcon,
  PeanutsIcon,
  SesameIcon,
  SoybeansIcon,
  SpicyIcon,
  SugarIcon,
  SulfateIcon,
  TreeNutsIcon,
  VegetarianIcon
}
