import {
  TOGGLE_PANE_CLICKED,
  ALL_OPTIONS,
  SHOW_MODAL,
  HIDE_MODAL,
  AUTH_SIGNOUT_SUCCESS
} from '../constants/actionTypes'

const initialState = {
  signUpPane: ALL_OPTIONS
}

const signUp = (state = initialState, action) => {
  switch (action.type) {
  case TOGGLE_PANE_CLICKED:
    return {
      signUpPane: action.payload
    }

  case SHOW_MODAL:
    return {
      ...state,
      activeFormType: action.modalType
    }

  case HIDE_MODAL:
    return {
      signUpPane: ALL_OPTIONS
    }

  case AUTH_SIGNOUT_SUCCESS:
    return initialState

  default:
    return state
  }
}

export default signUp
