import { REHYDRATE } from 'redux-persist'

import {
  // PAYMENTS
  PAYMENTS_MADE_REQUEST_SENT,
  PAYMENTS_MADE_REQUEST_SUCCESS,
  PAYMENTS_MADE_REQUEST_FAILED,
  SET_PAYMENTS_MADE_REQUEST_DATA,
  AUTH_SIGNOUT_SUCCESS,
  AUTH_TOKEN_FAILED,
  SET_SAVED_CARD_DATA,
  SET_ORDER_ID,
  SET_SETUP_INTENT
} from '../../../constants/actionTypes'

import { GenericRequestStatus } from '../../../constants/statuses'

const initialState = {
  status: null,
  data: [],
  savedCard: null,
  orderId: null,
  setupIntent: null
}

export default function(state = initialState, action) {
  switch (action.type) {
  case PAYMENTS_MADE_REQUEST_SENT:
    return {
      ...state,
      status: GenericRequestStatus.SENT
    }
  case PAYMENTS_MADE_REQUEST_FAILED:
    return {
      ...state,
      status: GenericRequestStatus.FAILED
    }
  case PAYMENTS_MADE_REQUEST_SUCCESS:
    return {
      ...state,
      status: GenericRequestStatus.SUCCESS
    }
  case SET_PAYMENTS_MADE_REQUEST_DATA:
    return {
      ...state,
      data: action.payload
    }

  case SET_SAVED_CARD_DATA:
    return {
      ...state,
      savedCard: action.payload
    }

  case SET_ORDER_ID:
    return {
      ...state,
      orderId: action.payload
    }
  case SET_SETUP_INTENT:
    return {
      ...state,
      setupIntent: action.payload
    }

  case AUTH_SIGNOUT_SUCCESS:
    return initialState

  case AUTH_TOKEN_FAILED:
    return initialState

  default:
    return state
  }

  // if (action.type === REHYDRATE) {
  //   // const savedUser = action.payload.user || state;
  //   // return {
  //   //   ...state,
  //   //   accessToken: savedUser.accessToken,
  //   //   profile: savedUser.profile,
  //   //   payment: savedUser.payment
  //   // };
  // }
  //
  // return state;
}
