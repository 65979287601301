import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
/* Constants */
import { currentUserPropTypes } from '../../../constants/composed-proptypes'

/* Action Creators */
import * as modalActionCreators from '../../../actions/modals'
import * as signUpActionCreators from '../../../actions/signUp'
import * as reservationActionCreators from '../../../actions/reservation'
// const ReservationLink = ({
class ReservationLink extends Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  componentDidMount() {
    // console.log('props', this.props)
  }

  componentDidUpdate(props, nextProps) {
    // console.log('componentDidUpdate props', props, this.props)
    if (this.props.paymentModal) {
      this.props.modalActions.showReservationModal({
        show: true,
        modalCallBack: () => {
          alert()
          // This gets added to Modal object in Redux as a prop. Can then be used
          // to send the user to a previous state, next state and/or close the modal.
          this.props.setPaymentModel(false)
          this.props.modalActions.hideReservationModal()
          this.props.reservationActions.setReservationRequestInfo({
            schedules: 0,
            seats: 0,
            coupon: null,
            couponInfo: null,
            couponError: null,
            couponDetail: null
          })
          // Can add Alerts here as well.
        }
      })
    }
  }

  render() {
    const {
      className,
      children,
      modalActions,
      currentUser,
      experience,
      reservationActions,
      setPaymentModel,
      paymentModel,
      selectedSeats,
      selectedSchedule
    } = this.props

    // console.log('identifypropchange', this.props.paymentModal)

    return (
      <button
        type="button"
        className={`${className}`}
        disabled={
          experience.seatsAvailable < 0
          || experience.seats < experience.seatsAvailable + experience.seatsBooked
          || !selectedSeats || !selectedSchedule
        }
        onClick={() => {
          // Sets the current Reservation Request Info
          reservationActions.setReservationRequestInfo({ experience })

          reservationActions.setAuthCallback({authCallBack: true})
          // Dependant Actions
          if (!currentUser.isSignedIn) {
            // Shows the Reg Modal with a callback
            // to show the reservation modal on successful login.

            modalActions.showRegModal({
              show: true,
              modalCallBack: () => {
                // This gets added to Redux as a prop. Can then be used
                // to send the user to a previous state, next state and/or close the modal.
                console.log('under book experience')
                modalActions.showReservationModal({
                  show: true,
                  modalCallBack: () => {
                    setPaymentModel(true)
                    console.log('callback reg modal')
                    // This gets added to Modal object in Redux as a prop. Can then be used
                    // to send the user to a previous state, next state and/or close the modal.
                    modalActions.hideReservationModal()
                    reservationActions.setAuthCallback({authCallBack: false})
                    // Can add Alerts here as well.
                  }
                })
                // Can add Alerts here as well.
              }
            })
            
            modalActions.showAuthModal({
              show: true,
              modalCallBack: () => {
                // eslint-disable-next-line no-console
                console.log('in modalCallBack')
                setTimeout(() => {
                  modalActions.showReservationModal({
                    show: true,
                    modalCallBack: () => {
                      // This gets added to Modal object in Redux as a prop. Can then be used
                      // to send the user to a previous state, next state and/or close the modal.
                      console.log('callback auth modal')
                      setPaymentModel(false)
                      modalActions.hideReservationModal()
                      reservationActions.setAuthCallback({authCallBack: false})
                      // Can add Alerts here as well.
                    }
                  })
                }, 1000)
              }
            })
          } else {
            // If the user is signed in, show the reservation modal with
            // a callback to close on successful operation. modalCallBack can be connected
            // via redux. No need to pass it in as a prop anywhere unless theres a reason.

            modalActions.showReservationModal({
              show: true,
              modalCallBack: () => {
                // This gets added to Modal object in Redux as a prop. Can then be used
                // to send the user to a previous state, next state and/or close the modal.
                setPaymentModel(false)
                modalActions.hideReservationModal()
                reservationActions.setAuthCallback({authCallBack: false})
                // Can add Alerts here as well.
              }
            })
          }
        }}
      >
        {children}
      </button>
    )
  }
}

ReservationLink.propTypes = {
  modalActions: PropTypes.shape({}).isRequired,
  reservationActions: PropTypes.shape({}).isRequired,
  experience: PropTypes.shape({}).isRequired,
  currentUser: currentUserPropTypes.isRequired,
  children: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired
}

const mapStateToProps = state => ({
  currentUser: state.reduxTokenAuth.currentUser,
  experience: state.experience.experienceSingle.data,
  reservationRequest: state.reservationRequest
})

const mapDispatchToProps = dispatch => ({
  modalActions: bindActionCreators(modalActionCreators, dispatch),
  reservationActions: bindActionCreators(reservationActionCreators, dispatch),
  signUpActions: bindActionCreators(signUpActionCreators, dispatch)
  /** NOTE: This would also be a nice way of doing it
   * https://github.com/reduxjs/react-redux/blob/master/docs/api.md#inject-todos-and-all-todoactioncreators-and-counteractioncreators-directly-as-props
   *
   * actions: bindActionCreators(Object.assign({}, modalActions), dispatch)
   */
})
export default connect(mapStateToProps, mapDispatchToProps)(ReservationLink)
