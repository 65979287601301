import { title } from 'process'
import { meta } from 'react-dom-factories'
import { REHYDRATE } from 'redux-persist'

import {
  SET_BLOGS,
  SET_BLOG,
  BLOG_LOADING
} from '../constants/actionTypes'


const initialState = {
  blogs: [],
  meta: {totalCount: 0, page: 1},
  blog: null,
  loading: false
}

export default function(state = initialState, action) {
  switch (action.type) {
    case BLOG_LOADING:
      const { loading } = action
      return {
        ...state,
        loading
      }
    case SET_BLOGS:
      return {
        ...state,
        blogs: action.meta.page > 1 ? [...state.blogs, ...action.blogItems] : action.blogItems,
        meta: action.meta
      }
    case SET_BLOG: 
      return {
        ...state,
        blog: action.blog
      }
    default:
      return state
  }
}
