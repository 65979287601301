import React from 'react'
import Modal from 'react-bootstrap/lib/Modal'
import { useLocation } from 'react-router'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { signOutUser } from '../../actions/sessions'
import { hideRegHostModal, showAuthModal } from '../../actions/modals'
import Swal from 'sweetalert2/dist/sweetalert2'
// import { SPLIT_GRID_SIZE } from '../../constants';

// Registration
import StepRegistration from '../Registration/Hosts/StepRegistrationForm'

const getModalSize = page => {
  switch (page) {
  case 2:
    return 'sm'
  case 3:
    return 'large'
  case 4:
    return 'large'
  case 5:
    return 'large'
  case 6:
    return 'large'
  case 7:
    return 'large'
  default:
    return undefined
  }
}
const HostModal = props => {
  const { show, closeModal, openAuthModal, stepForm } = props
  let modalSize = null
  if (typeof stepform !== 'undefined') {
    modalSize = getModalSize(stepForm.page)
  }

  const closeHostApplication = async () => {
    const confirmationModal = Swal.mixin({
      confirmButtonClass: 'btn',
      cancelButtonClass: 'btn btn--white',
      buttonsStyling: false,
      customClass: 'confirmationModal'
    })

    await confirmationModal({
      title: 'Are you sure you want to close it',
      html: `<h6>Your changes will be lost</h6>`,
      type: '',
      showCancelButton: true,
      confirmButtonText: 'YES',
      confirmButtonDisplay: 'block',
      cancelButtonDisplay: 'block',
      padding: '3rem'
    }).then(result => {
      if (result.value) {
        closeModal()
      }
    })
  }

  const hostModelClose = () => {
    closeModal()
  }
  const { location } = window

  if (location.pathname === '/become-a-host') {
    modalSize = 'sm'
  }
  return (
    <Modal
      show={show && location.pathname !== '/terms-and-conditions' }
      // onHide={() => closeModal()}
      animation={false}
      backdrop="static"
      bsSize={modalSize || 'large'}
    >
      {stepForm.page > 2 && stepForm.page <= 8 ? (
        <Modal.Header closeButton onClick={closeHostApplication}>
          <span className="text-lg">Become a Host - Application Form</span>
          {/* <div className="form-pages pull-right">
            Step {stepForm.page} of 8 -
            {[1, 2, 3, 4, 5, 6, 7,8].map(step => (
              <div
                key={"step-"+step}
                className={`badge ${
                  step <= stepForm.page ? 'badge--active' : 'badge--step'
                }`}
              >
                {step}
              </div>
            ))}
          </div> */}
        </Modal.Header>
      ) : (
        <Modal.Header closeButton onClick={hostModelClose}>
          <span className="text-lg">Become a Host - Application Form</span>
          {/* <div className="form-pages pull-right">
              Step {stepForm.page} of 8 -
            {[1, 2, 3, 4, 5, 6, 7,8].map(step => (
              <div
                key={"step-"+step}
                className={`badge ${
                  step <= stepForm.page ? 'badge--active' : 'badge--step'
                }`}
              >
                {step}
              </div>
            ))}
          </div> */}
        </Modal.Header>
      )}

      <Modal.Body>
        <StepRegistration />
      </Modal.Body>
      {/* {stepForm.page <= 1 && !props.loginStatus && ( */}
      <Modal.Footer>
        <div className="text-center">
          <div className="text-center">
            <button
              type="button"
              className="btn btn--link"
              onClick={() => openAuthModal({ show: true, isHost: true })}
            >
                Already have an account? Sign In
            </button>
          </div>
        </div>
      </Modal.Footer>
      {/* )} */}
    </Modal>
  )
}

HostModal.defaultProps = {
  stepForm: {
    page: 1
  }
}
HostModal.propTypes = {
  show: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  openAuthModal: PropTypes.func.isRequired,
  stepForm: PropTypes.shape({})
}

const mapStateToProps = state => ({
  currentUser: state.reduxTokenAuth.currentUser,
  stepForm: state.stepForm.hostRegistration,
  loginStatus: state.user.countryCode.disableLogin
})

const mapDispatchToProps = dispatch => ({
  openAuthModal: state => dispatch(showAuthModal(state)),
  closeModal: () => dispatch(hideRegHostModal()),
  signOut: user => dispatch(signOutUser(user))
})
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HostModal)
