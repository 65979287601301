import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import seoMeta from '../../utils/seo-meta.json'
import SEO from '../Shared/Web/SEO'

class LegalTermsCondition extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

    componentDidMount = () => {
    }

    render() {
      const { title, description } = seoMeta.cookie_policy
      return (
        <div className="breakbread-container">
          <SEO title={title} description={description} />

          <div className="page-header ">
            <h1> Cookie Policy </h1>
          </div>
          <div className="page-body grid grid--uniform ">

            <div className="grid__item medium-up--three-twelfths large-up-three-twelfths navbar-collapse collapse displayDesktop">
              <h4> </h4>
              <ul className="list-unstyled customList">
                <li ><Link to="/about-us"> About Us </Link></li>
                <li >
                  <Link to="/terms-and-conditions">Terms &amp; Conditions</Link>
                </li>
                <li ><Link to="/privacy-policy"> Privacy Policy </Link>
                </li>
                <li><Link to="/cookie-policy">Cookie Policy </Link> </li>
              </ul>
            </div>

            <div className="grid__item medium-up--nine-twelfths large-up-nine-twelfths policyPara">

              <div >
                <p ><b><span>INTRODUCTION</span></b></p>
                <p>This Cookie Policy provides you with information regarding how we use cookies.  If you wish to obtain any additional information regarding BreakBread’s cookies and processing of information in general, we invite you to review our <a href="/privacy-policy" target="_self">Privacy Policy</a>.</p>
                <p ><b><span>Information about our use of cookies</span></b></p>
                <p>
                  Our website uses cookies to distinguish you from other users of our website. This helps us to provide  you  with  a  good  experience  when  you  browse
                  our  website  and  also  allows  us  to improve our website. BY CONTINUING TO BROWSE THE WEBSITE, YOU ARE AGREEING TO OUR USE OF COOKIES.
                </p>
                <p>
                  A cookie is a small file of letters and numbers that we store on your browser or the hard drive of  your  computer  if  you  agree.  Cookies  contain
                  information  that  is  transferred  to  your computer’s hard drive.
                </p>
                <p>We use the following cookies:</p>
                <ul>
                  <li>
                    <p>
                      <strong>Strictly necessary cookies:</strong>
                      These are cookies that are required for the operation of our website.  They  include,  for  example,  cookies  that  enable  you  to  log  into
                      secure  areas  of our website, use a shopping cart or make use of e-billing services.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>Analytical/performance cookies:</strong>
                      They  allow  us  to  recognise  and  count  the  number  of visitors  and  to  see  how  visitors  move  around  our  website  when  they  are  using  it.
                      This helps us to improve the way our website works, for example, by ensuring that users are finding what they are looking for easily.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>Functionality cookies:</strong>
                      These are used to recognise you when you return to our website. This  enables  us  to  personalise  our  content  for  you,  greet  you  by  name  and
                      remember your preferences (for example, your choice of language or region).
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>Targeting cookies:</strong>
                      These cookies record your visit to our website, the pages you have visited and the links you have followed. We will use this information to make our website
                      and  the  advertising  displayed  on  it  more  relevant  to  your  interests.  We  may  also  share this information with third parties for this purpose.
                    </p>
                  </li>
                </ul>
                <p>You  can  find  more  information  about  the  individual  cookies  we  use  and  the  purposes  for which we use them below:</p>
                <ul>
                  <li>
                    <p>
                      <strong>Advertising Cookies</strong>
                      Advertising cookies are placed on your computer by advertisers and ad servers in order to display advertisements that are most likely to be of interest
                      to  you.  These  cookies  allow  advertisers  and  ad  servers  to gather information about your visits to this website and other websites, alternate  the
                      ads  sent  to  a  specific  computer,  and  track  how  often  an ad  has  been  viewed  and  by  whom.  These  cookies  are  linked  to  a computer and do
                      not gather any personal information about you.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>Analytics Cookies</strong>
                      Analytics  cookies  monitor  how  users  reached  this  website,  and  how they  interact  with  and  move  around  once  on  this  website.  These cookies
                      let us know what features on this website are working the best and what features on this website can be improved.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>Our Cookies</strong>
                      Our  cookies  are  “first-party  cookies”  and  can  be  either  permanent  or temporary.  These  are  necessary  cookies,  without  which  this  website
                      won’t   work   properly   or   be   able   to   provide   certain   features   and functionalities.  Some  of  these  may  be  manually  disabled  in  your
                      browser but may affect the functionality of this website.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>Personalization Cookies</strong>
                      Personalization  cookies  are  used  to  recognize  repeat  visitors  to  this website.  We  use  these  cookies  to  record  your  browsing  history,
                      the pages  you  have  visited,  and  your  settings  and  preferences  each  time you visit this website.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>Security Cookies</strong>
                      Security cookies help identify and prevent security risks. We use these cookies to authenticate users and protect user data from unauthorized parties.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>Site Management Cookies</strong>
                      Site management cookies are used to maintain your identity or session on  this  website  so  that  you  are  not  logged  off  unexpectedly,
                      and  any information  you  enter  is  retained  from  page  to  page.  These  cookies cannot be turned off individually, but you can disable all
                      cookies in your browser.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>Third-Party Cookies</strong>
                      Third-party cookies may be place on your computer when you visit this website  by  companies  that  run  certain  services  we  offer.
                      These cookies  allow  the  third  parties  to  gather  and  track  certain  information about  you.  These  cookies  can  be  manually  disabled
                      in  your  browser. Please  note  that  third  parties  (including,  for  example,  advertising networks  and  providers  of  external  services
                      like  web  traffic  analysis services) may also use cookies, over which we have no control. These cookies  are  likely  to  be  analytical/performance
                      cookies  or  targeting cookies.
                    </p>
                  </li>
                </ul>
                <p>
                  You  block  cookies  by  activating  the  setting  on  your  browser  that  allows  you  to  refuse  the setting of all or some cookies. However,
                  if you use your browser settings to block all cookies (including essential cookies) you may not be able to access all or parts of our site.
                </p>
                <p>
                  Most  browsers  are  set  to  accept  cookies  by  default.  However,  you  can  remove  or  reject cookies  in  your  browser’s  settings.
                  Please  be  aware  that  such  action  could  affect  the availability  and  functionality  of  this  website.  For  more  information  on  how  to
                  control  cookies, check your browser or device’s settings for how you can control or reject cookies.
                </p>
                <p>Except for essential cookies, all cookies will expire after 6 months.</p>
                <p style={{ textAlign: 'right' }}><b><span> Version 1.0 as on April 1 2021</span></b></p>
              </div>

            </div>
          </div>
        </div>

      )
    }
}
export default LegalTermsCondition
