import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'

/* Custom Components */
import NotificationsWSS from './NotificationsWSS'

/* Action Creators */
import * as notificationActionCreators from '../../actions/notifications'
import SEO from '../Shared/Web/SEO'

class Notifications extends Component {
  componentWillMount() {
    this.fetch()
  }

  fetch() {
    const { notificationActions } = this.props
    notificationActions.getNotifications()
  }

  render() {
    const { currentUser, notifications } = this.props
    return (
      <div className="breakbread-container">
        <SEO title="Notifications | Breakbread" description="Notifications | Breakbread" />
        <div className="section-header text-center">
          <h1 className="page-header">Notifications</h1>
        </div>
        <NotificationsWSS
          notifications={notifications}
          currentUser={currentUser}
          fetch={() => this.fetch()}
        />
      </div>
    )
  }
}

Notifications.propTypes = {
  notificationActions: PropTypes.shape({}).isRequired,
  currentUser: PropTypes.shape({}).isRequired,
  notifications: PropTypes.arrayOf(PropTypes.shape({})).isRequired
}
const mapStateToProps = state => ({
  currentUser: state.reduxTokenAuth.currentUser,
  notifications: state.notifications.data,
  online: state.notifications.online
})
const mapDispatchToProps = dispatch => ({
  notificationActions: bindActionCreators(notificationActionCreators, dispatch)
})
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Notifications)
