// Packages
import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
// import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import _ from 'lodash'
import axios from 'axios'

// Components
import RegLink from '../../Registration/Users/RegLink'
import ImageWithOverlaySquare from '../../Shared/Web/ImageWithOverlaySquare'
import ImageCarousel from '../../ImageCarousel/ImageCarousel'
import SEO from '../../Shared/Web/SEO'
import CMSSection from '../../CMS/Section'

// Utils
import * as userActionCreators from '../../../actions/user'
import * as modalActionCreators from '../../../actions/modals'

// Media
import HeroImg4 from '../../../images/how-it-works/how-it-works-4.jpg'
import EnjoyAndShareImg from '../../../images/how-it-works/enjoy-and-share.jpeg'

// Constants
import staticCmsData from '../../../utils/cmsData.json'
import { HOST } from '../../../constants'
import { snakeToCamel } from '../../../utils/camelSnake'

const CMS_SECTION_API_ENDPOINT = `${HOST}/api/v2`

class HowItWorks extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: true,
      cmsData: undefined
    }
  }

  componentDidMount() {
    this.fetchCmsData({ page: 'howitworks' })
  }

  setCmsDataToState(cmsData) {
    this.setState({
      cmsData,
      isLoading: false
    })
  }

  async fetchCmsData({ page }) {
    try {
      await axios.get(`${CMS_SECTION_API_ENDPOINT}/cms/pages/${page}`, { mode: 'no-cors' })
        .then((res) => {
          if(res.data.is_success) {
            this.setCmsDataToState(snakeToCamel(res.data.page_info, 4))
          } else {
            const matchingPage = _.find(staticCmsData, { page })
            this.setCmsDataToState(matchingPage)
          }
        })
    } catch (e) {
      const matchingPage = _.find(staticCmsData, { page })
      this.setCmsDataToState(matchingPage)
    }
  }

  render() {
    const { currentUser, featureGuestReviews, modalActions } = this.props
    const { isLoading, cmsData } = this.state
    return (
      <React.Fragment>
        <SEO title="How It Works" />
        <CMSSection
          isLoading={isLoading}
          data={cmsData}
          sectionName="hero1"
          type="hero"
          page="howitworks"
          modalActions={modalActions}
          currentUser={currentUser}
        >
          {childProps => (
            <div className="breakbread-component-text">
              <h1 className="h1 mega-title">{childProps.title}</h1>
              <div className="mega-subtitle">
                <p>{childProps.subTitle}</p>
              </div>
              {typeof childProps.buttonComponent !== 'undefined' && childProps.buttonComponent}
              {childProps.buttonText !== null && !currentUser.isSignedIn && (
                <RegLink className="btn">
                  <span>{childProps.buttonText}</span>
                </RegLink>
              )}
            </div>
          )}
        </CMSSection>
        <CMSSection
          isLoading={isLoading}
          data={cmsData}
          sectionName="hero2"
          type="hero"
          page="howitworks"
          modalActions={modalActions}
        />
        <div className="grid grid--no-gutters grid--uniform">
          <div className="grid__item small--one-whole medium-up--one-half">
            <ImageWithOverlaySquare
              image={EnjoyAndShareImg}
            />
          </div>
          <div className="grid__item small--one-whole medium-up--one-half ">
            <CMSSection
              isLoading={isLoading}
              data={cmsData}
              theme="color"
              sectionName="square1"
              type="overlaySquare"
              page="howitworks"
            />
          </div>
        </div>
        <div className="grid grid--no-gutters grid--uniform">
          <div className="grid__item small--one-whole medium-up--one-half">
            <CMSSection
              isLoading={isLoading}
              data={cmsData}
              sectionName="square2"
              type="overlaySquare"
              page="howitworks"
            />
          </div>
          <div className="grid__item small--one-whole medium-up--one-half">
            <ImageWithOverlaySquare
              image={HeroImg4}
            />
          </div>
        </div>

        <CMSSection
          isLoading={isLoading}
          data={cmsData}
          sectionName="hero3"
          type="hero"
          theme="dark"
          page="howitworks"
          modalActions={modalActions}
        />

        <React.Fragment>
          {featureGuestReviews && (
            <ImageCarousel carouselData={featureGuestReviews} />
          )}
        </React.Fragment>
      </React.Fragment>
    )
  }
}

HowItWorks.defaultProps = {
  currentUser: { isSignedIn: false },
  featureGuestReviews: []
}

HowItWorks.propTypes = {
  currentUser: PropTypes.shape({
    isSignedIn: PropTypes.bool
  }),
  featureGuestReviews: PropTypes.arrayOf(PropTypes.shape({}))
}

const mapStateToProps = state => ({
  currentUser: state.reduxTokenAuth.currentUser,
  featureGuestReviews: state.featured.featureGuestReviews
})

const mapDispatchToProps = dispatch => ({
  userActions: bindActionCreators(userActionCreators, dispatch),
  modalActions: bindActionCreators(modalActionCreators, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(HowItWorks)
