import { REHYDRATE } from 'redux-persist'

import {
  RESERVATION_REQUEST_SENT,
  RESERVATION_REQUEST_SUCCESS,
  RESERVATION_REQUEST_FAILED,
  SET_RESERVATION_REQUEST_INFO,
  AUTH_SIGNOUT_SUCCESS,
  AUTH_TOKEN_FAILED,
  SET_COUPON_CODE,
  SET_AUTH_CALLBACK,
  SET_COUPON_DETAIL,
  SET_COUPON_ERROR,
  GET_RESERVATION_DETAIL,
  SET_IS_SAVED_CARD
} from '../constants/actionTypes'

import { ReservationRequestStatus } from '../constants/statuses'

const initialState = {
  experience: null,
  seats: 0,
  status: ReservationRequestStatus.NOT_SENT,
  coupon: null,
  couponError: null,
  couponDetail: {},
  couponInfo: null,
  price: null,
  reservationDetail: null,
  schedules: 0,
  saveCard: false,
  authCallBack: false
}

export default function(state = initialState, action) {
  switch (action.type) {
  case RESERVATION_REQUEST_SENT:
    return {
      ...state,
      status: ReservationRequestStatus.SENT
    }
  case RESERVATION_REQUEST_FAILED:
    return {
      ...state,
      status: ReservationRequestStatus.FAILED
    }
  case RESERVATION_REQUEST_SUCCESS:
    return {
      ...state,
      status: ReservationRequestStatus.SUCCESS
    }
  case SET_RESERVATION_REQUEST_INFO:
    return {
      ...state,
      ...action.payload,
      status: ReservationRequestStatus.NOT_SENT,
      coupon: null,
      couponInfo: null,
      couponError: null,
      couponDetail: null
    }

  case AUTH_SIGNOUT_SUCCESS:
    return initialState
    
  case SET_COUPON_CODE:
    return {
      ...state,
      ...action.payload
    }

  case SET_IS_SAVED_CARD:
    return {
      ...state,
      ...action.payload
    }

  case SET_AUTH_CALLBACK: 
    return {
      ...state,
      ...action.payload
    }

  case SET_COUPON_DETAIL:
    return {
      ...state,
      couponDetail: action.payload.couponDetail,
      couponInfo: action.payload.couponInfo,
      couponError: null
    }

  case SET_COUPON_ERROR:
    return {
      ...state,
      couponDetail: {},
      couponInfo: null,
      couponError: action.couponError
    }
  case GET_RESERVATION_DETAIL:
    return {
      ...state,
      reservationDetail: action.reservation
    }
  case AUTH_TOKEN_FAILED:
    return initialState

  default:
    return state
  }

  // if (action.type === REHYDRATE) {
  //   // const savedUser = action.payload.user || state;
  //   // return {
  //   //   ...state,
  //   //   accessToken: savedUser.accessToken,
  //   //   profile: savedUser.profile,
  //   //   payment: savedUser.payment
  //   // };
  // }
  //
  // return state;
}
