import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'

/* Components */

import AccountsWrapper from '../AccountsWrapper'
import { TabActions, TabContent, TabButtons } from '../../Tabs/Tabs'
import ReviewsAboutMe from './AboutMe'
import ReviewsByMe from './ByMe'
import NeedsReview from './NeedsReview'

/* Actions */
import * as userActionCreators from '../../../actions/user'
import * as modalActionCreators from '../../../actions/modals'
import * as formActionCreators from '../../../actions/forms'
import * as hostActionCreators from '../../../actions/host'
import * as transactionActionCreators from '../../../actions/transactions'
import SEO from '../../Shared/Web/SEO'

const reviewsTabs = props => {
  // const defaultTab = { history } !== '/users/reviews/needs-review' ? 'aboutYou' : 'needsReview'
  const { reviews, transactions, transactionActions, location } = props
  const defaultTab =
    location.pathname === '/diners/reviews/needs-review'
      ? 'needsReview'
      : 'aboutYou'

  return {
    defaultTab, // Not Required (if other than the first tab)
    tabs: [
      {
        name: 'aboutYou',
        title: 'Reviews About You',
        // This can return a component, html or strings
        content: () => <ReviewsAboutMe reviews={reviews.receivedAsGuest} />
      },
      {
        name: 'byYou',
        title: 'Reviews By You',
        // You can also pass in content as HTML or Text
        content: () => <ReviewsByMe reviews={reviews.givenAsGuest} />
      },
      {
        name: 'needsReview',
        title: 'Needs Review',
        // You can also pass in content as HTML or Text
        content: () => (
          <NeedsReview
            reservations={transactions.reservations.made.past}
            fetch={() =>
              transactionActions.fetchReservationsMade({
                needs_guest_reviews: true
              })
            }
          />
        )
      }
    ]
  }
}

reviewsTabs.propTypes = {
  transactions: PropTypes.shape({}).isRequired
}

class UserReviews extends Component {
  constructor(props) {
    super(props)
    this.fetchReservations = this.fetchReservations.bind(this)
  }

  componentWillMount() {
    const { userActions } = this.props
    userActions.getMyReviews()
    this.fetchReservations()
  }

  fetchReservations() {
    const { transactionActions } = this.props
    transactionActions.fetchReservationsMade({
      needs_guest_reviews: true
    })
  }

  render() {
    return (
      <AccountsWrapper title="Reviews">
        <SEO title="My Reviews" description="My Reviews" />
        <div className="account-tabs text-center">
          <TabActions namespace="reviews" data={reviewsTabs(this.props)}>
            {childProps => <TabButtons {...childProps} />}
          </TabActions>
        </div>
        <TabContent
          namespace="reviews"
          data={reviewsTabs(this.props)}
          {...this.props}
        />
      </AccountsWrapper>
    )
  }
}

UserReviews.defaultProps = {
  // onChange: null,
  // experiences: null,
}

UserReviews.propTypes = {
  userActions: PropTypes.shape({}).isRequired,
  hostActions: PropTypes.shape({}).isRequired,
  modalActions: PropTypes.shape({}).isRequired,
  reviews: PropTypes.shape({}).isRequired,
  transactionActions: PropTypes.shape({}).isRequired
}

const mapStateToProps = state => ({
  currentUser: state.reduxTokenAuth.currentUser,
  reviews: state.user.reviews,
  transactions: state.transactions
})

const mapDispatchToProps = dispatch => ({
  formActions: bindActionCreators(formActionCreators, dispatch),
  userActions: bindActionCreators(userActionCreators, dispatch),
  hostActions: bindActionCreators(hostActionCreators, dispatch),
  modalActions: bindActionCreators(modalActionCreators, dispatch),
  transactionActions: bindActionCreators(transactionActionCreators, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserReviews)
