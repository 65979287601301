import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { reset } from 'redux-form'
import ReactTable from 'react-table'

import * as modalActionCreators from '../../actions/modals'
import * as formActionCreators from '../../actions/forms'
import * as userActionCreators from '../../actions/user'
import * as transactionActionCreators from '../../actions/transactions'

// Validations
import { currentUserPropTypes } from '../../constants/composed-proptypes'

//  style
import '../../scss/breakbread/components/host-receipt.scss'

const receiptColumn = [
  {
    id: 'guest',
    Header: 'Guest Name',
    // accessor: e => `${e.readableDate} - ${e.time}`, // String-based value accessors!
    accessor: e => {
      // eslint-disable-next-line no-console
      console.log(e)
      return e.host.fullName
    },
    className: 'padded-cells'
  },
  {
    id: 'bookingNumber',
    Header: 'Booking Id',
    // accessor: e => `${e.readableDate} - ${e.time}`, // String-based value accessors!
    accessor: e => e.bookingNumber,
    className: 'padded-cells'
  },
  {
    id: 'date',
    Header: 'Date',
    // accessor: e => `${e.readableDate} - ${e.time}`, // String-based value accessors!
    accessor: e => `${moment(e.readableDate).format('MM/DD/YY')}`,
    className: 'padded-cells'
  },
  {
    id: 'seat',
    Header: 'Seats',
    // accessor: e => `${e.readableDate} - ${e.time}`, // String-based value accessors!
    accessor: 'seats',
    className: 'padded-cells'
  },
  {
    id: 'amount',
    Header: 'Amount',
    // accessor: e => `${e.readableDate} - ${e.time}`, // String-based value accessors!
    accessor: e => `${e.price} ${e.currency}`,
    className: 'padded-cells'
  },
  {
    id: 'totalPayment',
    Header: 'Total Payment',
    // accessor: e => `${e.readableDate} - ${e.time}`, // String-based value accessors!
    accessor: e => `${e.price * e.seats} ${e.currency}`,
    className: 'padded-cells'
  }
]

class GuestReceipt extends Component {
  constructor(props) {
    super(props)
    this.closeAndExit = this.closeAndExit.bind(this)
  }

  handleGuestReceiptData = async ({ data, reservation }) => {
    this.closeAndExit()
  }

  async closeAndExit() {
    const { modalActions, modalCallBack } = this.props
    await modalActions.hideReceiptModal()
    if (typeof modalCallBack !== 'undefined') {
      modalCallBack()
    }
  }

  render() {
    const { currentUser, reservation, item, pastExperiences } = this.props
    return (
      <div
        className="guest-receipt-item form-body form-body--full"
        key={reservation}
      >
        {currentUser.isLoading && <div className="spinner" />}
        {/* <h2>
          {item.status}: {item.seatsBooked} seats for
        </h2> */}

        <div className="sub-header">
          <div style={{ width: '60%', textAlign: 'initial' }}>
            {/* <div style={{display: 'flex'}}> */}
            <div>
              <span>
                <b>Experience Title: </b>
                {item.title}
                {/* moment.utc(item.readableDate).format('LL') */}
              </span>
            </div>

            <div>
              <span>
                <b>Time: </b> {item.time}
              </span>
            </div>
            <div>
              <span>
                <b>Location: </b>{' '}
                {item.location ? item.location.streetAddress : ''}{' '}
                {item.location ? item.location.city : ''}
              </span>
            </div>
            <div>
              <span>
                <b>Seats booked: </b> {item.seatsBooked}
              </span>
            </div>

            {/* </div> */}
          </div>
          <div
            style={{
              width: '40%',
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <div style={{ textAlign: 'initial' }}>
              <div>
                <span>
                  <b>Total Payments </b>
                </span>
              </div>
              <div>
                <span>
                  {item.price} {item.currency} X {item.seatsBooked}
                </span>
              </div>
              <div>
                <span>Commision (20%)</span>
              </div>
              <br />
              <div>
                <span>
                  <b>Total Payout</b>
                </span>
              </div>
            </div>
            <div>
              <div>
                <br />
              </div>
              <div>
                <span>
                  {item.price * item.seatsBooked} {item.currency}
                </span>
              </div>
              <div>
                <span>
                  {(item.price * item.seatsBooked * 20) / 100}
                  {item.currency}
                </span>
              </div>
              <br />
              <div>
                <span>
                  <b>
                    {item.price * item.seatsBooked -
                      (item.price * item.seatsBooked * 20) / 100}
                    {item.currency}
                  </b>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div style={{ marginTop: 20 }}>
          <div style={{ color: 'black', textAlign: 'initial' }}>
            <span>
              <b>Overview of Payments</b>
            </span>
          </div>

          {item.seatsBooked > 0 ? (
            <ReactTable
              defaultPageSize={pastExperiences.length < 20 ? 5 : 20}
              pageSizeOptions={[20, 25, 30, 35, 40]}
              data={pastExperiences}
              columns={receiptColumn}
            />
          ) : (
            <h4>No Seats are Booked...</h4>
          )}
        </div>
      </div>
    )
  }
}

GuestReceipt.propTypes = {
  currentUser: currentUserPropTypes.isRequired,
  // stepForm: PropTypes.shape({}).isRequired,
  userActions: PropTypes.shape({}).isRequired,
  modalActions: PropTypes.shape({}).isRequired,
  transactionActions: PropTypes.shape({}).isRequired
}

const mapDispatchToProps = dispatch => ({
  formActions: bindActionCreators(formActionCreators, dispatch),
  userActions: bindActionCreators(userActionCreators, dispatch),
  modalActions: bindActionCreators(modalActionCreators, dispatch),
  transactionActions: bindActionCreators(transactionActionCreators, dispatch),
  resetForm: () => dispatch(reset('guestReview'))
})

const mapStateToProps = state => ({
  pathname: state.router.location.pathname,
  currentUser: state.reduxTokenAuth.currentUser,
  pastExperiences: state.host.listings.past //  TO_DO: change with reservation gest list
})

export default connect(mapStateToProps, mapDispatchToProps)(GuestReceipt)
