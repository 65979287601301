import React, { Component } from 'react'

export default class Logo extends Component {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 192.418 206.2"
        className="logoLarge"
      >
        <defs />
        <g className="hideOnScroll">
          <path
            className="a"
            d="M32.039,389.1c2.159,3.778,1.979,7.016-1.439,8.995a5.33,5.33,0,0,1-4.893.54,5.548,5.548,0,0,1-3.2,4.821c-2.986,1.727-6.476,1.187-8.923-3.094l-3.2-5.541a.807.807,0,0,1,.324-1.151l17.234-9.894a.807.807,0,0,1,1.151.324Zm-15.723,7.3.756,1.3c.9,1.547,2.015,1.655,3.274.935,1.367-.792,1.691-1.763.648-3.562l-.576-1.043Zm7.844-4.5.612,1.079c.792,1.367,1.727,1.439,2.7.863,1.043-.612,1.619-1.259.5-3.2l-.432-.72Z"
            transform="translate(-6.566 -245.618)"
            fill="#fff"
          />
          <path
            className="a"
            d="M117.941,497.972a.9.9,0,0,1-.432-.9l1.511-7.88-1.367-.792-3.634,6.332a.877.877,0,0,1-1.151.324l-3.022-1.727a.877.877,0,0,1-.324-1.151l9.894-17.234a.877.877,0,0,1,1.151-.324l5.541,3.166c3.67,2.087,5.037,5.5,2.77,9.427-1.4,2.447-3.238,3.526-5.145,3.562l-1.4,8.383a.866.866,0,0,1-1.259.612Zm2.95-12.7c1.259.72,2.519,1.115,3.526-.648,1.043-1.8.108-2.77-1.151-3.526l-1.043-.612-2.375,4.138Z"
            transform="translate(-70.052 -303.791)"
            fill="#fff"
          />
          <path
            className="a"
            d="M264.421,513.711l-.036,2.591a.823.823,0,0,1-.863.828l-5.793-.108-.072,3.958,4.965.072a.823.823,0,0,1,.828.864l-.036,2.627a.823.823,0,0,1-.864.828l-4.965-.072-.072,4.677,5.793.108a.823.823,0,0,1,.828.864l-.036,2.591a.823.823,0,0,1-.864.828l-10.11-.18a.823.823,0,0,1-.828-.864l.324-19.9a.823.823,0,0,1,.864-.828l10.11.18A.933.933,0,0,1,264.421,513.711Z"
            transform="translate(-161.52 -328.165)"
            fill="#fff"
          />
          <path
            className="a"
            d="M377.789,504.123l-4.821-20.112a.873.873,0,0,1,.4-.935l3.346-1.871a.818.818,0,0,1,1.007.144l14.752,14.536a.87.87,0,0,1-.18,1.367l-3.166,1.763a.869.869,0,0,1-1.043-.18l-2.051-2.3-4.389,2.483.9,2.95a.873.873,0,0,1-.4,1.007l-3.13,1.763A.9.9,0,0,1,377.789,504.123Zm2.806-9.535,2.231-1.259-4.174-4.857Z"
            transform="translate(-238.764 -307.999)"
            fill="#fff"
          />
          <path
            className="a"
            d="M472.543,409.59l-17.2-9.966a.877.877,0,0,1-.324-1.151L458.188,393c2.087-3.634,5.829-5.253,11.729-1.8l2.7,1.583c5.865,3.382,6.368,7.448,4.246,11.082l-3.166,5.469A.839.839,0,0,1,472.543,409.59Zm-2.518-12.341-2.7-1.583c-2.519-1.475-4.282-2.375-5.4-.5l-.971,1.691,11.226,6.512.971-1.691C474.235,399.8,472.543,398.725,470.025,397.249Z"
            transform="translate(-291.243 -249.23)"
            fill="#fff"
          />
        </g>
        <g className="hideOnScroll">
          <path
            className="a"
            d="M15.427,134.243c2.195-3.742,5.145-5.181,8.527-3.166a5.293,5.293,0,0,1,2.878,3.994,5.529,5.529,0,0,1,5.757.4c2.95,1.763,4.21,5.037,1.727,9.283l-3.238,5.5a.866.866,0,0,1-1.187.288l-17.126-10.11a.866.866,0,0,1-.288-1.187Zm6.3,5.469.648-1.079c.828-1.367.4-2.195-.576-2.77-1.043-.612-1.907-.792-3.022,1.115l-.432.72Zm7.808,4.605.756-1.3c.9-1.547.432-2.591-.828-3.31-1.331-.792-2.375-.612-3.418,1.187l-.612,1.007Z"
            transform="translate(-7.908 -83.332)"
            fill="#fff"
          />
          <path
            className="a"
            d="M130.355,51.9a.81.81,0,0,1-.971-.108l-6.009-5.361-1.367.756,3.6,6.368a.867.867,0,0,1-.324,1.151l-3.022,1.727a.867.867,0,0,1-1.151-.324L111.322,38.8a.867.867,0,0,1,.324-1.151l5.577-3.166c3.67-2.087,7.3-1.511,9.535,2.411,1.367,2.447,1.367,4.605.432,6.26l6.512,5.5a.845.845,0,0,1-.144,1.4Zm-9.391-9.067c1.3-.72,2.267-1.583,1.259-3.382s-2.339-1.511-3.6-.792l-1.079.612,2.339,4.174Z"
            transform="translate(-71.205 -21.317)"
            fill="#fff"
          />
          <path
            className="a"
            d="M262.3.9l.036,2.447a.922.922,0,0,1-.9.935l-5.721.144.072,3.958,4.893-.072a.922.922,0,0,1,.935.9l.036,2.447a.922.922,0,0,1-.9.935l-4.893.072.072,4.677,5.721-.108a.922.922,0,0,1,.936.9l.036,2.447a.922.922,0,0,1-.9.935l-10.038.144a.845.845,0,0,1-.863-.828L250.5.971a.845.845,0,0,1,.828-.864L261.366,0A.922.922,0,0,1,262.3.9Z"
            transform="translate(-160.372)"
            fill="#fff"
          />
          <path
            className="a"
            d="M357.758,48.074,372.4,33.466a.871.871,0,0,1,1.007-.144l3.382,1.871a.9.9,0,0,1,.432.935l-4.641,20.148a.863.863,0,0,1-1.259.54l-3.166-1.763a.856.856,0,0,1-.4-1.007l.9-2.95-4.425-2.447-2.051,2.3a.879.879,0,0,1-1.043.18L358.01,49.4A.807.807,0,0,1,357.758,48.074Zm9.607-2.591,2.231,1.259,1.943-6.117Z"
            transform="translate(-228.88 -21.269)"
            fill="#fff"
          />
          <path
            className="a"
            d="M457.7,143.47a.875.875,0,0,1,.072-.971l4.893-6.3-2.878.18-3.922,2.339a.874.874,0,0,1-1.187-.288l-1.8-2.986a.874.874,0,0,1,.288-1.187l17.09-10.182a.874.874,0,0,1,1.187.288l1.8,2.986a.875.875,0,0,1-.288,1.187l-5.865,3.49,7.951-.72a.887.887,0,0,1,.828.432l1.907,3.238a.859.859,0,0,1-.684,1.3l-8.671.36-7.268,10.29a.859.859,0,0,1-1.439-.072Z"
            transform="translate(-289.854 -79.355)"
            fill="#fff"
          />
        </g>
        <g transform="translate(0 36.375)" className="symbolGroup">
          <circle
            className="b circle"
            cx="4.066"
            cy="4.066"
            r="4.066"
            fill="#f8a48d"
            transform="translate(0 62.604)"
          />
          <g transform="translate(29.647)">
            <path
              className="b symbol"
              fill="#f8a48d"
              d="M227.181,164.784H215.6a66.688,66.688,0,1,0,0,5.793h11.729a2.922,2.922,0,0,0,2.878-3.382A3,3,0,0,0,227.181,164.784Zm-78.147,63.468a60.517,60.517,0,1,1,60.409-63.5H176.81a14.979,14.979,0,0,0-13.276-21.228c-.072,0-.108-.036-.18-.036h-40.4a3.027,3.027,0,0,0-3.022,2.411,2.905,2.905,0,0,0,2.878,3.382h17.45a7.965,7.965,0,0,1,8.023,6.944,7.73,7.73,0,0,1-7.664,8.491H122.949a3.027,3.027,0,0,0-3.022,2.411A2.905,2.905,0,0,0,122.8,170.5h17.45a7.965,7.965,0,0,1,8.023,6.944,7.73,7.73,0,0,1-7.664,8.491H122.949a3.027,3.027,0,0,0-3.022,2.411,2.905,2.905,0,0,0,2.878,3.382h17.342c7.088,0,13.348-5.289,13.96-12.341a13.476,13.476,0,0,0-5.145-11.8,13.581,13.581,0,0,0,5.181-10.614,13.168,13.168,0,0,0-2.447-7.7h11.477a9.254,9.254,0,0,1,7.448,3.814,9.151,9.151,0,0,1,1.727,5.361,9.406,9.406,0,0,1-1.727,5.325,9.172,9.172,0,0,1-4.5,3.346.512.512,0,0,0,0,.971,9.178,9.178,0,0,1,4.5,3.382,9.328,9.328,0,0,1,1.727,5.253,8.537,8.537,0,0,1-.432,2.734,9.124,9.124,0,0,1-8.779,6.44h-3.49a3.023,3.023,0,0,0-3.022,2.447,2.905,2.905,0,0,0,2.878,3.382h3.634a15.006,15.006,0,0,0,13.672-21.264h32.633A60.534,60.534,0,0,1,149.034,228.251Z"
              transform="translate(-82.4 -101.1)"
            />
            <circle
              className="b circle"
              cx="4.066"
              cy="4.066"
              r="4.066"
              fill="#f8a48d"
              transform="translate(154.64 62.604)"
            />
          </g>
        </g>
      </svg>
    )
  }
}
