import React, { Component } from 'react'
// import { StatusBar } from 'react-native';
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
// import { ActionCableProvider } from 'react-actioncable-provider'

// import ActionCable from 'actioncable'

// import PropTypes from 'prop-types'
import { persistor, store } from './configureStore'

import AppWithNavigationState from './navigators/AppNavigator'
import { verifyCredentials } from './auth/redux-token-auth-config'

// import { API_WS_ROOT } from './constants'

// import getHeadersFromStorage from './utils/apiHeaders'
// export const { persistor, store } = configureStore;

// import loadingProgressBar from './utils/loadingProgressBar'

verifyCredentials(store)

const onBeforeLift = async () => {
  // take some action before the gate lifts
  // console.info(
  //   'App is Loading - After this fires, redux persist will have completed'
  // );
}

export default class App extends Component {
  /* not used
  static childContextTypes = {
    cable: PropTypes.shape({}),
  }; */
  constructor(props) {
    super(props)
    this.state = {
      // headersSet: false,
      // headers: {},
      // cable: {},
      isLoading: true
    }
  }
  // getChildContext() {
  //   const { cable } = this.state;
  //   return {
  //     cable,
  //   };
  // }
  // componentWillMount() {
  //   store.subscribe(() => {
  //     setTimeout(() => {
  //       this.setHeaders();
  //     }, 500);
  //   });
  // }

  componentDidMount() {
    // this.setHeaders()
    // This tell the google tag manager that the dom is ready
    window.addEventListener('DOMContentLoaded', (_event) => {
      // DOM Ready Event
      window.dataLayer.push({ event: 'gtm.dom' })
    })
  }

  load = () => {
    this.setState({ isLoading: false })
  }

  componentDidMount = () => {
    // loadingProgressBar(this.load)
    this.load()
  }

  // setHeaders() {
  //   getHeadersFromStorage()
  //     .then(async headers => {
  //       this.setState({
  //         headersSet: true,
  //         headers
  //       }, () => this.cable(headers))
  //       return headers
  //     })
  // }

  // cable = async (headerData) => {
  //   const headers = headerData
  //   // const cable = ActionCable.createConsumer(
  //   //   `${API_WS_ROOT}/?access-token=${headers['access-token']}&client=${headers.client
  //   //   }&uid=${headers.uid}`
  //   // )
  //   await this.setState({
  //     // cableSet: true,
  //     // cable
  //   })
  // };

  render() {
    const { isLoading } = this.state
    // const { cable, headersSet, cableSet } = this.state
    // const cablePresent = typeof cable !== 'undefined'
    // const readyForActiveCable = false // headersSet && cablePresent && cableSet
    return (
      <React.Fragment>
        {isLoading ? null
          : (
            <Provider store={store}>
              <PersistGate
                // loading={<div className="spinner" />}
                persistor={persistor}
                onBeforeLift={onBeforeLift}
              >
                {/* !readyForActiveCable ? <div className="spinner" /> : null */}

                <AppWithNavigationState />
                {/* {readyForActiveCable ? (
                  <ActionCableProvider cable={cable}>
                    <AppWithNavigationState />
                  </ActionCableProvider>
                ) : null} */}
              </PersistGate>
            </Provider>
          )}
      </React.Fragment>
    )
  }
}
