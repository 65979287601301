import React, { Component } from 'react'
import { connect } from 'react-redux'
// import { ActionCableConsumer } from 'react-actioncable-provider'
import { bindActionCreators } from 'redux'

import PropTypes from 'prop-types'

/* Action Creators */
import * as notificationActionCreators from '../../actions/notifications'

class NotificationsWSS extends Component {
  static contextTypes = {
    cable: PropTypes.shape({})
  };

  constructor(props) {
    super(props)
    const { fetch } = this.props
    this.handleReceived = this.handleReceived.bind(this)
    this.fetch = fetch.bind(this)
  }

  componentWillMount() {
    this.fetch()
  }

  componentDidUpdate(prevProps) {
    const { currentUser } = this.props
    if (prevProps.currentUser !== currentUser) {
      if (!currentUser.isSignedIn) {
        /* eslint-disable-next-line no-console */
        console.log('Current User is not Signed In', currentUser)
      }
    }
  }

  onCableInit = () => {
    /* eslint-disable-next-line no-console */
    console.info('Notification Cable Channel Init')
  };

  onDisconnected = () => {
    /* eslint-disable-next-line no-console */
    console.info('Notification Cable Channel Disconnected')
  };

  onConnected = () => {
    /* eslint-disable-next-line no-console */
    console.info('Notification Cable Channel Connected')
  };

  onRejected = data => {
    /* eslint-disable-next-line no-console */
    console.info('Notification Cable Channel Rejected', data)
  };

  handleReceived = payload => {
    /* eslint-disable-next-line no-console */
    console.log('Notification Cable Received Message', payload)
    const { notificationActions } = this.props
    if (payload.online) {
      this.handleOnlineStatus(payload.online)
    }
    if (payload.notification) {
      /* eslint-disable-next-line no-console */
      console.log('Received Message - ITS A NOTE', payload.notification)
      notificationActions.addCableNotification(payload.notification)
      this.fetch()
    }
  };

  handleOnlineStatus = onlineStatus => {
    const { online } = this.props
    const { notificationActions } = this.props
    if (onlineStatus === 'on' && online !== onlineStatus) {
      notificationActions.setOnlineState(onlineStatus)
    }
  };

  render() {
    const { online, notifications } = this.props
    const { cable } = this.context
    /* eslint-disable-next-line no-console */
    // console.log('ONLINE STATUS', online);
    if (!cable || typeof cable === 'undefined') {
      return <div className="spinner" />
    }
    return (
      <div>
        {/* <ActionCableConsumer
          channel={{
            channel: 'NotificationsChannel',
            someOtherKey: 'keyhere'
          }}
          onReceived={data => this.handleReceived(data)}
          onInitialized={data => this.onCableInit(data)}
          onConnected={data => this.onConnected(data)}
          onDisconnected={data => this.onDisconnected(data)}
          onRejected={data => this.onRejected(data)}
        > */}
          {/* WE ARE ONLINE: {online} */}
          {notifications.map(item => (
            <div
              key={`${item.content}${item.created_at}`}
              className="notifications mb-5"
            >
              {item.content}
              {/* {item.action}, {item.created_at} */}
            </div>
          ))}
        {/* </ActionCableConsumer> */}
      </div>
    )
  }
}

NotificationsWSS.propTypes = {
  online: PropTypes.string.isRequired,
  notificationActions: PropTypes.shape({}).isRequired,
  notifications: PropTypes.arrayOf(PropTypes.shape({})).isRequired
}
const mapStateToProps = state => ({
  online: state.notifications.online
})
const mapDispatchToProps = dispatch => ({
  notificationActions: bindActionCreators(notificationActionCreators, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationsWSS)
