import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

/* Components */

import AccountsWrapper from './AccountsWrapper'
import WalletForm from '../Workflows/Wallet/WalletForm'
import { TabActions, TabContent, TabButtons } from '../Tabs/Tabs'
import TransactionHistory from './TransactionHistory'
import SEO from '../Shared/Web/SEO'

const walletTabs = {
  defaultTab: 'paymentMethod', // Not Required (if other than the first tab)
  tabs: [
    {
      name: 'paymentMethod',
      title: 'Payment Method',
      // This can return a component, html or strings
      content: props => <WalletForm {...props} />
    },
    {
      name: 'transactions',
      title: 'Transactions',
      // You can also pass in content as HTML or Text
      content: props => <TransactionHistory {...props} />
    }
  ]
}

const Wallet = () => (
  <AccountsWrapper title="My Wallet">
    <SEO title="My Wallet" description="My Wallet" />
    <div className="account-tabs text-center">
      {/* DEV NOTE: For Tabs to Work you must define a key in the tabs reducer with the namespace
        then, pass in data as an Array.
      */}
      <TabActions namespace="wallet" data={walletTabs}>
        {/* Headless Component
          For more info see https://medium.com/merrickchristensen/headless-user-interface-components-565b0c0f2e18
          The point of using a headless component is to allow
          the display to be different in every case.
        */}
        {childProps => (
          // const { selectedTab, namespace, changeTab, tabs } = childProps;
          <TabButtons {...childProps} />
          /* DEV NOTE: You Could also do something like instead.
           * You don't need to use the tabs :)
            <button
              type="button"
              // tabName and namespace should be values
              onClick={() => changeTab('tabName', 'namespace')}
            >
              {tab.title}
            </button>
          */
        )}
      </TabActions>
    </div>

    <TabContent namespace="wallet" data={walletTabs} />
  </AccountsWrapper>
)

export default Wallet
