import React from "react"

const Almonds = props => (
  <svg viewBox="0 0 88 88" width="1em" height="1em" {...props}>
    <g fill="currentColor" data-name="Layer 2">
      <path d="M51.09 37a71.49 71.49 0 0 0-6.33-8.62 1 1 0 0 0-1.52 0A71.49 71.49 0 0 0 36.91 37C34.33 41.3 34 47.18 34 51.82c0 7.1 2.25 8.18 10 8.18s10-1.07 10-8.17c0-4.65-.33-10.53-2.91-14.83zM44 58c-7.18 0-8-.63-8-6.17 0-4 .27-9.88 2.63-13.84A66.35 66.35 0 0 1 44 30.55 66.35 66.35 0 0 1 49.37 38C51.73 42 52 47.8 52 51.84c0 5.52-.82 6.16-8 6.16z" />
      <path d="M45.48 55.12C43.31 53.91 41 50.3 41 46a1 1 0 0 0-2 0c0 5.13 2.77 9.36 5.52 10.88A1 1 0 0 0 45 57a1 1 0 0 0 .48-1.87zM44 36a1 1 0 0 0-2 0 23.2 23.2 0 0 0 4.19 13.58A1 1 0 0 0 47 50a1 1 0 0 0 .58-.18 1 1 0 0 0 .23-1.4A20.94 20.94 0 0 1 44 36zM40 44a1 1 0 0 0 1-1v-3a1 1 0 0 0-2 0v3a1 1 0 0 0 1 1zM48.29 50.28a1 1 0 0 0 0 1.42l1 1a1 1 0 0 0 1.42 0 1 1 0 0 0 0-1.42l-1-1a1 1 0 0 0-1.42 0z" />
      <path d="M44 88a44 44 0 1 1 44-44 44.05 44.05 0 0 1-44 44zm0-86a42 42 0 1 0 42 42A42 42 0 0 0 44 2z" />
    </g>
  </svg>
)

export default Almonds
