import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { goBack } from 'connected-react-router'
// import alertModal from '../../../utils/alert';
import Step2Verify from './Step2Verify'
import {
  registerUser,
  signInUser,
  signOutUser
} from '../../../auth/redux-token-auth-config'
// Actions

import { fileUpload, updateUser } from '../../../actions/user'

import * as modalActionCreators from '../../../actions/modals'
import * as formActionCreators from '../../../actions/forms'
import * as userActionCreators from '../../../actions/user'

// Proptypes
import { currentUserPropTypes } from '../../../constants/composed-proptypes'
// Local Common Utils
import { handleRegistration, handleUpdate } from '../Common/utils'
// Form Steps
import StepCreateAnAccount from './Step1'
import StepUpdateProfileImage from './Step2'
import { trackSignUp } from '../../../utils/tracking'

class Registration extends Component {
  constructor(props) {
    super(props)
    this.nextPage = this.nextPage.bind(this)
    this.previousPage = this.previousPage.bind(this)
    const { currentUser, formActions, stepForm } = this.props

    /** NOTE: This checker can be a little ackward.
     * We want to skip the the 1st and second steps when the user is signed in.
     * We also check if the form is in its default state inorder to allow users
     * to reload the page and keep their place. stepForm.page will always reset
     * to 1 when the modal is closed.
     * */
    const isFirstTry = currentUser.isSignedIn && stepForm.page === 1
    formActions.updateRegFormStep(isFirstTry ? 3 : stepForm.page)
  }

  async submitGoNext(values) {
    const { stepForm, formActions } = this.props
    trackSignUp({ email: values.email })
    await handleRegistration(values, this.props)
    // Needs to be defined after registration so that
    // currentUser is updated with latest values
    const { currentUser } = this.props
    formActions.updateForJustReg(true)
    if (currentUser.isSignedIn) {
      // if required to showing mobile verification, and profile upload step then uncomment next line
      // formActions.updateRegFormStep(stepForm.page + 1)
      // skip mobile verifcation and profile image upload process
      this.closeAndExit()
    }
  }

  oAuthSuccess() {
    this.nextPage()
  }

  closeAndExit() {
    const { modalActions } = this.props
    modalActions.showUserConfirmation()
    // const { successAction } = this.props
    // debugger
    // successAction()
  }

  nextPage() {
    const { stepForm, formActions } = this.props
    formActions.updateRegFormStep(stepForm.page + 1)
    this.closeAndExit()
  }

  previousPage() {
    const { stepForm, formActions } = this.props
    formActions.updateRegFormStep(stepForm.page - 1)
  }

  render() {
    const {
      currentUser,
      stepForm,
      dispatchUpdateUser,
      userActions,
      justRegister
    } = this.props

    const { page } = stepForm
    return (
      <div>
        {page === 1 && !currentUser.isSignedIn && (
          <StepCreateAnAccount
            submitGoNext={values => this.submitGoNext(values)}
            onSubmit={values => this.submitGoNext(values)}
            currentUser={currentUser}
            oAuthSuccess={() => this.oAuthSuccess()}
            nextPage={() => this.nextPage()}
          />
        )}
        {page === 2 && (
          /* Profile Image */
          <Step2Verify
            {...this.props}
            nextPage={this.nextPage}
            previousPage={this.previousPage}
            onSubmit={async data => {
              // NOTE: THIS IS USING THE UPDATE USER FUNCTION
              // await userActions.verifileMobileWithOtp(data);
              // this.nextPage();
            }}
            currentUser={currentUser}
          />
        )}

        {page === 3 && (
          /* Profile Image */
          <StepUpdateProfileImage
            previousPage={this.previousPage}
            onSubmit={async data => {
              await userActions.updateProfilePic(data)
              this.closeAndExit()
            }}
            currentUser={currentUser}
            nextPage={() => {
              this.closeAndExit()
            }}
          />
        )}
      </div>
    )
  }
}

Registration.defaultProps = {
  successAction: () => {
    this.props.modalActions.showUserConfirmation()
  },
  dispatchUpdateUser: user => {
    updateUser(user)
  }
}

Registration.propTypes = {
  currentUser: currentUserPropTypes.isRequired,
  stepForm: PropTypes.shape({}).isRequired,
  formActions: PropTypes.shape({}).isRequired,
  modalActions: PropTypes.shape({}).isRequired,
  successAction: PropTypes.func,
  dispatchUpdateUser: PropTypes.func
}

const mapDispatchToProps = dispatch => ({
  register: user => dispatch(registerUser(user)),
  signIn: user => dispatch(signInUser(user)),
  signOut: user => dispatch(signOutUser(user)),
  dispatchUpdateUser: attributes => dispatch(updateUser(attributes)),

  backToPage: () => dispatch(goBack()),
  formActions: bindActionCreators(formActionCreators, dispatch),
  modalActions: bindActionCreators(modalActionCreators, dispatch),
  userActions: bindActionCreators(userActionCreators, dispatch)
})

const mapStateToProps = state => ({
  pathname: state.router.location.pathname,
  currentUser: state.reduxTokenAuth.currentUser,
  stepForm: state.stepForm.registration,
  justRegister: state.stepForm.justRegister
})

export default connect(mapStateToProps, mapDispatchToProps)(Registration)
