export default values => {
  const errors = {}

  if (typeof values === 'undefined') {
    return errors
  }

  if (!values.firstName) {
    errors.firstName = 'Required'
  }
  if (!values.lastName) {
    errors.lastName = 'Required'
  }
  if (!values.email) {
    // errors.email = 'Required';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Invalid email address'
  }

  if (!values.gender) {
    // errors.gender = 'Required';
  }
  if (typeof values.gender === 'object' && values.gender !== null) {
    if (values.gender.length === 0) {
      // errors.gender = 'Required';
    }
  }
  if (!values.languages) {
    // errors.languages = 'Required';
  }
  if (typeof values.languages === 'object' && values.languages !== null) {
    if (values.languages.length === 0) {
      // errors.languages = 'Required';
    }
  }

  if (!values.specialties || !values.specialties.length > 0) {
    errors.specialties = 'Required'
  }

  if (!values.culinaryJourney) {
    errors.culinaryJourney = 'Required'
  }

  if (!values.location) {
    const locationErrors = {}
    // locationErrors.postalCode = 'Required';
    // locationErrors.countryCode = 'Required';
    // locationErrors.streetAddress = 'Required';
    // locationErrors.city = 'Required';
  } else {
    const locationErrors = {}

    /* if (!values.location.postalCode) {
      locationErrors.postalCode = 'Postal Code is Required';
    } */
    if (!values.location.countryCode) {
      locationErrors.countryCode = 'Country is Required'
    } else if (values.location.countryCode.length === 0) {
      if (!values.location.countryCode.value) {
        // locationErrors.countryCode = 'Country Code is Required';
      }
    }

    if (!values.location.streetAddress) {
      // locationErrors.streetAddress = 'Street Address is Required';
    }

    if (!values.location.city) {
      // locationErrors.city = 'City is Required';
    }

    if (locationErrors) {
      // errors.location = locationErrors;
    }
  }

  if (values.password && !values.passwordConfirmation) {
    errors.passwordConfirmation = 'Required'
  } else if (
    values.password &&
    values.passwordConfirmation !== values.password
  ) {
    errors.passwordConfirmation = 'Passwords need to match'
  }

  if (!values.password && values.passwordConfirmation) {
    errors.password = 'Required'
  }

  if (!values.birthday) {
    // errors.birthday = 'Required';
  }
  return errors
}
