import { calcNavHeight } from './navbarScroll'
import transitions from './transitions'

export default class MobileMenuUtil {
  constructor(props) {
    const { mobileMenuOpen } = props
    this.mobileMenuOpen = mobileMenuOpen
    this.menuOpen = this.menuOpen.bind(this)
    this.menuClose = this.menuClose.bind(this)
    this.refreshSelectors()
  }

  refreshSelectors() {
    this.selectors = {
      $body: $('body'),
      $navbar: $('#mainNav'),
      $announcementBar: $('[data-banner-location=top]'),
      $mobileMenu: $('.breakbread-mobile-menu'),
      $button: $('.breakbread-burger-button'),
      $container: $('.breakbread-mobile-menu-container'),
      iconClass: '.breakbread-animated-menu-icon'
    }
  }

  menuOpen({ darkDrop = true }) {
    this.refreshSelectors()
    const { selectors } = this
    const { $body, $navbar, $announcementBar, iconClass } = selectors

    $navbar.find(iconClass).addClass('-active')

    if (darkDrop) {
      $('.breakbread-mobile-menu-backdrop').addClass('-dark')
    } else {
      $('.breakbread-mobile-menu-backdrop').removeClass('-dark')
    }

    $body.addClass('noScroll')
  }

  menuClose() {
    this.refreshSelectors()
    const { selectors } = this
    const { $navbar, iconClass } = selectors
    $('body').removeClass('noScroll')
    $navbar.find(iconClass).removeClass('-active')
  }
}
