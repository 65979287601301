// import React, { useState, useEffect } from 'react'
import React, { Component } from 'react'
import { connect } from 'react-redux'
// import { orderBy } from 'lodash'
import PropTypes from 'prop-types'
// import ReactTable from 'react-table'
import { Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import Swal from 'sweetalert2/dist/sweetalert2'
import moment from 'moment'

import api from '../../utils/api'
import alertModal from '../../utils/alert'
import {
  getDinerReservations as getReservationsAction, setDinerReservationType,
  getHostReviews
} from '../../actions/host'
import * as hostActionCreators from '../../actions/host'
import { showReceiptModal } from '../../actions/modals'
import * as transactionActionCreators from '../../actions/transactions'
import UserReservationCard from '../Experiences/UserReservationCard'
import SEO from '../Shared/Web/SEO'
import seoMeta from '../../utils/seo-meta.json'

const gridSize = 'grid__item small--one-whole medium-down--one-half medium--one-half large-up--one-quarter'
// const fieldGridSize = 'grid__item medium-down--one-half large-up--one-quarter'

const isCancellable = reservation => {
  let showCncel = false
  const before48HoursDate = moment(`${moment(reservation.schedule.date).format('YYYY-MM-DD')}T${reservation.experience.time}Z`).add(-48, 'hours')
  const currentDate = moment(new Date()).utc()
  if (currentDate <= before48HoursDate && reservation.paymentStatus === 'pending') {
    showCncel = true
  }
  return showCncel
}
class ReservationCardView extends Component {
  constructor(props) {
    super(props)
    this.handleCancellation = this.handleCancellation.bind(this)
    this.state = {
    }
    // let handleCancellation = this.handleCancellation.bind(this);
  }

  componentDidMount() {
    const { hostActions } = this.props
    hostActions.getExperiences()

    // let reservationList = [];
    // let selectedExperience = {}
    // let disableExperience = false
    this.fetchData()
    // const experienceId = this.props.match.params.reservationId
    // const options = []
    // let option = {}
    // for (const key in this.props.allExperiences) {
    //   if (!this.props.allExperiences[key].sample) {
    //     option = {
    //       value: this.props.allExperiences[key].id,
    //       label: this.props.allExperiences[key].title
    //     }
    //     options.push(option)
    //   }

    //   if (experienceId == this.props.allExperiences[key].id) {
    //     selectedExperience = option
    //     disableExperience = true
    //     // this.setState({ });
    //   }
    // }
    // this.setState({ options, selectedExperience, disable: disableExperience })
  }

  // handleChange = selectedExperience => {
  //   if (selectedExperience != null) {
  //     this.setState({ selectedExperience })
  //     let reservationList = this.state.reservations
  //     const experience_id = selectedExperience.value
  //     if (experience_id) {
  //       reservationList = reservationList.filter(
  //         data => data.experience.id == experience_id
  //       )
  //     }
  //     const newlySortedReservations = orderBy(reservationList, 'booked_date')
  //     // this.setState({ reservations: reservationList });
  //     this.setState({ sortedReservations: newlySortedReservations })
  //   } else {
  //     const reservationList = this.state.reservations
  //     this.setState({
  //       selectedExperience: '',
  //       sortedReservations: reservationList
  //     })
  //   }

  //   // this.props.history.push(`/hosts/reservations/${selectedExperience.value}`);
  // }

  // handlePageClick = data => {
  //   const { selected } = data
  //   const startIndex = Math.ceil(selected * 2)
  //   const endIndex = Math.ceil((selected + 1) * 2 - 1)

  //   this.setState({ startIndex, endIndex })
  // }

  handleCancellation(reservation) {
    const { transactionActions } = this.props

    const seatWording = reservation.seats > 1 ? 'Seats' : 'Seat'
    const message = `Are you sure you want to cancel your booking for <b>${reservation.seatsBooked} ${seatWording}</b> to <i>${reservation.experience.title}</i>?`
    const text = 'Confirmed bookings are not refundable if cancelled within less than 48 hours before start of experience.'

    const sweetness = Swal.mixin({
      confirmButtonClass: 'btn btn--link',
      cancelButtonClass: 'btn',
      buttonsStyling: false,
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonText: 'Explore Experiences',
      padding: '2rem'
    })

    sweetness({
      title: 'Cancel Confirmation',
      html: `<h6>${message}</h6><p><small>${text}</small></p>`,
      type: '',
      showCancelButton: true,
      cancelButtonText: 'No, Dont Cancel',
      confirmButtonText: 'Yes, I\'m Sure',
      padding: '3rem'
    }).then(result => {
      if (result.value) {
        transactionActions.cancelReservation(reservation.id)
        this.fetchData()
      }
    })
  }

  // updateSelection = target => {
  //   this.setState({ sortBy: target })
  //   const selected = target.value
  //   const data = this.state.reservations

  //   if (selected === 'booked_date') {
  //     const newlySortedReservations = orderBy(data, 'booked_date', 'desc')
  //     this.setState({ sortedReservations: newlySortedReservations })
  //   } else if (selected === 'status') {
  //     const newlySortedReservations = orderBy(data, 'status', 'desc')
  //     this.setState({ sortedReservations: newlySortedReservations })
  //   } else if (selected === 'experience') {
  //     const newlySortedReservations = orderBy(
  //       orderBy(data, 'booked_date'),
  //       'experience.id',
  //       'asc'
  //     )
  //     this.setState({ sortedReservations: newlySortedReservations })
  //   } else if (selected === 'seats') {
  //     const newlySortedReservations = orderBy(
  //       orderBy(data, 'booked_date'),
  //       'seats_booked',
  //       'desc'
  //     )
  //     this.setState({ sortedReservations: newlySortedReservations })
  //   }
  // }

  updateReservation = (type, reservation) => {
    if (type === 'decline') {
      api
        .post(`hosts/reservations/${reservation.id}/decline`, { reservation })
        .then(response => {
          const json = response.data
          if (json.is_success) {
            alertModal('Reservation request rejected.', 'success', 'Close')
            const fetchData = async () => {
              // const result = await getReservations()
              /* const reservationList = result.reservations
              const newlySortedReservations = orderBy(
                reservationList,
                'booked_date'
              )
              this.setState({ reservations: reservationList })
              this.setState({ sortedReservations: newlySortedReservations }) */
            }

            fetchData()
          } else {
            alertModal('there is some network issue', 'error', 'Close')
          }
        })
        .catch(e => alertModal(e.message, 'error', 'Close'))
    } else if (type === 'approve') {
      api
        .post(`hosts/reservations/${reservation.id}/approve`, { reservation })
        .then(response => {
          const json = response.data
          if (json.is_success) {
            alertModal('Reservation request approved.', 'success', 'Close')
            const fetchData = async () => {
              // const result = await getReservations()
              /*
                const reservationList = result.reservations
                const newlySortedReservations = orderBy(
                  reservationList,
                  'booked_date'
                )
                this.setState({ reservations: reservationList })
                this.setState({ sortedReservations: newlySortedReservations })
              */
            }
            fetchData()
          } else {
            alertModal('there is some network issue', 'error', 'Close')
          }
        })
        .catch(e => alertModal(e.message, 'error', 'Close'))
    }
  }

  fetchData = async () => {
    const { getReservations } = this.props
    try {
      await getReservations()
      this.setState({ loading: false })
      /*
      let reservationList = result.reservations
      const experience_id = this.props.match.params.reservationId
      if (experience_id) {
        reservationList = reservationList.filter(
          data => data.experience.id == experience_id
        )
        // this.setState({ reservations: filteredList });
      }
      this.setState({
        reservations: result.reservations,
        loading: true,
        sortedReservations: reservationList
      })
      */
    } catch (err) {
      this.setState({ loading: false })
      // this.setState({ loading: true, sortedReservations: [] })
    }
  }

  showReciept = () => {}

  render() {
    const { reservations, reservationType, getReservations, showModal, match } = this.props
    const { loading } = this.state
    const experienceId = match.params.reservationId
    const NotFoundMessage = !experienceId
      ? "You don't have any bookings. "
      : "You don't have any bookings."

    let reservationList = [...reservations]
    if (experienceId) {
      reservationList = reservationList.filter(
        data => data.experience.id === experienceId
      )
    }
    const pageTitle = 'My Bookings'

    return (
      <div className="breakbread-container">
        <div className="page-header text-center">
          <SEO title={seoMeta.diners_bookings.title} description={seoMeta.diners_bookings.title} />
          <h1>{pageTitle}</h1>
        </div>
        {false && (
          <div className="account-tabs text-center">
            <ul className="nav nav-tabs flexCenter newtabs">
              <li
                className={
                  reservationType === 'all' ? 'nav-item active' : 'nav-item'
                }
              >
                <button
                  type="button"
                  className="unbutton nav-link "
                  aria-current="page"
                  href="#"
                  onClick={e => {
                    e.preventDefault()
                    setDinerReservationType({
                      reservationType: 'all'
                    })
                    getReservations('all')
                  }}
                >
                  All
                </button>
              </li>
              <li
                className={
                  reservationType === 'upcoming' ? 'nav-item active' : 'nav-item'
                }
              >
                <button
                  type="button"
                  className="unbutton nav-link"
                  href="#"
                  onClick={e => {
                    e.preventDefault()
                    setDinerReservationType({
                      reservationType: 'upcoming'
                    })
                    getReservations('upcoming')
                  }}
                >
                  Upcoming
                </button>
              </li>
              <li
                className={
                  reservationType === 'past' ? 'nav-item active' : 'nav-item'
                }
              >
                <button
                  type="button"
                  className="unbutton nav-link"
                  href="#"
                  onClick={e => {
                    e.preventDefault()
                    setDinerReservationType({
                      reservationType: 'past'
                    })
                    getReservations('past')
                  }}
                >
                  Past
                </button>
              </li>
            </ul>
          </div>
        )}

        <div className="grid grid--uniform account">
          {reservationList.length > 0 ? (
            reservationList.sort((a, b) => b.id - a.id).map((reservation) => (
              <div key={reservation.id} className={gridSize}>
                <UserReservationCard
                  isCancellable={isCancellable}
                  cancelReservation={this.handleCancellation}
                  reservation={reservation}
                  displaySoldStatus
                  showModal={showModal}
                  updateReservation={this.updateReservation}
                  getReservations={getReservations}
                />
              </div>
            ))
          ) : (
            <React.Fragment>
              <div className="text-center text-warning">
                <div className="grid__item section-header section-heading">
                  {!loading ? (
                    <div className="breakbread-container">
                      <h5 className="text-center">{NotFoundMessage}</h5>
                    </div>
                  ) : <div className="spinner" />}
                </div>
                <Link to="/experiences" className="btn">
                  Explore Experiences
                </Link>
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
    )
  }
}

ReservationCardView.defaultProps = {
  // showModal: false
}

ReservationCardView.propTypes = {
  getReservations: PropTypes.func.isRequired,
  hostActions: PropTypes.shape({
    getExperiences: PropTypes.func
  }).isRequired,
  showModal: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      reservationId: PropTypes.string
    })
  }).isRequired,
  transactionActions: PropTypes.shape({
    cancelReservation: PropTypes.func
  }).isRequired,
  reservations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  reservationType: PropTypes.string.isRequired
}

const mapStateToProps = state => ({
  // hosts: state.user.hosts,
  reviews: state.host.reviews.reviews,
  allExperiences: state.host.listings.data,
  reservationType: state.user.reservation.filter.reservationType,
  reservations: state.user.reservation.dinersReservation
})

const mapDispatchToProps = dispatch => ({
  getReservations: (type) => dispatch(getReservationsAction(type)),
  setDinerReservationType: (filter) => dispatch(setDinerReservationType(filter)),
  getHostReviews: id => dispatch(getHostReviews(id)),
  transactionActions: bindActionCreators(transactionActionCreators, dispatch),
  hostActions: bindActionCreators(hostActionCreators, dispatch),
  showModal: state => {
    dispatch(showReceiptModal({ item: state, receiptType: 'guest' }))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(ReservationCardView)
