import React, { Component } from 'react'
import moment from 'moment'
import ReactPlaceholder from 'react-placeholder'
import StarRatingComponent from 'react-star-rating-component'
class UserReview extends Component {
  constructor(props) {
    super(props)
    this.state = {
      reservations: [],
      sortedReservations: [],
      show: false,
      user: [],
      userInfo: []
    }
  }

  componentDidMount() {
  }

  render() {
    const { review } = this.props
    return (<React.Fragment>
      <div className="grid grid--uniform  mtt_10">
        <div className="reviews--item-meta" style={{ paddingLeft: "10px" }}>
          <div className="reviews--item-date">
            {moment.utc(review.date_posted).format('LL')}
          </div>
          <div
            className="reviews--item-rating"
            style={{ width: '100%', maxWidth: 320 }}
          >
            <ReactPlaceholder
              showLoadingAnimation
              type="text"
              ready={() => { }}
              rows={1}
              color="#E0E0E0"
            >
              <span className="reviews--item-rating-label">
                <span className="color">Rated it</span>
                <span className="number">{review.star}.0</span>
              </span>
              <StarRatingComponent
                name="rate1"
                starCount={5}
                value={review.star?Number(review.star):0}
                onStarClick={args => { }}
              />
            </ReactPlaceholder>
          </div>
        </div>
      </div>
      <div className="grid grid--uniform ">
        <div className="grid__item medium-down--one-half medium--one-half large-up--one-sixth">

          <div className="chef-or-host__avatar" style={{ width: "90%", backgroundImage: `url(${review.host.avatar_url})` }} >
            <img
              className="lazyload lazysizes avatar profileAvatarDrop__avatar hidden"
              alt={review.host.fullname}

              src={review.host.avatar_url}
              data-src={review.host.avatar_url}
              data-widths="[180, 360, 540, 720, 900, 1080, 1296, 1512, 1728, 2048]"
            />
                               
          </div>
        </div>

        <div style={{ color: "black", textAlign: "left" }} className="grid__item medium-down--one-half medium--one-half large-up--five-sixths">

          <b style={{ color: "black" }}>{review.host.fullname}</b>
          <p style={{ color: "black" }}>{review.comment}</p>
        </div>

      </div>
    </React.Fragment>
    )
  }
}

export default UserReview
