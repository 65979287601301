import { REHYDRATE } from 'redux-persist'

import {
  // RESERVATIONS
  RESERVATIONS_MADE_REQUEST_SENT,
  RESERVATIONS_MADE_REQUEST_SUCCESS,
  RESERVATIONS_MADE_REQUEST_FAILED,
  SET_RESERVATIONS_MADE_REQUEST_DATA,
  SET_RESERVATIONS_MADE_PAST_FUTURE,
  AUTH_SIGNOUT_SUCCESS,
  AUTH_TOKEN_FAILED
} from '../../../constants/actionTypes'

import { GenericRequestStatus } from '../../../constants/statuses'

const initialState = {
  status: null,
  data: [],
  past: [],
  future: []
}

export default function(state = initialState, action) {
  switch (action.type) {
  case RESERVATIONS_MADE_REQUEST_SENT:
    return {
      ...state,
      status: GenericRequestStatus.SENT
    }
  case RESERVATIONS_MADE_REQUEST_FAILED:
    return {
      ...state,
      status: GenericRequestStatus.FAILED
    }
  case RESERVATIONS_MADE_REQUEST_SUCCESS:
    return {
      ...state,
      status: GenericRequestStatus.SUCCESS
    }
  case SET_RESERVATIONS_MADE_REQUEST_DATA:
    return {
      ...state,
      data: action.payload
    }

  case SET_RESERVATIONS_MADE_PAST_FUTURE:
    return {
      ...state,
      ...action.payload
    }
  case AUTH_SIGNOUT_SUCCESS:
    return initialState

  case AUTH_TOKEN_FAILED:
    return initialState

  default:
    return state
  }

  // if (action.type === REHYDRATE) {
  //   // const savedUser = action.payload.user || state;
  //   // return {
  //   //   ...state,
  //   //   accessToken: savedUser.accessToken,
  //   //   profile: savedUser.profile,
  //   //   payment: savedUser.payment
  //   // };
  // }
  //
  // return state;
}
