import React, { Component } from 'react'
import { Field, FieldArray, reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { validateExperience } from './validate'
import { currentUserPropTypes } from '../../../constants/composed-proptypes'
import renderMenuItemField from '../../Form/Fields/renderMenuItemField'
import CheckboxGroup from '../../Form/Fields/renderCheckBoxGroup'
import * as fields from '../../Form/Fields/formFields'
import _ from 'lodash'
import { SPLIT_GRID_SIZE, requiredExperience } from '../../../constants'

import seo_meta from '../../../utils/seo-meta.json'

import {
  professionalFieldCollection,
  menuItemFieldCollection
} from '../../../constants/formFields'
import SEO from '../../Shared/Web/SEO'

function keysMatch(key, obj) {
  const keys = Object.keys(obj).map(x => x.toLowerCase())
  return keys.indexOf(key.toLowerCase()) !== -1
}

class Step7 extends Component {
  constructor(props) {
    super(props)
    this.state = {
      professionalFields: professionalFieldCollection,
      menuItemFields: menuItemFieldCollection,
      cuisineChange: false
    }
  }

  componentWillMount() {}

  /**
   * Disables a field if the same key is present in the currentUser object,
   * the user will not be able to update the field, but it will display the value.
   * @param  {object} fieldArray  an array of fields with keys and values.
   * @param  {object} currentUser the current signed in user
   * @return {object}             the updated field array
   */
  disableFields = fieldArray => {
    const { currentUser } = this.props
    if (typeof currentUser === 'undefined' || !currentUser.isSignedIn) {
      return fieldArray
    }
    const obj = fieldArray
    Object.keys(obj).map(key => {
      obj[key].disabled = !!keysMatch(key, currentUser.attributes)
      return obj[key]
    })
    return obj
  }

  async componentDidMount() {
    await this.props.hostActions.getSampleExperiences()
    if (
      /* !this.props.sampleExperince.menu &&
      !this.state.cuisineChange && */
      typeof this.props.currentUser.attributes.specialties !== 'undefined' &&
      this.props.currentUser.attributes.specialties.length != 0
    ) {
      this.props.change(
        'cuisineTypesAttributes',
        this.props.currentUser.attributes.specialties.map((el)=>({value: el.id, label: el.name, id: el.id}))
      )
    }

    // let sampleMenu =
    //console.log('this.props.sampleExperince==', this.props.sampleExperince)
    if (this.props.sampleExperince && this.props.sampleExperince.menu) {
      const menu = this.props.sampleExperince.menu.menuItems.reverse()
      this.props.change('menuAttributes.menuItemsAttributes', menu)
      if (this.props.sampleExperince.cuisineTypes && this.props.sampleExperince.cuisineTypes.length>0) {
        this.props.change(
          'cuisineTypesAttributes',
          this.props.sampleExperince.cuisineTypes.map((el)=>({value: el.id, label: el.name, id: el.id}))
        )
      }
    }
  }

  render() {
    const {
      handleSubmit,
      pristine,
      valid,
      submitting,
      previousPage,
      form,
      formState,
      array,
      validate,
      cuisineTypesOptions,
      userActions,
      menuFIleArray,
      menuCoursesOptions
    } = this.props
    const localCuisineTypes = _.sortBy(cuisineTypesOptions?cuisineTypesOptions.filter(el=>el.status === 'active').map(el=>({label: el.name, value: el.name, id: el.id})):[], 'label')
    const { professionalFields, menuItemFields, menuItems } = this.state
    const menuItemsArr = formState.sampleExperience && formState.sampleExperience.values && formState.sampleExperience.values.menuAttributes
      ? formState.sampleExperience.values.menuAttributes.menuItemsAttributes
      : formState.experienceCreate && formState.experienceCreate.values && formState.experienceCreate.values.menuAttributes
        ? formState.experienceCreate.values.menuAttributes.menuItemsAttributes
        : []

    const localMenuCoursesOptions = menuCoursesOptions?menuCoursesOptions.filter(el=>el.status === 'active').map(el=>({label: el.name, value: el.name, id: el.id})):[]

    let menuItemValid = false
    if (!menuItemsArr) {
      menuItemValid = true
    }
    if (menuItemsArr) {
      if (menuItemsArr.length < requiredExperience) {
        menuItemValid = true
      }
      if (menuItemsArr.length > 0) {
        menuItemsArr.forEach((element, index) => {
          if (!element.title || !element.description || !element.course || (!menuFIleArray[index] && (!element.menu_image_urls || (element.menu_image_urls && !element.menu_image_urls.big)))) {
            menuItemValid = true
          }
        })
      }
    }
    const { cuisine } = professionalFields
    // const { allergens, mealCategories } = menuItemFields
    const currentFormFields = formState[form] && formState[form].fields
    const {
      title,
      description,
      og_title,
      og_description,
      og_site_name,
      og_locale,
      og_type
    } = seo_meta.create_sample_menu

    return (
      <React.Fragment>
        <SEO title={title} description={description} />

        <form onSubmit={handleSubmit} className="">
          <div className="form-header">
            <h4>Create a Sample Menu</h4>
          </div>
          <div className="form-body form-body--full">
            <div className="grid--uniform">
              {/* <div className={SPLIT_GRID_SIZE}> */}
              <div>
                <Field
                  name="cuisineTypesAttributes"
                  component={fields.renderSelectField}
                  disabled={submitting}
                  options={localCuisineTypes}
                  placeholder={cuisine.placeholder}
                  isClearable
                  isMulti
                  type="select"
                  selectType="cuisine"
                  label="Cuisine *"
                  onChange={() => {
                    this.setState({ cuisineChange: true })
                  }}
                  displayLabel
                />
                <div className="menu-items">
                  <FieldArray
                    form={form}
                    name="menuAttributes.menuItemsAttributes"
                    component={renderMenuItemField}
                    userActions={userActions}
                    localMenuCoursesOptions={localMenuCoursesOptions}
                    props={{ data: menuItems, compareKey: 'id', array }}
                  />
                </div>
              </div>
              {/* <div className={SPLIT_GRID_SIZE}>
                <Field
                  name="menuAttributes.menuAllergensAttributes"
                  component={fields.renderSelectField}
                  disabled={submitting}
                  options={allergens.options}
                  placeholder={allergens.placeholder}
                  isClearable
                  isMulti
                  type="select"
                  selectType="allergens"
                  label="Let your guests know if your meal contains any food allergens"
                  displayLabel
                />

                <CheckboxGroup
                  name="menuAttributes.mealCategories"
                  label="Check any that apply to your menu"
                  options={mealCategories.options}
                />
              </div> */}
            </div>
          </div>
          <div className="form-footer">
            <button
              type="button"
              disabled={submitting}
              className="btn btn--link form-footer--left"
              onClick={() => previousPage()}
            >
              Go Back a Step
            </button>
            {this.props.sampleExperince && this.props.sampleExperince.menu ? (
              <button
                type="submit"
                className="btn btn--default form-footer--right"
                disabled={ submitting || !valid || menuItemValid }
              >
                Submit your application
              </button>
            ) : (
              <button
                type="submit"
                disabled={submitting || !valid || menuItemValid }
                className="btn btn--default form-footer--right"
              >
                Submit your application
              </button>
            )}
          </div>
        </form>
      </React.Fragment>
    )
  }
}

Step7.propTypes = {
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  valid: PropTypes.bool.isRequired,
  previousPage: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  currentUser: currentUserPropTypes.isRequired
}

// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
const reduxFormStep7 = reduxForm({
  form: 'sampleExperience',
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  // enableReinitialize:true,
  validate: validateExperience

  // enableReinitialize:true,
})(Step7)

// You have to connect() to any reducers that you wish to connect to yourself
const connectedStep7 = connect(state => ({
  formState: state.form,
  sampleExperince: state.user.experiences.sampleExperince,
  menuFIleArray: state.user.fileUploads.menuFIleArray
    ? [...state.user.fileUploads.menuFIleArray]
    : []
  // initialValues: state.user.experiences.sampleExperince
  // pull initial values from account reducer
}))(reduxFormStep7)

export default connectedStep7
